export class Contact {
  constructor(userRecord) {
    this.uid = userRecord.uid;
    this.sharedWhoop = !!userRecord.sharedWhoop
    this.displayName = userRecord.displayName || userRecord.email;
    this.email = userRecord.email;
    this.phoneNumber = userRecord.phoneNumber;
    this.profileImage = userRecord.photoURL ? userRecord.photoURL : userRecord.profileImage;
    this.licenses = userRecord.licenses || "";
    this.degrees = userRecord.degrees || "";
    this.pronouns = userRecord.pronouns || 'they'
    this.updateCreds();
    this.isTrainer = !!userRecord.isTrainer
    this.open = userRecord.open;
    this.maxHeartRate = userRecord.maxHeartRate || 0
    this.devices = userRecord.devices || {}
    if (userRecord.group) {
      this.group = userRecord.group;
    }
    if (userRecord.isGroup) {
      this.isGroup = true;
    }
  }
  
  updateCreds = () => {
    this.creds = this.degrees.split(", ").concat(this.licenses.split(", ")).filter(x => x).join(", ");
  }
  
  toJSON() {
    const result = {};
    ["uid", "email", "displayName", "phoneNumber", "profileImage", "degrees", "licenses", "open"].map(i => {
      result[i] = this[i] ? this[i] : "";
    });
    ["group", "isGroup"].map(i => {
      if (this[i]) result[i] = i;
    });
    result.devices = JSON.stringify(this.devices)
    return result;
  }
}
