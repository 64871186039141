import React, { Component } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import "./UploadProgress.css";

export class UploadProgress extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className="uploadProgress">
            <div className='uploadProgressContainer'>
            <div className="uploadProgressTitle">File Upload</div>
            <div className='uploads'>
            {this.props.uploads.map(upload => {
                return <div style={{width: upload.progress + "%"}} className='upload'/>
            })}
            </div>
            </div>
            </div>
    }
}
