import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import './index.css'

export class UIIcon extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className={this.props.className || 'uiIcon'} onClick={this.props.onClick}>
            <ReactSVG className={'uiIconSvg' +(this.props.className ? " "+this.props.className+"Svg" : "")} src={this.props.icon}/>
        </div>
    }
}
