import React, { Component } from 'react';
import { LBHero } from '../Website/lbHero'
import { LBDetail } from '../Website/lbDetail/'
import { ReactSVG } from 'react-svg'
import './index.css'

export class UITodoListWebsite extends Component {
  render() {
    return <div className='uiTodoListWebsite'>
        <div className='letsSurface'>
      <div className='letsHeroContainer'>
      <div className='letsHero'/>
      <div className='lbHeroControls'>        
        <LBDetail />
      <div className='lbButtons'>
        <div className='lbCreate' onClick={this.props.signUp}>SIGN UP</div>
        <div className='lbSignin' onClick={this.props.signIn}>SIGN IN</div>
      </div>
      </div>
      </div>
        </div>
      <div className='uiTodoListWebsiteTitle'>
      <div className='uiTodoListWebsitePlan'>Plan</div>
      <div className='uiTodoListWebsiteToRelax'>to relax<span className='tm'>™</span></div>
      </div>
      <div className='uiTodoListWebsiteAbout'>
      <div className='uiTodoListWebsiteAboutIt'>Intelligent<br/>To-do List</div>
      <div className='uiTodoListWebsiteAboutSubtitle'>Free your mind</div>
      </div>
      </div>
  }
}
