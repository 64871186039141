import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { isDesktop, isMobile } from '../../../Platform.js';
import { LBButtons } from '../lbButtons/';
import { LBFooter } from '../lbFooter/';
import { LBDetail } from '../lbDetail/';

import LogoSVGDevices from '../../../assets/Vector/DevicesLogos.svg';
import LogoSVGDevicesMobile from '../../../assets/Vector/DevicesLogosMobile.svg';
import './index.css';

export class LBHero extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='letsHeroContainer'>
      <div className='letsHero'/>
      <div className='lbHeroControls'>        
        <LBDetail />
        <div className='letContent'>
          <div className='letsTitleText'>REAL-TIME TRANSPARENCY THROUGH WEARABLE TECHNOLOGY</div>
          <ReactSVG className='letsSVGDevices' src={isMobile() ? LogoSVGDevicesMobile : LogoSVGDevices}/>
          <div className='letsSubText'>PERSONAL TRAINING WITH<br/>WHOOP STRAP × OURA RING × GARMIN DEVICES × SMART SCALES</div>
          <LBButtons signIn={this.props.signIn} signUp={this.props.signUp} />
        </div>
      </div>
      </div>
    );
  }
}
