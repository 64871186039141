import React, { Component } from 'react';
import './index.css';

export class LBButtons extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='lbButtons'>
        <div className='lbCreate' onClick={this.props.signUp}>TRAINER SIGN UP</div>
        <div className='lbButtonText'>If you are a client, your trainer can provide you with a sign-up link</div>
        <div className='lbBuittonLineSep'></div>
        <div className='lbSignin' onClick={this.props.signIn}>SIGN IN</div>
      </div>
    );
  }
}
