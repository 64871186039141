import React from 'react'
import ReactDOM from 'react-dom'
import {Client} from './Client'
import {UIVoiceEditor} from './components/VoiceEditor'
import { BrowserRouter } from 'react-router-dom'
import './index.css'

const isVoiceEditor = () => {
  const searchParams =  new URLSearchParams(window.location.search)
  const app = searchParams.get("app")
  if (app) {
    return app === 'voice'
  }
  return false
}

ReactDOM.render(
  <BrowserRouter>
    {isVoiceEditor() ? <UIVoiceEditor/> : <Client/>}
  </BrowserRouter>,
  document.getElementById('root')
);


