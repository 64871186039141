import React, { Component } from 'react';
export const BAA =  <div class='baa'>    
<h1>HIPAA Business Associate Agreement</h1>
<p>This HIPAA Business Associate Agreement ("BAA") is entered into between Badnano Corporation ("Badnano") and the customer agreeing to the terms below ("Customer"), and supplements, amends and is incorporated into the Services Agreement(s) (defined below) solely with respect to Covered Services (defined below). This BAA will be effective as of the date electronically accepted by Customer (the "BAA Effective Date").</p>
<p>Customer must have an existing Services Agreement in place for this BAA to be valid and effective. Together with the Services Agreement, this BAA will govern each party's respective obligations regarding Protected Health Information (defined below).</p>
<p>You represent and warrant that (i) you have the full legal authority to bind Customer to this BAA, (ii) you have read and understand this BAA, and (iii) you agree, on behalf of Customer, to the terms of this BAA. If you do not have legal authority to bind Customer, or do not agree to these terms, please do not click to accept the terms of this BAA.</p>
<p>1.&nbsp;<strong>Definitions.</strong>&nbsp;Any capitalized terms used but not otherwise defined in this BAA will have the meaning given to them in HIPAA and the HITECH Act.</p>
<p>"Business Associate" has the definition given to it under HIPAA.</p>
<p>"Breach" has the definition given to it under HIPAA. A Breach will not include an acquisition, access, use, or disclosure of PHI with respect to which Badnano has determined in accordance with 45 C.F.R. &sect; 164.402 that there is a low probability that the PHI has been compromised.</p>
<p>"Covered Entity" has the definition given to it under HIPAA.</p>
<p>"Covered Services" means the Badnano products and service.</p>
<p>"HIPAA" means the Health Insurance Portability and Accountability Act of 1996 and the rules and the regulations thereunder, as amended.</p>
<p>"HITECH Act" means the Health Information Technology for Economic and Clinical Health Act enacted in the United States Congress, which is Title XIII of the American Recovery &amp; Reinvestment Act, and the regulations thereunder, as amended.</p>
<p>"Protected Health Information" or "PHI" has the definition given to it under HIPAA and for purposes of this BAA is limited to PHI within Customer Data to which Badnano has access through the Covered Services in connection with Customer's permitted use of Covered Services.</p>
<p>"Security Breach" means any Breach of Unsecured PHI or Security Incident of which Badnano becomes aware.</p>
<p>"Security Incident" has the definition given to it under HIPAA.</p>
<p>"Services Agreement(s)" means the written agreement(s) entered into between Badnano and Customer for provision of the Covered Services, which agreement(s) may be in the form of online terms of service.</p>
<p>2.&nbsp;<strong>Applicability.</strong>&nbsp;This BAA applies to the extent Customer is acting as a Covered Entity or a Business Associate to create, receive, maintain, or transmit PHI via a Covered Service and to the extent Badnano, as a result, is deemed under HIPAA to be acting as a Business Associate or Subcontractor of Customer. Customer acknowledges that this BAA does not apply to, or govern, any other Badnano product, service, or feature that is not a Covered Service.</p>
<p>3.&nbsp;<strong>Use and Disclosure of PHI.</strong></p>
<p>(a) Except as otherwise stated in this BAA, Badnano may use and disclose PHI only as permitted or required by this BAA or as Required by Law.</p>
<p>(b) Badnano may use and disclose PHI for the proper management and administration of Badnano's business and to carry out the legal responsibilities of Badnano, provided that any disclosure of PHI for such purposes may only occur if: required by applicable law
<p>(c) Badnano has no obligations under this BAA with respect to any PHI that Customer creates, receives, maintains, or transmits outside of the Covered Services (including Customer's use of its offline or on-premise storage tools or third-party applications) and this BAA will not apply to any PHI created, received, maintained or transmitted outside of the Covered Services.</p>
<p>4.&nbsp;<strong>Customer Obligations.</strong></p>
<p>(a) Customer may only use the Covered Services to create, receive, maintain, or transmit PHI. Customer is solely responsible for managing whether Customer's end users are authorized to share, disclose, create, and/or use PHI within the Covered Services.</p>
<p>(b) Customer will not request that Badnano or the Covered Services use or disclose PHI in any manner that would not be permissible under HIPAA if done by Customer (if Customer is a Covered Entity) or by the Covered Entity to which Customer is a Business Associate (unless expressly permitted under HIPAA for a Business Associate).</p>
<p>(c) Customer will take appropriate measures to limit its use of PHI to the Covered Services and will limit its use within the Covered Services to the minimum extent necessary for Customer to carry out its authorized use of such PHI.</p>
<p>(d) Customer warrants that it has obtained and will obtain any consents, authorizations and/or other legal permissions required under HIPAA and/or other applicable law for the disclosure of PHI to Badnano. Customer will notify Badnano of any changes in, or revocation of, the permission by an Individual to use or disclose his or her PHI, to the extent that such changes may affect Badnano's use or disclosure of PHI. Customer will not agree to any restriction on the use or disclosure of PHI under 45 CFR &sect; 164.522 that restricts Badnano's use or disclosure of PHI under the Agreement unless such restriction is required by law.</p>
<p>5.&nbsp;<strong>Appropriate Safeguards.</strong>&nbsp;Badnano and Customer will each use appropriate safeguards designed to prevent against unauthorized use or disclosure of PHI, and as otherwise required under HIPAA, with respect to the Covered Services.</p>
<p>6.&nbsp;<strong>Reporting.</strong></p>
<p>(a) Subject to Section 6(d), Badnano will promptly notify Customer following Badnano's Discovery of a Security Breach in accordance with HIPAA and in the most expedient time possible under the circumstances, consistent with the legitimate needs of applicable law enforcement and applicable laws, and after taking any measures Badnano deems necessary to determine the scope of the Security Breach and to restore the reasonable integrity of Badnano's systems.</p>
<p>(b) To the extent practicable, Badnano will use commercially reasonable efforts to mitigate any further harmful effects of a Security Breach caused by Badnano.</p>
<p>(c) Badnano will send any applicable Security Breach notifications to the notification email address provided by Customer in the Services Agreement or via direct communication with the Customer.</p>
<p>(d) Notwithstanding Section 6(a), this Section 6(d) will be deemed as notice to Customer that Badnano periodically receives unsuccessful attempts for unauthorized access, use, disclosure, modification or destruction of information, or interference with the general operation of Badnano's information systems and the Covered Services. Customer acknowledges and agrees that even if such events constitute a Security Incident as that term is defined under HIPAA, Badnano will not be required to provide any notice under this BAA regarding such unsuccessful attempts other than this Section 6(d).</p>
<p>7.&nbsp;<strong>Subcontractors.</strong>&nbsp;Badnano will take appropriate measures to ensure that any Subcontractors used by Badnano to perform its obligations under the Services Agreements that require access to PHI on behalf of Badnano are bound by written obligations that provide the same material level of protection for PHI as this BAA. To the extent Badnano uses Subcontractors in its performance of obligations hereunder, Badnano will remain responsible for their performance as if performed by Badnano.</p>
<p>8.&nbsp;<strong>Access and Amendment.</strong>&nbsp;Customer acknowledges and agrees that Customer is solely responsible for the form and content of PHI maintained by Customer within the Covered Services, including whether Customer maintains such PHI in a Designated Record Set within the Covered Services. Badnano will provide Customer with access to Customer's PHI via the Covered Services so that Customer may fulfill its obligations under HIPAA with respect to Individuals' rights of access and amendment, but will have no other obligations to Customer or any Individual with respect to the rights afforded to Individuals by HIPAA with respect to Designated Record Sets, including rights of access or amendment of PHI. Customer is responsible for managing its use of the Covered Services to appropriately respond to such Individual requests.</p>
<p>9.&nbsp;<strong>Accounting of Disclosures.</strong>&nbsp;Badnano will document disclosures of PHI by Badnano and provide an accounting of such disclosures to Customer as and to the extent required of a Business Associate under HIPAA and in accordance with the requirements applicable to a Business Associate under HIPAA.</p>
<p>10.&nbsp;<strong>Access to Records.</strong>&nbsp;To the extent required by law, and subject to applicable attorney client privileges, Badnano will make its internal practices, books, and records concerning the use and disclosure of PHI received from Customer, or created or received by Badnano on behalf of Customer, available to the Secretary of the U.S. Department of Health and Human Services (the "Secretary") for the purpose of the Secretary determining compliance with this BAA.</p>
<p>11.&nbsp;<strong>Expiration and Termination.</strong></p>
<p>(a) This BAA will terminate on the earlier of (i) a permitted termination in accordance with Section 11(b) below, or (ii) the expiration or termination of all Services Agreements under which Customer has access to a Covered Service.</p>
<p>(b) If either party materially breaches this BAA, the non-breaching party may terminate this BAA on 10 days' written notice to the breaching party unless the breach is cured within the 10-day period. If a cure under this Section 11(b) is not reasonably possible, the non-breaching party may immediately terminate this BAA, or if neither termination nor cure is reasonably possible under this Section 11(b), the non-breaching party may report the violation to the Secretary, subject to all applicable legal privileges.</p>
<p>(c) If this BAA is terminated earlier than the Services Agreements, Customer may continue to use the Services in accordance with the Services Agreements, but must delete any PHI it maintains in the Covered Services and cease to further create, receive, maintain, or transmit such PHI to Badnano.</p>
<p>12.&nbsp;<strong>Return/Destruction of Information.</strong>&nbsp;On termination of the Services Agreements, Badnano will return or destroy all PHI received from Customer, or created or received by Badnano on behalf of Customer; provided, however, that if such return or destruction is not feasible, Badnano will extend the protections of this BAA to the PHI not returned or destroyed and limit further uses and disclosures to those purposes that make the return or destruction of the PHI infeasible.</p>
<p>13.&nbsp;<strong>Miscellaneous.</strong></p>
<p>(a) Survival. Sections 12 (Return/Destruction of Information) and 13 (Return/Destruction of Information) will survive termination or expiration of this BAA.</p>
<p>(b) Counterparts. The parties may execute this BAA in counterparts, including facsimile, PDF or other electronic copies, which taken together will constitute one instrument.</p>
<p>Effects of Agreement. To the extent this BAA conflicts with the remainder of the Services Agreement(s), this BAA will govern. This BAA is subject to the "Governing Law" section in the Services Agreement(s). Except as expressly modified or amended under this BAA, the terms of the Services Agreement(s) remain in full force and effect</p>
</p>
</div>;
