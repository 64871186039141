import React, { Component } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {InputField} from "./InputField"
import {Button} from "./Button";
import {isDesktop} from "./Platform";
import "./SelectionList.css"

export class SelectionList extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    search = (name, term) => {
        if (this.searchBusy) {
            console.log("search was busy with: ", this.searchBusy);
            this.searchBusy = term;
            return;
        }
        this.searchBusy = term;
        this.setState({
            searchTerm: term
        }, () => {
            if (this.searchBusy != undefined) {
                if (term != this.searchBusy) {
                    term = this.searchBusy;
                    this.searchBusy = undefined;
                    this.search(name, term);
                } else {
                    this.searchBusy = undefined;
                }
            }
        });
    }

    filter(items) {
        if (this.state.searchTerm) {
            const searchTerms = this.state.searchTerm.toLowerCase().split(/\s+/);
            const matches = {};
            const filter = x => {
                let matched = 0;
                const name = x.displayName.toLowerCase();
                const terms = name.split(/\s+/);
                searchTerms.map(searchTerm => {
                    terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
                });
                matches[x.uid] = matched;
                return matched > 0;
            }
            const sort = (x, y) => {
                const w1 = matches[x.uid];
                const w2 = matches[y.uid];
                const cmp1 = w2-w1;
                if (cmp1 !== 0) {
                    return cmp1;
                }
                return x.displayName.localeCompare(y.displayName);
            }
            items = items.filter(filter).sort(sort);
        } else {
            items.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
    }

    renderListItem(x) {
        return <div className='selectionListItem'>{x.displayName}</div>;
    }

    isItemSelected(x) {
        return false;
    }

    matchSearchTerm(term, x) {
        const name = x.displayName;
        const result = name.indexOf(term) >= 0;
        return result;
    }
    
    render() {
        let filtered = this.props.list;
        if (this.state.searchTerm) {
            const term = this.state.searchTerm.toUpperCase();
            filtered = this.props.list.filter(x => this.matchSearchTerm(term, x));
        }
        const isSelected = this.props.isItemSelected || (x=>this.isItemSelected(x));
        const renderListItem = this.props.renderListItem || (x=> this.renderListItem(x));
        const itemClassName = this.props.itemClassName;
        const selectedItemClassName = this.props.selectedItemClassName;
        return <div className='selectionList'>
            <div className='selectionListSearch'>
            <InputField autoFocus={isDesktop()} label={"Search "+this.props.searchType} name='search' value={this.state.searchTerm} onChange={this.search}/>
            </div>
            <div className='selectionListContainer'>
            <div className='selectionListList'>{filtered.map((x, i) => {
                const selected = isSelected(x);
                return <div key={i} className={selected ? selectedItemClassName : itemClassName}>{renderListItem(x)}</div>
            })}
        </div>
            <div className='selectListButtonContainer'>
            <Button label={"Back"} action={this.props.back}/>
            </div>
            </div>
            </div>
    }
}
