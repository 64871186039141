import React, { Component } from 'react';
import {ReactSVG} from 'react-svg';
import {InputField} from './InputField'
import {Button} from './Button'
import {isDesktop} from "./Platform"
import Logo01 from "./assets/Assets/TeteLogo00.svg";
import "./SignIn.css"


export class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        };
        for (var i in this.props.form) {
            this.state[i] = this.props.form[i];
        }
    }
    onChange= (field, value) => {
        const update = {};
        update[field] = value
        this.setState(update);
    }

    onReturn = e => {
        if (e.keyCode === 13) {
            this.props.signIn(this.state);
        }
    }

    componentDidMount() {
        if (isDesktop()) {
            window.addEventListener("keydown", this.onReturn);
        }
    }
    componentWillUnmount() {
        if (isDesktop()) {
            window.removeEventListener("keydown", this.onReturn);
        }
    }
    render() {
        if (this.props.authenticating) {
            return <div className='signIn'>
                <div className='signInProgress'>Authenticating</div>
            </div>
        }
        return <div className='signIn'>
            <div className='signInFields'>
            <div className="appTitle"><ReactSVG src={Logo01}/></div>
            <div className='signInTitle'>{this.props.title}</div>
            <InputField autoFocus={true} label={'Email Address'} value={this.state.email} name='email' type={'email'} autoComplete={'username'} onChange={this.onChange}
        
            />
            <InputField label={'Password'} value={this.state.password} name='password' type={'password'} autoComplete={'current-password'} onChange={this.onChange}/>
            <InputField label={'Dummy'}/>
            <div className='buttonContainer signInButtonContainer'>
            <Button label={this.props.backLabel} action={this.props.backAction}/>
            <Button label={"Forgot Password"} action={()=>this.props.forgotPassword(this.state)}/>
            <Button label={this.props.label} action={()=>this.props.signIn(this.state)}/>
            </div>
            <div className='signInError'>{this.props.error}</div>
            </div>
            </div>
    }
}
