import React, { Component } from 'react';
import {isDesktop} from "./Platform"
import "./Button.css"

export class Button extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const getClassName = () => {
            const size = this.props.extraSmall ? " buttonExtraSmall" : this.props.small ? " buttonSmall" : "";
            const enabled = this.props.action ? "" : " buttonDisabled";
            const platform = isDesktop() ? " buttonDesktop" : "";
            return "button"+size+enabled+platform;
        }
        return <div className={getClassName()} onClick={this.props.action}>
            <div className='buttonLabel'>{this.props.label}</div>
            </div>
    }
}
