import React, { Component } from 'react';
import {ReactSVG} from 'react-svg';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import Spinner from '../../Mobile/src/assets/Icons/spinner.svg'
import {UISubscription} from "../Subscription";
import {UIOpenContactView} from "../OpenContactView";
import Activity from '../../assets/icons/Active.svg'
import {FDPage} from '../../Mobile/src/components/Page'
import {FDPopup} from '../../Mobile/src/components/Popup'
import {FDButton1, FDRadioButtons} from '../../Mobile/src/components/Button'
import {UICreateGroup} from "../Group";
import {SummaryChart} from '../../Mobile/src/components/Data'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import {getDeviceCaps, MediaDevice} from "../../Signaling";
import {UIBlackboard} from '../Blackboard'
import {UIOKCancel} from '../OKCancel'
import {UIProfileIcon} from '../ProfileIcon'
import {UIRemoteVideo, CallButton, UIVideoStream, getGridStyle} from '../RemoteVideo'
import {UISubscribeToChat} from '../SubscribeToChat'
import {formatDate} from "../Appointment";
import {UINotificationSetting} from "../Settings";
import {getTime, UICalendar} from "../Calendar";
import {FoodLogo, getFoodName, UIScheduleAppointment} from "../ScheduleAppointment";
import {isDesktop, isSafari} from "../../Platform";
import CallLeft from "../../assets/audio/Igor/callLeft.wav";
import CallJoin from "../../assets/audio/Igor/callJoin.wav";
import RingShort from "../../assets/audio/Igor/ring_short.wav";
import Ring1 from "../../assets/audio/Igor/ring_1.wav";
import Ring2 from "../../assets/audio/Igor/ring_2.wav";
import {renderFoodProfile,  MealsProfile, DataCharts, WhoopWorkout, WhoopSleep } from '../WhoopProfile'
import NewMessage from "../../assets/audio/Igor/notification.wav";
import NotShort from "../../assets/audio/Igor/notification_short.wav";
import {Howl, Howler} from 'howler';
import {isMobile, isIPad, isIOS, isAndroid} from "../../Platform";
import ChatIcon from '../../assets/icons/ChatSpace.svg'
import Group from '../../assets/icons/Group.svg'
import Profile from "../../assets/icons/Profile.svg";
import Trash from '../../assets/icons/Trash.svg'
import SignOut from '../../assets/icons/SignOut.svg'
import CalendarIcon from '../../assets/icons/CalendarSml.svg'
import BlackboardIcon from '../../assets/icons/Blackboard/Board.svg'
import CallIcon from '../../assets/icons/Call.svg'
import VideoIcon from '../../assets/icons/VideoOn.svg'
import DataIcon from '../../assets/icons/SettingSml.svg'
import { formatFoodCount } from '../Me'
import { NutritionLabel } from '../Nutrition'
import { toFDA } from '../../classes/Nutritionix.js'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import './index.css'

const clone = x => JSON.parse(JSON.stringify(x));
const debugLog = (...args) => {
  console.log.apply(null, args)
}


const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)
/*
  import {createFFmpeg} from "@ffmpeg/ffmpeg";

  const isChrome = window.MediaRecorder && window.MediaRecorder.isTypeSupported && window.MediaRecorder.isTypeSupported("video/webm;codecs=h264");
  let ffmpeg;

  const doTranscode = async (blob) => {
  if (!ffmpeg) {
  ffmpeg = createFFmpeg({log: true});
  }
  debugLog('Loading ffmpeg-core.js');
  await ffmpeg.load();
  debugLog('Start transcoding');
  await ffmpeg.write('recording.webm', blob);
  debugLog("wrote recording.webm");
  //await ffmpeg.transcode('recording.webm', 'recording.mp4', "-threads 4");
  await ffmpeg.run("-i recording.webm -c:v mpeg4 -b:v 6400k -threads 4 -strict experimental recording.mp4");
  debugLog('Complete transcoding');
  const data = ffmpeg.read('recording.mp4')
  ffmpeg.remove('recording.webm');
  ffmpeg.remove('recording.mp4');
  return new Blob([data.buffer], { type: 'video/mp4' });
  };
*/



const callJoin = new Howl({
  src: [CallJoin],
  loop: false,
});

const callLeft = new Howl({
  src: [CallLeft],
  loop: false,
});

const newMessage = new Howl({
  src: [NewMessage],
  loop: false,
});

const notShort = new Howl({
  src: [NotShort],
  loop: false,
});

const ring1 = new Howl({
  src: [Ring1],
  loop: true,
});

const ring2 = new Howl({
  src: [Ring2],
  loop: true,
});

const ringShort = new Howl({
  src: [RingShort],
  loop: true,
});


export class UIActiveContactView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cycleData: [],
      meals: [],
      selection: 'cal',
      videoMuted: false,
      audioMuted: false,
      remoteVideo: [],
      callStats: {
        duration: 0,
        bytesReceived: 0,
        bytesSent: 0
      },
      calls: [],
      messages: [],
      incomingCalls: [],
      upNext: [],
      signInError: {},
      signUpError: {},
      resetPasswordError: {},
      phoneSignInError: {},
      yourVideoHidden: true,
      to: this.props.to,
      callHeight: 1,
      dataShared: false,
    }
    debugLog('activeContact', props)      
    this.mediaDevice = new MediaDevice(this.props.to.isGroup);
    this.chatMessages = {};
    this.streams = {};
    this.inProgress = {};
    this.waiting = {};
    this.messagePageSize = 20;
    this.messageCount = this.messagePageSize;
    this.cursor = 0;
    this.screenShare = {};
  }

  applyVideoConstraints = async (stream, value) => {
    const caps = await getDeviceCaps()
    const aspect = caps ? caps.width.max / caps.height.max : undefined
    const track = stream.getVideoTracks()[0]
    let height
    let width
    switch (value) {
      case 'uhd':
        height = 3840
        width = 2160
        break
      case '1080p':
        height = 1920
        width = 1080
        break
      case 'hd':
        height = 1280
        width = 720
        break
      default:
      case 'sd':
        height = 640
        width = 480
        break
      case 'ld':
        height = 480
        width = 360
        break
    }

    let w
    let h
    if (isMobile()) {
      h = height
    } else {
      w = height
    }
    
    const constraints = {
      video: {
        frameRate: { min: 24, ideal: 30 },
        //aspectRatio: aspect,
        height: h ? { ideal: h } : undefined,
        width: w ? { ideal: w} : undefined
      }
    }
    try {
      await track.applyConstraints(constraints.video)
      debugLog("track => ", constraints.video, track.getSettings())
    } catch (err) {
      console.error(err)
      //alert(err.message + " " + JSON.stringify(constraints))
    }
  }

  setAudioInput = async deviceId => {
    if (this.state.audioInput == deviceId) {
      return
    }
    this.setState({
      audioInput: deviceId
    })
    if (this.state.localVideoStream) {
      const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: { exact: deviceId }})
      const track = stream.getAudioTracks()[0];
      const s = this.state.localVideoStream;
      s.getAudioTracks().map(t => {
        t.stop();
        s.removeTrack(t);
      });
      s.addTrack(track);
      stream.getTracks().forEach(t => stream.removeTrack(t));
      Promise.all(this.state.calls.map(call => call.replaceSenderTrack(track)))
    }
  }

  getVideoRes = () => {
    return this.state.localVideoResolution || localStorage.getItem('videores') || (isDesktop() ? 'hd' : 'sd')
  }

  setVideoInput = async deviceId => {
    debugLog("set video input", deviceId)
    if (this.state.videoInput == deviceId) {
      return
    }
    //alert('set video input ' + deviceId)
    this.setState({
      videoInput: deviceId
    })
    if (this.state.localVideoStream) {
      const s = this.state.localVideoStream
      try {
        const toRemove = s.getVideoTracks();
         //alert('get removing current tracks '+toRemove.length)
        toRemove.forEach(track => {
          track.stop()
          s.removeTrack(track)
        })
        //alert('get webcam')
        const stream = await this.mediaDevice.getWebcamWithResolution(
          {
            res: this.getVideoRes(),
            videoDevice: deviceId,
            audioDevice: false
          })
        //alert('new video track ' + stream.id)
        const track = stream.getVideoTracks()[0]
        s.addTrack(track)
        stream.getTracks().forEach(t => stream.removeTrack(t))
        await Promise.all(this.state.calls.map(call => call.replaceSenderTrack(track)))
        await Promise.all(this.state.calls.map(call => call.sendDeviceSettings()))
        this.forceUpdate()
      }
      catch (err) {
        if (true || !this.props.me.isNative()) {
          //alert(err.message)
        }
      }
    }
  }

  setLocalVideoResolutionWithConstraints = async res => {
    this.setState({
      localVideoResolution: res
    })
    if (this.state.localVideoStream) {
      await this.applyVideoConstraints(this.state.localVideoStream, res)
      await Promise.all(this.state.calls.map(call => call.sendDeviceSettings()))
    }
  }

  setLocalVideoResolution = async res => {
    if (isDesktop() /* && !isSafari() */ &&  this.state.callActive) {
      return this.setLocalVideoResolutionWithConstraints(res)
    }
    this.setState({
      localVideoResolution: res
    })
    if (this.state.localVideoStream) {
      const s = this.state.localVideoStream;
      const toRemove = []
      s.getVideoTracks().map(t => {
        t.enabled = false
        toRemove.push(t)
        s.removeTrack(t)
      })
      ////alert("setting resolution " + res)
      return this.mediaDevice.getWebcamWithResolution({res, videoDevice: this.state.videoInput}).then(stream => {
        const track = stream.getVideoTracks()[0];
        //alert('new video track ' + stream.id)
        s.addTrack(track.clone())
        stream.getVideoTracks().forEach(t => t.stop())
        toRemove.forEach(t => t.stop())
        return Promise.all(this.state.calls.map(call => call.replaceSenderTrack(track))).then(() => {
          return Promise.all(this.state.calls.map(call => call.sendDeviceSettings()))
        })
      })
    }
  }
  
  showSystemProgressIndicator = (ts, apptId, msg) => {
    this.inProgress = {
      ts: ts,
      apptId: apptId,
    }
    window.showProgressIndicator(msg);
  }


  openSubscription = (sub) => {
    debugger
    this.setState({
      addTrainingSubscription: true,
      openSubscription: sub
    })
  }

  componentWillUnmount = () => {
    this.props.onDeleted(this.state.to);
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    if (this.iSharedDataSub) this.iSharedDataSub.unsubscribe()
    if (this.dataSharedSub) this.dataSharedSub.unsubscribe()

    if (this.provSub) this.provSub.unsubscribe()
    if (this.clientSub) this.clientSub.unsubscribe()
  }

  openChat = (nav, scope) => {
    return new Promise(resolve => {
      this.setState({
        selection: nav || this.state.selection || 'cal'
      }, () => {
        if (nav === 'cal' && scope) this.cal.setScope(scope)
        resolve()
      })
    })
  }

  componentDidMount() {
    this.chatStart = Date.now();
    this.props.me.openChat(this.state.to).then(chat => {
      this.setState({
        chat: chat
      });
      chat.onMessage(this.onChatMessage);
      if (this.props.selected && this.state.selection == 'chat') {
        chat.markRead()
      }
      if (this.props.incomingCall) {
        this.handleIncomingCall(this.props.incomingCall);
      } else if (this.props.outgoingCall) {
        this.makeCall();
      }
    }).catch(err => {
      console.error("couldn't open chat: ", err);
      //debugger;
    });
    this.props.onCreated(this.state.to, this);
    setTimeout(() => {
      if (!this.messagesShown) {
        this.messagesShown = true;
        this.props.onMessagesShown()
      }
    }, 2500);
    if (this.props.to.isGroup) {
      this.contactSub = this.props.me.observeGroup(this.props.to.uid).subscribe(c => {
        if (this.chat) this.chat.markAllDirty();
        this.setState({
          to: c
        });
      });
    } else {
      this.contactSub = this.props.me.observeContact(this.props.to.uid).subscribe(c => {
        debugger
        debugLog("CONTACT CHANGED: ", c);
        if (this.chat) this.chat.markAllDirty();
      });
    }
    
    this.iSharedDataSub = this.props.me.observeISharedDataWith(this.props.to).subscribe(shared => {
      this.setState({
        iShared: shared,
        shareData: shared
      })
    })
    
    this.dataSharedSub = this.props.me.observeDataSharedWithMe(this.props.to).subscribe(shared => {
      let selection = this.state.selection
      if (!shared) {
        if (selection === 'cal') {
          selection = 'chat'
        }
      } else {
        //if (selection === 'chat') {
        //selection = 'cal'
        //}
      }
      this.setState({
        dataShared: shared,
        selection
      })
    })
    this.deviceSettingSub = this.props.me.observeDeviceSettings().subscribe(settings => {
      //alert(JSON.stringify(settings, null, ' '))
      this.setState(settings)
    })
      this.provSub = this.props.me.observeSubscription(this.props.to).subscribe(change => {
        ////debugLog("provSub: ", change);
        if (change.type == 'removed') {
          this.setState({
            providerSubscription: null,
          });
        } else {
          this.setState({
            providerSubscription: change.subscription,
          });
        }
      }, err => {
        //debugger;
      });
      this.clientSub = this.props.me.observeMySubscription(this.props.to).subscribe(change => {
        ////debugLog("clientSub: ", change);
        if (change.type == 'removed') {
          this.setState({
            clientSubscription: null,
          });
        } else {
          this.setState({
            clientSubscription: change.subscription,
          });
        }
      }, err => {
        //debugger;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const markRead = () => {
      if (this.props.selected && this.state.selection == 'chat') {
        if (this.state.chat) {
          this.state.chat.markRead()
          return true
        }
      }
    }
    if (isMobile()) {
      this.props.me.setMobileCallActive(this.state.callActive)
    }
    if (this.props.selected) {
      if (!prevProps.selected) {
        if (this.chat) {
          this.chat.takeFocus();
        }
        if (this.state.selection === 'video') {
          this.loadLocalStream()
        }
      }
      if (this.state.selection == 'chat' && (prevState.selection != 'chat' || !prevProps.selected)) {
        if (markRead()) {
          return
        }
      }
    } else {
      if (prevProps.selected) {
        if (!this.state.callActive) {
          this.closeLocalVideoStream()
        }
      }
    }
    if (this.props.unreadCount != prevProps.unreadCount) {
      if (markRead()) {
        return
      }
    }
    if (this.props.incomingCall != prevProps.incomingCall) {
      this.handleIncomingCall(this.props.incomingCall);
    }
    if (this.props.incomingCallRequest != prevProps.incomingCallRequest) {
      this.handleIncomingCallRequest(this.props.incomingCallRequest);
    }
    if (this.props.outgoingCall && !prevProps.outgoingCall) {
      this.makeCall();
    }
    if (this.props.callHolding != this.callHolding) {
      this.callHolding = this.props.callHolding;
      this.updateLocalMediaStream()
    }
    if (prevState.shareData != this.state.shareData &&
        this.state.shareData != this.state.iShared) {
      this.props.me.shareData(this.state.to, this.state.shareData).then(() => {
      }).catch(err => {
        debugger
      })
    }
    if (prevState.isTraining != this.state.isTraining &&
        this.state.isTraining != this.state.iShared) {
      this.props.me.train(this.state.to, this.state.isTraining).then(() => {
      }).catch(err => {
        debugger
      })
    }
  }

  closeLocalVideoStream = () => {
    if (this.localStreamLoaded) {
      this.localStreamLoaded = false
      this.setState({
        localVideoStream: null
      }, () => {
        this.mediaDevice.reset()
      })
    }
  }

  onChatMessage = (op, msg) => {
    debugLog("onChatMessage ", op, ": ", msg);
    if (op == 'removed') {
      delete this.chatMessages[msg.ts];
    } else {
      this.chatMessages[msg.ts] = msg;
      if (!this.chatAdded) {
        this.chatAdded = msg.from != this.props.me.self.uid && msg.ts > this.chatStart;
      }
      if (this.chat && (!this.mostRecent || msg.ts > this.mostRecent)) {
        this.mostRecent = msg.ts;
        this.chat.scrollToBottom();
      }
    }
    this.setState({
      mostRecent: this.mostRecent || 0,
      chatUpdates: this.state.chatUpdates + 1
    });
    if (msg.data && msg.data.appointment) {
      const appt = msg.data.appointment;
      const waiting = this.waiting[appt.id];
      if (waiting) {
        delete this.waiting[appt.id];
        waiting.map(resolve => resolve());
        return this.updateMessagesNow();
      }
    }
    if (msg.data && msg.data.scheduledWorkout) {
      const workout = msg.data.scheduledWorkout;
      const waiting = this.waiting[workout.id];
      if (waiting) {
        delete this.waiting[workout.id];
        waiting.map(resolve => resolve());
        return this.updateMessagesNow();
      }
    }
    if (this.chat) this.chat.markDirty(msg);
    if (msg.files && msg.files[0].progress) {
      return this.updateMessagesNow();
    }
    this.updateMessagesLater();
  }

  reactToMessage = (msg, emoji) => {
    const uid = this.state.chat.localContact.uid;
    const existing = msg.reactions && msg.reactions.find(x => x.name == emoji.short_name);
    if (!msg.reactions) msg.reactions = [];
    if (existing) {
      const found = existing.users.find(userId => userId == uid);
      if (found) {
        existing.users = existing.users.filter(userId => userId != uid)
        if (existing.users.length == 0) {
          msg.reactions = msg.reactions.filter(x => x.name != emoji.short_name);
        }
      } else {
        existing.users.push(uid);
      }
    } else {
      msg.reactions.push({
        users: [uid],
        name: emoji.short_name
      });
    }
    const to = this.state.chat.remoteContact.uid;
    this.onChatMessage("modified", msg);
    this.updateMessagesNow();
    return this.props.me.addReaction(to, msg.ts, emoji.short_name);
  }


  saveWhiteboard = data => {
    this.props.me.saveWhiteboard(this.state.chat.channelId, data);
  }


  observeWhiteboard = () => {
    return this.props.me.observeWhiteboard(this.state.chat.channelId);
  }

  observeCanvasData = () => {
    return this.state.chat.observeCanvasData(this.state.chat.channelId);
  }

  sendCanvasData = (data) => {
    return this.state.chat.sendCanvasData(this.state.chat.channelId, data);
  }


  saveMessage = msg => {
    this.onChatMessage("added", msg);
    return this.state.chat.sendMessage(msg);
  }

  sendMessage = text => this.state.chat.send(text);

  deleteMessage = message => {
    this.state.chat.deleteMessage(message);
  }

  waitForUpdate = appt => {
    let waiting = this.waiting[appt.id];
    if (!waiting) {
      waiting = [];
      this.waiting[appt.id] = waiting;
    }
    return new Promise((resolve, reject) => {
      waiting.push(resolve);
    });
  }

  updateMessagesNow = () => {
    if (this.props.selected && this.state.selection == 'chat') {
      this.state.chat.markRead()
    }
    let msgs = [];
    let messages = {};
    let active = {};
    let appts = {};
    const workouts = {}
    for (const ts in this.chatMessages) {
      const msg = this.chatMessages[ts]
      if (msg.data && msg.data.type === 'weight' && !msg.data.weight) continue
      msgs.push(msg)
    }
    msgs.sort((x, y) => {
      return x.ts - y.ts;
    });
    msgs.map(msg => {
      const ts = msg.ts;
      if (msg.data && msg.data.scheduledWorkout) {
        const prev1 = workouts[msg.data.scheduledWorkout.id]
        if (prev1) {
          if (prev1.ts < msg.ts) {
            console.log("deleting prev", prev1.data.scheduledWorkout.id, prev1)
            delete messages[prev1.ts]
          } else {
            return
          }
        }
        workouts[msg.data.scheduledWorkout.id] = msg
      }
      if (msg.data && msg.data.appointment) {
        const appt = msg.data.appointment;
        if (appt.paymentIntentId &&
            (!appt.paymentStatus ||
             appt.paymentStatus == "requires_confirmation" ||
             appt.paymentStatus == "requires_payment_method")) {
          return;
        }
        if (!appt.contact) {
          appt.organizer = this.props.me.getContact(appt.uid);
          const other = appt.uid == this.props.me.self.uid ? appt.client : appt.uid;
          appt.contact = this.props.remoteContact ? this.props.remoteContact : this.props.me.getContact(other);
          
        }
        //debugLog("appointment message: ", msg);
        const prev = appts[appt.id]
        //debugLog("appointment message prev: ", prev);
        if (prev) {
          const prevAppt = prev.data.appointment;
          if (appt.paymentSeq) {
            if (appt.paymentSeq != prevAppt.paymentSeq) {
              if (!prevAppt.paymentSeq || prevAppt.paymentSeq < appt.paymentSeq) {
                delete messages[prev.ts];
              } else {
                if (prev.paymentSeq && appt.paymentSeq < prev.paymentSeq) {
                  return;
                }
              }
            } 
          }
        }
        appts[appt.id] = msg;
      }
      if (msg.data && msg.data.subscription) {
        const sub = msg.data.subscription;
        if (sub.before && sub.before.state == sub.state) {
          //return;
        }
      }
      if (msg.text || msg.system || msg.data) {
        messages[msg.ts] = msg;
      }
    });
    console.log("workouts", workouts)
    let ms = Object.values(messages);
    ms.sort((a, b) => {
      return a.ts - b.ts;
    });
    console.log("msgs", ms)
    const seen = {};
    const status = {};
    let result = ms;
    for (var i = result.length-1; i >= 0; --i) {
      const m = result[i];
      if (m.data && m.data.scheduledWorkout) {
        const workout = m.data.scheduledWorkout
        if (!seen[workout.id]) {
          if (this.chat && !m.data.isActive) {
            this.chat.markDirty(m)
          }
          m.data.isActive = true
          seen[workout.id] = true
        } else {
          if (this.chat && m.data.isActive) {
            this.chat.markDirty(m)
          }
          m.data.isActive = false
        }
      } else if (m.data && m.data.appointment) {
        if (!seen[m.data.appointment.id]) {
          const appt = m.data.appointment;
          if (this.chat && !m.data.isActive) {
            this.chat.markDirty(m);
          }
          m.data.isActive = true;
          active[appt.id] = appt;
          seen[m.data.appointment.id] = true;
        } else {
          if (this.chat && m.data.isActive) {
            this.chat.markDirty(m);
          }
          m.data.isActive = false;
        }
      } else if (m.data && m.data.subscription) {
        if (!seen[m.data.subscription.id]) {
          const appt = m.data.subscription
          if (this.chat && !m.data.isActive) {
            this.chat.markDirty(m);
          }
          m.data.isActive = true;
          seen[m.data.subscription.id] = true;
        } else {
          if (this.chat && m.data.isActive) {
            this.chat.markDirty(m);
          }
          m.data.isActive = false;
        }
      }
    }
    debugLog("msgs=", result);
    result = result.slice(Math.max(result.length-this.messageCount, 0), result.length);
    this.setState({
      messages: result
    }, () => {
      if (this.chatAdded) {
        this.chatAdded = false;
        newMessage.play();
      }
      if (!this.messagesShown) {
        this.messagesShown = true;
        this.props.onMessagesShown();
      }
    });
  }
  
  updateMessagesLater = () => {
    clearTimeout(this.messagesUpdate);
    this.messagesUpdate = setTimeout(() => {
      this.updateMessagesNow();
    }, 200);
  }

  updateLocalMediaStream0 = () => {
    if (this.state.localVideoStream) {
      this.state.localVideoStream.getVideoTracks().forEach(track => track.enabled = !this.state.videoMuted);
      this.state.localVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.audioMuted);
    }
  }

  updateLocalMediaStream = () => {
    this.updateLocalMediaStream0()
    this.state.calls.forEach(call => {
      call.setAudioMuted(this.props.callHolding || this.state.audioMuted);
      call.setVideoMuted(this.props.callHolding || this.state.videoMuted);
    });
  }

  toggleVideoMuted = () => {
    const muted = !this.state.videoMuted;
    this.setState({
      videoMuted: muted
    }, this.updateLocalMediaStream);
  }

  toggleAudioMuted = (forceMute) => {
    const muted = forceMute ? true : !this.state.audioMuted;
    //debugLog("toggle audio muted: ", muted);
    this.setState({
      audioMuted: muted
    }, this.updateLocalMediaStream);
  }

  toggleYourVideoHidden = () => {
    this.setState({
      yourVideoHidden: !this.state.yourVideoHidden
    });
  }

  toggleFullScreen = () => {
    this.setState({
      fullScreen: !this.state.fullScreen
    });
  }

  toggleScreenShare = () => {
    if (this.props.to.isGroup) {
      for (var call of this.state.calls) {
        if (call.isSharingScreen()) {
          return;
        }
      }
    }
    const mediaDevice = this.mediaDevice;
    const screenShare = !this.state.screenShare;
    let p = screenShare ? mediaDevice.getScreenVideo() : this.mediaDevice.getWebcamWithResolution({res: this.getVideoRes(), videoDevice: this.state.videoInput})
    return p.then(stream => {
      const track = stream.getVideoTracks()[0];
      if (screenShare) {
        track.addEventListener('ended', () => {
          if (this.state.screenShare) {
            this.toggleScreenShare();
          }
        });
      }
      if (this.state.localVideoStream) {
        const s = this.state.localVideoStream;
        s.getVideoTracks().map(t => {
          t.stop();
          s.removeTrack(t);
        });
        s.addTrack(track);
      }
      stream.getTracks().forEach(t => stream.removeTrack(t));
      Promise.all(this.state.calls.map(call => call.replaceSenderTrack(track))).then(() => {
        this.state.calls.map(call => call.setScreenShare(screenShare));                
      });
      this.setState({
        screenShare: screenShare
      });

    });
  }


  startGroupCall = (id) => {
    const caller = this.props.me.self;
    const mediaDevice = this.mediaDevice;
    let callees;
    const group = this.state.to.group;
    if (group.organizer == this.props.me.self.uid) {
      callees = group.members.filter(uid => uid != group.organizer).map(uid => this.props.me.getContact(uid));
    } else {
      callees = [this.props.me.getContact(group.organizer)];
    }
    ring1.play();
    callees = callees.filter(c => {
      const uid = c.uid;
      if (uid == this.props.me.self.uid) {
        return false;
      }
      this.state.calls.map(call => {
        if (uid == call.getRemoteContact().uid) {
          return false;
        }
      });
      return true;
    });
    const ops = callees.forEach(callee => {
      if (!this.state.callActive) return;
      return this.props.signaling.call(id, mediaDevice, caller, callee, group).then(call => {
        if (!this.state.callActive) {
          call.hangup();
          return;
        }
        this.handleCall(call);
        this.state.calls.map(c => {
          c.add(call);
          call.add(c);
        });
        this.state.calls.push(call);
        debugLog("calls: ", this.state.calls);
        this.state.outgoingCall = call;
        this.forceUpdate(this.updateLocalMediaStream);
        this.props.onCallStarted(call);
        call.observeHangup().subscribe(() => {
          this.endCall(call);
        });
        call.onStatsUpdate(this.onStatsUpdate);
        call.observeHangup().subscribe(()=>this.handleStreamClosed(call));
        call.observeRemoteStreams().subscribe(stream => {
          this.handleIncomingStreams(call, stream);               
        });
      });
    });
    return Promise.all(ops);
  }

  startCall = (id) => {
    const caller = this.props.me.self;
    let callee = this.state.to;
    if (callee.isGroup) {
      return this.startGroupCall(id);
    }
    const mediaDevice = this.mediaDevice;
    ring1.play();
    return this.props.signaling.call(id, mediaDevice, caller, callee).then(call => {
      if (!this.state.callActive) {
        call.hangup();
        return;
      }
      debugLog("outgoing call connected: ", call);            
      if (call.getRemoteContact().uid == this.props.me.self.uid) {
        //debugLog("self-call!!");                        
        // self-call
        this.toggleAudioMuted(true);
      }
      if (!call) {
        // call failed
        this.setState({
          callActive: false
        });
        return;
      }
      if (false && !this.state.callActive || !this.state.callActive.id == id) {
        // user already hung up;
        call.hangup();
        return;
      }
      this.state.callActive.hangup = () => this.endCall(call);
      this.handleCall(call);
      this.state.calls.push(call);
      this.setState({
        outgoingCall: call,
      }, this.updateLocalMediaStream());
      this.props.onCallStarted(call);
      call.observeHangup().subscribe(() => {
        this.endCall(call);
      });
      call.onStatsUpdate(this.onStatsUpdate);
      call.observeHangup().subscribe(()=>this.handleStreamClosed(call));
      call.observeRemoteStreams().subscribe(stream => {
        debugLog("got outgoing stream: ", call);
        if (!this.isGroupOrganizer() && this.incomingCall) {
          const cmp = this.props.to.isGroup ? (this.isGroupOrganizer() ?  0 : 1) :
                this.props.me.self.uid.localeCompare(this.state.to.uid);
          if (cmp != 0) {
            const shouldAnswer = cmp > 0;
            let toHangup;
            if (shouldAnswer) {
              debugLog("hanging up outgoing call");
              toHangup = call;
            } else {
              debugLog("hanging up incoming call");
              toHangup = this.incomingCall;
            }
            this.killDup(toHangup);
            return;
          }
        }
        if (this.props.incomingCallRequest) {
          this.props.incomingCallRequest.answer();
        }
        this.outgoingCall = call;
        this.handleIncomingStreams(call, stream);               
      });
      if (this.props.incomingCallRequest) {
        this.props.incomingCallRequest.answer();
      }
      return Promise.resolve();
    });
  }

  callId = 0;

  makeCall = ()=> {
    const id = ++this.callId;
    const to = this.props.contact;
    if (this.state.callActive && !this.state.isCaller) {
      if (!this.state.call) {
        return this.state.callActive.answer();
      }
      return Promise.resolve();
    }
    if (this.props.incomingCallRequest) {
      this.props.answerIncomingCall(false);
      return Promise.resolve();
    }
    this.incomingCall = null;
    this.outgoingCall = null;
    const callActive = {
      id: id,
      remoteContact: to,
      answer: ()=> this.startCall(id),
      hangup: ()=> this.endCall()
    }
    return this.beginCall(callActive, true);
  }

  handleCall = call => {
    call.onMuted((type, muted) => {
      if (type == "audio") {
        this.setState({
          remoteAudioMuted: muted
        });
      } else if (type == "video") {
        this.setState({
          remoteVideoMuted: muted
        });
      }
    });
    call.observeHangup().subscribe(() => {
      delete this.screenShare[call.id];
      this.updateRemoteScreenShare();
    });
    call.observeScreenShare().subscribe(screenShare => {
      if (screenShare) {
        if (this.screenShare[call.id]) {
          return;
        }
        this.screenShare[call.id] = call;
      } else {
        if (!this.screenShare[call.id]) {
          return;
        }
        delete this.screenShare[call.id];
      }
      this.updateRemoteScreenShare();
    });
    call.observeGroupScreenShare().subscribe(contact => {
      debugLog("group screen share: ", contact);
      if (!this.state.groupScreenShare || this.state.groupScreenShare.uid != contact.uid) {
        this.setState({
          groupScreenShare: contact,
        });
      }
    });
    call.observeComposite().subscribe(composite => {
      //debugLog("composite: ", composite);
      this.setState({
        remoteComposite: composite
      });
    });
  }

  updateRemoteScreenShare = () => {
    debugLog("updateRemoteScreenShare: ", this.state.remoteScreenShare, ": ", this.screenShare);
    for (var id in this.screenShare) {
      if (!this.state.remoteScreenShare) {
        if (this.isGroupOrganizer()) {
          const call = this.screenShare[id];
          this.state.calls.forEach(c => {
            if (c.id != id) {
              c.setGroupScreenShare(call);
            }
          });
        }
        this.state.remoteScreenShare = true;
        this.forceUpdate();
      }
      return;
    }
    if (this.state.remoteScreenShare) {
      if (this.isGroupOrganizer()) {
        this.state.calls.forEach(c => {
          c.setGroupScreenShare(null);
        });
      }
      this.state.remoteScreenShare = false;
      this.forceUpdate();
    }
  }

  removeCall = c => {
    const newCalls = this.state.calls.filter(call => c != call);
    this.updateStreamsState(newCalls);
    this.setState({
      calls: newCalls,
    });
  }

  handleStreamClosed = call => {
    debugLog("STREAM CLOSED: ", call);
    ring1.stop();
    this.endCall(call);
  }

  updateStreamsState = () => {
    const video = [];
    var added = {};
    for (var callId in this.streams) {
      const call = this.state.calls.find(c => c.id == callId);
      if (!call) {
        delete this.streams[callId];
        continue;
      }
      for (var j in this.streams[callId]) {
        const stream = this.streams[callId][j];
        stream.getVideoTracks().forEach(track => {
          if (!added[stream.id]) {
            added[stream.id] = true;
          }
          video.push({
            call: call,
            stream,
          });
        });
      }
    }
    debugLog("streams state: ", this.streams);
    debugLog("video: ", video);
    this.setState({
      remoteVideo: video
    });
  }
  
  handleIncomingStreams = (call, stream) => {
    ring1.stop();
    //debugLog("got stream :", call.id, ": ", stream.id, ": tracks: ", stream.getVideoTracks().length);
    if (!this.streams[call.id]) {
      this.streams[call.id] = {};
    }
    if (!this.streams[call.id]['incoming-'+stream.id]) {
      this.streams[call.id]['incoming-'+stream.id] = stream;
      stream.onaddtrack = this.updateStreamsState;
      stream.onremovetrack = this.updateStreamsState;
    }
    this.updateStreamsState();        
  }

  loadLocalStream = async () => {
    if (!this.localStreamLoaded) {
      this.localStreamLoaded = true
      const stream = await this.mediaDevice.getWebcamWithResolution({
        audioDevice: this.state.audioInput,
        audio: true,
        res: this.getVideoRes(),
        videoDevice: this.state.videoInput}, true)
      //alert('local stream ' + stream.id)
      debugLog("make call: got webcam stream: ", stream);
      // @TODO handle webcam loss
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        debugLog("webcam closed");
        //debugger;
        //this.mediaDevice.reset();
        //this.localLocalStream();
      });
      //this.applyVideoConstraints(stream, this.getVideoRes())
      return new Promise(resolve => {
        this.setState({
          localVideoStream: stream
        }, resolve)
      })
    }
    return Promise.resolve()
  }       

  beginCall = (callActive, isCaller) => {
    return this.loadLocalStream().then(stream => {
      debugLog("loaded local stream: ", stream);
      this.setState({
        callActive: callActive,
        isCaller: isCaller,
        selection: 'video'
      });
    })
  }

  killDup = (call) => {
    debugLog("killDup: ", call);
    this.state.calls = this.state.calls.filter(x => x != call);
    call.ignoreEndCall = true;
    call.hangup();
    this.props.onCallEnded(call);
    this.handleCallEnded(call);
    this.forceUpdate();
  }

  handleCallEnded = call => {
    if (call == this.state.incomingCall) {
      this.state.incomingCall = null;
    }
    if (call == this.state.outgoingCall) {
      this.state.outgoingCall = null;
    }
    if (call == this.incomingCall) {
      this.incomingCall = null;
    }
    if (call == this.outgoingCall) {
      this.outgoingCall = null;
    }
  }

  endCall = (call) => {
    this.endCallImpl(call);
    debugLog("after endCall: ", this.state.calls);
  }
  
  endCallImpl = (call) => {
    if (call) this.props.onCallEnded(call);
    this.handleCallEnded(call);
    if (call) {
      if (this.streams[call.id]) {
        delete this.streams[call.id];
        this.updateStreamsState();
      }
    } else {
      //debugger;
      if (!this.state.calls.length) {
        this.reset();
        return;
      }
      this.state.calls.filter(x => x).map(call => {
        call.hangup();
      });
      return;
    }
    //debugger;
    if (call && call.ignoreEndCall) return;
    if (call) call.ignoreEndCall = true;
    const active = this.state.callActive;
    if (active) {
      const isCaller = active.isCaller;
      if (isCaller) {
        ring1.stop();
      } 
    } else {
      ring1.stop();
    }
    this.removeCall(call);
  }

  removeCall = call => {
    let calls = this.state.calls = this.state.calls.filter(x => x != call);
    //debugger;
    this.updateStreamsState();
    if (!calls.length) {
      this.reset();
    } else {
      this.setState({
        calls: calls
      });
    }
  }

  reset = () => {
    debugLog("RESET");
    //debugger;
    const active = this.state.callActive;
    this.state.callActive = null;
    let select = this.state.selection
    if (active) {
      callLeft.play();
      active.hangup();
      if (active.recording) {
        select = 'chat'
      }
    }
    if (this.screenshareSub) {
      this.screenshareSub.unsubscribe();
      this.screenshareSub = null;
    }
    debugLog("ended call");
    this.streams = {};
    this.setState({
      remoteVideoWidth: 0,
      messagingActive: false,
      screenShare: false,
      callActive: false,
      remoteVideo: [],
      isCaller: false,
      calls: [],
      callStats: {
        duration: 0,
        bytesReceived: 0,
        bytesSent: 0
      },
      incomingCall: null,
      outgoingCall: null,
      audioMuted: false,
      videoMuted: false,
      selection: select
    });
    this.incomingCall = this.outgoingCall = null;
  }

  onAnimationComplete = value => {
    if (value == 0) {
      this.closeLocalVideoStream()
    }
  }

  closeContact = (shouldEndCall) => {
    return new Promise((resolve, reject) => {
      this.chatMessages = {};
      this.updateMessagesLater();
      if (this.state.to) {
        this.setState({openContact: null, chat: null, remoteVideo: []},
                      () => {
                        if (shouldEndCall) this.endCall();
                        if (isDesktop() && this.contactsSearchField) {
                          this.contactsSearchField.focus();
                        }
                        resolve();
                      });
      } else {
        resolve();
      }
    });
  }

  callOrHangup = () => {
    if (this.state.callActive) {
      this.endCall();
    } else {
      this.makeCall(this.state.to);
    }
  }

  sendMessage = text => this.state.chat.send(text);

  deleteMessage = message => {
    this.state.chat.deleteMessage(message);
  }

  answerIncomingCall = (call, decline) => {

  }

  handleIncomingCallRequest = (c) => {
    debugLog("incoming call request: ", c);
    if (!c) {
      return;
    }
    if (this.state.calls.length > 0) {
      c.answer();
    }
  }

  isGroupOrganizer = () => this.state.to.isGroup && this.state.to.group.organizer == this.props.me.self.uid;
  
  handleIncomingCall = (c) => {
    if (!c) {
      return;
    }
    debugLog("got incoming call: ", c);
    
    const {call, resolve} = c;
    const me = this.props.me;
    const contact = this.state.to;
    const mediaDevice = this.mediaDevice;
    const decline = () => {
      return resolve();
    }
    const accept = () => {
      return resolve({
        mediaDevice: mediaDevice,
        answer: call => {
          debugLog("incoming call connected: ", call);
          if (call) {
            const remote = call.getRemoteContact();
            if (me.self.uid == remote.uid) {
              //debugLog("self-call!");
              this.toggleAudioMuted(true);
              call.observeHangup().subscribe(()=> this.endCall(call));
              return;
            }
            if (this.isGroupOrganizer()) {
              this.state.calls.map(c => {
                c.add(call);
                call.add(c);
              });
            } else if (this.props.to.isGroup) {
              if (this.state.calls.length > 0) {
                // duplicate call
                return call.hangup();
              }
            }
            this.setState({
              calls: this.state.calls.concat([call])
            }, this.updateLocalMediaStream);
            this.handleCall(call);
            this.setState({
              incomingCall: call,
            }, this.updateLocalMediaStream);
            call.onStatsUpdate(this.onStatsUpdate);
            call.observeHangup().subscribe(() => {
              debugLog("got incoming call hangup");
              this.handleStreamClosed(call);
            });
            call.observeRemoteStreams().subscribe(stream => {
              debugLog("got incoming stream: ", call);
              if (this.outgoingCall && !this.isGroupOrganizer()) {
                const cmp = this.props.to.isGroup ? (this.isGroupOrganizer() ?  0 : 1) :
                      this.props.me.self.uid.localeCompare(this.state.to.uid);
                if (cmp != 0) {
                  const shouldAnswer = cmp > 0;
                  let toHangup;
                  if (shouldAnswer) {
                    debugLog("hanging up incoming call");
                    toHangup = call;
                  } else {
                    debugLog("hanging up outgoing call");
                    toHangup = this.outgoingCall;
                  }                                
                  this.killDup(toHangup);
                  return;
                }
              }
              this.incomingCall = call;
              this.handleIncomingStreams(call, stream)
            });
            this.props.onCallStarted(call);
            return Promise.resolve();
          }
          return Promise.resolve();
        },
        from: contact,
        received: Date.now()
      });
    };

    if (this.state.callActive) { // already on a call
      debugLog("accepting incoming call");
      return accept();
    }

    if (this.props.selected && this.state.selection == 'call') {
      debugger
      return accept()
    }

    const callActive = {
      answer: accept,
      hangup: decline
    }
    call.observeHangup().subscribe(() => {
      debugLog("Incoming call hung up");
      if (this.state.calls.length == 0) {
        if (this.state.callActive == callActive) {
          this.setState({
            callActive: null,
          });                
        }
      }
    });
    return this.beginCall(callActive, false);
    
  }

  recordMessage = () => {
    return this.loadLocalStream().then(stream => {

      this.setState({
        selection: 'video',
        callActive: {
          recording: true,
          hangup: this.cancelRecording,
          answer: this.startRecording,
        }
      });
    });
  }

  startRecording = () => {
    const chunks = [];
    const stream = this.state.localVideoStream;        
    let mimeType;
    const types = ["video/mp4; codecs=h264", "video/webm; codecs=av1", "video/webm; codecs=vp9", "video/webm; codecs=vp8"];
    if (MediaRecorder.isTypeSupported) {
      types.map(t => {
        if (!mimeType && MediaRecorder.isTypeSupported(t)) {
          mimeType = t;
        }
      });
    }
    if (!mimeType) {
      mimeType = "video/mp4";
    }
    debugLog("recorder mime type: ", mimeType);
    const track = stream.getVideoTracks()[0]
    const { height, width }  = track.getSettings()
    this.mediaRecorder = new MediaRecorder(stream, {mimeType: mimeType});
    this.mediaRecorder.start();
    this.mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, {type: mimeType});
      let p = Promise.resolve(blob);
      //if (isChrome) {
      //p = doTranscode(blob);
      //}
      return p.then(blob => {
        debugLog("blob type: ", blob.type);
        this.chat.insertVideo(blob, { height, width });
        this.mediaRecorder = null;
        this.setState({
          callActive: null,
        });
        const resolve = this.mediaRecorderResolve;
        this.mediaRecorderResolve = null;
        resolve();
      }).catch(err => {
        console.error(err);
        return blob;
      });
    }
    this.mediaRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    }
  }

  cancelRecording = () => {
    if (this.mediaRecorder) {
      this.mediaRecorder.onstop = () => {};
      this.mediaRecorder.ondataavailable = () => {};
      this.mediaRecorder.stop();
      this.mediaRecorder = null;
    }
    this.setState({
      callActive: null,
    });
    return Promise.resolve();
  }

  setRecordingPaused = (paused) => {
    if (paused) {
      this.mediaRecorder.pause();
    } else {
      this.mediaRecorder.resume();
    }
  }

  stopRecording = () => {
    return new Promise((resolve, reject) => {
      this.mediaRecorderResolve = () => {
        this.closeLocalVideoStream()
        resolve()
      }
      this.mediaRecorder.stop();
    });
  }

  callJoin = () => {
    if (!this.state.callActive) {
      return this.makeCall().then(this.callJoin)
    }
    if (this.state.callActive.recording) {
      if (this.mediaRecorder) {
        this.state.selection = 'chat'
        return this.stopRecording();
      } else {
        this.state.callActive.answer();
      }
      return Promise.resolve();
    }
    this.state.callActive.answer()
    return Promise.resolve();
  }

  onRemoteVideoVisible = (width) => {
    const prev = this.state.remoteVideoWidth || 0;
    this.setState({
      remoteVideoWidth: width
    });
    debugLog("WIDTH: ", width);
    if (width > 0) {
      if (!prev) {
        callJoin.play();
      } else {
        const res = w => w >= 3840 ? 3840 : w >= 1920 ? 1920 : w >= 1280 ? 1280 : w >= 640 ? 640 : 480;
        //debugLog("res prev: ", prev, " => ", res(prev));
        //debugLog("res: ", width, " => ", res(width));
        if (res(prev) != res(width)) { 
          notShort.play();
        }
      }
    }
  }

  toggleCallActive = () => {
    debugger
    if (this.state.callActive) {
      if (this.state.callActive.recording) {
        this.state.selection = 'chat'
      }
    }
    //debugger;
    return this.state.callActive ? this.endCall() : this.makeCall();
  }

  onAppointmentEnded = msg => {
    this.forceUpdate();
  }

  onChatCreated = chat => {
    this.chat = chat;
    if (this.openSubscriptionRequested) {
      chat.subscribeToChat();
    }
  }

  scrollBack = ts => {
    console.log('scrollback', ts)
    if (ts && this.cursor != ts) {
      this.cursor = ts;
      this.messageCount += this.messagePageSize;
      this.state.chat.getHistory(ts, this.messagePageSize);
      this.updateMessagesNow();
    }
  }

  options = [
    {
      selector: 'cal',
      label: 'Calendar',
      icon: () => CalendarIcon,
      className: 'calendarRadioIcon'
    },
    {
      selector: 'chat',
      label: 'Chat',
      icon: () => ChatIcon,
      content: selected => {
        return <div className='uiChatRadioButton'>
          <div className='fdButtonIcon'><ReactSVG src={ChatIcon}/></div>
          <div className={'uiSideListContactUnread'+ (this.props.unreadCount ? this.props.unreadCount > 99 ? ' lbContactUnread99Plus':  '' : ' uiUnreadCountZero')}>
          {this.props.unreadCount > 99 ? '99+' : this.props.unreadCount}
          </div>
          </div>
      }
    },
    {
      selector: 'blackboard',
      label: 'Blackboard',
      icon: () => BlackboardIcon,
      className: 'blackboardRadioIcon'
    },
    {
      selector: 'video',
      label: 'Call',
      icon: () => this.state.callActive && this.state.callActive.recording ? VideoIcon : CallIcon
    }
  ]

  profileButton = 
    {
      selector: 'profile',
      label: 'Profile',
    }


  selectOption = button => {
    const updates = {
      selection: button.selector
    }
    let then
    if (button.selector == 'video') {
      this.loadLocalStream().then(() => {
      })
    } else if (!this.state.callActive && this.state.localVideoStream) {
      this.closeLocalVideoStream()
    }
    this.setState(updates, then)
  }

  shareWhoopData = () => {
  }

  renderSettings = (visible) => {
    if (this.props.to.isGroup) {
      return this.renderGroupSettings(visible)
    } else {
      return this.renderIndividualSettings(visible)
    }
  }


  offerSubscription = async () => {
  }

  manageSubscription = async () => {
    this.setState({
      manageSubscription: true
    })
  }

  manageClientSubscription = async () => {
  }

  renderIndividualSettings = (visible) => {
    const toggleDataShared = () => {
      this.setState({
        shareData: !this.state.shareData
      })
    }
    const onChange2 = e => {
      this.setState({
        isTraining: e.target.checked
      })
    }
    const back = () => {
      this.setState({
        settingsPage: null
      })
    }

    let subpage
    if (this.state.settingsPage) {
      subpage = this.state.settingsPage()
    }

    let ok
    let label
    let okIcon = Activity

    let card

    const createCard = (sub, isOrganizer, organizer, with_) => {
      let start = sub.offerTime
      let end = 0
      return <UISubscription
      editable={isOrganizer}
      invoiceAmount={sub.invoiceAmount}
      isChat={true}
      state={sub.state}
      hideWith={true}
      id={sub.uid + "-" + sub.client}
      organizer={organizer}
      onClick={()=>this.subscribeToTraining()}
      responseTime={sub.responseTime}
      start={start}
      end={end}
      paymentMethod={sub.paymentMethod}
      description={sub.description}
      invoiceDescription={sub.invoiceDescription}
      openChat={null}
      with={with_}
        />
    }

    if (this.state.clientSubscription) {
      if (this.state.clientSubscription.status == 'offer') {
        label = "Start Training with " +this.state.to.displayName
      } else {
        label = "Manage Subscription"
        card = createCard(this.state.clientSubscription, false, this.props.to, this.props.me.self)
      }
    } else if (this.state.providerSubscription) {
      label = "Manage Subscription"
      card = createCard(this.state.providerSubscription, true, this.props.me.self, this.props.to)
    } else {
      ok = async () => {
        await this.subscribeToTraining()
      }
      if (this.props.me.self.isTrainer) {
        label = "Train " + this.state.to.displayName
      } else {
        ok = null
      }
    }
    return <div className='uiActiveContactSettings' style={visible ? null: {display: 'none'}}>
      <div className='uiActiveContactSettingsFields'>
      <UINotificationSetting label={"Share my fitness data with "+this.state.to.displayName} value={this.state.shareData} checked={this.state.shareData} toggle={toggleDataShared}/>
      {card && <div key='card' className='uiActiveContactSettingsSubscriptionCard'>
      {card}
      </div>}
      {ok && <div key='button1' className='uiActiveContactSettingsSubscription'>
      <UIOKCancel label={label} ok={ok} okIcon={okIcon}/>
      </div>}
      {this.renderRemoveContact()}
      </div>
      </div>
  }


  onChangeSubscription = (field, value) => {
  }

  renderSubscription = () => {
    let title = "Training with " + this.props.to.displayName
    return <UISubscribeToChat
    title={title}
    editable={!this.state.clientSubscription &&
              (!this.state.providerSubscription || this.state.providerSubscription.state != "active")}
    isNew={!this.state.providerSubscription && !this.state.clientSubscription}
    small={!this.state.clientSubscription}
    me={this.props.me}
    state={this.state.openSubscription.state}
    with={this.props.to}
    withReadOnly={true}
    description={this.state.openSubscription.description}
    responseTime={this.state.openSubscription.responseTime}
    startDate={new Date(this.state.openSubscription.offerTime)}
    on={new Date(this.state.openSubscription.offerTime)}
    invoiceDescription={this.state.openSubscription.invoiceDescription}
    invoiceAmount={this.state.openSubscription.invoiceAmount}
    client={this.state.clientSubscription}
    onChange={this.onChangeSubscription}
    back={this.dismissTrainingSubscription}
    accept={this.acceptSubscription}
    decline={this.cancelSubscription}
    update={this.updateSubscription}
    cancel={this.cancelSubscription}
      />
  }

  acceptSubscription = () => {
    return this.props.me.acceptSubscription(this.props.to).then(result => {
      if (!result.error) {
        this.dismissTrainingSubscription();
      }
      return result;
    });
  }

  declineSubscription = () => {
    return this.props.me.declineSubscription(this.props.to).then(result => {
      if (!result.error) {
        this.dismissTrainingSubscription();
      }
      return result;
    });
  }

  updateSubscription = (form) => {
    debugger
    const fields = ["description", "invoiceAmount", "invoiceDescription",
                    "startDate", "responseTime"];
    const updates = {};
    fields.map(field => {
      switch (field) {
        case 'startDate':
          {
            const startDate = form.startDate
            if (startDate.getTime() !== this.state.providerSubscription.offerTime) {
              updates.offerTime = startDate.getTime()
            }
          }
          break
        default:
          {
            let value = form[field];
            if (!this.state.providerSubscription || this.state.providerSubscription[field] != value) {
              updates[field] = value;
            }
          }
          break
      }
    });
    debugLog("form: ", form);
    debugLog("updates: ", updates);
    let p;
    if (this.state.providerSubscription) {
      if (this.state.openSubscription.state != 'offer') {
        updates.state = 'offer'
      }
      p = this.props.me.updateSubscription(this.props.to, updates);
    } else {
      p = this.props.me.offerSubscription(this.props.to, updates);
    }
    return p.then(result => {
      if (!result.error) {
        this.dismissTrainingSubscription();
      }
      return result;
    });
  }

  cancelSubscription = () => {
    let p;
    if (this.state.clientSubscription) {
      p = this.props.me.cancelClientSubscription(this.props.to);
    } else {
      p = this.props.me.cancelSubscription(this.props.to);
    }
    return p.then(this.dismissTrainingSubscription);
  }

  subscribeToTraining = () => {
    let openSubscription;
    let startTime = Date.now()
    if (this.state.clientSubscription) {
      if (this.state.clientSubscription.state === 'active') {
        return this.cancelSubscription()
      }
      openSubscription = clone(this.state.clientSubscription);
    } else if (this.state.providerSubscription) {
      if (this.state.providerSubscription.state === 'active') {
        return this.cancelSubscription()
      }
      if (this.state.providerSubscription.state == 'offer') {
        openSubscription = clone(this.state.providerSubscription);
      }
    }
    if (!openSubscription) {
      openSubscription = {
        responseTime: 1,
        description: "Virtual Training",
        invoiceAmount: 150,
        offerTime: Date.now()
      }
    }
    this.setState({
      addTrainingSubscription: !!openSubscription,
      openSubscription: openSubscription
    }, () => {
      if (this.props.openPopup) {
        this.props.openPopup(() => this.renderSubscription())
      }
    })
  }

  dismissTrainingSubscription = () => {
    this.setState({
      addTrainingSubscription: false,
      openSubscription: null
    });
    if (this.props.openPopup) {
      this.props.openPopup(null)
    }
  }
  
  performRemoveContact = async () => {
    await this.props.removeContact()
    this.setState({
      confirmRemoveContact: false
    })
  }


  renderRemoveContact = () => {
    if (this.state.to.uid === this.props.me.self.uid) return null
    let okIcon = this.state.to.group ? Group : Profile
    let actionLabel = this.state.to.group ? "Leave Group" : "Remove Contact"
    let confirmIcon = this.state.to.group ? SignOut : Trash
    let confirmLabel = this.state.to.group ?
        "Confirm leaving this group" : "Confirm removing this contact"
    let cancelIcon
    let cancelAction
    const okAction = async () => {
      const remove = this.state.confirmRemoveContact
      this.setState({
        confirmRemoveContact: !remove
      })
    }
    const onStopHover = () => {
      this.setState({
        confirmRemoveContact: false
      })
    }
    if (this.state.confirmRemoveContact) {
      okIcon = Trash
    }
    return <div className='uiRemoveContact'>
      <UIOKCancel okIcon={okIcon} cancelIcon={cancelIcon} label={actionLabel} ok={okAction} cancel={cancelAction}/>
      {this.state.confirmRemoveContact && <ClickAwayListener onClickAway={onStopHover}><div key='confirm' className='uiRemoveContactConfirm' onMouseLeave={onStopHover}>
      <UIOKCancel ok={this.performRemoveContact} okIcon={confirmIcon} label={confirmLabel}/>
      </div></ClickAwayListener>}
      </div>
  }

  leaveGroup = async () => {
    await this.props.leaveGroup()
  }

  deleteGroup = async () => {
    await this.props.deleteGroup()
  }

  manageGroup = async () => {
    if (isMobile()) return this.props.manageGroup(this.state.to.group)
    this.setState({
      createGroup: true
    })
  }

  closeGroup = () => { 
    this.setState({
      createGroup: false
    })
  }

  renderGroupSettings = (visible) => {
    const onModifyGroup = () => {
    }
    const g = this.state.to.group
    if (g.organizer === this.props.me.self.uid) {
      let ok = this.manageGroup
      return <div className='uiManageGroupSettings' style={visible ? null : { display: 'none'}}>
        <UIOKCancel okIcon={Group} label="Manage Group" ok={ok}/>
        {false && this.renderIndividualSettings(visible)}
        </div>
    } else {
        return <div className='uiManageGroupSettings' style={visible ? null : { display: 'none'}}>      
        {this.renderRemoveContact()}
      </div>
    }
  }

  renderVideoStreams = () => {
    if (!this.state.callActive) return null
    const isNonOrganizerGroup = this.state.to.isGroup
    const remoteStreams = this.state.remoteVideo
    const contact = this.state.to
    const remoteScreenShare= this.state.remoteScreenShare || this.state.groupScreenShare
    const remoteComposite= this.state.remoteComposite || isNonOrganizerGroup
    const onDeviceSettingsChanged = info => {
    }
    const streamsVisible = {}
    const onStreamVisible = (width, height, index) => {
      debugLog("on stream visible: ", width);
    }
    let style;
    let className = !isMobile(true) ? 'uiRemoteVideoStreamsDesktop': 'uiRemoteVideoStreamsMobile';
    let className1 = 'uiRemoteVideoStreamContainer';
    let contained =remoteComposite;
    if (remoteStreams.length > 1) {
      className += " uiRemoteVideoGroupLayout";
    }
    let width;
    if (remoteStreams.length) {
      if (isMobile()) {
        width = window.innerWidth / 4 / remoteStreams.length
      } else {
        width = 240/remoteStreams.length;
      } 
      style = {};
      className1 += " uiRemoteStreamsBlackboard";
    } else {
      const dims = remoteStreams.map((info, i) => {
        const stream = info.stream;
        let setting = stream.getVideoTracks()[0].getSettings();
        if (!setting.width) {
          const call = info.call;
          const device = call.getRemoteDevice();
          if (device) {
            setting = device;
          }
        }
        return setting;
      });
      style = getGridStyle(dims, this.containerRef);
    }
    let screenShare;
    if (contact.isGroup) {
      screenShare = remoteStreams.find(info => info.call.isSharingScreen());
    }
    return <div ref={this.setContainerRef} key='streamsContainer' className={className1}>
      <div key='streams' className={className} style={style}>
      {remoteStreams.map((info, i) => {
        const stream = info.stream;
        let audioOnly = screenShare && screenShare != info;
        return <UIVideoStream
        index={i}
        compositor={remoteStreams.length > 1}
        width={width}
        contact={info.contact}
        audioOnly={audioOnly}
        onDeviceSettingsChanged={onDeviceSettingsChanged}
        onStreamVisible={onStreamVisible}
        contained={contained}
        composite={remoteComposite}
        info={info}
        remoteScreenShare={info.call.isSharingScreen() || remoteScreenShare} stream={stream}
          />
      })}
    </div>
      </div>
  }
  openEvent = event => {
    if (true ||( event.appt && (event.appt.sleep || event.appt.workout || event.appt.meal))) {
      this.setState({
        openEvent: event
      })
    }
  }

  closeEvent = () => {
    this.setState({
      openEvent: null
    })
  }

  closeProfile = () => {
    this.setState({
      openProfile: null
    })
  }

  openSummary = (title, url) => {
    this.setState({
      openSummaryTitle: title,
      openSummaryURL: url
    })
  }

  closeDocument = () => {
    this.setState({
      openSummaryURL: null,
      openDocumentNumPages: 0
    })
  }
  
  renderPage = () => {
    let subpage
    let popup
    if (this.state.openProfile) {
      subpage = <FDPage embed={true} title={this.state.openProfile.category.name} me={this.props.me} back={this.closeProfile}>
        <DataCharts options={
          {
            category: this.state.openProfile.category,
            type: this.state.openProfile.type,
            cycleData: this.state.openProfile.cycleData,
            meals: this.state.openProfile.meals,
            weights: this.state.openProfile.weights,
          }
        }/>
        </FDPage>
    }
    else if (this.state.openSummaryURL) {
      const onLoad = ({numPages}) => {
        debugger
        this.setState({
          openDocumentNumPages: numPages
        })
      }
      const onLoadErr = e => {
        debugger
      }
      const pages = []
      if (this.state.openDocumentNumPages) {
        for (let i = 0; i < this.state.openDocumentNumPages && i < 5; i++) {
          pages.push(i+1)
        }
      }
      const loading = <div className='uiPDFLoading'>
            <ReactSVG src={Spinner}/>
            </div>
      subpage = <FDPage safeArea embed={true} title={this.state.openSummaryTitle} me={this.props.me} back={this.closeDocument}>
        <Document loading={loading} file={this.state.openSummaryURL} onLoadSuccess={onLoad} onLoadError={onLoadErr} renderMode={'svg'}>
        {pages.map(i => <Page key={i} pageNumber={i}/>)}
        </Document>
      </FDPage>
    }    
    if (this.state.openEvent) {
      if (this.state.openEvent.appt && this.state.openEvent.appt.sleep) {
        const contact = this.state.openEvent.appt.organizer
        subpage = <FDPage safeArea embed={true} title="Sleep" me={this.props.me} back={this.closeEvent}>
          <WhoopSleep sleep={this.state.openEvent.appt.sleep} contact={contact} me={this.props.me}/>
          </FDPage>
      } else if (this.state.openEvent.appt && this.state.openEvent.appt.workout && !this.state.openEvent.appt.workout.scheduled) {
        const contact = this.state.openEvent.appt.organizer
        subpage = <FDPage embed={true} title="Workout" me={this.props.me} back={this.closeEvent}>
          <WhoopWorkout workout={this.state.openEvent.appt.workout} contact={contact} me={this.props.me}/>
          </FDPage>
      } else if (this.state.openEvent.appt && this.state.openEvent.appt.meal) {
        const appt = this.state.openEvent.appt;
        const meal = appt.meal
        const contact = this.state.openEvent.appt.contact
        const when = formatDate(new Date(appt.start))
        let foodPage
        if (this.state.openEventFood) {
          const food = this.state.openEventFood
          const close = () => this.setState({
            openEventFood: null
          })
          foodPage = <FDPage safeArea embed={true} title="Food" me={this.props.me} back={close}>
            {renderFoodProfile({
              me: this.props.me,
              title: getFoodName(food),
              subtitle: '',
              foods: [food]
              })}
          </FDPage>
        }
        subpage = <FDPage embed={true} title="Meal" me={this.props.me} back={this.closeEvent} subPage={foodPage}>
          {renderFoodProfile({
            me: this.props.me,
            title: capitalize(meal.type),
            subtitle: when,
            foods: meal.foods,
            onClick: food => {
              this.setState({
                openEventFood: food
              })
            }
          })}
          </FDPage>
      } else {
        popup = this.renderAppointmentPopup(true)
      }
    }
    if (this.state.createGroup) {
      popup = <UICreateGroup me={this.props.me} group={this.state.to.group} close={this.closeGroup} />
    }
    if (this.state.addTrainingSubscription && !isMobile()) {
      popup = <FDPopup className='uiSubscriptionPopup' noHeader={true}>{this.renderSubscription()}</FDPopup>
    }
    if (this.state.openPopup) {
      popup = [popup, <FDPopup className='uiSubscriptionPopup' noHeader={true}>{this.state.openPopup()}</FDPopup>]
    }
    return <div key={this.state.to.uid} className='uiActiveContactViewOptions' style={this.props.selected ? null : {display: 'none'}}>
      <div className='uiActiveContactViewInner'>
      <FDPage noHeader={true} me={this.props.me} subPage={subpage} popup={popup}>
      <div className='uiActiveContactViewNonMobile'>
      {this.renderSelf()}
    </div>
      </FDPage>
      </div>
      </div>
  }

  renderAppointmentPopup = (withReadOnly) => {
    if (!this.state.openEvent ||
        (this.state.openEvent.appt && (
          this.state.openEvent.appt.sleep
            ||
            this.state.openEvent.appt.meal
            ||
            this.state.openEvent.appt.weight
        ))) return null
    return <div className='uiScheduleAppointmentPopup'>
      <UIScheduleAppointment
    openPopup={this.openPopup}
    appt={this.state.openEvent.appt}
    appointmentId={this.state.openEvent.id}
    back={() => this.setState({
      openEvent: null,
    })}
    scope={this.state.openEvent.scope}
    isNew={this.state.openEvent.isNew}
    withReadOnly={withReadOnly}
    date={this.state.openEvent.date}
    start={this.state.openEvent.start}
    end={this.state.openEvent.end}
    headerTitle={"Schedule Appointment"}
    title={this.state.openEvent.title}
    with={this.state.openEvent.with}
    on={this.state.openEvent.date}
    trash={this.trashEvent}
    accept={()=>this.acceptEvent(this.state.openEvent.appt)}
    decline={()=>this.declineEvent(this.state.openEvent.appt)}
    schedule={this.scheduleAppointment}
    error={this.state.openEvent.error}
    onChange={this.onChangeEvent}
    editable={this.state.openEvent.editable}
    client={this.state.openEvent.client}
    invoiceAmount={this.state.openEvent.invoiceAmount || 0}
    invoiceDescription={this.state.openEvent.invoiceDescription}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    status={this.state.openEvent.status}
    paymentStatus={this.state.openEvent.paymentStatus}
    paymentMethod={this.state.openEvent.paymentMethod}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    event={this.state.openEvent}
    me={this.props.me}
      />
      </div>
  }

  trashEvent = async () => {
    const appt = this.state.openEvent.appt
    if (appt && appt.scheduledWorkout) {
      await this.props.me.deleteWorkout(appt.scheduledWorkout.id)
    }
    this.setState({
      openEvent: null
    })
  }
  
  

  render() {
    return isMobile() ? this.renderSelf() : this.renderPage()
  }

  renderSelf() {
    const options = this.options.filter(opt => opt.selector !== 'cal' || this.state.dataShared)
    debugLog('options', options, 'dataShared', this.state.dataShared)
    return <div key={this.state.to.uid} className='uiActiveContactViewOptions' style={this.props.selected ? null : {display: 'none'}}>
      <div className='uiActiveContactViewInnerTitle'>{this.state.to.displayName}</div>
      <div className='uiActiveContactViewOptionButtons'>
      <FDRadioButtons buttons={options} select={this.selectOption} selection={this.state.selection}/>
      <div className={'uiActiveContactViewProfileButton' + (this.state.selection === 'profile' ?  ' uiActiveContactViewProfileButtonSelected': '')} onClick={() => this.selectOption(this.profileButton)}><UIProfileIcon contact={this.state.to}/></div>
      </div>
      <div className='uiActiveContactViewContent'>
    {this.renderChat(this.state.selection == 'chat')}
    {this.renderCalendar(this.state.selection == 'cal')}
    {this.renderWhiteboard(this.state.selection == 'blackboard')}
    {this.renderVideo(this.state.selection == 'video')}
    {this.renderSettings(this.state.selection == 'profile')}
    </div>
      </div>
  }

  setCal = cal => {
    this.cal = cal
  }

  onCalendarClick = e => {
  }

  onLoadCycleData = (cycleData) => {
    debugLog("cycleData: ", cycleData)
    this.setState({
      cycleData: cycleData
    })
  }

  onLoadMeals = meals => {
    this.setState({
      meals: meals
    })
  }

  onClickProfile = (profile, cycleData, meals, weights, category) => {
    this.setState({
      openProfile: {type: profile.type, profile, cycleData, meals, weights, category}
    })
  }

  renderCalendar = visible => {
    if (false && !this.state.dataShared) {
      return <div key='cal' className='uiActiveContactCalendar uiActiveContactCalendarNotShared' style={visible ? null: {display: 'none'}}/>
    }
    let opts = this.props.calendarOpts
    if (!isMobile()) {
      opts = {
        openEvent: this.openEvent,
        closeEvent: this.closeEvent,
        disableAppointmentPopup: true,
        openPopup: this.openPopup
      }
      opts.onLoadCycleData = this.onLoadCycleData
      opts.onLoadMeals = this.onLoadMeals
      opts.onClickProfile = this.onClickProfile
      opts.openSummary = this.openSummary
    }
    console.log("calendarOpts", opts)
    const videoStream = visible && this.renderVideoStreams()
    opts.videoStream = videoStream
    return <div key='cal' className='uiActiveContactCalendar' style={visible ? null: {display: 'none'}}>
      <UICalendar initialPage='day' opts={opts} contactFilter={this.state.to} picker={false} visible={visible} onSet={this.setCal} me={this.props.me}/>
      </div>
  }


  openPopup = popup => {
    if (this.props.openPopup) return this.props.openPopup(popup)
    this.setState({
      openPopup: popup
    })
  }
  

  renderWhiteboard = visible => {
    if (!this.state.chat) {
      return <div key='whiteboard' className='uiActiveContactBlackboard' style={visible ? null: {display: 'none'}}/>
    }
    return <div key='whiteboard' className='uiActiveContactBlackboard' style={visible ? null: {display: 'none'}}>
      {visible && this.renderVideoStreams()}
      <UIBlackboard 
    uploadFile={this.state.chat.uploadFile}
    shareFile={this.state.chat.uploadFile}
    sendCanvasData={this.sendCanvasData}
    observeCanvasData={this.observeCanvasData}
    observeWhiteboard={this.observeWhiteboard}
    saveWhiteboard={this.saveWhiteboard}/>
      </div>
  }

  toggleCall = () => {
    this.toggleCallActive()
  }

  onDeviceSetupChanged = (field, value) => {
    debugLog("device setup changed", field, value)
    switch (field) {
      case 'audioinput':
        this.setAudioInput(value)
        break
      case 'audiooutput':
        break
      case 'videores':
        this.setLocalVideoResolution(value)
        break
      case 'videoinput':
        this.setVideoInput(value).catch(err => {

        })
        break
    }
  }


  renderVideo = (visible) => {
    if (!this.state.chat) return null
    const isNonOrganizerGroup = this.state.to.isGroup;
    let videoStyle
    if (!this.state.localVideoStream) {
      return <div className='uiActiveContactVideo' style={visible ? null: {display: 'none'}}/>
    }
    return <div key='video' className='uiActiveContactVideo' style={visible ? null: {display: 'none'}}>
      <UIRemoteVideo remoteStreams={this.props.remoteStreams}
    onDeviceSetupChanged={this.onDeviceSetupChanged}
    uploadImage={this.state.chat.uploadFile}
    uploadFile={this.state.chat.uploadFile}
    visible={visible}
    observeCanvasData={this.observeCanvasData}
    sendCanvasData={this.sendCanvasData}
    observeWhiteboard={this.observeWhiteboard}
    saveWhiteboard={this.saveWhiteboard}

    setLocalVideoResolution={this.setLocalVideoResolution}
    localVideoResolution={this.getVideoRes()}

    localVideoStream={this.state.localVideoStream}
    remoteStreams={this.state.remoteVideo}

    joinCall={() => this.callJoin()}
    remoteScreenShare={this.state.remoteScreenShare || this.state.groupScreenShare}
    remoteComposite={this.state.remoteComposite || isNonOrganizerGroup}
    audioMuted={this.state.audioMuted} toggleAudioMuted={this.toggleAudioMuted}
    videoMuted={this.state.videoMuted} toggleVideoMuted={this.toggleVideoMuted}
    screenShare={this.state.screenShare} toggleScreenShare={this.toggleScreenShare}
    fullScreen={this.state.fullScreen} toggleFullScreen={this.toggleFullScreen}
    yourVideoHidden={this.state.yourVideoHidden} toggleYourVideoHidden={this.toggleYourVideoHidden}
    toggleCallActive={this.toggleCallActive}
    callActive={this.state.callActive}
    call={this.state.calls.length > 0 ? this.state.calls[0] : null}
    endCall={()=> this.endCall(null)}
    makeCall={this.makeCall}
    close={this.closeContact}
    height={this.state.callHeight}
    onStreamVisible={this.onRemoteVideoVisible}
    makeCall={this.makeCall}
    hangup={()=>this.props.endCall()}
    contact={this.state.to}
    me={this.props.me}
    audioMuted={this.state.audioMuted} toggleAudioMuted={this.toggleAudioMuted}
    videoMuted={this.state.videoMuted} toggleVideoMuted={this.toggleVideoMuted}
    screenShare={this.state.screenShare} toggleScreenShare={this.toggleScreenShare}
    fullScreen={this.state.fullScreen} toggleFullScreen={this.toggleFullScreen}
    yourVideoHidden={this.state.yourVideoHidden} toggleYourVideoHidden={this.toggleYourVideoHidden}
    yourMediaStream={this.state.localVideoStream}
      />
      </div>
  }

  modifyWorkout = async (form) => {
    const data = this.state.openEvent;
    const { activity, description, demoFile, sets, reps, weight, sport } = form
    let { demo} = form
    const appt = data.appt
    const prev = appt ? appt.workout : null
    const date = data.date;
    if (demoFile) {
      const ref = await this.props.me.uploadFile(demoFile)
      const downloadURL = await ref.getDownloadURL()
      demo = [{
        contentType: demoFile.type,
        downloadURL
      }]
    }
    if (!demo) {
      if (prev) {
        demo = prev.demo
      }
    }
    const workout = {
      description,
      sets,
      reps,
      weight,
      demo: demo,
      activity: activity
    }
    if (prev) {
      workout.id =  prev.id
    }
    const uid = prev ? prev.client : this.state.openEvent.with.uid
    await this.props.me.saveWorkout({uid: uid}, workout)
  }
  
  scheduleAppointment = (form) => {
    const data = this.state.openEvent;
    if (data.scope == 'meals' || (data.appt && data.appt.meal)) {
      return this.scheduleMeal(form).then(() => {
        debugLog('saved meal')
        this.setState({
          openEvent: null
        })
      })
    }
    if (data.scope == 'workouts' || (data.appt && data.appt.workout)) {
      return this.modifyWorkout(form).then(() => {
        this.setState({
          openEvent: null
        })
      })
    }
    let p;
    if (data.appt) {
      p = this.rescheduleAppointment(form);
    } else  {
      const contact = data.with;
      const date = form.start;
      const start = form.start;
      const end = form.end;
      const updates = {
        start: getTime(date, start),
        end: getTime(date, end),
        title: form.title,
        invoiceDescription: form.invoiceDescription,
        invoiceAmount: form.invoiceAmount,
        title: form.title,
      }               
      window.showProgressIndicator("Scheduling");
      p = this.props.me.createAppointment(contact, updates);
    }
    return p.then(() => {
      this.setState({
        openEvent: null,
        addAppointment: null,
        calendarDate: null,
      });
    }).catch(err => {
      this.state.openEvent.error = err.message
      this.forceUpdate()
    })
  }
  
  rescheduleAppointment = appt => {
    if (appt.workout) {
      const openEvent = (this.props.calendarOpts && this.props.calendarOpts.openEvent) || this.openEvent
      const status = appt.status || "requested";
      const id = appt.id;
      const dur = appt.end - appt.start;
      const contact = appt.contact;
      const title = contact.displayName;
      const start = appt.start || appt.scheduled
      const end = appt.end || Date.now()
      const event = {
        scope: 'workouts',
        id: id,
        start: new Date(start),
        end: new Date(end),
        text: title,
        desc: title,
        status: status,
        ts: Date.now(),
        appt: appt
      }
      openEvent(event)
      return Promise.resolve()
    }
    return this.props.rescheduleAppointment(appt)
  }


  renderChat(visible) {
    const isNonOrganizerGroup = this.state.to.isGroup;
    //debugLog("isNonOrganizerGroup: ", isNonOrganizerGroup);
    if (!this.state.chat) return <div key='chat' className='uiChatEmpty'/>
    return <div key='chat' className='uiActiveContactBlackboard' style={visible ? null: {display: 'none'}}>
      <UIOpenContactView
    dataShared={this.state.dataShared}
    subscribeToChat={this.openSubscription}
    scheduleChatAppointment={this.props.scheduleChatAppointment}
    observeCanvasData={this.observeCanvasData}
    sendCanvasData={this.sendCanvasData}
    observeWhiteboard={this.observeWhiteboard}
    saveWhiteboard={this.saveWhiteboard}
    mostRecent={this.state.mostRecent}
    scrollBack={this.scrollBack}
    recordMessage={this.recordMessage}
    onChatCreated={this.onChatCreated}
    onAnimationComplete={this.onAnimationComplete}
    visible={visible && this.props.selected}
    openEvent={(this.props.calendarOpts && this.props.calendarOpts.openEvent) || this.openEvent}
    removeContact={this.props.removeContact}
    onAppointmentEnded={this.onAppointmentEnded}
    setLocalVideoResolution={this.setLocalVideoResolution}
    waitForSystemUpdate={this.waitForUpdate}
    onStreamVisible={this.onRemoteVideoVisible}
    scheduleAppointmentWith={this.props.scheduleAppointmentWith}
    rescheduleAppointment={this.rescheduleAppointment}                    
    showSystemProgressIndicator={this.showSystemProgressIndicator}
    key={this.state.to.uid}
    localVideoStream={this.state.localVideoStream}
    remoteStreams={this.state.remoteVideo}
    joinCall={() => this.callJoin()}
    recording={false}
    remoteScreenShare={this.state.remoteScreenShare || this.state.groupScreenShare}
    remoteComposite={this.state.remoteComposite || isNonOrganizerGroup}
    audioMuted={this.state.audioMuted} toggleAudioMuted={this.toggleAudioMuted}
    videoMuted={this.state.videoMuted} toggleVideoMuted={this.toggleVideoMuted}
    screenShare={this.state.screenShare} toggleScreenShare={this.toggleScreenShare}
    fullScreen={this.state.fullScreen} toggleFullScreen={this.toggleFullScreen}
    yourVideoHidden={this.state.yourVideoHidden} toggleYourVideoHidden={this.toggleYourVideoHidden}
    toggleCallActive={this.toggleCallActive}
    callActive={this.state.callActive}
    call={this.state.calls.length > 0 ? this.state.calls[0] : null}
    endCall={()=> this.endCall(null)}
    makeCall={this.makeCall}
    closeContact={this.closeContact}
    reactToChatMessage={this.reactToMessage}
    deleteChatMessage={this.deleteMessage}
    setPopupShowing={x => this.setState({popupShowing: x})}
    uploadFile={this.state.chat.uploadFile}
    shareFile={this.state.chat.uploadFile}
    localContact={this.props.me.self}
    contact={this.state.to}
    me={this.props.me}
    messages={this.state.messages}
    downloadFile={this.state.chat.downloadFile}
    saveMessage={this.saveMessage}
    sendMessage={text => {
      const chat = this.state.chat;
      const msg = chat.createMessage(text);
      this.onChatMessage("added", msg);
      chat.sendMessage(msg).then(() => {
        this.chat.scrollToBottom();
      });
    }}
    typing={()=>this.state.chat.typing()}
    observeTyping={()=>this.state.chat.observeTyping()}
    decline={call=>this.answerIncomingCall(call, true)}
    answer={call=>this.answerIncomingCall(call, false)}
    isSameChannel={this.isSameChannel}
    incomingCalls={this.state.incomingCall}
    selectedContact={this.state.to}
      />
      {visible && this.renderVideoStreams()}
    </div>
  }
}


