/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { getPortal } from '../Client'
import './index.css'

export class FDOKCancel extends Component {
  render () {
    const portalRef = getPortal()
    return ReactDOM.createPortal(this.renderMe(), portalRef)
  }

  renderMe () {
    let className = 'fdOKCancel'
    if (this.props.ok && this.props.cancel) {
      className += ' fdOKCancelTwoButtons'
    }
    return (
      <div className='fdOKCancelScreen'>
        <div className={className}>
          <div className='fdOKCancelBody'>
            <div className='fdOKCancelHeader'>
              {this.props.title}
            </div>
            <div className='fdOKCancelMessage'>
              {this.props.message}
            </div>
          </div>
          <div className='fdOKCancelButtons'>
            {this.props.ok && <div onClick={this.props.ok} className='fdOKCancelButton fdOK'><div className='fdOKCancelOKLabel'>{this.props.okLabel || 'OK'}</div></div>}
            {this.props.cancel && <div onClick={this.props.cancel} className='fdOKCancelButton fdCancel'><div className='fdOKCancelCancelLabel'>{this.props.cancelLabel || 'Cancel'}</div></div>}
          </div>
        </div>
      </div>
    )
  }
}
