/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { FDPage } from '../Page'
import { FDPopup } from '../Popup'
import { FDButton1 } from '../Button'
import mobiscroll from '@mobiscroll/react'
import QrReader from 'react-qr-reader'
import QRScanTarget from '../../assets/Icons/QRScanTarget.svg'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import './index.css'
import moment from 'moment'
import { BrowserCodeReader, BrowserMultiFormatOneDReader } from '@zxing/browser'
import { BarcodeFormat, DecodeHintType } from '@zxing/library'
let videoId = 0

class ZXingScanner extends Component {
  constructor (props) {
    super(props)
    this.videoId = `zxing-${++videoId}`
  }

  componentDidMount () {
    const hints = new Map()
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [true])
    console.log("hints:", hints)
    const codeReader = new BrowserMultiFormatOneDReader(hints)
    BrowserCodeReader
      .listVideoInputDevices()
      .then(videoInputDevices => {
        codeReader.decodeFromVideoDevice(videoInputDevices[0].deviceId, this.videoRef, (result, err, controls) => {
          console.log("err", err)
          console.log("result", result)
          if (!err) {
            this.props.onScan(result.text)
            controls.stop()
          }
        }).then(controls => {
          this.controls = controls
        })
      })
  }

  componentWillUnmount () {
    this.controls.stop()
  }

  setVideoRef = ref => {
      if (ref) {
        this.videoRef = ref
      }
  }

  render () {
    return (
        <div className='fdZXingScanner'>
        <video ref={this.setVideoRef} id={this.videoId} />
        </div>
    )
  }
}

export const formatScanTime = timestamp => {
  if (timestamp instanceof String) {
    timestamp = Date.parse(timestamp)
  }
  if (!(timestamp instanceof Date)) {
    timestamp = new Date(timestamp)
  }
  return moment(timestamp).format('h:mm:ss a MMM Do')
}

export class FDQRCodeInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      code: this.props.code || '',
      err: ''
    }
  }

  componentDidMount () {
    if (this.props.me.isNative()) {
      this.props.me.readQRCode().then(code => {
        if (!code) {
          this.props.back()
        } else {
          this.props.onCodeInput(code)
        }
      })
    } else {
      this.timeout = setTimeout(() => {
        let reader
        if (this.props.barcode) {
          reader = <ZXingScanner onScan={this.handleScan} />
        } else {
          reader = (
              <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
              />
          )
        }
        this.setState({
          reader: reader
        })
      }, 500)
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  updateCode = code => {
    this.setState({
      err: '',
      code: code
    })
    if (this.props.onChange) {
      this.props.onChange(code)
    }
  }

  onChange = e => {
    this.updateCode(e.target.value)
  }

  handleScan = data => {
    if (data) {
      this.updateCode(data)
    }
  }

  handleError = err => {
    if (err.message) {
      this.setState({
        err: 'Oof, QR Code reader failed'
      })
    }
  }

  onContinue = () => {
    if (!this.state.code) {
      this.setState({
        err: this.formatCodeType() + ' is required.'
      })
      return Promise.resolve()
    }
    if (this.busy) return
    this.busy = true
    return this.props.onCodeInput(this.state.code).then(result => {
      this.busy = false
      return result
    })
  }

  formatCodeType = () => {
    if (this.props.codeType) return this.props.codeType + '  Code'
    return 'Code'
  }

  renderPage () {
    const err = this.state.err || this.props.error
    return (
        <div className='fdQRCodeInput'>
        <div className='fdQRCodeInputQR' style={{ minHeight: Math.min(window.innerWidth / 375 * 215, 600) }}>
        <div className='fdQRCodeInputQRReader'>
        {this.state.reader}
      </div>
        <div className='fdQRCodeInputViewFinder'><ReactSVG src={QRScanTarget} /></div>
        </div>
        </div>
    )
  }

  render () {
    if (this.props.me.isNative()) {
      return null
    }
    if (this.props.popup) {
      return this.renderPopup()
    }
    return this.renderPage()
  }

  cancel = () => {
    this.props.back()
    return Promise.resolve()
  }

  renderPopup () {
    const err = this.state.err || this.props.error
    return (
        <div className='fdQRCodeInput'>
        <FDPopup title={'Scan ' + (this.props.codeType || 'Code')} cancel={this.cancel} done={this.onContinue}>
        <div className='fdQRCodeInputQR' style={{ minHeight: Math.min(window.innerWidth / 375 * 215, 600) }}>
        <div className='fdQRCodeInputQRReader'>
        {this.state.reader}
      </div>
        <div className='fdQRCodeInputViewFinder'><ReactSVG src={QRScanTarget} /></div>
        </div>
        <mobiscroll.Form theme='ios' themeVariant='light'>
        <mobiscroll.FormGroup>
        <mobiscroll.Input valid={!err} errorMessage={err} value={this.state.code} onChange={this.onChange} placeholder={this.formatCodeType()} />
        </mobiscroll.FormGroup>
        <mobiscroll.FormGroup>
        {this.props.infoMessage && <mobiscroll.FormGroupTitle>{this.props.infoMessage}</mobiscroll.FormGroupTitle>}
      </mobiscroll.FormGroup>

      </mobiscroll.Form>
        </FDPopup>
        </div>
    )
  }
}
