import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import LogoSVGDetail from '../../../assets/Vector/Detail.svg';
import { LBLogo } from '../lbLogo';
import './index.css';

export class LBGraphic extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='lbGraphic'>
        <div className='letsAnimateDetail'>
          <ReactSVG className='letsSVGDetail' src={LogoSVGDetail}/>
        </div>
        <LBLogo />
      </div>
    );
  }
}
