import { Subject, merge } from "rxjs";
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactSVG } from 'react-svg'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import {FDButton1, FDArrowField, FDInputField} from '../../Mobile/src/components/Button'
import Spinner from '../../Mobile/src/assets/Icons/spinner.svg'
import {UIProfileIcon} from "../ProfileIcon";
import {Subpages, FDPage} from '../../Mobile/src/components/Page'
import {FDSearchField} from '../../Mobile/src/components/SearchField'
import {FDPopup} from '../../Mobile/src/components/Popup'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {getTime, UICalendar} from "../Calendar";
import {FoodLogo, getFoodName, UIScheduleAppointment} from "../ScheduleAppointment";
import {UIDeviceSetup} from "../DeviceSetup";
import {UIOKCancel} from "../OKCancel";
import {formatNewContactText, UIChat} from "../Chat";
import {LBWorkoutCard} from '../WorkoutCard'
import {UIIcon} from "../Icon";
import {formatDate, formatStartEndTime, renderPaymentStatus, UIAppointment} from "../Appointment";
import Edit from "../../assets/icons/ChatEdit.svg";
import Group from "../../assets/icons/Group.svg";
import ProfileSml from "../../assets/icons/ProfileSml.svg";
import {UIInputField} from "../Home";
import Calendar from "../../assets/icons/Calender.svg";
import Forward from "../../assets/icons/Forward.svg";
import Arrow from "../../assets/icons/PointerDown.svg";
import Search from "../../assets/icons/Search.svg";
import Cross from "../../assets/icons/Cross.svg";
import PlusIcon from "../../assets/icons/Plus.svg";
import Save from "../../assets/icons/Save.svg";
import Checkmark from "../../assets/icons/CheckMark.svg";
import Hipaa from "../../assets/icons/Hipaa.svg";
import TeTeLogo from "../../assets/Assets/TeteLogo00.svg";
import LinkAsset from "../../assets/Assets/LinkAsset.png";
import LinkAssetSml from "../../assets/Assets/LinkAssetSml_3x.png";
import LinkBusinessAssetSml from "../../assets/Assets/LinkBusinessAssetSml_3x.png";
import StripeError from "../../assets/Assets/StripeError.png";
import BusinessAsset from "../../assets/Assets/BusinessAsset.png";
import StripeAsset from "../../assets/Assets/TeteStripeAsset_3x.png";
import { DataCharts, WhoopWorkout, WhoopSleep } from '../WhoopProfile'
import WhoopIcon from '../../assets/icons/Whoop.svg'
import WhoopLogo from '../../assets/icons/WhoopLogo.svg'
import OuraIcon from '../../assets/icons/Oura.svg'
import OuraActivity from '../../assets/icons/Flame.svg'
import LBLogo from '../../assets/icons/LB_Logo512.svg'
import Chat from '../../assets/icons/ChatSpace.svg'
import Profile from "../../assets/icons/Profile.svg";
import WhoopActivity from '../../assets/icons/Active.svg'
import ScaleIcon from '../../assets/icons/Scales.svg'
import Link from "../../assets/icons/Link.svg";
import MeIcon from "../../assets/icons/Me.svg";
import ToDoListIcon from "../../assets/icons/ToDoList.svg";
import Stripe from "../../assets/icons/StripeS.svg";
import Clipboard from "../../Clipboard";
import Settings from "../../assets/icons/Settings.svg";
import {UISettings} from "../Settings"
import {StripeButton} from "../../StripeButton";
import {BAA} from "./BAA";
import {isMobile, isDesktop, isSafari} from "../../Platform";
import {Whoop} from '../../classes/Whoop'
import {SummaryChart} from '../../Mobile/src/components/Data'
import validator from 'validator'
import moment from 'moment';
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import Fraction from 'fraction.js'
import { NutritionLabel } from '../Nutrition'
import { toFDA } from '../../classes/Nutritionix.js'
import { getEmojiFromTodo } from '../TodoCard'
import './index.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)

export const formatFoodCount = food => {
  const count = food.count === undefined ? 1 : food.count
  const whole = Math.floor(count)
  if (count != whole) {
    const fraction = new Fraction(count - whole).toFraction()
    return whole + ' ' + fraction
  }
  return count
}


class Trainee {
  constructor(opts) {
    const { me, contact } = opts
    this.me = me
    this.contact = contact
    this.scheduled = new Map()
    this.sub = me.observeWorkoutsIScheduled(contact.uid, 0, 0, [null, 'started']).subscribe(change => {
      const workout = change.workout
      if (change.type == 'removed') {
        this.scheduled.delete(workout.id)
      } else {
        this.scheduled.set(workout.id, workout)
      }
      if (this.scheduled.size == 0) {
      }
    })
  }

  destruct = () => {
    this.sub.unsubscribe()
  }
}


class UIMeTeTeFeed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      prompt: "What's on your mind?"
    };
    this.appts = {};
    this.messages = {};
    this.todoChat = {};
    this.inProgress = {};
    this.waiting = {};
    this.newContacts = {};
    this.scheduled = {};
    this.openContacts = {};
    this.removedContacts = {};
    this.subs = {};
  }

  waitForUpdate = appt => {
    let waiting = this.waiting[appt.id];
    if (!waiting) {
      waiting = [];
      this.waiting[appt.id] = waiting;
    }
    return new Promise((resolve, reject) => {
      waiting.push(resolve);
    });
  }

  showSystemProgressIndicator = (ts, apptId, msg) => {
    this.inProgress = {
      ts: ts,
      apptId: apptId,
    }
    window.showProgressIndicator(msg);
  }

  finishNewContact = newContact => {
    const contact = newContact.contact;
    if (this.newContacts[contact.uid]) {
      const msg = this.newContacts[contact.uid];
      this.deleteMessage(msg);
    }
    return Promise.resolve();
  }

  updateUnreadCount = () => {
    let count = 0
    for (const ts in this.messages) {
      if (ts > this.lastSystemReadTime) {
        count++
      }
    }
    debugLog("update unread count", count)
    this.setState({ unreadCount: count })
    if (this.props.setUnreadCount) {
      this.props.setUnreadCount(count)
    }
  }

  meals = {}
  
  updateMealsLater = () => {
    clearTimeout(this.mealsUpdater)
    this.mealsUpdater = setTimeout(this.updateMealsNow, 250)
  }

  today = {}

  recordMeal = async type => {
    const date = new Date()
    const now = new Date(Date.now())
    date.setHours(now.getHours())
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    const start = date
    const end = new Date(date)
    end.setHours(date.getHours() % 24 + 1)
    const with_ = this.props.me.self
    const event = {
      scope: 'meals',
      id: null,
      date: start,
      start: start,
      end: end,
      with: with_,
      isNew: true,
      title: '',
      editable: true,
      client: false,
      reschedule: false,
      invoiceDescription: "",
      invoiceAmount: 0,
      paymentIntentId: "",
      status: "",
      paymentStatus: "",
      finalPaymentMethod: null,
      paymentIntentId: "",
      mealType: type
    }
    this.props.openEvent(event)
  }


  updateMealsNow = () => {
    //    ////debugger
    if (true) return
    const dayStart = moment(new Date()).local().startOf('day').toDate().getTime()
    const dayEnd = moment(new Date()).local().endOf('day').toDate().getTime()
    const today = this.today
    const markDone = type => {
      if (type == 'dinner') {
        markDone('lunch')
      } else if (type == 'lunch') {
        markDone('breakfast')
      }
      const todo = today[type]
      if (todo && todo.msg) {
        this.delMsg(todo.msg)
        delete todo.msg
      } else {
        today[type] = {}
      }
    }
    
    const addMsg = type => {
      const todo = today[type]
      if (todo && todo.msg) {
        // already added
        return
      }
      const msg = {
        to: this.props.me.self.uid,
        system: true,
        data: {
          type: 'recordMeal',
          recordMeal: type
        },
        text: "Record your "+ type,
        ts: Date.now()
      }
      today[type] = {
        msg
      }
      this.addMsg(msg)
    }

    // check for expiration
    for (const type in today) {
      const { msg } = today[type]
      if (msg && msg.ts < dayStart) {
//        ////debugger
        this.delMsg(msg)
        delete today[type]
      }
    }
    // check if done
    for (const id in this.meals) {
      const meal = this.meals[id]
      if (meal.type == 'snack') continue
      if (meal.start > dayStart && meal.start < dayEnd) {
        markDone(meal.type)
      }
    }
    debugLog("today:", today)
    if (!today.breakfast) {
      addMsg('breakfast')
    } else if (!today.breakfast.msg && !today.lunch) {
      addMsg('lunch')
    } else if (today.lunch && !today.lunch.msg && !today.dinner) {
      addMsg('dinner')
    }
  }

  addMsg = msg => {
    console.log("me.addMsg", msg)
    if (this.chat) this.chat.markDirty(msg);
    this.messages[msg.ts] = msg;
    this.updateMessagesLater();
  }
  
  delMsg = msg => {
    console.log("me.delMsg", msg)
    if (this.chat) this.chat.markDirty(msg);
    this.deleteMessage(msg);
  }


  componentDidMount() {
    this.mealsInterval = setInterval(this.updateMealsNow, 60000 * 5)
    this.accountSub = this.props.me.observeAccount().subscribe(account => {
      if (account) {
        this.lastSystemReadTime = account.lastSystemReadTime  || 0
        this.updateUnreadCount()
      }
    })
    this.updateMessagesLater()
    {
      let removeFirstContact = () => {};
      {
        let text = "Welcome to Let's Build";
        const ts = Date.now();
        const msg = {
          system: true,
          ts: ts,
          text: text,
          data: {
            type: "welcome",
            welcome: ""
          }
        }
        this.messages[ts] = msg;
        this.removeWelcome = () => {
          delete this.messages[ts];
          this.updateMessagesNow();
        }
        const optedOut = localStorage.getItem("stripe-opt-out")  == this.props.me.self.uid;
        //debugLog("opted out: ", optedOut);
        const showBusinessSetup = () => {
          this.props.showBusinessSetup();
        }
        if (optedOut) {
          showBusinessSetup();
        }
        const sendContactMessage = () => {
          const contactMsg = {
            system: true,
            ts: ts - 1000,
            data: {
              type: 'connect',
              'connect': () =>  <UIMeContact portal={this.props.portal} me={this.props.me} type='contact' formLinkURL={this.props.formLinkURL} link={this.props.link} copyLink={this.props.copyLink}/>
                
            }
          }
          this.messages[contactMsg.ts] = contactMsg;
          removeFirstContact = () => {
            this.removeWelcome();
            delete this.messages[contactMsg.ts];
            this.updateMessagesNow();
          }
        }
        if (window.isBusiness && !optedOut) {
          this.startedStripeAuth = true;
          const optOut = () => {
            localStorage.setItem("stripe-opt-out", this.props.me.self.uid);
            showBusinessSetup();
            sendContactMessage();
            return Promise.resolve();
          }
          ////////debugger;
          const authMsg = {
            system: true,
            ts: ts - 1000,
            data: {
              type: 'connect',
              'connect': () => <UIMeConnectToStripe reviewBAA={this.props.reviewBAA} link={this.props.link} optOut={optOut} copyLink={this.props.copyLink} formLinkURL={this.props.formLinkURL}/>
            }
          }
          this.messages[authMsg.ts] = authMsg;
        } else {
          sendContactMessage();
        }
        this.updateMessagesLater();
      }
      const firstContactDone = () => {
        if (!this.completedFirstContact) {
          this.completedFirstContact = true;
          removeFirstContact();
          this.props.onCompleted("first-contact");
        }
      }
      const scheduleAppointmentDone = () => {
        if (!this.completedScheduleAppointment) {
          this.completedScheduleAppointment = true;
          firstContactDone();
          this.props.onCompleted('schedule-appointment');
        }
      }
      const addMsg = this.addMsg
      const delMsg = this.delMsg

      this.props.me.hasContacts().then(result => {
        if (result) firstContactDone();
      });
      this.subSub = this.props.me.observeSubscriptions().subscribe(change => {
        const sub = change.subscription;
        const channel = sub.uid + "-" + sub.client;
        const prev = this.subs[channel];
        if (prev) delMsg(prev);
        if (change.type == 'removed') {
          return;
        } else {
          if (sub.state == 'active') {
            const latestQuestion = sub.latestQuestion || 0;
            const latestResponse = sub.latestResponse || 0;
            if (latestQuestion > latestResponse) {
              const msg = {
                to: sub.uid,
                system: true,
                data: {
                  type: 'subscription',
                  subscription: sub,
                },
                text: "subscription question",
                ts: latestQuestion,
              }
              this.subs[channel] = msg;
              addMsg(msg);
            }
          } else if (sub.state == 'offer' && (!sub.before || sub.before.state != 'offer')) {
            const msg = {
              from: sub.uid,
              to: sub.client,
              system: true,
              data: {
                type: 'subscription',
                subscription: sub,
              },
              text: "subscription offer",
              ts: sub.offerTime || Date.now()
            }
            this.subs[channel] = msg;
            addMsg(msg);
          }
        }
      });

      const dayStart = moment(new Date()).local().startOf('day').toDate().getTime()
      this.mealsSub = this.props.me.observeMeals(
        this.props.me.self.uid,
        dayStart).subscribe(change => {
//          ////debugger
          const meal = change.meal
          if (change.type == 'removed') {
            delete this.meals[meal.id]
          } else {
            this.meals[meal.id] = meal
          }
          if (meal.type !== 'snack') {
            this.updateMealsLater()
          }
        })
      this.updateMealsLater()
      this.mySubSub = this.props.me.observeMySubscriptions().subscribe(change => {
        const sub = change.subscription;
        const channel = sub.uid + "-" + sub.client;
        const prev = this.subs[channel];
        if (prev) delMsg(prev);
        if (change.type == 'removed') {
          return;
        } else {
          if (sub.state == 'offer' && (!sub.before || sub.before.state != 'offer')) {
            const msg = {
              from: sub.uid,
              to: sub.client,
              system: true,
              data: {
                type: 'subscription',
                subscription: sub,
              },
              text: "subscription offer",
              ts: sub.offerTime || Date.now()
            }
            this.subs[channel] = msg;
            addMsg(msg);
          }
        }
      });

      this.ouraRelinkSub = this.props.me.observeOuraLinked().subscribe(linked => {
        //debugger
        if (linked && linked.needsRelink) {
          const uid = this.props.me.self.uid
          const msg = {
            from: uid,
            to: uid,
            system: true,
            data: {
              type: 'oura-relink',
            },
            text: "Please relink your oura ring",
            ts: linked.needsRelink
          }
          this.ouraRelinkMsg = msg
          addMsg(msg)
        } else {
          const msg = this.ouraRelinkMsg
          this.ouraRelinkMsg = null
          if (msg) delMsg(msg)
        }
      })
      this.sub = this.props.me.observeSystemMessages().subscribe(async change => {
        if (!change) {
          //debugLog("system message change was null");
          return;
        }
        const msg = change.message;
        if (msg.ts == this.inProgress.ts ||
            msg.data.appointment && msg.data.appointment.id == this.inProgress.apptId) {
          window.hideProgressIndicator();
          this.inProgress = {};
        }
        let updateNow = false;
        if (msg.data && msg.data.appointment) {
          const appt = msg.data.appointment;
          const waiting = this.waiting[appt.id];
          if (waiting) {
            delete this.waiting[appt.id];
            waiting.map(resolve => resolve(msg));
            updateNow = true;
          }
          return
        }
        if (change.type == 'removed') {
          delMsg(msg);
        } else {
          msg.system = true;
          //debugLog(msg);
          if (msg.data.type == 'joinedTeTe') {
            // ignore. no display name yet
            return;
          }
          else if (msg.data.type == 'bedtime') {
            return
            //addMsg(msg)
          } else if (msg.data.type == 'workoutSession') {
            if (this.props.me.isTodoList()) {
              return
            }
            if (!this.workoutSessions) {
              this.workoutSessions = {}
              this.scheduledWorkouts = {}
            }
            const workoutSession = msg.data.workoutSession
            const activities = workoutSession.activities
            const ts = msg.ts
            const prev = this.workoutSessions[workoutSession.id]
            this.workoutSessions[workoutSession.id] = workoutSession;
            if (prev) {
              if (prev.ts < msg.ts) {
                prev.activities.forEach((w, i) => {
                  const msg1 = this.scheduledWorkouts[w.id]
                  delete this.scheduledWorkouts[w.id]
                  delMsg(msg1)
                })
              } else {
                return
              }
            }
            const findPrev = a => {
              if (prev) {
                return prev.activities.find(x => {
                  return x.activity.uid = a.activity.uid &&
                    x.description === a.description 
                })
              }
              return null
            }
            activities.forEach((w, i) => {
              const sport = this.props.me.getWhoop().getSport(w.activity)
              w.activity = {
                uid: sport.id,
                displayName: sport.name,
                profileImage: sport.iconUrl,
              }
              const id = workoutSession.id + '-' + i
              w.id = id
              const existing = findPrev(w)
              if (existing) {
                return
              }
              const msg1 = {
                to: msg.to,
                channel: msg.channel,
                ts: ts + i,
                text: '',
                data: {
                  isActive: true,
                  type: 'scheduledWorkout',
                  scheduledWorkout: {
                    workout: w,
                    status: w.status || 'pending',
                    id: id,
                    sessionId: workoutSession.id,
                    client: workoutSession.client,
                    trainer: workoutSession.uid,
                    scheduled: workoutSession.scheduled,
                    start: w.start,
                    end: w.end                  
                  }
                }
              }
              this.scheduledWorkouts[id] = msg1
              addMsg(msg1)
              const waiting = this.waiting[id]
              if (waiting) {
                delete this.waiting[id]
                waiting.map(resolve => resolve())
                updateNow = true;
              }
            })
            return
          } else if (msg.data.type == 'scheduledWorkout') {
            if (!this.scheduledWorkouts) {
              this.scheduledWorkouts = {}
            }
            const workout = msg.data.scheduledWorkout
            debugLog("workout", msg.ts, workout.status, workout)
            const prev = this.scheduledWorkouts[workout.id]
            debugLog('workout prev', prev)
            if (prev) {
              if (prev.ts < msg.ts) {
                debugLog('delete workout', prev)
                delMsg(prev)
                this.scheduledWorkouts[workout.id] = msg
              } else {
                return
              }
            } else {
              this.scheduledWorkouts[workout.id] = msg
            }
            const waiting = this.waiting[workout.id]
            if (waiting) {
              delete this.waiting[workout.id]
              waiting.map(resolve => resolve())
              updateNow = true;
            }
            switch (workout.status) {
              default:
              case 'started':
                if (workout.client != this.props.me.self.uid) {
                  return
                }
                break
              case 'done':
                if (prev) delMsg(prev)
                return
              case 'completed':
                msg.data.needsWorkout = true
                break
              case 'canceled':
                return
              case 'declined':
                if (workout.client == this.props.me.self.uid) {
                  return
                }
                msg.data.needsWorkout = true
                //return
            }
            //  debugLog("workout: ", msg)
            msg.data.isActive = true
            addMsg(msg)
          } else if (msg.data.type == 'newContact') {
            ////////debugger;
            const newContact = msg.data.newContact;
            //debugLog("newContact: ", newContact);
            const contact = newContact.contact;
            if (!contact) return;
            if (this.removedContacts[contact.uid]) {
              const removed = this.removedContacts[contact.uid];
              if (removed.ts > msg.ts) {
                return;
              } else {
                delMsg(this.removedContacts[contact.uid]);
                delete this.removedContacts[contact.uid];
              }
            }
            if (contact.uid == this.props.me.self.uid) {
              return;
            } else {
              if (this.openContacts[contact.uid]) {
                return;
              }
              if (this.scheduled[contact.uid]) {
                return;
              }
              this.newContacts[contact.uid] = msg;
              if (newContact.role != "referer") {
                firstContactDone();
              }
              addMsg(msg);
            }
          } else if (msg.data.type == 'removeContact') {
            const removeContact = msg.data.removeContact;
            const contact = removeContact.contact;
            if (this.newContacts[contact.uid]) {
              const newContact = this.newContacts[contact.uid];
              if (newContact.ts < msg.ts) {
                delMsg(newContact);
                delete this.newContacts[contact.uid];
              } else {
                return;
              }
            }
            this.removedContacts[contact.uid] = msg;
            return;
          } else if (msg.data.type == 'openContact') {
            const openContact = msg.data.openContact;
            const contact = openContact.contact;
            this.openContacts[contact.uid] = true;
            if (this.newContacts[contact.uid]) {
              delMsg(this.newContacts[contact.uid]);
              delete this.newContacts[contact.uid];
            }
            return;
          } else if (msg.data.type == 'subscription') {
            const sub = msg.data.subscription;
            const channel = sub.uid + '-' + sub.client;
            const prev = this.subs[channel];
            if (prev) {
              if (prev.ts < msg.ts) {
                delMsg(prev);
              } else {
                return;
              }
            }
            if (sub.uid != this.props.me.self.uid) {
              return;
            }
            if (sub.state == 'active') {
              const latestQuestion = sub.latestQuestion || 0;
              const latestResponse = sub.latestResponse || 0;
              if (latestQuestion > latestResponse) {
                this.subs[channel] = msg;
                addMsg(msg);
              } else {
                return;
              }
            } else if (sub.state == 'offer') {
              this.subs[channel] = msg;
              addMsg(msg);
            } else {
              return;
            }
          } else if (msg.data.type == 'appointment') {
            scheduleAppointmentDone();
            const appt = msg.data.appointment;
            //debugLog(appt);
            const uid1 = appt.client;
            const uid2 = appt.uid;
            [uid1, uid2].map(uid => {
              if (this.newContacts[uid]) {
                delMsg(this.newContacts[uid]);
                delete this.newContacts[uid];
              } else {
                if (uid != this.props.me.uid) {
                  this.scheduled[uid] = true;
                }
              }
            });
            //////debugger;
            if (appt.paymentIntentId ) {
              if (!appt.paymentStatus ||
                  appt.paymentStatus == "requires_confirmation" ||
                  appt.paymentStatus == "requires_payment_method") {
                return;
              }
            }
            let prev = this.appts[appt.id]
            if (prev && prev.modified) {
              delete this.appts[appt.id];
              delMsg(prev);
              prev = null;
            }
            if (prev) {
              const prevAppt = prev.data.appointment;
              if (prevAppt.status == "canceled") {
                return;
              }
              let ignoreTs = false;
              if (appt.paymentSeq) {
                if (appt.paymentSeq != prevAppt.paymentSeq) {
                  if (!prevAppt.paymentSeq || prevAppt.paymentSeq < appt.paymentSeq) {
                    this.deleteMessage(prev);
                  } else {
                    if (prev.paymentSeq && appt.paymentSeq < prev.paymentSeq) {
                      return;
                    }
                  }
                }
              }
              if (appt.sequence > prevAppt.sequence) {
                this.deleteMessage(prev);
              } else if (appt.sequence < prevAppt.sequence) {
                return;
              }
              if (!ignoreTs) {
                if (prev.ts <= msg.ts) {
                  this.deleteMessage(prev);
                } else {
                  return;
                }
              }
            }
            //debugLog("me add appointment: ", msg);
            this.appts[appt.id] = msg;
            msg.data.isActive = true;
            const now = Date.now();
            const start = appt.start;
            const end = appt.end;
            if (end < now) {
              //debugLog("appointment in the past: ", new Date(start), "..",  new Date(end));
              return;
            }
            let status;
            if (msg.text == "Appointment canceled") {
              status = 'canceled';
            } else {
              if (appt.status) {
                status = appt.status;
                if (status == 'accepted') {
                  if (appt.invoiceAmount && appt.paymentStatus != 'succeeded') {
                    status = 'awaiting-payment';
                  } else {
                  }
                }
              } else {
                status = 'awaiting-accept';
              }
            }
            msg.text = status;
            msg.status = status;
            //debugLog("appointment status: ", status);
            if (status == 'declined' && appt.client == this.props.me.self.uid) {
              delMsg(msg);
              return;
            }
            if (status == 'canceled') {
              delMsg(msg);
              return;
            }
          } else {
            //debugLog("unsupported case: ", msg);
            return;
          }
          addMsg(msg);
        }
        if (updateNow) {
          this.updateMessagesNow();
        }
      });
    }

    if (this.props.me.isTodoList()) {
      this.todoSubSub = this.props.me.observeTodoListSubscription().subscribe(sub => {
        console.log("todo-sub", sub)
        if (!sub) {
        } else {
          if (sub.status === 'payment' || sub.status === 'canceled') {
            if (!this.todoSubMsg) {
              this.todoSubMsg = {
                ts: Date.now(),
                text: "Subscribe",
                data: {
                type: 'to-do-list-subscription',
                  subscription: sub
                }
              }
              this.addMsg(this.todoSubMsg)
            }
            return
          }
          let msg = this.todoSubMsg
          if (msg) {
            this.todoSubMsg = null
            this.delMsg(msg)
          }
        }
      })
    }

    if (this.props.me.isTodoList()) this.todoSub = this.props.me.observeTodoChat().subscribe(change => {
      //debugger
      const msg = change.message
      let notify
      if (change.type == 'removed') {
        delete this.todoChat[msg.ts] 
      } else {
        if (msg.data && msg.data.todo && msg.data.todo.progress && msg.data.todo.progress.length > 0) {
          const item = msg.data.todo
          if (item.status === 'pending') { // hack
            const todo = item.todo
            const prog = item.progress[item.progress.length-1]
            item.status =  'progress'
            item.outcome = prog.outcome
            todo.task = prog.notes
            todo.end = prog.end
            todo.emojis = ''
            delete item.progress
          }
        }
        this.todoChat[msg.ts] = msg
        this.todoMessagesSubject.next(msg)
      }
      this.chat.markDirty(msg)
      console.log("todo", msg)
      const waiting = this.waiting[msg.ts];
      if (waiting) {
        delete this.waiting[msg.ts];
        waiting.map(resolve => resolve());
        this.updateMessagesNow()
      } else {
        this.updateMessagesLater()
      }
    })
  }

  prompts = [
    "What's on your mind?",
    /*"Anything weighing on your mind?",
    "Are you able to relax? If not, why?",
    "Anything you'd like to get off your mind?",
    "Any concerns preventing you from relaxing?",
    "What are you worried about?",
    "Mention anything that's bothering you",
    "Tell me what's stressing you"*/
  ]
  
  nextPrompt = () => {
    function getRandomInt(max) {
      return Math.round(Math.random() * (max-1));
    }
    const prompt = this.prompts[getRandomInt(this.prompts.length)]
    this.setState({
      prompt
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchTerm != prevProps.searchTerm) {
      this.updateMessagesLater();
    }
    if (this.props.auth && !prevProps.auth) {
      if (this.removeWelcome) this.removeWelcome();
    }
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
    if (this.subSub) this.subSub.unsubscribe();
    clearInterval(this.mealsInterval)
    if (this.accountSub) this.accountSub.unsubscribe()
    if (this.todoSub) this.todoSub.unsubscribe()
    if (this.todoSubSub) this.todoSubSub.unsubscribe()
  }

  updateMessagesNow = () => {
    clearTimeout(this.updateTimeout);
    this.updateUnreadCount()
    let ms = Object.values(this.messages).concat(Object.values(this.todoChat))
    let active = {};
    let complete = {};
    let payments = {};
    ms.map(msg => {
      if (msg.data && msg.data.appointment) {
        const appt = msg.data.appointment;
        if (!appt.contact) {
          appt.organizer = this.props.me.getContact(appt.uid);
          const other = appt.uid == this.props.me.self.uid ? appt.client : appt.uid;
          appt.contact = this.props.remoteContact ? this.props.remoteContact : this.props.me.getContact(other);
          
        }
        if (appt.paymentStatus == "succeeded") {
          complete[appt.paymentIntentId] = msg;
          payments[appt.paymentIntentId] = msg;
        } else {
          const paymentIntentId = appt.paymentIntentId;
          if (paymentIntentId) {
            if (!payments[paymentIntentId] || payments[paymentIntentId].ts < msg.ts) {
              payments[paymentIntentId] = msg;
            }
          }
        }
        //debugLog("me message: ", msg);
      }
    });
    const matches = {};
    const searchTerms = this.props.searchTerm ? this.props.searchTerm.toLowerCase().split(/\s+/) : null;
    ms = ms.filter(msg => {
      if (searchTerms) {
        let allTerms;
        let textTerms = (msg.text && msg.text.split(/\s+/)) || []
        if (msg.data) {
          if (msg.data.appointment) {
            const appt = msg.data.appointment;
            let terms = appt.contact.displayName.split(/\s+/);
            let timeTerms = formatStartEndTime(new Date(appt.start), new Date(appt.end)).split("-").map(x => x.trim()).join("").split(/\s+/);
            let dateTerms = formatDate(new Date(appt.start)).split(/\s+/);
            let paymentTerms = renderPaymentStatus(appt.paymentStatus).split(/\s+/);
            let titleTerms = appt.title ? appt.title.split(/\s+/) : [];
            let amountTerms = appt.invoiceAmount ? ["$"+appt.invoiceAmount, ""+appt.invoiceAmount] : [];
            allTerms = [textTerms, terms, timeTerms, dateTerms, paymentTerms, titleTerms, amountTerms];
          } else if (msg.data.newContact) {
            const text = formatNewContactText(this.props.me, msg.data.newContact);
            allTerms = [text.split(/\s+/)];
          } else if (msg.data.subscription) {
            const sub = msg.data.subscription;
            let terms = sub.contact.displayName.split(/\s+/);
            terms.push("awaiting");
            terms.push("response");
            const organizer = this.props.me.getContact(sub.uid);
            if (organizer.uid != sub.contact.uid) {
              terms = terms.concat(organizer.displayName.split(/\s+/));
            }
            const latestQuestion = sub.latestQuestion || 0;
            const latestResponse = sub.latestResponse || 0;
            let start = 0;
            let end = 0;
            let timeTerms = [];
            let dateTerms = [];
            if (latestQuestion > latestResponse) {
              start = latestQuestion;
              end = this.props.me.getNextResponseTime(sub);
              dateTerms = formatDate(new Date(start)).split(/\s+/);
              dateTerms.push("respond");
            }
            let paymentTerms = ["active", "monthly"];
            let titleTerms = sub.description ? sub.description.split(/\s+/) : [];
            let amountTerms = sub.invoiceAmount ? ["$"+sub.invoiceAmount, ""+sub.invoiceAmount] : [];
            allTerms = [textTerms, terms, timeTerms, dateTerms, paymentTerms, titleTerms, amountTerms];
            
          } else if (msg.data.todo) {
            const todo = msg.data.todo.todo
            const fields = ['summary', 'task', 'category', 'location', 'emojis', getEmojiFromTodo(todo), 'benefits', 'how']
            allTerms = []
            fields.forEach(field => {
              const value = todo[field]
              if (value) {
                allTerms.push(value.toLowerCase().split(/\s+|-/))
              }
            })
          } else {
            // fix me
            allTerms = [textTerms]
          }
        } else {
          allTerms = [textTerms];
        }
        let matched = 0;
        allTerms.map(terms => {
          terms.map(term => {
            if (term) {
              term = term.toLowerCase();
              searchTerms.map(searchTerm => {
                if (searchTerm) {
                  if (term.startsWith(searchTerm)) {
                    matched++;
                  }
                }
              });
            }
          })
        });
        if (matched == 0) return false;
        matches[msg.ts] = matched;
        //debugLog("matched ", matched, ": ", msg);
      }
      if (msg.data && msg.data.appointment) {
        const appt = msg.data.appointment;
        const paymentIntentId = appt.paymentIntentId;
        if (paymentIntentId) {
          return payments[paymentIntentId] == msg;
        }
      }
      return true;
    });
    
    if (ms.length == 0) {
      if (!searchTerms) {
        if (this.props.me.isTodoList()){         
          ms.push({
            ts: Date.now(),
            text: "Type in whatever concerns are weighing on your mind 🧠 here.\n\nAny tasks that are implied will be added to your to-do list, which should let your mind relax 😌.",
            system: true,
            to: this.props.me.self.uid,
          });
        } else {
          ms.push({
            ts: Date.now(),
            text: 'You\'re all caught up',
            system: true,
            to: this.props.me.self.uid,
          });
        }
      }
    } else {
      if (searchTerms) {
        const sort = (x, y) => {
          if (x === this.todoSubMsg) {
            return 1
          }
          if (y === this.todoSubMsg) {
            return -1
          }
          const w1 = matches[x.ts];
          const w2 = matches[y.ts];
          const cmp1 = w2-w1;
          if (cmp1 !== 0) {
            return cmp1;
          }
          return x.ts - y.ts;
        }
        ms.sort(sort);
      } else {
        ms.sort((a, b) => {
          if (a === this.todoSubMsg) {
            return 1
          }
          if (b === this.todoSubMsg) {
            return -1
          }
          return a.ts - b.ts
        });
      }
    }
    let seen = {}
    for (let i = 0; i < ms.length; i++) {
      const m = ms[i]
      if (m.data && m.data.todo) {
        const prev = seen[m.data.todo.id]
        if (prev) {
          prev.data.isActive = false
        }
        seen[m.data.todo.id] = m
        m.data.isActive = true
      }
    }
    ms.map((x, i) => x.sortOrder = i);
    console.log("messages", ms)
    this.setState({
      messages: ms
    });
  }

  scheduleAppointment = form => {
//    ////debugger
    const data = this.state.openEvent;
    if (data.scope == 'meals' || (data.appt && data.appt.meal)) {
      return this.scheduleMeal(form).then(() => {
        debugLog('saved meal')
        this.setState({
          openEvent: null
        })
      })
    }
    else if (data.scope == 'workouts' || (data.appt && data.appt.workout)) {
      return this.scheduleWorkout(form).then(() => {
        debugLog('saved workout')
        this.setState({
          openEvent: null
        })
      })
    }
    else if (data.scope == 'todo' || (data.appt && data.appt.todo)) {
      return this.scheduleTodo(form).then(() => {
        this.setState({
          openEvent: null
        })
      })
    }
    return Promise.resolve()
  }

  rescheduleAppointment = appt => {
    if (appt.workout) {
      this.openEvent({
        id: appt.id,
        appt: appt
      })
      return Promise.resolve()
    }
    return this.props.rescheduleAppointment(appt)
  }

  updateMessagesLater = () => {
    clearTimeout(this.updateTimeout);
    setTimeout(this.updateMessagesNow, 500);
  }

  deleteMessage = msg => {
    //////debugger;
    delete this.messages[msg.ts];
    this.updateMessagesLater();
  }

  onAppointmentEnded = msg => {
    this.deleteMessage(msg);
  }

  onChatCreated = chat => {
    this.chat = chat;
  }

  sendMessage = async (text) => {
    const msg = {
      ts: Date.now(),
      text: text,
      from: this.props.me.self.uid,
      channel: this.props.me.self.uid,
      to: this.props.me.getSystemContact()
    }
    this.typingSubject.next(true)
    this.todoChat[msg.ts] = msg
    this.updateMessagesNow()
    this.props.me.sendTodoMessage(msg)
    clearInterval(this.typingInterval)
    this.typingInterval = setInterval(() => this.typingSubject.next(true), 5000)
    const sub = this.observeTodoMessages().subscribe(newMsg => {
      if (newMsg.ts == msg.ts && newMsg.answered) {
        sub.unsubscribe()
        clearInterval(this.typingInterval)
      }
    })
  }

  observeTodoMessages = () => this.todoMessagesSubject

  todoMessagesSubject = new Subject()

  saveMessage = async msg => {
    this.chat.markDirty(msg)
    this.props.me.sendTodo({ts: msg.ts, text: msg.text})
    clearInterval(this.typingInterval)
    this.typingInterval = setInterval(() => this.typingSubject.next(true), 5000)
    const sub = this.observeTodoMessages().subscribe(newMsg => {
      if (newMsg.ts == msg.ts && newMsg.answered) {
        sub.unsubscribe()
        clearInterval(this.typingInterval)
      }
    })
  }

  deleteChatMessage = async msg => {
    delete this.todoChat[msg.ts]
    const toDelete = []
    for (const id in this.todoChat) {
      const msg1 = this.todoChat[id]
      if (msg1.inReplyTo == msg.ts) {
        toDelete.push(msg1)
      }
    }
    toDelete.forEach(msg => {
      delete this.todoChat[msg.ts]
    })
    this.updateMessagesLater()
    this.props.me.deleteTodoChat(msg)
    //this.deleteMessage(msg)
  }

  typing =  () => {
  }

  typingSubject = new Subject()


  canDelete = msg => {
    return (msg.from === this.props.me) || (msg.data && msg.data.type === 'todo')
  }


  render() {
    let className = 'uiMeTeTeFeedMessagesContainer';
    const prompt = this.state.prompt
    if (this.props.isIPad) {
      className += ' uiMeTeTeFeedMessagesContainerIPad';
    }
    let sendMessage
    let saveMessage
    let deleteChatMessage
    let canDelete
    if (this.props.me.isTodoList() && !this.todoSubMsg) {
      sendMessage = this.sendMessage
      saveMessage = this.saveMessage
      deleteChatMessage = this.deleteChatMessage
      canDelete = this.canDelete
    }
    return <div className='uiMeTeTeFeed'>
      <div className={className}>
      <div className='uiMeTeTeFeedMessages'>
      <UIChat
    canDelete={canDelete}
    isMe={true}
    openChat={this.props.openChat}
    recordMeal={this.recordMeal}
    onChatCreated={this.onChatCreated}
    onAppointmentEnded={this.onAppointmentEnded}
    finishNewContact={this.finishNewContact}
    waitForSystemUpdate={this.waitForUpdate}
    hideWith={false}
    placeholder={prompt}
    sendMessage={sendMessage}
    saveMessage={saveMessage}
    deleteChatMessage={deleteChatMessage}
    sendLabel={'Send'}
    typing={this.typing}
    openAppointment={this.props.openAppointment}
    openEvent={this.props.openEvent}
    observeTyping={() => this.typingSubject}
    openSubscription={this.props.openSubscription}
    scheduleChatAppointment={this.props.scheduleChatAppointment}
    scheduleAppointmentWith={this.props.scheduleAppointmentWith}
    rescheduleAppointment={this.rescheduleAppointment} showSystemProgressIndicator={this.showSystemProgressIndicator} messages={this.state.messages} me={this.props.me} localContact={this.props.me.self} remoteContact={this.props.me.self}/>
      </div>
      </div>
      </div>
  }
}

export class UIMeConnectToStripe extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.sub = this.props.me.observeStripeAccount().subscribe(account => {
      this.state.account = account;
      this.forceUpdate();
    });
  }


  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
    this.closePopup();
  }

  closePopup = () => {
    clearInterval(this.popupTimer);
    if (this.state.popup) {
      this.state.popup.close();
      this.state.popup = null;
    }
  }

  checkPopupClosed = () => {
    const popup = this.state.popup;
    if (popup.closed) {
      this.closePopup();
      this.forceUpdate();
    }
  }

  okClick = () => {
    if (this.state.popup) {
      this.closePopup();
      this.forceUpdate();
      return Promise.resolve();
    }
    return this.props.me.stripeConnect().then(popup => {
      if (popup.closed) {
        return;
      }
      this.setState({
        popup: popup
      });
      this.popupTimer = setInterval(this.checkPopupClosed, 200);
    });
  }

  reviewBAA = () => {
    return this.props.reviewBAA();
  }
  

  render() {
    if (!this.props.me.self && this.props.me.self.displayName) {
      return;
    }
    let errors = [];
    const seen = {};
    let disabled;
    if (this.state.account && this.state.account.requirements) {
      if (this.state.account.requirements.errors) {
        this.state.account.requirements.errors.map(err => {
          if (!seen[err.reason]) {
            errors.push(err.reason);
            seen[err.reason] = true;
          }
        });
      }
      if (this.state.account.requirements.disabled_reason) {
        errors.push("Your account is disabled due to missing or incorrect information");
      }
    }
    let stripeLabel; 
    if (this.state.popup) {
      stripeLabel = this.props.auth ? "Close Payments Dashboard" : "Close Payments Set-up";
    } else {
      //////debugger;
      stripeLabel = this.props.auth ? "Open Payments Dashboard" : "Set up Payments"
    }

    const notConnectedToStripe = () => {
      return <div className='uiMeStripeNotConnected'>
        <div className='uiMeContactAssetImage'><img src={StripeAsset}/></div>
        <div className='uiMeStripeConnectMessage'>
        {}
      </div>
        <div className='uiMeStripeButtonContainer'>
        <UIOKCancel okIcon={Stripe} ok={this.okClick} label={stripeLabel}/>
        </div>
        {this.props.optOut && <div className='uiMeStripeOptOut'>
         <UIOKCancel okIcon={Forward} ok={this.props.optOut} label={"Not right now"}/>
         </div>}
      </div>;
    }

    const connectedToStripe = () => {
      const reviewBAALabel = this.props.acceptedBAA ? "View BAA" : "Review and Accept BAA";
      return <div className='uiMeStripeConnected'>
        {errors.length > 0 && <div className='uiMeStripeConnectedBlurb'>You are connected with Stripe</div>}
      {errors.length >  0 && <div className='uiMeStripeConnectErrors'>
       <img src={StripeError}/>
       <div className='uiMeStripeConnectErrorsText'>Your Stripe account is restricted due to the following errors:</div>
       {errors.map(err => {
         return <div className='uiMeStripeConnectError'>{err}</div>;
       })}
       <div className='uiMeStripeConnectErrorsText2'>Open your Stripe dashboard and correct them</div>
       </div>}
        <div className='uiMeStripeButtonContainer'>
        <UIOKCancel background={true} okIcon={Stripe} ok={this.okClick} label={stripeLabel}/>
        </div>
        {false && <div className='uiMeReviewBAA'>
        <UIOKCancel okIcon={Forward} ok={this.reviewBAA} label={reviewBAALabel}/>
         </div>}
        <UIMeContact me={this.props.me} formLinkURL={this.props.formLinkURL} type='client' link={this.props.link} copyLink={this.props.copyLink}/>
        </div>
    }
    
    return <div className='uiMeConnectToStripe'>
      {this.props.auth ? connectedToStripe() : notConnectedToStripe()}
    </div>
  }
  
}

export class UIMeBAA extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  close = () => {
    return this.props.cancel();
  }

  accept = () => {
    return this.props.accept();
  }

  downloadBAA = () => {
    return this.props.download();
  }

  
  render() {
    let className = 'uiMeBAA';
    if (!(isDesktop() && isSafari())) {
      className += " uiMeBAANotSafariDesktop";
    }
    return <div className={className}>
      <div className='uiMeBAAHeaderContainer'>
      <div className='uiMeBAAClose' onClick={this.close}><ReactSVG src={Cross}/></div>
      <div className='uiMeBAAHeader'>
      <div className='uiMeBAAHeaderIcon'>
      <ReactSVG src={Hipaa}/>
      </div>
      <div className='uiMeBAAHeaderTitle'>
      {this.props.acceptedBAA ? "Effective "+moment(new Date(this.props.acceptedBAA)).format("Do MMM YYYY") : "Review and Accept"}
    </div>
      <div className='uiMeBAAHeaderSubtitle'>
      TeTe Business Associate Agreement
    </div>
      </div>
      </div>
      <div className='uiMeBAABody'>
      {BAA}
    </div>
      <div className='uiMeBAAFooter'>
      {!this.props.acceptedBAA ?
       <UIOKCancel cancel={this.close} cancelIcon={Cross} okIcon={Checkmark} label="I Accept" ok={this.accept}/>
       :
       <UIOKCancel okIcon={Save} label="Download" ok={this.downloadBAA}/>
      }
    </div>
      </div>
  }
}

export class UIMeContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0
    };
    this.invites =
      [{
        text: "Add a contact to Let's Build by sharing this secure private link:"
      },
       {
         text: "Refer a colleague to Let's Build by sharing this secure private link:"
       },
       {
         text: "Invite your friends or contacts to connect with you on TeTby sharing this secure private link:"
       }];
  }

  checkSub() {
    if (!this.sub && this.props.me) {
      this.sub = this.props.me.observeSelf().subscribe(() => {
        this.forceUpdate();
      });
    }
    if (!this.sub1 && this.props.me) {
      this.sub1 = this.props.me.observeStripeAuth().subscribe(auth => {
        this.setState({
          auth: auth
        });
      });
    }
    if (!this.sub2 && this.props.me) {
      this.sub2 = this.props.me.observeAccount().subscribe(account => {
        this.setState({
          acceptedBAA: account && account.acceptedBAA
        });
      });
    }
  }

  componentDidMount() {
    this.checkSub();
  }

  componentDidUpdate() {
    this.checkSub();
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
    if (this.sub1) this.sub1.unsubscribe();
    if (this.sub2) this.sub2.unsubscribe();
  }

  selectTab = tab => {
    this.setState({
      selectedTab: tab
    });
  }

  formLinkURL = () => {
    const link = this.props.link;
    const types = ['client', 'refer', 'contact'];
    const type = types[this.state.selectedTab];
    return window.origin+"/?"+type+"="+link;
  }
  
  copyLink = () => {
    return new Promise((resolve, reject) => {
      const url = this.formLinkURL();
      Clipboard.copy(url);
      setTimeout(resolve, 500);
    });
  }

  stripeConnect = () => {
    return this.props.me.stripeConnect();
  }

  createGroup = () => {
    this.props.createGroup()
  }

  reviewAndAcceptBAA = () => {
    return this.toggleReviewBAA();
  }

  acceptBAA = () => {
    return this.props.me.acceptBAA().then(() => {
      this.toggleReviewBAA();
    })
  }
  
  toggleReviewBAA = () => {
    if (this.props.viewBAA) {
      return this.props.viewBAA()
    }
    this.setState({
      reviewBAA: !this.state.reviewBAA
    });
    return Promise.resolve();
  }

  renderBAA = () => {
    if (this.props.portal) {
      return ReactDOM.createPortal(this.renderBAAImpl(), this.props.portal);
    }
    return this.renderBAAImpl();
  }

  skipStripeConnect = () => {
  }

  renderBAAImpl = () => {
    if (!this.state.reviewBAA) return null;
    return <ClickAwayListener onClickAway={this.toggleReviewBAA}><UIMeBAA download={this.props.me.downloadBAA} acceptedBAA={this.state.acceptedBAA} cancel={this.toggleReviewBAA} accept={this.acceptBAA}/></ClickAwayListener>
  }
  
  render() {
    let message;
    if (this.props.type == 'client') {
      if (!this.state.acceptedBAA) {
        return null;
      }
      message = "Invite your clients to connect with you by sharing this link:";
      return <div className='uiMeContact uiMeContactBusiness'>
        <div className='uiMeContactBottomLayer'>
        <div className='uiMeStripeConnectedBlurb'>Onboard your clients</div>
        <div className='uiMeContactAssetImage'><img src={LinkBusinessAssetSml}/></div>
        <div className='uiMeContactText'>
        {message}
      </div>
        <div className='uiMeContactLink'>
        {this.props.link ? this.props.formLinkURL(this.props.type, this.props.link) : "Generating link..."}
      </div>
        <div className='uiMeContactButtons'>
        <UIOKCancel okIcon={Link} label="Copy Link" ok={()=>this.props.copyLink(this.props.type)}/>
        </div>
        </div>
        </div>
    } 
    const selected = this.state.selectedTab;
    let message1;
    message = this.invites[selected].text;
    let button;
    let showLink = true;
    const messageAndButton = (message, button) => {
      return [<div className='uiMeContactText'>
              {message}
              </div>,
              <div className='uiMeContactButtons'>
              {button}
              </div>];
    }
    let button1;
    if (selected == 0) {
      if (false && !this.state.acceptedBAA) {
        message1 = "For HIPAA compliance please review and accept TeTe's Business Associate Agreement";
        button1 = <UIOKCancel okIcon={Forward} label="Review and Accept BAA" ok={this.reviewAndAcceptBAA}/>;
      }
      if (false) {
        button1 = <div className='uiMeStripeButtonContainer'>
          <UIOKCancel okIcon={Stripe} ok={this.stripeConnect} label={"Enable Payments with Stripe"}/>
          </div>;
        showLink = true;
        const text = "Using TeTe for payments is optional, but we think it provides a great user experience. Note: you can selectively use TeTe for payments on a per-client or per-session basis."
        message1 = <div className='uiMeContactText2'>
          {text}
        </div>;
        if (false) button1 =
          <div className='uiMeContactSkipStripe'>
          <UIOKCancel okIcon={Forward} ok={this.skipStripeConnect} label={"Maybe Later"}/>
          </div>
      }
      
      
    }
    if (!button) {
      button = <UIOKCancel okIcon={Link} label="Copy Link" ok={()=>this.copyLink()}/>;
      button1 =
        <div className='uiMeContactButtons uiMeContactButtonsCreateGroup'>
        <UIOKCancel okIcon={Group} label="Create Group" ok={this.createGroup}/>
        </div>

    }
    return <div className='uiMeContact'>
      <div className='uiMeContactBottomLayer'>
      <div className='uiMeContactAssetImage'><img src={LinkAssetSml}/></div>
      <div className='uiMeContactLinkTabs'>
      <div onClick={()=>this.selectTab(0)}
    className={'uiMeContactLinkTab uiMeContactLinkTabLeft' + (selected == 0 ? " uiMeContactLinkTabSelected" : "")}>
      <div className='uiMeContactLinkTabText'>Onboard a client</div>
      <div className='uiMeContactLinkArrow'><ReactSVG src={Arrow}/></div>
      </div>
    {false &&
      <div onClick={()=>this.selectTab(1)}
    className={'uiMeContactLinkTab uiMeContactLinkTabMiddle' + (selected == 1 ? " uiMeContactLinkTabSelected" : "")}>
      <div className='uiMeContactLinkTabText'>Refer a colleague</div>
      <div className='uiMeContactLinkArrow'><ReactSVG src={Arrow}/></div>
     </div>}
    {false && <div onClick={()=>this.selectTab(2)}
    className={'uiMeContactLinkTab uiMeContactLinkTabRight' + (selected == 2 ? " uiMeContactLinkTabSelected" : "")}>
      <div className='uiMeContactLinkTabText'>Connect a friend</div>
      <div className='uiMeContactLinkArrow'><ReactSVG src={Arrow}/></div>
     </div>}
      </div>
   
      <div className='uiMeContactText'>
      {message}
    </div>
      {showLink ? <div className='uiMeContactLink'>
       {this.props.link ? this.formLinkURL() : "Generating link..."}
       </div> : <div className='uiMeContactNoLink'/>}
      <div className='uiMeContactButtons'>
      {button}
    {message1 &&
     <div className='uiMeContactText2'>
     {message1}
     </div>}
    {button1}
    </div>
      {this.renderBAA()}
    </div>
      </div>
  }
}

export class UIMeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  onSearchChange = e => {
    this.props.onSearch(e.target.value)
  }

  onSearchButtonClick = () => {
    if (this.state.searching) {
      this.props.onSearch("");
      this.input.focus();
      return;
    }
    this.setState({
      searching: true
    }, () => {
      this.input.focus();
    });
  }
  
  setInputRef = x => {
    if (x && x != this.input) {
      this.input = x;
      if (this.state.searching) {
        x.focus();
      }
    }
  }

  stopSearch = () => {
    this.setState({
      searching: false
    });
  }

  render() {
    let style = {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 20
    }
    return <div id={this.props.cardId} key={this.props.key} className='uiMeCard'>
      <div className='uiMeCardContent'>
      {this.props.onSearch && <div className='uiCalendarSearch' >
      <input placeholder={'Search'} value={this.state.searchTerm}   onFocus={this.onSearchFocus} onBlur={this.onSearchBlur}
    onChange={this.onSearchChange} onKeyDown={this.onSearchKeyDown} ref={this.setSearchInput} className='uiCalendarSearchField'/>
      <div className='uiCalendarSearchIcon' onClick={this.onSearchIconClick}>
      <ReactSVG src={this.state.searchTerm ? Cross: Search}/>
      </div>
       </div>}
    {!this.props.onSearch &&
     <div className='uiMeCardHeader'>
     <div className='uiMeCardTitle'>{this.props.title + (this.props.searchTerm  ? (" / " + this.props.searchTerm) : "")}</div>
     </div>}
      <div className='uiMeCardBody'>
      {this.props.children}
    </div>
      </div>
      </div>
  }
}

export class UIMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      formErr: {},
      feed: [],
      searchForm: {},
    }
    this.portalRef = React.createRef();
    this.workouts = {}
    this.activityOptions = this.props.me.getWhoop().getSports().map(sport => {
      return {
        uid: sport.id,
        displayName: sport.name,
        profileImage: sport.iconUrl
      }
    })
  }
  
  subscribe() {
//    ////debugger
    this.props.me.getContactLink().then(link => {
      const show = this.state.showBusinessSetup || !window.isBusiness;
      this.setState({
        contactLink: link,
        showBusinessSetup: show
      });
    });
    this.props.me.observeAccount().subscribe(account => {
      if (account) {
        debugLog("uime acceptedBAA: ", account.acceptedBAA);
      }
      this.setState({
        acceptedBAA: account && account.acceptedBAA
      });
    });
    this.props.me.observeStripeAuth().subscribe(auth => {
      this.setState({
        auth: auth,
      });
    });
    /*
    this.props.me.observeFeed().subscribe(async change => {
      if (change.type == 'removed') {
        delete this.workouts[change.workout.id]
      } else {
        this.workouts[change.workout.id] = change.workout
      }
      const trainer = await this.props.me.resolveContact(workout.trainer)
      const client = await this.props.me.resolveContact(workout.client)
      this.updateFeedLater()
    })
    */
    this.props.me.observeMyCompletedWorkouts().subscribe(change => {
      if (!this.state.showWorkouts) {
        this.setState({
          showWorkouts: true
        })
      }
      if (change.type == 'removed') {
        delete this.workouts[change.workout.id]
      } else {
        this.workouts[change.workout.id] = change.workout
      }
      this.updateFeedLater()
    })
  }

  updateFeedLater = () => {
    clearTimeout(this.feedTimer)
    this.feedTimer = setTimeout(this.updateFeedNow, 200)
  }

  updateFeedNow = () => {
    let workouts = Object.values(this.workouts)
    const with_ = this.state.searchForm.with
    const searchTerm = this.state.recentSearchTerm
    const activity = this.state.searchForm.activity
    let searchTerms
    if (searchTerm) {
      searchTerms = searchTerm.toLowerCase().split(/\s+/);
    }
    const matches = {}
    workouts = workouts.filter(workout => {
      if (activity && workout.activity.uid !== activity.uid) {
        return false
      }
      if (with_ && workout.client !== with_.uid) {
        return false
      }
      if (searchTerm) {
        const appt = this.workoutToAppt(workout)
        let terms = appt.contact.displayName.toLowerCase().split(/\s+/);
        let timeTerms = formatStartEndTime(new Date(appt.start), new Date(appt.end)).split("-").map(x => x.trim()).join("").split(/\s+/);
        let dateTerms = formatDate(new Date(appt.start)).toLowerCase().split(/\s+/);
        let titleTerms = appt.title ? appt.title.split(/\s+/) : [];
        const allTerms = [terms, timeTerms, dateTerms, titleTerms];
        let matched = 0;
        allTerms.map(terms => {
          terms.map(term => {
            if (term) {
              term = term.toLowerCase();
              searchTerms.map(searchTerm => {
                if (searchTerm) {
                    if (term.startsWith(searchTerm) || searchTerm.startsWith(term)) {
                      matched++;
                    }
                }
              });
            }
          })
        });
        //debugLog("matched: ", matched, " in ", appt);
        if (matched == 0) return false;
        matches[workout.id] = matched;
        return true;
      }
      return true
    })
    workouts.sort((x, y) => {
      const w1 = matches[x.id] || 0
      const w2 = matches[y.id] || 0
      const cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      const a = x.end || x.start || x.scheduled
      const b = y.end || y.start || y.scheduled
      return b - a
    })
    this.setState({
      feed: workouts
    })
  }

  showBusinessSetup = () => {
    this.setState({
      showBusinessSetup: true
    });
  }

  formLinkURL = (type, link) => window.origin+"/?"+type+"="+link;
  
  copyLink = (type) => {
    return new Promise((resolve, reject) => {
      const url = this.formLinkURL(type, this.state.contactLink);
      Clipboard.copy(url);
      setTimeout(resolve, 500);
    });
  }

  onCompleted = step => {
    //debugLog("Completed: ", step);
    switch (step) {
      case 'stripe-setup':
        {
          break;
        }
      case 'first-contact':
        {
          this.setState({showBusinessSetup: true, showContactLink: true});
          break;
        }
      case 'schedule-appointment':
        {
          break;
        }
    }
  }

  searchTodo = searchTerm => {
    this.setState({
      searchTerm: searchTerm
    });
  }

  componentDidUpdate() {
    this.checkLayout();
  }

  componentDidMount() {
    this.subscribe()
    window.addEventListener("resize", this.onResized);
    this.checkLayout();
    if (this.props.me.isTodoList()) {
      this.props.me.setStatusBarColor('dark-content')
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResized);
  }

  onResized = () => {
    this.forceUpdate();
    this.checkLayout();
  }

  checkLayout = () => {
    let markRead = this.props.nav == 'todo';
    if (false && !this.isIPad() && this.props.nav != 'todo') {
      this.props.navigate('todo');
      markRead = true;
    }
    if (markRead && this.props.visible && this.props.systemUnread > 0) {
      this.props.me.markSystemMessagesRead();
    }
  }

  isIPad() {
    //const isIPad = document.documentElement.clientWidth < 1024;
    //return isIPad;
    return true
  }

  acceptBAA = () => {
    return this.props.me.acceptBAA().then(() => {
      this.toggleReviewBAA();
    })
  }
  

  toggleReviewBAA = () => {
    if (this.props.viewBAA) {
      return this.props.viewBAA()
    }
    this.setState({
      reviewBAA: !this.state.reviewBAA
    });
    return Promise.resolve();
  }


  setUnreadCount = count => {
    this.setState({
      unreadCount: count || 0
    })
    debugLog("sete unread count", count, this.props.setUnreadCount)
    if (this.props.setUnreadCount) this.props.setUnreadCount(count)
  }

  renderTodoList() {
    const isIPad = this.isIPad();
    return <UIMeCard cardId={'todo'} icon={TeTeLogo} searchPlaceholder={"Search To-do List"} searchTerm={this.state.searchTerm} onSearch={this.searchTodo} title={"To-do List" + (this.state.searchTerm ? " / " + this.state.searchTerm :  "")}>
      <UIMeTeTeFeed portal={this.portalRef.current} auth={this.state.auth} acceptedBAA={this.state.acceptedBAA} isIPad={isIPad} searchTerm={this.state.searchTerm} setUnreadCount={this.setUnreadCount} openChat={this.props.openChat} openAppointment={this.props.openAppointment} openSubscription={this.props.openSubscription} copyLink={this.copyLink} formLinkURL={this.formLinkURL} link={this.state.contactLink} showBusinessSetup={this.showBusinessSetup}
    scheduleAppointmentWith={this.props.scheduleAppointmentWith} scheduleChatAppointment={this.scheduleChatAppointment}
    openEvent={this.openEvent}
    rescheduleAppointment={this.rescheduleAppointment} me={this.props.me} onCompleted={this.onCompleted}/>
      </UIMeCard>
  }


  scheduleChatAppointment = (opts) => {
    const { event } = opts
    this.openEvent(event)
  }

  searchPlans = searchTerm => {
  }

  newWorkoutPlan = () => {
    this.setState({
      workoutPlan: {weeks: [{}]},
      formErr: {}
    })
  }

  renderPlans =  () => {
    return <div className='uiMeLinkTabContent uiMeTabPlans'>
      <FDSearchField search={this.searchPlans}/>
      <FDButton1 label='New Workout Plan' onClick={this.newWorkoutPlan} icon={PlusIcon}/>
      </div>
  }

  renderContactLink = () => {
    return <div className='uiMeLinkTabContent'>
      {false && <UIMeCard cardId={'contactLink'} key='contactLink' title={"Link"}>
       <UIMeContact portal={this.portalRef.current} me={this.props.me} type='contact' formLinkURL={this.formLinkURL} link={this.state.contactLink} copyLink={this.copyLink}/>
       </UIMeCard>}
    {this.renderWhoopConnect()}            
    {this.renderOuraConnect()}            
    {this.renderWithingsConnect()}            
    </div>
  }

  renderBusinessSetup() {
    return <UIMeCard cardId={'businessSetup'} key='businessSetup' title={"Business Setup"}>
      <div className='uiMeCardInsets'><UIMeConnectToStripe auth={this.state.auth} acceptedBAA={this.state.acceptedBAA} reviewBAA={this.toggleReviewBAA} me={this.props.me} formLinkURL={this.formLinkURL} link={this.state.contactLink} copyLink={this.copyLink}/></div>
      </UIMeCard>;
  }

  renderTab = (name, icon, value, tabClass) => {
    let className = 'uiMeTab';
    if (tabClass) {
      className += ' ' + tabClass
    }
    if (value == this.props.nav) {
      className += " uiMeTabSelected";
    }
    let unread = 0;
    if (value == 'todo') {
      unread = this.state.unreadCount || 0
    }
    className += ' uiMeTab' + name.replace(' ', '')
    debugLog('tab value: ', value, " unread: ", unread);
    return <div className={className} onClick={()=>this.selectTab(value)}>
      {<div key='icon' className={'uiMeTabIcon'}><ReactSVG src={icon}/></div>}
      <div key='label' className={'uiMeTabLabel'}>{name}</div>
      {unread > 0 && <div key='unread' className='uiSideListContactUnread'>{unread> 99 ? "99+" : unread}</div>}           
    </div>
  }

  selectTab = value => {
    if (this.props.nav === 'device' && value !== 'device') {
      this.deviceSetup.closeStream()
    }
    this.props.navigate(value);
  }

  setDeviceSetup = ref => {
    if (ref) {
      this.deviceSetup = ref
    }
  }

  tabs = [
    {
      name: this.props.me.isTodoList() ? 'Plans': 'Me',
      value: 'me',
      icon: this.props.me.isTodoList() ? Calendar : MeIcon,
      visible: () => true,
      content: () => this.renderCalendar(true)
    },
    {
      name: 'To-do',
      value: 'todo',
      icon: ToDoListIcon,
      visible: () => true,
      content: () => this.renderTodoList()
    },
    {
      icon: WhoopActivity,
      name: 'Workouts',
      value: 'activity',
      visible: () => !this.props.me.isTodoList(),
      content: () => this.renderActivity()
    },
    {
      className: 'uiMeTabSettings',
      icon: Settings,
      name: '',
      visible: ()=> isMobile() && this.props.me.isTodoList(),
      content: () => this.renderSettings()
    }
    /*
    {
      name: 'Device Setup',
      value: 'device',
      visible: () => true,
      content: (selected) => <UIDeviceSetup ref={this.setDeviceSetup} me={this.props.me} visible={this.props.visible && selected}/>
    },
    {
      name: 'Link',
      value: 'link',
      visible: () => true,
      content: () => this.renderContactLink()
    },
    */
  ]

  workoutToAppt = workout => {
    return {
      organizer: this.props.me.getContact(workout.trainer),
      contact: workout.activity,
      title: workout.description + ((workout.activity.id == 35 || workout.activity.uid == 59) && workout.weight ? ' ' + workout.weight + ' lbs' : ''),
      client: this.props.me.self.uid,
      start: workout.start,
      end: workout.end,
      scheduled: workout.scheduled,
      id: workout.id,
      workout: workout,
      scheduledWorkout: workout,
      uid: workout.trainer,
      status: !workout.status || workout.status == 'started' ? 'todo' : workout.status
    }
  }
  
  createEvent = (appt) => {
    const status = appt.status || "requested";
    const id = appt.id;
    const dur = appt.end - appt.start;
    const contact = appt.contact;
    const title = contact.displayName;
    const start = appt.start || appt.scheduled
    const end = appt.end || Date.now()
    const event = {
      id: id,
      start: new Date(start),
      end: new Date(end),
      text: title,
      desc: title,
      status: status,
      open: () => this.openEvent(event),
      ts: Date.now(),
      appt: appt,
      scope: appt.todo ? 'todo' : appt.weight ? 'weight' : 'workouts'
    }
    return event
  }
  
  renderScheduledWorkouts = () => {
    const workouts = this.state.feed
    return workouts.map(workout => {
      const id = workout.id
      const appt = this.workoutToAppt(workout)
      let onClick
      if (workout.trainer == this.props.me.self.uid && (workout.status == 'todo' || !workout.status)) {
        onClick = () => {
          this.setState({
            openEvent: this.createEvent(appt)
          })
        }
      }
      const start = new Date(appt.start)
      const end = new Date(appt.end)
      const contact = workout.client === this.props.me.self.uid ? this.props.me.self : this.props.me.getContact(workout.client)
      return <div className='uiScheduleAppointmentRecentWorkoutCardWithMessage'>
        <div className='uiScheduleAppointmentRecentWorkoutCardMessage'><UIProfileIcon contact={contact}/><div className='uiWorkoutCardMessageName'>{contact.displayName}</div></div>
        <div className='uiScheduleAppointmentRecentWorkoutCard'>
        <UIAppointment inactive={false} me={this.props.me} appt={appt} organizer={appt.organizer} client={appt.client} status={appt.status} appointment={appt} id={appt.id} editable={false} start={start} title={appt.title} end={end} with={appt.contact} onClick={onClick}/>
        </div>
        </div>
    })
  }

  onSearchIconClick = () => {
    this.setState({
      recentSearchTerm: ''
    })
  }

  renderSearchField = () => {
      return <div className='uiCalendarSearch'>
      <input placeholder={'Search'} value={this.state.recentSearchTerm}   onFocus={this.onSearchFocus} onBlur={this.onSearchBlur}
    onChange={this.onSearchTermChanged} ref={this.setSearchInput} className='uiCalendarSearchField'/>
      <div className='uiCalendarSearchIcon' onClick={this.onSearchIconClick}>
      <ReactSVG src={this.state.recentSearchTerm ? Cross: Search}/>
      </div>
      </div>
  }

  getActivityIcon = () => {
    return this.props.me.getWhoop().getActivityIcon()
  }
  
  searchContacts = searchTerm => this.searchImpl('contacts', searchTerm)
  searchActivities = searchTerm => this.searchImpl('workouts', searchTerm)

  searchImpl = (scope, searchValue) => {
    const searchTerms = searchValue.toLowerCase().split(/\s+/);
    const matches = {};
    const filter = x => {
      if (!searchValue) return true;
      let matched = 0;
      const name = x.displayName.toLowerCase();
      const terms = name.split(/\s+/);
      searchTerms.map(searchTerm => {
        terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
      });
      matches[x.uid] = matched;
      return matched > 0;
    }
    const sort = (x, y) => {
      const w1 = matches[x.uid] || 0;
      const w2 = matches[y.uid] || 0;
      const cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      return x.displayName ? y.displayName ? x.displayName.localeCompare(y.displayName) : 1 : -1;
    }
    let result
    if (scope == 'workouts') {
      result = this.activityOptions.filter(filter)
    } else {
      result = this.props.me.getCurrentContacts().filter(filter);
    }
    result.sort(sort);
    return result;
  }

  onSearchTermChanged = e => {
    this.setState({
      recentSearchTerm: e.target.value
    }, this.updateFeedNow)
  }

  onChangeSearch = (field, value) => {
    const e = this.state.formError;
    this.setState({error: "", formError: {}});
    if (this.state.searchForm[field] != value) {
      this.state.searchForm[field] = value;
      this.updateFeedNow()
    }
  }

  onChange = (field, value) => {
    const e = this.state.formError;
    this.setState({error: "", formError: {}});
    if (this.state.form[field] != value) {
      debugLog("onChange: ", field, " => ", value);
      if (this.props.onChange) this.props.onChange(field, value);
      this.state.form[field] = value;
      this.forceUpdate();
      if (field == 'with' && value) {
        this.autofill();
      }
    }
  }
  renderActivity = () => {
    return <div key='activity-all' className='uiWorkoutCardList uiScheduleAppointmentWorkoutRecentList'>
      <div className='uiWorkoutCardListContent'>
      {this.renderSearchField()}
      <div key="workout-type" className='uiScheduleAppointmentBodyField uiScheduleAppointmentActivity uiScheduleAppointmentActivityFilterField'>
      <UIInputField defaultValue={{displayName: '', uid: 'empty', profileImage: this.getActivityIcon()}} label={'Activity'} placeholder={''} error={this.state.formError} value={this.state.searchForm.activity} icon={Checkmark} name='activity' search={this.searchActivities} onChange={this.onChangeSearch} type='contact'/>
      </div>
      <div key="client" className='uiScheduleAppointmentBodyField uiScheduleAppointmentWithFilterField'>
      <UIInputField label={'Client'} search={this.searchContacts} value={this.state.searchForm.with} error={this.state.formError} icon={ProfileSml} name='with' onChange={this.onChangeSearch} type='contact'/>
      </div>
      <div key='workout-scroller' className='uiMeWorkoutCardScroller'>
      {this.renderScheduledWorkouts()}
      </div>
      </div>
      </div>
  }

  render() {
    return this.renderTopLevel()
  }
  

  openSummary = (title, url) => {
    this.setState({
      openSummaryTitle: title,
      openSummaryURL: url
    })
  }

  closeDocument = () => {
    this.setState({
      openSummaryURL: null,
      openDocumentNumPages: 0
    })
  }

  closeEvent = () => {
    this.setState({
      openEvent: null
    })
  }

  onClickProfile = (profile, cycleData, meals, weights, category) => {
    const openProfile = {type: profile.type, profile, cycleData, meals, weights, category}
    debugLog('openProfile', openProfile)
    this.setState({
      openProfile
    })
  }

  openEvent = event => {
    if (event.appt && event.appt.workout && event.appt.workout.scheduled) {
      const workout = event.appt.workout
      if (workout.client == this.props.me.self.uid &&
          !workout.status) {
        // goto todo list
        this.props.navigate('todo')
      }
      return
    }
    if (event.scope || (event.appt && (event.appt.meal || event.appt.sleep || event.appt.workout || (event.appt.weight && event.appt.weight.editable)))) {
      this.setState({
        openEvent: event
      })
    }
    this.setState({
      openEvent: event
    })
  }
  
  modifyWorkout = async (form) => {
    const data = this.state.openEvent;
    const { activity, description, demoFile, sets, reps, weight, sport } = form
    let { demo} = form
    //debugger
    const appt = data.appt
    const prev = appt ? appt.workout : null
    const date = data.date;
    if (demoFile) {
      const ref = await this.props.me.uploadFile(demoFile)
      const downloadURL = await ref.getDownloadURL()
      demo = [{
        contentType: demoFile.type,
        downloadURL
      }]
    }
    if (!demo ) {
      if (prev) {
        demo = prev.demo
      }
    }
    const workout = {
      description,
      sets,
      reps,
      weight,
      demo: demo,
      activity: activity
    }
    if (prev) {
      workout.id =  prev.id
    }
    const uid = prev ? prev.client : this.state.openEvent.with.uid
    await this.props.me.saveWorkout({uid: uid}, workout)
  }
  
  rescheduleAppointment = (appt) => {

    const id = appt.id;
    const data = this.state.openEvent;
    const date = data.date;
    const start = getTime(date, data.start);
    const end = getTime(date, data.end);
    const prevStart = appt.start;
    const prevEnd = appt.end;
    const prevInvoiceAmount = appt.invoiceAmount || 0;
    const prevInvoiceDescription = appt.invoiceDescription || "";
    const prevTitle = appt.title || "";
    const updates = {
      id: id,
      start: start,
      end: end,
      invoiceDescription: this.state.openEvent.invoiceDescription || "",
      invoiceAmount: this.state.openEvent.invoiceAmount || 0,
      title: this.state.openEvent.title || "",
    }
    if (updates.start == prevStart &&
        updates.end == prevEnd &&
        updates.invoiceDescription == prevInvoiceDescription &&
        updates.invoiceAmount == prevInvoiceAmount &&
        updates.title == prevTitle) {
      ////debugLog("no change");
      this.closeEvent();
      return Promise.resolve();
    }
    const p = this.props.waitForSystemUpdate(appt);
    return this.props.me.updateAppointment(updates).then(response => {
      const appt = this.state.openEvent.appt;
      if (!response.data.error) {
        this.closeEvent();
      }
      if (response.data.error) {
        return response;
      }
      return p;
    });
  }

  scheduleTodo = async form => {
    const appt = this.state.openEvent.appt
    const prev = appt.todo.todo
    const updates = {}
    for (const field of ['task', 'category', 'location', 'term', 'summary', 'how']) {
      if (form[field] !== prev[field]) {
        updates[field] = form[field]
      }
    }
    /*
    const { task, category, location, term, summary } = form
    const updates = {
      todo: { task, category, location, term, summary }
    }
    */
    console.log("saveTodo", updates)
    const arg = {
      todo: updates
    }
    if (appt && appt.id) {
      arg.id = appt.id
    }
    await this.props.me.saveTodo(arg)
  }

  scheduleMeal = async (form) => {
    const data = this.state.openEvent;
    const { mealSelection, foods } = form
    const date = form.date
    const start = form.start
    const end = form.end
//    ////debugger
    const meal = {
      id: data.appt ? data.appt.id : undefined,
      start: getTime(date, start),
      end: getTime(date, end),
      foods: foods,
      type: mealSelection
    }
    await this.props.me.saveMeal(meal)
  }


  scheduleWeight = async (form) => {
    const data = this.state.openEvent;
    const { date, start, weight } = form
    const rec = {
      id: data.appt ? data.appt.id : undefined,
      created: getTime(date, start),
      weight
    }
    await this.props.me.saveWeight(rec)
  }

  scheduleAppointment = (form) => {
    const data = this.state.openEvent;
    if (data.scope == 'meals' || (data.appt && data.appt.meal)) {
      return this.scheduleMeal(form).then(() => {
        debugLog('saved meal')
        this.setState({
          openEvent: null
        })
      })
    }
    if (data.scope == 'weight' || (data.appt && data.appt.weight)) {
      return this.scheduleWeight(form).then(() => {
        debugLog('saved weight')
        this.setState({
          openEvent: null
        })
      })
    }
    if (data.appt.todo) {
      return this.scheduleTodo(form).then(this.closeEvent)
    }
    if (data.appt.workout) {
      return this.modifyWorkout(form).then(() => {
        this.closeEvent()
      })
    }
    let p;
    if (data.appt) {
      p = this.rescheduleAppointment(data.appt);
    } else  {
      const contact = data.with;
      const date = data.date;
      const start = data.start;
      const end = data.end;
      const updates = {
        start: getTime(date, start),
        end: getTime(date, end),
        title: data.title,
        invoiceDescription: data.invoiceDescription,
        invoiceAmount: data.invoiceAmount,
        title: data.title,
      }               
      window.showProgressIndicator("Scheduling");
      p = this.props.me.createAppointment(contact, updates);
    }
    return p.then(() => {
      this.setState({
        openEvent: null,
        addAppointment: null,
        calendarDate: null,
      });
    }).catch(err => {
      this.state.openEvent.error = err.message
      this.forceUpdate()
    })
  }
  
  renderAppointmentPopup = (withReadOnly) => {
    const self = this
    if (!this.state.openEvent ||
        (this.state.openEvent.appt && ((this.state.openEvent.appt.workout && !this.state.openEvent.appt.scheduledWorkout)
                                       ||
                                       this.state.openEvent.appt.sleep
                                      ))) return null
    let scope = this.state.openEvent.scope
    //debugger
    if (!scope) {
      const appt = this.state.openEvent.appt
      if (appt) {
        if (appt.workout) {
          scope = 'workout'
        }
        if (appt.meal) {
          scope = 'meals'
        }
        if (appt.todo) {
          scope = 'todo'
        }
        if (appt.weight) {
          scope = 'weight'
        }
      }
    }
    let trash
    if (this.state.openEvent.appt && this.state.openEvent.appt.meal) {
      trash = this.trashMeal
    } else if (this.state.openEvent.appt && this.state.openEvent.appt.scheduledWorkout) {
      trash = this.trashWorkout
    } else if (this.state.openEvent.appt && this.state.openEvent.appt.weight) {
      trash = this.trashWeight
    }
    return <div className='uiScheduleAppointmentPopup'>
      <UIScheduleAppointment 
    appointmentId={this.state.openEvent.id}
    back={() => this.setState({
      openEvent: null,
    })}
    trash={trash}
    scope={scope}
    isNew={this.state.openEvent.isNew}
    withReadOnly={withReadOnly}
    date={this.state.openEvent.date}
    start={this.state.openEvent.start}
    end={this.state.openEvent.end}
    headerTitle={"Schedule Appointment"}
    title={this.state.openEvent.title}
    with={this.state.openEvent.with}
    on={this.state.openEvent.date}
    schedule={this.scheduleAppointment}
    error={this.state.openEvent.error}
    onChange={this.onChangeEvent}
    editable={this.state.openEvent.editable}
    client={this.state.openEvent.client}
    invoiceAmount={this.state.openEvent.invoiceAmount || 0}
    invoiceDescription={this.state.openEvent.invoiceDescription}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    status={this.state.openEvent.status}
    paymentStatus={this.state.openEvent.paymentStatus}
    paymentMethod={this.state.openEvent.paymentMethod}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    event={this.state.openEvent}
    appt={this.state.openEvent.appt}
    me={this.props.me}
    mealSelection={this.state.openEvent.mealType}
      />
      </div>
  }

  trashMeal = async () => {
    const appt = this.state.openEvent.appt
    await this.props.me.deleteMeal(appt.meal.id)
    this.setState({
      openEvent: null
    })
  }

  trashWeight = async () => {
    const appt = this.state.openEvent.appt
    await this.props.me.deleteWeight(appt.weight.id)
    this.setState({
      openEvent: null
    })
  }

  trashWorkout = async () => {
    const appt = this.state.openEvent.appt
    await this.props.me.deleteWorkout(appt.workout.id)
    this.setState({
      openEvent: null
    })
  }

  renderCalendar = (visible) => {
    const opts = {
      openEvent: this.openEvent,
      closeEvent: this.closeEvent,
      onClickProfile: this.onClickProfile,
      disableAppointmentPopup: true,
      openSummary: this.openSummary
    }
    const onLoadCycleData = (cycles) => {
    }
    const onLoadMeals = meals => {
    }
    const setCal = cal => {
      this.cal = cal
    }
    opts.onLoadCycleData = onLoadCycleData
    opts.onLoadMeals = onLoadMeals
    return <div className='uiActiveContactCalendar' style={visible ? null: {display: 'none'}}>
      <UICalendar isMe={true} initialPage='day' opts={opts} contactFilter={this.props.me.self} picker={false} visible={visible} onSet={setCal} me={this.props.me}/>
      </div>
  }

  closeProfile = () => {
    this.setState({
      openProfile: null
    })
  }

  closeWorkoutPlan = () => {
    this.setState({
      workoutPlan: null
    })
  }

  saveWorkoutPlan = () => {
  }

  onWorkoutFormChange = (field, value) => {
    this.state.workoutPlan[field] = value
    this.forceUpdate()
  }

  renderTopLevel = () => {
    let subpage
    let subpageIndex = 0
    let popup
    let nonMobilePopup
    if (this.state.openProfile) {
      subpage = <FDPage safeArea={true} embed={true} title={this.state.openProfile.category.name} me={this.props.me} back={this.closeProfile}>
        <DataCharts options={
          {
            category: this.state.openProfile.category,
            type: this.state.openProfile.type,
            cycleData: this.state.openProfile.cycleData,
            meals: this.state.openProfile.meals,
            weights: this.state.openProfile.weights,
          }
        }/>
        </FDPage>
    }      
    else if (this.state.openSummaryURL) {
      const onLoad = ({numPages}) => {
        //debugger
        this.setState({
          openDocumentNumPages: numPages
        })
      }
      const onLoadErr = e => {
        //debugger
      }
      const pages = []
      if (this.state.openDocumentNumPages) {
        for (let i = 0; i < this.state.openDocumentNumPages && i < 5; i++) {
          pages.push(i+1)
        }
      }
      const loading = <div className='uiPDFLoading'>
            <ReactSVG src={Spinner}/>
            </div>
      subpage = <FDPage safeArea embed={true} title={this.state.openSummaryTitle} me={this.props.me} back={this.closeDocument}>
        <Document loading={loading} file={this.state.openSummaryURL} onLoadSuccess={onLoad} onLoadError={onLoadErr} renderMode={'svg'}>
        {pages.map(i => <Page key={i} pageNumber={i}/>)}
        </Document>
      </FDPage>
    } else if (this.state.openEvent) {
      if ((this.state.openEvent.scope == 'weight' || this.state.openEvent.scope == 'todo' || this.state.openEvent.scope == 'meals') && !this.state.openEvent.appt) {
        if (!isMobile()) {
          nonMobilePopup = this.renderAppointmentPopup(true)
        }
      } else if (this.state.openEvent.appt && this.state.openEvent.appt.sleep) {
        const contact = this.state.openEvent.appt.organizer
        subpage = <FDPage safeArea embed={true} title="Sleep" me={this.props.me} back={this.closeEvent}>
          <WhoopSleep sleep={this.state.openEvent.appt.sleep} contact={contact} me={this.props.me}/>
          </FDPage>
      } else if (this.state.openEvent.appt && this.state.openEvent.appt.workout && !this.state.openEvent.appt.workout.scheduled) {
        const contact = this.state.openEvent.appt.organizer
        subpage = <FDPage safeArea embed={true} title="Workout" me={this.props.me} back={this.closeEvent}>
          <WhoopWorkout workout={this.state.openEvent.appt.workout} contact={contact} me={this.props.me}/>
          </FDPage>
      } else if (this.state.openEvent.appt && (this.state.openEvent.appt.todo || this.state.openEvent.appt.scheduledWorkout || (this.state.openEvent.appt.weight && this.state.openEvent.appt.weight.editable))) {
          if (!isMobile()) {
            nonMobilePopup = this.renderAppointmentPopup(true)
          }
      } else if (this.state.openEvent.appt && this.state.openEvent.appt.meal) {
        const appt = this.state.openEvent.appt;
        if (appt.organizer.uid == this.props.me.self.uid) {
          if (!isMobile()) {
            nonMobilePopup = this.renderAppointmentPopup(true)
          }
        } else {
          const meal = appt.meal
          const when = formatDate(new Date(appt.start))
          const nutrients = toFDA(meal.foods)
          subpage = <FDPage safeArea embed={true} title="Meal" me={this.props.me} back={this.closeEvent}>
            <div className='uiMealDetails'>
            <div className='uiMealDetailsTitle'>{capitalize(meal.type)}</div>
            <div className='uiMealDetailsWhen'>
            <div className='uiMealDetailsWhenDate'>{when}</div>
            </div>
            <div className='uiMealDetailsFoods'>
            {
              meal.foods.map(food => {
                const name = getFoodName(food)
                let calories = Math.round((food.nutrition ? food.nutrition.nf_calories : food.nf_calories) * (food.count || 1))
                return <div className='uiBodyFood'>
                  <div className='uiBodyFoodNameAndImage'>
                  <div className='uiBodyFoodCount'>{formatFoodCount(food)}</div>
                  <FoodLogo food={food}/><div className='uiBodyFoodName'>{name}</div></div><div className='uiBodyFoodCal'>{calories ? `${calories} Cal` : ''}</div>
                  <div className='uiBodyFoodDelete' onClick={()=>this.deleteFood(food)}>
                  </div>
                  </div>
              })
            }
          </div>
            <div className='uiMealNutrition'>
            <NutritionLabel {...nutrients}/>
            </div>
            </div>
            </FDPage>
        }
      } 
    }
    if (this.state.popup) {
      popup = this.state.popup()
    } else {
      if (isMobile()) {
        popup = this.renderAppointmentPopup(true)
      }
    }
    return <FDPage safeArea noHeaderButtons noHeader={ !isMobile() } customSearch={isMobile() ? this.setSearchPortal: null} me={this.props.me} subPage={subpage} popup={popup || nonMobilePopup}>
      {this.renderMe()}
    </FDPage>
  }

  setSearchPortal = ref => {
    if (ref && ref != this.searchPortal) {
      this.searchPortal = ref
      this.forceUpdate()
    }
  }


  renderWhoopConnect = () => {
    return <div className='uiMeWhoopConnect'>
      <UIMeCard key='whoop' title='Whoop'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.whoopLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={WhoopIcon} ok={this.linkWhoop} label={(this.state.whoopLinked ? 'Unlink' :'Link') + ' your Whoop strap'}/>
      </div>
      </UIMeCard>
      </div>
  }

  renderWithingsConnect = () => {
    return <div className='uiMeWhoopConnect uiMeConnectWithings'>
      <UIMeCard key='whoop' title='Withings'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.withingsLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={ScaleIcon} ok={this.linkWithings} label={(this.state.withingsLinked ? 'Unlink' :'Link') + ' your Withings scale'}/>
      </div>
      </UIMeCard>
      </div>
  }

  renderOuraConnect = () => {
    return <div className='uiMeWhoopConnect'>
      <UIMeCard key='oura' title='Oura'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.ouraLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={OuraIcon} ok={this.linkOura} label={(this.state.ouraLinked ? 'Unlink' :'Link') + ' your Oura ring'}/>
      </div>
      </UIMeCard>
      </div>
  }


  renderSettings = () => {
    return this.props.renderSettings({})
  }
  
  
  renderMe() {
    if (!this.props.me) return null;

    let tabs
    if (this.props.me.isTodoList()) {
      tabs = [this.tabs[1], this.tabs[0], this.tabs[3]]
    } else {
        tabs = this.tabs.filter(tab => tab.value !== 'activity' || this.state.showWorkouts)
    }
    let selectedTab = tabs.find(tab => tab.visible() && tab.value == this.props.nav);
    const displayName = this.props.me.self.displayName || "";
    const isIPad = this.isIPad();      
    const c1 = ()=><div className='uiMeBodyColumn1'>
          {this.renderTodoList()}
    </div>;
    let renderedTabs = <div className='uiMeTabsBg'>
        <div className='uiMeTabs'>
        {tabs.filter(tab => tab.visible(true)).map(tab => this.renderTab(tab.name, tab.icon, tab.value, tab.className))}
    </div>
      </div>
      if (this.searchPortal) {
        renderedTabs = ReactDOM.createPortal(renderedTabs, this.searchPortal)
      }
    let allContent = 'uiMeTabsContentAll'
    let renderedTabsContent = <div className={allContent}>
        {tabs.filter(tab => tab.visible()).map(tab => {
          return <div key={tab.value} className='uiMeTabsContent' style={tab.value == selectedTab.value ? null : {display: 'none'}}>
            {tab.content(tab.value === selectedTab.value)}
          </div>
        })
        }
    </div>
      let scrollFun = ''
    if (selectedTab.value === 'todo') {
      scrollFun = " uiMeBodyContainerNoScrollTodo"
    }
      return <div className='uiMe' style={this.props.visible ? null : {display: "none"}}>
      <div className='uiMeTitle'>Home</div>
      {isIPad ?
       <div className={'uiMeBodyContainer uiMeBodyContainerNoScroll' + scrollFun}>
       <div className='uiMeBody1Column'>
       <div className='uiMeTabsContainer'>
       {renderedTabs}
       {renderedTabsContent}
       </div>
       </div>
       </div>:
       <div className={'uiMeBodyContainer' + scrollFun}>
       <div className='uiMeBody'>
       {c1()}
       </div>
       </div>}
      <div ref={this.portalRef}>{this.state.reviewBAA &&
                                 <ClickAwayListener onClickAway={this.toggleReviewBAA}><UIMeBAA download={this.props.me.downloadBAA} acceptedBAA={this.state.acceptedBAA} cancel={this.toggleReviewBAA} accept={this.acceptBAA}/></ClickAwayListener>}</div>
      
    </div>
  }
}
