import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { UIOKCancel } from '../OKCancel'
import { Dots } from '../Dots'
import { unicodeEmojiToSprite } from '../Chat'
import { UIProfileIcon } from '../ProfileIcon'
import ChatEdit from "../../assets/icons/ChatEdit.svg";
import ChatDelete from "../../assets/icons/Delete.svg";
import { formatDate, formatStartEndTime } from "../Appointment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReactSwipeableViews from 'react-swipeable-views'
import Check from "../../assets/icons/CheckMark.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import Forward from "../../assets/icons/Forward.svg";
import ImageIcon from "../../assets/icons/Image.svg";
import Trash from "../../assets/icons/Trash.svg";
import Cross from "../../assets/icons/Cross.svg";
import Update from "../../assets/icons/Update.svg";
import Complete from "../../assets/icons/Stop.svg";
import ToDoListIcon from "../../assets/icons/ToDoList.svg";
import moment from 'moment'
import scrollIntoView from 'scroll-into-view'
import './index.css'
const Box = Check


const Emojis = {
  "ashamed": "😳",
  "embarrased": "😳",
  "excited": "🤩",
  "excitement": "🤩",
  "amusement": "😃",
  "focus": "🧐",
  "focused": "🧐",
  "anguished": "😧",
  "anguish": "😧",
  "concern": "🧐",
  "discomfort": "😧",
"envy": "💕",
"disdain": "🤢",
"resentment": "🤬",
"hate": "🖕",
"shame": "🙈",
"enjoyment": "👍",
"joy": "👍",
"cold": "🥶",
"hot": "🔥",
"remorse": "🙏",
"pain": "🤕",
"thirst": "💧",
"pleasure": "🎭",
"sulky": "🙃",
"resolved": "🎓",
"disgruntled": "😡",
"irritated": "😡",
"bravery": "💪",
"resolute": "💪",
"courageous": "💪",
"exasperated": "😡",
"dissatisfied": "😕",
"content": "💓",
"relieved": "😊",
"disappointed": "😕",
"anxious": "😰",
"baffled": "😕",
"befuddled": "😕",
"confused": "😕",
"dazed": "😯",
"dejected": "😔",
"doubtful": "🤔",
"puzzled": "😕",
"scared": "😰",
"stressed": "😰",
"nonplussed": "😕",
"confounded": "😕",
"perplexed": "😕",
"discombobulated": "😕",
"bemused": "😃",
"vexed": "😡",
"bothered": "😕",
"agitated": "😰",
"disturbed": "😕",
"uncomfortable": "😕",
"unsure": "🤔",
"alarmed": "😰",
"apprehensive": "😰",
"distressed": "😰",
"troubled": "😕",
"uneasy": "😕",
"unsettled": "😕",
"upset": "😕",
"irritated": "😡",
"awed": "😳",
"astonished": "😲",
"awed": "😳",
"bewildered": "😕",
"dumbfounded": "😕",
"disbelieving": "😕",
"flabbergasted": "😕",
"shaken": "😰",
"stunned": "😳",
"unnerved": "😕",
"rattled": "😰",
"startled": "😰",
"astonished": "😲",
"astounded": "😲",
"cheerful": "🎉",
"content": "💓",
"happy": "🎊",
"jubilant": "🎉",
"pleased": "💕",
"delighted": "🎭",
"ecstatic": "💃",
"triumphant": "🎉",
"grateful": "🙏",
"encouraged": "💪",
"inspired": "💪",
"exuberant": "🎉",
"energetic": "💪",
"sullen": "🙃",
"miserable": "😔",
"sad": "😢",
"depressed": "😔",
"moody": "🙃",
"gloomy": "🙃",
"melancholy": "😔",
"glum": "🙃",
"gloomy": "🙃",
"melancholy": "😔",
"forlorn": "😔",
  "lonely": "😥",
  "relaxed": "👌",
  "curiosity": "🔬",
  "curious": "🔬",
  "satisfaction": "💯",
  'grief': '💔',
  'high-anxiety': '😰',
  'anxiety': '😅',
  'low-anxiety': '😅',
  'boredom': '🥱',
  'bored': '🥱',
  'fatigue': '😪',
  'sadness': '💔️',
  'hungry': '😋',
  'hunger': '😋',
  'craving': '😋',
  'love': '😍',
  'frustration': '😖',
  'anger': '😡',
  'uncomfortable': '😅',
  'worried': '😟',
  'worry': '😟',
  'anger and frustration': '😡😖',
  'anger or frustration': '😡😖',
  'smh': '🤦',
  'none': '😑',
  'motivation': '💪',
  'motivated': '💪',
  'happiness': "🙂",
  'anguish': '😧',
"inactive": "🛌",
  "serene": "😊",
  "guilt": "😬",
  "guilty": "😬",
"calm": "💤",
"sleepy": "💤",
"exhausted": "💤",
"exhaustion": "💤",
"tired": "💤",
"chill": "☕️",
"comfortable": "😴",
"boredom": "😩",
"bored": "😩",
"disinterest": "✋️",
"indifferent": " 🙃",
"apathy": "😕",
"apathetic": "😕",
"depression": "☹️",
  "defeat": "😞",
  "defeated": "😞",
  "fear": "😨",
  "fearful": "😨",
  "dread": "😱",
  "comatose": "😷",
  ":smiling_face:": "😂",
"resigned": "😕",
"unmoved": "🤷️",
"unperturbed": "👌",
"disengaged": "😔",
"hopelessness": "💀",
"disgust": "😫",
"appalled": "😰",
"disdainful": "🥺",
"contemptuous": "😡",
"disapproving": "🤕",
"nauseated": "🤢",
"horrified": "😱",
"scornful": "🖕",
"revolted": "😷",
}

export const getEmojiFromTodo = todo => {
  return Emojis[todo.emotion]
}

export const getEmojiForEmotion = emotion => {
  return Emojis[emotion]
}

const capitalize = x => (x && x[0].toUpperCase() + x.substring(1)) || x

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

export class LBTodoCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      outcome: 0,
      notes: ''
    }
  }

  componentDidMount() {
  }

  done = async () => {
    const todo = this.props.todo
    await this.props.me.markTodoDone(todo)
    if (this.props.waitForSystemUpdate) {
      await this.props.waitForSystemUpdate()
    }
  }

  startEndTodo = async () => {
    const todo = this.props.todo
    let result
    if (todo.status == 'started') {
      result = await this.props.me.completeTodo(todo)
    } else {
      result = await this.props.me.startTodo(todo)
    }
    debugLog('startTodo:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    if (this.props.waitForSystemUpdate) {
      await this.props.waitForSystemUpdate()
    }
  }

  editTodo = async () => {
    debugger
    return this.props.edit(this.props.appt)
  }

  trashTodo = async () => {
    const item = this.props.todo
    await this.props.me.updateTodo({status: 'canceled', id: item.id})
  }

  cancelMarkDone = async () => {
    this.setState({
      markingDone: false,
      recordingProgress: false
    }, this.popScroll)
  }

  showRecordProgress = async () => {
    this.pushScroll()
    this.setState({
      markingDone: true,
      recordingProgress: true
    }, () => {
        this.scrollToVisible()
      })
  }

  getScrollParent = node => {
    const regex = /(auto|scroll)/
    const parents = (_node, ps) => {
      if (_node.parentNode === null) { return ps; }
      return parents(_node.parentNode, ps.concat([_node]));
    };
    
    const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
    const overflow = _node => style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');
    const scroll = _node => regex.test(overflow(_node));
    
    /* eslint-disable consistent-return */
    const scrollParent = (_node) => {
      if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
        return;
      }
      
      const ps = parents(_node.parentNode, []);
      
      for (let i = 0; i < ps.length; i += 1) {
        if (scroll(ps[i])) {
          return ps[i];
        }
      }
      
      return document.scrollingElement || document.documentElement;
    };
    
    return scrollParent(node);
    /* eslint-enable consistent-return */
  }

  pushScroll = () => {
    const n = this.getScrollParent(this.outerRef)
    this.scroller = n
    this.scrollPos = n.scrollTop
  }

  popScroll = () => {
    this.scroller.scrollTop = this.scrollPos
  }

  recordProgress = async () => {
    const item = this.props.todo
    const result = await this.props.me.updateTodo({status: 'progress', id: item.id, outcome: this.state.outcome, notes: this.state.notes})
    debugger
    this.setState({
      markingDone: false,
      recordingProgress: false
    }, this.popsScroll)
  }

  markDone = async () => {
    if (this.state.markingDone) {
      const item = this.props.todo
      await this.props.me.updateTodo({status: 'done', id: item.id, outcome: this.state.outcome, notes: this.state.notes})
      this.setState({
        markingDone: false
      }, this.popScroll)
    } else {
      this.pushScroll()
      this.setState({
        markingDone: true
      }, () => {
        this.scrollToVisible()
      })
    }
  }

  setOuterRef = x => {
    this.outerRef = x
  }

  setRef = x => {
    this.ref = x
  }

  scrollToVisible = () => {
    scrollIntoView(this.ref, { time: 500, ease: t => t - Math.pow(1 - t, 7) })
  }

  onChangeNotes = e => {
    this.setState({
      notes: e.target.value
    })
  }

  renderProgressEditor = (placeholder, outcomePrompt, form, onChange, okLabel, okIcon, ok, cancel) => {
    const onChangeNotes = e => onChange('notes', e.target.value)
    return <div ref={this.setRef} className='lbTodoCardMarkDoneContainer'>
      <div className='lbTodoCardProgressNotes'>
        <textarea value={form.notes} placeholder={placeholder} onChange={onChangeNotes}/>
      </div>
       <div key='outcomeContainer' className='lbTodoCardOutcomeContainer'>
       <div className='lbTodoCardOutcomeTitle'>{outcomePrompt}</div>
       <div className='lbTodoCardOutcomeSlider'>
       <div className='lbTodoCardOutcomeSliderRow1'>
       <div className='lbTodoCardOutcomeWorse'>It was worse</div>
       <div className='lbTodoCardOutcomeAsExpected'>Yes, as expected</div>
       <div className='lbTodoCardOutcomeBetter'>It was better</div>
       </div>
       <div className='lbTodoCardOutcomeSliderRow2'>
       {[-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5].map(outcome => {
         let className='lbTodoCardOutcome'
         if (form.outcome === outcome) {
           className += ' lbTodoCardOutcomeSelected'
         }
         const onClick = () => {
           onChange('outcome', outcome)
         }
         return <div key={outcome} onClick={onClick} className={className}>{outcome}</div>
       })}
       </div>
      </div>
      </div>
      <div className='lbTodoCardProgressEditorButtons'>
      <UIOKCancel okIcon={okIcon} ok={ok} cancel={cancel} cancelIcon={Cross} label={okLabel}/>
      </div>
      </div>
  }

  render () {
    const me = this.props.me
    const item = this.props.todo
    const todo = item.todo
    const isActive = item.status === 'pending' && !this.props.inactive
    //debugLog("todo:", todo)
    //console.log("render todo", isActive, this.props.todo)
    let statusClass = 'lbTodoStatusReadyToStart'
    let editIcon = Edit
    let edit = this.editTodo
    let okLabel = 'Edit'
    let trash = this.trashTodo
    let markDone = this.markDone
    let canUpload = false
    const start = item.todo.date && item.todo.date > item.start ? new Date(item.todo.date) : item.start ? new Date(item.start) : undefined
    const end = item.end ? new Date(item.end) : undefined
    const formatStartDate = () => {
      if (start.getHours() === 0 && start.getMinutes() === 0 && start.getSeconds() === 0) {
        return formatDate(start)
      }
      return moment(start.getTime() - this.props.me.utcOffset).calendar()
    }

    switch (item.status) {
      case 'pending':
        statusClass = 'lbTodoStatusStarted'
        break
      case 'progress':
      case 'done':
        statusClass = 'lbTodoStatusCompleted'
        break
      case 'canceled':
        statusClass = 'lbTodoStatusCanceled'
        break
    }


    let canceled = item.status === 'canceled'
    const category = item.todo.category || 'General'
    let location = capitalize(category)
    if (todo.location && todo.location.toLowerCase() != 'n/a') {
      location = capitalize(todo.location)
    } 

    let activity = {
      profileImage: ToDoListIcon,
    }
    const emotions = todo.emotion.toLowerCase().split('/')
    const emojis = emotions.map(emotion => {
      if (emotion === 'anxiety') {
        if (todo.['anxiety-level']) {
          emotion = todo['anxiety-level'] + '-anxiety'
        }
      }
      let intensity = todo.intensity
      let emoji
      if (intensity) {
        intensity = intensity.toLowerCase()
        if (intensity == 'medium') intensity = 'moderate'
        emoji = Emojis[intensity + '-' + emotion]
      }
      if (!emoji) {
        emoji = Emojis[emotion]
      }
      if (!emoji) {
        emoji = todo.emotion
      }
      return emoji
    })
    let notesPrompt
    let outcomePrompt
    let buttonLabel
    let buttonIcon
    if (!this.state.recordingProgress) {
      notesPrompt = 'Describe the outcome'
      outcomePrompt = 'Was the outcome as you expected?'
      buttonLabel = 'Mark Done'
      buttonIcon = Check
    } else {
      notesPrompt = 'Describe your progress'
      outcomePrompt = 'Did your progress match your expectations?'
      buttonLabel = 'Record Progress'
      buttonIcon = Update
      markDone = this.recordProgress
    }
    let progresses = []
    if (item.progress) {
      progresses = progresses.concat(item.progress)
      progresses.sort((x, y) => {
        return y.end - x.end
      })
    }
    const form = this.state
    const onChange = (field, value) => {
      const updates = {}
      updates[field] = value
      this.setState(updates)
    }
    let taskEmoji
    if ((!item.userEdit && item.todo.emojis)) {
      taskEmoji = item.todo.emojis
    }
    return <div ref={this.setOuterRef} className={'lbTodoCard' + (this.props.onClick ? ' lbTodoCardClickable' : '') } >
      <div className={ 'lbTodoCardStatus' + ' ' + statusClass } />

      <div className={'lbTodoCardBody'} onClick={this.props.onClick}>
      <div className='lbTodoCardHeader' >
      <div className='lbTodoCardTitle'>
      <div className='lbTodoCardSummary'>
      {todo.summary ? capitalize(todo.summary) : this.props.me.self.displayName}&nbsp;<div className='task-emojis'>{!item.userEdit ? emojis.map(emoji => <span className='task-emotion'>{emoji}&nbsp;</span>) : null}</div>
      </div>
      <div className='lbTodoCardTitleActivity'>{location}</div>
      </div>
      <div className='lbTodoCardIcon'>
      <UIProfileIcon contact={activity}/>
      <div className='lbTodoCategory'>{capitalize(category.split('/').join(" / "))}</div>
      </div>
      </div>
      {(item.status == 'done' || item.status == 'canceled') && <div className='lbTodoCardDoneContainer'>
      <div className='lbTodoCardProgressRecordedNotes'>{item.description}</div>
      {canceled ? <div className='lbTodoCardWhen lbTodoCardCanceled'>CANCELED</div>: <div className='lbTodoCardWhen'>
     {"DONE"}
     </div>}

      <div className='uiAppointmentInfoDate'>
       {formatDate(end)}
    </div>

      {(item.status == 'done' || item.status == 'progress') && <div className='lbTodoCardOutcomeContainer'>
       <div className='lbTodoCardRecordedOutcome lbTodoCardWhen'>{item.outcome < 0 ? '👎' : '👍'} {item.outcome}</div>
       </div>
      }
    </div>}
<div className='lbTodoCardProgressItems'>
      {progresses.map((progress, i) => {
        let prev = i === 0 ? item.start : progresses[i-1].end
        const edit = async () => {
          const clone = JSON.parse(JSON.stringify(progress))
          this.setState({
            renderProgressEditor: i,
            progressForm: clone
          })
        }
        const del = async () => {
          item.progress.splice(i, 1)
          this.forceUpdate()
          await this.props.me.deleteProgress(item.id, progress.end)
          await cancel()
        }
        const save = async () => {
          progress = progresses[i] = this.state.progressForm
          await this.props.me.updateProgress(item.id, progress)
          await cancel()
        }
        const cancel = async () => {
          this.setState({
            renderProgressEditor: -1
          })
        }
        if (this.state.renderProgressEditor == i) {
          const onChange = (field, value) => {
            this.state.progressForm[field] = value
            this.forceUpdate()
          }
          const outcomePrompt = 'Did your progress match your expectations?'
          return <ClickAwayListener onClickAway={cancel}>
            {this.renderProgressEditor(notesPrompt,
                                       outcomePrompt,
                                       this.state.progressForm,
                                       onChange,
                                       "Update",
                                       Update,
                                       save,
                                       cancel)}
          </ClickAwayListener>
        }
        return <div key={'item'+i} className='lbTodoCardProgressItem'>          
          <div className='lbTodoCardProgressRecordedNotes'>{progress.notes}</div>
          <div className='lbTodoCardWhen'>PROGRESS</div>
          <div className='uiAppointmentInfoDate'>{formatDate(progress.end)} {formatStartEndTime(new Date(progress.end))}</div>
          <div className='lbTodoCardOutcomeContainer'>
          <div className='lbTodoCardRecordedOutcome lbTodoCardWhen'>{progress.outcome < 0 ? '👎' : '👍'} {progress.outcome}</div>
          <div className='lbTodoCardProgressControls'>
          <div className='lbTodoCardProgressEdit' onClick={edit}>
          <ReactSVG src={ChatEdit}/>
          </div>
          <div className='lbTodoCardProgressDelete' onClick={del}>
          <ReactSVG src={ChatDelete}/>
          </div>
          </div>
          </div>
          </div>
          })
}
</div>

      <div className='lbTodoCardDescription'>
      {capitalize(todo.task)}&nbsp;<span class='task-emotion'>{taskEmoji}</span>
      </div>
    <div className='lbTodoCardWhen'>
     {"TO-DO"}
     </div>

      <div className='uiAppointmentInfoDate'>
      {formatStartDate()}
    </div>
      {this.state.markingDone ? <ClickAwayListener onClickAway={this.cancelMarkDone}>
       {this.renderProgressEditor(notesPrompt,
                                  outcomePrompt,
                                  form,
                                  onChange,
                                  buttonLabel,
                                  buttonIcon,
                                  markDone,
                                  this.cancelMarkDone)}
       </ClickAwayListener> :
    <div className='lbTodoCardButtonsRow1'>
      {isActive && !this.state.markingDone && todo.term === 'long-term' && <div key='recordProgress' className='lbTodoCardMarkDone'>
       <UIOKCancel okIcon={Update} ok={this.showRecordProgress} cancel={this.state.markingDone && this.cancelMarkDone} cancelIcon={Cross} label={'Record Progress'}/>
       </div>}
      {isActive && !this.state.recordingProgress && <div key='markDone' className='lbTodoCardMarkDone'>
       <UIOKCancel okIcon={Check} ok={markDone} cancel={this.state.markingDone && this.cancelMarkDone} cancelIcon={Cross} label={'Mark Done'}/>
       </div>}
    </div>
      }
  {!this.state.markingDone && isActive && <div className={ 'lbTodoCardAction lbTodoCardAction'+'-'+statusClass }>
      <UIOKCancel background={true} okIcon={editIcon} ok={edit} label={'Edit'} cancel={trash} cancelIcon={Trash}/>
  </div>}
    </div>

    </div>
  }
}
