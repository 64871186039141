import React, { Component } from 'react'
import { UIOKCancel } from '../OKCancel'
import { Dots } from '../Dots'
import { UIProfileIcon } from '../ProfileIcon'
import { formatDate, formatStartEndTime } from "../Appointment";
import ReactSwipeableViews from 'react-swipeable-views'
import Check from "../../assets/icons/CheckMark.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import Forward from "../../assets/icons/Forward.svg";
import ImageIcon from "../../assets/icons/Image.svg";
import Trash from "../../assets/icons/Trash.svg";
import Update from "../../assets/icons/Update.svg";
import Complete from "../../assets/icons/Stop.svg";
import './index.css'
const Box = Check

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

class LBWorkoutCardMedia extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selection: this.props.selection || 0
    }
  }

  onChangeIndex = index => {
    this.setState({
      selection: index
    })
    if (this.props.onChangeIndex) this.props.onChangeIndex(index)
  }

  render () {
    const media = this.props.media.filter(x => {
      if (!x) { // @TODO fix the cause of this
        return false
      }
      return true
    }) 
    if (media.length === 0) {
        return <div className='lbWorkoutCardMedia'/>
    }
    const selection = this.props.onChangeIndex ? this.props.selection : this.state.selection
    debugLog("selection:", selection)
    return <div className='lbWorkoutCardMedia'>
      <ReactSwipeableViews
      onChangeIndex={this.onChangeIndex} 
      index={selection}
      enableMouseEvents={true}
      ignoreNativeScroll={true}>
      {
        media.map(media => {
          let mediaFragment = '#t=0.001'
          let autoplay = false
          if (media.downloadURL.startsWith('blob')) {
            mediaFragment = ''
            autoplay = true
          }
          if (media.contentType.startsWith('video')) {
            return <video src={media.downloadURL + mediaFragment} muted playsInline controls autoPlay={autoplay} />
          } else {
            return <img src={media.downloadURL} />
          }
        })
      }
    </ReactSwipeableViews>
      <div className='lbWorkoutCardMediaDots' style={this.props.media.length > 1 ? null : { display: 'none' }}>
      <Dots position={selection} clickable={false} length={this.props.media.length}/>
      </div>
      </div>
  }
}

export class LBWorkoutCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientMedia: [],
      clientMediaIndex: 0
    }
  }

  componentDidMount() {
    this.setState({
      clientMedia: this.props.workout.media || []
    })
  }

  done = async () => {
    const workout = this.props.workout
    await this.props.me.markWorkoutDone(workout)
    if (this.props.waitForSystemUpdate) {
      await this.props.waitForSystemUpdate()
    }
  }

  startEndWorkout = async () => {
    const workout = this.props.workout
    let result
    if (workout.status == 'started') {
      result = await this.props.me.completeWorkout(workout)
    } else {
      result = await this.props.me.startWorkout(workout)
    }
    debugLog('startWorkout:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    if (this.props.waitForSystemUpdate) {
      await this.props.waitForSystemUpdate()
    }
  }

  onSelectClientMedia = clientMediaIndex => this.setState({ clientMediaIndex })

  deleteSelectedMedia = () => this.deleteClientMedia(this.state.clientMediaIndex)

  deleteClientMedia = async index => {
    const workout = this.props.workout
    const media = this.state.clientMedia[index]
    debugLog('delete media', index, media)
    const result = await this.props.me.deleteMediaFromWorkout(workout.id, media.downloadURL)
    debugLog('delete complete')
    this.state.clientMedia = this.state.clientMedia.filter(x => x != media)
    this.forceUpdate()
  }

  deleteWorkout = async () => {
    const workout = this.props.workout
    const result = await this.props.me.deleteWorkout(workout)
    debugLog('deleteWorkout:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    if (this.props.waitForSystemUpdate) {
      await this.props.waitForSystemUpdate()
    }
  }

  declineWorkout = async () => {
    const workout = this.props.workout
    const result = await this.props.me.declineWorkout(workout)
    debugLog('declineWorkout:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    if (this.props.waitForSystemUpdate) {
      await this.props.waitForSystemUpdate()
    }
  }

  render () {
    const embed = this.props.embed
    const isActive = !this.props.inactive
    const me = this.props.me
    const workout = this.props.workout
    //debugLog("workout:", workout)
    let statusClass = 'lbWorkoutStatusReadyToStart'
    let okIcon
    let ok
    let okLabel
    let cancel
    let cancelIcon
    let canUpload = false
    const isClient = this.props.isClient
    switch (workout.status) {
      default:
        if (isClient) {
          okIcon = Forward
          okLabel = 'Start Activity'
          ok = this.startEndWorkout
          if (workout.trainer == this.props.me.self.uid) {
            cancel = this.deleteWorkout
            cancelIcon = Trash
          }
        } else {
          okLabel = 'Edit'
          ok = this.props.edit
          okIcon = Edit
          /*
          cancel = this.deleteWorkout
          cancelIcon = Trash
          */
        }
        break
      case 'started':
        statusClass = 'lbWorkoutStatusStarted'
        if (isClient) {
          okIcon = Complete
          okLabel = 'Complete Activity'
          ok = this.startEndWorkout
        }
        break
      case 'declined':
      case 'canceled':
        statusClass = 'lbWorkoutStatusDeclined'
        break
      case 'completed':
        statusClass = 'lbWorkoutStatusCompleted'
        if (isClient) {
          okIcon = Check
          okLabel = 'Done'
          ok = this.done
          canUpload = true
        }
        break
      case 'done':
        statusClass = 'lbWorkoutStatusDone'
        if (isClient) {
          canUpload = true
        }
        break
    }
    canUpload = isClient && canUpload
    const demo = workout.demo || []
    const clientMedia = this.state.clientMedia
    const activity = workout.activity;
    const trainer = me.getContact(workout.trainer)

    const start = workout.start ? new Date(workout.start) : undefined
    const end = workout.end ? new Date(workout.end) : undefined

    let deleteSelectedMedia
    const selectedMedia = this.state.clientMedia[this.state.clientMediaIndex]
    if (selectedMedia) {
      deleteSelectedMedia = this.deleteSelectedMedia
    }

    const onFileInput = async e => {
      debugger
      this.setState({
        lookBusy: true,
        uploadError: false
      })
      let err = null
      const files = Array.from(e.target.files)
      if (files.length > 0) {
        const media = await Promise.all(files.map(async file => {
          return {
            contentType: file.type,
            downloadURL: URL.createObjectURL(file),
          }
        }))
        const updates = {
          clientMedia: this.state.clientMedia.concat(media),
          clientMediaIndex: this.state.clientMedia.length
        }
        this.setState(updates)
        if (true) {
          const channel = [workout.trainer, workout.client].sort().join('-')
          let uploadedMedia
          try {
            uploadedMedia = await Promise.all(files.map(async (file, i) => {
              const ref = await this.props.me.uploadFileToChannel(channel, file)
              const downloadURL = await ref.getDownloadURL()
              return {
                contentType: file.type,
                downloadURL: downloadURL
              }
            }))
            await this.props.me.addMediaToWorkout(workout.id, uploadedMedia)
          } catch (uploadError) {
            console.error(uploadError)
            err = true
          }
        }
      }
      this.setState({
        lookBusy: false,
        uploadError: err
      })
    }

    let uploadButton
    if (isActive && canUpload) {
      let lookBusy = this.state.lookBusy
      uploadButton = <div className='uiChatWorkoutMediaUpload'>
        <div className='uiChatWorkoutMediaUploadTrash'><UIOKCancel background={true} cancel={deleteSelectedMedia} cancelIcon={Trash}/></div>
        <input onChange={onFileInput} id={'upload-'+workout.id} type='file' multiple accept='image/*,video/*'/>
        <label htmlFor={'upload-'+workout.id}>
        <UIOKCancel background={true} error={this.state.uploadError} lookBusy={lookBusy} okIcon={ImageIcon} ok={()=>Promise.resolve()} label={"Upload Media"}/>
        </label>
        </div>
    }
    console.log("workout", workout)
    let setReps = ''
    let sep = ''
    if (workout.sets > 0) {
      setReps += sep
      sep = ' of '
      setReps += workout.sets + " sets"
    }
    if (workout.reps > 0) {
      setReps += sep
      setReps += workout.reps + " reps"
    }
    if (sep) {
      sep = ' x '
    }
    if (workout.weight > 0 && (workout.activity.uid === 59 ||  workout.activity.uid == 45)) {
      setReps += sep + workout.weight + ' lbs'
    } else {
      setReps += sep + workout.duration + " mins"
    }
    if (setReps && workout.description && workout.description.trim().endsWith('.')) {
      setReps += '.'
    }

    let canceled = workout.status === 'canceled'
    if (this.props.isEditTime) {
      ok = this.props.edit
      okIcon = Edit
      cancel = this.props.trash
      console.log("cancel", cancel)
      cancelIcon = Trash
      okLabel = 'Edit'
    } else {
    }      
    const who = this.props.me.self.uid === workout.client ? trainer : this.props.me.getContact(workout.client)
    const creds = this.props.me.self.uid === workout.client ? '' : who.creds
    let className = 'lbWorkoutCard' + (this.props.onClick ? ' lbWorkoutCardClickable' : '')
    if (embed) {
      className += ' lbWorkoutCardEmbed'
    }
    return <div className={className} >
      {!embed && <div className={ 'lbWorkoutCardStatus' + ' ' + statusClass } />}
      <div className={'lbWorkoutCardBody'}>
      <div className='lbWorkoutCardHeader' >
      <div className='lbWorkoutCardTitle'>
      {!embed && <div className='uiAppointmentInfoOrganizer'>
      {who.displayName} <span className='uiAppointmentOrganizerCreds'>{creds}</span>
      </div>}
      <div className='lbWorkoutCardTitleActivity'>{activity.displayName}</div>
      </div>
      <div className='lbWorkoutCardIcon'>
      <UIProfileIcon contact={activity}/>
      </div>
      </div>
      {!embed && workout.sessionTitle && <div className='lbWorkoutCardSessionTitle'>
      {workout.sessionTitle}
      </div>}

      <div className='lbWorkoutCardDescription'>
      {workout.index !== undefined ? '('+(workout.index + 1) + ') ' : ''}{workout.description}
      {setReps && <span><p/>{setReps}</span>} 
      </div>
      {!embed && <div className='lbWorkoutCardWhenContainer'>
    {canceled ? <div className='lbWorkoutCardWhen lbWorkoutCardCanceled'>CANCELED</div>: <div className='lbWorkoutCardWhen'>
      {start ? formatStartEndTime(start, end) : "READY TO START"}
     </div>}

      <div className='uiAppointmentInfoDate'>
      {formatDate(start || new Date(workout.scheduled))}
        </div>
     </div>}
      <div key='demo' className='lbWorkoutCardDemo' style={demo.length > 0 ? null: { display: 'none'}}>
      {demo.length > 0 ? <LBWorkoutCardMedia media={demo}/> : null }
      </div>
      { clientMedia.length > 0 && <div key='clientMedia' className='lbWorkoutCardClientMedia'>
      <LBWorkoutCardMedia selection={this.state.clientMediaIndex} onChangeIndex={this.onSelectClientMedia} media={clientMedia}/>
      </div>}
      <div key='upload' className={ 'lbWorkoutCardUpload' }>
      {uploadButton}
      </div>

      <div className={ 'lbWorkoutCardAction lbWorkoutCardAction'+'-'+statusClass }>
      {isActive && <UIOKCancel background={true} okIcon={okIcon} ok={ok} label={okLabel} cancel={cancel} cancelIcon={cancelIcon}/>}
    {this.props.select && <UIOKCancel background={true} okIcon={Forward} ok={this.props.select} label={'Select'} />}
      </div>

    </div>

    </div>
  }
}
