//const EThreeKit = require("@virgilsecurity/e3kit-browser/dist/browser.asmjs.es");
import {find} from 'linkifyjs';
import {docData, doc, collectionChanges} from "rxfire/firestore";
import {merge, of, concat, from, Subject} from "rxjs";
import {filter, map, flatMap, take} from 'rxjs/operators';

const findLinks = text => {
  if (!text) return [];
  return find(text);
}

const convertTs = ts => {
  const result = ts.seconds * 1000 + Math.round(ts.nanoseconds/1000000);
  return result;
}

class Channel {

  constructor(me, channelId, you) {
    this.firebase = me.firebase;
    this.me = me;
    this.you = you;
    this.channelId = channelId;
    this.localContact = me.self;
  }

  encodeCanvasData = (from, data) => {
    const text = JSON.stringify(data);
    return this.you.encrypt(text, this.you.publicKey);
  }
  
  decodeCanvasData = (from, data) => {
    const publicKey = this.you.getPublicKey(from);
    return this.you.decrypt(data, publicKey);
  }

  sendCanvasData = (channel, data) => {
    const collection = this.whiteboardCollection();
    data.from = this.me.self.uid;
    return this.encodeCanvasData(data.from, data.canvasData).then(canvasData => {
      const record = {
        ts: data.ts,
        from: this.me.self.uid,
        canvasData: canvasData,
      }
      return this.firebase.firestore().collection(collection).doc(channel).set(record);
    });
  }

  observeCanvasData = channel => {
    const collection = this.whiteboardCollection();
    return docData(this.firebase.firestore().collection(collection).doc(channel)).pipe(flatMap(data => {
      return from(this.decodeCanvasData(data.from, data.canvasData).then(canvasData => {            
        return {
          fromMe: data.from == this.me.self.uid,
          canvasData: canvasData, 
          ts: data.ts
        };
      }));
    }));
  }

  systemMessagesRef() {
    return this.firebase.firestore().collection("SystemMessages").where("to", "==", this.me.self.uid).where("channel", "==", this.channelId);
  }

  channelRef() {
    return this.firebase.firestore().collection("Channels").doc(this.channelId);
  }
  
  getMessagesRef() {
    return this.messagesRef().where("channel", "==", this.channelId);
  }
  
  messagesRef() {
    return this.channelRef().collection("Messages");
  }

  deleteMessage = (msg) => {
    this.messageHandler('removed', msg)
    return this.messagesRef().doc(String(msg.ts)).delete().then(() => {
      //console.log("deleted message: ", msg);
      return msg;
    }).catch(err => {
      console.error("error deleting message: ", err);
    });
  }

  markRead() {
    return this.me.markRead(this.channelId);
  }

  sendUnencryptedMessage = msg => {
    if (this.remoteContact.isGroup) {
      return this.me.sendGroupMessage(msg);
    }
    return this.me.sendMessage(msg).then(result => {
      //console.log("wrote message to db: ", msg);
      //return addLinkPreviews(encryptedMsg, text);
    });
  }
  
  sendMessage = (msg) => {
    if (this.remoteContact.isGroup) {
      return this.you.encryptMessage(msg).then(encryptedMsg => {
        return this.me.sendGroupMessage(encryptedMsg).then(result => {
          //console.log("wrote message to db: ", msg);
          //return addLinkPreviews(encryptedMsg, text);
        });
      });
    }
    return this.you.encryptMessage(msg).then(encryptedMsg => {
      return this.me.sendMessage(encryptedMsg).then(result => {
        //console.log("wrote message to db: ", msg);
        //return addLinkPreviews(encryptedMsg, text);
      });
    });
  }

  createMessage = (text, files) => {
    const msg = {
      channel: this.channelId,
      from: this.localContact.uid,
      to: this.remoteContact.uid,
      ts: Date.now(),
      text: text
    }
    if (files) {
      msg.files = files.map(storageRef => {
        const bucket = storageRef.getBucket();
        const path = storageRef.getPath();
        return {
          //bucket: bucket,
          path: path,
        }
      });
    }
    return msg;
  }

  sendFile = file => {
    const msg = this.createMessage(this.localContact.displayName + " shared a file");
    msg.files =  [file];
    return this.sendMessage(msg);
  }
  
  send = text => {
    return this.sendMessage(this.createMessage(text));
  }

  disconnect = () => {
    this.unsubscribe();
    if (this.unsubscribe2) this.unsubscribe2();
  }

  getHistory = (ts, limit) => {
    const op1 = this.getMessagesRef()
          .orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2).get();
    const op2 = this.remoteContact.isGroup ? Promise.resolve() : this.systemMessagesRef()
            .orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2).get();
    return Promise.all([op1, op2]).then(results => {
      const [snap1, snap2] = results;
      snap1.docs.map(doc => {
        const msg = doc.data();
        this.processMessageReceived("added", msg, false);
      })
      if (snap2) snap2.docs.map(doc => {
        const msg = doc.data();
        this.processMessageReceived("added", msg, true);
      })
    });
  }

  listenForMessages = () => {
    console.log("listening for messages on channel: ", this.channelId);
    this.unsubscribe = this.getMessagesRef()
      .orderBy("ts", "desc").limit(40)
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          //console.log("message received: ", change);
          this.handleMessageReceived(change);
        });
      }, err => {
        console.error(err)
        debugger;
      });
    if (!this.remoteContact.isGroup) {
      this.unsubscribe2 = this.systemMessagesRef()
        .orderBy("ts", "desc").limit(40)
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const data = change.doc.data();
            console.log("system message received: ", change.doc.data());
            this.handleMessageReceived(change, true);
          });
        },err => {
          console.error(err)
          debugger;
        });
    }
  }
  
  handleMessageReceived = (change, system) => {
    return this.processMessageReceived(change.type, change.doc.data(), system);
  }
  
  processMessageReceived = (type, msg, system) => {
    if (system) {
      msg.ts = convertTs(msg.ts);
    }
    if (type == 'removed') {
      return this.messageHandler("removed", msg);
    }
    if (system) {
      if (msg.data.type != "appointment" &&
          msg.data.type != "subscription" &&
          msg.data.type != 'scheduledWorkout') {
        return;
      }
      msg.system = true;
      return this.messageHandler(type, msg);
    }
    if ((msg.text || msg.data)  && !msg.encryptedText) {

      return this.messageHandler(type, msg);
    }
    if (msg.files) msg.files.map(file => {
      delete file.state;
    });
    return this.you.decryptMessage(msg).then(decryptedMsg => {
      const addLinkPreviews = (msg, text) => {
        const links = findLinks(text);
        return Promise.all(links.map(link => {
          const url = link.href;
          return this.me.linkPreviewer.getLinkPreview(url);
        })).then(results => {
          const previews = results.filter(result => !!result);
          if(msg.linkPreviews) {
            if (JSON.stringify(msg.linkPreviews) == JSON.stringify(previews)) {
              return Promise.resolve();
            }
          }
          if (previews.length > 0) {
            msg.linkPreviews = previews;
          } else {
            delete msg.linkPreviews;
          }
          this.messageHandler("added", msg);
        });
      };
      if (type != "removed") {
        return addLinkPreviews(decryptedMsg, decryptedMsg.text);
      }
      this.messageHandler(type, decryptedMsg);
    });
  }

  onMessage(k) {
    this.messageHandler = k;
  }

  typing = () => {
    const now = Date.now();
    const last = this.myLastTyping || 0;
    if (now - last > 5000) {
      this.myLastTyping = now;
    } else {
      return;
    }
    const update = {
      typing: {}
    }
    update.typing[this.me.self.uid] = now;
    return this.channelRef().set(update, {merge: true});
  }

  observeTyping = () => {
    const filt = data => {
      //console.log("channel data: ", data);
      if (data.typing) {
        const you = data.typing[this.you.uid];
        if (you) {
          if (!this.lastTyping) {
            this.lastTyping = you;
            return false;
          }
        }
        const result = you > this.lastTyping;
        if (result) {
          this.lastTyping = you;
        }
        return result;
      }
      return false;
    }
    return docData(this.channelRef()).pipe(filter(filt), map(data => data.typing[this.you.uid]));
  }

  downloadFile = msg => {
    return this.you.downloadFile(msg);
  }

  uploadFileToChannel = async (file, progress, videoDimensions) => {
    return await this.me.uploadFileToChannel(this.channelId, file, progress, videoDimensions)
  }

  uploadFile = (origFile, progress, dontSendMessage, videoDimensions) => {
    if (dontSendMessage) return this.uploadFileToChannel(this.channelId, origFile, progress, videoDimensions).then(ref => ref.getDownloadURL())
    const timestamp = Date.now();
    const uid = this.me.self.uid;
    const type = origFile.type.startsWith("video/") ? "movie" : origFile.type.startsWith("image/") ? "picture" : "file";
    const msg = this.createMessage("Uploading a "+type);
    const text = msg.text;
    msg.files = [{
      name: origFile.name || "",
      contentType: origFile.type,
      state: "uploading",
      progress: 0,
    }]
    const op1 = Promise.resolve() // this.sendUnencryptedMessage(msg);
    this.messageHandler("added", msg);
    const op2 = this.you.encryptFile(origFile);
    return Promise.all([op1, op2]).then(async results => {
      const [ignored, file] = results;
      let ref
      try {
        ref = await this.me.uploadFileToChannel(this.channelId, file, progress, videoDimensions)
      } catch (err) {
        console.error(err)
        msg.files[0].state = 'upload-failed'
        this.messageHandler('added', msg)
        return
      }
      const downloadURL = await ref.getDownloadURL()
      if (dontSendMessage) return downloadURL
      const fileAttachment = {
        name: origFile.name || "",
        contentType: origFile.type == "video/quicktime" ? "video/mp4": origFile.type,
        bucket: ref.bucket,
        path: ref.fullPath,
        downloadURL: downloadURL,
      }
      msg.text = "Shared a "+type;
      msg.files[0] = fileAttachment;
      await this.sendMessage(msg)
      return downloadURL
    });
  }
}


export class DM extends Channel {
  constructor(me, channelId, remoteContact, you) {
    super(me, channelId, you);
    this.remoteContact = remoteContact;
    this.listenForMessages();
  }

  systemMessagesRef() {
    return this.firebase.firestore().collection("SystemMessages").where("to", "==", this.me.self.uid).where("channel", "==", this.channelId);
  }

  channelRef() {
    const collectionName = this.remoteContact.isGroup ? "GroupChannels" : "Channels";
    return this.firebase.firestore().collection(collectionName).doc(this.channelId);
  }
  
  getMessagesRef() {
    return this.messagesRef().where("channel", "==", this.channelId);
  }
  
  messagesRef() {
    return this.channelRef().collection("Messages");
  }

  whiteboardCollection() {
    return this.remoteContact.isGroup ? "GroupWhiteboards" : "Whiteboards";
  }

  getHistory = (ts, limit) => {
    console.log("getHistory", ts, limit)
    const ops = [];
    const op1 = this.getMessagesRef()
          .orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2).get();
    if (!this.remoteContact.isGroup) {
      const op2 = this.systemMessagesRef()
            .orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2).get();
      ops.push(op2);
    }
    return Promise.all(ops).then(results => {
      results.map(snap => {
        console.log("getHistory=>", snap.docs.length)
        snap.docs.map(doc => {
          const msg = doc.data();
          this.processMessageReceived("added", msg, false);
        })
      })
    });
  }
  
  createMessage = (text, files) => {
    const msg = {
      channel: this.channelId,
      from: this.localContact.uid,
      to: this.remoteContact.uid,
      ts: Date.now(),
      text: text
    }
    if (files) {
      msg.files = files.map(storageRef => {
        const bucket = storageRef.getBucket();
        const path = storageRef.getPath();
        return {
          //bucket: bucket,
          path: path,
        }
      });
    }
    return msg;
  }
}

export class Group extends Channel {

  constructor(me, channelId, group, remoteContacts) {
    super(me, channelId, group);
    this.group = group;
    this.remoteContacts = remoteContacts;
    this.listenForMessages();
  }

  channelRef() {
    return this.firebase.firestore().collection("Channels").doc(this.channelId);
  }
  
  getMessagesRef() {
    return this.messagesRef().where("channel", "==", this.channelId);
  }
  
  messagesRef() {
    return this.channelRef().collection("Messages");
  }

  whiteboardCollection() {
    return "Whiteboards";
  }

  getHistory = (ts, limit) => {
    const op1 = this.getMessagesRef()
          .orderBy("ts", "desc").where("ts", "<", ts).limit(limit*2).get();
    return Promise.all([op1]).then(results => {
      const [snap1] = results;
      snap1.docs.map(doc => {
        const msg = doc.data();
        this.processMessageReceived("added", msg, false);
      })
    });
  }
  
  createMessage = (text, files) => {
    const msg = {
      channel: this.channelId,
      from: this.localContact.uid,
      to: this.group.id,
      ts: Date.now(),
      text: text
    }
    if (files) {
      msg.files = files.map(storageRef => {
        const bucket = storageRef.getBucket();
        const path = storageRef.getPath();
        return {
          //bucket: bucket,
          path: path,
        }
      });
    }
    return msg;
  }
}

