import React, { Component } from 'react';
import "./ProductView.css"
import {InputField, InputFieldSelect} from "./InputField";
import {Button} from "./Button";
import {SelectionList} from "./SelectionList";

export class ProductView extends Component {
    constructor(props) {
        super(props);
    }

    renderDetails() {
        const det = this.props.product.details;
        switch(this.props.product.productType) {
        case "teletherapy":
            return [<InputField readOnly={true} value={det.service.name} name='service' onChange={this.onChange}/>,
                    <InputField readOnly={true} value={"Treating "+det.treatment.name} name='treatment' onChange={this.onChange}/>]
        case "videoConference":
        case "professionalChat":
        }
    }

    onChange = () => {}

    renderPrice() {
        const det = this.props.product.details;
        const label = "$"+det.unitPrice + " " +det.priceUnit;
        return <InputField readOnly={true} value={label} name='price' onChange={this.onChange}/>
    }
    
    render() {
        return <div className='product'>
            <div className='productFields'>
            <InputField readOnly={true} value={this.props.product.name} label={"Name"} name='name' onChange={this.onChange}/>
            {this.renderDetails()}
            {this.renderPrice()}
            </div>
            </div>
    }
}

class DefaultProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    getPriceUnit() {
        return "";
    }

    componentDidMount() {
        this.updateProductLater();
    }
    
    updateProductLater = () => {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            this.props.setProduct(this.getProductDetails());
        }, 200);
    }

    getProductDetails() {
        return {
            type: this.props.productType,
        }
    }

    render() {
        return null;
    }
}


class VideoConferenceProduct extends DefaultProduct {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.serviceOptions = [
            {
                value: "15",
                name: "15 Minutes",
            },
            {
                name: "30 Minutes",
                value: "30",
            },
            {
                name: "45 Minutes",
                value: "45",
            },
            {
                name: "60 Minutes",
                value: "60",
            },
            {
                name: "90 Minutes",
                value: "90",
            }];
        this.state.service = this.state.serviceOptions[0];
        this.state.billingOptions = [
            {
                name: "Monthly",
                value: "subscription",
            },
            {
                name: "Pay as you go",
                value: "product",
            }];
        this.state.billing = this.state.billingOptions[0];
    }

    getPriceUnit() {
        return " per Session";
    }

    getProductDetails() {
        return {
            type: "videoConference",
            service: this.state.service,
            billing: this.state.billing,
            unitPrice: this.state.planPrice,
            priceUnit: "per Session"
        }
    }

    getTooltip = (name) => {
        const e = this.state[name];
        return e.value + " "+e.name;
    }

    onChange=(field, value) => {
        if (field == "service") {
            this.state.service = this.state.serviceOptions.find(x => x.value == value);
        } else if (field == "billing") {
            this.state.billing = this.state.billingOptions.find(x => x.value == value);
        } else {
            this.state[field] = value;
        }
        this.updateProductLater();
        this.forceUpdate();
    }

    render() {
        return <div className='product videoConferenceProduct'>
            <div className='productFields videoConferenceProductFields'>
            <div className='modifySelectionList'><InputFieldSelect label={"Session"} readOnly={this.props.readOnly} value={this.state.service.value} options={this.state.serviceOptions} name='service' onChange={this.onChange}/></div>
            <InputField label={"Price"+this.getPriceUnit()} type='currency' value={this.state.planPrice} name='planPrice'  onChange={this.onChange}/>
            <InputFieldSelect readonly={this.props.readOnly} value={this.state.billing}
               options={this.state.billingOptions} label={"Billing Method"} name='billing'
                onChange={this.onChange}/>
            </div>
            </div>
    }
    
}

class ProfessionalChatProduct extends DefaultProduct {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.serviceOptions = [
            {
                value: "24",
                name: "Within 24 Hours",
            },
            {
                name: "Within 48 Hours",
                value: "48",
            },
            {
                name: "Within 72 Hours",
                value: "72",
            },
            {
                name: "Weekly",
                value: "week",
            }];
        this.state.service = this.state.serviceOptions[0];
        this.state.subscriptionOptions = [
            {
                name: "1 Month",
                value: "1",
            },
            {
                name: "3 Months",
                value: "3",
            },
            {
                name: "6 Months",
                value: "6",
            },
            {
                name: "1 Year",
                value: "12",
            }];
        this.state.subscription = this.state.subscriptionOptions[0];
    }

    getPriceUnit() {
        return " per month";
    }

    getProductDetails() {
        return {
            type: "professionalChat",
            service: this.state.service,
            subscription: this.state.subscription,
            unitPrice: this.state.planPrice,
            priceUnit: "per Month",
        }
    }

    getTooltip = (name) => {
        const e = this.state[name];
        return e.value + " "+e.name;
    }

    onChange=(field, value) => {
        if (field == "service") {
            this.state.service = this.serviceOptions.find(x => x.value == value);
        } else {
            this.state[field] = value;
        }
        this.updateProductLater();
        this.forceUpdate();
    }

    render() {
        return <div className='product professionalChatProduct'>
            <div className='productFields professionalChatProductFields'>
            <div className='modifySelectionList'><InputFieldSelect label={"Response"} readOnly={this.props.readOnly} value={this.state.service.value} options={this.state.serviceOptions} name='service' onChange={this.onChange}/></div>
            <div className='modifySelectionList'><InputFieldSelect label={"Subscription"} readOnly={this.props.readOnly} value={this.state.subscription.value} options={this.state.subscriptionOptions} name='subscription' onChange={this.onChange}/></div>
            <InputField label={"Price"+this.getPriceUnit()} type='currency' value={this.state.planPrice} name='planPrice'  onChange={this.onChange}/>
            </div>
            </div>
    }
    
}



export class AddProductView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: "month",
            interval_count: 3,
            planPrice: 0,
            productType: "teletherapy",
            productOptions: [
                {
                    name: "Individual Teletherapy",
                    value: "teletherapy"
                },
                {
                    name: "1 - 1 Video Conference",
                    value: 'videoConference'
                },
                {
                    name: "1 - 1 Professional Chat",
                    value: 'professionalChat'
                }
            ],
        }
        this.state.billingOptions = [
            {
                name: "Pay as you go",
                value: "product",
            },
            {
                name: "Monthly",
                value: "subscription",
            }]
    }

    onChange = (field, value) => {
        this.state[field] = value;
        if (field == 'nickname') {
            this.product.name = value;
        }
        this.forceUpdate();
    }

    onChangeProduct = (field, value) => {
        const product = this.state[this.state.productType];
    }

    setProduct = (details) => {
        this.product = {
            productType: this.state.productType,
            name: this.state.nickname,
            details: details,
        };
        console.log("Product: ", this.product);
    }

    render() {
        return <div className='addProduct'>
            <div className='productFields'>
            <div className='paymentMethodTitle'>Create TeTe Product</div>
            <InputFieldSelect value={this.state.productType}
               options={this.state.productOptions} label={"Product"} name='productType'
                onChange={this.onChange}/>
            <InputField readOnly={this.props.readOnly} value={this.state.nickname} label={"Description"} name='nickname' onChange={this.onChange}/>
            {this.renderProduct()}
            </div>
            <div className='buttonContainer'>
            <Button label={"Back"} action={this.props.back}/>
            <Button label={"Add"} action={()=>this.props.add(this.product)}/>
            </div>
            <div className='signUpError'>{this.props.error}</div>            
            </div>
    }

    renderProduct() {
        if (this.state.productType == "teletherapy") {
            return <TeletherapyProduct setProduct={this.setProduct}/>
        }
        if (this.state.productType == "videoConference") {
            return <VideoConferenceProduct setProduct={this.setProduct}/> 
        }
        if (this.state.productType == "professionalChat") {
            return <ProfessionalChatProduct setProduct={this.setProduct}/> 
        }
        return null;
    }
}




const clone = obj => obj ? JSON.parse(JSON.stringify(obj)) : obj;


export class TeletherapyProduct extends DefaultProduct {
    constructor(props) {
        super(props);
        this.treatmentOptions = [
                                 {value: "F41.1  ", name: "Generalized anxiety disorder"},
                                 {value: "F43.23 ", name: "Adjustment disorder with mixed anxiety and depressed mood"},
                                 {value: "F43.22 ", name: "Adjustment disorder with anxiety"},
                                 {value: "F33.1  ", name: "Major depressive disorder, recurrent, moderate"},
                                 {value: "F43.20 ", name: "Adjustment disorder, unspecified"},
                                 {value: "F41.9  ", name: "Anxiety disorder, unspecified"},
                                 {value: "F43.21 ", name: "Adjustment disorder with depressed mood"},
                                 {value: "Z63.0  ", name: "Relationship distress with spouse or partner"},
                                 {value: "F43.12 ", name: "Post-traumatic stress disorder, chronic"},
                                 {value: "F34.1  ", name: "Dysthymic disorder"},
                                 {value: "F43.10 ", name: "Post-traumatic stress disorder, unspecified"},
                                 {value: "F32.1  ", name: "Major depressive disorder, single episode, moderate"},
                                 {value: "F33.0  ", name: "Major depressive disorder, recurrent, mild"},
                                 {value: "F32.9  ", name: "Major depressive disorder, single episode, unspecified"},
                                 {value: "F90.2  ", name: "Attention-deficit hyperactivity disorder, combined type"},
                                 {value: "F60.9  ", name: "Personality disorder, unspecified"},
                                 {value: "F40.228", name: "Other natural environment type phobia"},
                                 {value: "F43.25 ", name: "Adjustment disorder w mixed disturb of emotions and conduct"},
                                 {value: "F06.1  ", name: "Catatonic disorder due to known physiological condition"},
                                 {value: "F33.2  ", name: "Major depressive disorder, recurrent severe without psychotic features"},
                                 {value: "F41.0  ", name: "Panic disorder without agoraphobia"},
                                 {value: "F90.0  ", name: "Attention-deficit hyperactivity disorder, predominantly inattentive type"},
                                 {value: "F32.0  ", name: "Major depressive disorder, single episode, mild"},
                                 {value: "F42.2  ", name: "Mixed obsessional thoughts and acts"},
                                 {value: "F84.0  ", name: "Childhood autism"}                    
                                ];
        this.treatmentOptions.sort((a, b) => {
           return a.name.localeCompare(b.name);
        });
        this.serviceOptions = [
            {
                name: "Therapy 30 min",
                value: "90832-95"
            },
            {
                name: "Therapy 45 min",
                value: "90834-95"
            },
            {
                name: "Therapy 60 min",
                value: "90837-95"
            }];
        const state = {
            serviceOptions: clone(this.serviceOptions),
            treatmentOptions: clone(this.treatmentOptions),
            treatment: this.treatmentOptions[0],
            service: this.serviceOptions[0],
            pricePerSession: 0,
        }
        for (var i in state) {
            this.state[i] = state[i];
        }
        this.state.billingOptions = [
            {
                name: "Monthly",
                value: "subscription",
            },
            {
                name: "Pay as you go",
                value: "product",
            }];
        this.state.billing = this.state.billingOptions[0];
    }

    getPriceUnit() {
        return " per Session";
    }

    getProductDetails() {
        return {
            type: "teletherapy",
            service: this.state.service,
            treatment: this.state.treatment,
            billing: this.state.billing,
            unitPrice: this.state.pricePerSession,
            priceUnit: "per Session"
        }
    }

    getTooltip = (name) => {
        const e = this.state[name];
        return e.value + " "+e.name;
    }

    onChange=(field, value) => {
        console.log("onChange ", field, ": ", this.state[field], " => ", value);
        if (field == "service") {
            this.state.service = this.serviceOptions.find(x => x.value == value);
        } else if (field == "treatment") {
            this.state.treatment = this.treatmentOptions.find(x => x.value == value);
        } else {
            this.state[field] = value;
        }
        this.updateProductLater();
        this.forceUpdate();
    }

    render() {
        return <div className='product teletherapyProduct'>
            <div className='productFields teletherapyProductFields'>
            <div className='modifySelectionList'><InputFieldSelect tooltip={this.getTooltip("service")} label={"Service"} readOnly={this.props.readOnly} value={this.state.service.value} options={this.state.serviceOptions} name='service' onChange={this.onChange}/></div>
            <div className='modifySelectionList'><InputFieldSelect label={"Treatment"} readOnly={this.props.readOnly} value={this.state.treatment.value} options={this.state.treatmentOptions} name='treatment' tooltip={this.getTooltip("treatment")} onChange={this.onChange}/></div>
            <InputField label={"Price"+this.getPriceUnit()} type='currency' value={this.state.pricePerSession} name='pricePerSession'  onChange={this.onChange}/>
            <InputFieldSelect readonly={this.props.readOnly} value={this.state.interval}
               options={this.state.billingOptions} label={"Billing Method"} name='billing'
                onChange={this.onChange}/>

            </div>
            </div>
            
    }
}


