import React, { Component } from 'react';
import {ReactSVG} from 'react-svg';
import "./SignUp.css"
import {Button} from './Button'
import {isDesktop} from "./Platform"
import {InputField} from './InputField'
import Logo01 from "./assets/Assets/TeteLogo00.svg";

export class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        if (this.props.contact) {
            this.state.email = this.props.contact.email;
            this.state.name = this.props.contact.displayName;
            if (this.state.name == this.state.email) {
                this.state.name = "";
            }
            
        }
    }
    onReturn = e => {
        if (e.keyCode === 13) {
            this.props.signUp(this.state);
        }
    }

    componentDidMount() {
        if (isDesktop()) {
            window.addEventListener("keydown", this.onReturn);
        }
    }
    componentWillUnmount() {
        if (isDesktop()) {
            window.removeEventListener("keydown", this.onReturn);
        }
    }
    onChange=(field, value) => {
        const update = {};
        update[field] = value
        this.setState(update);
    }
    render() {
        return <div className='signUp'>
            <div className='signUpFields'>
            <div className="appTitle"><ReactSVG src={Logo01}/></div>
            <div className='signUpTitle'>{this.props.title}</div>
            <InputField autoFocus={true} label={'Your Name'} value={this.state.name} name='name' type={'name'} onChange={this.onChange}/>
            <InputField label={'Title'} value={this.state.title} name='title' type={'name'} onChange={this.onChange}/>
            <InputField label={'Organization'} value={this.state.organization} name='organization' type={'text'} autoComplete={"organization"} onChange={this.onChange}/>
            <InputField label={'Email Address'} value={this.state.email} name='email' type={'email'} autoComplete={'email'} onChange={this.onChange}/>
            <InputField label={'Password'} name='password' value={this.state.password} type={'password'} autoComplete={'new-password'} onChange={this.onChange}/>
            <div className='buttonContainer signUpButtonContainer'>
            <Button label={this.props.backLabel} action={this.props.backAction}/>
            <Button label={this.props.label} action={()=>this.props.signUp(this.state)}/>
            </div>
            <div className='signUpError'>{this.props.error}</div>            
            </div>
            </div>
    }
}
