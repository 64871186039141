import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UIOKCancel} from "../OKCancel";
import {cycleToAppointment, UICalendar} from "../Calendar";
import {UISubscription} from "../Subscription";
import { formatDate, formatStartEndTime } from "../Appointment";
import {FoodLogo, logoImage, getFoodName, UICreditCardInput, UIScheduleAppointment} from "../ScheduleAppointment";
import OuraIcon from '../../assets/icons/Oura.svg'
import Info from '../../assets/icons/Info.svg'
import Activity from '../../assets/icons/Active.svg'
import {UISubscribeToChat} from "../SubscribeToChat";
import ScaleIcon from "../../assets/icons/Scales.svg";
import Trash from "../../assets/icons/Trash.svg";
import Update from "../../assets/icons/Update.svg";
import Check from "../../assets/icons/CheckMark.svg";
import Cross from "../../assets/icons/Cross.svg";
import Menu from "../../assets/icons/Menu.svg";
import Cal from "../../assets/icons/CalendarSml.svg";
import Plus from "../../assets/icons/Plus.svg";
import CreditCard from "../../assets/icons/CreditCard.svg";
import Forward from "../../assets/icons/Forward.svg";
import Pause from "../../assets/icons/Hold.svg";
import Refund from "../../assets/icons/Refund.svg";
import {UIButton} from "../Button";
import {UIIcon} from "../Icon";
import {UIProfileIcon} from "../ProfileIcon";
import {UIAppointment} from "../Appointment";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {convertUnicodeEmojisToMarkdown, emojiIsSupported, unicodeToEmoji, emojiToUnicode} from "../../Emojis";
import {hasSoftKeyboard, isSafari, isApple, isWindows, isDesktop} from "../../Platform";
import {parse, NodeType } from 'slack-message-parser-imajion';
import mime from 'mime-types';
import moment from 'moment'
import {UploadProgress} from "../..//UploadProgress";
import ResizeObserver from 'resize-observer-polyfill';
import Send from "../../assets/icons/Send.svg";
import Emoji from "../../assets/icons/Emoji.svg";
import TeTeLogo from "../../assets/icons/TeTeLogoSquare.png";
import TodoListProfileImage from "../../assets/icons/ToDoList.svg"
import GiphyAttribution from "../../assets/Assets/GiphyAttribution.png";
import SpinnerShape from "../../assets/icons/SpinnerShape.svg";
import Err from "../../assets/icons/Error.svg";
import Save from "../../assets/icons/Save.svg";
import FileIcon from "../../assets/icons/File.svg";
import ImageIcon from "../../assets/icons/Image.svg";
import ChatReact from "../../assets/icons/ChatReact.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import ChatEdit from "../../assets/icons/ChatEdit.svg";
import ChatDelete from "../../assets/icons/Delete.svg";
import ChatSpace from "../../assets/icons/ChatSpace.svg";
import Undo from "../../assets/icons/Undo.svg";
import Giphy from "../../assets/icons/Gif.svg";
import {GiphyFetch} from '@giphy/js-fetch-api';
import Video from "../../assets/icons/VideoOn.svg";
import PointerDown from "../../assets/icons/PointerDown.svg";
import GiphySelect from '../GiphySelect'
//import ChatDelete from "../../assets/icons/ChatDelete.svg";
import Home from "../../assets/icons/Home.svg";
import Arrow from "../../assets/icons/PointerRight.svg";
import {CallButton} from "../RemoteVideo";
import {SideListProductChannel} from "../Sidebar";
import {Button} from "../../Button";
import '../../emoji-mart/css/emoji-mart.css'
import { Picker } from '../../emoji-mart/src'
import {isMobile} from "../../Platform";
import {find} from 'linkifyjs';
import {getTime} from "../Calendar";
import {Elements} from '@stripe/react-stripe-js';
import {ElementsConsumer, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import emojiData from '../../emoji-mart/data/apple.json';
import {SliderPicker, CompactPicker} from 'react-color';
import WhoopLogo from '../../assets/icons/Whoop.svg'
import LetsBuildLogo from '../../assets/icons/LB_Logo512.svg'
import OuraLogo from '../../assets/icons/Oura.svg'
import GarminLogo from '../../assets/icons/Garmin.svg'
import FileSaver from 'file-saver';
import { Dots } from '../Dots'
import ReactSwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'
import TurndownService from 'turndown'
import './index.css';

TurndownService.prototype.escape = x => x
const turndownService = new TurndownService()
turndownService.addRule('img', {
  filter: ['img'],
  replacement: (content, n, options) => {
    const name = n.getAttribute("emoji-name");
    let result = ''
    if (name) {
      result += name;
    } else {
      const gif = n.getAttribute("gif-link");
      if (gif) {
        result += ' ' + gif + ' ';
      } else {
        result += ' ' + n.src + ' ';
      }
    }
    return result
  }
})

const debugLog = (...args) => {
  console.log.apply(null, args)
}

const addScheduledWorkoutAppointment = (isMe, me, msg) => {
  const rec = msg.data.scheduledWorkout
  const workout = rec.workout
  let fakeAppt = msg.data.appointment;
  if (!fakeAppt && workout) {
    workout.id = rec.id
    workout.start = rec.start
    workout.end = rec.end
    workout.trainer = rec.trainer
    workout.client = rec.client
    workout.lastModified = rec.lastModified
    workout.scheduled = rec.scheduled
    workout.status = rec.status
    workout.before = rec.before
    if (!workout.activity.uid) {
      const sport = me.getWhoop().getSport(workout.activity)
      workout.activity = {
        uid: sport.id,
            displayName: sport.name,
        profileImage: sport.iconUrl,
      }
    }
    const description = workout.description + (
      (workout.activity.id == 35 || workout.activity.uid == 59) && workout.weight ? ' ' + workout.weight + ' lbs' : '')
    const trainer = me.getContact(workout.trainer)
    const client = me.getContact(workout.client)
    fakeAppt = {
      organizer: trainer,
      contact: workout.activity,
      title: description,
      client: client.uid,
      start: workout.start,
      scheduled: workout.scheduled,
      end: workout.end,
      id: workout.id,
      workout: workout,
      uid: workout.trainer,
      isClient: isMe || me.self.uid !== trainer.uid
    }
    switch (workout.status) {
      case 'done':
        msg.from = workout.client
        if (workout.before.status !== 'done') {
          msg.text = client.displayName + " marked a workout done"
        } else {
          msg.text = client.displayName + " updated a workout"
        }
        break
      default:
        msg.from = workout.trainer
        msg.text = trainer.displayName + ' has scheduled a workout'
        msg.data = {
          appointment: fakeAppt,
          type: 'scheduledWorkout',
          scheduledWorkout: workout,
          isActive: msg.data.isActive
        }
        break
      case 'started':
        msg.from = workout.client
        msg.text = client.displayName + ' has started a workout'
        msg.data = {
          appointment: fakeAppt,
          type: 'scheduledWorkout',
          scheduledworkout: workout,
          isActive: msg.data.isActive
        }
        break
      case 'completed':
        msg.from = workout.client
        if (workout.before.status !== 'completed') {
          msg.text = client.displayName + ' updated a workout'
        } else {
          msg.text = client.displayName + ' has completed a workout'
        }
        msg.data = {
          appointment: fakeAppt,
          type: 'scheduledWorkout',
          scheduledWorkout: workout,
          isActive: msg.data.isActive
        }
        break
      case 'declined':
        msg.from = workout.client
        msg.text = client.displayName + ' has canceled a workout'
        msg.data = {
          appointment: fakeAppt,
          type: 'scheduledWorkout',
          scheduledWorkout: workout,
          isActive: msg.data.isActive
        }
        break
      case 'canceled':
        msg.from = workout.trainer
        msg.text = trainer.displayName + ' has canceled a workout'
        msg.data = {
          appointment: fakeAppt,
          type: 'scheduledWorkout',
          scheduledWorkout: workout,
          isActive: msg.data.isActive
        }
        break
    }
    msg.data.appointment = fakeAppt
  }
}

let textArea;

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)

const unescape = (text) => {
  if (!text) return text;
  if (!textArea) textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  const value = textArea.value;
  textArea.innerHTML = '';
  return value.replace(/  +/g, match => " " + Array(match.length).join("\u00A0"));
}

export const formatNewContactText = (me, newContact) => {
  const contact = newContact.contact;
  let text;
  switch (newContact.role) {
    default:
      const by = me.getContact(newContact.role);
      if (by) {
        return by.displayName + " has connected you with " + contact.displayName;
      }
    case 'contacted':
      text = "You are now connected with "+contact.displayName;
      break;
    case 'referred':
      text = "You are now connected with "+contact.displayName;
      break;
    case 'client':
      text = "You are now connected with your client "+contact.displayName;
      break;
    case 'contactee':
    case 'referer':
      text = contact.displayName + " accepted your invite"
      break;
    case 'provider':
      text = "You are now connected with "+contact.displayName;
      break;
  }
  return text;
}

const chatControls = {};
// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
//const gf = new GiphyFetch('eSeANz1CYF7bWLNcXsRYzI7MH5QSkKmx');
// configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
//const fetchGifs = (offset: number) => gf.trending({ offset, limit: 10 })

const nameToEmoji = name => {
  const data = emojiData.emojis[name];
  if (!data) {
    debugger
  }
  //////debugLog(name, " => ", data);
  const result = {
    short_name: name,
    unified: data.b,
    name: data.a,
    sheet_x: data.k[0],
    sheet_y: data.k[1],
  }
  return result;
}

const canvas = document.createElement("canvas");
canvas.width = 64;
canvas.height = 64;

const rendered = {};
const sprites = new Image();
sprites.crossOrigin = "Anonymous";
const emojiSet = isApple() ? "apple" : false && isWindows() ? 'windows' : 'google';
sprites.src = "https://unpkg.com/emoji-datasource-"+emojiSet+"@5.0.1/img/"+emojiSet+"/sheets-256/64.png";

export const unicodeEmojiToSprite = c => {
  const name = unicodeToEmoji(c)
  const emoji = nameToEmoji(name)
  return emojiToSprite(emoji)
}

const emojiToSprite = emoji0 => {
  const emoji = nameToEmoji(emoji0.short_name);
  if (isApple() || isWindows()) {
    const img = <span className='uiChatInlineEmoji'>{emojiToUnicode(emoji)}</span>;
    return img;
  }
  const x = (emoji.sheet_x / 56)
  const y = (emoji.sheet_y / 56);
  const style = {
    display: 'inline-block',
    width: 25,
    height: 25,
    backgroundImage: "url("+sprites.src+")",
    backgroundSize: "5700% 5700%",
    backgroundPosition: x*100 + "% "+y*100+"%"
  };
  return <div className='uiChatEmojiSprite'><span style={style}></span></div>;
}

const emojiToHtmlImg = emoji0 => {
  //    ////debugLog("emoji0: ", emoji0);
  const emoji = nameToEmoji(emoji0.short_name);
  //    ////debugLog("emoji: ", emoji);
  let blob = rendered[emoji.short_name];
  let src;
  if (!blob) {
    const s = 3762/57;
    const u = (emoji.sheet_x * s);
    const v = (emoji.sheet_y * s);
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(sprites, u, v, 64, 64, 0, 0, 64, 64);
    src = canvas.toDataURL();
    canvas.toBlob(blob => {
      rendered[emoji.short_name] = blob;
    });
  } else {
    src = URL.createObjectURL(blob);
  }
  return "<img class='emoji' src='"+src+"' emoji-name=':"+emoji.short_name+":'/>";
}

const normalizeEmoji = emoji => {
  const result = {
    short_name: emoji.id,
    name: emoji.name,
    unified: emoji.unified,
  }
  return result;
}

const clone = x => JSON.parse(JSON.stringify(x));

const nbsp = new RegExp(String.fromCharCode(160), "gi");

function walkDOM(node, func) {
  func(node);
  node = node.firstChild;
  while(node) {
    walkDOM(node, func);
    node = node.nextSibling;
  }
}

export class UIRemoveContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needsConfirm: false
    }
  }

  onClick = () => {
    this.setState({
      needsConfirm: true,
    });
  }

  onStopHover = () => {
    this.setState({
      needsConfirm: false,
    });
  }

  removeContact = () => {
    return this.props.removeContact();
  }
  
  render() {
    const contact = this.props.contact;
    return <div className='uiRemoveContact'>
      {<div style={this.state.needsConfirm ? {visibility: 'hidden'} : null} key='remove' className='uiRemoveContactRemove'><Tooltip title={"Remove "+contact.displayName}><div className='uiRemoveContactRemove' onClick={()=>this.onClick()}>Remove Contact</div></Tooltip></div>}
    {this.state.needsConfirm && <div key='confirm' className='uiRemoveContactConfirm' onMouseLeave={this.onStopHover}>
     <UIOKCancel ok={this.removeContact} okIcon={Trash} label={"Confirm removing this contact"}/>
     </div>}
    </div>
  }
}


export class ChatAppointment extends Component {

  constructor (props) {
    super(props)
    this.state = {
      clientMedia:
      (this.props.appt &&
       this.props.appt.workout &&
       this.props.appt.workout.media &&
       this.props.appt.workout.media.filter(x => x)) || []
    }
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    if (this.workoutSub) this.workoutSub.unsubscribe()
  }

  mediaIndex = 0

  onChangeMediaIndex = index => {
    this.mediaIndex = index
    this.forceUpdate()
  }

  clientMediaIndex = 0

  onChangeClientMediaIndex = index => {
    this.clientMediaIndex = index
    this.forceUpdate()
  }


  skipWorkoutMedia = async () => {
  }

  startEndWorkout = async () => {
    const msg = this.props.msg
    const appt = msg.data.appointment
    const workout = appt.workout
    let result
    if (workout.status == 'started') {
      result = await this.props.me.completeWorkout(workout)
    } else {
      result = await this.props.me.startWorkout(workout)
    }
    debugLog('startWorkout:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    await this.props.waitForSystemUpdate(appt)
  }

  declineWorkout = async () => {
    const msg = this.props.msg
    const appt = msg.data.appointment
    const workout = appt.workout
    const result = await this.props.me.declineWorkout(workout)
    debugLog('completeWorkout:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    await this.props.waitForSystemUpdate(appt)
  }

  deleteClientMedia = async index => {
    const msg = this.props.msg
    const appt = msg.data.appointment
    const workout = appt.workout
    const media = this.state.clientMedia[index]    
    const result = await this.props.me.deleteMediaFromWorkout(workout.id, media.downloadURL)
    await this.props.waitForSystemUpdate(appt)
    this.state.clientMedia = this.state.clientMedia.filter(x => x != media)
    this.forceUpdate()
  }

  deleteWorkout = async () => {
    const msg = this.props.msg
    const appt = msg.data.appointment
    const workout = appt.workout
    const result = await this.props.me.deleteWorkout(workout)
    debugLog('completeWorkout:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    await this.props.waitForSystemUpdate(appt)
  }

  completeWorkout = async () => {
    const msg = this.props.msg
    const appt = msg.data.appointment
    const workout = appt.workout
    const result = await this.props.me.completeWorkout(workout)
    debugLog('completeWorkout:', result)
    if (result.error) {
      throw new Error(result.error)
    }
    await this.props.waitForSystemUpdate(appt)
  }
  
  editWorkout = async () => {
    const msg = this.props.msg
    return this.props.openAppointment(msg.data.appointment)
  }

  renderClientMedia = () => {
    if (this.state.clientMedia.length > 0) {
      const msg = this.props.msg
      const appt = msg.data.appointment
      const workout = appt.workout
      return <div className='uiChatWorkoutUploadedMedia'>
        <ReactSwipeableViews
      onChangeIndex={this.onChangeClientMediaIndex} 
      index={this.clientMediaIndex}
      enableMouseEvents={true}
      ignoreNativeScroll={true}>
        {this.state.clientMedia.map((media, i) => {
          let mediaFragment = '#t=0.001'
          let autoplay = false
          if (isSafari()) {
            if (media.downloadURL.startsWith("blob")) {
              mediaFragment = ''
              autoplay = true
            }
          }
          if (!media) {
            debugger
          }
          return <div className='uiChatWorkoutMedia'>
            {media.contentType.startsWith('video/') ?
             <video muted playsInline controls autoplay={autoplay} src={media.downloadURL + mediaFragment}/>
             :
             <img src={media.downloadURL}/>
            }
          {false && workout.client == this.props.me.self.uid && <div className='uiChatWorkOutMediaDelete'>
            <UIOKCancel cancelIcon={Trash} cancel={()=>this.deleteClientMedia(i)}/>
           </div>}
           </div>
        })
        }
      </ReactSwipeableViews>
        {this.state.clientMedia.length > 1 && <div className='uiChatClientMediaDots'>
        <Dots position={this.mediaIndex} clickable={false} length={this.state.clientMedia.length}/>
         </div>}
        </div>
    }
    return null
  }

  renderDemoMedia = () => {
    const workout = this.props.appt && this.props.appt.workout
    const demo = workout && workout.demo
    debugLog("renderDemoMedia:", this.props.appt)
    if (demo && demo.length > 0) {
      switch (workout.status) {
        case 'declined':
        case 'canceled':
          return null
      }
      return <div className='uiChatWorkoutUploadedMedia'>
        <ReactSwipeableViews
      onChangeIndex={this.onChangeMediaIndex}
      index={this.mediaIndex}
      enableMouseEvents={true}
      ignoreNativeScroll={true}>
        {demo.map(media => {
          let mediaFragment = '#t=0.001'
          let autoplay = false
          if (isSafari()) {
            if (media.downloadURL.startsWith("blob")) {
              mediaFragment = ''
              autoplay = true
            }
          }
          return <div className='uiChatWorkoutMedia'>
            {media.contentType.startsWith('video/') ?
             <video muted autoplay={autoplay} playsInline controls src={media.downloadURL + mediaFragment}/>
             :
             <img src={media.downloadURL}/>
            }
          </div>
        })
        }
      </ReactSwipeableViews>
        {demo.length > 1 && <div className='uiChatClientMediaDots'>
        <Dots position={this.mediaIndex} clickable={false} length={this.state.clientMedia.length}/>
         </div>}
        </div>
    }
    return null
  }
  
  render() {
    const msg = this.props.msg;
    const appt = msg.data.appointment;
    const isOrganizer = () => appt.uid == this.props.localContact.uid && appt.uid != remoteContact.uid
    const defaultText = () => {
      if (appt.workout) {
        const workout = appt.workout
        switch (workout.status) {
          case 'done':
            break
          default:
            if (workout.before) {
              if (workout.client == this.props.localContact.uid) {
                msg.text = remoteContact.displayName + " modified a workout"
              } else {
                msg.text = "You modified a workout" 
              }
              return
            }
            break
          case 'declined':
            if (workout.client == this.props.localContact.uid) {
              msg.text = "You canceled a workout" 
            } else {
              msg.text = remoteContact.displayName + " canceled a workout"
            }
            return
          case 'canceled':
            if (workout.client == this.props.localContact.uid) {
              msg.text = remoteContact.displayName + " canceled your workout"
            } else {
              msg.text = "You canceled a workout" 
            }
            return
          case 'completed':
            {
              let completed = 'completed'
              let prev = workout.before && workout.before.workout.media
              debugLog("completed workout:", workout)
              if (workout.media) {
                if ((!prev  && workout.media.length > 0) || (prev && workout.media.length > prev.length)) {
                  completed = 'added media to'
                } else if (prev && prev.length < workout.media.length) {
                  completed = 'removed media from'
                }
              }
              if (workout.client != this.props.localContact.uid) {
                msg.text = remoteContact.displayName + ` ${completed} a workout`
              } else {
                msg.text = `You ${completed} a workout`
              }
            }
            return
          case 'started':
            if (workout.client != this.props.localContact.uid) {
              msg.text = remoteContact.displayName + " started a workout"
            } else {
              msg.text = "You started a workout"
            }
            return
        }
      }
      if (appt.workout.status !== 'done') {
        const type = appt.workout ? "a workout" : "an appointment"
        const with_ = appt.workout ? 'for' : 'with'

        const contact = appt.workout ? this.props.me.getContact(appt.workout.trainer) : remoteContact
        
        if (isOrganizer()) {
          msg.text = "You"+ scheduled + type + " "+with_+ " "+contact.displayName;
        } else {
          msg.text = contact.displayName + scheduled+ type + " "+with_+ " you";
        }
      }
    }
    const organizer = appt.uid == this.props.localContact.uid ? this.props.localContact: this.props.me.getContact(appt.uid);
    let remoteContact = this.props.remoteContact;
    if (!remoteContact) {
      remoteContact = appt.uid == this.props.localContact.uid ? this.props.me.getContact(appt.client) : this.props.me.getContact(appt.uid);
    }
    let button;
    let isLast = msg.data.isActive || !this.props.remoteContact;
    let isPast = false;
    const now = Date.now();
    if (now > appt.end) {
      isPast = true;
    }
    if (msg.trashed) {
      return null;
    }
    let status;
    const origMsg = msg;
    //msg = clone(msg);
    if (false && msg.canceled || msg.text == "Appointment canceled") {
      status = 'canceled';
      msg.canceled = true;
    } else {
      if (appt.status) {
        status = appt.status;
        if (status == 'accepted') {
          if (appt.invoiceAmount && appt.paymentStatus != 'succeeded') {
            status = 'awaiting-payment';
          } else {
          }
        }
      } else {
        status = 'awaiting-accept';
      }
    }
    let scheduled = ' scheduled ';
    if (appt.before) {
      if (appt.before.start != appt.start || appt.before.end != appt.end) {
        scheduled = ' rescheduled ';
      } else if (appt.status == 'refunded') {
        scheduled = ' refunded ';
      } else {
        scheduled = ' updated ';
      }
    } 
    if (isPast && false) {
      scheduled = " had "+scheduled;
    }
    //////debugLog("chat appointment status: ", status, "msg: ", msg);
    let paymentStatus = appt.paymentStatus;
    switch (status) {
      case "canceled":
        {
          if (isOrganizer()) {
            msg.text = "You canceled an appointment with "+remoteContact.displayName;
          } else {
            msg.text = remoteContact.displayName + " canceled an appointment with you";
          }
          break;
        }
      default:
        {
          //debugger;
          defaultText();
        }
        break;
      case 'refunded':
      case 'awaiting-payment':
      case 'accepted':
        {
          //////debugLog(appt);
          if (appt.before.status != 'accepted') {
            if (!isOrganizer()) {
              msg.text = "You accepted an appointment with "+remoteContact.displayName;
            } else {
              msg.text = remoteContact.displayName + " accepted your appointment";
            }
          } else {
            if (appt.before.paymentIntentId && !appt.paymentIntentId) {
              paymentStatus = 'refunded';
              if (isOrganizer()) {
                msg.text = "You refunded "+remoteContact.displayName + "'s payment on this appointment";
              } else {
                msg.text = remoteContact.displayName + " refunded your payment on this appointment";
              }
            } else if (appt.paymentStatus != "succeeded") {
              if (appt.invoiceAmount) {
                if (!isOrganizer()) {
                  msg.text = "You must complete payment on your appointment with "+remoteContact.displayName;
                } else {
                  msg.text = remoteContact.displayName + " must complete payment on your appointment";
                }
              } else {
                defaultText();
              }
            } else {
              if (!isOrganizer()) {
                msg.text = "You completed payment on your appointment with "+remoteContact.displayName;
              } else {
                msg.text = remoteContact.displayName + " completed payment on your appointment";
              }
            }
          }
          break;
        }
      case 'declined':                    
        {
          if (isOrganizer()) {
            msg.text = remoteContact.displayName + " declined your appointment";
          } else {
            msg.text =  "You declined "+remoteContact.displayName + "'s appointment";
          }
          break;
        }
      case 'schedule':
        {
          if (!isOrganizer()) {
            msg.text = remoteContact.displayName + scheduled+ "an appointment with you";
          } else {
            msg.text =  "You"+ scheduled +"an appointment with "+remoteContact.displayName;
          }
          break;
        }
    }
    if (isLast && status != "canceled") {
      const trash = () => {
        this.props.showSystemProgressIndicator(msg.ts, appt.id, "Deleting Appointment");
        const p = this.props.waitForSystemUpdate(appt);
        return this.props.me.deleteAppointment(appt.id).then(result => {
          /*
            msg.trashed = true;
            msg.data.isActive = false;
            msg.modified = true;
            this.forceUpdate();
            //////debugLog(result);
            */
          return p;                            
        });
        
      }
      const decline = () => {
        this.props.showSystemProgressIndicator(msg.ts, appt.id, "Declining");
        const p = this.props.waitForSystemUpdate(appt);
        return this.props.me.declineAppointment(appt.id).then(() => {
          /*
            msg.data.isActive = false;
            msg.modified = true;
            if (!isOrganizer()) {
            msg.trashed = true;
            }
            this.forceUpdate();
          */
          return p;
        });
      }
      const rescheduleButton = () => {
        const reschedule = () => {
          if (!this.props.openChat) {
          }
          return this.props.openAppointment(appt);
        }
        const label = status == 'declined' ? "Reschedule" : "Edit";
        const icon = status == 'declined' ? Cal : Edit;
        const cancel = appt.paymentStatus != "succeeded" ? trash : null;
        if (this.props.isMe) {
          return null
        }
        return  <UIOKCancel okIcon={icon} cancelIcon={Trash} ok={reschedule} cancel={cancel} label={label}/>
      }
      const acceptButton = (declineOnly) => {
        if (isPast) return null;
        const accept = () => {
          this.props.showSystemProgressIndicator(msg.ts, appt.id, "Accepting");
          const p = this.props.waitForSystemUpdate(appt);
          return this.props.me.acceptAppointment(appt.id).then(() => {
            /*
              appt.before = clone(appt);
              appt.status = "accepted";
              msg.data.isActive = false;
              msg.modified = true;
              this.forceUpdate();
            */;
            return p;
          });
        }
        const ok = declineOnly ? null: accept;
        return <UIOKCancel okIcon={Check} cancelIcon={Cross} ok={ok} cancel={decline} label="Accept"/>
      }


      switch (status) {
        default:
        case 'declined':
          if (!isOrganizer()) {
            break;
          }
        case 'refunded':
        case 'schedule':
          {

            button = isOrganizer() ? rescheduleButton() : acceptButton(status == 'refunded');
            break
          }
        case 'accepted':
        case 'awaiting-payment':
          {
            ////debugger;
            const popupInfo = this.props.popups[appt.id];
            const downloadInvoice = () => {
              const closePopup = () => {
                const popupInfo = this.props.popups[appt.id];
                if (popupInfo) {
                  delete this.props.popups[appt.id];
                  popupInfo.popup.close();
                  clearInterval(popupInfo.timer);
                  this.props.markDirty(msg);
                  this.forceUpdate();
                }
                return Promise.resolve()
              }
              if (this.props.popups[appt.id]) {
                return closePopup();
              } else {
                return this.props.me.showReceipt(appt.id).then(popup => {
                  if (popup && !popup.closed) {
                    const checkPopup = () => {
                      if (popup.closed) {
                        closePopup();
                      }
                    }
                    this.props.popups[appt.id] = {
                      popup: popup,
                      timer: setInterval(checkPopup, 350)
                    }
                    this.props.markDirty(msg);
                    this.forceUpdate();
                  }
                });
              }
            }
            const startSession = () => {
              if (this.props.openChat) {
                return this.props.openChat(remoteContact, 'call');
              } else {
                this.props.toggleCallActive();
                return Promise.resolve();
              }
            }
            const when = this.props.openChat ? new Date(Date.now()) : new Date(msg.ts);
            let cancelIcon;
            let cancel;
            if (appt.invoiceAmount == 0) {
              if (isOrganizer()) {
                cancelIcon = Trash;
                cancel = trash;
              } else {
                cancelIcon = Cross;
                cancel = decline;
              }
            }
            const startSessionButton = (button) => {
              if (isPast) return null;
              let ok = startSession;
              let okIcon = Forward;
              if (!this.props.openChat) {
                if (appt.invoiceAmount > 0 && !isOrganizer()) {
                  okIcon = Save;
                  const label = this.props.popups[appt.id] ? "Close Receipt" : "View Receipt";
                  return <UIOKCancel cancel={null} cancelIcon={null} okIcon={okIcon} ok={downloadInvoice} label={label}/>
                }
                ok = null;
                okIcon = null;
              }
              return <div className='uiChatStartSession'>
                {this.props.openChat && <div className='uiChatStartSessionWhen'>
                 {isOrganizer() ? remoteContact.displayName + "'s" : "Your"} appointment starts <span className='uiChatStartSessionWhenFromNow'>{moment(new Date(appt.start)).from(when)}</span>
                 </div>}
              {button}
                <UIOKCancel cancel={cancel} cancelIcon={cancelIcon} okIcon={okIcon} ok={ok} label="Start Now"/>
                </div>;
            }
            if (!appt.invoiceAmount) {
              button = startSessionButton();
            } else {
              if (appt.paymentStatus == 'succeeded') {
                if (isOrganizer()) {
                  const refund = () => {
                    this.props.showSystemProgressIndicator(msg.ts, appt.id, "Refunding Payment");
                    const p = this.props.waitForSystemUpdate(appt);
                    return this.props.me.refundAppointment(appt.id).then(result => {
                      /*
                  //////debugLog("refund: ", result);
                  const appt = msg.data.appointment;
                  appt.before = clone(appt);
                  appt.status = "refunded";
                  appt.paymentIntentId = null;
                  appt.paymentStatus = null;
                  msg.data.isActive = false;
                  msg.modified = true;                                                
                  this.forceUpdate();
                      */
                      if (result.error) {
                        console.error(result.error);
                        return;
                      }
                      return p;
                    });
                  }
                  const refundButton =
                        <div className='uiChatExtraButton'>
                        <UIOKCancel okIcon={Refund} ok={refund} label="Refund Payment"/>
                        </div>
                    button =                                  
                    startSessionButton(refundButton);
                } else {
                  button = startSessionButton();
                }
              } else {
                if (isOrganizer()) {
                  button = rescheduleButton();
                } else {
                  button = <ChatPaymentComponent
                  waitForSystemUpdate={()=>this.props.waitForSystemUpdate(appt)}
                  setPaymentError={err=> {appt.paymentError=err; this.forceUpdate()}}
                  appointment={appt} me={this.props.me} msg={msg}/>
                }
              }
            }
            break
          }
      }
      //////debugLog("button => ", button);
    }
    let hideWith = this.props.hideWith && !appt.workout
    let end
    let with_ = remoteContact
    if (appt.workout && appt.workout.activity) {
      with_ = appt.workout.activity
      hideWith = false
    }
    if (appt.end) end = new Date(appt.end)
    if (isLast && appt.workout) {
      if (appt.workout.status != 'declined' && appt.workout.status != 'canceled') {
        if (appt.workout.client == this.props.me.self.uid) {
          if (appt.workout.status == 'completed') {
            let cancel
            let cancelIcon = Trash
            let ok
            ok = async () => {
              return 
            }
            if (this.state.clientMedia.length > 0) {
              cancel = async () => {
                return this.deleteClientMedia(this.clientMediaIndex)
              }
            } else {
              if (!this.props.remoteContact) {
                cancel = async () => {
                  await this.props.me.addMediaToWorkout(appt.workout.id, [])
                }
                cancelIcon = Cross
              }
            }
            const onFileInput = async e => {
              this.setState({
                lookBusy: true,
                uploadError: false
              })
              let err = null
              const files = Array.from(e.target.files)
              if (files.length > 0) {
                const media = await Promise.all(files.map(async file => {
                  return {
                    contentType: file.type,
                    downloadURL: URL.createObjectURL(file),
                  }
                }))
                this.setState({
                  clientMedia: this.state.clientMedia.concat(media)
                })
                const channel = [appt.workout.trainer, appt.workout.client].sort().join('-')
                let uploadedMedia
                try {
                  uploadedMedia = await Promise.all(files.map(async (file, i) => {
                    const ref = await this.props.me.uploadFileToChannel(channel, file)
                    const downloadURL = await ref.getDownloadURL()
                    return {
                      contentType: file.type,
                      downloadURL: downloadURL
                    }
                  }))
                  await this.props.me.addMediaToWorkout(appt.workout.id, uploadedMedia)
                } catch (uploadError) {
                  console.error(uploadError)
                  err = true
                }
              }
              this.setState({
                lookBusy: false,
                uploadError: err
              })
            }
            button =
              <div className='uiChatWorkoutMediaUpload'>
              <div className='uiChatWorkoutMediaUploadTrash'><UIOKCancel cancel={cancel} cancelIcon={cancelIcon}/></div>
              <input onChange={onFileInput} id={'upload-'+appt.workout.id+'-'+msg.ts} type='file' multiple accept='image/*,video/*'/>
              <label htmlFor={'upload-'+appt.workout.id+'-'+msg.ts}>
              <UIOKCancel error={this.state.uploadError} lookBusy={this.state.lookBusy} okIcon={ImageIcon} ok={ok} label={"Upload Media"}/>
              </label>
              </div>
          } else {
            const ok = this.startEndWorkout
            let cancel = this.skipWorkoutMedia
            let cancelIcon = Cross
            if (appt.workout.client == appt.workout.trainer) {
              cancel = this.deleteWorkout
              cancelIcon = Trash
            }
            button = <UIOKCancel okIcon={Check} cancelIcon={cancelIcon} ok={ok}
            cancel={cancel} label={appt.workout.status != 'started' ? "Start Workout" : "Complete Workout"}/>
          }
        } else {
          if (!appt.workout.status) {
            const ok = this.editWorkout
            const cancel = this.deleteWorkout
            button = <UIOKCancel okIcon={Edit} cancelIcon={Trash} ok={ok} cancel={cancel} label="Modify Workout"/>
          } else {
            button = null
          }
        }
      }
    }
    let onClick = () => this.props.openAppointment(appt)
    if (appt.workout) {
      if (appt.workout.trainer != this.props.me.self.uid) {
        onClick = null
      }
      if (appt.workout.status == 'declined' ||
          appt.workout.status == 'canceled') {
        onClick = null
      }
      button = null
    }
    const waitForSystemUpdate = () => this.props.waitForSystemUpdate(appt)
    return <div className='uiChatAppointmentContainer'>
      <div className='uiChatAppointmentMessage'>{msg.text}</div>
      <UIAppointment inactive={!msg.data.isActive} me={this.props.me} appt={appt} openChat={this.props.openChat} onEnded={()=>this.props.onAppointmentEnded(msg)} onClick={onClick} paymentError={appt.paymentError} isChat={true} organizer={organizer} client={appt.client} status={appt.status} appointment={appt} id={appt.id} editable={false} start={appt.start  ? new Date(appt.start) : null} title={appt.title || "Video Conference"} end={end} with={with_} paymentAmount={appt.paymentAmount} invoiceAmount={appt.invoiceAmount} paymentStatus={paymentStatus} hideWith={hideWith} waitForSystemUpdate={waitForSystemUpdate}/>
      {button}
    </div>
  }
}

export class ChatPaymentComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    const appt = this.props.appointment;
    if (appt.paymentStatus == 'succeeded') {
      this.state.savedPaymentMethod = appt.finalPaymentMethod;
    }
  }

  setPaymentMethod = (x) => {
    this.state.paymentMethod = x;
    this.forceUpdate();
  }

  componentDidMount() {
    const appt = this.props.appointment;
    if (appt.paymentStatus != "succeeded") {
      this.props.me.getPaymentMethod(appt.id).then(paymentMethod => {
        //debugger;
        this.state.savedPaymentMethod = paymentMethod;
        this.forceUpdate();
      });
    }
  }

  createPaymentMethod = () => {
    const elements = this.elements;
    const stripe = this.stripe;
    const cardElement = this.elements.getElement(CardElement);
    //////debugLog("got card element: ", cardElement);
    return this.stripe.createToken(cardElement, {currency: "usd"}).then(result => {
      //debugger;
      if (result.error) {
        this.props.setPaymentError(result.error.message);
      } else {
        //////debugLog("saving payment method: ", result);
        return this.props.me.savePaymentMethod(result.token.id).then(() => {
          //debugger;
          //////debugLog("saved payment method: " , result);
          this.state.savedPaymentMethod = result.token;
          this.forceUpdate();
          return result.token;
        });
        return result.token;
      }
    }).catch(err => {
      //debugger;
      this.props.setPaymentError(err.error.message);
    });
  }

  ensurePaymentMethod = () => {
    if (this.state.savedPaymentMethod) {
      return Promise.resolve(this.state.savedPaymentMethod);
    }
    return this.createPaymentMethod();
  }
  
  makePayment = () => {
    const msg = this.props.msg;
    const appt = this.props.appointment;
    const getPaymentIntent = () => {
      return this.props.me.getPaymentIntent(appt.id);
    }
    return getPaymentIntent().then(data => {
      //////debugLog("got payment intent data: ", data);
      const clientSecret = data.id;
      let p = Promise.resolve();
      if (data.status == "requires_confirmation") {
        const stripe = window.Stripe(window.stripe_key, {stripeAccount: data.account});
        p = stripe.confirmCardPayment(clientSecret).then(result => {
          //////debugLog(result);
        });
      }
      const p1 = this.props.waitForSystemUpdate();
      return p.then(() => {
        /*
          msg.data.isActive = false;
          const appt = msg.data.appointment;
          appt.before = clone(appt);
          const paymentStatus = appt.paymentStatus;
          if (paymentStatus == 'refunded') {
          appt.status = "accepted";
          }
          appt.paymentStatus = "succeeded";
          this.forceUpdate();
          msg.modified = true;
        */
        return p1;
      }).catch(err => {
        //debugger;
        this.props.setPaymentError(err.error.message);
      });
    });
  }
  
  accept = () => {
    return this.ensurePaymentMethod().then(result => {
      //debugger;
      if (result) {
        return this.makePayment();
      }
    });
  }

  setPaymentMethod = method => {
    let savedPaymentMethod = this.state.savedPaymentMethod;
    if (!method) {
      savedPaymentMethod = null;
    }
    this.setState({
      savedPaymentMethod: savedPaymentMethod
    });
  }
  
  render() {
    const paymentStatus = this.props.appointment.paymentStatus;
    const cancelIcon = Cross;
    const cancel = this.props.decline;
    const okIcon = Forward;
    const ok = this.accept;
    const buttonLabel = paymentStatus == "refunded" ? "Make a new payment" : "Make Payment";
    const payment = <div className='uiChatMakePayment'>
          <UICreditCardInput paymentMethod={this.state.savedPaymentMethod} setPaymentMethod={this.setPaymentMethod} paymentStatus={paymentStatus}/>
          
          {paymentStatus != "succeeded" && <UIOKCancel ok={ok} okIcon={okIcon} cancel={cancel} cancelIcon={cancelIcon} label={buttonLabel}/>}
    </div>;
    return <Elements stripe={window.stripePromise}>
      <ElementsConsumer>
      {({elements, stripe}) => {
        // stripe wtfs
        this.elements = elements;
        this.stripe = stripe;
        return payment;
      }}
    </ElementsConsumer>
      </Elements>
  }
}


class UIMessageEditor extends Component {
  constructor(props) {
    super(props);
    this.contentEditable = React.createRef();
    this.state = {
      html: "",
      editorWidth: 20,
    }
    this.resizeObserver = new ResizeObserver(entries => {
      this.setState({
        editorHeight: this.contentEditable.current.offsetHeight,
        editorWidth: this.contentEditable.current.offsetWidth
      });
    });
  }

  getNode = () => {
    return this.contentEditable.current;
  }

  onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDrop(e);
  }

  init = ()=> {
    const el = this.contentEditable.current;
    const dropTarget = el.parentNode;
    dropTarget.ondrop = this.onDrop;
    el.ondrop = this.onDrop;
    if (this.props.onPaste) {
      el.onpaste = this.props.onPaste;
    }
    if (this.props.initialValue) {
      el.innerHTML = this.props.initialValue;
      this.selectAll();
    }
    el.addEventListener("input", () => {
      this.forceUpdate();
      if (this.props.onUpdate) {
        this.props.onUpdate();
      }
    })
    if (this.props.autoFocus) {
      el.focus();
    }
    this.resizeObserver.observe(el);
  }

  isFocused= ()=> this.contentEditable.current && document.activeElement == this.contentEditable.current;

  selectAll = () => {
    const range = document.createRange();
    range.selectNodeContents(this.contentEditable.current);
    this.lastSelectionRange = range;
    if (document.activeElement == this.contentEditable.current) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  onSelectionChange=(e) => {
    if (document.activeElement != this.contentEditable.current) {
      return;
    }
    this.lastSelectionRange = window.getSelection().getRangeAt(0);
    ////////debugLog("saved selection: ", this.lastSelectionRange);
  }

  componentDidMount() {
    this.init();
  }
  
  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.onSelectionChange);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }


  restoreSelection = () => {
    if (this.lastSelectionRange) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      var range = this.lastSelectionRange;
      sel.addRange(range);
    }
  }

  onFocus=(e)=> {
    this.focused = true;
    if (this.props.onFocus) this.props.onFocus(e, this, ()=>{});
    this.restoreSelection();
    document.addEventListener("selectionchange", this.onSelectionChange);
  }

  onEditorHeightChanged = height => {
    this.setState({editorHeight: height});
  }

  onBlur=(e)=> {
    this.focused = false;
    if (this.props.onBlur) this.props.onBlur(e, this, ()=>{});
  }

  onKeyDown=(e)=> {
    if (this.props.onKeyDown) this.props.onKeyDown(e, this, ()=>{});
  }

  onKeyUp=(e)=> {
    if (this.props.onKeyUp) this.props.onKeyUp(e, this, ()=>{});
  }

  focus = () => {
    this.contentEditable.current.focus();
  }

  blur = () => {
    if (this.contentEditable.current) this.contentEditable.current.blur();
  }

  isEmpty = (debug) => {
    if (!this.contentEditable.current) { 
      return true;
    }
    const trim = this.contentEditable.current.innerHTML;
    if (debug) {
      ////debugger;
    }
    return !trim || trim == "<br>";
  }

  render = () => {
    return <div className='uiChatEditor'>
      <div className='uiChatEditorBg' style={{height: this.state.editorHeight}} />
      <div className='uiChatEditorContainer'><div className={this.props.editorClass} contentEditable={true} ref={this.contentEditable } onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp}/>
      {this.props.placeholder && <div className={this.props.placeholderClass} style={this.isEmpty() ? {} : {display: "none"}}>{this.props.placeholder}</div>}</div>
      </div>
  }

  undo = () => {
    document.execCommand("undo");
  }
  
  
  insert=(html, selectPastedContent) => {
    if (document.activeElement != this.contentEditable.current) {
      this.contentEditable.current.focus();
      setTimeout(() => {
        this.insert(html, selectPastedContent);
      });
    } else {
      setTimeout(() => {
        this.doInsert(html, selectPastedContent);
      }, 30);
    }
  }

  clear = () => {
    this.contentEditable.current.innerHTML = "";
    this.forceUpdate();
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }


  getText=() => {
    if (true) {
      return turndownService.turndown(this.contentEditable.current)
    }
    const apply = node => {
      let result = "";
      walkDOM(node, n => {
        ////////debugLog("n.nodeName: ", n.nodeName);
        if (n instanceof HTMLVideoElement) {
          const link = n.getAttribute("vid-link");
          result += "Shared a video";
        }
        else if (n instanceof HTMLImageElement) {
          const name = n.getAttribute("emoji-name");
          if (name) {
            result += name;
          } else {
            const gif = n.getAttribute("gif-link");
            if (gif) {
              result += ' ' + gif + ' ';
            } else {
              result += ' ' + n.src + ' ';
            }
          }
        } else if (n instanceof HTMLSpanElement) {
          const name = n.getAttribute("emoji-name");
          if (name) {
            result += name;
          }
        } else if (n.nodeName == "WBR") {
        } else if (n.nodeName == "BR") {
          result += "\n";
        } else if (n.nodeType == 3 && !(n.parentElement instanceof HTMLSpanElement)) {
          let textContent = n.textContent.replace(nbsp, " ");
          result += textContent;
          debugger
          if (n.parentElement !== node && n.parentElement instanceof HTMLDivElement) {
            result += '\n'
          }
        }
      });
      result = result.replace(/\n•[ ]+/g, "\n•  ");
      return result
    }
    return apply(this.contentEditable.current)
  }

  doInsert=(html, selectPastedContent) => {
    document.execCommand("insertHTML", false, html);
  }
}

export class InlineMedia extends Component {
  constructor(props) {
    super(props);
    const file = this.props.file;
    this.state = {
      loaded: false,
      videoPlaying: false,
      isVideo: file.contentType.startsWith("video/"),
    };
  }
  componentWillUnmount() {
    if (this.video) this.video.playing = false;
    //debugLog("inline media unmounted: ", this.props.file);
  }

  handleVideoLoaded  = e => {
    e.target.position = 0.01
    e.target.pause()
    this.handleLoaded(e)
  }

  handleLoaded = (e) => {
    this.setState({loaded: true}, this.props.onLoaded);
  }

  playPause = () => {
    this.setState({
      videoPlaying: !this.state.videoPlaying
    }, () => {
      if (this.state.videoPlaying) this.video.play(); else this.video.pause();
    });
    return Promise.resolve();
  }

  handleVideoFail = (e) => {
    this.setState({
      videoFail: true
    });
  }

  setImageRef = ref => {
    if (ref && ref != this.image) {
      this.image = ref;
      this.image.addEventListener("load", () => {
        this.image.style['max-width'] = "calc(min(100%, "+ this.image.naturalWidth + "px))";
      });
    }
  }

  componentDidMount() {
    if (this.video) {
    }
  }
 

  setVideoSrc = (ref) => {
    if (ref && ref !== this.video) {
      this.video = ref;
      this.video.loop = true;
    }
  }
  render = () => {
    const self = this
    const file = this.props.file;
    //////debugLog("file: ", file);
    let media;
    const src = file.src || file.downloadURL
    if (file.contentType.startsWith("image/") && src) {
      media = <img ref={this.setImageRef} onClick={this.props.maximize} height={this.props.image_height} width={this.props.image_width} onLoad={this.handleLoaded} className='uiChatInlineImage' src={file.thumbnailURL ? file.thumbnailURL : src}/>;
    } else if (this.state.isVideo) {
      if (this.state.videoFail && this.props.alternateUrl) {
        media = <img onLoad={this.handleLoaded}  className='uiChatInlineImage' src={this.props.alternateUrl}/>;
      } else {
        self.props.me.nativeLog('src: ' + src)
        let mediaFragment = '#t=0.001'
        let autoplay = false
        if (isSafari()) {
          if (media.downloadURL.startsWith("blob")) {
            mediaFragment = ''
            autoplay = true
          }
        }
        media = <video preload={'metadata'} controls autoplay={autoplay} muted playsInline onError={this.handleVideoFail} onLoadedData={this.handleVideoLoaded} ref={this.setVideoSrc} className='uiChatInlineVideo'><source type={file.contentType} src={src + mediaFragment}/></video>;
      }
    }
    if (media) {
      const filename = file.name;
      const downloading = file.state == 'downloading';
      const download = () => {
        if (file.state == 'downloading') return;
        file.state = "downloading";
        this.markDirty(msg);
        this.forceUpdate();
        const msg = this.props.msg;
        return this.props.downloadFile(msg).then(() => {
          delete file.state;
          this.markDirty(msg);
          this.forceUpdate();
        });
      }
      return <div key={src} className='uiChatInlineMedia'>
        {media}
      {true  ? null:  (this.state.isVideo && !this.state.videoFail &&
       (false ? <div className='uiChatInlineMediaPlayPause'>
        <UIOKCancel okIcon={this.state.videoPlaying ? Pause: Forward} label={filename} ok={this.playPause}/>
        </div>
        :
        <div className='uiChatFileUpload uiChatFileDownload' onClick={download}>
        <div className='uiChatFileUploadSpinnerAndText'>
        {downloading ? <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> :
         <div className='uiChatFileUploadIcon'><ReactSVG src={Save}/></div>}
        <div className='uiChatFileUploadText'>
        <span>Download&nbsp;</span>{!isMobile() && <span className='uiChatFileUploadTextFilename'></span>}
        </div>
        </div>
        <div className='uiChatFileUploadIcon'><ReactSVG src={ImageIcon}/></div>
        </div>))                    
      }
      </div>
    }
    return <a href={src}>{file.name}</a>;
  }
}

export class UIChatTodoListSubscription extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      subscriptionError: ''
    }
  }

  componentDidMount() {
    this.sub = this.props.me.observeAccount().subscribe(account => {
      this.setState({
        paymentMethod: account ? account.paymentMethod : null
      })
    })
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  renderCard = () => {
    const CARD_OPTIONS = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: 'white',
          color: 'white',
          fontWeight: 500,
          fontSize: '14px',
          caretColor: 'white',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: 'white',
          },
          '::placeholder': {
            color: 'white'
          },
        },
        webkitAutoFill: {
          color: 'white',
          caretColor: 'white',
          backgroundColor: 'rgb(33, 161, 196)'
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#ffc7ee',
        },
      },
    };
    return <div className='uiStripeClientConnectForm'>
      <div className='uiStripeConnectFormCard'><CardElement onChange={this.onChangeCard} options={CARD_OPTIONS}/></div>
      </div>        
  }

  onChangeCard = e => {
    if (this.state.cardComplete !== e.complete) {
      this.setState({
        cardComplete: e.complete
      })
    }
  }

  clearPaymentMethod = () => {
    this.setState({
      paymentMethod: null
    })
  }

  renderPaymentMethod = () => {
    if (this.state.paymentMethod && this.state.paymentMethod.card) {
      const { brand, last4 } = this.state.paymentMethod.card
      return <div className='uiTodoListExistingPaymentMethod'>
        <div className='uiTodoListExistingPaymentMethodCard'><div className='uiPaymentMethodIcon'><ReactSVG src={CreditCard}/></div>{brand.toUpperCase()} ending in {last4}</div>
        <div className='uiTodoListExistingPaymentMethodClear' onClick={this.clearPaymentMethod}>
        <ReactSVG src={Cross}/>
        </div>
        </div>
    }
    return <Elements stripe={window.stripePromise}>
      <ElementsConsumer>
      {({elements, stripe}) => {
        // stripe wtfs
        this.elements = elements;
        this.stripe = stripe;
        return this.renderCard()
      }}
    </ElementsConsumer>
      </Elements>
  }

  apply = async () => {
    const sub = this.props.subscription
    switch (sub.status) {
      case 'active':
        await this.props.me.cancelTodoListSubscription()
        return
    }
    if (!this.state.paymentMethod) {
      let err = ''
      if (!this.state.cardComplete) {
        err = 'Your card is incomplete.'
      }
      this.setState({
        subscriptionError: err
      })
      if (err) return
      const card = this.elements.getElement(CardElement)
      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card: card
      })
      console.log(result)
      const { error, paymentMethod } = result
      if (error) {
        this.setState({
          subscriptionError: error.message
        })
        return
      }
      await this.props.me.saveTodoListPaymentMethod(paymentMethod.id)
    }
    await this.props.me.startTodoListSubscription()
  }
  
  render() {
    const sub = this.props.subscription
    let className = 'uiTodoListSubscription'
    let t = this.props.subscription ? this.props.subscription.created : Date.now()
    let date = formatDate(t)
    let okLabel = 'Purchase'
    let ok = this.apply
    let when = 'STARTS'
    let okIcon = Forward
    switch (sub.status) {
      case 'pending':
        break
      case 'active':
        className += ' uiTodoListSubscriptionActive'
        when = 'ACTIVE'
        okLabel = 'Cancel'
        okIcon = Trash
        break
      case 'canceled':
        className += ' uiTodoListSubscriptionCanceled'
        when = 'CANCELED'
        break
    }
    return <div className={className}>
      <div className='uiTodoListSubscriptionStatus'/>

      <div className='uiTodoListSubscriptionBody'>
      <div className='uiTodoListSubscriptionBodyTitle'>Plan to Relax</div>
      <div className='uiTodoListSubscriptionBodySubtitle'>{sub.plan}</div>
      <div className='uiTodoListSubscriptionWhen'>{when}</div>
      <div className='uiTodoListSubscriptionDate'>{date}</div>

      <div className='uiTodoListSubscriptionPrice'>
      <div className='uiTodoListSubscriptionPriceAmount'>${sub.price}</div>
      <div className='uiTodoListSubscriptionDuration'>&nbsp;monthly</div>
      </div>

      <div className='uiTodoListSubscriptionPaymentMethod'>
      {this.renderPaymentMethod()}
      </div>
      <div className='uiTodoListSubscriptionError'>
      {this.state.subscriptionError}
      </div>
      <div className='uiTodoListSubscriptionButtons'>
      <UIOKCancel okIcon={okIcon} label={okLabel} ok={ok}/>
      </div>

      </div>
      </div>
  }
}


let chatId = 0;

export class UIChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingImages: {},
      uploads:[],// [{progress: 40}]
    };
    //debugLog('uiChat', props)      
    this.popups = {};
    this.scrollBottom = 0;
    this.lastContentHeight = -1;
    this.lastHeight = 0;
    ++chatId;
    this.chatId = chatId;
  }



  setEditor = ref => {
    if (ref) {
      this.editor = ref;
    }
  }


  clearEdit = () => {
    this.editor.clear();
    this.video = null;
  }
  
  undoEdit = () => {
    this.editor.undo();
    this.video = null;
    const text = this.editor.getText();
    if (!text) {
      this.setState({
        editingMessage: null
      });
    }
  }

  onFocus = e => {
    if (isMobile()) {
      this.forceUpdate()
    }
  }

  onBlur = e => {
    if (isMobile()) {
      this.forceUpdate()
    }
  }

  send = () => {
    if (this.state.editingMessage) {
      return this.saveMessage();
    }
    if (this.video) {
      const video = this.video;
      const vid = document.createElement('video')
      vid.src = URL.createObjectURL(video)
      const self = this
      vid.onloadedmetadata = () => {
        const dur = vid.duration
        if (dur == Infinity || dur < 10) {
          this.setState({
            cantSendMessageError: "Video duration must be at least 10 seconds"
          })
        } else {
          this.editor.clear();
          self.video = null;
          const now = Date.now()
          const timestamp = moment(now).format('MM-DD-YY hh:mm:ss a')
          const file = new File([video], `Recorded Message ${timestamp}`, {type: video.type || 'video/mp4'})
          self.uploadFile(file)
        }
      }
      return
    }
    let text = this.editor.getText();
    //debugger
    if (text) {
      const links = find(text);
      let files;
      links.map(link => {
        this.props.me.linkPreviewer.getLinkPreview(link.href);
        text = text.split(link.href).join("<"+link.href+">");
      });
      this.props.sendMessage(text);
      this.scrollToBottom();
      this.editor.clear();
    }
  }

  onKeyDown = e => {
    const RETURN = "Enter";
    const insertNewline = () => {
      e.preventDefault()
      e.stopPropagation()
      document.execCommand('insertLineBreak')
    }
    if (isDesktop()) {
      ////debugLog("key: ", e.key);
      if (e.key === RETURN && !e.shiftKey) {
        insertNewline()
        this.send();
        return
      }
      const ESC = "Escape"
      if (e.key === ESC) {
        this.cancelEdit()
        return 
      }
    } 
    if (e.key === RETURN) {
      insertNewline()
    }
  }

  uploadFile = file => {
    const name = file.name.toLowerCase();
    if (name.endsWith(".mov")) {
      try {
        file = new File(file, name.replace(".mov", ".mp4"));
      } catch (err) {
        this.props.me.nativeLog(err)
      }
    }
    const upload = {
      file: file,
      progress: 0,
      blobUrl: URL.createObjectURL(file)
    };
    const uploads = [upload].concat(this.state.uploads);
    const progress = percent => {
      upload.progress = percent;
      this.forceUpdate();
    }
    this.setState({
      uploads: uploads,
    })
    return this.props.uploadFile(file, progress, false).finally(() => {
      this.setState({
        uploads: this.state.uploads.filter(x => x.file != file),
      })
    })
  }

  handleDataTransfer = (event, transfer)=> {
    if (transfer.files.length > 0) {
      event.preventDefault();
      for (var i = 0; i < transfer.files.length; i++) {
        let file = transfer.files.item(i);
        this.uploadFile(file);
      }
      return true;
    }
    return false;
  }
  
  onPaste = e => {
    if (e.clipboardData.files && e.clipboardData.files.length > 0) {
      if (this.handleDataTransfer(e, e.clipboardData)) {
        return;
      }
    }
  }
  
  onUpdate = e => {
  }

  onDrop = e => {
    const transfer = e.dataTransfer;
    this.handleDataTransfer(e, transfer);
  }

  onBlur = e => {
  }

  onEditorUpdate = e => {
    this.forceUpdate();
    this.props.typing();
  }

  isGroup = () => this.props.remoteContact.isGroup;
  isGroupOrganizer = () => this.props.remoteContact.group.organizer == this.props.me.self.uid;

  renderLinkPreview=(preview)=> {
    let foundVideo = false;
    let alternateUrl;
    let contentType = preview.contentType;
    let showPreview = true;
    let mediaUrl = preview.url;
    let url = preview.url;
    if (!url) {
      console.error("invalid link preview: ", preview)
      return null;
    }
    if (url.endsWith(".gif")) {
      return null;
    }
    switch (preview.mediaType) {
      default:
        showPreview = false;
        break;
      case "index":
      case "website":
      case "article":
        {
          if (!preview.title || !preview.description) {
            ////////debugLog("not showing preview: ", preview);
            showPreview = false
            break;
          }
        }
      case "video.other":
        try {
          if (preview.videos && preview.videos.length > 0) {
            mediaUrl = preview.videos[0].secureUrl;
            contentType = preview.videos[0].type;
            foundVideo = mediaUrl != null;
          }
        } catch (error) {
          //////debugLog(preview);
          console.error(error);
        }
        if (preview.images && preview.images.length > 0) {
          if (foundVideo) {
            alternateUrl = preview.images[0];
          } else {
            mediaUrl = preview.images[0];
          }
          contentType = mime.lookup(mediaUrl);
          if (!contentType) {
            break;
          }
        }
      case "video":
        break;
      case "image":
        {
          if (preview.contentType == 'image/gif') {
            return <img src={preview.url}/>;
          }
        }
    }
    let showInlineMedia = preview.mediaType == "video" || preview.mediaType == "image";
    const showDescription = !showInlineMedia && preview.mediaType != "video.other";
    if (preview.mediaType == "video.other") {
      showInlineMedia = true;
    }
    const inlineMedia = () => {
      const slash = mediaUrl.lastIndexOf("/");
      if (!contentType) {
      }
      let filename = mediaUrl.substring(slash + 1);
      const file = {
        name: preview.title ? preview.title : filename,
        src: mediaUrl,
        contentType: contentType
      }
      ////////debugLog("creating inline media: ", mediaUrl);
      return <InlineMedia me={this.props.me} height={preview.image_height} width={preview.image_width} key={mediaUrl} maximize={e=>this.openFile(file)} file={file} alternateUrl={alternateUrl}  isExternalLink={true}/>;
    }
    if (!preview.title) {
      //preview.title = node.url;
    }
    if (this.isBlacklistedForPreview(preview.siteName, true)) {
      showPreview = false;
    }
    if (preview.title == "YouTube" && preview.siteName == "youtube.com" || preview.siteName == "GIPHY") {
      showPreview = false;
    }
    if (showPreview) {
      let previewImage;
      if (preview.images && preview.images.length > 0) {
        previewImage = preview.images[0];
      }
      const div = (
          <div className='website-preview'>
          <div className='website-preview-site'>
          {preview.favicons && preview.favicons.length > 0 && <img alt={""} src={preview.favicons[0]}/>}
          <p>{preview.siteName}</p>
          </div>
          <div className='website-preview-title'>                                                       
          <a href={url} onClick={e=>this.openFileLink(e, url)}>{preview.title}</a>
          </div>
          {showInlineMedia && inlineMedia()}
        {!showInlineMedia && preview.images && preview.images.length > 0 && <div className='website-preview-image'><img alt={""} src={preview.images[0]} onLoad={e => {
          ////debugLog("img.onLoad: ", e);
          preview.image_width  = e.target.naturalWidth;
          preview.image_height = e.target.naturalHeight;
          this.forceUpdate();
        }} height={preview.image_height} width={preview.image_width}/></div>}
        {showDescription && <div style={preview.image_width ? {maxWidth: preview.image_width}: null} className='website-preview-description'> 
         <p >{preview.description}</p> 
         </div>}
        </div>);
      return div;
    } else if (showInlineMedia) {
      return inlineMedia();
    }
    return null;
  }

  openFile = file => {
    FileSaver.saveAs(file.src, file.name, {type: file.type});
  }
  
  openFileLink = (event, url) => {
    if (event) event.preventDefault();
    this.props.me.openWindow(url, "_blank");
  }

  noPreview = ["appspot.com", "google.com", "atlassian.net", "github.com", "microsoft.com", ".md", "gmail.com", "idmsa.apple.com"];

  isBlacklistedForPreview=(link, noValidate)=>{ 
    let url;
    try {
      url = new URL(link);
    } catch (exc) {
      return !noValidate;
    }
    return this.noPreview.find(domain => url.hostname.endsWith(domain));
  }

  slackToHtml = (editing, msg, message, noPreview) => {
    const ts = msg.ts
    const emojiImageClass = 'slack_emoji';
    const emojiUnicodeClass = 'slack_emoji';
    let i = 0;
    const nextKey = () => {
      const result = "slackToHtml-"+i;
      i++;
      return result;
    }
    const n = parse(unescape(message));
    const retry = () => {
      //console.log("retry", msg.ts)
      this.markDirty(msg)
      this.forceUpdate()
    }
    const nodeStack = [];
    const nodeToReact = (node, index)=> {
      ////////debugLog("parse: ", node);
      const content = () => {
        const doit = () => {
          if (!node.children) {
            if (node.text) {
              return nodeToReact(parse(node.text));
            }
          }
          return node.children.map(nodeToReact);
        }
        nodeStack.push(node);
        try {
          return doit();
        } finally {
          nodeStack.shift();
        }
      }
      switch (node.type) {
        case NodeType.Root:
          return <div key='message'>{content()}</div>;
          
        case NodeType.Text:
          {
            const escaped = unescape(node.text);
            const start = String.fromCodePoint(57344);
            const end = String.fromCodePoint(57345);
            const nbsp = "\u00A0";
            const renderText = (escaped) => {
              let j = escaped.indexOf(start);
              let hi = false;
              if (j >= 0) {
                let i = -1;
                if (j === 0) {
                  i = j;
                  j = escaped.indexOf(end);
                  hi = true;
                } 
                let result = [];
                while (true) {
                  const className = hi ? "slack_highlight_text": "slack_text";
                  result.push(<span className={className}>{escaped.substring(i+1, j)}</span>);
                  hi = !hi;
                  i = j;
                  j = escaped.indexOf(hi ? end : start, i+1);
                  if (j < 0) {
                    result.push(<span className='slack_text'>{escaped.substring(i+1)}</span>);
                    break;
                  }
                }
                return <React.Fragment>{result}</React.Fragment>;
              }
              //return <span key={nextKey()} className='slack_text'>{escaped}</span>;
              return escaped;
            }
            const lines = escaped.split("\n");
            const renderLine = (j, line, br) => {
              if (line.startsWith("•")) {
                const bullet = [<br/>, "•", 
                                nbsp, <wbr/>, 
                                //nbsp, <wbr/>, 
                                //nbsp, <wbr/>, 
                                nbsp, <wbr/>,
                                renderText(line.substring(1))];
                return  <span key={'line'+j} className='slack_bullet'>{bullet}</span>;
              }
              if (br) {
                return [renderText(line), <br/>];
              }
              return renderText(line);
            };
            if (lines.length > 1) {
              const output = [];
              for (var j = 0; j < lines.length; j++) {
                const line = lines[j];
                output.push(renderLine(j, line, j+1 < lines.length));
              }
              return <div key={'lines-'+index}>{output}</div>;
            }
            return renderLine(0, lines[0]);
          }
        case NodeType.Bold:
          return <strong className='slack_bold'>{content()}</strong>;
          
        case NodeType.Italic: 
          return <i className='slack_italics'>{content()}</i>;
          
        case NodeType.Strike: 
          return <del className='slack_strikethrough'>{content()}</del>;
          
        case NodeType.Quote: 
          return <blockquote className='slack_blockquote'>{content()}</blockquote>;
          
        case NodeType.ChannelLink: 
          
          break;
          
        case NodeType.UserLink: 
          {
            const userName = this.resolveUserLink(node);
            ////////debugLog("userLink: ", node);
            if (this.props.isConference) {
              return <span classname='slack_user_nolink'>{userName}</span>;
            }
            return <span key={nextKey()} onClick={e=>this.openUserLink(node.userID)} className='slack_user'>{userName}</span>;;
          }
        case NodeType.URL: 
          {
            let results = [];
            node.url = node.url.replace(/&amp;/g, "&");
            const u = new URL(node.url)
            if (u.pathname.endsWith('.gif')) {
              return <img onLoad={()=>{
                retry()
              }} src={node.url}/>;
            }
            if (editing) {
              let preview;
              if (message.linkPreviews) {
                preview = message.linkPreviews.find(x => x.url == node.url);
              }
              if (!preview) {
                preview = this.props.me.linkPreviewer.getExistingLinkPreview(node.url, result => {
                  //console.log("got link preview #1")
                  retry()
                });
              }
              if (preview && preview.url) {
                const result = this.renderLinkPreview(preview);
                if (result) {
                  results.push(result);
                }
              } else if (this.state.editingImages[node.url]) {
                const file = {
                  contentType: "image/gif",
                  name: 'some gif',
                  src: this.state.editingImages[node.url]
                }
                results.push(<img onLoad={()=>this.markDirty(msg)} src={node.url}/>);
              }
            } else {
              if (message.linkPreviews && !noPreview && !nodeStack.find(n => n.type == NodeType.PreText || n.type == NodeType.Code || n.type == NodeType.Quote)) {
                const preview = message.linkPreviews.find(node.url);
                if (preview && preview.url) {
                  const result = this.renderLinkPreview(preview);
                  if (result) {
                    results.push(result);
                  }
                } else {
                  ////debugLog("preview not found: ", node.url);
                }
              } else {
                const preview = this.props.me.linkPreviewer.getExistingLinkPreview(node.url, result => {
                  //console.log("got link preview #2", node.url)
                  retry()
                });
                if (preview) {
                  //console.log("got link preview", node.url, preview)
                  if (preview.url) {
                    const result = this.renderLinkPreview(preview);
                    //console.log("rendered link preview", msg.ts, preview.url)
                    if (result) {
                      results.push(result);
                    }
                  } else {
                    console.log("couldn't get link preview", preview, node.url)
                  }
                } else {
                  ////debugLog("no preview found 1: ", node.url);
                  //return;
                }
              }
            }
            if (!results.length) { // just show raw link
              results.push(<a className='uiChatA' target={'_blank'} onClick={e=>this.openFileLink(e, node.url)} href={node.url}>{node.label ? node.label.map(nodeToReact) : node.url}</a>);
            }
            return results;
          }
        case NodeType.Command: 
          break;
          
        case NodeType.Emoji: 
          {
            const emoji = nameToEmoji(node.name);
            if (emoji) {
              //const url = "https://a.slack-edge.com/production-standard-emoji-assets/10.2/apple-medium/"+emoji.unified.toLowerCase()+"@2x.png";
              //let img = <img class='emoji' src={url}/>;
              return emojiToSprite(emoji);
            }
            console.warn("can't find emoji: ", node);
            return nodeToReact({
              text: ":"+node.name+":",
              type: NodeType.Text
            });
            
          }
        case NodeType.PreText: 
          return <pre className='slack_pre'>{content()}</pre>;
          
        case NodeType.Code: 
          return <div className='slack_code'>{content()}</div>;
      }
      ////////debugLog("not parsed: ", node);
      return "";
    }
    return nodeToReact(n);
  }

  renderChatMessageBody= (msg, mergedBody) => {
    if (msg.system && msg.data) {
      if (msg.data.newContact) {
        const newContact = msg.data.newContact;
        const contact = newContact.contact;
        const schedule = () => {
          return this.props.scheduleAppointmentWith(contact).then(() => {
            this.props.finishNewContact(newContact);
          });
        }
        const cancel = () => {
          return this.props.finishNewContact(newContact);
        }
        const chat = () => {
          return this.props.openChat(contact, 'chat').then(() => {
            this.props.finishNewContact(newContact);
          });
        }
        const openContact = () => {
          return this.props.finishNewContact(newContact).then(() => {
            return this.props.me.markContactOpened(contact);
          });
        }
        const text = formatNewContactText(this.props.me, newContact);
        return <div className='uiChatNewContact'>
          <div className='uiChatNewContactAcceptText'>
          {text}
        </div>
          {newContact.role == 'client' ?
           <UIOKCancel cancelIcon={Cross} cancel={openContact} okIcon={Cal} ok={schedule} label="Schedule Appointment"/>
           :
           <UIOKCancel cancelIcon={Cross} cancel={openContact} okIcon={Forward} ok={chat} label="Chat Now"/>}
        </div>
      }
      if (msg.data.recordMeal) {
        const skipMeal = async () => {
          await this.props.skipMeal(msg.data.recordMeal)
        }
        const recordMeal = async () => {
          await this.props.recordMeal(msg.data.recordMeal)
        }
        const date = moment(new Date(msg.ts)).format('dddd MMM Do')
        return <div className='uiChatNewContact'>
          <div className='uiChatNewContactAcceptText'>
          Record your {msg.data.recordMeal} for {date}
        </div>
          <UIOKCancel cancelIcon={Cross} cancel={null} okIcon={Check} ok={recordMeal} label={'Add Meal'}/>
          </div>
      }
      if (msg.data.type === 'oura-relink') {
        const linkOura = () => {
          return this.props.me.linkOura()
        }
        return <div className='uiChatNewContact uiChatOuraRelink'>
          <div className='uiChatNewContactAcceptText'>
          Please relink your Oura Ring
        </div>
          <UIOKCancel okIcon={OuraIcon} ok={linkOura} label={'Relink'}/>
          </div>
        
      }
      if (msg.data.subscription) {
        let deadline;
        //debugger;
        const sub = msg.data.subscription;
        const isOrganizer = () => sub.uid == this.props.localContact.uid;
        let text;
        let ok;
        let okIcon;
        let okLabel;
        let cancel;
        let cancelIcon;
        const remoteContact = this.props.me.getContact(isOrganizer() ? sub.client : sub.uid);
        if (sub.state == 'offer') {
          if (sub.before && sub.before.state == 'offer') {
            if (isOrganizer()) {
              text = "You updated a subscription";
            } else {
              text = remoteContact.displayName+" updated an subscription";
            }
          } else {
            if (isOrganizer()) {
              text = "You offered "+remoteContact.displayName+" a subscription";
              if (this.props.openChat) {
                cancelIcon = Trash;
                cancel = () => this.props.me.cancelSubscription(remoteContact);
                okLabel = "Edit";
                okIcon = Edit;
                ok = ()=> this.props.openSubscription(remoteContact);
              }
            } else {
              text = remoteContact.displayName+" offered you a subscription";
              if (this.props.openChat) {
                cancelIcon = Cross;
                cancel = () => this.props.me.declineSubscription(remoteContact);
                okIcon = Check;
                ok = () => this.props.me.acceptSubscription(remoteContact);
                okLabel = "Accept";
              }
            }
          }
        } else if (sub.state == 'decline' || sub.state == '') {
          if (isOrganizer()) {
            text = remoteContact.displayName+" declined a subscription";
          } else {
            text = "You declined a subscription"; 
          }
        } else if (sub.state == 'active') {
          if (this.props.openChat) {
            if (isOrganizer()) {
              text = remoteContact.displayName+" is awaiting your response";
            } else {
              text = "You are awaiting a response from "+remoteContact.displayName;
            }
            okLabel = "Reply Now";
            ok = ()=>this.props.openChat(remoteContact, 'chat');
            okIcon = Forward;
          } else {
            if (isOrganizer()) {
              text = remoteContact.displayName+" started a subscription";
            } else {
              text = "You started a subscription";
            }
          }
        } else if (sub.state == 'provider-cancel') {
          if (isOrganizer()) {
            text = "You canceled a subscription";
          } else {
            text = remoteContact.displayName + " canceled a subscription";
          }
        } else if (sub.state == 'client-cancel') {
          if (isOrganizer()) {
            text = remoteContact.displayName+" canceled a subscription";
          } else {
            text =" You canceled a subscription";
          }
        }
        const organizer = isOrganizer() ? this.props.localContact: remoteContact;
        const with_ = remoteContact;
        const latestQuestion = sub.latestQuestion || 0;
        const latestResponse = sub.latestResponse || 0;
        let start = 0;
        let end = 0;
        if (sub.state == 'active' && latestQuestion > latestResponse) {
          start = latestQuestion;
          end = this.props.me.getNextResponseTime(sub);
          deadline = end;
        }
        return <div className='uiChatSubscription'>
          <div className='uiChatSubscriptionText'>
          {text}
        </div>
          <UISubscription
        inactive={!msg.data.isActive}
        editable={isOrganizer()}
        invoiceAmount={sub.invoiceAmount}
        isChat={true}
        state={sub.state}
        hideWith={!this.props.openChat}
        id={sub.uid + "-" + sub.client}
        organizer={organizer}
        onClick={()=>this.openSubscription(sub)}
        responseTime={sub.responseTime}
        start={start}
        end={end}
        paymentMethod={sub.paymentMethod}
        description={sub.description}
        invoiceDescription={sub.invoiceDescription}
        openChat={this.props.openChat}
        with={with_}
          />
          {deadline && <div className='uiChatStartSessionWhen'>
           Your reply is expected <span className='uiChatStartSessionWhenFromNow'>{moment(new Date(deadline)).fromNow()}</span></div>}
        
          <UIOKCancel cancel={cancel} cancelIcon={cancelIcon} okIcon={okIcon} ok={ok} label={okLabel}/>
          </div>
      }
      if (msg.data.appointment) {
        return <ChatAppointment
        appt={msg.data.appointment}
        msg={msg}
        markDirty={this.markDirty}
        showSystemProgressIndicator={this.props.showSystemProgressIndicator}
        me={this.props.me}
        localContact={this.props.localContact}
        remoteContact={this.props.remoteContact}
        openChat={this.props.openChat}
        uploadFile={this.props.uploadFile}
        rescheduleAppointment={this.props.rescheduleAppointment}
        waitForSystemUpdate={this.props.waitForSystemUpdate}
        popups={this.popups}
        toggleCallActive={this.props.toggleCallActive}
        onAppointmentEnded={this.props.onAppointmentEnded}
        openAppointment={this.openAppointmentHere}
        hideWith={this.props.hideWith}
        isMe={this.props.isMe}
          />;
      } else if (msg.data.connect) {
        return msg.data.connect();
      }
    }
    if (msg.data && msg.data.scheduledWorkout) {
      if (!msg.data.appointment) {
        addScheduledWorkoutAppointment(this.props.isMe, this.props.me, msg)
      }
      return <ChatAppointment
      msg={msg}
      appointment={msg.data.appointment}
      appt={msg.data.appointment}
      markDirty={this.markDirty}
      showSystemProgressIndicator={this.props.showSystemProgressIndicator}
      me={this.props.me}
      localContact={this.props.localContact}
      remoteContact={this.props.remoteContact}
      openChat={this.props.openChat}
      rescheduleAppointment={this.props.rescheduleAppointment}
      waitForSystemUpdate={this.props.waitForSystemUpdate}
      uploadFile={this.props.uploadFile}
      popups={this.popups}
      toggleCallActive={this.props.toggleCallActive}
      onAppointmentEnded={this.props.onAppointmentEnded}
      openAppointment={this.openAppointmentHere}
      hideWith={this.props.hideWith}
      isMe={this.props.isMe}
        />
    } else if (msg.data && msg.data.type == 'to-do-list-subscription') {
      const subscription = msg.data.subscription
      let text = ''
      switch (subscription.status) {
        case 'payment':
          text = 'You\'ve used your quota of free to-do items. '
          break
        case 'pending':
          break
        case 'active':
          break
        case 'canceled':
          break
      }
      text += 'Purchase a paid plan to continue.'
      return <div className='uiChatSubscription'>
        <div className='uiChatSubscriptionText'>
        {text}
        </div>
        <UIChatTodoListSubscription me={this.props.me} subscription={subscription}/>
        </div>
    } else if (msg.data && msg.data.type === 'bedtime') {
      debugger
      const bedtime = msg.data.bedtime.utcOffset
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      const when = new Date(today.getTime() + bedtime)
      msg.text = "Time to relax and enjoy your evening 😌!\n\nYour usual bedtime is around "+moment(when).format('h:mm a')+" 💤. \n\nIf you're unable to relax, say what's on your mind, and Let's Build will add it to your to-do list for you to do tomorrow (or asap after that).\n   "
    } else if (msg.data) {
      let fakeAppt
      let message
      //console.log("msg.data: ", msg.data)
      const contact = msg.from == this.props.localContact.uid ? this.props.localContact : this.props.remoteContact
      switch (msg.data.type) {
        case 'todo':
          {
            const item = msg.data.todo;
            //message = "Let's Build added an item to your to-do list"
            if (!item.todo.task) {
              break
            }
            switch (item.status) {
              case 'progress':
                message = 'You made progress on your plan'
                break
              case 'pending':
                if (msg.data.before) {
                  message = "You edited a to-do item"
                  break
                }
                if (item.userEdit) {
                  message = item.todo.task
                } else {
                  message = "Plan to "+ item.todo.task[0].toLowerCase() + item.todo.task.substring(1)
                  if (item.todo.emojis) {
                    message += ' '
                    message += item.todo.emojis
                  }
                  if (item.todo.how) {
                    message += '. '
                    message += item.todo.how
                  }
                  if (item.todo.benefits) {
                    if (!message.endsWith('.')) {
                      message += '.'
                    }
                    message += ' '
                    message += item.todo.benefits
                    if (!item.todo.benefits.endsWith('.')) {
                      message += '.'
                    }
                  }
                }
                break
              case 'canceled':
                message = "Canceled"
                break
              case 'done':
                message = "Done!"
                break
            }
            fakeAppt = {
              organizer: contact,
              title: item.todo.category,
              titleHtml: item.todo.category,
              client: contact.uid,
              start: msg.ts,
              end: msg.ts,
              status: item.status,
              id: item.id,
              todo: item
            }
          }
          break
        case 'oura-relink':
          break
        case 'workout':
          if (msg.data.type === 'whoop' && !msg.data.cycle.id) return null
          fakeAppt = cycleToAppointment(this.props.me, contact, msg.data.source, msg.data.type, msg.data.cycle)
          if (msg.data.message) {
            const { header, bodyText } = msg.data.message
            message = header + ' ' + bodyText
          } else {
            message = contact.displayName + ' completed a workout'
          }
          break
        case 'sleep':
          if (!msg.data.cycle.id) {
            msg.data.cycle.id = msg.ts
          }
          fakeAppt = cycleToAppointment(this.props.me, contact, msg.data.source, msg.data.type, msg.data.cycle)
          if (msg.data.message) {
            if (msg.data.message.hoursOfSleep) {
              const { header, bodyText } = msg.data.message.hoursOfSleep
              message = header + ' ' + bodyText
            } else {

            }
          } else {
            message = contact.displayName + ' completed a sleep'
          }
        case 'recovery':
          break
        case 'weight':
          {
            const { created, id, weight, from } = msg.data
            const cycle = { created, id, weight, from }
            const weightTitle = 'Weight'
            message = contact.displayName + ' has a new weight'
            const weightContact = {
              displayName: "Weight",
              profileImage: ScaleIcon,
              uid: contact.uid
            }
            fakeAppt = {
              contact: weightContact,
              organizer: contact,
              title: weightTitle,
              titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>{weightTitle}</div>&nbsp;<div className='workoutEventStrain'>{cycle.weight} lbs</div></div>,
              client: contact.uid,
              start: cycle.created,
              end: cycle.created,
              weight: cycle,
              status: 'completed',
              id: cycle.id
            }
          }
          break
        case 'meal':
          {
            const meal = msg.data.meal
            const mealTitle = capitalize(meal.type)
            let calories = 0
            let mainFood
            message = contact.displayName +
              (meal.before ? ' updated a meal' : ' completed a meal')
            meal.foods.forEach(food => {
              if (!food.nutrition && food.full_nutrients) {
                food.nutrition = JSON.parse(JSON.stringify(food))
              }
              if (!mainFood) {
                mainFood = food
              }
              let amount = food.nutrition ? food.nutrition.nf_calories : food.nf_calories
              if (amount) {
                calories += Math.round(amount * (food.count || 1)) 
              } else {
                debugLog("no calories", food)
              }
            })
            let foodContact
            if (mainFood) {
              let displayName = getFoodName(mainFood, true)
              const comma = displayName.indexOf(',')
              if (comma > 0) {
                displayName = displayName.substring(0, comma)
              }
              if (meal.foods.length > 1) {
                displayName += ' (+' + (meal.foods.length - 1) + ')'
              }
              foodContact = {
                profileIcon: <FoodLogo food={mainFood}/>,
                profileImage: logoImage(mainFood),
                displayName: displayName,
                uid: contact.uid,
              }
            }
            fakeAppt = {
              contact: foodContact || contact,
              organizer: contact,
              title: mealTitle,
              titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>{mealTitle}</div>&nbsp;<div className='workoutEventStrain'>{calories} Cal</div></div>,
              client: contact.uid,
              start: meal.start,
              end: meal.end,
              meal: meal,
              status: 'completed',
              id: meal.id
            }
          }
      }
      if (!fakeAppt) return null
      const appt = fakeAppt
      const event = {
        id: appt.id,
        appt: appt
      }
      const waitForSystemUpdate = () => this.props.waitForSystemUpdate(appt)
      return <div className='uiChatAppointmentContainer'>
        <div className='uiChatAppointmentMessage'>{message}</div>
        <UIAppointment inactive={!msg.data.isActive} me={this.props.me} appt={appt} openChat={this.props.openContact} organizer={appt.organizer} client={appt.client} status={appt.status} appointment={appt} id={appt.id} editable={false} start={new Date(appt.start)} title={appt.titleHtml || appt.title || "Video Conference"} end={new Date(appt.end)} openAppointment={this.props.openAppointment} with={appt.contact} onClick={()=>this.props.openEvent(event)} waitForSystemUpdate={waitForSystemUpdate}/>
        </div>
    }
    let text
    if (mergedBody) {
      let sep = ''
      text = ''
      for (const m of mergedBody) {
        text += sep
        text += m.text
        sep = '\n'
      }
    } else {
        text = msg.text;
    }
    let editing = false;
    if (this.state.editingMessage && this.state.editingMessage.ts == msg.ts) {
      text = this.editor.getText();
      editing = true;
    }
    if (false && msg.linkPreviews) msg.linkPreviews.map(p => {
      const url = p.url;
      if (!url.endsWith(".gif")) {
        text = text.split("<"+url+">").join("").split(url).join("");
      }
    });
    if (msg.files && msg.files.length > 0) {
      const file = msg.files[0];
      //////debugLog("file: ", file);
      let text = msg.text;
      let name = file.name;
      if (!name) {
        if (file.path) {
          const comps = file.path.split('-')
          name = comps.slice(2, comps.length-1).join("-");
          file.name = name;
        }
      }
      if (name) {
        text += ": "+name;
      }
      let icon = file.contentType.startsWith("image/") || file.contentType.startsWith("video/") ? ImageIcon : FileIcon;
      if (file.contentType.startsWith("image/svg")) {
        icon = FileIcon;
      }
      let cantPlayVideo = false;
      if (isSafari() && file.contentType.startsWith("video/webm")) {
        cantPlayVideo = true;
      }
      const uploading = file.state == 'uploading' || file.state == 'upload-failed';
      if (uploading) {
        let uploading;
        let className = "uiChatFileUploadTypeImage";
        let preview
        const currentFileUpload = this.state.uploads.find(upload => upload.file.name == file.name)
        if (file.contentType.startsWith("image/")) {
          uploading = "Uploading an image";
          preview = <img src={currentFileUpload ? currentFileUpload.blobUrl : null}/>
        } else if (file.contentType.startsWith("video/")) {
          uploading = "Uploading a movie";
          preview = <video playsInline muted controls autoplay={true} src={currentFileUpload ? currentFileUpload.blobUrl  : null}/>
        } else {
          className = "uiChatFileUploadTypeFile";
          uploading = "Uploading a file";
        }
        const filename = name;
        let prog = currentFileUpload ? currentFileUpload.progress : 0
        if (prog == 100) {
          prog = ''
          uploading = 'Transcoding Media'
        } else {
          prog += '%'
        }
        let svg = SpinnerShape
        if (file.state == 'upload-failed') {
          className += ' uiChatUploadFailed'
          uploading = "Oof, sorry that didn't work"
          prog = ''
          svg = Err
        }
        return <div className='uiChatFileUploadWithPreview'>
          {preview}
          <div className={'uiChatFileUpload ' + className}>
          <div className='uiChatFileUploadSpinnerAndText'>
          <div className='uiChatFileUploadSpinner'><ReactSVG src={svg}/></div>
          <div className='uiChatFileUploadText'>
          <span className='uiChatFileUploadTextUploading'>{uploading}&nbsp;</span>
          {false && <span className='uiChatFileUploadTextFilename'>{filename}&nbsp;</span>}
          <span className='uiChatFileUploadTextPercent'>{prog}</span>
          </div>
          </div>
          <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
          </div>
        </div>
      } else {
        if (icon == FileIcon || cantPlayVideo) {
          const downloading = file.state == 'downloading';
          const download = () => {
            if (file.state == 'downloading') return;
            file.state = "downloading";
            this.markDirty(msg);
            this.forceUpdate();
            return this.props.downloadFile(msg).then(() => {
              delete file.state;
              this.markDirty(msg);
              this.forceUpdate();
            });
          }
          return <div className='uiChatMessageBody'>
            <div className='uiChatFileUpload uiChatFileDownload' onClick={download}>
            <div className='uiChatFileUploadSpinnerAndText'>
            {downloading ? <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> :
             <div className='uiChatFileUploadIcon'><ReactSVG src={Save}/></div>}
            <div className='uiChatFileUploadText'>
            <span>Download&nbsp;</span>{!isMobile() && <span className='uiChatFileUploadTextFilename'>{file.name}</span>}
          </div>
            </div>
            <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
            </div>                    
            </div>
        } else if (!file.src) {
          const resolveFile  = () => {
            if (file.resolving) return;
            file.resolving = true;
            file.resolve().then(() => {
              this.markDirty(msg);
              if (file.src) {
                setTimeout(()=>this.forceUpdate(() => {
                  if (this.props.mostRecent == msg.ts) {
                    ////debugLog("file resolution complete: scroll to bottom");
                    setTimeout(this.scrollToBottom, 20);
                  }
                }));
              }                            
            });
            this.markDirty(msg);
            this.forceUpdate();
          }
          if (file.resolve) {
            if (!isMobile() && file.contentType.startsWith("video/")) {
              return <div className='uiChatMessageBody'>
                <div className='uiChatFileUpload uiChatFileDownload' onClick={resolveFile}>
                <div className='uiChatFileUploadSpinnerAndText'>
                {file.resolving ? <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> :
                 <div className='uiChatFileUploadIcon'><ReactSVG src={Forward}/></div>}
                <div className='uiChatFileUploadText'>
                <span>Watch&nbsp;</span><span className='uiChatFileUploadTextFilename'>{file.name}</span>
                </div>
                </div>
                <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
                </div>                    
                </div>
            } else {
              resolveFile();
              return <div className='uiChatMessageBody'>
                <div className='uiChatFileUpload uiChatFileDownload'>
                <div className='uiChatFileUploadSpinnerAndText'>
                <div className='uiChatFileUploadSpinner'><ReactSVG src={SpinnerShape}/></div> 
                <div className='uiChatFileUploadText'>
                <span>Loading&nbsp;</span>{!isMobile() && <span className='uiChatFileUploadTextFilename'>{file.name}</span>}
              </div>
                </div>
                <div className='uiChatFileUploadIcon'><ReactSVG src={icon}/></div>
                </div>                    
                </div>
            }
          }
        }
      }
    }
    let markdown = '';
    if (text) {
      const links = find(text);
      links.map(link => {
        const markdownLink = "<"+link.href+">";
        if (text != markdownLink) {
          text = text.split(markdownLink).join(link.href).split(link.href).join(markdownLink);
        }
      });
      try {
        markdown = this.slackToHtml(editing, msg, text);
      } catch (err) {
        console.error(err)
        markdown = text
      }
    }
    const files = msg.files ? msg.files.filter(file => file.src || file.downloadURL) : null;
    return <div className={'uiChatMessageBody' + (editing ?' uiChatMessageBodyEdited' : '')}>
      {files && files.length > 0 ? files.map(file => <InlineMedia me={this.props.me} onLoaded={()=>this.markDirty(msg)} maximize={()=>this.openFile(file)} downloadFile={this.props.downloadFile} msg={msg} file={file}/>) :
       <div className='uiChatMessageText'>{markdown}</div>}
    {false && msg.linkPreviews && msg.linkPreviews.map(preview => this.renderLinkPreview(preview))}

    </div>;
  }


  renderChatMessageFrom = (contact) => {
    debugLog("render chat message from: ", contact.displayName)
    return <div className={'uiChatFromName'}>{contact.displayName} <span className='uiChatFromCreds'>{contact.creds}</span></div>;
  }

  tappedReaction = (msg, reaction) => {
    const emoji = nameToEmoji(reaction.name);
    this.props.reactToChatMessage(msg, emoji);
  }

  renderReaction = (msg, reaction) => {
    const emoji = nameToEmoji(reaction.name);
    if (emoji) {
      const img = emojiToSprite(emoji);
      let tip = "";
      let sep = "";
      var i = 0;
      reaction.users.map(u => {
        const c = this.props.localContact.uid == u ? this.props.localContact : this.props.remoteContact;
        tip += sep;
        tip += c.displayName;
        sep = i+2 == reaction.users.length ? " and " : ", ";
        i++;
      });
      const onClick = () => this.tappedReaction(msg, reaction);
      tip += " reacted with " +emoji.name;
      return <Tooltip key={emoji.name} enterDelay={750} title={tip}>
        <div onClick={onClick} className={'uiChatReaction'}>{img}<div className='uiChatReactionCount'>{reaction.users.length}</div></div>
        </Tooltip>;
    }
  }

  addReaction = (msg, emoji) => {
    this.props.reactToChatMessage(msg, emoji);
    const active = document.activeElement
    if (active) active.blur()
    this.dismissEmojiPicker();
  }

  reactToMessage = msg => {
    this.editor.blur()
    this.setState({
      addGIF: null,
      addReaction: emoji => this.addReaction(msg, emoji),
      emojiPickerSmall: false
    });
    if (this.props.setPopupShowing) this.props.setPopupShowing(true);
  }

  dismissEmojiPicker = (e) => {
    if (e) e.preventDefault()
    this.setState({
      addReaction: null,
    });
    if (this.props.setPopupShowing)     this.props.setPopupShowing(false);
  }

  deleteMessage = msg => {
    this.props.deleteChatMessage(msg);
  }

  cancelEdit = () => {
    this.editor.clear();
    this.setState({
      editingMessage: null,
      editingImages: {},
      cantSendMessageError: null
    }, () => { 
      this.scrollWindow.scrollTop = this.editPos
    });
  }

  editMessage = msg => {
    if (this.state.editingMessage) {
      this.markDirty(this.state.editingMessage)
    }
    this.pushScrollBottom()
    this.ignoreClickAway = true;
    this.markDirty(msg);
    this.editPos = this.scrollWindow.scrollTop
    let text = msg.text;
    const n = parse(unescape(text));
    const nodeStack = [];
    const render = (node, index)=> {
      const content = () => {
        const doit = () => {
          if (!node.children) {
            if (node.text) {
              return render(parse(node.text));
            }
          }
          return node.children.map(render);
        }
        nodeStack.push(node);
        try {
          return doit();
        } finally {
          nodeStack.shift();
        }
      }
      switch (node.type) {
        case NodeType.Root:
          return content();
        case NodeType.Text:
          {
            return node.text;
          }
        case NodeType.Bold:
          {
            return "*"+content()+"*";
          }
        case NodeType.Italic: 
          {
            return "_"+content()+"_";
          }
        case NodeType.Strike: 
          {
            return "~"+content()+"~";
          }
        case NodeType.Quote: 
          return ">"+content();
        case NodeType.ChannelLink: 
        case NodeType.UserLink:
          return content();
        case NodeType.URL:
          {
            node.url = node.url.replace(/&amp;/g, "&");
            //debugger;
            if (node.url.endsWith('.gif')) {
              return "<img src='"+node.url+"'/>";
            }
            return node.url;
          }
        case NodeType.Command: 
          return content();
        case NodeType.Emoji: 
          {
            const emoji = nameToEmoji(node.name);
            //debugger;
            if (emoji) {
              return emojiToHtmlImg(emoji);
            }
            return content();
          }
        case NodeType.PreText: 
          return <pre className='slack_pre'>{content()}</pre>;
          
        case NodeType.Code: 
          return "`"+content()+"`";
      }
      ////////debugLog("not parsed: ", node);
      return "";
    }
    const html = render(n).join("");
    this.editor.clear();
    this.editor.insert(html);
    this.state.editingMessage = msg
    this.forceUpdate(() => {
      this.popScrollBottom()
    })
  }

  saveMessage = () => {
    const msg = this.state.editingMessage
    const text = this.editor.getText()
    msg.text = convertUnicodeEmojisToMarkdown(text)
    this.markDirty(msg)
    this.editor.clear()
    this.state.editingMessage = null
    this.state.editingImages = {}
    this.props.saveMessage(msg)
    this.forceUpdate()
  }

  hideMenu = () => {
    if (this.state.showMenu) {
      this.setState({
        showMenu: false
      });
    }
  }
  
  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  }

  manageGroup = () => {
    this.setState({
      showMenu: false,
      showManageGroup: true,
    });
  }

  dismissManageGroup = () => {
    this.setState({
      showManageGroup: false,
    });
  }

  createGroup = () => {
    this.setState({
      showMenu: false,
      showManageGroup: true,
    });
  }

  recordMessage = () => {
    this.setState({
      showMenu: false,
    });
    return this.props.recordMessage();
  }

  removeContact = () => {
    return this.props.removeContact();
  }

  cache = {};

  markAllDirty = () => {
    this.cache = {};
  }

  markDirty = msg => {
    delete this.cache[msg.ts]
    debugLog("mark dirty", msg.ts)
    debugLog("markDirty:", msg, "cache", this.cache)
  }

  renderChatMessage = (msg, prev, next, merged)  => {
    const ts = msg.ts;
    let cached = this.cache[ts];
    if (!merged[ts]) {
      if (cached) return cached;
    }
    cached = this.renderChatMessageImpl(msg, prev, next, merged);
    let canCache = (!msg.data || (msg.data.type != 'connect' && !msg.data.todo)) && msg != this.state.editingMessage && !merged[msg.ts];
    if (msg.files) {
      msg.files.map(file => {
        if (file.contentType.startsWith("image/") && !file.src || file.resolve || file.state) {
          canCache = false;
        }
      });
    }
    if (canCache) {
      debugLog("cache", ts, msg)
      this.cache[ts] = cached;
    }
    return cached;
  }

  isTextMessage = (msg, withReactions) => {
    return !msg.data && !(withReactions && msg.reactions && msg.reactions.length) && !(msg.files && msg.files.length)
  }
  
  renderChatMessageImpl = (msg, prev, next, merged)  => {
    const formatMessageTime = ()=> {
      const time = Number(msg.ts);
      let sameDay = "h:mm a";
      let sameElse =  "MM/DD/YYYY \\a\\t ";
      const sameMinute = (t1, t2) => {
        const d1 = new Date(t1);
        const d2 = new Date(t2);
        return (d1.getYear() === d1.getYear() && d1.getMonth() == d2.getMonth() && d1.getDate() === d2.getDate() && d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes());
      };
      if ((prev && sameMinute(time, prev)) || (next && sameMinute(time, next))) {
        sameDay = "h:mm:ss a";
      }
      const timestamp =  moment(time).calendar(null, {
        sameDay: sameDay,
        //lastDay: "[Yesterday] "+sameDay,
        //lastWeek: "[Last] dddd "+sameDay,
        sameElse: sameElse+ sameDay,
      })
      return timestamp;
    }
    const tete = {
      uid: "system",
      displayName: this.props.me.isTodoList() ? "To-do" : 'Let\'s Build',
      profileImage: this.props.me.isTodoList() ? TodoListProfileImage : LetsBuildLogo
    }
    const whoop = {
      uid: 'whoop',
      displayName: 'Whoop',
      profileImage: WhoopLogo
    }
    const oura = {
      uid: 'oura',
      displayName: 'Oura',
      profileImage: OuraLogo
    }
    const garmin = {
      uid: 'garmin',
      displayName: 'Garmin',
      profileImage: GarminLogo
    }
    const getContact = msg => {
      if (msg.data) {
        if (msg.data.source == 'whoop') {
          return whoop
        }
        if (msg.data.source == 'oura') {
          return oura
        }
        if (msg.data.source == 'garmin') {
          return garmin
        }
      }
      let from = msg.from
      if (msg.data && msg.data.workout && msg.data.workout.trainer) {
        from = msg.data.workout.trainer
      } else {
        if (msg.system || msg.data) {
          return tete
        }
      }
      const result = this.props.me.getContact(from)
      if (!result) {
        debugger
      }
      return result
    }
    const from = getContact(msg);
    const fromMe = from.uid == this.props.me.self.uid;
    const canDelete = (fromMe && !msg.data)
    const ringColor = from.profileImage ? '#FFFFFF' : '#dcdcdc';

    const renderHeader = (contact, noTime) => {
      return <div key='header' className={'uiChatMessageHeader' + (noTime ? ' uiChatMessageHeaderNoTime' : '')}>
        {!msg.system && (!prev || from.uid != getContact(prev).uid) ? this.renderChatMessageFrom(from) : <div key='empty'/>}
      {!noTime ? renderTime() : null}
      </div>
    }

    const renderLeft = () => {
      let className =  'uiChatProfileIconContainer'
      if (from.uid == 'whoop') {
        className += ' uiChatWhoopProfileIcon'
      } else if (from.uid == 'oura') {
        className += ' uiChatOuraProfileIcon'
      } else if (from.uid == 'garmin') {
        className += ' uiChatGarminProfileIcon'
      } else if (from.uid == 'system') {
        className += ' uiChatSystemProfileIcon'
      }
      const isNewUser = (!prev || getContact(prev).uid != from.uid)
      //console.log('renderLeft', msg.text, from, prev, 'merged', merged[msg.ts])
      return <div key='left' className='uiChatMessageLeft'>
        {isNewUser &&
         <div className={className}>
         <UIProfileIcon contact={from}/>
         </div>
        }
      </div>
    }

    const renderTime = () => {
      return <div className='uiChatMessageTime'>{formatMessageTime()}</div>
    }
    const noTime = !msg.system && next && (prev && msg.ts - prev.ts < 15 * 1000 * 60);
    const isSameFrom = prev && prev.from == from.uid;
    const body = this.renderChatMessageBody(msg, merged[msg.ts])
    if (!body) return null
    return <div key={msg.ts} ref={x => {
      if (x && msg == this.state.editingMessage) {

      }
    }} className={'uiChatMessage'  + (!msg.system && isSameFrom ? " uiChatMessageSameFrom": "")}>
      {renderLeft()}
      <div key='center' className='uiChatMessageCenter'>
      {renderHeader(from, noTime)}
      <div key='content' className='uiChatMessageContent'>
      {body}
    </div>
      {<div key='bottom' className={'uiChatMessageBottomRow' + (noTime && (!msg.reactions || msg.reactions.length == 0) ? " uiChatMessageBottomRowNoReactions" : "")}>
       <div key='reactions' className='uiChatMessageReactions'>{msg.reactions ? msg.reactions.map(x => this.renderReaction(msg, x)): null}</div>
       <div key='buttons' className='uiChatMessageButtons'>
       {!this.props.isMe && <UIButton tooltip={"React"}  key='react' className='uiChatEmojiButton' icon={ChatReact} editing={this.state.editingMessage} action={()=>this.reactToMessage(msg)}/>}
       {fromMe && !msg.data && <UIButton tooltip={"Edit"} key='edit' className='uiChatEmojiButton' editing={this.keyboardIsShowing} disabled={!fromMe} icon={ChatEdit} action={()=>this.editMessage(msg)}/>}
       {canDelete && <UIButton tooltip={"Delete"} key='del' className='uiChatEmojiButton' disabled={!canDelete} icon={ChatDelete} action={()=>this.deleteMessage(msg)}/>}
       </div>
       </div>}
    </div>
      <div key='right' className='uiChatMessageRight'/>
      </div>;
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect()
    this.lastHeight = 0;
    clearInterval(this.scrollHeightPoller);
    clearTimeout(this.typingTimeout);
    if (this.typingSub) this.typingSub.unsubscribe();
    if (this.provSub) this.provSub.unsubscribe();
    if (this.clientSub) this.clientSub.unsubscribe();
    if (this.accountSub) this.accountSub.unsubscribe()
    window.removeEventListener("resize", this.onResized);
    this.destroyFocusListener()
    
  }

  scrollDelta = 0

  // scrollBottom = 0, scrollTop = scrollHeight - clientHeight
  // 
  computeScrollTop = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - this.scrollBottom - ref.clientHeight
    debugLog(`computeScrollTop ${ref.scrollHeight} - ${this.scrollBottom} - ${ref.clientHeight} = ${result}`)
    return result
  }

  computeScrollBottom = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - ref.clientHeight - ref.scrollTop
    debugLog(`computeScrollBottom ${ref.scrollHeight} - ${ref.clientHeight} - ${ref.scrollTop} = ${result}`)
    return result
  }

  componentDidMount() {
    const ref = this.scrollWindow;
    this.resizeObserver = new ResizeObserver(entries => {
      if (this.inScroll) return
      if (ref.scrollHeight == 0) return
      this.inResize = true
      if (ref.scrollTop !== this.lastScrollTop) {
        debugLog("onresize: scrollTop", this.scrollWindow.scrollTop, "last", this.lastScrollTop)
      }
      if (ref.scrollHeight !== this.lastHeight) {
        debugLog("onresize: scrollHeight", this.scrollWindow.scrollHeight, "last", this.lastHeight)
        this.lastHeight = ref.scrollHeight
      }
      if (ref.clientHeight !== this.lastOffsetHeight) {
        debugLog("onresize:scroll clientHeight", ref.clientHeight, "last", this.lastOffsetHeight)
        this.lastOffsetHeight = ref.clientHeight;
      }
      const newScrollTop = this.computeScrollTop()
      debugLog("new scroll top: ", newScrollTop)
      const newScrollBottom = ref.scrollHeight - ref.clientHeight - newScrollTop
      debugLog("current scroll bottom: ", this.scrollBottom, "=>", newScrollBottom)
      ref.scrollTop = newScrollTop
      console.log("resize scrollTop==>", newScrollTop)
      this.inResize = false
    });
    this.scrollBottom = 0;
    this.lastHeight = ref.scrollHeight;
    this.lastOffsetHeight = ref.clientHeight;
    ref.scrollTop = this.computeScrollTop()
    this.lastScrollTop = ref.scrollTop
    debugLog("chat mounted scroll last: ", this.lastHeight)
    const onscroll = e => {
      let sizeDelta = 0
      if (this.inResize) {
        return
      }
      if (this.lastOffsetHeight !== ref.clientHeight) {
        debugLog("scroll client height change: ", ref.clientHeight, "last", this.lastOffsetHeight)
        sizeDelta += ref.clientHeight - this.lastOffsetHeight
        this.lastOffsetHeight = ref.clientHeight
      }
      if (this.lastHeight !== ref.scrollHeight) {
        debugLog("scroll height change: ", ref.scrollHeight, "last", this.lastHeight)
        sizeDelta += ref.scrollHeight - this.lastHeight
        this.lastHeight = ref.scrollHeight
      }
      if (sizeDelta === 0) {
        this.scrollBottom = this.computeScrollBottom()
        this.lastScrollTop = ref.scrollTop
      } else {
        const newValue = this.computeScrollTop()
        debugLog("onscroll scrollTop==>", newValue)
        return ref.scrollTop = newValue
      }
      debugLog("scrolltop=>", ref.scrollTop)
      debugLog("scrollHeight=>", ref.scrollHeight)
      debugLog("scrollClient=>", ref.clientHeight)
      debugLog("scrollBottom=>", this.scrollBottom)
      this.checkScrollBack();
    }
    ref.onscroll = e => {
      this.inScroll = true
      onscroll(e)
      this.inScroll = false
    }
    this.resizeObserver.observe(this.scrollWindow);
    this.resizeObserver.observe(this.scrollContent);

    if (this.props.observeTyping) {
      this.typingSub = this.props.observeTyping().subscribe(ts => {
        //////debugLog("observe typing: ", ts);
        clearTimeout(this.typingTimeout);
        this.setState({
          typing: true
        });
        this.typingTimeout = setTimeout(() => {
          this.setState({
            typing: false
          });
        }, 5000);
      });
    }
    if (this.props.remoteContact) {
      this.provSub = this.props.me.observeSubscription(this.props.remoteContact).subscribe(change => {
        ////debugLog("provSub: ", change);
        if (change.type == 'removed' || change.subscription.state == 'client-cancel' || change.subscription.state == 'provider-cancel') {
          this.setState({
            providerSubscription: null,
            openSubscription: null,
          });
        } else {
          this.setState({
            providerSubscription: change.subscription,
            openSubscription: null
          });
        }
      }, err => {
        //debugger;
      });
      this.clientSub = this.props.me.observeMySubscription(this.props.remoteContact).subscribe(change => {
        ////debugLog("clientSub: ", change);
        if (change.type == 'removed' || change.subscription.state == '' || change.subscription.state == 'decline' || change.subscription.state == 'client-cancel' || change.subscription.state == 'provider-cancel') {
          this.setState({
            clientSubscription: null,
            openSubscription: null,
          });
        } else {
          this.setState({
            clientSubscription: change.subscription,
            openSubscription: null,
          });
        }
      }, err => {
        //debugger;
      });
    }
    this.accountSub = this.props.me.observeAccount().subscribe(account => {
      this.markAllDirty()
    })
    this.windowListener = window.addEventListener("resize", this.onResized);
    this.initFocusListener()    
    if (this.props.onChatCreated) this.props.onChatCreated(this);
    this.scrollToBottom()
  }

  destroyFocusListener() {
    if (!isDesktop()) {
      window.removeEventListener('focus', this.detectFocus, true)
      window.removeEventListener('blur', this.detectFocus, true)
    }
  }

  initFocusListener() {
    if (!isDesktop()) {
      window.addEventListener('focus', this.detectFocus, true)
      window.addEventListener('blur', this.detectFocus, true)
    }
  }

  detectFocus = () => {
    const focused = !document.activeElement || document.activeElement !== document.body
    debugLog("FOCUS", focused)
    if (this.state.keyboardOpen != focused) {
      this.state.keyboardOpen = focused
      if (!focused) {
        if (this.state.editingMessage && !isDesktop()) {
          return this.cancelEdit()
        }
      }
      this.forceUpdate()
    }
  }

  onResized = () => {
    this.forceUpdate();
  }

  takeFocus = () => {
    if (!hasSoftKeyboard()) this.editor.focus()
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if (prevProps.updates != this.props.updates) {
      this.fixupScrollTop();
    }
    if (this.props.active && !prevProps.active) {
      this.takeFocus();
    }
    const setEditorFocus = () => {
      if (!this.state.addGIF && !this.state.addReaction && !this.state.addAppointment) {
        this.takeFocus();
      }
    }
    if (!this.state.addAppointment) {
      this.cal = null;
      if (prevState.addAppointment) {
        setEditorFocus();
      }
    }
    if ((!this.state.addGIF && prevState.addGIF) || (!this.state.addReaction && prevState.addReaction)) {
      setEditorFocus();
    }
    if (this.cal && prevState.openEvent && !this.state.openEvent) {
      this.cal.takeFocus();
    }
    if (this.props.messages.length > 0) {
      this.checkScrollBack();
    }
    if (true) {
      if (prevProps.visible != this.props.visible) {
        //debugLog("chat update ", this.props.remoteContact.displayName, " => ", this.props.visible);
        if (!prevProps.visible) {
          this.popScrollBottom();
        } else {
          this.pushScrollBottom();
        }
      }
    }
    const current = {}
    for (var msg of this.props.messages) {
      current[msg.ts] = msg;
    }
    for (var i in this.cache) {
      if (!current[i]) {
        delete this.cache[i];
      }
    }
    if (prevProps.alpha !== this.props.alpha) {
      debugLog('alpha', this.props.alpha)
    }
  }

  popScrollBottom() {
    debugLog("popScrollBottom: ", this.scrollBottom, " => ", this.savedScrollBottom);
    this.scrollBottom = this.savedScrollBottom || 0;
    this.fixupScrollTop();
  }

  pushScrollBottom() {
    this.savedScrollBottom = this.scrollBottom;
    //debugLog("pushScrollBottom: ", this.savedScrollBottom);
  }

  fixupScrollTopLater = () => {
    clearTimeout(this.fixupTimeout);
    setTimeout(this.fixupScrollTop);
  }

  setScrollWindow = ref => {
    if (ref && ref != this.scrollWindow) {
      this.scrollWindow = ref;
    }
  }

  checkScrollBack = () => {
    if (this.props.scrollBack) {
      const ref = this.scrollWindow;
      if (ref.scrollTop == 0 || ref.scrollHeight <= ref.offsetHeight) {
        if (this.props.messages.length > 0) {
          const earliest = this.props.messages[0].ts;
          this.props.scrollBack(earliest);
        }
      }
    }
  }

  setScrollContent = ref => {
    if (ref && ref != this.scrollContent) {
      this.scrollContent = ref;
    }
  }

  fixupScrollTop = () => {
    this.scrollWindow.scrollTop = this.computeScrollTop()
  }

  scrollToBottom=()=> {
    this.scrollBottom = 0
    this.fixupScrollTop()
  }

  onFileInput = e => {
    this.handleDataTransfer(e, e.target);
    e.target.value = "";
  }

  dismissGIFPicker = () => {
    this.setState({
      addGIF: null,
    });
    if (this.props.setPopupShowing)     this.props.setPopupShowing(false);
  }

  renderGIFPicker() {
    const theme = {
      select: 'uiChatGIFSelect',
      selectInput: 'uiChatGIFSelectInput',
      attribution: 'uiChatGIFAttribution'
    }
    return <div className='uiChatEmojiPicker GIFPicker'>
      <ClickAwayListener onClickAway={this.toggleGIFPicker}>
      <div className='uiEmojiPickerContainer'>
      <div className='uiChatGiphyContainer'>
      <GiphySelect autoFocus={isDesktop()} theme={theme} onEntrySelect={this.doInsertGIF} requestKey={'NliWm3ch154djr59X5ONE3UazkbDgepu'}/>
      <img className='uiChatGiphyAttributionImage' src={GiphyAttribution}/>
      </div>
      <div className='uiEmojiPickerArrow'>
      <div className='uiEmojiPickerArrowShape'/>
      </div>
      </div>
      </ClickAwayListener>
      </div>
  }

  onSelectEmoji = emoji => {
    ////debugLog("emoji: ", emoji);
    this.state.addReaction(normalizeEmoji(emoji));
    this.dismissEmojiPicker();
  }

  renderEmojiPicker() {
    let className = 'uiChatEmojiPicker'
    if (this.state.emojiPickerSmall) {
      className += ' uiChatEmojiPickerKeyboardShowing'
    }
    const onClick = (emoji, e) => {
      debugLog("on click")
      if (this.isKeyboardShowing()) {
        e.preventDefault()
      } 
    }
    const onMouseDown = (emoji, e) => {
      debugLog("on mouse down")
      if (this.isKeyboardShowing()) {
        e.preventDefault()
        e.stopPropagation()
        this.onSelectEmoji(emoji)
      }
    }
    const onMouseUp = (emoji, e) => {
      debugLog("on mouse up")
      if (this.isKeyboardShowing()) {
        e.preventDefault()
        this.onSelectEmoji(emoji)
      }
    }
    const onSelect = (emoji) => {
      debugLog("on select")
      if (this.isKeyboardShowing()) {
        alert("on select")
        return
      }
      this.onSelectEmoji(emoji)
    }
    return <div className={className}>
      <ClickAwayListener onClickAway={() => {
        if (this.isKeyboardShowing()) {
          return
        }
        debugLog('click away')
        this.dismissEmojiPicker()
      }}>
      <div className='uiEmojiPickerContainer'>
      <Picker autoFocus={!hasSoftKeyboard()} native={isApple()}
    set={emojiSet} title="Choose an emoji" emoji="+1" onSelect={onSelect} onClick={onClick} onMouseDown={onMouseDown} onMouseUp={onMouseUp} emojisToShowFilter={this.emojisToShowFilter}/>
      <div className='uiEmojiPickerArrow'>
      <div className='uiEmojiPickerArrowShape'/>
      </div>
      </div>
      </ClickAwayListener>
      </div>
  }

  doInsertEmoji = emoji => {
    const html = emojiToHtmlImg(emoji);
    this.editor.insert(html);
    this.dismissEmojiPicker();
  }

  insertEmoji = () => {
    if (this.props.setPopupShowing) this.props.setPopupShowing(true);
    this.setState({
      addGIF: null,
      addReaction: this.doInsertEmoji,
      emojiPickerSmall: this.isKeyboardShowing()
    });
  }

  setCal=x=> {
    if (x && x != this.cal) {
      this.cal = x;
    }
  }

  onCalendarClick = date => {
    if (date.getTime() === this.state.calendarDate) {
      this.createAppointment(date);
    } else {
      this.setState({
        calendarDate: date.getTime()
      })
    }
  }

  onChangeSubscription = (field, value) => {
    if (field == 'startDate') {
      value = value.getTime();
    }
    this.state.openSubscription[field] = value;
    //debugLog(field, " => ", value);
    this.forceUpdate();
  }

  openSubscription = sub => {
    //this.subscribeToChat();
    //this.props.openSubscription(sub)
    this.props.subscribeToChat(sub)
  }

  acceptSubscription = () => {
    return this.props.me.acceptSubscription(this.props.remoteContact).then(result => {
      if (!result.error) {
        this.dismissChatSubscription();
      }
      return result;
    });
  }

  declineSubscription = () => {
    return this.props.me.declineSubscription(this.props.remoteContact).then(result => {
      if (!result.error) {
        this.dismissChatSubscription();
      }
      return result;
    });
  }

  updateSubscription = () => {
    const form = this.state.openSubscription;
    const fields = ["description", "invoiceAmount", "invoiceDescription",
                    "startDate", "responseTime"];
    const updates = {};
    fields.map(field => {
      let value = form[field];
      if (!this.state.providerSubscription || this.state.providerSubscription[field] != value) {
        updates[field] = value;
      }
    });
    //debugLog("form: ", form);
    //debugLog("updates: ", updates);
    let p;
    if (this.state.providerSubscription) {
      if (this.state.openSubscription.state != 'offer') {
        updates.state = 'offer'
      }
      p = this.props.me.updateSubscription(this.props.remoteContact, updates);
    } else {
      p = this.props.me.offerSubscription(this.props.remoteContact, updates);
    }
    return p.then(result => {
      if (!result.error) {
        this.dismissChatSubscription();
      }
      return result;
    });
  }

  cancelSubscription = () => {
    let p;
    if (this.state.clientSubscription) {
      p = this.props.me.cancelClientSubscription(this.props.remoteContact);
    } else {
      p = this.props.me.cancelSubscription(this.props.remoteContact);
    }
    return p.then(this.dismissChatSubscription);
  }

  renderSubscribeToChat = () => {
    return <UISubscribeToChat
    title={"Professional Chat"}
    editable={!this.state.clientSubscription &&
              (!this.state.providerSubscription || this.state.providerSubscription.state != "active")}
    isNew={!this.state.providerSubscription && !this.state.clientSubscription}
    small={!this.state.clientSubscription}
    me={this.props.me}
    state={this.state.openSubscription.state}
    with={this.props.remoteContact}
    withReadOnly={true}
    description={this.state.openSubscription.description}
    responseTime={this.state.openSubscription.responseTime}
    startDate={new Date(this.state.openSubscription.startDate)}
    on={new Date(this.state.openSubscription.startDate)}
    invoiceDescription={this.state.openSubscription.invoiceDescription}
    invoiceAmount={this.state.openSubscription.invoiceAmount}
    client={this.state.clientSubscription}
    onChange={this.onChangeSubscription}
    back={this.dismissChatSubscription}
    accept={this.acceptSubscription}
    decline={this.declineSubscription}
    update={this.updateSubscription}
    cancel={this.cancelSubscription}
      />
  }

  

  renderSubscribeToChatPopup = () => {
    if (isMobile()) return null
    return <div className='uiScheduleAppointmentPopup'>
      <ClickAwayListener onClickAway={this.dismissChatSubscription}>
      {this.renderSubscribeToChat()}
    </ClickAwayListener>
      </div>
  }
  
  onCalendarPlus = e => this.createAppointment();
  renderCalendarPicker() {
    const isIPad = () => {
      return document.documentElement.clientWidth < 1024;
    }
    let className = 'uiChatEmojiPicker uiChatCalendarPicker';
    if (isIPad()) {
      className +=  " uiChatCalendarPickerIPad";
    }
    return <div className={className}>
      <ClickAwayListener onClickAway={this.dismissCalendarPicker}>
      <div className='uiEmojiPickerContainer'>
      <div className='uiChatCalendarContainer'>
      <UICalendar contactFilter={this.props.remoteContact} picker={true} visible={true} onSet={this.setCal} me={this.props.me} onClick={this.onCalendarClick}/>
      <div className='uiCalendarEventControls'>
      <div className='uiCalendarEventPlus' onClick={this.onCalendarPlus}>
      <div className='uiCalendarPlusIcon' ><ReactSVG src={Plus}/></div>
      <div className='uiCalendarPlusText'>Schedule an appointment{!isMobile() ? ` with ${this.props.contact.displayName}` : ''}</div>
      </div>
      </div>
      </div>
      <div className='uiEmojiPickerArrow'>
      <div className='uiEmojiPickerArrowShape'/>
      </div>
      {!isMobile() && this.state.openEvent && <div className='uiScheduleAppointmentPopup'>         
       {this.renderAppointmentPopup()}
       </div>}
    </div>
      </ClickAwayListener>
      </div>
  }

  dismissCalendarPicker = (e) => {
    if (e) e.preventDefault()
    if (this.state.openEvent) return;
    ////debugLog("dismiss calendar picker");
    this.setState({
      addAppointment: null,
      openEvent: null,
      calendarDate: null,
    });
  }

  renderAppointmentPopup = () => {
    return <UIScheduleAppointment 
    appointmentId={this.state.openEvent.id}
    back={() => this.setState({
      openEvent: null,
    })}
    appt={this.state.openEvent.appt}
    scope={this.state.openEvent.appt.workout ? "workouts" : "all"}
    isNew={this.state.openEvent.isNew}
    withReadOnly={true}
    date={this.state.openEvent.date}
    start={this.state.openEvent.start}
    end={this.state.openEvent.end}
    headerTitle={"Schedule Appointment"}
    title={this.state.openEvent.title}
    with={this.state.openEvent.with}
    on={this.state.openEvent.date}
    trash={this.trashEvent}
    schedule={this.scheduleAppointment}
    error={this.state.openEvent.error}
    onChange={this.onChangeEvent}
    editable={this.state.openEvent.editable}
    client={this.state.openEvent.client}
    invoiceAmount={this.state.openEvent.invoiceAmount || 0}
    invoiceDescription={this.state.openEvent.invoiceDescription}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    status={this.state.openEvent.status}
    paymentStatus={this.state.openEvent.paymentStatus}
    paymentMethod={this.state.openEvent.paymentMethod}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    event={this.state.openEvent}
    me={this.props.me}
      />
  }

  trashEvent = async () => {
    const appt = this.state.openEvent.appt
    if (appt && appt.scheduledWorkout) {
      await this.props.me.deleteWorkout(appt.scheduledWorkout.id)
    }
    this.setState({
      openEvent: null
    })
  }

  addWorkout = async () => {
    const now = new Date(Date.now());
    const date = new Date()
    date.setHours(now.getHours());
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const start = date;
    const end = new Date(date)
    end.setHours(date.getHours() % 24 + 1);
    const event = {
      scope: 'workouts',
      date: date,
      start: start,
      end: end,
      editable: true,
      status: '',
      invoiceAmount: 0,
      isNew: true,
      with: this.props.remoteContact
    }
    this.props.openEvent(event)
  }

  modifyWorkout = async (form, progress) => {
    const data = this.state.openEvent;
    const { activity, description, demoFile, sets, reps, weight, sport } = form
    const appt = data.appt
    const prev = appt.workout
    const date = data.date;
    let downloadURL
    if (demoFile) {
      const ref = await this.props.me.uploadFile(demoFile, progress)
      downloadURL = await ref.getDownloadURL()
    }
    const workout = {
      id: prev.id,
      description,
      sets,
      reps,
      weight,
      demo: demoFile ? [{
        contentType: demoFile.type,
        downloadURL
      }] : appt ? prev.demo : [],
      activity: activity
    }
    await this.props.me.saveWorkout({uid: prev.client}, workout)
  }

  rescheduleAppointment = (form) => {
    const appt = this.state.openEvent.appt;
    if (appt.workout) {
      return this.modifyWorkout(form)
    }
    const id = appt.id;
    const data = this.state.openEvent;
    const date = data.date;
    const start = getTime(date, data.start);
    const end = getTime(date, data.end);
    const prevStart = appt.start;
    const prevEnd = appt.end;
    const prevInvoiceAmount = appt.invoiceAmount || 0;
    const prevInvoiceDescription = appt.invoiceDescription || "";
    const prevTitle = appt.title || "";
    const updates = {
      id: id,
      start: start,
      end: end,
      invoiceDescription: this.state.openEvent.invoiceDescription || "",
      invoiceAmount: this.state.openEvent.invoiceAmount || 0,
      title: this.state.openEvent.title || "",
    }
    if (updates.start == prevStart &&
        updates.end == prevEnd &&
        updates.invoiceDescription == prevInvoiceDescription &&
        updates.invoiceAmount == prevInvoiceAmount &&
        updates.title == prevTitle) {
      //////debugLog("no change");
      this.closeEvent();
      return Promise.resolve();
    }
    const p = this.props.waitForSystemUpdate(appt);
    return this.props.me.updateAppointment(updates).then(response => {
      return p;
    });
  }

  closeEvent = () => {
    this.dismissCalendarPicker();
  }

  
  scheduleAppointment = (form) => {
    const data = this.state.openEvent;
    let p;
    if (data.appt) {
      p = this.rescheduleAppointment(form);
    } else  {
      const contact = data.with;
      const date = data.date;
      const start = data.start;
      const end = data.end;
      const updates = {
        start: getTime(date, start),
        end: getTime(date, end),
        title: data.title,
        invoiceDescription: data.invoiceDescription,
        invoiceAmount: data.invoiceAmount,
        title: data.title,
      }               
      window.showProgressIndicator("Scheduling");
      p = this.props.me.createAppointment(contact, updates);
    }
    return p.then(() => {
      this.setState({
        openEvent: null,
        addAppointment: null,
        calendarDate: null,
      });
    });
  }

  insertAppointment = () => {
    this.setState({
      addAppointment: true
    });
  }

  subscribeToChat = () => {
    let openSubscription;
    if (this.state.clientSubscription) {
      openSubscription = clone(this.state.clientSubscription);
    } else if (this.state.providerSubscription) {
      openSubscription = clone(this.state.providerSubscription);
    } else {
      openSubscription = {
        responseTime: 1,
        startDate: Date.now(),
        description: "Professional Chat",
      }
    }
    this.setState({
      addChatSubscription: true,
      openSubscription: openSubscription
    });
  }

  dismissChatSubscription = () => {
    this.setState({
      addChatSubscription: false,
      openSubscription: null
    });
  }

  createAppointment = (date) => {
    const with_ = this.props.remoteContact;
    if (this.props.setPopupShowing)     this.props.setPopupShowing(true);
    const now = new Date(Date.now());
    if (!date) {
      date = now;
    } else {
      //debugger;
      date.setHours(now.getHours());
    }
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const start = date;
    const end = new Date(date);
    end.setHours(date.getHours() % 24 + 1)
    const event = {
      id: null,
      date: start,
      start: start,
      end: end,
      with: with_,
      isNew: true,
      title: "Video Conference",
      editable: true,
      client: false,
      reschedule: false,
      invoiceDescription: "",
      invoiceAmount: 0,
      paymentIntentId: "",
      status: "",
      paymentStatus: "",
      finalPaymentMethod: null,
      paymentIntentId: ""
    }
    if (this.props.scheduleChatAppointment) {
      return this.props.scheduleChatAppointment({chat: this, event: event, renderAppointment: this.renderAppointmentPopup})
    }
    this.setState({
      openEvent: event
    });
  }

  openAppointment = appt  => {
    if (appt.client == this.props.me.self.uid || this.props.openChat) {
      return this.props.rescheduleAppointment(appt);
    }
    return this.openAppointmentHere(appt)
  }
  
  openAppointmentHere = appt  => {
    if (appt.workout && appt.workout.scheduled) {
      if (appt.workout.status) {
        return
      }
    }
    let start = appt.start ? new Date(appt.start) : undefined
    let date = start || new Date(appt.scheduled)
    let end = appt.end ? new Date(appt.end) : undefined
    const event = {
      isNew: false,
      appt: appt,
      id: appt.id,
      date: date,
      start: start,
      end: end,
      with: appt.contact,
      title: appt.title || "Video Conference",
      editable: appt.editable,
      client: appt.client.uid == this.props.me.self.uid,
      reschedule: true,
      invoiceDescription: appt.invoiceDescription || "",
      invoiceAmount: appt.invoiceAmount || 0,
      paymentIntentId: appt.paymentIntentId,
      status: appt.status,
      paymentStatus: appt.paymentStatus,
      finalPaymentMethod: appt.finalPaymentMethod,
      paymentIntentId: appt.paymentIntentId,
    }
    if (this.props.scheduleChatAppointment) {
      this.props.scheduleChatAppointment({chat: this, event: event, renderAppointment: this.renderAppointmentPopup})
    }
    if (isMobile()) {
      //return this.props.rescheduleAppointment(appt)
    }
    this.setState({
      openEvent: event
    });
    return Promise.resolve();
  }

  toggleGIFPicker = (e) => {
    if (this.state.addGIF && this.isKeyboardShowing()) {
      return
    }
    e.preventDefault()
    e.stopPropagation()
    return this.state.addGIF ? this.dismissGIFPicker() : this.insertGIF()
  }

  insertGIF = () => {
    if (this.props.setPopupShowing)     this.props.setPopupShowing(true);
    this.setState({
      addReaction: null,
      addGIF: this.doInsertGIF
    }, () => {
      if (hasSoftKeyboard()) return
      const pollFocus = () => {
        setTimeout(() => {
          const els = document.getElementsByClassName("uiChatGIFSelectInput");
          if (els && els.length > 0) {
            return els[0].focus();
          } else {
            if (this.state.addGIF) {
              pollFocus();
            }
          }
        }, 50);
      }
      pollFocus();
    });
  }

  doInsertGIF = gif => {
    const url = gif.images.downsized.url;
    const link = gif.embed_url;
    const html = "<img class='gif' src='"+url+"' gif-link='"+url+"'/>";
    this.state.editingImages[url] = url;
    this.editor.insert(html);
    this.dismissGIFPicker();
  }

  videos = {}

  insertVideo = (blob) => {
    const url = URL.createObjectURL(blob);
    this.videoURL = url;
    this.video = blob;
    const html = "<video controls playsInline class='vid' vid-link='"+url+"'/><source type='"+blob.type+"' src='"+url+"' /></video>";
    this.editor.insert(html);
  }

  renderUploads = () => {
    return <UploadProgress uploads={this.state.uploads}/>
  }

  onChangeEvent = (field, value) => {
    if (value == this.state.openEvent[field]) return;
    //////debugLog(field, " => ", value);
    this.state.openEvent[field] = value;
  }

  setFilesRef = ref => {
    if (ref) this.filesRef = ref;
  }

  setMediaFilesRef = ref => {
    if (ref) this.mediaFilesRef = ref;
  }

  chatInputClickAway = () => {
    if (this.state.editingMessage && !this.ignoreClickAway) {
      if (isDesktop()) this.cancelEdit();
    }
    this.ignoreClickAway = false;
  }


  isKeyboardShowing = () => !isDesktop() && this.state.keyboardOpen


  render() {
    const sendLabel = this.props.sendLabel || "Send Message";
    //debugLog("UIChat callActive: ", this.props.callActive);
    const isDev= this.props.me.isDev;
    let containerClass = 'uiChatMessagesContainer'
    if (this.props.callActive) {
      containerClass += ' uiChatMessagesContainerCallActive'
    }
    let placeholder
    if (this.props.placeholder) {
      placeholder = this.props.placeholder
    } else {
      placeholder = "Send a message to " +this.props.remoteContact.displayName + " "+this.props.remoteContact.creds
    }
    let respondingToMessagePrompt = 'Let\'s Build is responding to your message'
    if (this.props.me.isTodoList()) {
      respondingToMessagePrompt = 'Working...'
    }
    let messages
    let mergedBody = {}
    if (this.state.editingMessage) {
      messages = this.props.messages
    } else {
      messages = []
      let i = 0
      while (i < this.props.messages.length) {
        const msg = this.props.messages[i]
        if (this.isTextMessage(msg, true)) {
          let j = i + 1
          let lines 
          let ts = msg.ts
          let last
          let body
          while (j < this.props.messages.length) {
            const next = this.props.messages[j]
            if (next.from !== msg.from || !this.isTextMessage(next, false)) {
              break
            }
            if (next.ts - msg.ts > 15 * 1000 * 60) {
              break
            }
            if (!lines) {
              lines = [msg]
            }
            lines.push(next)
            last = next
            j++
            if (last.reactions && last.reactions.length > 0) {
              break
            }
          }
          if (last) {
            mergedBody[last.ts] = lines
            //console.log("merged", j, mergedBody[last.ts], last)
            i = j-1
            msg = last
          }
        }
        messages.push(msg)
        i++
      }
    }
    //console.log('render', messages)
    return <div className={'uiChat'} >
      <div className='uiChatMarginBottom'>
      <div key='chatMessages' className={'uiChatMessages'} ref={this.setScrollWindow}>
      <div key='chatMessagesContainer' className={containerClass} ref={this.setScrollContent}>
      {
        messages.map((msg, i) => {
          const prev = i > 0 ? messages[i-1] : null;
          const next = i + 1 < messages.length ? messages[i+1] : null;
          return this.renderChatMessage(msg, prev, next, mergedBody);
        })
      }
    </div>
      </div>            
      {this.props.contact && <div className='uiChatCallControls'>
       <CallButton callActive={this.props.callActive} contact={this.props.contact} action={this.props.toggleCallActive}/>
       </div>}
    {false && this.state.openSubscription && this.renderSubscribeToChatPopup()}
    {this.props.sendMessage && <ClickAwayListener onClickAway={this.chatInputClickAway}>
     <div className='uiChatInputClickAwayContainer'>
     <div className='uiChatInputMessageEditorRow'>
     <UIMessageEditor
     autoFocus={isDesktop()}
     editorClass={'uiChatInput' + (this.state.addReaction ? ' uiChatInputCaretHidden' : '')}
     placeholder={placeholder}
     placeholderClass={"uiChatInputPlaceholder"}
     ref={x=> this.setEditor(x)}
     onKeyDown={this.onKeyDown}
     onPaste={this.onPaste}
     onDrop={this.onDrop}
     onUpdate={this.onEditorUpdate}
     onBlur={this.onBlur}
     onFocus={this.onFocus}
     onHeightChanged={this.onEditorHeightChanged}
     />
     <div className='uiChatInputFieldButtons' style={!this.editor || this.editor.isEmpty() ? {display: 'none'} : null}>
     <UIButton tooltip={"Clear"} key='clear' className='uiChatUndoButton' disabled={false} icon={Cross} action={this.clearEdit}/>
     {!isMobile() && <UIButton tooltip={"Undo"} key='undo' className='uiChatUndoButton' disabled={false} icon={Undo} action={this.undoEdit}/>}
     </div>             
     </div>
      {this.props.sendMessage && <div key='chatInputContainer'className='uiChatInputContainer'>
       <div className='uiChatInputPopupContainer'>
     {this.state.addReaction && this.renderEmojiPicker()}
     {this.state.addGIF && this.renderGIFPicker()}
     {this.state.addAppointment && this.renderCalendarPicker()}
     {!isMobile() && this.state.openEvent && <div className='uiScheduleAppointmentPopup'>         
      {this.renderAppointmentPopup()}
      </div>}
       </div>
     <div key='chatButtonContainer' className='uiChatButtonContainer'>
     <div key='chatButtonContainerLeft' className='uiChatButtonContainerLeft'>
     {!this.props.isMe && <form key='form2'>
     <input ref={this.setMediaFilesRef} className={'uiFileUpload'} id={'uiImageUpload-'+this.chatId} name={'uiImageUpload-'+this.chatId} type='file' accept={'image/*,video/mp4,video/quicktime'} onChange={this.onFileInput}/>
     {!this.props.isMe && <label htmlFor={'uiImageUpload-'+this.chatId}>
     <UIButton tooltip={"Media Files"} className='uiChatButton' icon={ImageIcon}/>
      </label>}
      </form>}
     {!isWindows() && <UIButton tooltip={"Emojis"} key='emoji' className='uiChatButton' icon={Emoji} action={this.insertEmoji} editing={this.isKeyboardShowing}/>}
     {!this.props.isMe && <UIButton tooltip={"GIFs"} key='gif' className={'uiChatButton uiChatButtonGiphy'} icon={Giphy} action={this.toggleGIFPicker} editing={this.keyboardIsShowing}/>}
     {this.props.canTrainContact && this.props.openEvent && <UIButton tooltip={"Schedule a workout"} key='workout' className={'uiChatWorkoutButton'} icon={Activity} action={this.addWorkout}/>}
     </div>
     <div key='typing' className='uiChatTyping' style={this.state.typing ? null: {visibility: 'hidden'}}>
     {this.props.isMe ? respondingToMessagePrompt : this.props.remoteContact.displayName + ' is typing'}
     </div>
     <div className='uiChatSendButtonContainer'> 
     <UIButton key='send' className='uiChatSendButton' icon={Send} label={sendLabel}
     editing={this.isKeyboardShowing}
     action={() => {
       this.send()
       this.editor.blur()
     }}/>
     
     </div>
     </div>
     </div>
      }
    </div>
     </ClickAwayListener>}
    </div>
    </div>
      
  }
}
