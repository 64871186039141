import EMOJIs from 'emoji-datasource/emoji'
const UnicodeToEmoji = {};
const NameToEmoji = {};

const asciiToUnicodeMap = Object.freeze({
  'o/':      '👋',
  '</3':     '💔',
  '<3':      '💗',
  '8-D':     '😁',
  '8D':      '😁',
  ':-D':     '😁',
  '=-3':     '😁',
  '=-D':     '😁',
  '=3':      '😁',
  '=D':      '😁',
  'B^D':     '😁',
  'X-D':     '😁',
  'XD':      '😁',
  'x-D':     '😁',
  'xD':      '😁',
  ':\')':    '😂',
  ':\'-)':   '😂',
  ':-))':    '😃',
  '8)':      '😄',
  ':)':      '😄',
  ':-)':     '😄',
  ':3':      '😄',
  ':D':      '😄',
  ':]':      '😄',
  ':^)':     '😄',
  ':c)':     '😄',
  ':o)':     '😄',
  ':}':      '😄',
  ':っ)':     '😄',
  '=)':      '😄',
  '=]':      '😄',
  '0:)':     '😇',
  '0:-)':    '😇',
  '0:-3':    '😇',
  '0:3':     '😇',
  '0;^)':    '😇',
  'O:-)':    '😇',
  '3:)':     '😈',
  '3:-)':    '😈',
  '}:)':     '😈',
  '}:-)':    '😈',
  '*)':      '😉',
  '*-)':     '😉',
  ':-,':     '😉',
  ';)':      '😉',
  ';-)':     '😉',
  ';-]':     '😉',
  ';D':      '😉',
  ';]':      '😉',
  ';^)':     '😉',
  ':-|':     '😐',
  ':|':      '😐',
  ':(':      '😒',
  ':-(':     '😒',
  ':-<':     '😒',
  ':-[':     '😒',
  ':-c':     '😒',
  ':<':      '😒',
  ':[':      '😒',
  ':c':      '😒',
  ':{':      '😒',
  ':っC':     '😒',
  '%)':      '😖',
  '%-)':     '😖',
  ':-P':     '😜',
  ':-b':     '😜',
  ':-p':     '😜',
  ':-Þ':     '😜',
  ':-þ':     '😜',
  ':P':      '😜',
  ':b':      '😜',
  ':p':      '😜',
  ':Þ':      '😜',
  ':þ':      '😜',
  ';(':      '😜',
  '=p':      '😜',
  'X-P':     '😜',
  'XP':      '😜',
  'd:':      '😜',
  'x-p':     '😜',
  'xp':      '😜',
  ':-||':    '😠',
  ':@':      '😠',
  ':-.':     '😡',
  ':-/':     '😡',
  ':/':      '😡',
  ':L':      '😡',
  ':S':      '😡',
  ':\\':     '😡',
  '=/':      '😡',
  '=L':      '😡',
  '=\\':     '😡',
  ':\'(':    '😢',
  ':\'-(':   '😢',
  '^5':      '😤',
  '^<_<':    '😤',
  'o/\\o':   '😤',
  '|-O':     '😫',
  '|;-)':    '😫',
  ':###..':  '😰',
  ':-###..': '😰',
  'D-\':':   '😱',
  'D8':      '😱',
  'D:':      '😱',
  'D:<':     '😱',
  'D;':      '😱',
  'D=':      '😱',
  'DX':      '😱',
  'v.v':     '😱',
  '8-0':     '😲',
  ':-O':     '😲',
  ':-o':     '😲',
  ':O':      '😲',
  ':o':      '😲',
  'O-O':     '😲',
  'O_O':     '😲',
  'O_o':     '😲',
  'o-o':     '😲',
  'o_O':     '😲',
  'o_o':     '😲',
  ':$':      '😳',
  '#-)':     '😵',
  ':#':      '😶',
  ':&':      '😶',
  ':-#':     '😶',
  ':-&':     '😶',
  ':-X':     '😶',
  ':X':      '😶',
  ':-J':     '😼',
  ':*':      '😽',
  ':^*':     '😽',
  'ಠ_ಠ':     '🙅',
  '*\\0/*':  '🙆',
  '\\o/':    '🙆',
  ':>':      '😄',
  '>.<':     '😡',
  '>:(':     '😠',
  '>:)':     '😈',
  '>:-)':    '😈',
  '>:/':     '😡',
  '>:O':     '😲',
  '>:P':     '😜',
  '>:[':     '😒',
  '>:\\':    '😡',
  '>;)':     '😈',
  '>_>^':    '😤'
});

function ascii2Emoji (text) {
}


function emojiToUnicode(emoji) {
    return String.fromCodePoint.apply(null, emoji.unified.split("-").map(pt => parseInt(pt, 16)));
}

function unicodeToEmoji(unicode) {
    return UnicodeToEmoji[unicode].short_name;
}

function nameToEmoji(name) {
    const emoji =  NameToEmoji[name];
    return emoji;
}


EMOJIs.map(emoji => {
        try {
            const ch = emojiToUnicode(emoji);
            UnicodeToEmoji[ch] = emoji;
            emoji.ch = ch;
            NameToEmoji[emoji.short_name] = emoji;
        } catch (err) {
            debugger;
        }
    });

const ranges = [
              '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
              '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
              '\ud83d[\ude80-\udeff]'  // U+1F680 to U+1F6FF
              ];
    
const matcher1 = new RegExp(ranges.join('|'), 'g');
const matcher2 = new RegExp(":[^:\s]*(?:::[^:\s]*)*:", 'g');

function convertUnicodeEmojisToMarkdown(text) {
    return text.replace(matcher1, match => {
            const emoji = UnicodeToEmoji[match];
            let result = match;
            if (emoji) {
                result = ":"+UnicodeToEmoji[match].short_name+":";
                console.log(match, " => ", result);
            }
            return result;
       });
}

function convertEmojisToUnicode(text) {
    return text.replace(/:([^:\s]*(?:::[^:\s]*)*):/g, (match, name, p2) => {
            let result = match;
            const emoji = NameToEmoji[name];
            if (emoji) {
                result = emoji.ch;
            }
            return result;
        });
}

const emojiIsSupported = name => {
    return nameToEmoji(name)
}

export { emojiIsSupported, nameToEmoji, unicodeToEmoji, emojiToUnicode, convertEmojisToUnicode, convertUnicodeEmojisToMarkdown }

