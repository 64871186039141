import React, { Component } from 'react'
import {FDPage} from '../Page'
import {FDPopup} from '../Popup'
import {UIMeBAA} from '../../../../components/Me'
import Close from "../../../../assets/icons/Cross.svg";
import {ReactSVG} from 'react-svg';
import {formatDate} from '../../../../components/Appointment'
import {UISelectionList} from '../../../../components/DeviceSetup'
import {Degrees, Licenses} from '../../../../components/Settings'
import {SidebarNavButton} from '../../../../components/Sidebar'
import {renderFoodProfile, MealsProfile, DataCharts, WhoopWorkout, WhoopSleep } from '../../../../components/WhoopProfile'
import {FoodLogo, getFoodName, UIScheduleAppointment} from "../../../../components/ScheduleAppointment";
import {UISubscribeToChat} from "../../../../components/SubscribeToChat";
import {UICreateGroup} from "../../../../components/Group";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import Spinner from '../../assets/Icons/spinner.svg'
import Home from "../../assets/Icons/MHome.svg";
import HomeSolid from "../../assets/Icons/MHomeSolid.svg";
import Calendar from "../../assets/Icons/MCalendar.svg";
import CalendarSolid from "../../assets/Icons/MCalendarSolid.svg";
import Settings from "../../assets/Icons/MSettings.svg";
import SettingsSolid from "../../assets/Icons/MSettingsSolid.svg";
import ContactsSolid from "../../assets/Icons/MContactsSoild.svg";
import Contacts from "../../assets/Icons/MContacts.svg";
import PlusIcon from '../../assets/Icons/MAddContact.svg'
import Arrow from "../../../../assets/icons/PointerDown.svg";
import {FDSearchField} from '../SearchField'
import {UIMeCard, UIMeContact} from "../../../../components/Me";
import {getTime} from "../../../../components/Calendar";
import {SummaryChart} from '../Data'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import moment from 'moment'
import './index.css'

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)


class NavButton extends Component {
  constructor (props) {
    super(props)
  }
  render () {
    let className = 'teteMobileNavButton'
    className += ' ' + this.props.label
    const icon = this.props.icon
    const selectedIcon = this.props.selectedIcon
    const selected = this.props.selected === this.props.nav
    if (selected) {
      className += ' teteMobileNavButtonSelected'
    }
    return <div className={className} onClick={()=>this.props.navigate(this.props.nav)}>
      <div className='teteMobileNavButtonIcon'>
      <div key='icon' style={!selected ? null : { display: 'none'}}><ReactSVG src={icon}/></div>
      <div key='selectedIcon' style={selected ? null : { display: 'none'}}><ReactSVG src={selectedIcon}/></div>
      </div>
      <div className='teteMobileNavButtonLabel'>
      {this.props.label}
    </div>
      {this.props.unread ? <div className='teteMobileNavButtonUnread uiSideListContactUnread'>{this.props.unread}</div> : null}
     </div>
  }
  
}

export class Main extends Component {
  constructor (props) {
    super(props)
    this.state = {
      degrees: [],
      licenses: []
    }
    this.waiting = {}
    this.createWaiters = []
  }

  getContactLink = () => {
    if (this.state.link) {
      return Promise.resolve(this.state.link);
    }
    return new Promise((resolve, reject) => {
      this.props.me.getContactLink().then(link => {
        this.setState({link: link}, () => resolve(link));
      });
    });
  }
  
  navigate = nav => {
    return this.props.navigate(nav)
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  fixStatusBar = () => {
    let content = 'light-content'
    if (this.props.me.isTodoList()) {
      content = 'dark-content'
    }
    this.props.me.setStatusBarColor(content)
    setTimeout(() => this.props.me.setStatusBarColor(content), 500);
    setTimeout(() => this.props.me.setStatusBarColor(content), 700);
  }

  componentDidMount() {
    window.addEventListener('focusin', e=> {
      if (e.target instanceof HTMLInputElement || e.target.getAttribute('contentEditable')) {
        this.setState({
          inputFocused: true
        });
        this.fixStatusBar()
      }
    })
    window.addEventListener('focusout', e=> {
      this.setState({
        inputFocused: false
      });
      this.fixStatusBar()
    })
    this.props.me.observeAccount().subscribe(account => {
      if (account) {
        const degrees = account.degrees ? account.degrees.split(", ") : [];
        const licenses = account.licenses ? account.licenses.split(", ") : [];
        this.setState({
          account: account,
          degrees, licenses
        })
      }
    })
    this.apptSub = this.props.me.observeAppointments().subscribe(change => {
      const appt = change.appointment;
      debugLog("appt ", change.type, ": ", appt);
      const id = appt.id;
      if (!appt.organizer) {
        return;
      }
      const waiters = this.waiting[id];
      if (waiters) {
        delete this.waiting[id];
        waiters.map(resolve => resolve());
      } else if (this.createWaiters.length > 0) {
        for (var i = 0; i < this.createWaiters.length; i++) {
          const w = this.createWaiters[i];
          if (w.start == appt.start && w.end == appt.end &&
              w.contact.uid == appt.contact.uid) {
            this.createWaiters.splice(i, 1);
            w.resolve();
            break;
          }
        }
      }
    });
    this.sub = this.props.me.observeNotifications().subscribe(async not => {
      //alert(JSON.stringify(not, null, ' '))
      if (not.data) {
        switch (not.data.type) {
          case 'calories':
            await this.props.suggestRecordMeal()
            break
          case 'scheduledWorkout':
            const scheduledWorkout = not.data.scheduledWorkout
            let contact = await this.props.me.resolveContact(scheduledWorkout.client)
            switch (scheduledWorkout.status) {
              case 'done':
              case 'started':
              case 'completed':
              case 'declined':
                {
                  await this.props.openEvent(contact, 'cal', 'workouts', scheduledWorkout.id)
                }
                break
              default:
                {
                  await this.props.openTodoList()
                  // open to-do list
                }
                break
              case 'canceled':
                {
                }
                break
            }
            break
          case 'bedtime':
            await this.props.openTodoList()
            break
          case 'weight':
          case 'sleep':
          case 'meal':
          case 'workout':
          case 'recovery':
            {
              const contact = await this.props.me.resolveContact(not.data.from)
              let scope
              switch (not.data.type) {
                case 'weight':
                  scope = 'weight'
                  break
                case 'sleep':
                  scope = 'sleep'
                  break
                case 'workout':
                  scope = 'workouts'
                  break
                case 'recovery':
                  scope = 'all'
                  break
                case 'meal':
                  scope = 'meals'
              }
              await this.props.openEvent(contact, 'cal', scope)
            }
            break
          case 'call':
            {
              const contact = await this.props.me.resolveContact(not.data.from)
              await this.props.answerCall(contact)
            }
            break
          case 'addToGroup':
            {
              const group = await this.props.me.resolveGroup(not.data.addToGroup)
              await this.props.openChat(group, 'chat')
            }
            break;
          case 'message':
            {
              if (not.data.isGroup) {
                const group = await this.props.me.resolveGroup(not.data.channel)
                await this.props.openChat(group, 'chat')
              } else {
                const contact = await this.props.me.resolveContact(not.data.from)
                await this.props.openChat(contact, 'chat')
              }
            }
            break;
        }
      }
    })
    this.getContactLink().then(() => {
      debugLog('got contact link')
    })
  }

  waitForCreate = (contact, appt) => {
    return new Promise((resolve, reject) => {
      this.createWaiters.push({
        start: appt.start,
        end: appt.end,
        contact: contact,
        resolve: resolve,
      })
    });
  }

  waitForUpdate = (id) => {
    let waiting = this.waiting[id];
    if (!waiting) {
      waiting = [];
      this.waiting[id] = waiting;
    }
    return new Promise((resolve, reject) => {
      waiting.push(resolve);
    });
  }

  openChat = (to, startCall) => {
    return this.props.openChat(to, false)
  }

  answer = (call, handler) => {
    return handler(call)
  }

  viewBAA = () => {
    this.setState({
      viewBAA: true
    })
    return Promise.resolve()
  }

  acceptBAA = () => {
    this.setState({
      acceptedBAA: true
    })
    return Promise.resolve()
  }

  hideBAA = () =>  {
    this.setState({
      viewBAA: false
    })
    return Promise.resolve()
  }

  searchRef = React.createRef()

  setMe = ref => {
    this.meRef = ref
  }

  renderHome = () => {
    return <div className='teteMobileMainTop' style={this.props.nav == 'home' ? null : { display: 'none'}}>
      {this.props.renderHome({ref: this.setMe})}
    </div>
  }

  renderHome0 = () => {
    let popup
    let subpage
    const openPage = page => {
      this.setState({
        homePage: page
      })
    }
    const closePage = page => {
      this.setState({
        homePage: null
      })
    }
    const openPopup = popup => {
      this.setState({
        homePopup: popup
      })
    }
    const closePopup = () => {
      this.setState({
        homePopup: null
      })
    }
    const update = () => {
      this.forceUpdate()
    }
    if (this.state.viewBAA) {
      popup = <FDPopup me={this.props.me}>
        {<UIMeBAA download={this.props.me.downloadBAA} acceptedBAA={this.state.acceptedBAA} cancel={this.hideBAA} accept={this.acceptBAA}/>}
      </FDPopup>
    } else if (this.state.homePopup) {
      popup = this.state.homePopup()
    } else if (this.state.homePage) {
      subpage = this.state.homePage()
    }
    
    return <div className='teteMobileMainTop' style={this.props.nav == 'home' ? null : { display: 'none'}}>
      <FDPage safeArea noHeaderButtons customSearch={this.searchRef} title='Home' me={this.props.me} popup={popup} subPage={subpage}>
      {this.props.renderHome({viewBAA: this.viewBAA, searchPortal: this.searchRef.current, openPopup, closePopup, openPage, closePage, update})}
    </FDPage>
      </div>
  }

  searchDegrees = async searchTerm => {
    this.setState({
      searchDegrees: searchTerm
    })
  }

  searchLicenses = async searchTerm => {
    this.setState({
      searchLicenses: searchTerm
    })
  }

  searchContacts = async searchTerm => {
    this.setState({
      contactsSearchTerm: searchTerm
    })
  }

  closeContact = async () => {
    this.props.openChat(null)
  }

  modifyWorkout = async (form) => {
    const data = this.state.openEvent;
    const { activity, description, demoFile, sets, reps, weight, sport } = form
    let { demo } = form
    debugger
    const appt = data.appt
    const prev = appt ? appt.workout : null
    const date = data.date;
    if (demoFile) {
      const ref = await this.props.me.uploadFile(demoFile)
      const downloadURL = await ref.getDownloadURL()
      demo = [{
        contentType: demoFile.type,
        downloadURL
      }]
    }
    if (!demo) {
      if (prev) {
        demo = prev.demo
      }
    }
    const workout = {
      description,
      sets,
      reps,
      weight,
      demo: demo,
      activity: activity
    }
    if (prev) {
      workout.id = prev.id
    }
    const uid = prev ? prev.client : this.state.openEvent.with.uid
    await this.props.me.saveWorkout({uid: uid}, workout)
  }

  rescheduleAppointment = (form) => {
    const appt = this.state.openEvent.appt;
    if (appt.workout) {
      return this.modifyWorkout(form).then(() => {
        this.closeEvent()
      })
    }
    const id = appt.id;
    const data = form
    const date = new Date(data.date)
    const start = getTime(date, data.start);
    const end = getTime(date, data.end);
    const prevStart = appt.start;
    const prevEnd = appt.end;
    const prevInvoiceAmount = appt.invoiceAmount || 0;
    const prevInvoiceDescription = appt.invoiceDescription || "";
    const prevTitle = appt.title || "";
    const updates = {
      id: id,
      start: start,
      end: end,
      invoiceDescription: form.invoiceDescription || "",
      invoiceAmount: form.invoiceAmount || 0,
      title: form.title || "",
    }
    if (updates.start == prevStart &&
        updates.end == prevEnd &&
        updates.invoiceDescription == prevInvoiceDescription &&
        updates.invoiceAmount == prevInvoiceAmount &&
        updates.title == prevTitle) {
      ////debugLog("no change");
      this.closeEvent();
      return Promise.resolve();
    }
    const p = this.waitForSystemUpdate(appt);
    return this.props.me.updateAppointment(updates).then(p)
  }

  scheduleMeal = async (form) => {
    const data = this.state.openEvent;
    const { mealSelection, foods } = form
    if (data.appt) {
      // reschedule
    } else {
      const date = data.date;
      const start = data.start;
      const end = data.end;
      const meal = {
        start: getTime(date, start),
        end: getTime(date, end),
        foods: foods,
        type: mealSelection
      }
      await this.props.me.saveMeal(meal)
    }
  }

  scheduleAppointment = (form) => {
    const data = this.state.openEvent;
    if (data.scope == 'meals' || (data.appt && data.appt.meal)) {
      return this.scheduleMeal(form).then(() => {
        debugLog('saved meal')
        this.setState({
          openEvent: null
        })
      })
    }
    if (data.scope == 'workouts' || (data.appt && data.appt.workout)) {
      return this.modifyWorkout(form).then(() => {
        this.setState({
          openEvent: null
        })
      })
    }
    let p;
    if (data.appt) {
      p = this.rescheduleAppointment(form);
    } else  {
      const contact = data.with;
      const date = form.start;
      const start = form.start;
      const end = form.end;
      const updates = {
        start: getTime(date, start),
        end: getTime(date, end),
        title: form.title,
        invoiceDescription: form.invoiceDescription,
        invoiceAmount: form.invoiceAmount,
        title: form.title,
      }               
      window.showProgressIndicator("Scheduling");
      p = this.props.me.createAppointment(contact, updates);
    }
    return p.then(() => {
      this.setState({
        openEvent: null,
        addAppointment: null,
        calendarDate: null,
      });
    }).catch(err => {
      this.state.openEvent.error = err.message
      this.forceUpdate()
    })
  }
  
  renderAppointmentPopup = (withReadOnly) => {
    if (!this.state.openEvent ||
        (this.state.openEvent.appt && (
          this.state.openEvent.appt.sleep
            ||
            this.state.openEvent.appt.meal
            ||
            this.state.openEvent.appt.weight
        ))) return null
    return <div className='uiScheduleAppointmentPopup'>
      <UIScheduleAppointment
    appt={this.state.openEvent.appt}
    appointmentId={this.state.openEvent.id}
    back={() => this.setState({
      openEvent: null,
    })}
    scope={this.state.openEvent.scope}
    isNew={this.state.openEvent.isNew}
    withReadOnly={withReadOnly}
    date={this.state.openEvent.date}
    start={this.state.openEvent.start}
    end={this.state.openEvent.end}
    headerTitle={"Schedule Appointment"}
    title={this.state.openEvent.title}
    with={this.state.openEvent.with}
    on={this.state.openEvent.date}
    trash={this.trashEvent}
    accept={()=>this.acceptEvent(this.state.openEvent.appt)}
    decline={()=>this.declineEvent(this.state.openEvent.appt)}
    schedule={this.scheduleAppointment}
    error={this.state.openEvent.error}
    onChange={this.onChangeEvent}
    editable={this.state.openEvent.editable}
    client={this.state.openEvent.client}
    invoiceAmount={this.state.openEvent.invoiceAmount || 0}
    invoiceDescription={this.state.openEvent.invoiceDescription}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    status={this.state.openEvent.status}
    paymentStatus={this.state.openEvent.paymentStatus}
    paymentMethod={this.state.openEvent.paymentMethod}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    event={this.state.openEvent}
    me={this.props.me}
      />
      </div>
  }

  acceptEvent = appt => {
    const id = appt.id
    const p = this.waitForSystemUpdate(appt)
    return this.props.me.acceptAppointment(id).then(window.hideProgressIndicator).then(() => {
      return p.then(this.closeEvent)
    });
  }

  declineEvent = appt => {
    if (appt.workout) {
      const p = this.waitForSystemUpdate(appt)
      return this.props.me.declineWorkout(appt.id).then(() => p.then(this.closeEvent))
    }
    const id = appt.id
    const p = this.waitForSystemUpdate(appt)
    return this.props.me.declineAppointment(id).then(window.hideProgressIndicator).then(() => {
      return p.then(this.closeEvent)
    });                                                                                            
  }

  removeEvent = appt => {
    if (appt.workout) {
      const p = this.waitForSystemUpdate(appt)
      return this.props.me.deleteWorkout(appt.id).then(() => p.then(this.closeEvent))
    }
    const id = appt.id
    const p = this.waitForSystemUpdate(appt)
    return this.props.me.deleteAppointment(id).then(() => p)
  }

  waitForSystemUpdate = appt => this.waitForUpdate(appt.id)

  trashEvent = () => {
    let p;
    if (this.state.openEvent.client) {
      p = this.declineEvent(this.state.openEvent.appt)
    } else {
      p = this.removeEvent(this.state.openEvent.appt)
    }
    return p.then(this.closeEvent)
  }

  closeEvent = () => {
    this.setState({
      openEvent: null
    })
  }
  
  renderSubscribeToChat = () => {
    return <UISubscribeToChat
    title={"Professional Chat"}
    editable={!this.state.clientSubscription &&
              (!this.state.providerSubscription || this.state.providerSubscription.state != "active")}
    isNew={!this.state.providerSubscription && !this.state.clientSubscription}
    small={!this.state.clientSubscription}
    me={this.props.me}
    state={this.state.openSubscription.state}
    with={this.props.remoteContact}
    withReadOnly={true}
    description={this.state.openSubscription.description}
    responseTime={this.state.openSubscription.responseTime}
    startDate={new Date(this.state.openSubscription.startDate)}
    on={new Date(this.state.openSubscription.startDate)}
    invoiceDescription={this.state.openSubscription.invoiceDescription}
    invoiceAmount={this.state.openSubscription.invoiceAmount}
    client={this.state.clientSubscription}
    onChange={this.onChangeSubscription}
    back={this.dismissChatSubscription}
    accept={this.acceptSubscription}
    decline={this.declineSubscription}
    update={this.updateSubscription}
    cancel={this.cancelSubscription}
      />
  }


  toggleAddContact = (e) => {
    e.preventDefault()
    this.setState({
      addContact: !this.state.addContact
    })
  }

  renderContacts = () => {
    let popup
    let subpage
    let eventPage
    let eventPage2
    const search = <div className='uiMobileContactsSearch'>
          <FDSearchField search={this.searchContacts}    
    searchValue={this.state.contactsSearchTerm}/>
      <div className={'uiMobileContactsAddContact' + (this.state.addContact ? ' uiMobileContactsAddContactSelected' : '')} onClick={this.toggleAddContact}><ReactSVG src={PlusIcon}/></div>          
      </div>
      const onClickProfile = (profile, cycleData, meals, weights, category) => {
        this.setState({
          openProfile: {type: profile.type, profile, cycleData, meals, weights, category}
        })
      }
    const closeProfile = () => {
      this.setState({
        openProfile: null
      })
    }
    const openEvent = event => {
      this.setState({
        openEvent: event
      })
    }
    const closeEvent = () => {
      this.setState({
        openEvent: null
      })
    }
    const openPopup = x => {
      this.setState({
        openPopup: x
      })
    }
    if (this.state.openProfile) {
      eventPage = <FDPage safeArea embed title={this.state.openProfile.category.name} me={this.props.me} back={closeProfile}>
        <DataCharts options={
          {
            category: this.state.openProfile.category,
            type: this.state.openProfile.type,
            cycleData: this.state.openProfile.cycleData,
            meals: this.state.openProfile.meals,
            weights: this.state.openProfile.weights,
          }
        }/>
        
      </FDPage>
    }
    if (this.props.nav == 'contacts') {
      if (this.state.openEvent) {
        if (this.state.openEvent.appt && this.state.openEvent.appt.sleep) {
          const contact = this.state.openEvent.appt.organizer
          eventPage = <FDPage safeArea embed title="Sleep" me={this.props.me} back={closeEvent}>
            <WhoopSleep sleep={this.state.openEvent.appt.sleep} contact={contact} me={this.props.me}/>
            </FDPage>
        } else if (this.state.openEvent.appt && this.state.openEvent.appt.workout
                   && !this.state.openEvent.appt.workout.scheduled) {
          const contact = this.state.openEvent.appt.organizer
          eventPage = <FDPage safeArea embed title="Workout" me={this.props.me} back={closeEvent}>
            <WhoopWorkout workout={this.state.openEvent.appt.workout} contact={contact} me={this.props.me}/>
            </FDPage>
        } else if (this.state.openEvent.appt && this.state.openEvent.appt.meal) {
          const appt = this.state.openEvent.appt;
          const meal = appt.meal
          const contact = this.state.openEvent.appt.contact
          const when = formatDate(new Date(appt.start))
          if (this.state.openEventFood) {
            const food = this.state.openEventFood
            const close = () => this.setState({
              openEventFood: null
            })
            eventPage2 = <FDPage safeArea embed={true} title="Food" me={this.props.me} back={close}>
              {renderFoodProfile({
                me: this.props.me,
                title: getFoodName(food),
                subtitle: '',
                foods: [food]
              })}
            </FDPage>
          }
          eventPage = <FDPage safeArea embed={true} title="Meal" subPage={eventPage2} me={this.props.me} back={closeEvent}>
            {renderFoodProfile({
              me: this.props.me,
              title: capitalize(meal.type),
              subtitle: when,
              foods: meal.foods,
              onClick: food => {
                this.setState({
                  openEventFood: food
                })
              }
            })}
          </FDPage>
        } else {          
          popup = this.renderAppointmentPopup(true)
        }
      }
      else if (this.state.openSummaryURL) {
        const onLoad = ({numPages}) => {
          debugger
          this.setState({
            openDocumentNumPages: numPages
          })
        }
        const onLoadErr = e => {
          debugger
        }
        const pages = []
        if (this.state.openDocumentNumPages) {
          for (let i = 0; i < this.state.openDocumentNumPages && i < 5; i++) {
            pages.push(i+1)
          }
        }
        const testLoading = false
        const loading = <div className='uiPDFLoading'>
              <ReactSVG src={Spinner}/>
              </div>
          eventPage = <FDPage safeArea embed={true} title={this.state.openSummaryTitle} me={this.props.me} back={this.closeDocument}>
          {testLoading ? loading : <Document loading={loading} file={this.state.openSummaryURL} onLoadSuccess={onLoad} onLoadError={onLoadErr} renderMode={'svg'}>
          {pages.map(i => <Page key={i} pageNumber={i}/>)}
           </Document>}
          </FDPage>
      }    
    }
    const subpages = []
    if (this.state.openPopup) {
      popup = this.state.openPopup()
    }
    if (this.state.manageGroup) {
      popup = <UICreateGroup me={this.props.me} group={this.state.manageGroup} close={()=>this.setState({manageGroup: false})} />
    }
    const contactPage = <FDPage safeArea embed back={this.closeContact} title={this.props.openContact ? this.props.openContact.displayName : "Contact"} subPage={eventPage} me={this.props.me} popup={popup}>
                    {this.props.renderOpenContacts({
                      openEvent, closeEvent, onClickProfile, openSummary: this.openSummary, manageGroup: this.manageGroup, openPopup: openPopup
                    })}
    </FDPage>
    subpages.push(contactPage)
    if (this.props.openContact) {
      subpage = subpages[subpages.length-1]
    }
    let popup1
    if (this.state.createGroup) {
      popup1 = <UICreateGroup me={this.props.me} close={()=>this.setState({createGroup: false})} />
    }
    let style
    return <div className='teteMobileMainTop' style={this.props.nav == 'contacts' ? null : { display: 'none'}}>
      <FDPage safeArea noHeaderButtons title='Contacts' customSearchField={search} me={this.props.me} popup={popup1}
    subPages={subpages}
    subPage={subpage}>
      {this.props.renderSidebar({contactsSearchTerm: this.state.contactsSearchTerm, openChat: this.openChat, nav: this.props.nav, navigate: this.navigate, answer: this.answer})}
    {this.state.addContact && <ClickAwayListener onClickAway={this.toggleAddContact}><div className='uiContactsMobileAddContactCard'>
     <UIMeCard title='Add Contact'>
     <UIMeContact me={this.props.me} createGroup={this.showCreateGroup} link={this.state.link}/>
     </UIMeCard>
     <div className='uiContactsMobileContactLinkArrow'><ReactSVG src={Arrow}/></div>
     </div>
     </ClickAwayListener>}
    </FDPage>
      </div>
  }

  showCreateGroup = () => {
    this.setState({
      addContact: false,
      createGroup: true
    })
  }

  manageGroup = to => {
    this.setState({
      manageGroup: to
    })
  }

  setCal = ref => {
    if (ref) {
      this.cal = ref
    }
  }

  onChangeEvent = (field, value) => {
    if (value == this.state.openEvent[field]) return;
    ////debugLog(field, " => ", value);
    this.state.openEvent[field] = value;
  }
  
  onEventChanged = event => {
    debugLog("event=>", event);
    this.forceUpdate()
  }
  
  openSummary = (title, url) => {
    this.setState({
      openSummaryTitle: title,
      openSummaryURL: url
    })
  }

  closeDocument = () => {
    this.setState({
      openSummaryURL: null,
      openDocumentNumPages: 0
    })
  }
  
  renderCalendar = () => {
    let popup
    let subpage
    const openEvent = event => {
      this.setState({
        openEvent: event
      })
    }
    const closeEvent = () => {
      this.setState({
        openEvent: null
      })
    }
    if (this.state.openEvent && this.props.nav == 'cal') {
      popup = this.renderAppointmentPopup(false)      
    }
    return <div className='teteMobileMainTop' style={this.props.nav == 'cal' ? null : { display: 'none'}}>
      <FDPage safeArea embed noHeader title='Calendar' me={this.props.me} popup={popup} subPage={subpage}>
      {this.props.renderCalendar({
        onEventChanged: this.onEventChanged,
        ref: this.setCal,
        openEvent, closeEvent
      })}
    </FDPage>
      </div>
  }

  onChangeDegrees = value => {
    this.setState({
      degrees: value,
    });
  }

  onClickDegrees = () => {
    this.setState({
      selectingDegrees: !this.state.selectingDegrees
    });
    if (this.props.onClickDegrees) {
      this.props.onClickDegrees()
    }
  }

  dismissDegreesSelection = () => {
    this.setState({
      selectingDegrees: false
    });
  }

  onChangeLicenses = value => {
    this.setState({
      licenses: value,
    });
  }

  onClickLicenses = () => {
    this.setState({
      selectingLicenses: !this.state.selectingLicenses
    });
    if (this.props.onClickLicenses) {
      this.props.onClickLicenses()
    }
  }

  dismissLicensesSelection = () => {
    this.setState({
      selectingLicenses: false
    });
  } 

  renderSettings = () => {
    let popup
    let subpage
    let options;
    let searchTerm;
    const existing = {}
    const onCancel = () => {
      debugger
      const account = this.state.account
      const degrees = account.degrees ? account.degrees.split(", ") : [];
      const licenses = account.licenses ? account.licenses.split(", ") : [];
      this.setState({
        degrees,
        licenses
      })
    }
    if (this.state.selectingLicenses) {
      options = Licenses
      searchTerm = this.state.searchLicenses
      this.state.licenses.forEach(x => existing[x] = true)
    }
    else if (this.state.selectingDegrees) {
      options = Degrees
      searchTerm = this.state.searchDegrees
      this.state.degrees.forEach(x => existing[x] = true)
    }
    debugLog('searchTerm:', searchTerm)
    if (searchTerm) {
      const searchTerms = searchTerm.toLowerCase().split(/\s+/);
      const matches = {}
      const filter = opt => {
        let matched = 0;
        const name = opt.name.toLowerCase();
        const terms = name.split(/\s+|[()]/);
        searchTerms.forEach(searchTerm => {
          terms.forEach(term => { if (term.startsWith(searchTerm)) { matched++ } });
        });
        matches[opt.value] = matched;
        return matched > 0;
      }
      const sort = (x, y) => {
        if (existing[x.value] && !existing[y.value]) return -1
        if (existing[y.value] && !existing[x.value]) return 1
        const w1 = matches[x.value];
        const w2 = matches[y.value];
        const cmp1 = w2-w1;
        if (cmp1 !== 0) {
          return cmp1;
        }
        return x.name.localeCompare(y.name);
      }
      options = options.filter(filter).sort(sort);
    } 
    if (this.state.selectingLicenses) {
      subpage = <FDPage embed safeArea search={this.searchLicenses} searchTerm={this.state.searchLicenses} me={this.props.me} back={this.dismissLicensesSelection} title='Licenses'>
        {<UISelectionList multiSelect={true} select={this.onChangeLicenses} options={options} selected={this.state.licenses} visible={true} value={this.state.licenses}/>}
      </FDPage>
    } else if (this.state.selectingDegrees) {
      subpage = <FDPage embed title={'Degrees'} safeArea search={this.searchDegrees} searchTerm={this.state.searchDegrees} me={this.props.me} back={this.dismissDegreesSelection}>
        {<UISelectionList multiSelect={true} select={this.onChangeDegrees} options={options} selected={this.state.degrees} visible={true} value={this.state.degrees}/>}
      </FDPage>
    }
    return <div className='teteMobileMainTop' style={this.props.nav == 'settings' ? null : { display: 'none'}}>
      <FDPage noHeader title='Settings' me={this.props.me} popup={popup}
    subPage={subpage}>
      {this.props.renderSettings({
        onClickDegrees: this.onClickDegrees,
        onClickLicenses: this.onClickLicenses,
        licenses: this.state.licenses,
        degrees: this.state.degrees,
        onCancel: onCancel
      })}
    </FDPage>
      </div>
  }


  renderNav = () => {
    console.log("renderNav", this.props.nav)
    if (this.props.me.isTodoList()) {
      if (this.props.meNav !== 'todo') {
        return null
      }
      return <div className='teteMobileTodoListSafeArea' style={this.state.inputFocused ? { display: 'none' }: null}/>
    }
    return <div className={'teteMobileNav'} style={this.state.inputFocused ? { display: 'none' } : null}>
      <NavButton unread={this.props.systemUnread} nav='home' icon={Home} selectedIcon={HomeSolid} navigate={this.navigate} selected={this.props.nav} label='Home'/>
      <NavButton unread={this.props.contactsUnread} nav='contacts' icon={Contacts} selectedIcon={ContactsSolid} navigate={this.navigate} selected={this.props.nav} label='Contacts'/>
      <NavButton nav='cal' icon={CalendarSolid} selectedIcon={Calendar} navigate={this.navigate} selected={this.props.nav} label='Appointments'/>
      <NavButton nav='settings' icon={Settings} selectedIcon={SettingsSolid} navigate={this.navigate} selected={this.props.nav} label='Settings'/>
      </div>
  }

  render () {
    let className = 'teteMobileMain'
    if (!this.props.me.isNative()) {
      className += " teteMobileWeb"
    }
    let className2 = 'teteMobileMainView'
    if (this.state.inputFocused) {
      className2 += ' teteMobileMainViewFocused'
    }
    return <div className={className}>
      <div className={className2}>
    {this.renderHome()}
    {this.renderContacts()}
    {this.renderCalendar()}
    {this.renderSettings({renderSelectionList: this.renderSettingsSelectionList})}
    </div>
      {this.renderNav()}
    </div>
  }
}
