const attrs = [
  {
    "attr_id": 301,
    "2018 NFP": 1,
    "usda_tag": "CA",
    "name": "Calcium, Ca",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_calcium_mg",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 205,
    "2018 NFP": 1,
    "usda_tag": "CHOCDF",
    "name": "Carbohydrate, by difference",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_total_carbohydrate",
    "cxh_attribute_name": "CHO-",
    "": ""
  },
  {
    "attr_id": 601,
    "2018 NFP": 1,
    "usda_tag": "CHOLE",
    "name": "Cholesterol",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_cholesterol",
    "cxh_attribute_name": "CHOL-",
    "": ""
  },
  {
    "attr_id": 208,
    "2018 NFP": 1,
    "usda_tag": "ENERC_KCAL",
    "name": "Energy",
    "unit": "kcal",
    "notes": "",
    "bulk_csv_field": "nf_calories",
    "cxh_attribute_name": "ENER-",
    "": ""
  },
  {
    "attr_id": 606,
    "2018 NFP": 1,
    "usda_tag": "FASAT",
    "name": "Fatty acids, total saturated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_saturated_fat",
    "cxh_attribute_name": "FASAT",
    "": ""
  },
  {
    "attr_id": 204,
    "2018 NFP": 1,
    "usda_tag": "FAT",
    "name": "Total lipid (fat)",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_total_fat",
    "cxh_attribute_name": "FAT",
    "": ""
  },
  {
    "attr_id": 605,
    "2018 NFP": 1,
    "usda_tag": "FATRN",
    "name": "Fatty acids, total trans",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_trans_fatty_acid",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 303,
    "2018 NFP": 1,
    "usda_tag": "FE",
    "name": "Iron, Fe",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_iron",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 291,
    "2018 NFP": 1,
    "usda_tag": "FIBTG",
    "name": "Fiber, total dietary",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_dietary_fiber",
    "cxh_attribute_name": "FIBTSW",
    "": ""
  },
  {
    "attr_id": 306,
    "2018 NFP": 1,
    "usda_tag": "K",
    "name": "Potassium, K",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_potassium",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 307,
    "2018 NFP": 1,
    "usda_tag": "NA",
    "name": "Sodium, Na",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_sodium",
    "cxh_attribute_name": "NA",
    "": ""
  },
  {
    "attr_id": 203,
    "2018 NFP": 1,
    "usda_tag": "PROCNT",
    "name": "Protein",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_protein",
    "cxh_attribute_name": "PRO-",
    "": ""
  },
  {
    "attr_id": 269,
    "2018 NFP": 1,
    "usda_tag": "SUGAR",
    "name": "Sugars, total",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_sugars",
    "cxh_attribute_name": "SUGAR-",
    "": ""
  },
  {
    "attr_id": 539,
    "2018 NFP": 1,
    "usda_tag": "SUGAR_ADD",
    "name": "Sugars, added",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_added_sugars",
    "cxh_attribute_name": "SUGAD",
    "": ""
  },
  {
    "attr_id": 324,
    "2018 NFP": 1,
    "usda_tag": "VITD",
    "name": "Vitamin D",
    "unit": "IU",
    "notes": "",
    "bulk_csv_field": "nf_vitamin_d_mcg",
    "cxh_attribute_name": "",
    "": "must transform MCG to IU"
  },
  {
    "attr_id": 513,
    "2018 NFP": 0,
    "usda_tag": "ALA_G",
    "name": "Alanine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 221,
    "2018 NFP": 0,
    "usda_tag": "ALC",
    "name": "Alcohol, ethyl",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 511,
    "2018 NFP": 0,
    "usda_tag": "ARG_G",
    "name": "Arginine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 207,
    "2018 NFP": 0,
    "usda_tag": "ASH",
    "name": "Ash",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 514,
    "2018 NFP": 0,
    "usda_tag": "ASP_G",
    "name": "Aspartic acid",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 454,
    "2018 NFP": 0,
    "usda_tag": "BETN",
    "name": "Betaine",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 262,
    "2018 NFP": 0,
    "usda_tag": "CAFFN",
    "name": "Caffeine",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 639,
    "2018 NFP": 0,
    "usda_tag": "CAMD5",
    "name": "Campesterol",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 322,
    "2018 NFP": 0,
    "usda_tag": "CARTA",
    "name": "Carotene, alpha",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 321,
    "2018 NFP": 0,
    "usda_tag": "CARTB",
    "name": "Carotene, beta",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 326,
    "2018 NFP": 0,
    "usda_tag": "CHOCAL",
    "name": "Vitamin D3 (cholecalciferol)",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "nf_vitamin_d3",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 421,
    "2018 NFP": 0,
    "usda_tag": "CHOLN",
    "name": "Choline, total",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 334,
    "2018 NFP": 0,
    "usda_tag": "CRYPX",
    "name": "Cryptoxanthin, beta",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 312,
    "2018 NFP": 0,
    "usda_tag": "CU",
    "name": "Copper, Cu",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 507,
    "2018 NFP": 0,
    "usda_tag": "CYS_G",
    "name": "Cystine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 268,
    "2018 NFP": 0,
    "usda_tag": "ENERC_KJ",
    "name": "Energy",
    "unit": "kJ",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 325,
    "2018 NFP": 0,
    "usda_tag": "ERGCAL",
    "name": "Vitamin D2 (ergocalciferol)",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "nf_vitamin_d2",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 610,
    "2018 NFP": 0,
    "usda_tag": "F10D0",
    "name": "10:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 611,
    "2018 NFP": 0,
    "usda_tag": "F12D0",
    "name": "12:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 696,
    "2018 NFP": 0,
    "usda_tag": "F13D0",
    "name": "13:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 612,
    "2018 NFP": 0,
    "usda_tag": "F14D0",
    "name": "14:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 625,
    "2018 NFP": 0,
    "usda_tag": "F14D1",
    "name": "14:01",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 652,
    "2018 NFP": 0,
    "usda_tag": "F15D0",
    "name": "15:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 697,
    "2018 NFP": 0,
    "usda_tag": "F15D1",
    "name": "15:01",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 613,
    "2018 NFP": 0,
    "usda_tag": "F16D0",
    "name": "16:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 626,
    "2018 NFP": 0,
    "usda_tag": "F16D1",
    "name": "16:1 undifferentiated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 673,
    "2018 NFP": 0,
    "usda_tag": "F16D1C",
    "name": "16:1 c",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 662,
    "2018 NFP": 0,
    "usda_tag": "F16D1T",
    "name": "16:1 t",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 653,
    "2018 NFP": 0,
    "usda_tag": "F17D0",
    "name": "17:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 687,
    "2018 NFP": 0,
    "usda_tag": "F17D1",
    "name": "17:01",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 614,
    "2018 NFP": 0,
    "usda_tag": "F18D0",
    "name": "18:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 617,
    "2018 NFP": 0,
    "usda_tag": "F18D1",
    "name": "18:1 undifferentiated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 674,
    "2018 NFP": 0,
    "usda_tag": "F18D1C",
    "name": "18:1 c",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 663,
    "2018 NFP": 0,
    "usda_tag": "F18D1T",
    "name": "18:1 t",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 859,
    "2018 NFP": 0,
    "usda_tag": "F18D1TN7",
    "name": "18:1-11t (18:1t n-7)",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 618,
    "2018 NFP": 0,
    "usda_tag": "F18D2",
    "name": "18:2 undifferentiated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 670,
    "2018 NFP": 0,
    "usda_tag": "F18D2CLA",
    "name": "18:2 CLAs",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 675,
    "2018 NFP": 0,
    "usda_tag": "F18D2CN6",
    "name": "18:2 n-6 c,c",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 669,
    "2018 NFP": 0,
    "usda_tag": "F18D2TT",
    "name": "18:2 t,t",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 619,
    "2018 NFP": 0,
    "usda_tag": "F18D3",
    "name": "18:3 undifferentiated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 851,
    "2018 NFP": 0,
    "usda_tag": "F18D3CN3",
    "name": "18:3 n-3 c,c,c (ALA)",
    "unit": "g",
    "notes": "omega-3 alpha-linolenic acid",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 685,
    "2018 NFP": 0,
    "usda_tag": "F18D3CN6",
    "name": "18:3 n-6 c,c,c",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 627,
    "2018 NFP": 0,
    "usda_tag": "F18D4",
    "name": "18:04",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 615,
    "2018 NFP": 0,
    "usda_tag": "F20D0",
    "name": "20:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 628,
    "2018 NFP": 0,
    "usda_tag": "F20D1",
    "name": "20:01",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 672,
    "2018 NFP": 0,
    "usda_tag": "F20D2CN6",
    "name": "20:2 n-6 c,c",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 689,
    "2018 NFP": 0,
    "usda_tag": "F20D3",
    "name": "20:3 undifferentiated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 852,
    "2018 NFP": 0,
    "usda_tag": "F20D3N3",
    "name": "20:3 n-3",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 853,
    "2018 NFP": 0,
    "usda_tag": "F20D3N6",
    "name": "20:3 n-6",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 620,
    "2018 NFP": 0,
    "usda_tag": "F20D4",
    "name": "20:4 undifferentiated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 855,
    "2018 NFP": 0,
    "usda_tag": "F20D4N6",
    "name": "20:4 n-6",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 629,
    "2018 NFP": 0,
    "usda_tag": "F20D5",
    "name": "20:5 n-3 (EPA)",
    "unit": "g",
    "notes": "omega-3 eicosapentaenoic acid",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 857,
    "2018 NFP": 0,
    "usda_tag": "F21D5",
    "name": "21:05",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 624,
    "2018 NFP": 0,
    "usda_tag": "F22D0",
    "name": "22:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 630,
    "2018 NFP": 0,
    "usda_tag": "F22D1",
    "name": "22:1 undifferentiated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 858,
    "2018 NFP": 0,
    "usda_tag": "F22D4",
    "name": "22:04",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 631,
    "2018 NFP": 0,
    "usda_tag": "F22D5",
    "name": "22:5 n-3 (DPA)",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 621,
    "2018 NFP": 0,
    "usda_tag": "F22D6",
    "name": "22:6 n-3 (DHA)",
    "unit": "g",
    "notes": "omega-3 docosahexaenoic acid",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 654,
    "2018 NFP": 0,
    "usda_tag": "F24D0",
    "name": "24:00:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 671,
    "2018 NFP": 0,
    "usda_tag": "F24D1C",
    "name": "24:1 c",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 607,
    "2018 NFP": 0,
    "usda_tag": "F4D0",
    "name": "4:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 608,
    "2018 NFP": 0,
    "usda_tag": "F6D0",
    "name": "6:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 609,
    "2018 NFP": 0,
    "usda_tag": "F8D0",
    "name": "8:00",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 645,
    "2018 NFP": 0,
    "usda_tag": "FAMS",
    "name": "Fatty acids, total monounsaturated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 646,
    "2018 NFP": 0,
    "usda_tag": "FAPU",
    "name": "Fatty acids, total polyunsaturated",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 693,
    "2018 NFP": 0,
    "usda_tag": "FATRNM",
    "name": "Fatty acids, total trans-monoenoic",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 695,
    "2018 NFP": 0,
    "usda_tag": "FATRNP",
    "name": "Fatty acids, total trans-polyenoic",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 313,
    "2018 NFP": 0,
    "usda_tag": "FLD",
    "name": "Fluoride, F",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 417,
    "2018 NFP": 0,
    "usda_tag": "FOL",
    "name": "Folate, total",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 431,
    "2018 NFP": 0,
    "usda_tag": "FOLAC",
    "name": "Folic acid",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 435,
    "2018 NFP": 0,
    "usda_tag": "FOLDFE",
    "name": "Folate, DFE",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 432,
    "2018 NFP": 0,
    "usda_tag": "FOLFD",
    "name": "Folate, food",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 212,
    "2018 NFP": 0,
    "usda_tag": "FRUS",
    "name": "Fructose",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 287,
    "2018 NFP": 0,
    "usda_tag": "GALS",
    "name": "Galactose",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 515,
    "2018 NFP": 0,
    "usda_tag": "GLU_G",
    "name": "Glutamic acid",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 211,
    "2018 NFP": 0,
    "usda_tag": "GLUS",
    "name": "Glucose (dextrose)",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 516,
    "2018 NFP": 0,
    "usda_tag": "GLY_G",
    "name": "Glycine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 512,
    "2018 NFP": 0,
    "usda_tag": "HISTN_G",
    "name": "Histidine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 521,
    "2018 NFP": 0,
    "usda_tag": "HYP",
    "name": "Hydroxyproline",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 503,
    "2018 NFP": 0,
    "usda_tag": "ILE_G",
    "name": "Isoleucine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 213,
    "2018 NFP": 0,
    "usda_tag": "LACS",
    "name": "Lactose",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 504,
    "2018 NFP": 0,
    "usda_tag": "LEU_G",
    "name": "Leucine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 338,
    "2018 NFP": 0,
    "usda_tag": "LUT+ZEA",
    "name": "Lutein + zeaxanthin",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 337,
    "2018 NFP": 0,
    "usda_tag": "LYCPN",
    "name": "Lycopene",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 505,
    "2018 NFP": 0,
    "usda_tag": "LYS_G",
    "name": "Lysine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 214,
    "2018 NFP": 0,
    "usda_tag": "MALS",
    "name": "Maltose",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 506,
    "2018 NFP": 0,
    "usda_tag": "MET_G",
    "name": "Methionine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 304,
    "2018 NFP": 0,
    "usda_tag": "MG",
    "name": "Magnesium, Mg",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 428,
    "2018 NFP": 0,
    "usda_tag": "MK4",
    "name": "Menaquinone-4",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 315,
    "2018 NFP": 0,
    "usda_tag": "MN",
    "name": "Manganese, Mn",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 406,
    "2018 NFP": 0,
    "usda_tag": "NIA",
    "name": "Niacin",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 573,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "Vitamin E, added",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_vitamin_e",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 578,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "Vitamin B-12, added",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "nf_vitamin_b12",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 257,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "Adjusted Protein",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 664,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "22:1 t",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 676,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "22:1 c",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 856,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "18:3i",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 665,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "18:2 t not further defined",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 666,
    "2018 NFP": 0,
    "usda_tag": "NULL",
    "name": "18:2 i",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 305,
    "2018 NFP": 0,
    "usda_tag": "P",
    "name": "Phosphorus, P",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 410,
    "2018 NFP": 0,
    "usda_tag": "PANTAC",
    "name": "Pantothenic acid",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 508,
    "2018 NFP": 0,
    "usda_tag": "PHE_G",
    "name": "Phenylalanine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 636,
    "2018 NFP": 0,
    "usda_tag": "PHYSTR",
    "name": "Phytosterols",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 517,
    "2018 NFP": 0,
    "usda_tag": "PRO_G",
    "name": "Proline",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 319,
    "2018 NFP": 0,
    "usda_tag": "RETOL",
    "name": "Retinol",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 405,
    "2018 NFP": 0,
    "usda_tag": "RIBF",
    "name": "Riboflavin",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_riboflavin",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 317,
    "2018 NFP": 0,
    "usda_tag": "SE",
    "name": "Selenium, Se",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "nf_selenium",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 518,
    "2018 NFP": 0,
    "usda_tag": "SER_G",
    "name": "Serine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 641,
    "2018 NFP": 0,
    "usda_tag": "SITSTR",
    "name": "Beta-sitosterol",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 209,
    "2018 NFP": 0,
    "usda_tag": "STARCH",
    "name": "Starch",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_starch",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 638,
    "2018 NFP": 0,
    "usda_tag": "STID7",
    "name": "Stigmasterol",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 210,
    "2018 NFP": 0,
    "usda_tag": "SUCS",
    "name": "Sucrose",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 263,
    "2018 NFP": 0,
    "usda_tag": "THEBRN",
    "name": "Theobromine",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 404,
    "2018 NFP": 0,
    "usda_tag": "THIA",
    "name": "Thiamin",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_thiamin",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 502,
    "2018 NFP": 0,
    "usda_tag": "THR_G",
    "name": "Threonine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 323,
    "2018 NFP": 0,
    "usda_tag": "TOCPHA",
    "name": "Vitamin E (alpha-tocopherol)",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 341,
    "2018 NFP": 0,
    "usda_tag": "TOCPHB",
    "name": "Tocopherol, beta",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 343,
    "2018 NFP": 0,
    "usda_tag": "TOCPHD",
    "name": "Tocopherol, delta",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 342,
    "2018 NFP": 0,
    "usda_tag": "TOCPHG",
    "name": "Tocopherol, gamma",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 501,
    "2018 NFP": 0,
    "usda_tag": "TRP_G",
    "name": "Tryptophan",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 509,
    "2018 NFP": 0,
    "usda_tag": "TYR_G",
    "name": "Tyrosine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 510,
    "2018 NFP": 0,
    "usda_tag": "VAL_G",
    "name": "Valine",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 318,
    "2018 NFP": 0,
    "usda_tag": "VITA_IU",
    "name": "Vitamin A, IU",
    "unit": "IU",
    "notes": "",
    "bulk_csv_field": "nf_vitamin_a",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 320,
    "2018 NFP": 0,
    "usda_tag": "VITA_RAE",
    "name": "Vitamin A, RAE",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 418,
    "2018 NFP": 0,
    "usda_tag": "VITB12",
    "name": "Vitamin B-12",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 415,
    "2018 NFP": 0,
    "usda_tag": "VITB6A",
    "name": "Vitamin B-6",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 401,
    "2018 NFP": 0,
    "usda_tag": "VITC",
    "name": "Vitamin C, total ascorbic acid",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "nf_vitamin_c",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 328,
    "2018 NFP": 0,
    "usda_tag": "VITD",
    "name": "Vitamin D (D2 + D3)",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 430,
    "2018 NFP": 0,
    "usda_tag": "VITK1",
    "name": "Vitamin K (phylloquinone)",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 429,
    "2018 NFP": 0,
    "usda_tag": "VITK1D",
    "name": "Dihydrophylloquinone",
    "unit": "Âµg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 255,
    "2018 NFP": 0,

    "name": "Water",
    "unit": "g",
    "notes": "",
    "bulk_csv_field": "nf_water",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 309,
    "2018 NFP": 0,
    "usda_tag": "ZN",
    "name": "Zinc, Zn",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 344,
    "2018 NFP": "",
    "usda_tag": "TOCTRA",
    "name": "Tocotrienol, alpha",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 345,
    "2018 NFP": "",
    "usda_tag": "TOCTRB",
    "name": "Tocotrienol, beta",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 346,
    "2018 NFP": "",
    "usda_tag": "TOCTRG",
    "name": "Tocotrienol, gamma",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  },
  {
    "attr_id": 347,
    "2018 NFP": "",
    "usda_tag": "TOCTRD",
    "name": "Tocotrienol,delta",
    "unit": "mg",
    "notes": "",
    "bulk_csv_field": "",
    "cxh_attribute_name": "",
    "": ""
  }
]


const labelMap = {
  calories: 'nf_calories',
  totalFat: 'nf_total_fat',
  saturatedFat: 'nf_saturated_fat',
  transFat: 'nf_trans_fatty_acid',
  cholesterol: 'nf_cholesterol',
  sodium: 'nf_sodium',
  totalCarbs: 'nf_total_carbohydrate',
  dietaryFiber: 'nf_dietary_fiber',
  sugars: 'nf_sugars',
  protein: 'nf_protein',
  vitaminA: 'nf_vitamin_a',
  vitaminC: 'nf_vitamin_c',
  vitaminD: 'nf_vitamin_d_mcg',
  vitaminD2: 'nf_vitamin_d2',
  vitaminD3: 'nf_vitamin_d_mcg',
  vitaminE: 'nf_vitamin_e',
  vitaminB12: 'nf_vitamin_b12',
  riboflavin: 'nf_riboflavin',
  thiamin: 'nf_thiamin',
  calcium: 'nf_calcium_mg',
  iron: 'nf_iron',
  selenium: 'nf_selenium',
  zinc: 'nf_zinc',
  starch: 'nf_starch',
  tryptophan: 'nf_tryptophan',
  water: 'nf_water',
}

const attrMap = {
}

const fieldAttrMap = {
}

const reverseLabelMap = {
}

const labelAttrMap = {
}

attrs.forEach(attr => {
  const field = attr.bulk_csv_field
  for (const id in labelMap) {
    if (labelMap[id] == field) {
      reverseLabelMap[field] = id
      fieldAttrMap[field] = attr
      labelAttrMap[id] = attr
      break
    }
  }
  attrMap[attr.attr_id] = attr
})

const PCT_DAILY_VALUES = {
  calcium: 1300,
  dietaryFiber: 28,
  fat: 78,
  magnesium: 420,
  manganese: 2.3,
  vitaminC: 90,
  vitaminD: 20,
  vitaminK: 120,
  sodium: 2300,
  vitaminA: 900,
  vitaminB12: 2.4,
  vitaminE: 15,
  selenium: 55,
  niacin: 16,
  copper: 2,
  iron: 18,
  zinc: 11,
  protein: 50,
  saturatedFat: 20,
}

export const toFDA = foods => {
  const label = {}
  foods.map(food => {
    const nutrition = food.nutrition || food
    const full = nutrition.full_nutrients
    if (full) {
      full.forEach(n => {
        const attr = attrMap[n.attr_id]
        const field = reverseLabelMap[attr.bulk_csv_field]
        if (!field) return
        let value = n.value * (food.count || 1)
        console.log("toFDA", field, value)
        if (!label[field]) {
          label[field] = value
        } else {
          label[field] += value
        }
      })
    }
    for (const id in food) {
      if (id.startsWith('nf_')) {
        const labelId = reverseLabelMap[id]
        if (!label[labelId]) {
          const attr = fieldAttrMap[id]
          const value = (food[id] || nutrition[id]) * food.count
          if (!label[labelId]) {
            label[labelId] = value
          } else {
            label[labelId] += value
          }
        }
      }
    }
  })
  for (const id in label) {
    let value = label[id]
    const attr = labelAttrMap[id]
    if (!attr) {
      console.warn("not found:", id)
      continue
    }
    if (attr.unit == 'mg') {
      //value /= 1000
    }
    value = Math.round(value)
    label[id] = value
  }
  for (const id in labelMap) {
    if (!label[id]) {
      label[id] = 0
    }
  }
  console.log("toFDA", foods, label)
  return label
}
