import React, { Component } from 'react';
import { LBGraphic } from '../lbGraphic';
import './index.css';

export class LBDetail extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='letsDetail'>
        <div className='letsFill'>
          <LBGraphic />
        </div>
      </div>
    );
  }
}
