import React from 'react';
import {InputField} from "./InputField";
import "./PaymentMethod.css";

import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <label>
      Card details
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
};

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
            name: props.name,
            email: props.email
        },
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <button disabled={!stripe}>Confirm order</button>
    </form>
  );
}


export class UserPaymentMethod extends React.Component {
    onChange=(field, value) => {
        const update = {};
        update[field] = value;
        this.setState(update);
    }
    render() {
        return <div className='paymentMethod'>
            <form className='paymentMethodDetails'>
            <InputField label={"Name on Card"} name="ccname" id="frmNameCC" autoComplete="cc-name" onChange={this.onChange}/>
            <InputField label={"Card Number"} name="cardnumber" id="frmCCNum" required autoComplete="cc-number" onChange={this.onChange}/>
            <InputField label={"CVC"} name="cvc" id="frmCCCVC" required autoComplete="cc-csc" onChange={this.onChange}/>
            <InputField label={"MM-YYYY"} name="cc-exp" id="frmCCExp" required  autoComplete="cc-exp" onChange={this.onChange}/>
            </form>
            </div>
    }
}
