import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import './index.css'

export class FDUserProfileIcon extends Component {
  render () {
    const className = 'fdUserProfileIcon'
    return (
      <div className={className}>
        <div className='fdUserProfileIconIcon'>
          {this.props.user.profileImage && <img src={this.props.user.profileImage} />}
        </div>
      </div>
    )
  }
}
