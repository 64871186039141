import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Header, Nutrients, Vitamins } from '../index';

export const styles = {
  root: {
    display: 'flex',
    flexFlow: 'column',
    padding: 8,
    backgroundColor: 'white',
    border: '1px solid black',
    fontFamily: 'Libre Franklin, sans-serif'
  },
  attribute: {
    fontWeight: 800
  },
  italic: {
    fontStyle: 'italic'
  }
};
const PCT_DAILY_VALUES = {
  calcium: 1300,
  dietaryFiber: 28,
  fat: 78,
  magnesium: 420,
  manganese: 2.3,
  vitaminC: 90,
  vitaminD: 20,
  vitaminK: 120,
  sodium: 2300,
  vitaminA: 900,
  vitaminB12: 2.4,
  vitaminE: 15,
  selenium: 55,
  niacin: 16,
  copper: 2,
  iron: 18,
  zinc: 11,
  protein: 50,
  saturatedFat: 20,
}

class NutritionLabel extends React.Component {
  render() {
    const {
      classes,
      servingSize,
      servingsPerContainer,
      calories,
      totalFat,
      saturatedFat,
      transFat,
      cholesterol,
      sodium,
      totalCarbs,
      dietaryFiber,
      sugars,
      protein
    } = this.props;

    let {
      vitaminA,
      vitaminB12,
      vitaminC,
      vitaminD,
      vitaminE,
      riboflavin,
      calcium,
      iron,
      zinc,
      water
    } = this.props

    vitaminA /= PCT_DAILY_VALUES.vitaminA
    vitaminC /= PCT_DAILY_VALUES.vitaminC
    calcium /= PCT_DAILY_VALUES.calcium
    iron /= PCT_DAILY_VALUES.iron

    vitaminA = Math.round(vitaminA * 100)
    vitaminC = Math.round(vitaminC * 100)
    calcium = Math.round(calcium * 100)
    iron = Math.round(iron * 100)

    return (
      <div className={classes.root}>
        <Header servingSize={servingSize} servingsPerContainer={servingsPerContainer} />

        <Nutrients calories={calories} caloriesFromFat={totalFat * 9}>
          <ul>
            <li>
              <b className={classes.attribute}>Total Fat</b> {totalFat}g
              <ul>
                <li>Saturated Fat {saturatedFat}g</li>
                <li>Trans Fat {transFat}g</li>
              </ul>
            </li>
            <li>
              <b className={classes.attribute}>Cholesterol</b> {cholesterol}mg
            </li>
            <li>
              <b className={classes.attribute}>Sodium</b> {sodium}mg
            </li>
            <li>
              <b className={classes.attribute}>Total Carbohydrates</b> {totalCarbs}g
              <ul>
                <li>Dietary Fiber {dietaryFiber}g</li>
                <li>Sugars {sugars}g</li>
              </ul>
            </li>
            <li>
              <b className={classes.attribute}>Protein</b> {protein}g
            </li>
        <li style={{display: 'none'}}>
        <b className={classes.attribute}>Water</b> {Math.round(water*0.033824)} fl oz.
            </li>
          </ul>
        </Nutrients>
        <Vitamins
      collection={[
        /*
            [`Vitamin A ${vitaminA}%`, `Vitamin B12 ${vitaminB12}%`],
            
            [`Vitamin C ${vitaminC}%`, `Vitamin D ${vitaminD}%`],
            [`Vitamin E ${vitaminE}%`, `Riboflavin ${riboflavin}%`],
            [`Calcium ${calcium}%`, `Iron ${iron}%`],
            [`Selenium ${selenium}%`, `Zinc ${zinc}%`]
        */
            [`Vitamin A ${vitaminA}%`, `Vitamin C ${vitaminC}%`],
            [`Calcium ${calcium}%`, `Iron ${iron}%`]
          ]}
        />
      </div>
    );
  }
}

NutritionLabel.propTypes = {
  servingSize: PropTypes.string,
  servingsPerContainer: PropTypes.number,
  calories: PropTypes.number,
  totalFat: PropTypes.number,
  saturatedFat: PropTypes.number,
  transFat: PropTypes.number,
  cholesterol: PropTypes.number,
  sodium: PropTypes.number,
  totalCarbs: PropTypes.number,
  dietaryFiber: PropTypes.number,
  sugars: PropTypes.number,
  protein: PropTypes.number,
  vitaminA: PropTypes.number,
  vitaminC: PropTypes.number,
  calcium: PropTypes.number,
  iron: PropTypes.number
};

export default injectSheet(styles)(NutritionLabel);
