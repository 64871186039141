/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { SignIn } from '../SignIn'
import { FDPage } from '../Page'
import { Main } from '../Main'
import Splash from '../../../../assets/Assets/splash.png'
import SpinnerShape from "../../../../assets/icons/SpinnerShape.svg";
import { isAndroid, isMobile } from '../../classes/Platform.js'
import {UIWebsite} from "../../../../components/Website";
import {parsePhoneNumber} from '../../../../components/Settings'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import './index.css'

const delay = secs => new Promise(resolve => setTimeout(resolve, secs * 1000))

mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light'
}

let portalRef

export const getPortal = () => {
  if (window.getPortalRef) return window.getPortalRef()
  if (portalRef) return portalRef
  return null
}

let _showBusy
export const showBusy = busy => {
  if (_showBusy) _showBusy(busy)
}

export class MobileClient extends Component {
  constructor (props) {
    super(props)
    this.connectedDeviceList = []
    _showBusy = this.showBusy
    this.state = {}
  }

  showBusy = busy => {
    this.setState({
      busy: busy
    })
  }


  createAccount = () => {
    this.props.signInError.field = ''
    this.props.signInError.err = ''
  }

  signIn = () => {
    this.props.signInError.field = ''
    this.props.signInError.err = ''
  }

  cancelSignIn = () => {
    this.setState({
      needsSignIn: false,
      needsSignUp: false
    })
  }

  componentDidMount() {
    this.props.me.nativeInit()
    const sub = this.props.me.observeCreds().subscribe(creds => {
      this.props.me.nativeLog('got creds: '+ creds.email + ","+creds.password)
      this.props.onChange('email', creds.email)
      this.props.onChange('password', creds.password)
      //alert('creds: ' + JSON.stringify(creds))
      if (creds.phoneNumber) {
        const { countryCode, phoneNumber} = parsePhoneNumber(creds.phoneNumber)
        if (phoneNumber) {
          this.props.onChange('phoneNumber', phoneNumber)
        }
        if (countryCode) {
          this.props.onChange('countryCode', countryCode)
        }
      }
    })
    this.props.me.observeSelf().subscribe(self => {
      if (self) {
        this.setState({
          self: self
        })
      } else {
        this.setState({
          self: null
        })
      }
    })
    window.addEventListener('resize', () => this.forceUpdate())
  }

  signOut = () => {
    this.props.me.signOut()
  }

  signIn = async (form) => {
    return await this.props.signIn(form)
  }

  signUp = async (form) => {
    return await this.props.signUp(form)
  }

  renderView () {
    let result
    let popup
    let website
    if (this.props.me.self) {
      result = <Main
      contactsUnread={this.props.contactsUnread}
      systemUnread={this.props.systemUnread}
      navigate={this.props.navigate}
      nav={this.props.nav}
      meNav={this.props.meNav}
      answerCall={this.props.answerCall}
      openTodoList={this.props.openTodoList}
      suggestRecordMeal={this.props.suggestRecordMeal}
      openEvent={this.props.openEvent}
      openChat={this.props.openChat}
      openContact={this.props.openContact}
      me={this.props.me}
      renderHome={this.props.renderHome}
      renderSettings={this.props.renderSettings}
      renderCalendar={this.props.renderCalendar}
      renderSidebar={this.props.renderSidebar}
      renderOpenContacts={this.props.renderOpenContacts}/>
    } else {
      website = this.props.renderWebsite()
      result = website
      let type
      if (this.props.needsSignUp) {
        type = 'sign-up'
      } else if (this.props.needsSignIn) {
        type = 'sign-in'
      }
      if (type && portalRef) {
        popup = <SignIn signInWithPhoneNumber={this.props.signInWithPhoneNumber} signUp={this.signUp} signIn={this.signIn} clearForm={this.props.clearForm} type={type} cancel={this.props.cancelSignIn} onChange={this.props.onChange} form={this.props.form} signInError={this.props.signInError} me={this.props.me} />
      }
    }
    return (
        <FDPage safeArea={!website && popup} me={this.props.me} noHeader popup={popup}>
        {result}
      </FDPage>
    )
  }

  setPortalRef = ref => {
    if (ref) {
      portalRef = ref
      this.forceUpdate()
    }
  }

  render () {
    let style
    let className = 'fdFastDetectClient'
    if (!isMobile()) {
      let width
      if (window.innerWidth > window.innerHeight) {
        width = 700
      } else {
        width = 600
      }
      const left = (window.innerWidth - width) / 2
      style = {
        left: left,
        width: width
      }
      className += ' fdFastDetectClientIPad'
    }
    if (window.ReactNativeWebView) {
      className += ' fdFastDetectClientNative'
    }
    if (!style) {
      style = {}
    }
    style.backgroundImage = `url(${Splash})`
    return (
        <div ref={this.setPortalRef} style={style} className={className} >
        <div className='spinner-preloader' style={{display: 'none'}}><ReactSVG src={SpinnerShape}/></div>
        {this.renderView()}
      {this.state.busy && <div className='fdBusy' />}
      </div>
    )
  }
}
