import React, {useState, useEffect, Component } from 'react'
import {ReactSVG} from 'react-svg'
import {UIProfileIcon} from '../ProfileIcon'
import { LBWorkoutCard } from '../WorkoutCard'
import { LBTodoCard } from '../TodoCard'
import Bubble from '../../assets/icons/ChatSpace.svg'
import moment from 'moment'
import './index.css'
import { DragPreviewImage, useDrag } from 'react-dnd'

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

const hashCode = function (s) {
  let h = 0, l = s.length, i = 0
  if ( l > 0 )
    while (i < l)
      h = (h << 5) - h + s.charCodeAt(i++) | 0
  return hash_32b_to_16b(h)
}

function hash_32b_to_16b (val32b) {
  let rightBits = val32b & 0xffff // Left-most 16 bits
  let leftBits = val32b & 0xffff0000 // Right-most 16 bits

  leftBits = leftBits >>> 16 // Shift the left-most 16 bits to a 16-bit value

  return rightBits ^ leftBits // XOR the left-most and right-most bits
}

export const formatStartEndTime = (start, end) => moment(start).format('h:mm A') + (end && end.getTime() !== start.getTime() ? ' - '+ moment(end).format('h:mm A') : '')
export const formatDate = start => moment(start).format('MMMM Do YYYY')

export const renderPaymentStatus = (paymentStatus) => {
  let paymentStatusLabel = 'Payment Requested'
  switch (paymentStatus) {
    case 'requires_payment_method':
    case 'requires_confirmation':
    case 'requires_action':
      paymentStatusLabel = 'Payment Requested'
      break
    case 'processing':
      paymentStatusLabel = 'Payment Processing'
      break
    case 'canceled':
      paymentStatusLabel = 'Payment Canceled'
      break
    case 'succeeded':
      paymentStatusLabel = 'Payment Complete'
      break
    case 'refunded':
      paymentStatusLabel = 'Payment Refunded'
      break
  }
  return paymentStatusLabel
}

class AppointmentTimeComp extends Component {
  constructor (props) {
    super(props)
  }

  componentDidMount () {
    if (this.props.end) {
      const now = Date.now()
      const end = this.props.end.getTime()
      if (end > now) {
        const until = end - now
        if (until < 2147483647) {
          this.timeout = setTimeout(this.props.onEnded, until)
        }
      }
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  render () {    
    const props = this.props
    if (!props.start) {
      return <div className='uiAppointmentInfoStartEndTime'>
       Ready to start
      </div>
    }
    return <div className='uiAppointmentInfoStartEndTime'>
      {formatStartEndTime(props.start, props.end)}
    </div>
  }
}

export const UIAppointment = props => { 
  const [collectedProps, drag] = useDrag({
    item: { id: props.id || '', type: 'appointment' || '', appointment: props.appointment},
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        debugLog('You dropped ', item, ' into ', dropResult)
      }
    }
  })

  if (props.appt && props.appt.workout && props.appt.workout.trainer) {
    return <LBWorkoutCard isClient={props.appt.isClient} waitForSystemUpdate={props.waitForSystemUpdate} edit={props.onClick} workout={props.appt.workout} me={props.me} inactive={props.inactive}/>
  }

  if (props.appt && props.appt.todo) {
    return <LBTodoCard  onClick={() => {}} waitForSystemUpdate={props.waitForSystemUpdate} edit={() => props.onClick()} todo={props.appt.todo} me={props.me} inactive={props.inactive}/>
  }

  const now = Date.now()
  let className='uiAppointment'
  let isPast = false
  if (props.end && props.end.getTime() < now) {
    className += ' uiAppointmentPast'
    isPast = true
  } 
  let indicator =''
  let indicatorStyle
  if (props.appt && (props.appt.sleep || props.appt.workout || props.appt.meal || props.appt.weight)) {
    className += ' uiAppointmentWhoopActivity'
    if (props.appt.sleep && !props.appt.sleep.isNap) {
      const score = props.appt.sleep.score
      if (score) {
        if (score < 70) {
          indicator = ' uiAppointmentIndicatorDeclined'
        } else if (score >= 90) {
          indicator = ' uiAppointmentIndicatorAccepted'
        } else {
          indicator = ' uiAppointmentIndicatorInWaiting'
        }
      }
    }
    if (props.appt.meal) {
      className += ' uiAppointmentMeal'
      let protein = 0
      let fat = 0
      let carbs = 0
      const meal = props.appt.meal
      meal.foods.forEach(food => {
        if (food.nutrition) {
          protein += food.nutrition.nf_protein
          fat += food.nutrition.nf_total_fat
          carbs += food.nutrition.nf_total_carbohydrate
        }
      })
      const total = carbs + protein + fat
      if (total) {
        protein = ((fat + protein) / total * 100) + '%'
        fat = (fat / total * 100) + '%'
        indicatorStyle = {
          backgroundImage: `linear-gradient(0.5turn, #008178 0 ${fat}, #001A9A ${fat} ${protein}, #9200A7 ${protein} 100%)`
        }
        debugLog('indicatorStyle', indicatorStyle)
      }
    }
    if (props.appt.workout) {
      indicator = ' uiAppointmentIndicatorWorkout'
      const workout = props.appt.workout
      console.log("appt organizer", props.appt.organizer)
      const maxHeartRate = props.me.getMaxHeartRate(props.appt.organizer.uid) || workout.maxHeartRate
      const avgHeartRate = workout.averageHeartRate
      const exertion = avgHeartRate / maxHeartRate
      if (exertion >= 0.9) {
        indicator = ' uiExertionMax'
      } else if (exertion >= 0.8) {
        indicator = ' uiExertionHard'
      } else if (exertion >= 0.7) {
        indicator = ' uiExertionModerate'
      } else if (exertion >= 0.6) {
        indicator = ' uiExertionLight'
      } else if (exertion >= 0.5) {
        indicator = ' uiExertionVeryLight'
      } else {
        indicator = ' uiExertionRest'
      }
    }
  } else {
    if (props.status == 'accepted' && (props.invoiceAmount == 0 || props.paymentStatus == 'succeeded')) {
      indicator = ' uiAppointmentIndicatorAccepted'
    } else if (props.status == 'declined') {
      indicator = ' uiAppointmentIndicatorDeclined'
    } else if (props.status != 'canceled') {
      indicator = ' uiAppointmentIndicatorInWaiting'
    }
  }
  if (!props.editable) {
    className += ' uiAppointmentClient'
  }
  if (props.invoiceAmount || props.paymentAmount) {
    className += ' uiAppointmentWithPayment'
  }
  if (props.isChat) {
    className += ' uiAppointmentChat'
  }

  if (!props.onClick) {
    className += ' uiAppointmentNoClick'
  }
  
  let error
  if (props.paymentError) {
    error = props.paymentError
      .replace('Your card number', 'Card number')
      .replace('Your postal code', 'Zip code')
      .replace('Your card\'s security code', 'CVC')
      .replace('Your card\'s expiration', 'Expiration')
      .replace('is ', '')
      .replace(/[.]/, '')
  } else {
    if (props.status == 'canceled') {
      error = 'Canceled'
    } else if (props.status == 'refunded') {
      error = 'Refunded'
    }
  }
  let date
  if (props.appt) {
    if (props.appt.sleep) {
      date = props.end
    } else if (props.start) {
      date = props.start
    } else {
      date = new Date(props.appt.workout.scheduled)
    }
  }
  let appointmentTime = <AppointmentTimeComp start={props.start} end={props.end} onEnded={props.onEnded}/>;
  if (props.appt.workout) {
    switch (props.appt.workout.status) {
      case 'declined':
      case 'canceled':
        appointmentTime = <div className='uiAppointmentTimeCanceled'>Canceled</div>;
    }
  }
  if (!props.id) {
    debugger
  }
  const id = 'A-'+hashCode(props.id)
  const invoiceAmount = props.paymentAmount && (props.status == 'refunded' || props.paymentStatus == 'succeeded') ? props.paymentAmount : props.invoiceAmount
  return <div draggable={props.editable} key={props.id} className={className} onClick={props.onClick} ref={!props.editable ? null: drag}>
    <div className='uiAppointmentInfoId'>
    {id}
  </div>
    <div className={'uiAppointmentIndicator' + indicator} style={indicatorStyle}/>
    <div className='uiAppointmentInfo'>
    {props.appt && props.appt.workout && props.appt.workout.sets ?
        <div className='uiAppointmentInfoStartEndTime'>
        {props.appt.workout.sets + " Sets of "+props.appt.workout.reps + " Reps"}
     </div>
     :<div className='uiAppointmentInfoOrganizer'>
    {props.organizer.displayName} <span className='uiAppointmentOrganizerCreds'>{props.organizer.creds}</span>
     </div>}
    <div className='uiAppointmentInfoTitle'>
    {props.title}
  </div>
    {appointmentTime}
    <div className={'uiAppointmentInfoDate' + (isPast ? ' uiAppointmentInfoDatePast' : '')}>
    {formatDate(date)}
  </div>
    {invoiceAmount ? <div className='uiAppointmentPaymentInfo'>
     {error ?  <div className='uiAppointmentStatusCanceled'>
      {error}
      </div>
      :
      <div className='uiAppointmentPaymentInfoStatus'>
      {renderPaymentStatus(props.paymentStatus)}
      </div>}
     <div className={'uiAppointmentPaymentInfoAmount' + (props.paymentStatus == 'refunded' ? ' uiAppointmentPaymentInfoAmountNegative': '')}>
     ${invoiceAmount.toFixed(2)}
     </div>
     </div> : null
    }
  </div>
    {props.with && !props.hideWith && <div className='uiAppointmentWith'>
     <div className='uiAppointmentWithIcon'>
     <UIProfileIcon radius={20} contact={props.with}/>
     </div>
     <div className='uiAppointmentWithName'>
     {props.with.displayName} <span className='uiAppointmentWithCreds'>{props.with.creds}</span>
     </div>
     <div className='uiAppointmentWithChat'><ReactSVG src={Bubble}/></div>
     </div>}
  </div>
}



