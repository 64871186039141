/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import Error from '../../assets/Icons/Error.svg'
import { FDPopup } from '../Popup'
import { FDButton2 } from '../Button'
import { UIInputField } from "../../../../components/Home"
import {UIOKCancel} from "../../../../components/OKCancel";
import Phone from "../../../../assets/icons/EndCall.svg";
import Lock from "../../../../assets/icons/Padlock.svg";
import Close from "../../../../assets/icons/Cross.svg";
import Profile from "../../../../assets/icons/Profile.svg";
import Email from "../../../../assets/icons/Email.svg";
import Password from "../../../../assets/icons/Padlock.svg";
import Edit from "../../../../assets/icons/ChatEdit.svg";
import RightArrow from "../../../../assets/icons/Forward.svg";
import './index.css'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
const owasp = require('owasp-password-strength-test')

const isDev = true
const debugLog = (...args) => {
  //console.log.apply(null, args)
}

export class SignIn extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errors: {
        field: '',
        message: ''
      },
      formErr: {
      },
      items: [
        {
          name: 'Location',
          children: [
            {
              name: 'Mayo Clinic'
            },
            {
              name: 'Kaiser Permanente'
            }]
        }
      ]
    }
  }

    onChange = (field, value) => {
      const err = this.state.errors
      debugLog('err: ', err)
      if (err.message && (field === err.field || err.field === 'other')) {
        this.setState({
          errors: {}
        })
      }
      this.props.onChange(field, value)
    }

  signUp = () => {
    const form = this.props.form
    return this.props.signUp(form).catch(err => {
      debugger
    })
  }

  signUpOld = () => {
    const self = this
      const form = this.props.form
      const err = this.state.errors
      err.field = ''
      err.message = ''
    err.err = ''
    console.log('form', form)
      if (!form.name) {
        err.field = 'name'
        err.message = 'Display Name is required.'
      } else if (!form.email) {
        err.field = 'email'
        err.message = 'Email Address is required'
      } else if (!form.password) {
        err.field = 'password'
        err.message = 'Password is required'
      } else {
        const result = owasp.test(form.password)
        if (!isDev && result.errors.length) {
          err.field = 'password'
          err.message = result.errors[0]
        }
      }
      err.err = err.message
      this.forceUpdate()
      if (err.field) {
        return Promise.resolve()
      }
      return this.props.signUp(form.email, form.password, form.name, form.pinCode).then(() => {
        this.props.clearForm()
      }).catch(err => {
        debugLog(err)
        if (err.code === 'auth/network-request-failed') {
          return this.setState({
            errors: {
              field: 'other',
              message: 'A network error occurred'
            }
          })
        }
        if (err.code === 'auth/email-already-in-use') {
          return this.setState({
            errors: {
              field: 'email',
              message: err.message
            }
          })
        }
        return Promise.reject(err)
      })
    }

  signIn = () => {
    const form = this.props.form
    return this.props.signIn(form).catch(err => {
      console.error(err)
    })
  }

  signInWithPhoneNumber = () => {
    const form = this.props.form
    return this.props.signInWithPhoneNumber(form).catch(err => {
      console.error(err)
    })
  }
  
  signInOld = () => {
      const form = this.props.form
      const err = this.state.errors
      err.field = ''
      err.message = ''
      if (!form.email) {
        err.field = 'email'
        err.message = 'Email Address is required'
      } else if (!form.password) {
        err.field = 'password'
        err.message = 'Password is required'
      }
      if (err.field) {
        err.err = err.message
        this.forceUpdate()
        return Promise.resolve()
      }
      return this.props.signIn(form).catch(err => {
        console.error(err)
        let field = ''
        let message = err.message || ''
        if (err.code === 'auth/network-request-failed') {
          return this.setState({
            errors: {
              field: 'other',
              message: 'A network error occurred'
            }
          })
        }
        if (err.code === 'auth/user-not-found') {
          field = 'email'
          message = 'No user with that email'
        } else if (err.code === 'auth/invalid-email') {
          field = 'email'
          message = 'Email Address is invalid.'
        }
        if (err.code === 'auth/wrong-password') {
          field = 'password'
          message = 'Password is invalid.'
        }
        if (err.code === 'auth/too-many-requests') {
          field = 'password'
        }
        this.setState({
          errors: {
            field: field,
            message: message,
            err: message
          }
        })
      })
    }

    onChangeEmail = e => {
      this.onChange('email', e.target.value)
    }

    onChangePassword = e => {
      this.onChange('password', e.target.value)
    }

    onChangeName = e => {
      this.onChange('name', e.target.value)
    }

    onChangePinCode = e => {
      this.onChange('pinCode', e.target.value)
    }

    render () {
      switch (this.props.type) {
        case 'sign-in':
          return this.renderSignIn()
        case 'sign-up':
          return this.renderSignUp()
      }
    }

  doSignUp = () => {
    return this.props.signUp(this.props.form)
  }

  renderSignUpPage1() {
    const form = this.props.form
    let action = this.doSignUp
    let fields = [{
        name: 'phoneNumber', label: 'Phone Number', type: 'tel', icon: Phone, countryCode: form.countryCode
    }]    
    if (form.onVerificationCodeInput) {
      fields.push({
        name: 'verificationCode', label: "Verification Code", icon: Lock,
        autoComplete: 'one-time-code'
      })
    }
    return this.renderUI({
      title:"Sign Up",
      icon: Profile,
      back: this.props.cancel,
      fields: fields,      
      okLabel: "Continue",
      okIcon: RightArrow,
      okAction: action
    })
  }


  renderSignUp () {
    const form = this.props.form
    console.log('form', form)
    let needsPhoneNumber = !this.props.form.user;
    if (needsPhoneNumber) {
      return this.renderSignUpPage1()
    }
    if (true) return this.renderUI({
      title:"Sign Up",
      icon: Profile,
      back: this.props.cancel,
      fields:
      [{
        name: 'phoneNumber', label: 'Phone Number', type: 'tel', icon: Phone, countryCode: form.countryCode
      },
      {
        name: 'name', label: 'Display Name', type: 'text', icon: Edit,
      },
       {
        name: 'email', label: 'Email Address', type: 'email', icon: Email,
      },
       {
         name: 'password', label: 'Password', type: 'password', icon: Password
       }],
      okLabel: "Continue",
      okIcon: RightArrow,
      okAction: this.doSignUp
    });

      const err = this.state.errors
      const nameErr = err.field === 'name' ? err.message : null
      const emailErr = err.field === 'email' ? err.message : null
      const passwordErr = err.field === 'password' ? err.message : null
      const pinCodeErr = err.field === 'pinCode' ? err.message : null
      const other = err.field === 'other' ? err.message : null
      return (
       <FDPopup me={this.props.me} cancel={this.props.cancel} title='Sign Up' done={this.signUp}>
          <mobiscroll.Form theme='ios' themeVariant='light'>
            <mobiscroll.FormGroup>
              <mobiscroll.Input valid={!nameErr} errorMessage={nameErr} value={form.name} onChange={this.onChangeName} placeholder='Name' />
              <mobiscroll.Input valid={!emailErr} errorMessage={emailErr} value={form.email} onChange={this.onChangeEmail} placeholder='Email Address' />
              <mobiscroll.Input valid={!passwordErr} errorMessage={passwordErr} type='password' value={form.password} onChange={this.onChangePassword} placeholder='Password' />
              {false && <mobiscroll.Input valid={!pinCodeErr} errorMessage={pinCodeErr} type='number' min='0' max='999999' value={form.pinCode} onChange={this.onChangePinCode} placeholder='Pin Code' />}
            </mobiscroll.FormGroup>
            {other && <div className='fdOtherErr'>
              <ReactSVG src={Error} />
              {other}
                      </div>}
          </mobiscroll.Form>
        </FDPopup>
      )
    }

    resetPassword = () => {
      const form = this.props.form
      if (!form.email) {
        this.state.passwordResetMessage = ''
        const err = this.state.errors
        err.field = 'email'
        err.message = 'Email Address is required'
        return this.forceUpdate()
      }
      form.password = ''
      debugger
      try {
        this.props.me.resetPassword(form.email).then(() => {
          this.state.passwordResetMessage = 'A password reset email has been sent to your email address'
          this.forceUpdate()
        }).catch(err => {
          if (err.code === 'auth/invalid-email') {
            const err = this.state.errors
            err.field = 'email'
            err.message = 'Invalid email address'
            return this.forceUpdate()
          }
          if (err.code === 'auth/network-request-failed') {
            return this.setState({
              errors: {
                field: 'other',
                message: 'A network error occurred'
              }
            })
          }
        })
      } catch (err) {
        console.error(err)
        if (err.code === 'auth/argument-error') {
          const err = this.state.errors
          err.field = 'email'
          err.message = 'Invalid email address'
          return this.forceUpdate()
        }
      }
    }

  toggleEmailSignIn = () => {
    this.setState({
      emailSignIn: !this.state.emailSignIn
    })
  }

  renderSignIn () {
    const link =  {
      label: this.state.emailSignIn ? "Sign in with phone number": "Sign in with email address",
      action: this.toggleEmailSignIn
    }
    if (this.state.emailSignIn) {
      return this.renderUI({
      title:"Sign In",
      icon: Profile,
      back: this.props.cancel,
      fields:
      [{
        name: 'email', label: 'Email Address', type: 'email', icon: Email,
      },
       {
         name: 'password', label: 'Password', type: 'password', icon: Password
       }],
      okLabel: "Continue",
      okIcon: RightArrow,
        okAction: this.signIn,
        link: link
      })
    } else {
      const form = this.props.form
      let fields = [{
        name: 'phoneNumber', label: 'Phone Number', type: 'tel', icon: Phone, countryCode: form.countryCode
      }]    
      if (form.onVerificationCodeInput) {
        fields.push({
          name: 'verificationCode', label: "Verification Code", icon: Lock,
          autoComplete: 'one-time-code'
        })
      }
      return this.renderUI({
        title:"Sign In",
        icon: Profile,
        back: this.props.cancel,
        fields: fields,
        okLabel: "Continue",
        okIcon: RightArrow,
        okAction: this.signInWithPhoneNumber,
        link: link
      })
    }
      const form = this.props.form
      const err = this.state.errors
      const emailErr = err.field === 'email' ? err.message : null
      const passwordErr = err.field === 'password' ? err.message : null
      const other = err.field === 'other' ? err.message : null
      return (
        <FDPopup cancel={this.props.cancel} title='Sign In' done={this.signIn}>
          <mobiscroll.Form theme='ios' themeVariant='light'>
            <mobiscroll.FormGroup>
              <mobiscroll.Input valid={!emailErr} errorMessage={emailErr} value={form.email} onChange={this.onChangeEmail} placeholder='Email Address' />
              <mobiscroll.Input valid={!passwordErr} errorMessage={passwordErr} type='password' value={form.password} onChange={this.onChangePassword} placeholder='Password' />

            </mobiscroll.FormGroup>
            <mobiscroll.FormGroup>
              {other && <div className='fdOtherErr'>
                <ReactSVG src={Error} />
                {other}
              </div>}
              <FDButton2 label='Forgot Password' onClick={this.resetPassword} />
              {this.state.passwordResetMessage && <mobiscroll.FormGroupTitle>{this.state.passwordResetMessage}</mobiscroll.FormGroupTitle>}
            </mobiscroll.FormGroup>
          </mobiscroll.Form>
        </FDPopup>
      )
    }


  renderUI (opts) {
    const {
      title, icon, back, fields, okIcon, okLabel, okAction, link
    } = opts
    const onChange = (field, value) => {
      this.props.onChange(field, value)
    }
    let err = this.props.signInError.err

    return <FDPopup me={this.props.me} noHeader={true}>
    <div className={'uiScheduleAppointment uiSignIn'}>
      <div className='uiScheduleAppointmentHeader'>
      <div className='uiScheduleAppointmentHeaderClose' onClick={back}>
      <ReactSVG src={Close}/>
      </div>

      <div className='uiScheduleAppointmentHeaderInfo'>
      <div className='uiScheduleAppointmentHeaderIcon'>
      <ReactSVG src={icon}/>
      </div>
      <div className='uiScheduleAppointmentHeaderTitle'>
      {title}
    </div>
      </div>
      </div>
      <div className='uiScheduleAppointmentBody'>
      <div className='uiScheduleAppointmentBodyFields'>
      {fields.map(field => {
        const { name, label, icon, type, countryCode, autoComplete } = field
        return <div className='uiScheduleAppointmentBodyField'>
          <UIInputField countryCode={countryCode} error={this.props.signInError} label={label} value={this.props.form[name]} icon={icon} name={name} onChange={onChange} type={type} autoComplete/>
          </div>
      })}
       <div className='uiScheduleAppointmentBodyFieldsError'>
       {err}
       </div>
       <div className='uiScheduleAppointmentBodyField'>
       <UIOKCancel okIcon={okIcon} label={okLabel} ok={okAction}/>
      </div>
      {link && <div className='uiSignInLink' onClick={link.action}>{link.label}</div>}
      </div>
      </div>
      </div>
      </FDPopup>
  }
}
