import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UIInputField} from "../Home";
import {UIOKCancel} from "../OKCancel";
import {FDPopup} from '../../Mobile/src/components/Popup'
import {FDPage} from '../../Mobile/src/components/Page'
import {ProfilePictureInput} from '../Sidebar'
import {UIProfileIcon} from '../ProfileIcon'
import {UISelectionList} from "../DeviceSetup";
import {Contact} from '../../Contact.js'
import Stripe from "../../assets/icons/StripeS.svg";
import {UIChatTodoListSubscription, UIChat} from "../Chat";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Check from "../../assets/icons/CheckMark.svg";
import Lock from "../../assets/icons/Padlock.svg";
import Email from "../../assets/icons/Email.svg";
import LetsBuild from "../../assets/icons/LB_Logo512.svg";
import User from "../../assets/icons/ProfileSml.svg";
import Phone from "../../assets/icons/EndCall.svg";
import Cross from "../../assets/icons/Cross.svg";
import CheckBox from "../../assets/icons/Checkbox.svg";
import CheckBoxOn from "../../assets/icons/CheckboxOn.svg";
import Camera from "../../assets/icons/VideoOn.svg";
import Link from "../../assets/icons/Link.svg";
import Save from "../../assets/icons/Save.svg";
import SignOut from "../../assets/icons/SignOut.svg";
import Bell from "../../assets/icons/Bell.svg";
import ScaleIcon from '../../assets/icons/Scales.svg'
import {UIDeviceSetup} from "../DeviceSetup";
import {UIMeConnectToStripe, UIMeCard} from '../Me'
import WhoopIcon from '../../assets/icons/Whoop.svg'
import FitbitIcon from '../../assets/icons/FitbitIcon.png'
import GarminLogo from '../../assets/icons/Garmin.svg'
import WhoopLogo from '../../assets/icons/WhoopLogo.svg'
import OuraIcon from '../../assets/icons/Oura.svg'
//import {UICreditCardInput} from '../ScheduleAppointment'
import validator from 'validator';
import {Whoop} from '../../classes/Whoop'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import { isMobile } from '../../Platform.js';
import {Elements, ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import phone from 'phone';
import googleLibPhoneNumber from 'google-libphonenumber'
import owasp from "owasp-password-strength-test";
import { Subject } from 'rxjs'
import './index.css'
const PNF = googleLibPhoneNumber.PhoneNumberFormat;
const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();

const debugLog = (...args) => {
  console.log.apply(null, args)
}

const whoop = new Whoop()

const degrees = [
  {value: "BSKin", name: "Bachelor of Science in Kinesiology"},
  {value: "DO", name: "Doctor of Osteopathy"},
  {value: "DrPH", name: "Doctor of Public Health"},
  {value: "EdD", name: "Doctor of Education"},
  {value: "EdM", name: "Master of Education"},
  {value: "MA", name: "Master of Arts"},
  {value: "MD", name: "Doctor of Medicine"},
  {value: "MEd", name: "Master of Education"},
  {value: "MHDL", name: "Master of Human Development & Learning"},
  {value: "MPH", name: "Master of Public Health"},
  {value: "MPS", name: "Master of Professional Studies"},
  {value: "MS", name: "Master of Science"},
  {value: "MSc", name: "Master of Science"},
  {value: "MSEd", name: "Master of Science in Education"},
  {value: "MSKin", name: "Master of Science in Kinesiology"},
  {value: "PhD", name: "Doctor of Philosophy"},
  {value: "ScD", name: "Doctor of Science"},
  {value: "BEXSc", name: "Bachelor of Exercise Science"},
  {value: "MEXSc", name: "Master of Exercise Science"}
];
degrees.sort((a, b) => a.value.localeCompare(b.value))

const licenses = [
  {value: "BCPM", name: "Board Certification in Pain Management™"},
  {value: "CPLC", name: "Certified Professional Life Coach"},
  {value: "CPT", name: "Certified Personal Trainer"},
  {value: "ACE", name: "American Council on Exercise"},
  {value: "AFAA", name: "Aerobics and Fitness Association of America"},
  {value: "ATC", name: "Board of Certification Examination for Athletic Trainers"},
  {value: "NSCA", name: "NCSA Certified Personal Trainer"},
  {value: "NASM", name: "National Academy of Sports Medicine"},
  {value: "CSCS", name: "Certified Strength and Conditioning Specialist"},
];
licenses.sort((a, b) => a.value.localeCompare(b.value))

const licenses2 = licenses.map(x => {
  return {
    name: x.name + "  (" + x.value + ")",
    value: x.value,
  };
});

const degrees2 = degrees.map(x => {
  return {
    name: x.name + " ("+x.value+")",
    value: x.value,
  }
});


export const Degrees = degrees2
export const Licenses = licenses2


export const parsePhoneNumber = number => {
  try {
    const parsed = phoneUtil.parseAndKeepRawInput(number, 'US')
    return {countryCode: parsed.getCountryCode(), phoneNumber: ''+parsed.getNationalNumber()};
  } catch (ignored) {
    return {countryCode: 1, phoneNumber: number};
  }
}

class UISettingsTabview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const selectedTab = this.props.tabs.find(tab => tab.value == this.props.selection);
    return <div className='uiSettingsTabview'>
      <div className='uiSettingsTabviewTabs'>
      {this.props.tabs.map((tab, i) => {
        return <UISettingsTab select={this.props.select} label={tab.name} value={tab.value} icon={tab.icon} selected={tab == selectedTab}/>;
      })}
    </div>
      <div className='uiSettingsTabviewBody'>
      {this.props.tabs.map((tab, i) => {
        return <div className='uiSettingsTabviewBodyTab' style={tab == selectedTab ? null: {display: 'none'}}>
          {tab.render(tab == selectedTab)}
        </div>
      })}
    </div>
      </div>
  }
}

class UISettingsTab extends Component {
  constructor(props) {
    super(props);
  }

  select = () => {
    this.props.select(this.props.value);
  }
  
  render() {
    return <div className='uiSettingsTab'>
      <div onClick={this.select} className={'uiSettingsTabTab' + (this.props.selected ? " uiSettingsTabSelected" : "")}>
      <div className='uiSettingsTabIcon'><ReactSVG src={this.props.icon}/></div>
      <div className='uiSettingsTabLabel'>{this.props.label}</div>
      </div>
      </div>
  }
}

export class UINotificationSetting extends Component {
  constructor(props) {
    super(props);
  }
  toggle= () => {
    this.props.toggle(this.props.value);
  }
  render() {
    let className = 'uiNotificationSetting';
    if (this.props.checked) {
      className += ' uiNotificationSettingChecked';
    }
    return <div key={this.props.value} className={className} onClick={this.toggle}>
      <div key='label'className='uiNotificationSettingLabel'>{this.props.label}</div>
      <div key='icon' className='uiNotificationSettingIcon'><ReactSVG src={this.props.checked ? CheckBoxOn : CheckBox}/></div>
      </div>
  }
}

class UISettingsSupport extends Component {
  constructor (props) {
    super(props)
    this.state = {
      messages: [],
    }
    this.supportContact = new Contact({
      displayName: "Let's Build Support",
      uid: 'support',
    })
    this.typingSubject = new Subject()
  }
  render() {

    const sendMessage = async msg => {
    }
    const observeTyping = () => this.typingSubject
    const typing = () => {
    }
    const setPopupShowing = showing => {
    }
    return <div className='uiSettingsSupport'>
      <UIChat
    alpha={1}
    visible={true}
    isMe={false}
    localContact={this.props.me.self}
    remoteContact={this.supportContact}
    recordMeal={this.recordMeal}
    onChatCreated={this.onChatCreated}
    onAppointmentEnded={this.onAppointmentEnded}
    finishNewContact={this.finishNewContact}
    waitForSystemUpdate={this.waitForUpdate}
    hideWith={false}
    observeTyping={observeTyping}
    typing={typing}
    setPopupShowing={setPopupShowing}
    openChat={this.props.openChat}
    openSubscription={this.props.openSubscription}
    scheduleChatAppointment={this.props.scheduleChatAppointment}
    scheduleAppointmentWith={this.props.scheduleAppointmentWith}
    rescheduleAppointment={this.rescheduleAppointment} showSystemProgressIndicator={this.showSystemProgressIndicator} messages={this.state.messages} me={this.props.me} sendMessage={sendMessage}/>
      
      </div>
  }
}

class UISettingsNotifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messages: true,
      appointments: true,
      workouts: true,
      sleep: true,
      account: true,
      meals: true,
      weights: true,
      promotions: true
    }
    this.settings = [
      {
        value: 'messages',
        name: "Messages"
      },
      {
        value: 'appointments',
        name: "Appointments"
      },
      {
        value: 'workouts',
        name: "Workouts"
      },
      {
        value: 'sleep',
        name: "Sleep"
      },
      {
        value: 'meals',
        name: "Meals"
      },
      {
        value: 'weights',
        name: "Weight"
      },
      {
        value: 'promotions',
        name: "Announcements and News"
      }]
    
  }

  toggle = value => {
    this.state[value] = !this.state[value];
    this.forceUpdate();
  }

  initState = (acct) => {

    const me = this.props.me;
    acct = acct || me.accountData;
    debugLog("acct", acct)
    if (acct) {
      const unsubscribe = acct.unsubscribe;
      this.settings.map(s => {
        const value = unsubscribe ? !unsubscribe[s.value] : true;
        this.state[s.value] = value;
      });
    }
  }

  componentDidMount() {
    this.initState();
    this.sub = this.props.me.observeAccount().subscribe(acct => {
      this.initState(acct);
      this.forceUpdate();
    })
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
  }

  save = async () => {
    const unsubscribe = {
    }
    this.settings.map(x => {
      const value = x.value;
      unsubscribe[value] = !this.state[value];
    });
    return this.props.me.updateAccount({
      unsubscribe: unsubscribe
    });
  }

  cancel = () => {
    const me = this.props.me;
    const acct = me.accountData;
    const unsubscribe = acct.unsubscribe;
    this.settings.map(s => {
      const value = unsubscribe ? !unsubscribe[s.value] : true;
      this.settings[s.value] = value;
    });
    return Promise.resolve();
  }
  
  render() {
    const me = this.props.me;
    const acct = me.accountData;
    if (!acct) return null;
    const unsubscribe = acct.unsubscribe;
    let modified;
    this.settings.map(s => {
      const value = unsubscribe ? !unsubscribe[s.value] : true;
      if (value != this.state[s.value]) {
        modified = true;
      }
    });
    return <div key='notifications' className='uiSettingsNotifications'>
      <div className='uiSettingsNotificationsTitle'>Send me notifications about:</div>
      {this.settings.map(s => {
        return <UINotificationSetting label={s.name} value={s.value} checked={this.state[s.value]} toggle={this.toggle}/>
      })}
      <div style={modified ? null: {display: 'none'}} className='uiSettingsSave'>
      <UIOKCancel ok={this.save} cancel={this.cancel} okIcon={Save} cancelIcon={Cross} label="Save"/>
      </div>
      </div>
  }
}

const pronounOptions = [
  {
    value: 'he',
    name: 'he/him/his'
  },
  {
    value: 'she',
    name: 'she/her/hers'
  },
  {
    value: 'they',
    name: 'they/them/theirs'
  },
  {
    value: 'xhe',
    name: 'xhe/xym/xer/xers'
  }
]


class UISettingsProfile extends Component {
  constructor(props) {
    super(props);
    const {countryCode, phoneNumber} = parsePhoneNumber(this.props.me.self.phoneNumber);
    this.state = {
      form: {
        email: this.props.me.self.email,
        displayName: this.props.me.self.displayName,
        phoneNumber: (phoneNumber || '') + '',
        countryCode: countryCode,
        pronouns: 'they'
      },
      degrees: [],
      licenses: [],
    }
    this.savedPassword = '';
  }

  componentDidMount() {
    this.sub = this.props.me.observeAccount().subscribe(account => {
      if (account) {
        debugLog("account updated: ", account);
        this.account = account;
        const {countryCode, phoneNumber} = parsePhoneNumber(account.phoneNumber);
        if (!isMobile()) {
          const degrees = account.degrees ? account.degrees.split(", ") : [];
          const licenses = account.licenses ? account.licenses.split(", ") : [];
          this.state.degrees = degrees;
          this.state.licenses = licenses
        }
        if (this.state.form.phoneNumber != phoneNumber || this.state.form.countryCode != countryCode) {
          this.state.form.phoneNumber = (phoneNumber || '') + '';
          this.state.form.countryCode = countryCode || 1;
        }
        debugLog("form", this.state.form)
        this.forceUpdate();
        this.state.form.isTrainer = !!account.isTrainer
        this.state.form.pronouns = account.pronouns || 'they'
      }
    });
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
  }

  onChangePronouns = value => {
    this.onChange('pronouns', value)
    this.dismissPronounsSelection()
  }
  
  onChange = (field, value) => {
    if (field == 'phoneNumber') value = (value || '') + ''
    this.state.form[field] = value;
    this.state.formError = {};
    this.props.onChange(field, value)
  }

  formError = info => {
    this.state.formError = info;
    this.forceUpdate();
    return Promise.resolve();
  }

  save = async () => {
    let p = Promise.resolve();
    if (this.props.profilePictureToUpload) {
      p = this.props.me.uploadProfileImage(this.props.profilePictureToUpload);
    }
    return p.then(profileImageDownloadURL => {
      const updates = {
      }
      let update = profileImageDownloadURL;
      if (profileImageDownloadURL) {
        updates.profileImage = profileImageDownloadURL
      }
      const me = this.account;
      const form = this.state.form;
      if (form.displayName != me.displayName) {
        if (!form.displayName.trim()) {
          return this.formError({field: 'name', err: "Display name is required"});
        }
        updates.displayName = form.displayName;
        update = true;
      }
      if (form.email != me.email) {
        if (!form.email.trim()) {
          return this.formError({field: 'email', err: "Email address is required"});
        }
        if (!validator.isEmail(form.email)) {
          return this.formError({field: 'email', err: "Email address is invalid"});
        }
        updates.email = form.email;
        update = true;
      }
      const {countryCode, phoneNumber} = parsePhoneNumber(this.account.phoneNumber);
      if (form.phoneNumber != phoneNumber || form.countryCode != countryCode) {
        if (!form.phoneNumber.trim()) {
          return this.formError({field: 'phoneNumber', err: "Phone number is required"});
        }
        const newPhoneNumber = "+"+form.countryCode + form.phoneNumber;
        if (!phone(newPhoneNumber).length) {
          return this.formError({field: 'phoneNumber', err: "Phone number is invalid"});
        }
        updates.phoneNumber = newPhoneNumber;
        update = true;
      }
      if (form.password) {
        if (window.teteConfig != 'dev') {
          const result = owasp.test(form.password);
          if (result.errors.length) {
            return this.formError({field: 'password', err: result.errors[0]});
          }
        }
        updates.password = form.password;
        update = true;
      }
      let degrees
      let licenses
      if (isMobile()) {
        if (this.props.degrees) {
          degrees = this.props.degrees.join(', ')
        } else {
          degrees = ''
        }
        if (this.props.licenses) {
          licenses = this.props.licenses.join(', ')
        } else {
          licenses = ''
        }
      } else {
        degrees = this.state.degrees.join(", ");
        licenses = this.state.licenses.join(", ");
      }
      if (degrees != this.account.degrees) {
        updates.degrees = degrees;
        update = true;
      }
      if (licenses != this.account.licenses) {
        updates.licenses = licenses;
        update = true;
      }
      if (form.isTrainer != this.account.isTrainer) {
        updates.isTrainer = !!form.isTrainer
        update = true
      }
      if (form.pronouns != this.account.pronouns) {
        updates.pronouns = form.pronouns
        update = true
      }
      this.setState({formError: {}});
      if (update) {
        return this.props.me.updateAccount(updates).then(result => {
          debugLog(result);
          if (result && result.data.error) {
            const err = result.data.error;
            const formError = {};
            if (err.code == "auth/email-already-exists") {
              formError.field = "email";
              formError.err = "Email address is in use by another account";
            }
            if (err.code == "auth/phone-number-already-exists") {
              formError.field = "phoneNumber";
              formError.err = "Phone number is in use by another account";
            }
            this.setState({formError: formError});
            return;
          }
          if (updates.password) {
            this.savedPassword = updates.password;
            this.forceUpdate();
          }
          this.props.onSave();
        });
      }
    });

  }

  cancel = () => {
    debugger
    this.props.onCancel()
    return new Promise((resolve, reject) => {
      const {countryCode, phoneNumber} = parsePhoneNumber(this.account.phoneNumber);
      const account = this.account;
      const degrees = account.degrees ? account.degrees.split(", ") : [];
      const licenses = account.licenses ? account.licenses.split(", ") : [];
      const form = {
          email: account.email,
          displayName: account.displayName,
          phoneNumber: phoneNumber,
          countryCode: countryCode,
          password: this.savedPassword,
          degrees: account.degrees,
          licenses: account.licenses
      }
      console.log("cancel before => form: ", form, this.account);
      console.log("cancel before => degrees: ", degrees, 'licenses', licenses)
      this.setState({
        formError: {},
        form: form,
        degrees: degrees,
        licenses: licenses
      }, () => {
        //debugLog("cancel => form: ", this.state.form, this.account);
        resolve();
      });
    });
  }

  signOut = () => {
    return new Promise((resolve, reject) => {
      setTimeout(()=> this.props.signOut().then(resolve), 750);
    });
  }

  onChangeDegrees = value => {
    debugLog("onChange degrees: ", value)
    this.setState({
      degrees: value,
    });
  }

  onClickDegrees = () => {
    this.setState({
      selectingDegrees: !this.state.selectingDegrees
    });
    if (this.props.onClickDegrees) {
      this.props.onClickDegrees()
    }
  }

  dismissDegreesSelection = () => {
    this.setState({
      selectingDegrees: false
    });
  }

  onChangeLicenses = value => {
    this.setState({
      licenses: value,
    });
  }

  onClickLicenses = () => {
    this.setState({
      selectingLicenses: !this.state.selectingLicenses
    });
    if (this.props.onClickLicenses) {
      this.props.onClickLicenses()
    }
  }

  dismissLicensesSelection = () => {
    this.setState({
      selectingLicenses: false
    });
  }


  onClickPronouns = () => {
    this.setState({
      selectingPronouns: !this.state.selectingPronouns
    });
    if (this.props.onClickPronouns) {
      this.props.onClickPronouns()
    }
  }

  dismissPronounsSelection = () => {
    this.setState({
      selectingPronouns: false
    });
  }

  setPaymentMethod = paymentMethod => {
    debugger
    this.state.form.paymentMethod = paymentMethod
    this.forceUpdate()
  }

  updatePaymentMethod = async () => {
  }

  onChangeCard = e => {
    console.log('onChangeCard', e)
    this.setState({cardInputComplete: e.complete })
  }

  renderPaymentMethod = () => {
    let paymentStatus
    let opts = {
      clientSecret: this.state.clientSecret
    }
    return <Elements stripe={window.stripePromise} options={opts}>
      <ElementsConsumer>
      {({elements, stripe}) => {
        // stripe wtfs
        this.elements = elements;
        this.stripe = stripe;
        if (!elements || !stripe) return null
    const CARD_OPTIONS = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: 'white',
          color: 'white',
          fontWeight: 500,
          fontSize: '14px',
          caretColor: 'white',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: 'white',
          },
          '::placeholder': {
            color: 'white'
          },
        },
        webkitAutoFill: {
          color: 'white',
          caretColor: 'white',
          backgroundColor: 'rgb(33, 161, 196)'
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#ffc7ee',
        },
      },
    };
        return <div className='uiStripeClientConnectForm'>
          <div className='uiStripeConnectFormCard'><CardElement onChange={this.onChangeCard} options={CARD_OPTIONS}/></div>
          </div>
      }}
    </ElementsConsumer>
      </Elements>
  }

  fields = ["displayName", "email", "phoneNumber", "password", "pronouns"];

  render() {
    let modified = !!this.props.profilePictureToUpload;
    if (modified) {
      debugLog("changed: profilePictureToUpload");
    }
    let licensesArr = this.state.licenses
    let degreesArr = this.state.degrees
    if (isMobile()) {
      licensesArr = this.props.licenses || []
      degreesArr = this.props.degrees || []
    } else {
      licensesArr = this.state.licenses
      degreesArr = this.state.degrees
    }
    const form = this.state.form;
    const acc = this.account;
    if (acc && !modified) {
      for (var i = 0; i < this.fields.length; i++) {
        const field = this.fields[i];
        const value = acc[field];
        if (field == 'phoneNumber') {
          const {countryCode, phoneNumber} = parsePhoneNumber(this.account.phoneNumber);
          debugLog("form", form)
          let num = form.phoneNumber
          if (num) num = num.replace(' ', '')
          if (!num) num = null
          let code = form.countryCode
          if (!code) code = 1
          if (code != countryCode || num != phoneNumber) {
            modified = true;
            debugLog("changed: ", field, ": ", {code, num}, " <> ", {phoneNumber, countryCode});
          }
        } else if (field == 'password') {
          if (form.password != this.savedPassword) {
            modified = true;
            debugLog("changed: ", field, ": ", form[field], " <> ", this.savedPassword);
          }
        } else {
          if (form[field] != acc[field]) {
            modified = true;
            debugLog("changed: ", field, ": ", form[field], " <> ", acc[field]);
          }
        }                
      }
      const degrees = degreesArr.join(", ");
      if (degrees != (acc.degrees || "")) {
        debugLog("changed:  degrees: ", degrees, "<>", acc.degrees);
        modified = true;
      }
      const licenses = licensesArr.join(", ");
      if (licenses != (acc.licenses || "")) {
        debugLog("changed:  licenses: ", degrees, "<>", acc.licenses);
        modified = true;
      }
      if (!!form.isTrainer !== !!acc.isTrainer) {
        modified = true
      }
      if (form.pronouns !== acc.pronouns) {
        modified = true
      }
    }
    debugLog("modified: ", modified);
    if (this.state.cardInputComplete) {
      modified = true
    }
    let contact = this.props.me.self
    if (this.props.profilePictureToUpload) {
      contact = new Contact(this.props.me.self)
      contact.profileImage = this.props.profilePictureToUploadURL
    }
    const toggleIsTrainer = () => {
      this.onChange('isTrainer', !form.isTrainer)
    }
    return <div key='profile' className='uiSettingsProfile'>
      <div className='uiSettingsProfileFields'>
      {isMobile() && <div className='uiSidebarHeaderProfile'>
       <UIProfileIcon radius={65/2-2} contact={contact}/>
       <ProfilePictureInput onChange={this.props.onProfilePictureInput}/>
       </div>}
      <form>
      {this.props.todoListSub && this.props.todoListSub.status !== 'trial' && <div className='uiSettingsTodoListSubscription'>
       <UIChatTodoListSubscription subscription={this.props.todoListSub} me={this.props.me}/>
       </div>
      }
      <UIInputField error={this.state.formError} icon={User} label={'Display Name'} name='displayName' value={form.displayName} type='text' autoComplete='name' onChange={this.onChange}/>
      <UIInputField readOnly={!this.props.me.user || this.props.me.user.providerData.find(x => x.providerId == 'google.com')} error={this.state.formError} icon={Email} label={'Email Address'} name='email' value={form.email} type='email' autoComplete='username' onChange={this.onChange}/>
      <UIInputField countryCodeName='countryCode' countryCode={form.countryCode} error={this.state.formError} icon={Phone} label={'Phone Number'} name='phoneNumber' value={form.phoneNumber} type='tel' autoComplete='tel' onChange={this.onChange}/>
      <UIInputField error={this.state.formError} icon={Lock} label={'Password'} name='password' value={form.password} type='password' autoComplete='new-password' onChange={this.onChange}/>
      <div className='uiSettingsPronouns'>
      <div className='uiSettingsPronounsLabel'>Pronouns</div>
      <ClickAwayListener onClickAway={this.dismissPronounsSelection}>
      <div className='uiSettingsPronounsBody'>
      <div className='uiSettingsPronounsButton' onClick={this.onClickPronouns}>{pronounOptions.find(x => x.value === this.state.form.pronouns).name}
      <div className='uiSettingsPronounsIcon'><ReactSVG src={Check}/></div></div>
      <UISelectionList options={pronounOptions} select={this.onChangePronouns} value={this.state.form.pronouns} selected={this.state.form.pronouns} visible={this.state.selectingPronouns}/>
      </div>
      </ClickAwayListener>
      </div>
      {!this.props.me.isTodoList() && <div className='uiSettingsIsTrainer'>
      <UINotificationSetting label={'I\'m a Personal Trainer'} value={form.isTrainer} checked={form.isTrainer} toggle={toggleIsTrainer}/>
       </div>}
    {!this.props.me.isTodoList() && (form.isTrainer || this.props.stripeAuth) && this.props.renderPayments() }
      {form.isTrainer && <div className='uiSettingsDegrees'>
      <div className='uiSettingsDegreesLabel'>Degrees</div>
      <ClickAwayListener onClickAway={this.dismissDegreesSelection}>
      <div className='uiSettingsDegreesBody'>
      <div className='uiSettingsDegreesButton' onClick={this.onClickDegrees}>{
        degreesArr.join(", ")
      }<div className='uiSettingsLicensesIcon'><ReactSVG src={Check}/></div></div>
      <UISelectionList multiSelect={true} select={this.onChangeDegrees} options={degrees2} selected={this.state.degrees} visible={this.state.selectingDegrees && !isMobile()} value={this.state.degrees}/>
      </div>
      </ClickAwayListener>
       </div>}
    {form.isTrainer &&  <div className='uiSettingsLicenses'>
      <div className='uiSettingsLicensesLabel'>Licenses/Certifications</div>
      <ClickAwayListener onClickAway={this.dismissLicensesSelection}>
      <div className='uiSettingsLicensesBody'>
      <div className='uiSettingsLicensesButton' onClick={this.onClickLicenses}> {
        licensesArr.join(", ")
      }<div className='uiSettingsLicensesIcon'><ReactSVG src={Check}/></div></div>
      <UISelectionList multiSelect={true} select={this.onChangeLicenses} options={licenses2} selected={this.state.licenses} visible={this.state.selectingLicenses && !isMobile()} value={this.state.licenses}/>
      </div>
      </ClickAwayListener>
     </div>}
      <div className='uiSettingsFormError'>
      {this.state.formError ? this.state.formError.err : ''}
    </div>
      <div style={modified ? null: {display: 'none'}} className='uiSettingsSave'>
      <UIOKCancel ok={this.save} cancel={this.cancel} okIcon={Save} cancelIcon={Cross} label="Save"/>
      </div>
      <div className='uiSettingsSignout'>
      <UIOKCancel okIcon={SignOut} ok={this.signOut} label={"Sign Out"}/>
      </div>
      </form>
      </div>
      </div>
  }
}

export class UISettings extends Component {
  constructor(props) {
    super(props);
    this.state = {form: {}}
    this.tabs = [
      {
        icon: User,
        name: "Profile",
        value: 'profile',
        render: () => <UISettingsProfile onChange={(field, value) => {
          this.state.form[field] = value
          this.forceUpdate()
        }} signOut={this.props.signOut} 
        onClickDegrees={this.props.onClickDegrees} onClickLicenses={this.props.onClickLicenses} degrees={this.props.degrees} licenses={this.props.licenses}
        stripeAuth={this.state.stripeAuth}
        renderPayments={this.renderPayments}
        onSave={this.props.onSave}
        onCancel={this.props.onCancel}
        me={this.props.me}
        todoListSub={this.state.todoListSub}
        profilePictureToUpload={this.props.profilePictureToUpload}
        profilePictureToUploadURL={this.props.profilePictureToUploadURL}/>
          
      },
      {
        icon: Link,
        name: 'Link Devices',
        value: 'link',
        render: () => this.renderContactLink()
      },
      {
        icon: Camera,
        name: "AV Setup",
        value: 'device',
        render: selected => <UIDeviceSetup ref={this.setDeviceSetup} me={this.props.me} visible={this.props.visible && selected}/>
      },
      {
        icon: Bell,
        name: "Notifications",
        value: 'notification',
        render: () => <UISettingsNotifications me={this.props.me}/>
      },
      {
        icon: LetsBuild,
        name: "Support",
        value: 'support',
        render: () => <UISettingsSupport me={this.props.me}/>
      },
    ]
  }

  componentDidMount() {
    this.props.me.observeOuraLink().subscribe(linked => {
      this.setState({
        canLinkOura: true
      })
    })
    this.props.me.observeWhoopLinked().subscribe(linked => {
      this.setState({
        whoopLinked: linked
      })
    })
    this.props.me.observeFitbitLinked().subscribe(linked => {
      if (this.fitbitPopup) {
        const popup = this.fitbitPopup
        this.fitbitPopup = null
        popup.close()
      }
      this.setState({
        fitbitLinked: linked
      })
    })
    this.props.me.observeGarminLinked().subscribe(linked => {
      if (this.garminPopup) {
        const popup = this.garminPopup
        this.garminPopup = null
        popup.close()
      }
      this.setState({
        garminLinked: linked
      })
    })
    this.props.me.observeOuraLinked().subscribe(linked => {
      debugLog("oura linked:", linked)
      if (this.ouraPopup) {
        const popup = this.ouraPopup
        this.ouraPopup = null
        popup.close()
      }
      this.setState({
        ouraLinked: linked
      })
    })
    this.props.me.observeWithingsLinked().subscribe(linked => {
      debugLog("withings linked:", linked)
      if (this.withingsPopup) {
        const popup = this.withingsPopup
        this.withingsPopup = null
        popup.close()
      }
      this.setState({
        withingsLinked: linked
      })
    })
    this.props.me.observeStripeAuth().subscribe(auth => {
      console.log("STRIPE AUTH", auth)
      this.setState({
        stripeAuth: auth,
      });
    });
    if (this.props.me.isTodoList()) {
      this.todoSubSub = this.props.me.observeTodoListSubscription().subscribe(sub => {
        console.log("todolist sub", sub)
        this.setState({
          todoListSub: sub
        })
      })
    }
  }

  componentWillUnmount() {
    if (this.todoSubSub) {
      this.todoSubSub.unsubscribe()
    }
  }

  renderPayments = () => {
    return <div className='uiSettingsPayments'>
      <UIMeConnectToStripe auth={this.state.stripeAuth} me={this.props.me}/>
      </div>
  }

  renderContactLink = () => {
    return <div className='uiMeLinkTabContent'>
    {this.renderWhoopConnect()}            
    {this.renderOuraConnect()}
    {this.renderGarminConnect()}
    {this.renderWithingsConnect()}            
    {this.renderFitbitConnect()}
    </div>
  }
  
  linkOura = async () => {
    if (this.state.ouraLinked) {
      return this.props.me.unlinkOura()
    }
    return this.props.me.linkOura()
  }
  
  linkWithings = () => {
    if (this.state.withingsLinked) {
      return this.props.me.unlinkWithings()
    }
    const popup = this.props.me.linkWithings()
    debugLog("popup", popup)
    this.withingsPopup = popup
    return new Promise(resolve => {
      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer)
          resolve()
        }
      }, 200)
    })
  }

  linkWhoop = async => {
    if (this.state.whoopLinked) {
      return this.props.me.unlinkWhoop()
    }
    const form = {email: '', password: ''}
    let emailErr
    let passwordErr
    let other
    const onChangePassword = e => {
      passwordErr = null
      form.password = e.target.value
      if (this.props.update) {
        this.props.update()
      } else {
        this.forceUpdate()
      }
    }
    const onChangeEmail = e => {
      form.email = e.target.value
      emailErr = null
      if (this.props.update) {
        this.props.update()
      } else {
        this.forceUpdate()
      }
    }
    const cancel = () => {
      if (this.props.closePopup) {
        this.props.closePopup()
      } else {
        this.setState({
          popup: null
        })
      }
    }
    const done = async () => {
      try {
        if (!form.email) {
          emailErr = 'Email Address is required'
        }
        else if (!validator.isEmail(form.email)) {
          emailErr = 'Invalid email address'
        }
        else if (!form.password) {
          passwordErr = 'Password is required'
        }
        if (emailErr || passwordErr) {
          this.forceUpdate()
          return
        }
        const result = await whoop.signIn(form.email, form.password)
        await this.props.me.linkWhoop(result)
        this.setState({
          popup: null
        })
      } catch (err) {
        console.error(err)
        other = "Invalid email or password"
        this.forceUpdate()
      }
    }
    const popup  = () => <FDPopup title='Whoop Login' cancel={cancel} done={done} me={this.props.me}>
          <mobiscroll.Form id='whoopConnect' autoComplete='off' theme='ios' themeVariant='light'>
          <mobiscroll.FormGroup>
          <mobiscroll.Input key='whoopUsername' valid={!emailErr} errorMessage={emailErr} value={form.email} onChange={onChangeEmail} placeholder='Email Address' type='text' autoComplete='off'/>
          <mobiscroll.Input key='whoopPassword' valid={!passwordErr} errorMessage={passwordErr} type='password' value={form.password} onChange={onChangePassword} placeholder='Password' autoComplete={'new-password'}/>

          </mobiscroll.FormGroup>
      <mobiscroll.FormGroup>
      {other && <div className='fdOtherErr'>
       <ReactSVG src={Error} />
       {other}
       </div>}
    </mobiscroll.FormGroup>
      <div className='whoopLogo'>
      <ReactSVG src={WhoopLogo}/>
      </div>
      </mobiscroll.Form>
      </FDPopup>
      if (this.props.openPopup) {
        this.props.openPopup(popup)
      } else {
        this.setState({
          popup: popup
        })
      }
    return Promise.resolve()
  }

  renderWhoopConnect = () => {
    return <div className='uiMeWhoopConnect'>
      <UIMeCard key='whoop' title='Whoop'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.whoopLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={WhoopIcon} ok={this.linkWhoop} label={(this.state.whoopLinked ? 'Unlink' :'Link') + ' your Whoop strap'}/>
      </div>
      </UIMeCard>
      </div>
  }

  linkFitbit = async () => {
    if (this.state.fitbitLinked) {
      return this.props.me.unlinkFitbit()
    }
    const popup = this.props.me.linkFitbit()
    debugLog("popup", popup)
    this.fitbitPopup = popup
    return new Promise(resolve => {
      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer)
          resolve()
        }
      }, 200)
    })
  }

  renderFitbitConnect = () => {
    return <div className='uiMeWhoopConnect'>
      <UIMeCard key='fitbit' title='Fitbit Aria'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.fitbitLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={ScaleIcon} ok={this.linkFitbit} label={(this.state.fitbitLinked ? 'Unlink' :'Link') + ' your Fitbit Aria Scale'}/>
      </div>
      </UIMeCard>
      </div>
  }

  linkGarmin = async () => {
    if (this.state.garminLinked) {
      return this.props.me.unlinkGarmin()
    }
    const popup = this.props.me.linkGarmin()
    debugLog("popup", popup)
    this.garminPopup = popup
    return new Promise(resolve => {
      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer)
          resolve()
        }
      }, 200)
    })
  }
  
  renderGarminConnect = () => {
    return <div className='uiMeWhoopConnect'>
      <UIMeCard key='garmin' title='Garmin'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.garminLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={GarminLogo} ok={this.linkGarmin} label={(this.state.garminLinked ? 'Unlink' :'Link') + ' your Garmin device'}/>
      </div>
      </UIMeCard>
      </div>
  }
  
  renderWithingsConnect = () => {
    return <div className='uiMeWhoopConnect uiMeConnectWithings'>
      <UIMeCard key='whoop' title='Withings'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.withingsLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={ScaleIcon} ok={this.linkWithings} label={(this.state.withingsLinked ? 'Unlink' :'Link') + ' your Withings scale'}/>
      </div>
      </UIMeCard>
      </div>
  }

  renderOuraConnect = () => {
    return <div className='uiMeWhoopConnect' style={this.state.canLinkOura ? null : {display: 'none'}}>
      <UIMeCard key='oura' title='Oura'>
      <div className={'uiMeWhoopButtonContainer' + (this.state.ouraLinked ? ' whoopLinked' : '')}>
      <UIOKCancel okIcon={OuraIcon} ok={this.linkOura} label={(this.state.ouraLinked ? 'Unlink' :'Link') + ' your Oura ring'}/>
      </div>
      </UIMeCard>
      </div>
  }

  setDeviceSetup = ref => {
    if (ref) {
      this.deviceSetup = ref
    }
  }

  select = value => {
    if (this.props.nav === 'device' && value !== 'device') {
      this.deviceSetup.closeStream()
    }
    this.props.navigate(value);
  }
  
  renderSettings() {
    debugLog('renderSettings', this.props.visible)
    let tabs = this.tabs
    if (this.props.me.isTodoList()) {
      tabs = tabs.filter(tab => tab.value === 'profile')
    }
    return <div className='uiSettings' style={this.props.visible ? null: {display: "none"}}>
      <div className='uiSettingsTitle'>Settings</div>
      <div className='uiSettingsBody'>
      <UISettingsTabview tabs={tabs} selection={this.props.nav} select={this.select}/>
      </div>
      </div>
  }

  render() {
    if (this.props.me.isTodoList()) {
      return this.renderSettings()
    }
    let popup
    if (this.state.popup) {
      popup = this.state.popup()
    }
    return <FDPage safeArea noHeaderButtons noHeader={ true } popup={popup} me={this.props.me}>
      {this.renderSettings()}
    </FDPage>
  }
}

