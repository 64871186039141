import React, {useState, findDOMNode, Component } from 'react';
import { ReactSVG } from 'react-svg'
import { useDrop } from 'react-dnd'
import {isMobile, isIPad} from "../../Platform";
import { FDRadioButtons } from '../../Mobile/src/components/Button'
import {hasSoftKeyboard, isDesktop} from "../../Platform";
import {formatDate, formatStartEndTime, renderPaymentStatus, UIAppointment} from "../Appointment";
import {FoodLogo, logoImage, getFoodName, UIScheduleAppointment} from "../ScheduleAppointment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {UISubscription} from "../Subscription";
import {UISelectionList} from "../DeviceSetup";
import Search from "../../assets/icons/Search.svg";
import Cross from "../../assets/icons/Cross.svg";
import ScaleIcon from "../../assets/icons/Scales.svg";
import OuraIcon from '../../assets/icons/OuraDark.png'
import OuraSleep from '../../assets/icons/OuraSleep.png'
import {UIOKCancel} from '../OKCancel'
import { formatWeight, TodoProfile, WeightProfile, MealsProfile, OuraProfile, WhoopProfile, FitbitProfile, GarminProfile, WhoopSleepSummary, WhoopWorkoutSummary } from '../WhoopProfile'
import { getEmojiFromTodo, getEmojiForEmotion } from '../TodoCard'
import moment from 'moment';
import 'moment-duration-format'
import mobiscroll from "@mobiscroll/react";
import TeTe from "../../assets/Assets/TeteLogo00.svg";
import Plus from "../../assets/icons/Plus.svg";
import Forward from "../../assets/icons/Forward.svg";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import "./index.css";
import {FDScopeButtons} from '../../Mobile/src/components/SearchField'
import Back from '../../Mobile/src/assets/Icons/BackArrow.svg'

const clone = obj => JSON.parse(JSON.stringify(obj))

const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds * 1000))

const debugLog = (...args) => {
  console.log.apply(null, args)
}

const ONE_DAY = 24 * 60 * 60 * 1000

export const capitalize = n => n.substring(0, 1).toUpperCase() + n.substring(1)
export const getTime = (date, time) => {
  const dateTime = new Date(date);
  dateTime.setHours(time.getHours());
  dateTime.setMinutes(time.getMinutes());
  return dateTime.getTime();
}

function startOfCycle(date) {
  const week = startOfWeek(date)
  const month = startOfMonth(date)
  return Math.min(week, month)
}

function startOfDay(date)  {
  return moment(date).local().startOf("day").toDate().getTime()
}
function startOfWeek(date)  {
  return moment(date).local().startOf("isoweek").toDate().getTime()
}
function startOfMonth(date)  {
  return moment(date).local().startOf("month").toDate().getTime()
}
function startOfYear(date)  {
  return moment(date).local().startOf("year").toDate().getTime()
}
function endOfWeek(date)  {
  return moment(date).local().endOf("isoweek").toDate().getTime()
}
function endOfMonth(date)  {
  return moment(date).local().endOf("month").toDate().getTime()
}
function endOfDay(date)  {
  return moment(date).local().endOf("day").toDate().getTime()
}
function endOfYear(date)  {
  return moment(date).local().endOf("year").toDate().getTime()
}

const isTodo = x => x.status === 'todo' || (x.appt && x.appt.todo && x.appt.todo.todo.status === 'pending')

export const todoToAppointment = (me, item) => {
  const fakeAppt = {
    contact: me.self,
    organizer: me.self,
    title: item.todo.category,
    titleHtml: item.todo.category,
    client: me.self,
    start: item.start,
    end: item.end /* || item.todo.date */ || 0,
    status: item.status,
    id: item.id,
    todo: item
  }
  console.log("todoAppt", item, fakeAppt)
  return fakeAppt
}

export const cycleToAppointment = (me, contact, source, type, cycle) => {
  switch (type) {
    case 'workout':
      {
        if (source === 'whoop') {
          const workout = cycle
          const begin = Date.parse(workout.during.lower)
          const end = Date.parse(workout.during.upper)
          const dur = moment.duration(end - begin, "milliseconds").format("h:mm", { trim: false } )
          const details = me.getWhoop().getWorkout(workout)
          const fakeAppt = {
            contact: {
              displayName: details.name,
              profileImage: details.iconUrl,
              uid: contact.uid
            },
            organizer: contact,
            title: details.name,
            titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>{details.name} {dur}</div>&nbsp;<div className='workoutEventStrain'>{(workout.score || 0).toFixed(1)}</div></div>,
            client: me.self.uid,
            start: begin,
            end: end,
            workout: workout,
            status: 'completed',
            id: workout.id
          }
          return fakeAppt
        }
        if (source === 'garmin') {
          const getGarminSport = cycle => {
            let sport = me.getWhoop().mapFromName(cycle.activityName)
            if (sport.id === -1) {
              sport = me.getWhoop().mapFromName(cycle.activityType.replace(/_/g, ' '))
              if (sport.id === -1) {
                for (const comp of cycle.activityType.split('_')) {
                  sport = me.getWhoop().mapFromName(comp)
                  if (sport.id !== -1) {
                    break
                  }
                }
              }
            }
            console.log("sport", cycle.activityType, cycle.activityName, sport, cycle)
            return sport
          }
          const sport = getGarminSport(cycle)
          const workout = {
            during: {
              lower: new Date(cycle.start).toISOString(),
              upper: new Date(cycle.end).toISOString()
            },
            id: ''+cycle.summaryId,
            garmin: cycle,
            kilojoules: 4.184 * (cycle.activeKilocalories || 0),
            maxHeartRate: cycle.maxHeartRateInBeatsPerMinute,
            score: cycle.score ? -cycle.score : 0,
            battery: cycle.score || 0,
            sportId: sport.id,
            averageHeartRate: cycle.averageHeartRateInBeatsPerMinute,
            start: cycle.start,
            end: cycle.end,
            uid: cycle.uid
          }
          console.log("garmin to workout", workout, cycle)
          const begin = Date.parse(workout.during.lower)
          const end = Date.parse(workout.during.upper)
          const dur = moment.duration(end - begin, "milliseconds").format("h:mm", { trim: false } )
          const details = me.getWhoop().getWorkout(workout)
          const fakeAppt = {
            contact: {
              displayName: details.name,
              profileImage: details.iconUrl,
              uid: contact.uid
            },
            organizer: contact,
            title: details.name,
            titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>{details.name} {dur}</div>&nbsp;<div className='workoutEventStrain'>{workout.score ? workout.score.toFixed(1) : ''}</div></div>,
            client: contact.uid,
            start: begin,
            end: end,
            workout: workout,
            status: 'completed',
            id: workout.id
          }
          return fakeAppt
        }
      }
      break
    case 'nap':
    case 'sleep':
      {
        if (source == 'whoop') {
          const sleepIcon = me.getWhoop().getSleepIcon()
          const sleep = cycle
          const begin = Date.parse(sleep.during.lower)
          const end = Date.parse(sleep.during.upper)
          const dur = moment.duration(end - begin, "milliseconds").format("h:mm", { trim: false})
          const slept = moment.duration(sleep.qualityDuration, "milliseconds").format("h:mm", { trim: false})
          const fakeAppt = {
            contact: {
              displayName: capitalize(type),
              profileImage: sleepIcon,
              uid: contact.uid
            },
            organizer: contact,
            title: capitalize(type),
            titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>{capitalize(type)} {sleep.score ? slept : "Processing"}</div>&nbsp;<div className='workoutEventStrain'>{!sleep.isNap && sleep.score ? sleep.score + '%' : ''}</div></div>,
            client: me.self.uid,
            start: begin,
            end: end,
            sleep: sleep,
            status: 'completed',
            id: sleep.id
          }
          return fakeAppt
        } else if (source == 'oura') {
          if (cycle.sleep) {
            const slept = moment.duration(cycle.sleep.duration * cycle.sleep.efficiency/100, "seconds").format("h:mm", { trim: false})
            const start = Date.parse(cycle.sleep.bedtime_start)
            const end = Date.parse(cycle.sleep.bedtime_end)
            const efficiency = cycle.sleep.efficiency / 100
            const consistency = 0
            const sleepIcon = OuraSleep
            const fakeAppt = {
              contact: {
                profileImage: sleepIcon,
                displayName: "Sleep",
                uid: contact.uid
              },
              organizer: contact,
              title: 'Sleep',
              titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>Sleep {slept}</div>&nbsp;<div className='workoutEventStrain'>{cycle.sleep.score + '%'}</div></div>,
              client: me.self.uid,
              start: start,
              end: end,
              status: 'completed',
              id: cycle.id, 
              type: 'sleep',
              cycle: cycle,
              sleep: {
                oura: cycle,
                needBreakdown: {
                  total: (100/cycle.sleep.score) * efficiency * cycle.sleep.duration * 1000
                },
                recovery: {
                  heartRateVariabilityRmssd: cycle.sleep.rmssd / 1000,
                  score: cycle.readiness ? cycle.readiness.score : 0,
                  restingHeartRate: cycle.sleep.hr_lowest,
                },
                during: {
                  lower: cycle.sleep.bedtime_start,
                  upper: cycle.sleep.bedtime_end
                },
                score: cycle.sleep.score,
                inBedDuration: end - start,
                respiratoryRate: cycle.sleep.breath_average,
                lightSleepDuration: cycle.sleep.light * 1000,
                qualityDuration: cycle.sleep.duration * efficiency * 1000,
                wakeDuration: cycle.sleep.awake * 1000,
                remSleepDuration: cycle.sleep.rem * 1000,
                slowWaveSleepDuration: cycle.sleep.deep * 1000,
                sleepEfficiency: efficiency,
                sleepConsistency: consistency
              }
            }
            return fakeAppt
          }
        } else if (source === 'garmin') {
          const sleepIcon = me.getWhoop().getSleepIcon()
          let respiratoryRate = 0
          if (cycle.timeOffsetSleepRespiration) {
            let avg = 0
            let count = 0
            for (const offset in cycle.timeOffsetSleepRespiration) {
              const value = cycle.timeOffsetSleepRespiration[offset]
              avg += value
              count++
            }
            if (count) {
              avg /= count
              respiratoryRate += avg
            }
          }
          const sleep = {
            during: {
              lower: new Date(cycle.start).toISOString(),
              upper: new Date(cycle.end).toISOString()
            },
            id: cycle.summaryId,
            garmin: cycle,
            needBreakdown: {
              total: 0
            },
            recovery: {
              heartRateVariabilityRmssd: 0,
              score: cycle.score,
              restingHeartRate: 0,
            },
            score: cycle.overallSleepScore ? cycle.overallSleepScore.value : cycle.score,
            inBedDuration: cycle.end - cycle.start,
            respiratoryRate,
            lightSleepDuration: cycle.lightSleepDurationInSeconds * 1000,
            qualityDuration: (cycle.end - cycle.start) - cycle.awakeDurationInSeconds * 1000,
            wakeDuration: cycle.awakeDurationInSeconds * 1000,
            remSleepDuration: cycle.remSleepInSeconds * 1000,
            slowWaveSleepDuration: cycle.deepSleepDurationInSeconds * 1000,
            sleepEfficiency: 0,
            sleepConsistency: 0
          }
          sleep.sleepEfficiency = sleep.qualityDuration / sleep.inBedDuration
          sleep.needBreakdown.total = Math.round((100/sleep.score) * sleep.sleepEfficiency * sleep.inBedDuration)              
          const getTitle = () => <div className='workoutEvent'><div className='workoutEventSport'>{'Sleep'} {slept}</div>&nbsp;<div className='workoutEventStrain'>{!sleep.isNap && sleep.score ? sleep.score + '%' : ''}</div></div>
            const begin = Date.parse(sleep.during.lower)
          const end = Date.parse(sleep.during.upper)
          const dur = moment.duration(end - begin, "milliseconds").format("h:mm", { trim: false})
          const slept = moment.duration(sleep.qualityDuration, "milliseconds").format("h:mm", { trim: false})
          const fakeAppt = {
            contact: {
              displayName: 'Sleep',
              profileImage: sleepIcon,
              uid: contact.uid
            },
            organizer: contact,
            title: 'Sleep',
            titleHtml: getTitle(),
            client: contact.uid,
            start: begin,
            end: end,
            sleep: sleep,
            status: 'completed',
            id: sleep.id
          }
          return fakeAppt
        }
      }
  }
}

export const UICalendar = props => {
  const accept = "appointment";
  ////debugLog("props: ", props);
  let mobi
  const setRef = x => {
    mobi = x;
  }
  const [dropped, setValue] = useState(""); // integer state
  const [collectedProps, drop] = useDrop({
    accept: accept,
    drop: (result, mon) => {
      setValue(result.appointment);
    },
    collect: mon => mon,

  })
  const cal = <UICalendarMobi isMe={props.isMe} initialPage={props.initialPage} opts={props.opts || {}} openContact={props.openContact} contactFilter={props.contactFilter} onClick={props.onClick} onSet={props.onSet} me={props.me} dragSource={dropped} ref={setRef} visible={props.visible}/>
  if (isMobile()) return cal
  return <div ref={drop} className='uiCalendarDropTarget'>
    {cal}
  </div>;
}


class UICalendarSelectionButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const selected = this.props.selection == this.props.value;
    return <div onClick={()=>this.props.select(this.props.value)} className={'uiCalendarSelectionButton' + (selected ? " uiCalendarSelectionButtonSelected" : "")}>
      <div className='uiCalendarSelectionButtonText'>{this.props.name}</div>
      </div>
  }
}

class UICalendarSelectionMenu extends Component {
  constructor(props) {
    super(props);
    const nav = props.initialPage
    this.state = {
      selection: nav 
    }
  }

  componentDidMount() {
    this.props.onSelect(this.state.selection);
  }

  select = value => {
    this.setState({
      selection: value
    }, () => {
      //localStorage.setItem("cal-nav", value);
    });
    this.props.onSelect(value);
  }
  
  render() {
    return <div className='uiCalendarSelectionMenu'>
      <UICalendarSelectionButton value='day' name="Day" selection={this.state.selection} select={this.select}/>
      <UICalendarSelectionButton value='week' name="Week" selection={this.state.selection} select={this.select}/>
      <UICalendarSelectionButton value='month' name="Month" selection={this.state.selection} select={this.select}/>
      {false && <UICalendarSelectionButton value='year' name="Year" selection={this.state.selection} select={this.select}/>}
      </div>
  }
}




class UICalendarMobi extends Component {
  constructor(props) {
    super(props);
    this.cal = React.createRef();
    this.events = {};
    this.appts = {};
    this.subs = {};
    this.meals = {}
    const page = this.props.initialPage || 'month'
    debugLog("calendar page: ", page)
    this.state = {
      workoutTypes: [],
      workoutTypeFilter: -1,
      scope: 'any',
      searchTerm: "",
      view: page,
      myEvents: [],
      activeEvents: {},
      calView: {
        calendar: page == 'day' ? null : { type: page },
        eventList: { type: page, scrollable: true }
      },
      currentDate: new Date(Date.now()),
      todoCategories: [{
        value: 'all',
        name: 'All Categories'
      }],
      todoEmotions: [{
        value: 'all',
        name: '⬇️  All Motivations'
      }],
      todoEmotion: 'all',
      todoStatusType: 'all',
      todoStatusTypes: [
        {
          selector: 'all',
          label: 'All'
        },
        {
          selector: 'pending',
          label: 'To-do'
        },
        {
          selector: 'progress',
          label: 'Progress'
        },
        {
          selector: 'done',
          label: 'Done'
        },
        {
          selector: 'canceled',
          label: 'Canceled'
        }
      ],
      todoOutcome: 'all',
      todoOutcomes: [
        {
          selector: 'all',
          label: 'All'
        },
        {
          selector: 'expected',
          label: 'As Expected'
        },
        {
          selector: 'better',
          label: isMobile() ? 'Better' :'Better than Expected'
        },
        {
          selector: 'worse',
          label: isMobile() ? 'Worse': 'Worse than Expected'
        },
        
      ],
      todoTerm: 'all',
      todoTermTypes: [
        {
          selector: 'all',
          label: 'All'
        },
        {
          selector: 'short-term',
          label: 'Short-term'
        },
        {
          selector: 'long-term',
          label: 'Long-term'
        }
      ],
      todoCategory: 'all',
      todoCategoryName: 'All Categories',
      todoEmotion: 'all',
      todoEmotionName: '⬇️  All Motivations',
      todoProfile: {
        todo: 0,
        done: 0,
        expected: 0,
        better: 0,
        worse: 0,
        canceled: 0
      },
      weightProfile: {
        last: 0,
        weights: [],
        avg: 0,
        min: 0,
        max: 0,
        start: 0,
        end: 0,
        calendar: { view: 'day', start: 0, end: 0 }
      }
    }
    //debugLog("contactFilter: ", this.getContactFilter());
    this.createWaiters = [];
    this.waiting = {};
    this.whoopCycleData = {}
    this.weights = {}
    this.ouraCycleData = {}


          let avgHeartRate = 0
          let maxHeartRate = 0
          let workoutStrain = 0
          let caloriesOut = 0
          let workoutCalories = 0
          let workoutDur = 0
          let workoutZones = []
          let workouts = []
          const workoutProfile = {
            avgHeartRate, maxHeartRate, strain: workoutStrain,
            caloriesOut: workoutCalories, workoutDur, workoutZones, workouts
          }
    //this.state.whoopWorkoutProfile = workoutProfile
          let needed = 0
          let sleepConsistency = 0
          let sleepEfficiency = 0
          let hrv = 0
          let inBed = 0
          let respiratoryRate = 0
          let score = 0
          let slept = 0
          const sleepProfile = {
            needed, sleepConsistency, sleepEfficiency, hrv, inBed, respiratoryRate, score, slept
          }
          this.state.fitbitSleepProfile = sleepProfile
          this.state.fitbitProfile = {
            type: 'fitbit',
            readiness: 0,
            caloriesIn: 0,
            rhr: 0,
            hrv: 0,
            caloriesOut: 0,
            activity: 0
          }
  }

  openSubscription = sub => {
    let contact = sub.contact;
    const me = this.props.me;
    if (!contact) {
      contact = sub.uid == me.self.uid ? me.self : me.getContact(sub.client);
    }
    return this.props.openContact(contact);
  }

  createEvent = appt => {
    const status = appt.status || "requested";
    const id = appt.id;
    const dur = appt.end - appt.start;
    const contact = appt.contact;
    const title = contact.displayName;
    const start = appt.start || appt.scheduled
    const end = appt.end || Date.now()
    const event = {
      id: id,
      start: new Date(start),
      end: new Date(end),
      text: title,
      desc: title,
      status: status,
      open: () => this.openEvent(event),
      ts: Date.now(),
      appt: appt
    }
    this.events[id] = event;
    this.appts[id] = appt;
  }

  componentDidMount() {
    debugLog("calendar mount", this.state.scope)
    if (this.props.contactFilter && !this.props.me.isTodoList()) {
      if (this.props.contactFilter.uid === this.props.me.self.uid) {
        this.setState({
          iShared: true,
          dataShared: true
        })
        this.props.me.observeFitbitLinked().subscribe(linked => {
          this.setState({
            fitbitLinked: linked,
            scaleLinked: linked || this.state.withingsLinked
          })
        })
        this.props.me.observeWithingsLinked().subscribe(linked => {
          this.setState({
            withingsLinked: linked,
            scaleLinked: linked || this.state.fitbitLinked
          })
        })
        this.whoopLinkSub = this.props.me.observeWhoopLinked().subscribe(async linked => {
          this.setState({
            whoopLinked: linked
          })
          if (linked) {
            this.getWhoopCycleData()
          }
        })
        this.garminLinkSub = this.props.me.observeGarminLinked().subscribe(async linked => {
          this.setState({
            garminLinked: linked
          })
          if (linked) {
            this.getGarminCycleData()
          }
        })
        this.getMeals()
        this.getOura()
        this.getWeight()
      } else {
        this.iSharedDataSub = this.props.me.observeISharedDataWith(this.props.contactFilter).subscribe(shared => {
          this.setState({
            iShared: shared
          })
        })
        this.dataSharedSub = this.props.me.observeDataSharedWithMe(this.props.contactFilter).subscribe(shared => {
          this.setState({
            dataShared: shared
          })
          if (shared) {
            this.getWhoopCycleData()
            this.getOura()
            //this.getFitbitCycleData()
            this.getGarminCycleData()
            this.getMeals()
            this.getWeight()
          } else {
            if (this.mealsSub) {
              this.mealsSub.unsubscribe()
            }
            if (this.whoopSub) {
              this.whoopSub.unsubscribe()
            }
          }
        })
      }
    }

    this.dateTimer = setInterval(this.checkDate,  5 * 60 * 1000)
    
    this.apptSub = this.props.me.observeAppointments().subscribe(change => {
      const appt = change.appointment;
      debugLog("appt ", change.type, ": ", appt);
      const id = appt.id;
      if (!appt.organizer) {
        return;
      }
      if (change.type == "removed" || (appt.organizer.uid != this.props.me.self.uid && appt.status == 'declined')) {
        delete this.appts[id];
        delete this.events[id];
      } else {
        this.createEvent(appt);
        //debugLog("event: ", this.events[id]);
      }
      const waiters = this.waiting[id];
      if (waiters) {
        delete this.waiting[id];
        waiters.map(resolve => resolve());
      } else if (this.createWaiters.length > 0) {
        for (var i = 0; i < this.createWaiters.length; i++) {
          const w = this.createWaiters[i];
          if (w.start == appt.start && w.end == appt.end &&
              w.contact.uid == appt.contact.uid) {
            this.createWaiters.splice(i, 1);
            w.resolve();
            break;
          }
        }
      }
      this.updateEventsLater();
    });

    
    this.subSub = this.props.me.observeSubscriptions().subscribe(change => {
      const sub = change.subscription;
      debugLog("sub: ", sub);
      const id = sub.uid + "-" + sub.client;
      if (change.type == 'removed' || sub.state == 'client-cancel' || sub.state == 'provider-cancel') {
        delete this.events[id];
        delete this.subs[id];
      } else {
        if (!sub.latestQuestion || sub.latestResponse >= sub.latestQuestion) {
          delete this.events[id];
          delete this.subs[id];
        } else {
          const title = sub.contact.displayName + " is waiting for your response"
          this.subs[id] = sub;
          const event = {
            id: id,
            start: new Date(sub.latestQuestion),
            end: new Date(this.props.me.getNextResponseTime(sub)),
            text: title,
            desc: title,
            open: () => this.openEvent(event),
            ts: sub.latestQuestion,
          }
          this.events[id] = event;
          debugLog("created event for sub: ", event);
        }
      }
      this.updateEventsLater();
    });
    this.props.onSet(this);
    if (this.props.visible && this.searchInput) {
      this.takeFocus();
    }
    if (!this.props.me.isTodoList()) {
      /*
      const ob = (this.getContactFilter() && this.getContactFilter().uid != this.props.me.self.uid) ?
            this.props.me.observeScheduledWorkouts(this.getContactFilter().uid) :
            this.props.me.observeMyScheduledWorkouts()'
      */
      const ob = (this.getContactFilter() && this.getContactFilter().uid != this.props.me.self.uid) ?
            this.props.me.observeWorkoutSessions(this.getContactFilter().uid) :
            this.props.me.observeMyWorkoutSessions()
      this.sub0 = ob.subscribe(async change => {
        const id = change.workout.id
        const sessionId = change.workout.sessionId
        if (change.type != 'removed') {
          const workout = change.workout
          const channel = [workout.client, workout.trainer].sort().join('-')
          const contact = await this.props.me.resolveContact(workout.trainer)
          const fakeAppt = {
            organizer: contact,
            contact: workout.activity,
            title: workout.description + ((workout.activity.uid == 45 || workout.activity.uid == 59) && workout.weight ? ' ' + workout.weight + ' lbs' : ''),
            client: workout.client,
            start: workout.start,
            end: workout.end,
            scheduled: workout.scheduled,
            id: workout.id,
            workout: workout,
            scheduledWorkout: workout,
            uid: workout.trainer,
            status: !workout.status || workout.status === 'started' ? 'todo' : workout.status,
            isClient: workout.client === this.props.me.self.uid && (workout.trainer !== this.props.me.self.uid || this.props.isMe)
          }
          console.log("scheduled workout:", fakeAppt)
          this.createEvent(fakeAppt)
        } else {
          const workout = change.workout
          delete this.events[workout.id]
          delete this.appts[workout.id]
        }
        this.onSystemUpdate(id)
        this.updateEventsLater()
      })
    }

    if (this.props.contactFilter && this.props.contactFilter.uid) {
      const devicesSub = this.props.me.observeContact(this.props.contactFilter.uid).subscribe(change => {
        console.log("contact devices", change.contact)
        this.setState({
          devices: change.contact.contact.devices
        })
      })
    }
  }

  workouts = {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.scope != prevState.scope) {
      debugLog("calendar scope update", this.state.scope)
    }
    if (this.props.visible && this.searchInput) {
      if (!prevProps.visible) {
        this.takeFocus();
      } else if (!this.state.openEvent && prevState.openEvent) {
        this.takeFocus();
      }
    }
    if (this.state.searchTerm != prevState.searchTerm) {
      this.updateEventsLater()
    }
  }

  takeFocus = () => {
    if (hasSoftKeyboard()) return;
    //if (this.searchInput && !this.getContactFilter()) this.searchInput.focus();
  }

  componentWillUnmount() {
    if (this.whoopLinkSub) this.whoopLinkSub.unsubscribe()
    if (this.apptSub) this.apptSub.unsubscribe()
    if (this.subSub) this.subSub.unsubscribe()
    if (this.iSharedDataSub) this.dataSharedSub.unsubscribe()
    if (this.dataSharedSub) this.dataSharedSub.unsubscribe()
    if (this.mealsSub) this.mealsSub.unsubscribe()
    if (this.whoopSub) this.whoopSub.unsubscribe()
    if (this.whoopCycleSub) this.whoopCycleSub.unsubscribe()
    if (this.sub0) this.sub0.unsubscribe()
    if (this.weightSub) this.weightSub.unsubscribe()
    if (this.devicesSub) this.devicesSub.unsubscribe()
    clearInterval(this.dateTimer)
  }

  checkDate = () => {
    const currentDate = startOfDay(new Date())
    if (!this.currentDate) {
      this.currentDate = currentDate
    } else {
      if (this.currentDate !== currentDate) {
        this.currentDate = currentDate
        const when = new Date(currentDate)
        const cal = this.cal.current;
        cal.instance.navigate(when)
        this.setState({
          currentDate: when
        });
      }
    }
  }


  whoopSeq = 0

  getWhoopCycleData = () => {
    if (!this.getContactFilter()) return
    const when = this.state.currentDate
    let start, end
    if (this.state.view == 'year') {
      start = startOfYear(when) - ONE_DAY
      end = endOfYear(when)
    } else {
      start = startOfCycle(when) - ONE_DAY
      end = endOfMonth(when)
    }
    const cycleStart = start
    const cycleEnd = end
    if (this.whoopSub && cycleStart == this.cycleStart && cycleEnd == this.cycleEnd) {
      return
    }
    this.cycleStart = cycleStart
    this.cycleEnd = cycleEnd
    for (const id in this.whoopCycleData) {
      delete this.events[id]
      delete this.appts[id]
    }
    if (this.state.whoopLinked) {
      this.resetWhoopProfiles()
    }
    this.whoopCycleData = {}
    if (this.whoopSub) {
      this.whoopSub.unsubscribe()
    }
    this.resetWhoopProfiles()
    const seq = ++this.whoopSeq
    this.whoopSub = this.props.me.observeWhoopCycleData(this.getContactFilter().uid, this.cycleStart, this.cycleEnd).subscribe(change => {
      if (seq !== this.whoopSeq) return
      if (change.type == 'removed') {
        delete this.whoopCycleData[change.cycle.id]
      } else {
        this.whoopCycleData[change.cycle.id] = change.cycle
      }
      debugLog('got cycle', change.cycle)
      this.updateWhoopCycleDataLater()
    })
  }

  getTodoCycleDataLater = () => {
    clearTimeout(this.todoCycleTimeout)
    this.todoCycleTimeout = setTimeout(this.getTodoCycleData, 400)
  }
  
  getTodoCycleData = () => {
    if (!this.getContactFilter()) return
    const when = this.state.currentDate
    let start, end
    if (this.state.view == 'year') {
      start = startOfYear(when) - ONE_DAY
      end = endOfYear(when)
    } else {
      start = startOfCycle(when) - ONE_DAY
      end = endOfMonth(when)
    }
    const cycleStart = start
    const cycleEnd = end
    if (this.todoSub && cycleStart == this.cycleStart && cycleEnd == this.cycleEnd) {
      this.updateTodoDataLater()
      return
    }
    this.cycleStart = cycleStart
    this.cycleEnd = cycleEnd
    if (this.props.contactFilter.uid === this.props.me.self.uid) {
      const seq1 = ++this.todoSeq
      this.todoSub = this.props.me.observeTodo(this.cycleStart, this.cycleEnd).subscribe(change => {
        if (seq1 !== this.todoSeq) return
        delete this.appts[change.todo.id]
        if (!change.todo.todo.category) {
          change.todo.todo.category = "General"
        }
        if (change.type === 'removed') {
          const item = this.todoData[change.todo.id]
          if (item) {
            if (false && item.progress) {
              item.progress.forEach(el => {
                delete this.todoData[el.end]
              })
            }
          }
          delete this.todoData[change.todo.id]
        } else {
          if (false && change.todo.progress) {
            for (const el of change.todo.progress) {
              const data = clone(change.todo)
              data.on = change.todo.id
              data.id = el.end
              data.end = el.end
              data.todo.notes = el.notes
              data.status = 'progress'
              data.outcome = el.outcome
              data.progress = el
              this.todoData[data.id] = data
            }
          }
          this.todoData[change.todo.id] = change.todo
        }
        this.updateTodoDataLater()
      })
    }
  }

  todoData = {}

  todoSeq = 0

  summarySeq = 0

  getWhoopSummaries = async () => {
    if (!this.state.whoopLinked) return
    console.log("summary date", this.state.currentDate)
    if (this.state.view === 'year') {
      if (this.state.weeklySummaryURL || this.state.monthlySummaryURL) {
        this.setState({
          weeklySummaryURL: null,
          monthlySummaryURL: null
        })
      }
      return
    }
    const week = moment(new Date(this.state.currentDate)).startOf('isoweek').toDate().getTime()
    const month = moment(new Date(this.state.currentDate)).startOf('month').toDate().getTime()
    let weekly = this.state.weeklySummaryURL
    let monthly = this.state.monthlySummaryURL
    console.log("summary before", weekly, monthly)
    if (this.weeklySummary !== week) {
      weekly = null
    }
    if (this.monthlySummary !== month) {
      monthly = null
    }
    if (!weekly || !monthly) {
      console.log("summary changed", weekly, monthly, week, month, this.weeklySummary, this.monthlySummary)
      this.weeklySummary = week
      this.monthlySummary = month
      this.setState({
        weeklySummaryURL: weekly,
        monthlySummaryURL: monthly
      })
      const seq = ++this.summarySeq
      const op1 = !weekly ? this.props.me.getWhoopWeekly(this.getContactFilter().uid, week) : Promise.resolve({url: weekly })
      const op2 = !monthly ? this.props.me.getWhoopMonthly(this.getContactFilter().uid, month) : Promise.resolve({url: monthly})
      const result = await Promise.all([op1, op2])
      console.log("summary", result)
      const [weekly, monthly] = result
      if (seq === this.summarySeq) {
        this.setState({
          weeklySummaryURL: weekly.url,
          monthlySummaryURL: monthly.url
        })
      }
    }
  }

  updateWhoopCycleDataLater = () => {
    clearTimeout(this.whoopCycleDataTimer)
    this.whoopCycleDataTimer = setTimeout(this.updateWhoopCycleDataNow, 200)
  }

  updateWhoopCycleDataNow = () => {
    const cycles = Object.values(this.whoopCycleData)
    let prevSleep
    let prevWorkout
    let prevCycle
    cycles.sort((a, b) => {
      const start1 = Date.parse(a.during.lower)
      const start2 = Date.parse(b.during.lower)
      return start1 - start2
    })
    let bedtime
    cycles.forEach(cycle => {
      if (prevCycle) {
        cycle.prevId = prevCycle.id
      }
      prevCycle = cycle
      if (cycle.sleep) {
        let cycleDateStart
        let cycleEndDate
        if (cycle.during.upper) {
          cycleEndDate = Date.parse(cycle.during.upper)
        } else {
          cycleEndDate = Date.now()
        }
        cycleDateStart = moment(cycleEndDate - 24 * 60 * 60 * 1000).toDate()
        cycleDateStart.setHours(0)
        cycleDateStart.setMinutes(0)
        cycleDateStart.setSeconds(0)
        cycleDateStart.setMilliseconds(0)
        cycleDateStart = cycleDateStart.getTime()
        console.log("bedtime cycle", moment(cycleDateStart).format("MM/DD"), moment(cycleEndDate).format("MM/DD"))
        let bedtimeOffset = 0
        let bedtimeCount = 0
        cycle.sleep.sleeps.forEach(sleep => {
          sleep.cycleId = cycle.id
          sleep.cycleStart = Date.parse(cycle.during.lower)
          sleep.type = 'sleep'
          prevSleep = sleep
          sleep.recovery = cycle.recovery
          sleep.needBreakdown = cycle.sleep.needBreakdown
          const when = Date.parse(sleep.during.lower)
          console.log('bedtime when', moment(when).format('MM/DD'))
          let offset = when - cycleDateStart
          if (isNaN(offset)) {
            console.log("bedtime fail", sleep.during.lower, cycle.during.lower, cycle.during.upper)
            return
          }
          if (offset < 12 * 60 * 60 * 1000) {
            offset += 24 * 60 * 60 * 1000
          }
          console.log('bedtime offset', offset / (1000 * 60 * 60)
)
          bedtimeCount++
          bedtimeOffset += offset
        })
        if (bedtimeCount > 0) {
          cycle.bedtime = bedtimeOffset / bedtimeCount
        }
        cycle.sleep.naps.forEach((sleep, i) => {
          sleep.cycleId = cycle.id
          sleep.type = 'nap'
          sleep.recovery = cycle.recovery
          sleep.needBreakdown = cycle.sleep.needBreakdown
        })
      }
      if (cycle.strain) {
        cycle.strain.workouts.forEach(workout => {
          if (prevWorkout) {
            workout.prevId = prevWorkout.id
          }
          prevWorkout = workout
        })
      }
    })
    this.finishWhoopData(this.cycleStart, this.cycleEnd, cycles)
  }

  fitbitSeq = 0

  getFitbitCycleData = () => {
    if (true) return
    if (!this.getContactFilter()) return
    if (!(this.getContactFilter().uid == this.props.me.uid ||
          this.state.dataShared)) {
      return
    }
    const when = this.state.currentDate
    const start = startOfCycle(when) - ONE_DAY
    const end = endOfMonth(when)
    if (this.fitbitSub && this.fitbitStart == start && this.fitbitEnd == end) {
      this.onLoadCycleDataLater()
      return
    }
    if (this.fitbitSub) {
      this.fitbitSub.unsubscribe()
    }
    const seq = ++this.fitbitSeq
    this.fitbitStart = start
    this.fitbitEnd = end
    for (const id in this.fitbitCyles) {
      delete this.events[id]
      delete this.appts[id]
    }
    this.fitbitCycles = null
    if (false) this.fitbitSub = this.props.me.observeFitbitCycleData(this.getContactFilter().uid, start, end).subscribe(change => {
      if (!this.state.fitbitLinked) {
        this.state.fitbitLinked = true
      }
      if (seq !== this.fitbitSeq) return
      const cycle = change.cycle
      if (change.type == 'removed') {
        delete this.fitbitCycles[cycle.id]
        delete this.events[cycle.id]
        delete this.appts[cycle.id]
      } else {
        if (!this.fitbitCycles) this.fitbitCycles = {}
        this.fitbitCycles[cycle.id] = cycle
      }
      this.updateFitbitCyclesLater()
    })
  }

  updateFitbitCyclesLater = () => {
    clearTimeout(this.fitbitCycleTimer)
    this.fitbitCycleTimer = setTimeout(this.updateFitbitCyclesNow, 200)
  }

  updateFitbitCyclesNow = () => {
    const me = this.props.me
    const contact = this.getContactFilter()
    for (const id in this.fitbitCycles) {
      const cycle = this.fitbitCycles[id]
        //debugger
      if (cycle.activities) cycle.activities.forEach(workout => {
        const begin = Date.parse(workout.startTime)
        const end = begin + workout.duration
        const dur = moment.duration(end - begin, "milliseconds").format("h:mm", { trim: false } )
        let details = me.getWhoop().mapFromName(workout.activityType)
        if (details.id === -1) {
          details = me.getWhoop().mapFromName(workout.activityName)
        }
        let maxHeartRate = 0
        const upper = moment(new Date(end)).format('yyyy-MM-DDTHH:mm:ss')
        workout.heartRateZones.forEach(zone => {
          if (zone.minutes > 0) {
            maxHeartRate = Math.max((zone.min + zone.max) / 2, maxHeartRate)
          }
        })
        const wkout = {
          sportId: details.id,
          id: ''+workout.logId,
          duration: workout.duration,
          averageHeartRate: workout.averageHeartRate,
          maxHeartRate: Math.round(maxHeartRate),
          during: {
            lower: workout.startTime,
            upper
          },
          score: 0,
          kilojoules: workout.calories * 4.184,
          start: Date.parse(workout.startTime),
          end: Date.parse(workout.startTime + workout.duration),
          zones: [0].concat(workout.heartRateZones.map(zone => zone.minutes * 60 * 1000)).concat([0])
        }
        const fakeAppt = {
            contact: {
              displayName: details.name,
              profileImage: details.iconUrl,
              uid: contact.uid
            },
            organizer: contact,
            title: details.name,
            titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>{details.name} {dur}</div>&nbsp;</div>,
            client: me.self.uid,
            start: begin,
            end: end,
            workout: wkout,
            status: 'completed',
            id: wkout.id
        }
        this.createEvent(fakeAppt)
      })
    }
    this.updateEventsNow()
  }


  garminActivityDetails = {}

  garminSeq = 0

  getGarminCycleData = () => {
    if (!this.getContactFilter()) return
    if (!(this.getContactFilter().uid == this.props.me.uid ||
          this.state.dataShared)) {
      return
    }
    const when = this.state.currentDate
    const start = startOfCycle(when) - ONE_DAY
    const end = endOfMonth(when)
    if (this.garminSub && this.garminStart == start && this.garminEnd == end) {
      this.onLoadCycleDataLater()
      return
    }
    if (this.garminSub) {
      this.garminSub.unsubscribe()
    }
    const seq = ++this.garminSeq
    this.garminStart = start
    this.garminEnd = end
    for (const id in this.garminCyles) {
      delete this.events[id]
      delete this.appts[id]
    }
    this.resetGarminProfiles()
    this.garminCycles = null
    this.garminSub = this.props.me.observeGarminCycleData(this.getContactFilter().uid, start, end).subscribe(change => {
      //debugger
      if (!this.state.garminLinked) {
        this.state.garminLinked = true
      }
      if (seq !== this.garminSeq) return
      const cycle = change.cycle
      if (change.type == 'removed') {
        delete this.garminCycles[cycle.id]
        delete this.events[cycle.id]
        delete this.appts[cycle.id]
      } else {
        if (!this.garminCycles) this.garminCycles = {}
        this.garminCycles[cycle.id] = cycle
        cycle.summaryId = cycle.id
        const { type, calendarDate} = cycle
        const addToDay = () => {
          if (!this.garminDay) {
            this.garminDay = {}
          }
          if (!this.garminDay[calendarDate]) {
            this.garminDay[calendarDate] = { calendarDate, day: {calendarDate} }
          }
          const getCurrent = () => (this.garminDay &&
                                    this.garminDay[calendarDate] &&
                                    this.garminDay[calendarDate][type] &&
                                    this.garminDay[calendarDate][type][0])
          const clear = () => {
            if (this.garminDay[calendarDate][type]) {
              this.garminDay[calendarDate][type].length = 0
            }
          }
          const push = (cycle) => {
            if (!this.garminDay[calendarDate][type]) {
              this.garminDay[calendarDate][type] = [cycle]
            } else {
              this.garminDay[calendarDate][type].push(cycle)
            }
          }
          console.log('got garmin daily', type, cycle)
          if (type === 'dailies' || type === 'stressDetails') { // these need to be overwritten not accumulated
            const current = getCurrent()
            if (current) {
              if (current.end < cycle.end) {
                clear()
              } else {
                console.log("not replacing", type, cycle)
                return
              }
            }
          }
          console.log("replacing", type, cycle)
          push(cycle)
        }
        const me = this.props.me
        const contact = this.getContactFilter()
        console.log("got garmin cycle", type, cycle)
        switch (type) {
          case 'dailies':
          case 'stressDetails':
            {
              if (calendarDate) {
                addToDay()
              }
            }
            break
          case 'activityDetails':
            {
              const id = '' + cycle.activityId
              this.garminActivityDetails[id] = cycle
              if (this.appts[id]) {
                this.appts[id].workout.garmin.activityDetails = cycle
                this.forceUpdate()
              }
            }
            break
          case 'activities':
            {
              cycle.activityDetails = this.garminActivityDetails[cycle.summaryId]
              //debugger
              const fakeAppt = cycleToAppointment(this.props.me, this.getContactFilter(), 'garmin', 'workout', cycle)
              this.createEvent(fakeAppt)
              console.log("created garmin event", fakeAppt)
            }
            break
          case 'sleeps':
            {
              console.log('garmin sleep', cycle.validation, cycle)
              if (!cycle.score || cycle.validation.startsWith('AUTO')) {
                break
              }
              const prev = this.garminDay &&
                    this.garminDay[calendarDate] &&
                    this.garminDay[calendarDate].sleeps &&
                    this.garminDay[calendarDate].sleeps[0]
              if (prev) {
                const prevVersion = parseInt(prev.summaryId.split('-')[2], 16)
                const version = parseInt(cycle.summaryId.split('-')[2], 16)
                console.log("garmin sleep version" , prevVersion, '=>', version)
                if (prevVersion > version) {
                  break
                }
                delete this.appts[prev.summaryId]
                delete this.events[prev.summaryId]
              }
              addToDay()
              const fakeAppt = cycleToAppointment(this.props.me, this.getContactFilter(), 'garmin', 'sleep', cycle)
              this.createEvent(fakeAppt)
              console.log("created garmin event", fakeAppt)              
            }
            break
        }
      }
      this.updateGarminCyclesLater()
      this.updateEventsLater()
    })
  }

  updateGarminCyclesLater = () => {
    clearTimeout(this.garminCycleTimer)
    this.garminCycleTimer = setTimeout(this.updateGarminCyclesNow, 200)
  }

  updateGarminCyclesNow = () => {
    if (!this.state.garminLinked) return
    const me = this.props.me
    const contact = this.getContactFilter()
    const contaxMax = me.getMaxHeartRate(contact.uid) 
    let readiness = 0
    let caloriesIn = 0
    let rhr = 0
    let hrv = 0

    let needed = 0
    let sleepEfficiency = 0
    let sleepConsistency = 0
    let inBed = 0
    let respiratoryRate = 0
    let sleepScore = 0
    let slept = 0
    let spo2 = 0
    
    let caloriesOut = 0
    let activity = 0
    let numReadiness = 0
    let numActivity = 0
    let numSleep = 0
    let numCycles = 0

    let avgHeartRate = 0
    let maxHeartRate = 0
    let workoutDur = 0
    let workoutCalories = 0
    let workoutStrain = 0
    let workoutZones = [
    ]
    let workoutDays = 0
    let workouts = 0
    let heartRateWorkouts = 0
    const filter = this.getDateFilter()
    const { start, end } = filter.endPoints
    console.log("date filter", new Date(start), new Date(end))
    const calendarDates = {}
    const dailies = {}
    const getCalendarDate = (when) => {
      return moment(new Date(when)).local().format('yyyy-MM-DD')
    }
    const getDailyByCalendarDate = calendarDate => {
      if (!dailies[calendarDate]) {
        dailies[calendarDate] = {
          recovery: 0,
          activity: 0,
          rhr: 0,
          caloriesOut: 0,
          stressLevel: 0,
          
          sleepNeed: 0,
          sleepEfficiency: 0,
          inBedDuration: 0,
          respiratoryRate: 0,
          sleepScore: 0,
          sleeps: 0,
          slept: 0,
          remSleepDuration: 0,
          slowWaveSleepDuration: 0,
          lightSleepDuration: 0,
          wakeDuration: 0,
          spo2: 0,
          
          workoutDur: 0,
          avgHeartRate: 0,
          workoutCalories: 0,
          workouts: 0,
          maxHeartRate: 0,
          workoutScore: 0,
          calendarDate
        }
      }
      const day = dailies[calendarDate]
      return day
    }
    const getDaily = (when) => {
      const calendarDate = getCalendarDate(when)
      return getDailyByCalendarDate(calendarDate)
    }
    for (const apptId in this.appts) {
      const appt = this.appts[apptId]
      if (appt.start > end || appt.end < start) {
        continue
      }
      const workout = appt.workout
      if (workout) {
        if (this.state.workoutTypeFilter !== -1 && this.state.workoutTypeFilter !== workout.sportId) {
          continue
        }
        if (!workout.garmin) continue
        const calendarDate = moment(new Date(workout.start)).startOf("day").toDate().getTime()
        if (!calendarDates[calendarDate]) {
          calendarDates[calendarDate] = true
          workoutDays++
        }
        const day = getDaily(workout.start)
        day.workouts++
        day.workoutScore += workout.score
        workoutStrain += workout.score
        if (workout.averageHeartRate) {
          avgHeartRate += workout.averageHeartRate
          workoutCalories += workout.kilojoules / 4.148
          maxHeartRate = Math.max(maxHeartRate, workout.maxHeartRate)
          day.avgHeartRate += workout.averageHeartRate
          day.maxHeartRate = Math.max(day.maxHeartRate, workout.maxHeartRate)
          day.workoutCalories += workout.kilojoules / 4.148
        }
        workoutDur += appt.end - appt.start
        day.workoutDur += appt.end - appt.start
        workouts++
      }
    }
    let days = 0
    let j = 0
    let activityDays = 0
    let readinessDays = 0
    let stressDays = 0
    let stressLevel = 0
    let battery = 0
    let rhrDays = 0
    let dailyDays = 0
    console.log("garminDay", this.garminDay)
    const cycleData = []
    for (const calendarDate in this.garminDay) {
      j++
      const day = this.garminDay[calendarDate]
      const { dailies, stressDetails, activities, sleeps } = day
      let includeDay = false
      if (dailies) {
        dailies.sort((x, y) => y.end - x.end)
        let rhrMin = 0
        let dailyStress = 0
        let dailyStressDur = 0
        dailies.forEach((daily, i) => {
          if (daily.start > end || daily.end < start) return
          if (true || this.state.view === 'day') {
            if (daily.start < start) return
          }
          const graph = getDailyByCalendarDate(calendarDate)
          if (daily.restingHeartRateInBeatsPerMinute) {
            rhrMin = Math.min(daily.restingHeartRateInBeatsPerMinute, rhrMin || 999999)
            rhrDays++
          }
          graph.rhr = daily.restingHeartRateInBeatsPerMinute
          includeDay = true
          graph.caloriesOut += daily.bmrKilocalories + daily.activeKilocalories
          graph.stressLevel += daily.averageStressLevel
          caloriesOut += daily.bmrKilocalories + daily.activeKilocalories
          console.log('dailyStress', j, i, daily.calendarDate, daily.averageStressLevel)
          dailyStress += daily.averageStressLevel * daily.durationInSeconds
          dailyStressDur += daily.durationInSeconds
          dailyDays++
        })
        rhr += rhrMin
        if (dailyStressDur > 0) {
          //stressLevel += dailyStress / dailyStressDur
        }
      }
      if (stressDetails) {
        let batteryMax = 0
        stressDetails.sort((x, y) => x.end - y.end)
        let d = false
        let first = 0
        let firstT = -1
        let last = 0
        let max = 0
        let maxT = 0
        let min = 0
        let minT = -1
        let t = -1
        let stress = 0
        let stressAtMin = 0
        let pos = 0
        let neg = 0
        let keys = []
        stressDetails.forEach((stressDetail, i) => {
          if (stressDetail.start > end || stressDetail.end < start) return
          d = true
          for (let offset in stressDetail.timeOffsetBodyBatteryValues) {
            offset = Number(offset)
            keys.push(offset)
          }
          includeDay = true
          keys.sort()
          keys.forEach((offset, k) => {
            const value = stressDetail.timeOffsetBodyBatteryValues[offset]
            const when = stressDetail.start + offset * 1000
            if (when < start || when > end) {
              return
            }/*
            if (k > 0) {
              const prev = keys[k-1]
              const prevValue = stressDetail.timeOffsetBodyBatteryValues[prev]
              const delta = value - prevValue
              console.log('delta', k, delta)
              if (delta < 0) {
                neg += delta
              } else {
                pos += delta
              }
            }
             */
            if (value > max) {
              max = value
              maxT = offset
              stress = stressDetail.timeOffsetStressLevelValues[maxT]
            }
            if (minT < 0 || value < min) {
              min = value
              minT = offset
              stressAtMin = stressDetail.timeOffsetStressLevelValues[minT]
            }
            if (offset > t) {
              t = offset
              last = value
            }
            if (firstT < 0 || offset < firstT) {
              firstT = offset
              first = value
            }
          })
        })
        console.log({
          pos, neg, firstT, first, lastT: t, last, maxT, max, minT, min
        })
        const graph = getDailyByCalendarDate(calendarDate)
        if (stress) {
          stressLevel += stress
          stressDays++
          graph.stressLevel = Math.max(stress, 0)
        }
        if (max) {
          //readiness += 100 - stressLevel
          const charged = max - first
          const drained = max - last
          graph.recovery = first + charged
          graph.activity = drained
          readiness += first + charged
          activity += drained
          activityDays++
          battery += last
        }
      }
      if (includeDay) cycleData.push(getDailyByCalendarDate(calendarDate))
    }
    console.log("garminCycleData", this.garminCycleData)
    if (dailyDays) {
      caloriesOut /= dailyDays
    }
    if (activityDays) {
      activity /= activityDays
      readiness /= activityDays
      battery /= activityDays
    }
    if (rhrDays) {
      rhr /= rhrDays
    }
    if (stressDays) {
      stressLevel /= stressDays
    }
    const profile = {
      type: 'garmin',
      readiness: Math.round(readiness),
      caloriesIn,
      rhr: Math.round(rhr),
      hrv: 0,
      battery,
      stressLevel: Math.round(stressLevel),
      caloriesOut: Math.round(caloriesOut),
      activity: Math.round(activity)
    }
    if (workouts > 0) {
      avgHeartRate /= workouts
    }
    console.log('calendarDates', calendarDates)
    if (workoutDays > 0) {
      workoutDur /= workoutDays
      workoutCalories /= workoutDays
      workoutStrain /= workoutDays
    }
    const workoutProfile = {
      avgHeartRate, maxHeartRate, battery: workoutStrain , 
      caloriesOut: workoutCalories, workoutDur, workoutZones, workouts
    }
    let sleeps = 0
    for (const apptId in this.appts) {
      const appt = this.appts[apptId]
      if (appt.end < start || appt.start > end) {
        continue
      }
      const sleep = appt.sleep
      if (sleep) {
        if (!sleep.score) continue
        const calendarDate = sleep.garmin.calendarDate
        const day = getDailyByCalendarDate(calendarDate)
        console.log("sleep", sleep)
        day.sleeps++
        day.sleepNeed += sleep.needBreakdown.total
        day.sleepEfficiency += sleep.sleepEfficiency
        day.inBedDuration += sleep.inBedDuration
        day.respiratoryRate += sleep.respiratoryRate
        day.sleepScore += sleep.score
        day.slept += sleep.qualityDuration
        day.remSleepDuration += sleep.remSleepDuration
        day.slowWaveSleepDuration += sleep.slowWaveSleepDuration
        day.lightSleepDuration += sleep.lightSleepDuration
        day.wakeDuration += sleep.wakeDuration
        needed += sleep.needBreakdown.total
        day.spo2 += (sleep.garmin.averageSpo2 || 0)
        spo2 += (sleep.garmin.averageSpo2 || 0)
        sleepEfficiency += sleep.sleepEfficiency
        sleepConsistency += sleep.sleepConsistency
        inBed += sleep.inBedDuration
        respiratoryRate += sleep.respiratoryRate
        if (sleep.score) {
          sleepScore += sleep.score
        }
        slept += sleep.qualityDuration
        sleeps++
      }
    }
    
    if (sleeps > 0) {
      needed /= sleeps
      sleepEfficiency /= sleeps
      sleepConsistency /= sleeps
      inBed /= sleeps
      respiratoryRate /= sleeps
      sleepScore /= sleeps
      slept /= sleeps
      spo2 /= sleeps
    }

    for (const calendarDate in dailies) {
      const day = dailies[calendarDate]
      console.log('daily', calendarDate, day)
      const target = day
      target.sleepNeed = day.sleepNeed / (day.sleeps || 1)
      target.sleepEfficiency = day.sleepEfficiency / (day.sleeps || 1)
      target.inBedDuration = day.inBedDuration / (day.sleeps || 1)
      target.respiratoryRate = day.respiratoryRate / (day.sleeps || 1)
      target.sleepScore = day.sleepScore / (day.sleeps || 1)
      target.sleeps = day.sleeps
      target.slept = day.slept / (day.sleeps || 1)
      target.remSleepDuration = day.remSleepDuration / (day.sleeps || 1)
      target.slowWaveSleepDuration = day.slowWaveSleepDuration / (day.sleeps || 1)
      target.lightSleepDuration = day.lightSleepDuration / (day.sleeps || 1)
      target.wakeDuration = day.wakeDuration / (day.sleeps || 1)

      target.workoutDur = day.workoutDur / (day.workouts || 1)
      target.avgHeartRate = day.avgHeartRate / (day.workouts || 1)
      target.workoutCalories = day.workoutCalories / (day.workouts || 1)
      target.workouts = day.workouts / (day.workouts || 1)
      target.workoutScore = day.workoutScore / (day.workouts || 1)
      target.maxHeartRate = day.maxHeartRate
    }
    this.garminCycleData = cycleData.filter(day => {
      for (const i in day) {
        if (i !== 'calendarDate') {
          if (day[i]) return true
        }
      }
      return false
    })
    
    const sleepProfile = {
      needed, sleepConsistency, sleepEfficiency, hrv, inBed, respiratoryRate, score: sleepScore, slept, spo2,
    }

    console.log("sleepProfile", sleepProfile)
    
    this.setState({
      garminProfile: profile,
      garminWorkoutProfile: workoutProfile,
      garminSleepProfile: sleepProfile
    })
    this.updateWorkoutTypes()
  }

  ouraSeq = 0

  getOura = () => {
    if (!this.getContactFilter()) return
    if (!(this.getContactFilter().uid == this.props.me.uid ||
          this.state.dataShared)) {
      return
    }
    const when = this.state.currentDate
    const start = startOfCycle(when) - ONE_DAY
    const end = endOfMonth(when)
    if (this.ouraSub && this.ouraStart == start && this.ouraEnd == end) {
      this.onLoadCycleDataLater()
      return
    }
    if (this.ouraSub) {
      this.ouraSub.unsubscribe()
    }
    this.resetOuraProfiles()
    const seq = ++this.ouraSeq
    this.ouraStart = start
    this.ouraEnd = end
    for (const id in this.ouraCyles) {
      delete this.events[id]
      delete this.appts[id]
    }
    this.ouraCycles = null
    this.ouraSub = this.props.me.observeOuraCycleData(this.getContactFilter().uid, start, end).subscribe(change => {
      if (seq !== this.ouraSeq) return
      const cycle = change.cycle
      if (change.type == 'removed') {
        delete this.ouraCycles[cycle.id]
        delete this.events[cycle.id]
        delete this.appts[cycle.id]
      } else {
        if (change.type != 'added') {
          delete this.events[cycle.id]
          delete this.appts[cycle.id]
        }
        if (!this.ouraCycles) this.ouraCycles = {}
        this.ouraCycles[cycle.id] = cycle
      }
      this.updateOuraCyclesLater()
    })
  }

  todoOutcomeFilter = (item) => {
    const outcome = item.outcome || 0
    switch (this.state.todoStatusType) {
      case 'done':
        switch (this.state.todoOutcome) {
          case 'all':
            break
          case 'expected':
            if (outcome !== 0) return false
            break
          case 'better':
            if (outcome <= 0) return false
            break
          case 'worse':
            if (outcome >= 0) return false
            break
        }
      case 'progress':
        if (item.progress) {
          const found = item.progress.find(x => {
            const { outcome } = x
            switch (this.state.todoOutcome) {
              case 'all':
                break
              case 'expected':
                if (outcome !== 0) return false
                break
              case 'better':
                if (outcome <= 0) return false
                break
              case 'worse':
                if (outcome >= 0) return false
                break
            }
            return true
          })
          if (!found) return false
        }
        break
    }
    return true
  }
  

  updateTodoDataLater = () => {
    clearTimeout(this.todoCycleUpdate)
    this.todoCycleUpdate = setTimeout(this.updateTodoDataNow)
  }

  updateTodoDataNow = () => {
    let todo = 0
    let done = 0
    let expected = 0
    let likes = 0
    let dislikes = 0
    let better = 0
    let worse = 0
    let canceled = 0
    const filt = this.getDateFilter()
    const dateStart = filt.endPoints.start
    const dateEnd = filt.endPoints.end
    const self = this
    const todoCats = {}
    const todoEmotions = {}
    const options = []
    options.push({
      value: 'all',
      name: 'All Categories'
    })
    const emotOptions = []
    emotOptions.push({
      value: 'all',
      name: <div className='todoEmotionSelection'><div className='todoEmojiIcon'>⬇️</div><div className='todoEmotionName'>All Motivations</div></div>
    })
    for (const id in this.todoData) {
      const item = this.todoData[id]
      const start = item.start
      const end = item.end
      if (start > dateEnd + ONE_DAY) {
        continue
      }
      if (item.todo.status !== 'pending') {
        if (end && end < dateStart) {
          continue
        }
      }
      console.log('todo item', item)
      if (!this.appts[item.id]) {
        const fakeAppt = todoToAppointment(this.props.me, item)
        if (fakeAppt) {
          this.createEvent(fakeAppt)
        }
      }
      const outcome = item.outcome || 0
      switch (this.state.todoStatusType) {
        case 'done':
          switch (this.state.todoOutcome) {
            case 'all':
              break
            case 'expected':
              if (outcome !== 0) continue
              break
            case 'better':
              if (outcome <= 0) continue
              break
            case 'worse':
              if (outcome >= 0) continue
              break
          }
        case 'progress':
          if (item.progress) {
            const found = item.progress.find(x => {
              const { outcome } = x
              switch (this.state.todoOutcome) {
                case 'all':
                  break
                case 'expected':
                  if (outcome !== 0) return false
                  break
                case 'better':
                  if (outcome <= 0) return false
                  break
                case 'worse':
                  if (outcome >= 0) return false
                  break
              }
              return true
            })
            if (!found) continue
          }
          break
      }
      if (this.state.todoTerm !== 'all') {
        if (item.todo.term !== this.state.todoTerm) {
          continue
        }
      }
      if (this.state.todoStatusType === 'progress') {
        if (!(item.status === 'pending' && item.progress)) {
          continue
        }
      } else if (this.state.todoStatusType !== 'all') {
        if (item.status !== this.state.todoStatusType) {
          continue
        }
      }
      if (this.state.todoEmotion !== 'all') {
        if (!item.todo.emotion.toLowerCase().split('/').find(x => x == this.state.todoEmotion)) {
          continue
        }
      }
      if (false && item.progress) {
        for (const progress of item.progress) {
          const { end, outcome } = progress
          switch (this.state.todoOutcome) {
            case 'all':
              break
            case 'expected':
              if (outcome !== 0) continue
              break
            case 'better':
              if (outcome <= 0) continue
              break
            case 'worse':
              if (outcome >= 0) continue
              break
          }
        }
      }
      if (item.todo.emotion) {
        const emotions = item.todo.emotion.toLowerCase().split('/')
        emotions.map(emotion => {
          let emot = todoEmotions[emotion]
          if (!emot) {
            emot = {
              todo: 0,
              done: 0,
              canceled: 0,
              better: 0,
              worse: 0,
              expected: 0,
              likes: 0,
              dislikes: 0
            }
            todoEmotions[emotion] = emot
            emotOptions.push({
              value: emotion,
              name: <div className='todoEmotionSelection'><div className='todoEmojiIcon'>{getEmojiForEmotion(emotion)}</div><div className='todoEmotionName'>{capitalize(emotion)}</div></div>
            })
          }
        })
      }
      const catId = item.todo.category.toLowerCase()
      let cat = todoCats[catId]
      if (!cat) {
        cat = {
          todo: 0,
          done: 0,
          canceled: 0,
          better: 0,
          worse: 0,
          expected: 0,
          likes: 0,
          dislikes: 0
        }
        todoCats[catId] = cat
        options.push({
          value: catId,
          name: capitalize(item.todo.category)
        })
      }
      switch (item.status) {
        case 'pending':
          todo++
          cat.todo++
          break
        case 'done':
          done++
          cat.done++
          if (!item.outcome) {
            expected++
            cat.expected++
          } else if (item.outcome > 0) {
            likes += item.outcome
            better++
            cat.better++
            cat.likes += item.outcome
          } else {
            dislikes += item.outcome
            worse++
            cat.worse++
            cat.dislikes += item.outcome
          }
          break
        case 'canceled':
          canceled++
          cat.canceled++
          break
      }
      if (item.progress) {
        item.progress.forEach(progress => {
          const { end, outcome } = progress
          switch (this.state.todoOutcome) {
            case 'all':
              break
            case 'expected':
              if (outcome !== 0) return
              break
            case 'better':
              if (outcome <= 0) return
              break
            case 'worse':
              if (outcome >= 0) return
              break
          }
          if (end <= dateEnd) {
            if (outcome > 0) {
              likes += outcome
              cat.likes += outcome
              better++
              cat.better++
            } else if (outcome < 0) {
              dislikes += outcome
              cat.dislikes += outcome
              worse++
              cat.worse++
            } else {
              expected++
              cat.expected++
            }
          }
        })
      }
    }
    console.log("emotions", emotOptions)
    const prof = {
      todo, done, expected, better, worse, canceled, likes, dislikes,
    }
    todoCats['all'] = prof
   const profile = todoCats[this.state.todoCategory]
    if (profile) {
      options.sort((x, y) => {
        return x.name.localeCompare(y.name)
      })
      this.setState({
        todoCatProfiles: todoCats,
        todoCategories: options,
        todoProfile: profile,
      })
    } else {
      this.state.todoCategory = 'all'
    }
    emotOptions.sort((x, y) => {
      return x.value.localeCompare(y.value)
    })
    this.setState({
      todoEmotionProfiles: todoEmotions,
      todoEmotions: emotOptions,
    })
    if (!todoEmotions[this.state.todoEmotion]) {
      this.state.todoEmotion = 'all'
    }
    this.updateEventsLater()
  }

  updateOuraCyclesLater = () => {
    clearTimeout(this.ouraCycleUpdate)
    this.ouraCycleUpdate = setTimeout(this.updateOuraCyclesNow)
  }

  updateOuraCyclesNow = () => {
    for (const id in this.ouraCycles) {
      const cycle = this.ouraCycles[id]
      if (!this.appts[cycle.id]) {
        const fakeAppt = cycleToAppointment(this.props.me, this.getContactFilter(), 'oura', 'sleep', cycle)
        if (fakeAppt) {
          this.createEvent(fakeAppt)
          debugLog('event:', this.events[fakeAppt.id])
        }
      }
    }
    this.updateEventsNow()
  }

  weightSeq = 0

  getWeight = () => {
    if (!this.getContactFilter()) return
    if (!(this.getContactFilter().uid == this.props.me.uid ||
          this.state.dataShared)) {
      return
    }
    const start = startOfCycle(this.state.currentDate) - ONE_DAY
    const end = endOfMonth(this.state.currentDate)
    if (this.weightsSub && this.weightsStart == start && this.weightsEnd == end) {
      return
    }
    if (this.weightsSub) {
      this.weightsSub.unsubscribe()
    }
    const seq = ++this.weightSeq
    this.weightsStart = start
    this.weightsEnd = end
    for (const id in this.weights) {
      delete this.events[id]
      delete this.appts[id]
    }
    this.weights = {}
    this.weightsSub = this.props.me.observeWeight(this.getContactFilter().uid, start, end).subscribe(change => {
      ////debugger
      if (!this.state.scaleLinked) {
        this.setState({
          scaleLinked: true
        })
      }
      if (seq !== this.weightSeq) return
      const cycle = change.cycle
      if (change.type == 'removed') {
        delete this.weights[cycle.id]
        delete this.events[cycle.id]
        delete this.appts[cycle.id]

      } else {
        this.weights[cycle.id] = cycle
        const weightContact = {
          displayName: "Weight",
          profileImage: ScaleIcon,
          uid: this.getContactFilter().uid
        }
        const fakeAppt = {
          contact: weightContact,
          organizer: this.getContactFilter(),
          title: 'Weight '+ cycle.weight + 'lbs',
          titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>Weight</div>&nbsp;<div className='workoutEventStrain'>{formatWeight(cycle.weight)} lbs</div></div>,
          client: this.props.me.self.uid,
          start: cycle.created,
          end: cycle.created,
          weight: cycle,
          status: 'completed',
          id: cycle.id
        }
        this.createEvent(fakeAppt)
      }
      this.updateEventsLater()
    })
  }

  mealSeq = 0

  getMeals = () => {
    if (!this.getContactFilter()) return
    let start
    let end
    const when = this.state.currentDate
    if (this.state.view == 'year') {
      start = startOfYear(when) - ONE_DAY
      end = endOfYear(when)
    } else {
      start = startOfCycle(when) - ONE_DAY
      end = endOfMonth(when)
    }
    if (this.mealsSub && this.mealsStart == start && this.mealsEnd == end) {
      return
    }
    if (this.mealsSub) {
      this.mealsSub.unsubscribe()
    }
    const seq = ++this.mealSeq
    this.mealsStart = start
    this.mealsEnd = end
    for (const id in this.meals) {
      delete this.events[id]
      delete this.appts[id]
    }
    this.meals = {}
    this.mealsSub = this.props.me.observeMeals(this.getContactFilter().uid, start, end).subscribe(change => {
      if (seq !== this.mealSeq) return
      const meal = change.meal
      if (change.type == 'removed') {
        delete this.meals[meal.id]
        delete this.events[meal.id]
      } else {
        this.meals[meal.id] = meal
        const mealTitle =  capitalize(meal.type)
        let calories = 0
        let mainFood
        meal.foods.forEach(food => {
          if (!mainFood) {
            mainFood = food
          }
          let amount = food.nutrition ? food.nutrition.nf_calories : food.nf_calories
          if (amount) {
            calories += Math.round(amount * (food.count || 1)) 
          } else {
            debugLog("no calories", food)
          }
        })
        let foodContact
        if (mainFood) {
          let displayName = getFoodName(mainFood, true)
          const comma = displayName.indexOf(',')
          if (comma > 0) {
            displayName = displayName.substring(0, comma)
          }
          if (meal.foods.length > 1) {
            displayName += ' (+' + (meal.foods.length - 1) + ')'
          }
          foodContact = {
            profileImage: logoImage(mainFood),
            profileIcon: <FoodLogo food={mainFood}/>,
            displayName: displayName,
            uid: this.getContactFilter().uid,
          }
        }
        const fakeAppt = {
          contact: foodContact || this.getContactFilter(),
          organizer: this.getContactFilter(),
          title: mealTitle,
          titleHtml: <div className='workoutEvent'><div className='workoutEventSport'>{mealTitle}</div>&nbsp;<div className='workoutEventStrain'>{calories} Cal</div></div>,
          client: this.props.me.self.uid,
          start: meal.start,
          end: meal.end,
          meal: meal,
          status: 'completed',
          id: meal.id
        }
        this.createEvent(fakeAppt)
      }
      this.updateEventsLater()
    })
  }

  updateEventsNow = () => {
    if (this.state.view == 'year') {
      return this.setState({
        myEvents: []
      }, () => {
        this.onLoadMeals()
        this.onLoadWeights()
      })
    }
    let events = Object.values(this.events)
    //debugLog("searchTerm: ", this.state.searchTerm, " applied to: ", events);
    console.log("todo events" , events.filter(e => e.appt.status == 'todo'))
    if (this.state.searchTerm) {
      const matches = {};
      const searchTerms = this.state.searchTerm.toLowerCase().split(/\s+/);
      events = events.filter(event => {
        const appt = this.appts[event.id];
        if (appt) {
          let allTerms = []
          if (appt.todo) {
            const todo = appt.todo.todo
            const fields = ['summary', 'task', 'category', 'location', 'emojis', getEmojiFromTodo(todo)]
            fields.forEach(field => {
              const value = todo[field]
              if (value) {
                allTerms.push(value.toLowerCase().split(/\s+|-/))
              }
            })
          } else {
            if (this.getContactFilter() && !appt.workout && !appt.sleep && !appt.meal) {
              if (appt.contact.uid !== this.getContactFilter().uid &&
                  appt.uid != this.getContactFilter().uid) {
                return false
              }
            }
            let terms = appt.contact.displayName.toLowerCase().split(/\s+/);
            let timeTerms = formatStartEndTime(new Date(appt.start), new Date(appt.end)).split("-").map(x => x.trim()).join("").split(/\s+/);
            let dateTerms = formatDate(new Date(appt.start)).toLowerCase().split(/\s+/);
            let paymentTerms = renderPaymentStatus(appt.paymentStatus).toLowerCase().split(/\s+/);
            let titleTerms = appt.title ? appt.title.split(/\s+/) : [];
            let amountTerms = appt.invoiceAmount ? ["$"+appt.invoiceAmount, ""+appt.invoiceAmount] : [];
            if (appt.workout && appt.workout.description) {
              terms = terms.concat(appt.workout.description.toLowerCase().split(/\s+|-/))
            }
            allTerms = [terms, timeTerms, dateTerms, paymentTerms, titleTerms, amountTerms];
          }
          let matched = 0;
          allTerms.map(terms => {
            terms.map(term => {
              if (term) {
                term = term.toLowerCase();
                searchTerms.map(searchTerm => {
                  if (searchTerm) {
                    if (term.startsWith(searchTerm)) {
                      matched++;
                    }
                  }
                });
              }
            })
          });
          //debugLog("matched: ", matched, " in ", appt);
          if (matched == 0) return false;
          matches[event.id] = matched;
          return true;
        }
        debugLog('no appt', event)
        return false;
      });

      events.sort((x, y) => {
        const isTodo1 = isTodo(x)
        const isTodo2 = isTodo(y)
        if (isTodo1 && !isTodo2) return -1
        if (isTodo2) return 1
        const w1 = matches[x.id];
        const w2 = matches[y.id];
        const cmp1 = w2-w1;
        if (cmp1 !== 0) {
          return cmp1;
        }
        return x.start.getTime() - y.start.getTime();
      });
    }

    //debugLog(events);
    this.state.myEvents = events;
    this.state.activeEvents = {}
    this.state.myEvents.map(e => {
      this.state.activeEvents[e.id] = e
    })
    //debugLog("activeEvents:", this.state.activeEvents)
    console.log("updateEventsNow")
    this.onLoadCycleData()
  }


  getDateFilter = () => {
    let now = this.state.currentDate
    let start
    let end0
    switch (this.state.view) {
      case 'month':
        {
          start = startOfMonth(now)
          end0 = endOfMonth(now)
          const today = startOfDay(Date.now())
          if (this.getContactFilter() && start < today) {
            end0 = Math.min(end0, today)
          }
        }
        break;
      case 'week':
        {
          start = startOfWeek(now)
          end0 = endOfWeek(now)
          const today = startOfDay(Date.now())
          if (this.getContactFilter() && start < today) {
            end0 = Math.min(end0, today)
          }
        }
        break;
      case 'day':
        {
          start = startOfDay(now)
          end0 = endOfDay(now)
        }
        break
      case 'year':
        {
          start = startOfYear(now)
          end0 = endOfYear(now)
        }
        break
    }
    const filter = event => {
      const eventStart = event.start.getTime()
      let eventEnd = event.end.getTime()
      let end = end0
      if (event.appt && event.appt.todo) {
        end += ONE_DAY
      }
      if (isTodo(event)) {
        eventEnd = Infinity
      }      
      return !(end < eventStart || start > eventEnd)
    }
    filter.endPoints = { start, end: end0, type: this.state.view }
    return filter
  }
  
  updateEventsLater = () => {
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      this.updateEventsNow();
    }, 300);
  }

  onEventSelect = event => {
  }

  setSearchInput = x => {
    if (x && this.searchInput != x) {
      this.searchInput = x;
    }
  }

  onSearchFocus = e => {
    if (!this.state.searchFocus) {
      this.setState({searchFocus: true})
    }
  }

  onSearchBlur = e => {
    if (this.state.searchFocus) {
      this.setState({searchFocus: false})
    }
  }

  onSearchTermChanged = e => {
    const term = e.target.value;
    this.setState({
      searchTerm: term,
    }, this.updateEventsLater);
  }

  changeView = (event) => {
    var view;
    switch (event.target.value) {
      case 'month':
        view = {
          calendar: { type: 'month' },
          eventList: { type: 'month', scrollable: true }
        };
        break;
      case 'week':
        view = {
          calendar: { type: 'week' },
          eventList: { type: 'week', scrollable: true }
        };
        break;
      case 'day':
        view = {
          eventList: { type: 'day', scrollable: true }
        };
        break
      case 'year':
        view = {
          eventList: { type: 'year', scrollable: true }
        };
        break;
    }
    this.setState({
      view: event.target.value,
      calView: view
    }, this.onDateChanged)
  }

  onDragOver = e => {
    //debugLog("onDragOver: ", e.target);
  }

  onDrop = e => {
    let targetDate;
    let targetNode = e.target;
    while (targetNode) {
      //debugLog("onDrop: ", targetNode);
      if (targetNode.attributes['data-full']) {
        const date = targetNode.attributes['data-full'].value;
        targetDate = moment(date, "YYYY-MM-DD");
        break;
      }
      targetNode = targetNode.parentElement;
    }
    if (!targetDate) {
      return
    }
    setTimeout(() => {
      //debugLog("targetDate: ", targetDate);
      //debugLog("dragSource: ", this.props.dragSource);
      const cal = this.cal.current;
      cal.instance.navigate(targetDate.toDate());
      this.setState({
        currentDate: targetDate.toDate()
      });
      if (this.props.dragSource.contact) {
        this.openEvent(this.events[this.props.dragSource.id], targetDate);
      } else {
        this.plusEvent(this.props.dragSource, targetDate.toDate());
      }
    }, 100);
  }

  onPageChange = (event, inst) => {
    clearTimeout(this.pageChangeTimeout)
    console.log("on page change")
    this.pageChangeTimeout = setTimeout(() => {
      this.onDayChange({date: event.firstDay}, inst, true)
    }, 500)
  }

  getWhoopDataLater = () => {
    clearTimeout(this.getWhoopDataTimeout)
    this.getWhoopDataTimeout = setTimeout(this.getWhoopCycleData, 300);
  }

  onLoadCycleDataLater = () => {
    this.onLoadCycleData()
  }

  onDateChanged = () => {
    console.log("onDataChanged", this.cycleStart, this.cycleEnd)
    if (!this.props.me.isTodoList()) {
      this.observeLastWeight()
      this.getWhoopDataLater()
      //this.getFitbitCycleData()
      this.getGarminCycleData()
      this.getOura()
      this.getMeals()
      this.getWeight()    
      this.getWhoopSummaries().then(() => {
      })
      this.updateWorkoutTypes()
    } else {
      this.getTodoCycleDataLater()
    }
  }

  observeLastWeight = () => {
    if (this.getContactFilter()) {
      const filter = this.getDateFilter()
      const { end } = filter.endPoints
      if (this.currentWeightEnd !== end) {
        this.currentWeightEnd = end
        if (this.weightSub) {
          this.weightSub.unsubscribe()
        }
        this.weightSub = this.props.me.observeCurrentWeight(this.getContactFilter(), end).subscribe(cycle => {
          this.currentWeight = cycle
          this.onLoadWeights()
        })
      }
    }
  }

  onDayChange = (event, inst, noClick) => {
    event.date = new Date(event.date.getTime())
    if (!noClick && this.state.currentDate && this.state.currentDate.getTime() == event.date.getTime()) {
      if (this.props.onClick) {
        this.props.onClick(event.date);
      } else {
        if (!this.state.openEvent) {
          this.plusEvent();
        }
      }
      return true;
    }        
    if (this.state.currentDate.getTime() !== event.date.getTime()) {
      const prev = this.state.currentDate
      this.state.currentDate = event.date;
      if (this.state.openEvent) {
        ['date', 'start', 'end'].map(field => {
          const date = new Date(this.state.openEvent[field]);
          date.setMonth(event.date.getMonth());
          date.setDate(event.date.getDate());
          this.state.openEvent[field] = date;
        });
      }
      this.forceUpdate(()=>this.onDateChanged())
    }
    if (this.props.onClick && event.target.className.indexOf("mbsc-cal-cell") >= 0) {
      this.props.onClick(event.date);
    }
    return true;
  }

  getCurrentDate = () => this.state.currentDate;

  onTap = (e, inst) => {
    if (this.props.onClick) {
      this.props.onClick(e.date);                    
    } else {
      if (this.state.currentDate == e.date) {
        this.plusEvent();
      } 
    }
  }

  onSearchKeyDown = e => {
    if (e.keyCode === 27) {
      e.target.blur()
    }
  }

  onSearchIconClick = e => {
    if (this.state.searchTerm) {
      this.setState({
        searchTerm: ''
      });
    } else {
      this.searchInput.focus()
    }
  }

  selectTodoCategory = () => {
    this.setState({
      selectingTodoCategory: true
    })
  }

  dismissTodoCategorySelector = () => {
    this.setState({
      selectingTodoCategory: false
    })
  }

  
  onChangeTodoCategory = (value, opt) => {
    const profiles = this.state.todoCatProfiles
    const profile = profiles[value]
    this.setState({
      todoCategory: value,
      todoCategoryName: opt.name,
      todoProfile: profile,
      selectingTodoCategory: false
    })
    if (this.state.todoEmotion !== 'all') {
      this.updateTodoDataLater()
    }
  }

  selectTodoEmotion = () => {
    this.setState({
      selectingTodoEmotion: true
    })
  }

  dismissTodoEmotionSelector = () => {
    this.setState({
      selectingTodoEmotion: false
    })
  }

  onChangeTodoEmotion = (value, opt) => {
    this.setState({
      todoEmotion: value,
      todoEmotionName: opt.name,
      selectingTodoEmotion: false
    })
    this.updateTodoDataLater()
  }
  

  onChangeScope = button => {
    if (true || button.value == 'workouts' || button.value == 'sleep') {
      this.getWhoopDataLater()
    }
    this.setState({
      scope: button.value
    })
  }

  scopeButtons = {
    selection: () => this.state.scope,
    buttons: [
        {
          value: 'all',
          name: 'All'
        },
        {
          value: 'workouts',
          name: 'Workouts'
        },
        {
          value: 'meals',
          name: 'Meals'
        },
        {
          value: 'sleep',
          name: 'Sleep'
        },
        {
          value: 'weight',
          name: 'Weight'
        },
      ]
  }

  onLoadCycleData = () => {
    if (!this.props.me.isTodoList()) {
      console.log('onLoadCycleData')
      this.onLoadWhoopCycleData()
      this.onLoadOuraCycleData()
      this.onLoadMeals()
      this.onLoadWeights()
      this.updateGarminCyclesNow()
    }
  }

  onLoadMeals = () => {
    const filter = this.getDateFilter()
    const { start, end } = filter.endPoints
    let protein = 0
    let carbs = 0
    let fat = 0
    let meals = 0
    let calories = 0
    let meals1 = 0
    let days = {}
    this.mealCycleData = []
    for (let i = start; i < end; i += 60 * 60 * 24 * 1000) {
      const s = startOfDay(new Date(i))
      let day = days[s]
      if (!day) {
        day = {
          date: new Date(s),
          carbs: 0,
          protein: 0,
          fat: 0,
          calories: 0,
          meals: 0
        }
        days[s] = day
      }
    }
    for (const id in this.meals) {
      if (!this.state.activeEvents[id]) {
        continue
      }
      const meal = this.meals[id]
      if (meal.start >= start && meal.start < end) {
      } else {
        continue
      }
      const s = startOfDay(new Date(meal.start))
      let day = days[s]
      if (!day) {
        day = {
          date: new Date(s),
          carbs: 0,
          protein: 0,
          fat: 0,
          calories: 0,
          meals: 0
        }
        days[s] = day
      }
      meals++
      meal.foods.forEach(food => {
        let cal = Math.round(food.nutrition ? food.nutrition.nf_calories : food.nf_calories || 0)
        const count = food.count === undefined ? 1 : food.count
        cal *= count
        calories += cal
        const nutrition = food.nutrition || (food.nf_protein || food.nf_total_fat || food.nf_total_carbohydrate) && food
        if (nutrition) {
          let total = 0
          let p = nutrition.nf_protein
          total += p
          let f = nutrition.nf_total_fat
          total += f
          let c = nutrition.nf_total_carbohydrate
          total += c
          if (total) {
            meals1++
            carbs += c
            fat += f
            protein += p
            day.meals++
            day.carbs += c
            day.fat += f
            day.protein += p
            day.calories += cal
          }
        }
      })
    }
    this.mealCycleData = Object.values(days)
    this.mealCycleData.sort((x, y) => x.date.getTime() - y.date.getTime())
    days = Object.values(days).filter(day => day.calories > 0).length
    let total = 0
    if (days) {
      if (meals1) {
        total = protein + fat + carbs
        if (total) {
          protein /= total
          fat /= total
          carbs /= total
        }
      }
      calories /= days
    }
    const mealsProfile = {
      days: days,
      meals,
      protein: Math.round(protein * 100),
      carbs: Math.round(carbs * 100),
      fat: Math.round(fat * 100),
      total: total,
      caloriesIn: Math.round(calories),
      caloriesOut: 0
    }
    this.updateCalories(mealsProfile)
    this.setState({
      mealsProfile
    })
  }

  updateMealsProfile = profile => {
    debugLog("updateMealsProfile", profile)
    if (this.state.mealsProfile) {
      let needsUpdate = false
      if (this.state.mealsProfile.caloriesOut !== profile.caloriesOut) {
        this.state.mealsProfile.caloriesOut = profile.caloriesOut
        needsUpdate = true
      }
      if (profile.caloriesIn !== this.state.mealsProfile.caloriesIn) {
        profile.caloriesIn = this.state.mealsProfile.caloriesIn
        needsUpdate = true
      }
      if (needsUpdate) {
        this.forceUpdate()
      }
    }
  }

  updateCalories = (profile) => {
    debugLog("updateCalories", this.state.whoopProfile, profile)
    if (profile) {
      if (this.state.whoopProfile) {
        this.state.whoopProfile.caloriesIn = profile.caloriesIn
        profile.caloriesOut = this.state.whoopProfile.caloriesOut
      } else if (this.state.ouraProfile) {
        this.state.ouraProfile.caloriesIn = profile.caloriesIn
        profile.caloriesOut = this.state.ouraProfile.caloriesOut
      } else if (this.state.garminProfile) {
        this.state.garminProfile.caloriesIn = profile.caloriesIn
        profile.caloriesOut = this.state.garminProfile.caloriesOut
      }
    }
  }

  weights = {}

  onLoadWeights = () => {
    const filter = this.getDateFilter()
    const { start, end } = filter.endPoints
    let weight = 0
    let days = {}
    let count = 0
    let minCycle
    let maxCycle
    let startCycle
    let endCycle
    const cycles = Object.values(this.weights).filter(cycle => {
      const when = cycle.created
      return when >= start && when < end
    })
    let weights
    let last
    if (cycles.length === 0 && this.currentWeight) {
      if (this.currentWeight.created < end) {
        last = this.currentWeight
        weights = [this.currentWeight]
        this.state.activeEvents[this.currentWeight.id] = true
        count--
      } else {
        weights = []
      }
    } else {
      weights = cycles
    }
    console.log("weights", weights)
    if (weights.length > 0) {
      weights.sort((x, y) => x.created - y.created)
      weights.forEach((cycle, i) => {
        if (!this.state.activeEvents[cycle.id]) {
          return
        }
        const w = cycle.weight
        if (!minCycle || w < minCycle.weight) {
          minCycle = cycle
        }
        if (!maxCycle || maxCycle.weight < w) {
          maxCycle = cycle
        }
        if (i == 0) {
          startCycle = cycle
        }
        if (i + 1 == weights.length) {
          endCycle = cycle
        }
        weight += w
        last = cycle
        count++
      })
      if (count > 0) {
        weight /= count
      }
    }

    this.setState({
      weightProfile: {
        last: last,
        weights: cycles,
        avg: weight,
        min: minCycle,
        max: maxCycle,
        start: startCycle,
        end: endCycle,
        calendar: { view: this.state.view, start, end }
      }
    })
  }
                  

  onLoadOuraCycleData() {
    if (!this.ouraCycles) {
      if (this.state.ouraProfile) {
        this.setState({
          ouraProfile: {
            type: 'oura',
            readiness: 0,
            caloriesIn: 0,
            rhr: 0,
            hrv: 0,
            caloriesOut: 0,
            activity: 0
          }
        })
      }
      return
    }
    let readiness = 0
    let caloriesIn = 0
    let rhr = 0
    let hrv = 0

    let needed = 0
    let sleepEfficiency = 0
    let sleepConsistency = 0
    let inBed = 0
    let respiratoryRate = 0
    let sleepScore = 0
    let slept = 0
    
    let caloriesOut = 0
    let activity = 0
    let numReadiness = 0
    let numActivity = 0
    let numSleep = 0
    let numCycles = 0
    const filter = this.getDateFilter()
    let dayStart
    if (this.state.view == 'day') {
      dayStart = startOfDay(this.state.currentDate)
      debugLog('currentDate:', this.state.currentDate)
      debugLog('dayStart:', dayStart)
    }
    debugLog("filter:", new Date(filter.endPoints.start), new Date(filter.endPoints.end))
    for (const id in this.ouraCycles) {
      if (!this.state.activeEvents[id]) {
        continue
      }
      const cycle = this.ouraCycles[id]
      debugLog("cycle:", cycle.start, cycle.end)
      if (false && this.state.view == 'day') {
        const { start, end } = filter.endPoints
        const cycleEnd = cycle.end
        if (!(cycleEnd >= start && cycleEnd <= end)) {
          continue
        }
      } else {
        if (!filter({
          start: new Date(cycle.start),
          end: new Date(cycle.end)
        })) {
        debugLog("filtered out")
          continue
        }
      }
      debugLog(cycle)
      if (cycle.sleep) {
        rhr += cycle.sleep.hr_lowest
        hrv += cycle.sleep.rmssd
        const cycleSleepEfficiency = (cycle.sleep.efficiency/100)
        const cycleSleepConsistency = 0
        const cycleSlept = cycle.sleep.duration * cycleSleepEfficiency * 1000
        const cyclePerf = cycle.sleep.score/100
        needed = cycleSlept / cyclePerf
        sleepEfficiency += cycleSleepEfficiency
        sleepConsistency += cycleSleepConsistency
        sleepScore += cycle.sleep.score
        inBed += Date.parse(cycle.sleep.bedtime_end) - Date.parse(cycle.sleep.bedtime_start)
        slept += cycleSlept
        respiratoryRate += cycle.sleep.breath_average
        numSleep++
      }
      if (cycle.activity) {
        caloriesOut += cycle.activity.cal_total
        activity += cycle.activity.score
        numActivity++
      }
      if (cycle.readiness) {
        readiness += cycle.readiness.score
        numReadiness++
      }
      numCycles++
    }
    if (numReadiness) {
      readiness /= numReadiness
    }
    if (numSleep) {
      rhr /= numSleep
      hrv /= numSleep
      needed /= numSleep
      inBed /= numSleep
      sleepEfficiency /= numSleep
      respiratoryRate /= numSleep
      sleepScore /= numSleep
      slept /= numSleep
    }
    if (numActivity) {
      caloriesOut /= numActivity
      activity /= numActivity
    }
    readiness = Math.round(readiness)
    rhr = Math.round(rhr)
    hrv = Math.round(hrv)
    caloriesOut = Math.round(caloriesOut)
    activity = Math.round(activity)
    const profile = {
      type: 'oura',
      readiness,
      caloriesIn,
      rhr,
      hrv,
      caloriesOut,
      activity
    }
    const workoutProfile = {
    }
    const sleepProfile = {
      needed, sleepConsistency, sleepEfficiency, hrv, inBed, respiratoryRate, score: sleepScore, slept
    }
    debugLog("profile=>", profile, numCycles)
    this.setState({
      ouraProfile: profile,
      ouraSleepProfile: sleepProfile,
    }, () => {
      this.updateMealsProfile(profile)
    })
  }


  updateWorkoutTypes() {
    const workoutType = {}
    workoutType[-1] = true
    const filter = this.getDateFilter()
    if (this.cycleData) {
      const cycles = this.cycleData
      debugLog("filter:", new Date(filter.endPoints.start), new Date(filter.endPoints.end))
      if (cycles) {
        const { start, end } = filter.endPoints
        cycles.forEach(cycle => {
          const date = moment(cycle.days[0]).local().toDate().getTime()
          if(date >= start && date < end) {
            if (cycle.strain) {
              cycle.strain.workouts.map(workout => {
                workoutType[workout.sportId] = true
              })
            }
          } 
        })
      }
    }
    for (const id in this.appts) {
      const appt = this.appts[id]
      if (appt.scheduledWorkout) {
        const event = this.events[id]
        if (filter(event)) {
          workoutType[appt.scheduledWorkout.activity.uid] = true
        }
      }
      if (appt.workout && appt.workout.garmin) {
        const event = this.events[id]
        if (filter(event)) {
          workoutType[appt.workout.sportId] = true
        }
      }
    }
    const whoop = this.props.me.getWhoop()
    const types = Object.keys(workoutType).map(sportId => whoop.getSport(sportId))
    types.sort((x, y) => {
      return x.id - y.id
    })
    let workoutTypeFilter = this.state.workoutTypeFilter
    if (!types.find(x => x.id == workoutTypeFilter)) {
      workoutTypeFilter = -1
    }
    this.setState({
      workoutTypeFilter,
      workoutTypes: types.map(sport => {
        return {
          selector: sport.id,
          label: sport.name,
          content: () => <div className='uiSportRadioButton'><img src={sport.iconUrl}/></div>,
          sport: sport
        }
      })
    })
  }

  selectTodoTerm = button => {
    this.setState({
      todoTerm: button.selector
    }, this.updateTodoDataLater)
  }

  selectTodoOutcome = button => {
    this.setState({
      todoOutcome: button.selector
    }, this.updateTodoDataLater)
  }

  selectTodoStatus = button => {
    this.setState({
      todoStatusType: button.selector
    }, this.updateTodoDataLater)
  }

  selectWorkoutType = button => {
    this.setState({
      workoutTypeFilter: button.selector
    }, () => {
      this.onLoadWhoopCycleData()
      this.updateGarminCyclesLater()
    })
  }

  resetGarminProfiles = () => {
    const garminProfile = {
      type: 'garmin',
      readiness: 0,
      caloriesIn: 0,
      rhr: 0,
      hrv: 0,
      battery: 0,
      stressLevel: 0,
      caloriesOut: 0,
      activity: 0
    }
    const garminSleepProfile = {
      needed: 0, sleepConsistency: 0, sleepEfficiency: 0, hrv: 0,
      inBed: 0, respiratoryRate: 0, score: 0, slept: 0, sp02: 0
    }
    const garminWorkoutProfile = {
      avgHeartRate: 0, maxHeartRate: 0, battery: 0,
      caloriesOut: 0, workoutCalories: 0, workoutDur: 0,
      workoutZones: [], workouts: 0
    }
    this.setState({
      garminProfile,
      garminSleepProfile,
      garminWorkoutProfile
    })
  }

  resetOuraProfiles = () => {
    const ouraProfile = {
      type: 'oura',
      readiness: 0,
      caloriesIn: 0,
      rhr: 0,
      hrv: 0,
      caloriesOut: 0,
      activity: 0
    }
    const ouraSleepProfile = {
      needed: 0, sleepConsistency: 0, sleepEfficiency: 0, hrv: 0,
      inBed: 0, respiratoryRate: 0, score: 0, slept: 0
    }
    this.setState({
      ouraProfile,
      ouraSleepProfile,
    })
  }
  
  resetWhoopProfiles = () => {
    const workoutProfile = {
      avgHeartRate: 0, maxHeartRate: 0, strain: 0,
      caloriesOut: 0, workoutDur: 0, workoutZones: [], workouts: 0
    }
    const sleepProfile = {
      needed: 0, sleepConsistency: 0, sleepEfficiency: 0, hrv: 0, inBed: 0, respiratoryRate: 0, score : 0, slept: 0
    }
    const profile = {
      type: 'whoop',
      days: 0,
      user: this.getContactFilter(),
      recovery: 0,
      strain: 0,
      caloriesOut: 0,
      caloriesIn: 0,
      rhr: 0,
      hrv: 0,
    }
    this.setState({
      whoopProfile: profile,
      whoopSleepProfile: sleepProfile,
      whoopWorkoutProfile: workoutProfile,
    })
  }

  onLoadWhoopCycleData() {
    if (!this.cycleData) {
      return
    }
    const cycles = this.cycleData
    debugLog("cycleData=", cycles)
    let recovery = 0
    let strain = 0
    let calories = 0
    let currentCycles;
    const filter = this.getDateFilter()
    debugLog("filter:", new Date(filter.endPoints.start), new Date(filter.endPoints.end))
    if (cycles) {
      const { start, end } = filter.endPoints
      currentCycles = cycles.filter(cycle => {
        if (true) {
          const date = moment(cycle.days[0]).local().toDate().getTime()
          return date >= start && date < end
        } else {
          const lower = Date.parse(cycle.during.lower)
          const upper = Date.parse(cycle.during.upper || cycle.lastUpdatedAt)
          return filter({
            start: new Date(lower),
            end: new Date(upper)
          })
        }
      })
    } else {
      currentCycles = []
    }
    console.log('onLoadWhoopCycleData', currentCycles)
    this.currentCycleData = currentCycles
    if (this.getContactFilter()) {
      // sleeps
      let sleeps = 0
      let needed = 0
      let sleepEfficiency = 0
      let sleepConsistency = 0
      let inBed = 0
      let respiratoryRate = 0
      let score = 0
      let slept = 0
      let bedtimes = 0
      let bedtime = 0
      
      // workouts
      let workouts = 0
      let avgHeartRate = 0
      let workoutCalories = 0
      let maxHeartRate = 0
      let workoutStrain = 0
      let workoutDur = 0
      const workoutZones = [0, 0, 0, 0, 0, 0]

      // all
      let recoveries = 0
      let strains = 0
      let hrv = 0
      let rhr = 0
      const workoutType = {}
      currentCycles.forEach((cycle, i) => {
        let cycleRecovery = cycle.recovery
        if (cycleRecovery) {
          recovery += cycleRecovery.score
          rhr += cycleRecovery.restingHeartRate
          hrv += Math.round(cycleRecovery.heartRateVariabilityRmssd * 1000);
          recoveries++
        }
        if (cycle.strain) {
          strain += cycle.strain.score
          calories += cycle.strain.kilojoules * 0.239006
          strains++
          const day = {
            workouts: 0,
            workoutCalories: 0,
            workoutDur: 0,
            maxHeartRate: 0
          }
          cycle.strain.workouts.forEach(workout => { 
            if (!this.state.activeEvents[workout.id]) {
              return 
            }
           if (!workout.score) return
            const type = workout.sportId
            if (this.state.workoutTypeFilter >= 0 && this.state.workoutTypeFilter !== type) {
              return
            }
            day.workouts++
            workoutType[type] = true
            avgHeartRate += workout.averageHeartRate
            day.maxHeartRate = Math.max(workout.maxHeartRate, day.maxHeartRate)
            workoutStrain += workout.score
            day.workoutCalories += Math.round(workout.kilojoules * 0.239006)
            const workoutBegin = Date.parse(workout.during.lower)
            const workoutEnd = Date.parse(workout.during.upper)
            day.workoutDur += (workoutEnd - workoutBegin)
            workout.zones.forEach((zone, i) => {
              workoutZones[i] += zone
            })
          })
          if (day.workouts > 0) {
            workouts += day.workouts
            workoutDur += day.workoutDur * day.workouts
            workoutCalories += day.workoutCalories * day.workouts
            maxHeartRate += day.maxHeartRate * day.workouts
          }
        }
        if (cycle.sleep) {
          cycle.sleep.sleeps.forEach(sleep => {
            if (!this.state.activeEvents[sleep.id]) {
              return 
            }
            if (!sleep.score) return
            needed += sleep.needBreakdown.total
            sleepEfficiency += sleep.sleepEfficiency
            sleepConsistency += sleep.sleepConsistency
            inBed += sleep.inBedDuration
            respiratoryRate += sleep.respiratoryRate
            score += sleep.score
            slept += sleep.qualityDuration
            sleeps++
          })
        }
        if (cycle.bedtime) {
          bedtimes++
          bedtime += cycle.bedtime
        }
      })
      if (recoveries > 0) {
        recovery /= recoveries
        rhr /= recoveries
        hrv /= recoveries
      }
      if (strains > 0) {
        strain /= strains
        calories /= strains
      }
      if (sleeps > 0) {
        needed /= sleeps
        sleepEfficiency /= sleeps
        sleepConsistency /= sleeps
        inBed /= sleeps
        respiratoryRate /= sleeps
        score /= sleeps
        slept /= sleeps
      }
      if (workouts > 0) {
        avgHeartRate /= workouts
        maxHeartRate /= workouts
        workoutStrain /= workouts
        workoutCalories /= workouts
        workoutDur /= workouts
        workoutZones.forEach((zone, i) => {
          workoutZones[i] /= workouts
        })
      }
      const workoutProfile = {
        avgHeartRate, maxHeartRate, strain: workoutStrain,
        caloriesOut: workoutCalories, workoutDur, workoutZones, workouts
      }
      const sleepProfile = {
        needed, sleepConsistency, sleepEfficiency, hrv, inBed, respiratoryRate, score, slept
      }
      console.log("bedtimes: ", bedtimes, bedtime)
      const profile = {
        type: 'whoop',
        days: strains,
        user: this.getContactFilter(),
        recovery: Math.round(recovery),
        strain: strain,
        caloriesOut: Math.round(calories),
        caloriesIn: 0,
        rhr: Math.round(rhr),
        hrv: Math.round(hrv),
        bedtime: bedtimes > 0 ?
          moment(startOfDay(Date.now()) + bedtime / bedtimes).format("h:mm a") : 'n/a'
      }
      debugLog("profile=>", profile)
      this.setState({
        whoopProfile: profile,
        whoopSleepProfile: sleepProfile,
        whoopWorkoutProfile: workoutProfile
      }, () => {
        this.updateWorkoutTypes()
        this.updateMealsProfile(profile)
      })
    }
    if (this.props.onLoadCycleData) {
      this.props.onLoadCycleData(currentCycles)
    }
  }
  
  finishWhoopData = (start, end, cycles) =>  {
    debugLog(cycles)
    this.cycleData = cycles
    debugger
    if (!this.state.whoopLinked) {
      this.setState({
        whoopLinked: true
      })
    }
    for (const cycle of cycles) {
      const sleeps = []
      const workouts = []
      const sleepIcon = this.props.me.getWhoop().getSleepIcon()
      const recovery = cycle.recovery
      for (const sleep of cycle.sleep.sleeps) {
        const fakeAppt = cycleToAppointment(this.props.me, this.getContactFilter(), 'whoop', 'sleep', sleep)
        this.createEvent(fakeAppt)
      }
      for (const sleep of cycle.sleep.naps) {
        const fakeAppt = cycleToAppointment(this.props.me, this.getContactFilter(), 'whoop', 'nap', sleep)
        this.createEvent(fakeAppt)
      }
      for (const workout of cycle.strain.workouts) {
        const fakeAppt = cycleToAppointment(this.props.me, this.getContactFilter(), 'whoop', 'workout', workout)
        this.createEvent(fakeAppt)
      }
    }
    this.updateEventsLater()
  }

  setScrollRef = ref => {
    if (ref) {
      this.scroller = ref
    }
  }

  openWeeklySummary = () => {
    if (this.props.opts.openSummary) {
      return this.props.opts.openSummary(this.getContactFilter().displayName, this.state.weeklySummaryURL)
    }
    return this.props.me.openWindow(this.state.weeklySummaryURL, '_blank')
  }

  openMonthlySummary = () => {
    if (this.props.opts.openSummary) {
      return this.props.opts.openSummary(this.getContactFilter().displayName, this.state.monthlySummaryURL)
    }
    return this.props.me.openWindow(this.state.monthlySummaryURL, '_blank')
  }

  render() {
    debugLog('calendar render:', this.state.scope)

    let filter;

    const sameDate = (x, y) => x.getYear() == y.getYear() && x.getMonth() == y.getMonth() &&
          x.getDate() == y.getDate();
    const when = moment(this.state.currentDate)
    let now = this.state.currentDate
    filter = this.getDateFilter()
    let events;
    if (this.getContactFilter()) {
      console.log("my events", this.state.myEvents)
      const { start, end } = filter.endPoints
      events = this.state.myEvents.filter(x => {
        if (x.appt.todo && x.appt.todo.todo.status === 'pending') {
          return x.appt.start > end
        }
        return filter(x)
      })
      console.log("events after filter", events)
      events = events.filter(e => {
        const appt = this.appts[e.id];
        if (!appt) {
          return false
        }
        if (appt.sleep) {
          let { start, end } = filter.endPoints
          if (appt.end < start || appt.start > end) {
            return false
          }
          if (appt.end > end) {
            //return false
          }
        }
        if (this.props.me.isTodoList() && !appt.todo) {
          return false
        }
        switch (this.state.scope) {
          case 'any':
            {
              if (this.state.workoutTypeFilter >= 0) {
                if (appt.scheduledWorkout) {
                  if (appt.scheduledWorkout.activity.uid !== this.state.workoutTypeFilter) {
                    return false
                  }
                } else if (appt.workout) {
                  if (appt.workout.sportId != this.state.workoutTypeFilter) {
                    return false
                  }
                }
              }
              if (appt.todo) {
                if (!this.props.me.isTodoList()) return false
              }
              if (appt.todo && this.state.todoCategory !== 'all') {
                if (appt.todo.todo.category.toLowerCase() !== this.state.todoCategory) {
                  return false
                }
              }
              if (appt.todo && this.state.todoTerm !== 'all') {
                if (appt.todo.todo.term.toLowerCase() !== this.state.todoTerm) {
                  return false
                }
              }
              if (appt.todo && this.state.todoStatusType !== 'all') {
                if (this.state.todoStatusType === 'progress') {
                  if (!(appt.todo.status === 'pending' && appt.todo.progress)) {
                    return false
                  }
                } else if (appt.todo.status.toLowerCase() !== this.state.todoStatusType) {
                  return false
                }
              }
              if (appt.todo && this.state.todoEmotion !== 'all') {
                if (!appt.todo.todo.emotion.toLowerCase().split('/').find(x => x == this.state.todoEmotion)) {
                  return false
                }
              }
              if (appt.todo && !this.todoOutcomeFilter(appt.todo)) {
                return false
              }
            }
            break
          case 'todo':
            {
              if (!this.props.me.isTodoList()) return false
              if (!appt.todo) return false
              if (this.state.todoCategory !== 'all') {
                if (appt.todo.todo.category.toLowerCase() !== this.state.todoCategory) {
                  return false
                }
              }
              if (this.state.todoTerm !== 'all') {
                if (appt.todo.todo.term.toLowerCase() !== this.state.todoTerm) {
                  return false
                }
              }
              if (appt.todo && this.state.todoStatusType !== 'all') {
                if (this.state.todoStatusType === 'progress') {
                  if (!(appt.todo.status === 'pending' && appt.todo.progress)) {
                    return false
                  }
                } else if (appt.todo.status.toLowerCase() !== this.state.todoStatusType) {
                  return false
                }
              }
              if (this.state.todoEmotion !== 'all') {
                if (this.state.todoEmotion !== appt.todo.todo.emotion) {
                  return false
                }
              }
              if (!this.todoOutcomeFilter(appt.todo)) {
                return false
              }
            }
            break
          case 'sleep':
            {
              if (!appt.sleep) return false
            }
            break
          case 'workouts':
            {
              if (!appt.workout) return false
              if (this.state.workoutTypeFilter >= 0) {
                if (appt.scheduledWorkout) {
                  if (appt.scheduledWorkout.activity.uid !== this.state.workoutTypeFilter) {
                    return false
                  }
                } else {
                  if (appt.workout.sportId != this.state.workoutTypeFilter) {
                    return false 
                 }
                }
              }
            }
            break
          case 'all':
            if (!(appt.workout || appt.sleep || appt.meal || appt.weight)) return false
            break
          case 'appointments':
            if (appt.workout || appt.sleep || appt.meal || appt.weight) return false
            break
          case 'meals':
            {
              if (!appt.meal) return false
            }
            break
          case 'weight':
              if (!appt.weight) return false
            break
        }
        if (isTodo(e)) return true
        let result = appt.contact.uid == this.getContactFilter().uid
        if (!result && appt.workout && appt.workout.scheduled) {
          result = appt.workout && (appt.workout.client == this.getContactFilter().uid ||
                                    appt.workout.trainer == this.getContactFilter().uid)
          
        }
        return result;
      });
      console.log("events after filter", events)
    } else {
      const { start, end, type } = filter.endPoints
      events = this.state.myEvents.filter(event => {
        if (event.client && event.status == "declined") {
          return false;
        }
        const appt = this.appts[event.id]
        if (appt) {
          if (appt.workout && appt.workout.scheduled) {
            return false
          }
        }
        //debugLog("filter", {start: new Date(start), end: new Date(end), type: type}, event, filter(event))
        return filter(event)
      });
    }
    events.sort((a, b) => {
      const isTodo1 = isTodo(a)
      const isTodo2 = isTodo(b)
      if (isTodo1 && !isTodo2) return -1
      if (isTodo2) return 1
      let t1 = a.start
      if (a.appt && a.appt.sleep) {
        t1 = a.end
      }
      let t2 = b.start
      if (b.appt && b.appt.sleep) {
        t2 = b.end
      }
      return t2 - t1
    });
    let showControls = true
    let plusText = 'Schedule an appointment'
    if (this.getContactFilter()) {
      const scope = this.state.scope
      if (this.getContactFilter().uid == this.props.me.self.uid && this.props.isMe) {
        if (scope == 'meals') {
          plusText = 'Add a meal'
        } else if (scope == 'todo') {
          plusText = 'Add To-do'
        } else if (scope == 'weight') {
          plusText = 'Add Weight'
        } else {
          if (false && !this.props.me.isTodoList() && !this.props.me.self.isTrainer && !this.state.mySubscription) {
            plusText = "Find a trainer"
            showControls = true
          } else {
            showControls = false
          }
        }
      } else if (this.state.dataShared) {
        if (scope == 'workouts' && this.props.me.self.isTrainer) {
          plusText = 'Schedule a workout'
        } else {
          showControls = false
        }
      } else {
        showControls = false
      }
    }
    if (this.state.searchFocus) {
      showControls = false
    }
    let showWhoopProfile
    let showSleepProfile
    let showGarminProfile
    let showGarminSleepProfile
    let showGarminWorkoutProfile
    let showOuraProfile
    let showOuraSleepProfile
    let showWorkoutProfile
    let showWeightProfile
    let showMealsProfile
    let showTodoProfile
    let devices = {}
    if (this.getContactFilter()) {
      if (this.getContactFilter().uid == this.props.me.self.uid) {
        devices = {
          withings: !!this.state.scaleLinked,
          whoop: !!this.state.whoopLinked,
          oura: !!this.state.ouraLinked,
          garmin: !!this.state.garminLinked
        }
      } else {
        devices = this.state.devices || {}
      }
    }
    if (!this.props.me.isTodoList()) {
      console.log("scope", this.state.scope)
      if (this.state.scope == 'any' || this.state.scope == 'todo') {
        if (this.getContactFilter() && this.getContactFilter().uid == this.props.me.self.uid) {
          //showTodoProfile = true
        }
      }
      if (devices.whoop && this.state.dataShared) {
        if (this.state.scope == 'any' || this.state.scope == 'all') {
          showWhoopProfile = this.state.whoopProfile
        }
      }
      if (devices.whoop && this.state.dataShared) {
        if (this.state.scope == 'any' || this.state.scope == 'sleep') {
          showSleepProfile = this.state.whoopSleepProfile
        }
      }
      if (devices.oura  && this.state.dataShared) {
        if (this.state.scope == 'sleep' || this.state.scope == 'any') {
          showOuraSleepProfile = this.state.ouraSleepProfile
        }
      }
      if (devices.garmin && this.state.dataShared) {
        if (this.state.scope == 'any' || this.state.scope == 'sleep') {
          showGarminSleepProfile = this.state.garminSleepProfile
        }
      }

      if (devices.garmin  && this.state.dataShared) {
        if (this.state.scope == 'any' || this.state.scope == 'all') {
          showGarminProfile = this.state.garminProfile
        }
      }

      if (devices.garmin  && this.state.dataShared) {
        if (this.state.scope == 'any' || this.state.scope == 'workouts') {
          showGarminWorkoutProfile = this.state.garminWorkoutProfile
        }
      }

      if (devices.whoop && this.state.dataShared) {
        if (this.state.scope == 'workouts' || this.state.scope == 'any') {
          showWorkoutProfile = this.state.whoopWorkoutProfile
        }
      }

      if (devices.oura && this.state.dataShared) {
        if (this.state.scope == 'any') {
          showOuraProfile = this.state.ouraProfile
        }
      }
      if (this.state.scope == 'any' || this.state.scope == 'weight') {
        showWeightProfile = true
      }
      if (this.state.dataShared) {
        if (this.state.scope == 'meals'  || this.state.scope == 'any') {
          showMealsProfile = this.state.mealsProfile
        }
      }
    } else {
      showTodoProfile = true
    }
    let dialsClass = this.state.scope == 'any' ? 'uiCalendarDials' : 'uiCalendarDial'
    if (!showWhoopProfile && !showOuraProfile && !showMealsProfile && !showWeightProfile && !showWorkoutProfile) {
      dialsClass = 'uiCalendarDial'
    }
    return <div className={'uiCalendar' + (isMobile() ? " uiCalendarMobile" : " uiCalenderDesktop")} onDragOver={this.onDragOver} onDrop={this.onDrop} style={this.props.visible ? {} : {display: "none"}}>
      {this.props.opts.videoStream}
      <div className='uiCalendarSearch'>
      <input placeholder={'Search'} value={this.state.searchTerm}   onFocus={this.onSearchFocus} onBlur={this.onSearchBlur}
    onChange={this.onSearchTermChanged} onKeyDown={this.onSearchKeyDown} ref={this.setSearchInput} className='uiCalendarSearchField'/>
      <div className='uiCalendarSearchIcon' onClick={this.onSearchIconClick}>
      <ReactSVG src={this.state.searchTerm ? Cross: Search}/>
      </div>
      </div>
      {this.getContactFilter() && this.state.dataShared && false && <FDScopeButtons scopeButtons={this.scopeButtons} onClick={this.onChangeScope}/>}
      <div className='uiCalendarScroll' ref={this.setScrollRef}>
      <div className='uiCalendarHiddenDuringSearch' style={false && this.state.searchFocus && !isDesktop() ? { display: 'none' } : null}>
      <div className='uiCalendarSelectionContainer'><UICalendarSelectionMenu initialPage={this.state.calView.calendar ? this.state.calView.calendar.type : 'day' } onSelect={value => this.changeView({target: {value: value}})}/></div>
      <div className='uiCalendarCalendar'>
      <div className="md-switching-view-cont">
      <div className="md-switching-view-cal-cont">
      <mobiscroll.Eventcalendar
    firstDay={1}
    firstSelectDay={1}
    ref={this.cal}
    display="inline"
    theme={"windows"}
    themeVariant={"light"}
    view={this.state.calView}
    data={events}
    onDayChange={this.onDayChange}
    onPageChange={this.onPageChange}
    onTap={this.onTap}
      />
      </div>
      </div>
      </div>
      {!this.props.me.isTodoList() && this.state.scope === 'any' && this.state.whoopLinked && this.getContactFilter() && <div key='whoopPerformance' className='uiWhoopPerformanceSummary'>
       <div key='performanceSummary' className='uiWhoopPerformanceSummaryLinks'>
       {this.state.weeklySummaryURL && <div key='weekly' className='uiWhoopPerformanceSummaryLink' onClick={this.openWeeklySummary}>Weekly Summary</div>}
       {this.state.monthlySummaryURL && <div key='monthly' className='uiWhoopPerformanceSummaryLink' onClick={this.openMonthlySummary}>Monthly Summary</div>}
        </div>
       </div>}
    {this.getContactFilter() && <div className={dialsClass}>
     {showWhoopProfile &&
     <div className='uiCalendarWhoopProfile' onClick={() => this.onClickProfile('whoop')}>
       <WhoopProfile
       profile={this.state.whoopProfile}
      me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
       </div>}
    {showOuraProfile &&
     <div className='uiCalendarWhoopProfile' onClick={()=>this.onClickProfile('oura')}>
       <OuraProfile
       profile={this.state.ouraProfile}
       me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
     </div>}
    {showGarminProfile &&
     <div className='uiCalendarWhoopProfile' onClick={()=>this.onClickProfile('garmin')}>
       <GarminProfile
       profile={this.state.garminProfile}
       me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
     </div>}
    {showSleepProfile &&
     <div className='uiCalendarWhoopProfile' onClick={() => this.onClickProfile('sleep')}>
       <WhoopSleepSummary
     profile={this.state.whoopSleepProfile}
       me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
       </div>}
    {showOuraSleepProfile &&
     <div className='uiCalendarWhoopProfile' onClick={()=>this.onClickProfile('ouraSleep')}>
       <WhoopSleepSummary
     profile={this.state.ouraSleepProfile}
       me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
       </div>}
    {showGarminSleepProfile &&
     <div className='uiCalendarWhoopProfile' onClick={()=>this.onClickProfile('garminSleep')}>
       <WhoopSleepSummary
     profile={this.state.garminSleepProfile}
       me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
       </div>}
    
    {showWeightProfile &&
     <div className='uiCalendarWhoopProfile' onClick={()=>this.onClickProfile('weight')}>
     <WeightProfile
     profile={this.state.weightProfile}
     me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
     />
     </div>}
     {showMealsProfile &&
         <div className='uiCalendarWhoopProfile' onClick={() => this.onClickProfile('meals')}>
     <MealsProfile
     profile={this.state.mealsProfile}
     me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
     />
         </div>}
{showWorkoutProfile &&
     <div className='uiCalendarWhoopProfileWorkouts'>
     <div className='uiCalendarWhoopProfile' onClick={()=>this.onClickProfile('workouts')}>
       <WhoopWorkoutSummary
     profile={this.state.whoopWorkoutProfile}
     contact={this.getContactFilter()}
     title={this.state.workoutTypeFilter === -1 ? 'Workouts' : this.state.workoutTypes.find(x => x.selector === this.state.workoutTypeFilter).label}
       me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
     </div>
 {(this.state.workoutTypes.length > 1) && this.state.scope == 'workouts' &&
  <div className='uiCalendarWorkoutsRow2'>
  <FDRadioButtons buttons={this.state.workoutTypes} select={this.selectWorkoutType} selection={this.state.workoutTypeFilter}/>
</div> }
 
 </div>}

    {showGarminWorkoutProfile &&
     <div className='uiCalendarWhoopProfileWorkouts'>
     <div className='uiCalendarWhoopProfile' onClick={()=>this.onClickProfile('garminWorkouts')}>
       <WhoopWorkoutSummary
     contact={this.getContactFilter()}
     profile={this.state.garminWorkoutProfile}
     title={this.state.workoutTypeFilter === -1 ? 'Workouts' : this.state.workoutTypes.find(x => x.selector === this.state.workoutTypeFilter).label}
       me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}
       />
     </div>
     {(this.state.workoutTypes.length > 1) && this.state.scope == 'workouts' &&
      <FDRadioButtons buttons={this.state.workoutTypes} select={this.selectWorkoutType} selection={this.state.workoutTypeFilter}/>}
     </div>}
     {showTodoProfile &&
      <div className={'uiCalendarWhoopProfileWorkouts uiCalendarTodoProfile'}>
      <div className='uiCalendarWhoopProfile' onClick={() => this.onClickProfile('todo')}>
      <TodoProfile profile={this.state.todoProfile}
      title={this.computeTodoProfileTitle()}
      me={this.props.me}
      back={this.state.scope != 'any' && this.scopeBack}/>
      </div>
      {this.state.scope == 'todo' && <div key='liked' className='uiCalendarTodoProfileRow1'>
      <div className='uiCalendarPlansLikes'><span className='uiCalendarPlansEmoji'>👍</span> {this.state.todoProfile.likes}</div>
      <div className='uiCalendarPlansDislikes'><span className='uiCalendarPlansEmoji'>👎</span> {this.state.todoProfile.dislikes}</div>
       </div>}
      {this.state.scope == 'todo' && <div key='filters' className='uiCalendarPlansFilters'>
      <div className='uiCalendarPlansFiltersStatus'>
      <FDRadioButtons buttons={this.state.todoStatusTypes} select={this.selectTodoStatus} selection={this.state.todoStatusType}/>
      </div>
      <div className='uiCalendarPlansFiltersTerm'>
      <FDRadioButtons buttons={this.state.todoTermTypes} select={this.selectTodoTerm} selection={this.state.todoTerm}/>
      </div>
       {(this.state.todoStatusType == 'progress' ||
         this.state.todoStatusType == 'done') &&
        <div className='uiCalendarPlansFiltersOutcome'>
      <FDRadioButtons buttons={this.state.todoOutcomes} select={this.selectTodoOutcome} selection={this.state.todoOutcome}/>
        </div>}
       </div>}
      {this.state.scope == 'todo' &&
       <div key='categories' className='uiCalendarTodoProfileRow2'>
       <div className='uiCalendarTodoProfileRow2Column'>
      <ClickAwayListener onClickAway={this.dismissTodoCategorySelector}>
      <div className='uiCalendarTodoProfileCategories'>
      <div className='uiCalendarTodoProfileCategory' onClick={this.selectTodoCategory}>{this.state.selectingTodoCategory ? '' : this.state.todoCategoryName}</div>
      <UISelectionList select={this.onChangeTodoCategory} options={this.state.todoCategories} selected={this.state.todoCategory} visible={this.state.selectingTodoCategory} value={this.state.todoCategory}/>
       </div>
       </ClickAwayListener>
       </div>
       <div className='uiCalendarTodoProfileRow2Column'>
      <ClickAwayListener onClickAway={this.dismissTodoEmotionSelector}>
      <div className='uiCalendarTodoProfileCategories'>
      <div className='uiCalendarTodoProfileCategory' onClick={this.selectTodoEmotion}>{this.state.selectingTodoEmotion ? '' : this.state.todoEmotionName}</div>
      <UISelectionList select={this.onChangeTodoEmotion} options={this.state.todoEmotions} selected={this.state.todoEmotion} visible={this.state.selectingTodoEmotion} value={this.state.todoEmotion}/>
       </div>
       </ClickAwayListener>
       </div>
       </div>}
      </div>
     }     
       </div>}

      </div>
      <div className='uiCalendarEvents'>
      <div className={'uiCalendarEventControls'}>
      <div className={'uiCalendarEventControlsButtons'}>
      {this.state.view !== 'day' && this.state.scope != 'any' && this.state.scope !== 'todo'  && (
        isDesktop() ?   <div className={'uiCalendarEventPlus'} onClick={()=> this.onClickGraph(this.state.scope)} >      <div className='uiCalendarPlusIcon' ><ReactSVG src={Forward}/></div>
      <div className='uiCalendarPlusText'>Daily</div>
       </div>
          : <div className='uiCalendarGraphs'><UIOKCancel label='Daily' okIcon={Forward} ok={() => this.onClickGraph(this.state.scope)}/></div>)}
    {isIPad() || isMobile() ? <div className={'uiCalendarEventPlus' + (showControls ? '' : ' uiCalendarEventControlsHidden')}>
       <UIOKCancel label={plusText} okIcon={Plus} ok={this.plusClick}/>
       </div>
       :
      <div className={'uiCalendarEventPlus' + (showControls ? '' : ' uiCalendarEventControlsHidden')} onClick={this.plusClick} >
      <div className='uiCalendarPlusIcon' ><ReactSVG src={Plus}/></div>
      <div className='uiCalendarPlusText'>{plusText}</div>
       </div>}
    </div>
    {!isMobile() && <div key='eventsDate' className='uiCalendarEventsDate'>
      {this.state.searchTerm ? "Results: "+events.length : this.state.currentDate && moment(this.state.currentDate).format("MMM Do YYYY")}
     </div>}
      </div>
      <div className='uiCalendarEventsScroller'>
      <div className='uiCalendarEventsList'>
      {events.slice(0, 100).map(event => {
        const appt = this.appts[event.id];
        if (!appt) {
          const sub = this.subs[event.id];
          if (sub && sub.state == 'active') {
            return <div key={event.id} className='uiCalendarAppointmentEvent'>
              <UISubscription
            editable={false}
            openChat={this.props.openContact}
            invoiceAmount={sub.invoiceAmount}
            isChat={false}
            state={sub.state}
            hideWith={false}
            id={event.id}
            start={event.start}
            end={event.end}
            organizer={this.props.me.self}
            onClick={()=>this.openSubscription(sub)}
            responseTime={sub.responseTime}
            description={sub.description}
            invoiceDescription={sub.invoiceDescription}
            with={sub.contact}/>
              </div>
          }
          return null;
        }
        const start = appt.start && new Date(appt.start)
        const end = appt.end && new Date(appt.end)
        const waitForSystemUpdate = () => this.waitForSystemUpdate(appt)
        return <div key={appt.id} className='uiCalendarAppointmentEvent'>
          <UIAppointment me={this.props.me} appt={appt} openChat={this.props.openContact} organizer={appt.organizer} client={appt.client} status={appt.status} appointment={this.appts[event.id]} id={event.id} editable={appt.editable} start={start} title={appt.titleHtml || appt.title || "Video Conference"} end={end} with={appt.contact} invoiceAmount={appt.invoiceAmount} paymentAmount={appt.paymentAmount} paymentStatus={appt.paymentStatus} onClick={event.open} waitForSystemUpdate={waitForSystemUpdate}/>
          </div>
      })}
    </div>
      </div>
      </div>
      </div>
      {!isMobile() && this.renderAppointment()}
    </div>
  }

  renderAppointment = () => {
    if (!this.state.openEvent) {
      return null
    }
    if (this.state.openEvent.appt) {
      if (this.state.openEvent.appt.meal) {
        if (!(this.getContactFilter() && this.getContactFilter().uid == this.props.me.uid)) {
          return null
        }
      } else if (this.getContactFilter()) {
        return null
      }
    }
    return <div key='appointmentPopup' className='uiScheduleAppointmentPopup'>
      <UIScheduleAppointment
    todoCategories={this.state.todoCategories}
    openPopup={this.props.opts.openPopup}
    appointmentId={this.state.openEvent.id}
    scope={this.state.openEvent.scope}
    back={this.closeEvent}
    isNew={this.state.openEvent.isNew}
    date={this.state.openEvent.start}
    start={this.state.openEvent.start}
    end={this.state.openEvent.end}
    headerTitle={"Schedule Appointment"}
    title={this.state.openEvent.title}
    with={this.state.openEvent.with}
    withReadOnly={this.getContactFilter()}
    on={this.state.openEvent.date}
    trash={this.trashEvent}
    schedule={this.okEvent}
    downloadInvoice={()=>this.downloadInvoice(this.state.openEvent.id)}
    accept={()=>this.acceptEvent(this.state.openEvent.id)}
    decline={()=>this.declineEvent(this.state.openEvent.id)}
    error={this.state.openEvent.error}
    onChange={this.onChangeEvent}
    editable={this.state.openEvent.editable}
    client={this.state.openEvent.client}
    invoiceAmount={this.state.openEvent.invoiceAmount || 0}
    invoiceDescription={this.state.openEvent.invoiceDescription}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    status={this.state.openEvent.status}
    paymentStatus={this.state.openEvent.paymentStatus}
    paymentMethod={this.state.openEvent.paymentMethod}
    paymentIntentId={this.state.openEvent.paymentIntentId}
    event={this.state.openEvent}
    me={this.props.me}
    mealSelection={this.state.openEvent.mealType}
      />
      </div>
  }

  onChangeEvent = (field, value) => {
    if (value == this.state.openEvent[field]) return;
    this.state.openEvent[field] = value;
  }


  trashEvent = () => {
    let p;
    ////////debugger;
    if (this.state.openEvent.client) {
      p = this.declineEvent(this.state.openEvent.id);
    } else {
      p = this.removeEvent(this.state.openEvent.id);
    }
    return p.then(result => {
      this.setState({
        openEvent: null
      });
    }).catch(err => {
      console.error(err);
      this.setState({
        openEvent: null
      });
      return Promise.reject(err);
    });
  }

  onSystemUpdate = id => {
    const waiters = this.waiting[id];
    if (waiters) {
      delete this.waiting[id];
      waiters.map(resolve => resolve());
    }
  }
  
  waitForSystemUpdate = appt => {
    return this.waitForUpdate(appt.id)
  }

  waitForUpdate = (id) => {
    let waiting = this.waiting[id];
    if (!waiting) {
      waiting = [];
      this.waiting[id] = waiting;
    }
    return new Promise((resolve, reject) => {
      waiting.push(resolve);
    });
  }

  waitForCreate = (contact, appt) => {
    return new Promise((resolve, reject) => {
      this.createWaiters.push({
        start: appt.start,
        end: appt.end,
        contact: contact,
        resolve: resolve,
      })
    });
  }

  okEvent = (form) => {
    const data = this.state.openEvent;
    if (data.scope == 'meals') {
      return this.scheduleMeal(form).then(() => {
        debugLog('saved meal')
        this.setState({
          openEvent: null
        })
      })
    }
    else if (data.scope == 'workouts') {
      return this.scheduleWorkout(form).then(() => {
        debugLog('saved workout')
        this.setState({
          openEvent: null
        })
      })
    }
    else if (data.scope == 'weight') {
      return this.scheduleWeight(form).then(() => {
        debugLog('saved weight')
        this.setState({
          openEvent: null
        })
      })
    }
    let p;
    if (this.state.openEvent.isNew) {
      const contact = data.with;
      const start = data.start;
      const end = data.end;
      const date = data.date;
      const updates = {
        start: getTime(date, start),
        end: getTime(date, end),
        title: data.title,
        invoiceDescription: data.invoiceDescription,
        invoiceAmount: data.invoiceAmount,
        title: data.title,
      }
      const p1 = this.waitForCreate(contact, updates);
      p = this.props.me.createAppointment(contact, updates).then(p1);
    } else  if (!this.state.openEvent.editable) {
      p = this.acceptEvent(this.state.openEvent.id);
    } else {
      p = this.rescheduleEvent(this.state.openEvent.id, this.state.openEvent.start, this.state.openEvent.end);
    }
    window.showProgressIndicator("Scheduling");
    return p.then(result => {
      //debugLog("okEvent: ", result);
      window.hideProgressIndicator();
      if (result && result.data && result.data.error) {
        this.state.openEvent.error = result.data.error;
        return this.forceUpdate();
      }
      this.setState({
        openEvent: null
      });
    }).catch (err => {
      debugLog(err);
      return Promise.reject(err);
    });
  }

  scopeBack = () => {
    this.setState({
      scope: 'any'
    }, () => {
      debugLog("scrollTop =>", this.scrollPos)
      if (this.scrollPos) {
        this.scroller.scrollTop = this.scrollPos
      }
    })
  }

  setScope = (scope, scrollPos) => {
    this.setState({
      scope
    }, () => {
      debugLog("calendar scope set", this.state.scope)
      this.scroller.scrollTop = scrollPos || 0
      console.log('scrollTop', this.scroller.scrollTop)
    })
  }

  onClickGraph = async scope => { 
    this.scrollPos = 0
    if (this.props.opts.onClickProfile) {
      const scope = this.state.scope
      let category = {
        scope: scope,
        name: capitalize(scope)
      }
      switch (scope) {
        case 'all':
          category = { scope: 'overview', name: 'Overview' }
          break
        case 'workouts':
          {
            const type = this.state.workoutTypeFilter
            if (type !== -1) {
              const whoop = this.props.me.getWhoop()
              const sport = whoop.getSport(type)
              category = { scope: 'workouts', name: sport.name, filter: type }
            } else {
              category = { scope: 'workouts', name: 'Workouts', filter: -1 }
            }
            break
          }
      }
      const filter = this.getDateFilter()
      if (this.cycleData) {
        this.props.opts.onClickProfile(this.state.whoopProfile, this.currentCycleData, this.mealCycleData || [], this.state.weightProfile && this.state.weightProfile.weights, category)
      } else if (this.garminCycleData) {
        this.props.opts.onClickProfile(this.state.garminProfile, this.garminCycleData, this.mealCycleData || [], this.state.weightProfile && this.state.weightProfile.weights, category)
      } else {
        if (this.ouraCycles) {
          let cycleData = []
          for (const id in this.ouraCycles) {
            const cycle = this.ouraCycles[id]
            debugLog("cycle:", cycle.start, cycle.end)
            if (this.state.view == 'day') {
              const { start, end } = filter.endPoints
              const cycleEnd = cycle.end
              if (!(cycleEnd >= start && cycleEnd <= end)) {
                continue
              }
            } else {
              if (!filter({
                start: new Date(cycle.start),
                end: new Date(cycle.end)
              })) {
                debugLog("filtered out")
                continue
              }
            }
            cycleData.push(cycle)
          }
          cycleData.sort((x, y) => x.start - y.start)
          this.props.opts.onClickProfile(this.state.ouraProfile, cycleData, this.mealCycleData || [], this.state.weightProfile && this.state.weightProfile.weights, category)
        }
      }
    }
  }

  computeTodoProfileTitle = () => {
    let title = 'plans'
    if (this.state.todoStatusType === 'done' && this.state.todoOutcome !== 'all') {
      title = 'outcomes'
    }
    else if (this.state.todoStatusType === 'progress' && this.state.todoOutcome !== 'all' && this.state.todoTerm == 'all') {
      title = ''
    }
    if (this.state.todoCategory !== 'all') {
      title = this.state.todoCategoryName + ' ' + title
    }
    if (this.state.todoTerm !== 'all') {
      title = this.state.todoTermTypes.find(x => x.selector == this.state.todoTerm).label + ' ' + title 
    }
    let title0 = title
    switch (this.state.todoStatusType) {
      case 'done':
        if (this.state.todoOutcome == 'all') {
          title = 'completed ' + title
        }
        break
      case 'progress':
        title = title ? 'progress on ' + title : 'progress'
        break
      case 'all':
        break
      case 'pending':
        title = 'pending ' + title
        break
      case 'canceled':
        title = 'canceled ' + title
        break
    }
    let title1 = title
    switch (this.state.todoStatusType) {
      case 'done':
        title = title0
      case 'progress':
        switch (this.state.todoOutcome) {
          case 'all':
            title = title1
            break
          case 'better':
            title = 'Better than expected ' + title
            break
          case 'worse':
            title = 'Worse than expected ' + title
            break
          case 'expected':
            title = 'As expected ' + title
            break
        }
        break
    }
    return capitalize(title)
  }
  
  onClickProfile = option => {
    if (this.state.scope == 'any') {
      this.scrollPos = this.scroller.scrollTop
      let scope
      switch (option) {
        case 'whoop':
        case 'oura':
        case 'garmin':
          scope = 'all'
          break;
        case 'sleep':
        case 'garminSleep':
        case 'ouraSleep':
          scope = 'sleep'
          break
        case 'meals':
          scope = 'meals'
          break
        case 'workouts':
        case 'garminWorkouts':
          scope = 'workouts'
          break;
        case 'weight':
          scope = 'weight'
          break;
        case 'todo':
          scope = 'todo'
          break
      }
      return this.setScope(scope)
    } else {
      return this.setScope('any', this.scrollPos)
    }
  }

  closeEvent = event => {
    if (this.props.opts.closeEvent) {
      this.props.opts.closeEvent()
    } 
    this.setState({
      openEvent: null
    });
  }

  plusClick = e => {
    return this.plusEvent()
  }

  plusEvent = (with_, date) => {
    with_ = with_ || this.getContactFilter()
    if (!date) date = new Date(this.state.currentDate);
    const now = new Date(Date.now());
    date.setHours(now.getHours());
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const start = date;
    const end = new Date(date);
    end.setHours(date.getHours() % 24 + 1);
    let p = Promise.resolve();
    let title = "Video Conference"
    let scope
//    //////debugger
    if (this.getContactFilter()) {
      title = ''
      if (this.getContactFilter().uid == this.props.me.self.uid && this.props.isMe) {
        scope = this.state.scope
      } else if (this.state.dataShared) {
        scope = 'workouts'
      } else {
      }
    }
    const event = {
      scope: scope,
      id: null,
      date: start,
      start: start,
      end: end,
      with: with_,
      isNew: true,
      title: title,
      editable: true,
      client: false,
      reschedule: false,
      invoiceDescription: "",
      invoiceAmount: 0,
      paymentIntentId: "",
      status: "",
      paymentStatus: "",
      finalPaymentMethod: null,
      paymentIntentId: ""
    }
    if (this.props.opts.openEvent) {
      this.props.opts.openEvent(event)
    }
    if (!this.props.opts.disableAppointmentPopup) {
      this.setState({
        openEvent: event,
      });
    }
  }

  openAppointment = appt => {
    if (!this.appts[appt.id]) {
      this.createEvent(appt);
    }
    this.openEvent(this.events[appt.id]);
    const cal = this.cal.current;
    const when = new Date(appt.start)
    cal.instance.navigate(when);
    this.setState({
      currentDate: when
    }, this.updateEventsLater);
  }
  
  scheduleNewAppointment = contact => {
    this.plusEvent(contact);
  }
  
  openEvent = (event, newDate)  => {
    debugger
    const appt = this.appts[event.id]
    if (appt.workout && appt.workout.scheduled) {
      if (appt.workout.status && appt.workout.status !== 'todo') {
        return
      }
    }
    let start = event.start;
    let end = event.end;
    ////////debugger;
    if (newDate) {
      start = new Date(newDate);
      start.setHours(event.start.getHours());
      start.setMinutes(event.start.getMinutes());
      end = new Date(newDate);
      end.setHours(event.end.getHours());
      end.setMinutes(event.end.getMinutes());
    }
    debugLog(appt);
    let scope
    if (this.getContactFilter()) {
      if (this.getContactFilter().uid == this.props.me.self.uid) {
        if (appt.todo) {
          scope = 'todo'
        } else if (appt.meal) {
          scope = 'meals'
        } else if (appt.weight && appt.weight.editable) {
          scope = 'weight'
        }
      } else if (this.state.dataShared) {
        scope = 'workouts'
      } else {
      }
    }
    //if (!scope) return
    const newEvent =  {
      scope: scope,
      id: event.id,
      date: start,
      start: start,
      end: end,
      with: appt.contact,
      title: appt.title || "Video Conference",
      editable: appt.editable && appt.status != 'canceled',
      client: appt.organizer.uid != this.props.me.self.uid,
      reschedule: true,
      invoiceDescription: appt.invoiceDescription || "",
      invoiceAmount: appt.invoiceAmount || 0,
      paymentIntentId: appt.paymentIntentId,
      status: appt.status,
      paymentStatus: appt.paymentStatus,
      finalPaymentMethod: appt.finalPaymentMethod,
      paymentIntentId: appt.paymentIntentId,
      appt: event.appt
    }
    //////debugger
    if (this.props.opts.openEvent) {
      return this.props.opts.openEvent(newEvent)
    }
    if (!this.props.opts.disableAppointmentPopup) {
      this.setState({
        openEvent: newEvent
      });
    }
  }

  downloadInvoice = id => {
    return this.props.me.showReceipt(id);
  }

  acceptEvent = id => {
    window.showProgressIndicator("Accepting Appointment");
    const p = this.waitForUpdate(id);
    return this.props.me.acceptAppointment(id).then(window.hideProgressIndicator).then(() => {
      return p.then(this.closeEvent);
    });
  }

  declineEvent = id => {
    window.showProgressIndicator("Declining Appointment");
    const p = this.waitForUpdate(id);
    return this.props.me.declineAppointment(id).then(window.hideProgressIndicator).then(() => {
      return p.then(this.closeEvent);
    });                                                                                            
  }

  scheduleMeal = async (form) => {
    const data = this.state.openEvent;
    const { mealSelection, foods } = form
    const date = form.date
    const start = form.start
    const end = form.end;
    const meal = {
      id: data.appt ? data.appt.id : undefined,
      start: getTime(date, start),
      end: getTime(date, end),
      foods: foods,
      type: mealSelection
    }
    await this.props.me.saveMeal(meal)
  }
  
  scheduleWeight = async (form) => {
    const data = this.state.openEvent;
    const { date, start, weight } = form
    const rec = {
      id: data.appt ? data.appt.id : undefined,
      when: getTime(date, start),
      weight
    }
    await this.props.me.saveWeight(rec)
  }

  scheduleWorkout = async (form) => {
    const data = this.state.openEvent;
    const { activity, description, demo, demoFile, sets, reps, weight, sport } = form
    if (data.appt) {
      // reschedule
    } else {
      const date = data.date;
      const progress = percent => {
        this.setState({
          fileUpload: true,
          fileUploadProgress: percent
        })
      }
      const ref = demoFile ? (await this.props.me.uploadFile(demoFile, progress)) : undefined
      const downloadURL = ref ? (await ref.getDownloadURL()) : undefined
      this.setState({
        fileUpload: false,
        fileUploadProgress: 0
      })
      const workout = {
        start: Date.now(),
        activity,
        description,
        sets,
        reps,
        weight,
        demo: demoFile ? [{
          contentType: demoFile.type,
          downloadURL
        }] : (demo || []),
        activity: activity
      }
      await this.props.me.saveWorkout(this.getContactFilter(), workout)
    }
  }

  getContactFilter = () => {
    let contact = this.props.contactFilter
    if (!contact) return null
    if (contact.isGroup) {
      contact = this.props.me.getContact(contact.group.organizer)
    }
    console.log("contactFilter", contact)
    return contact
  }
  
  
  rescheduleEvent = (id) => {
    const data = this.state.openEvent;
    const date = data.date;
    const start = getTime(date, data.start);
    const end = getTime(date, data.end);
    const appt = this.appts[id];
    const prevStart = appt.start;
    const prevEnd = appt.end;
    const prevInvoiceAmount = appt.invoiceAmount || 0;
    const prevInvoiceDescription = appt.invoiceDescription || "";
    const prevTitle = appt.title || "";
    const updates = {
      id: id,
      start: start,
      end: end,
      invoiceDescription: this.state.openEvent.invoiceDescription || "",
      invoiceAmount: this.state.openEvent.invoiceAmount || 0,
      title: this.state.openEvent.title || "",
    }
    if (updates.start == prevStart &&
        updates.end == prevEnd &&
        updates.invoiceDescription == prevInvoiceDescription &&
        updates.invoiceAmount == prevInvoiceAmount &&
        updates.title == prevTitle) {
      //debugLog("no change");
      this.closeEvent();
      return Promise.resolve();
    }
    const event = this.events[id];
    const p = this.waitForUpdate(id);
    return this.props.me.updateAppointment(updates).then(p)
  }

  removeEvent = id => {
    window.showProgressIndicator("Removing Appointment");
    const p = this.waitForUpdate(id);
    return this.props.me.deleteAppointment(id).then(() => p);
  }
}




