import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import ProfileSml from "../../assets/icons/ProfileSml.svg";
import {UIInputField} from "../Home"
import {UIOKCancel} from "../OKCancel";
import Edit from "../../assets/icons/ChatEdit.svg";
import Search from "../../assets/icons/Search.svg";
import Cross from "../../assets/icons/Cross.svg";
import Trash from "../../assets/icons/Trash.svg";
import moment from 'moment';
import Schedule from "../../assets/icons/ScheduleClock.svg";
import Plus from "../../assets/icons/Plus.svg";
import Close from "../../assets/icons/Cross.svg";
import Update from "../../assets/icons/Update.svg";
import Forward from "../../assets/icons/Forward.svg";
import {FDPage} from '../../Mobile/src/components/Page'
import {LBWorkoutCard} from '../WorkoutCard'
import {FDRadioButtons} from '../../Mobile/src/components/Button'
import {UIScheduleAppointment} from '../ScheduleAppointment'
import {isIPad, isMobile, isSafari, isDesktop} from '../../Platform.js'
import ReactSwipeableViews from 'react-swipeable-views'
import { Dots } from '../Dots'
import './index.css'

export class UIWorkoutSession extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sequence: [],
      recentWorkouts: [],
      workoutTabSelection: 'workout',
      form: {},
      formError: {},
      searchForm: {},
      selection: -1
    }
  }

  workoutTabs = [
    {
      selector: 'workout',
      label: 'Workout',
    },
    {
      selector: 'recent',
      label: 'Recent'
    }
  ]

  renderHeader = () => {
      return <div className='uiScheduleAppointmentHeader'>
      <div className='uiScheduleAppointmentHeaderClose' onClick={this.props.close}>
      <ReactSVG src={Close}/>
      </div>
      
      <div className='uiScheduleAppointmentHeaderInfo'>
      
      <div className='uiScheduleAppointmentHeaderIcon'>
      <ReactSVG src={Schedule}/>
      </div>
      
      <div className='uiScheduleAppointmentHeaderTitle'>
      {'Workout'}
      </div>
      <div className='uiScheduleAppointmentHeaderDate'>
      {moment(this.state.when).format("MMM Do YYYY")}
    </div>
   </div>
  </div>
  }

  recents = {}

  componentDidMount() {
    if (this.props.workout) {
      const sessionId = this.props.workout.sessionId
      const index = this.props.workout.index
      this.setState({
        when: this.props.workout.scheduled
      })
      this.props.me.getWorkoutSession(sessionId).then(workoutSession => {
        this.state.workoutId = sessionId
        this.state.when = workoutSession.scheduled
        this.state.form.description = workoutSession.description
        this.setState({
          sequence: workoutSession.activities,
          selection: index,
        })
      })
    } else {
      this.setState({
        when: Date.now()
      })
    }
    this.recentSub = this.props.me.observeRecentWorkoutSessions().subscribe(change => {
      if (change.type == 'removed' || change.workout.activities.length == 0) {
        delete this.recents[change.workout.id]
      } else {
        this.recents[change.workout.id] = change.workout
      }
      const rec = change.workout
      change.workout.activities.forEach((workout, i) => {
        const sport = this.props.me.getWhoop().getSport(workout.activity)
        workout.id = rec.id + '-' + i
        workout.scheduled = rec.scheduled
        workout.trainer = rec.trainer
        workout.client = rec.client
        workout.activity = {
          uid: sport.id,
          displayName: sport.name,
          profileImage: sport.iconUrl,
        }
      })
      this.updateRecentListLater()
    })
  }

  updateRecentListLater = () => {
    const recentList = []
    for (const id in this.recents) {
      const workout = this.recents[id]
      recentList.push(workout)
    }
    recentList.sort((a, b) => {
      return b.scheduled - a.scheduled
    })
    this.setState({
      recentWorkouts: recentList
    })
  }

  componentWillUnmount() {
    if (this.recentSub) {
      this.recentSub.unsubscribe()
    }
  }

  openPopup = popup => {
    this.setState({
      popup: popup
    })
  }

  addWorkout = () => {
    this.setState({
      formError: {},
      addWorkout: true,
      workoutIndex: -1
    })
    this.openPopup(() => this.renderWorkoutPopup())
  }

  editWorkout = (index, workout) => {
    this.setState({
      formError: {},
      addWorkout: false,
      workoutIndex: index
    })
    this.openPopup(() => this.renderWorkoutPopup(workout))
  }

  addWorkoutToSession = async form => {
    console.log("addWorkoutToSession", form)
    let { demo, demoFile, media, reps, sets, weight, duration, activity, description }  = form
    if (demoFile) {
      const url = URL.createObjectURL(demoFile)
      demo = [{
        file: demoFile,
        downloadURL: url,
        contentType: demoFile.type
      }]
    }
    const workout = {
      demo: demo ? demo.map(x => x) : [],
      media,
      reps,
      sets,
      weight,
      duration,
      activity,
      description 
    }
    if (this.state.workoutIndex >= 0) {
      this.state.sequence[this.state.workoutIndex] = workout
    } else {
      this.state.sequence.push(workout)
      this.state.selection = this.state.sequence.length - 1
    }
    this.openPopup(null)
  }

  renderWorkoutPopup = (workout) => {
    const back = () => {
      this.openPopup(null)
      this.setState({
        addWorkout: false
      })
    }
    let appt
    if (workout) {
      appt = {
        start: new Date(workout.scheduled),
        end: new Date(workout.scheduled),
        workout: workout,
        organizer: this.props.me.self,
        scheduledWorkout: workout,
        uid: this.props.me.self.uid
      }
    }
    return <UIScheduleAppointment
    me={this.props.me}
    okActionTitle={'Add Activity'}
    schedule={this.addWorkoutToSession}
    nonSession={true}
    scope='workouts'
    back={back}
    appt={appt}
    isNew={!workout}/>
  }

  renderAddButton = () => {
    let ok = this.addWorkout
    let okIcon = Plus
    let okLabel = "Add Activity"
    let cancel
    let cancelIcon
    return <div className='uiWorkoutSessionAddWorkout'>
      <UIOKCancel okIcon={okIcon} ok={ok} cancelIcon={cancelIcon} cancel={cancel} label={okLabel}/>
      </div>
  }

  deleteWorkoutSession = async () => {
    await this.props.me.deleteWorkoutSession(this.state.workoutId)
    this.props.close()
  }

  ok = async () => {
    let id
    if (this.state.workoutId) {
      id = this.state.workoutId
    }
    if (!this.state.form.description) {
      this.setState({
        formError: {
          field: 'description',
          error: 'Description is required'
        }
      })
      return
    }
    if (!this.state.sequence.length) {
      this.setState({
        formError: {
          field: 'activities',
          error: 'Activities are required'
        }
      })
      return
    }
    // upload demo videos if necessary
    for (const act of this.state.sequence) {
      for (const demo of act.demo) {
        if (demo.file) {
          const demoFile = demo.file
          const ref = await this.props.me.uploadFile(demoFile)
          const downloadURL = await ref.getDownloadURL()
          demo.downloadURL = downloadURL
          delete demo.file
        }
      }
    }
    const wkout = {
      id,
      description: this.state.form.description,
      scheduled: this.state.when,
      activities: this.state.sequence.map(act => {
        let { start, end, status, demo, media, reps, sets, weight, duration, activity, description } = act
        return {
          demo: demo ? demo.map(x => x) : [],
          media,
          reps,
          sets,
          weight,
          duration,
          activity: activity.uid,
          description 
        }
      })
    }
    const result = await this.props.me.saveWorkoutSession(this.props.client.uid, wkout)
    if (result.error) {
      console.error(result.error, wkout)
      throw new Error(result.error)
    }
    return this.props.close()
  }

  renderSearchField = () => {
      return <div className='uiCalendarSearch'>
      <input placeholder={'Search'} value={this.state.recentSearchTerm}   onFocus={this.onSearchFocus} onBlur={this.onSearchBlur}
    onChange={this.onSearchTermChanged} ref={this.setSearchInput} className='uiCalendarSearchField'/>
      <div className='uiCalendarSearchIcon' onClick={this.onSearchIconClick}>
      <ReactSVG src={this.state.recentSearchTerm ? Cross: Search}/>
      </div>
      </div>
  }

  getActivityIcon = () => {
    return this.props.me.getWhoop().getActivityIcon()
  }

  selectWorkoutTab = (tab) => {
    this.setState({
      workoutTabSelection: tab.selector
    })
  }
  
  renderOptions = () => {
      return <div key='recent' className='uiScheduleAppointmentWorkoutOrRecent'>
      <div className='uiScheduleAppointmentWorkoutOrRecentTabs'>
      <FDRadioButtons buttons={this.workoutTabs} selection={this.state.workoutTabSelection} select={this.selectWorkoutTab}/>
      </div>
      <div key='workoutOption' className='uiScheduleAppointmentWorkoutOption' style={this.state.workoutTabSelection == 'workout' ? null : { display: 'none' }}>
      {this.renderBody()}
      </div>
      {this.state.workoutTabSelection == 'recent' && <div key="recentOption" className='uiScheduleAppointmentWorkoutOption'>
      <div className='uiScheduleAppointmentWorkoutRecentList'>
      {false && <div classname='uiScheduleAppointmentWorkoutRecentListSearchFields'>
      {this.renderSearchField()}
       <div key="activity" className='uiScheduleAppointmentBodyField uiScheduleAppointmentActivity uiScheduleAppointmentActivityFilterField'>
      <UIInputField defaultValue={{displayName: '', uid: 'empty', profileImage: this.getActivityIcon()}} label={'Activity'} placeholder={''} error={this.state.formError} value={this.state.searchForm.activity} icon={Edit} name='activity' search={this.searchActivities} icon={Edit} onChange={this.onChangeSearch} type='contact'/>
      </div>
      <div key="client" className='uiScheduleAppointmentBodyField uiScheduleAppointmentWithFilterField'>
       <UIInputField label={'Client'} search={this.searchContacts} value={this.state.searchForm.with} error={this.state.formError} icon={ProfileSml} name='with' onChange={this.onChangeSearch} type='contact'/>
       </div>
      </div>}
      <div className='uiScheduleAppointmentWorkoutRecentListTitle'>Workouts</div>
      <div className='uiScheduleAppointmentWorkoutRecentListWorkouts'>
      {this.renderRecentList()}
    </div>
       </div>
       </div>}
      </div>
  }


  autoFill = workout => {
    const now = Date.now()
    this.state.sequence = workout.activities.map((act, i) => {
      const w = JSON.parse(JSON.stringify(act))
      w.scheduled = now + i
      w.status = 'pending'
      w.start = w.end = 0
      w.media = undefined
      return w
    })
    this.state.form.description = workout.description
    this.setState({
      selection: 0,
      workoutTabSelection: 'workout'
    })
  }


  renderRecentList = () => {
    return this.state.recentWorkouts.map(workout => {
      return this.renderSession(workout)
    })
  }

  onChangeIndex = index => {
    this.setState({
      selection: index
    })
  }

  moveLeft = () => {
    const i = this.state.selection
    const seq = this.state.sequence
    const tmp = seq[i-1]
    seq[i-1] = seq[i]
    seq[i] = tmp
    this.state.selection = i-1
    this.forceUpdate()
  }

  moveRight = () => {
    const i = this.state.selection
    const seq = this.state.sequence
    const tmp = seq[i+1]
    seq[i+1] = seq[i]
    seq[i] = tmp
    this.state.selection = i+1
    this.forceUpdate()
  }

  renderDescription = () => {
    const onChange = (field, value) => {
      this.state.form.description = value
      this.state.formError = {}
      this.forceUpdate()
    }
    return <div className='uiWorkoutSessionDescriptionInput'>
      <div className='uiScheduleAppointmentBodyFields'>
      <UIInputField label={'Description'} placeholder={''} error={this.state.formError} value={this.state.form.description} icon={Edit} name='description' icon={Edit} onChange={onChange} type='text'/>
      </div>
    </div>
  }

  setContainerWorkoutsRef = ref => {
    this.workoutsContainer = ref
  }

  renderBody = () => {
    let isNew = !this.props.workout
    let footerStyle
    let ok = this.ok
    let okIcon
    let okLabel
    let cancel
    let cancelIcon
    if (isNew) {
      okIcon = Forward
      okLabel = "Schedule"
    } else {
      okIcon = Update
      okLabel = "Update"
      cancel = this.deleteWorkoutSession
      cancelIcon = Trash
    }
    let error = this.state.formError.error
    let selection = this.state.selection
    let leftStyle
    let rightStyle
    if (selection < 1) {
      leftStyle = { visibility: 'hidden'}
    }
    if (selection + 1 >= this.state.sequence.length) {
      rightStyle = {visibility: 'hidden'}
    }
    return <div className='uiWorkoutSessionBody'>
      {this.renderDescription()}
      <div className='uiWorkoutSessionFieldLabel'>Activities</div>
      <div className='uiWorkoutSessionWorkoutsContainer' ref={this.setWorkoutsContainerRef}>
      <div className='uiWorkoutSessionWorkouts'>
      <ReactSwipeableViews
      onChangeIndex={this.onChangeIndex} 
      index={selection}
      enableMouseEvents={true}
      ignoreNativeScroll={true}>
      {
        this.state.sequence.map((workout, i) => {
          const workout1 = {
          index: i,
          scheduled: workout.scheduled || Date.now(),
          trainer: this.props.me.self.uid,
          status: workout.status || 'ready',
          client: this.props.client.uid,
          activity: workout.activity,
          description: workout.description,
          reps: workout.reps,
          sets: workout.sets,
          duration: workout.duration,
          weight: workout.weight,
          activity: workout.activity,
          demo: workout.demo,
          media: workout.media
        }
        const edit = async () => {
          this.editWorkout(i, workout1)
        }
        const trash = async () => {
          if (i + 1 === this.state.sequence.length) {
            this.state.selection--
          }
          this.state.sequence.splice(i, 1)
          this.forceUpdate()
        }
        return <div className='uiWorkoutSessionCard'><LBWorkoutCard workout={workout1} inactive={false} me={this.props.me} isEditTime={true} edit={edit} trash={trash} embed={true}/></div>
        })}
     </ReactSwipeableViews>          
      </div>
      </div>
      {<div className='uiWorkoutSessionDots'>
       <div className='uiWorkoutSessionLeft' style={leftStyle} onClick={this.moveLeft}><ReactSVG src={Forward}/></div>
      <Dots position={selection} clickable={true} length={this.state.sequence.length} positionChangeListener={ position => this.onChangeIndex(position)} />
       <div className='uiWorkoutSessionRight' style={rightStyle} onClick={this.moveRight}><ReactSVG src={Forward}/></div>
       </div>}
      {this.renderAddButton()}
      <div className='uiScheduleAppointmentOrganizerPageFooter'>
      <div className='uiScheduleAppointmentBodyFieldsError'>
      {error}
    </div>
      <div className='uiScheduleAppointmentBodyButtons'>
      <UIOKCancel okIcon={okIcon} ok={ok} cancelIcon={cancelIcon} cancel={cancel} label={okLabel}/>
      </div>
      </div>
      </div>
  }

  selections = {}

  renderSession = (workoutSession) => {
    const selection = this.selections[workoutSession.id] || 0
    const onChangeIndex = index => {
      this.selections[workoutSession.id] = index
      this.forceUpdate()
    }
    return <div className='uiWorkoutSessionBody'>
      <div className='uiWorkoutSessionHeader'>
       <div className='uiWorkoutSessionHeaderIndicator'/>
       <div className='uiWorkoutSessionHeaderContent'>
         <div className='uiWorkoutSessionDescription'>{workoutSession.description || "Workout"}</div>
      <div className='uiWorkoutSessionSelectButton'><UIOKCancel label='Select' okIcon={Forward} ok={()=>this.autoFill(workoutSession)}/></div>
      {workoutSession.activities.length > 0 && <div className='uiWorkoutSessionDots'>
       <Dots position={selection} clickable={true} length={workoutSession.activities.length} positionChangeListener={ onChangeIndex} />
       </div>}
       </div>
      </div>
      <div className='uiWorkoutSessionWorkoutsContainer'>
      <div className='uiWorkoutSessionWorkouts'>
      <ReactSwipeableViews
      onChangeIndex={onChangeIndex} 
      index={selection}
      enableMouseEvents={true}
      ignoreNativeScroll={true}>
      {
        workoutSession.activities.map((workout, i) => {
        const workout1 = {
          scheduled: Date.now(),
          trainer: this.props.me.self.uid,
          status: 'ready',
          client: this.props.client.uid,
          activity: workout.activity,
          description: workout.description,
          reps: workout.reps,
          sets: workout.sets,
          duration: workout.duration,
          weight: workout.weight,
          activity: workout.activity,
          demo: workout.demo,
          media: workout.media,
          session: workout.session
        }
        const edit = null
        const trash = null
          return <div className='uiWorkoutSessionCard'><LBWorkoutCard workout={workout1} inactive={false} me={this.props.me} isEditTime={true} edit={edit} trash={trash} embed={true}/></div>
        })}
     </ReactSwipeableViews>          
      </div>
      </div>
      </div>
  }
  

  render () {
    let popup
    if (this.state.popup) popup = this.state.popup()
    return <div className='uiWorkoutSessionContainer'>
      <FDPage me={this.props.me} noHeader={true} subPage={popup}>
      <div key='session' className='uiWorkoutSession'>
      {this.renderHeader()}
      {this.renderOptions()}
      </div>
      </FDPage>
      </div>
  }
}
