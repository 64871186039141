import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Board from "../../assets/icons/Blackboard/Board.svg";
import Clear from "../../assets/icons/Blackboard/Clear.svg";
import Color from "../../assets/icons/Blackboard/Color.svg";
import Pen from "../../assets/icons/Blackboard/Pen.svg";
import Pan from "../../assets/icons/Blackboard/Pan.svg";
import PenCursor from "../../assets/icons/Blackboard/PenCursor.svg";
import PenCursorImage from "../../assets/icons/Blackboard/PenCursor.png";
import Redo from "../../assets/icons/Blackboard/Redo.svg";
import Undo from "../../assets/icons/Blackboard/Undo.svg";
import Select from "../../assets/icons/Blackboard/Select.svg";
import Cross from "../../assets/icons/Cross.svg";
import Upload from "../../assets/icons/File.svg";
import Save from "../../assets/icons/Save.svg";
import ResizeObserver from 'resize-observer-polyfill';
import {isMobile, isIPad} from "../../Platform";
import {UIWhiteboard} from "../Whiteboard";
import './index.css'

export class UIBlackboard extends Component {

  state = {}
  
  setWhiteboardColor = color => {
  }

  colors = [
    "#f2f2f2",
    "#d80000",
    "#21d821",
    "#e223d3",
    "#f2eb00",
    "#1d74ff",
    ];
  
  colorClass = [
    "uiWhiteboardColorWhite",
    "uiWhiteboardColorRed",
    "uiWhiteboardColorGreen",
    "uiWhiteboardColorPurple",
    "uiWhiteboardColorYellow",
    "uiWhiteboardColorBlue",
  ];
  
  toggleColorPicker = (e) => {
    if (e) e.preventDefault()
    this.setState({
      colorPickerVisible: !this.state.colorPickerVisible
    });
  }         
  
  setWhiteboardColor = color => {
    this.wb.setColor(color);
    this.toggleColorPicker();
  }
  
    renderColorPicker() {
      if (!this.wb) return null;
      const colors = this.colors;
        const selectedColorClass = this.colorClass[colors.indexOf(this.wb.getColor())];
      
      return <div className='uiBlackboardColorPickerContainer'>
        <div className={'uiBlackboardColorPickerColor uiBlackboardColorPickerColorButton ' + selectedColorClass} onClick={this.toggleColorPicker}>
        <ReactSVG src={Color}/>
        </div>
        {this.state.colorPickerVisible &&
         <ClickAwayListener onClickAway={this.toggleColorPicker}>
         <div key='colorPicker' className='uiBlackboardColorPicker'>
         {colors.map((color, i) => {
           const colorClass = this.colorClass[i];
           return <div className={'uiBlackboardColorPickerColor '+colorClass} onClick={()=>this.setWhiteboardColor(color)} style={{fill: color}}>
             <ReactSVG src={Color}/>
             </div>
         })}
         </div>
         </ClickAwayListener>}
      </div>
    }
  
  renderButton = (key, icon, action, tooltip, placement) => {
    if (this.wb == null) return null;
    const selected = this.wb.toolSelected == key;
    let className = 'uiBlackboardButton';
    if (selected) {
      className += " uiBlackboardButtonSelected";
    }
    return <Tooltip placement={placement} key={key} title={tooltip}><div key={key} className={className} onClick={action}>
      <ReactSVG src={icon}/>
      </div></Tooltip>
  }
  
  chooseSelect = () => {
    this.wb.selectTool();
    this.forceUpdate()
  }
  
  choosePen = () => {
    this.wb.penTool();
    this.forceUpdate()
  }
  
  chooseDelete = () => {
    //debugger;
    this.wb.clear();
  }
  
  chooseUpload = () => {
    //debugger;
    this.wb.clear();
  }
  
  chooseUndo = () => {
    this.wb.undo();
  }
  
  chooseRedo = () => {
    this.wb.redo();
  }
  
  choosePan = () => {
    this.wb.pan()
    this.forceUpdate()
  }
  
  chooseSave = () => {
    this.wb.canvas.setBackgroundColor("#4d4d4d", () => {
      return fetch(this.wb.canvas.toDataURL()).then(res => {
        console.log("got data url");
        return res.blob().then(blob => {
          console.log("got blob");
          //debugger;
          const file = new File([blob], "Shared Blackboard.png", {type: "image/png"});
          this.props.uploadFile(file);
        });
      })
    });
  }
  
  isIPad = () => this.whiteboardWidth < 650;
  
  renderBlackboardControlsTop() {
    let className = isMobile() ? 'uiRemoteVideoBlackboardControlsMobile': 'uiRemoteVideoBlackboardControlsDesktop';
    return <div className={className}>
    {this.renderButton('select', Select, this.chooseSelect, "Select", "bottom")}
    {this.renderButton('pen', Pen, this.choosePen, "Pen", "bottom",)}
    {this.renderColorPicker()}
    {this.renderButton('pan', Pan, this.choosePan, "Pan", "bottom")}
    </div>
  }
  
  onBlackboardFileInput = async e => {
    e.preventDefault()
    if (e.target.files) {
      const file = e.target.files[0]
      //await this.props.uploadFile(file)
      let el
      const isVideo = file.type.startsWith("video")
      debugger
      if (isVideo) {
        el = document.createElement('video')
        el.autoplay = true
        el.muted = true
        el.playsinline = true
      } else {
        el = document.createElement('img')
      }
      if (!isVideo) this.props.uploadFile(file, null, true).then(async ref => {
        const downloadURL = await ref.getDownloadURL()
        el.src = downloadURL
        console.log("got download url: ", downloadURL)
        this.wb.sendCanvas()
      })
      el.crossOrigin = 'anonymous'
      let url
      if (false && !isVideo) {
        url = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener("load", function () {
            resolve(reader.result);
          }, false);
          reader.readAsDataURL(file);
        })
      } else {
        url = URL.createObjectURL(file)
      }
      if (!el.src) {
        el.src = url
      }
      if (isVideo) {
        el.onloadedmetadata = e => {
          debugger
          this.wb.imageUploaded(el)
        }
      } else {
        el.onload = e => {
          el.onload = null
          this.wb.imageUploaded(el)
        }
      }
    }
  }
  
  renderBlackboardControlsRight() {
    const className = "uiRemoteVideoBlackboardControlsIPad";
    return <div className={className}>
      {
          <div className='uiBlackboardFileUpload'>
          <input ref={this.setFilesRef} className={'uiFileUpload'} id={'uiBlackboardFileUpload'} name={'uiFileBlackBoardFileUpload'} type='file' accept='image/*,video/mp4' onChange={this.onBlackboardFileInput}/>
          <label htmlFor={'uiBlackboardFileUpload'}>
          {this.renderButton('upload', Upload, this.chooseUpload, 'Add Document', 'left')}
        </label>
          </div>
      }
    {this.renderButton('undo', Undo, this.chooseUndo, "Undo", "left")}
    {this.renderButton('redo', Redo, this.chooseRedo, "Redo", "left")}
    {this.renderButton('delete', Clear, this.chooseDelete, "Delete", "left")}
    {this.renderButton('save', Save, this.chooseSave, "Share", "left")}
    </div>
  }
  
  renderBlackboardControls() {
    return [this.renderBlackboardControlsTop(), this.renderBlackboardControlsRight()];
  }
  
  onInitWb = wb => {
    this.wb = wb;
  }
  
  onWhiteboardResize = (width, height) => {
    this.whiteboardWidth = width;
    this.forceUpdate();
  }
  
  
  renderBlackboard() {
    return <div  key='blackboard' className='uiRemoteVideoBlackboard'>
      <div key='whiteboard' className='uiRemoteVideoWhiteboard'>
      <UIWhiteboard onResize={this.onWhiteboardResize}  onInit={this.onInitWb} sendCanvasData={this.props.sendCanvasData} observeCanvasData={this.props.observeCanvasData}
    height={720} width={1280}/>
      </div>
      <div key='overlay' className='uiRemoteVideoBlackboardOverlay'>
    {this.renderBlackboardControls()}
    </div>
      </div>
  }

  render() {
    return this.renderBlackboard()
  }
}
