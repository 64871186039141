import React, { Component } from 'react';
import {isDesktop} from "./Platform"
import {OKCancel} from "./components/OKCancel";
import "./StripeButton.css"

export class StripeButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        debugger;
    }
    componentDidMount() {
        if (this.props.observeStripeAuth) {
            this.subscription = this.props.observeStripeAuth().subscribe(auth => {
                this.setState({
                    stripeAuth: auth
                })
            });
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    render() {
        let label = this.props.label;
        let className = "stripe-connect";
        if (!label) {
            label = this.state.stripeAuth || this.props.forceConnected ? "Connected with Stripe" : "Connect with Stripe";
        }
        if (this.state.stripeAuth) {
            className += ' stripe-connected'; 
        }
        return <a href="#" onClick={this.props.action} className={className}><span>{label}</span></a>;
    }
}
