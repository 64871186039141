import {Contact} from "./Contact";
import {DM} from "./DM";
import {LinkPreviewer} from "./LinkPreviewer";
import {docData, doc, collectionChanges} from "rxfire/firestore";
import {of, concat, from, Subject, merge as mergeN } from "rxjs";
import { catchError, filter, map, flatMap, take, merge } from 'rxjs/operators';
import moment from 'moment';
import filenamify from 'filenamify'
import FileSaver from 'file-saver';
import phone from 'phone';
import "moment-business-days"
import { Whoop } from './classes/Whoop.js'
import { v4 as uuidv4 } from 'uuid'
import LetsBuildLogo from './assets/icons/LB_Logo512.svg'

const debugLog = (...args) => {
  console.log.apply(null, args)
}

const SystemContact = {
  uid: 'system',
  displayName: "Let's Build",
  profileImage: LetsBuildLogo
}

const brandMap = {}
export const resolveBrandLogo = name => {
  name = name.toLowerCase()
  const brand = brandMap[name]
  let domain
  if (brand) {
    if (brand.logo) return brand.logo
    domain = brand.domain
  }
  if (!domain) {
    domain = name.replace(/[,']/, "").split(" ").join('') + '.com'
  }
  return `https://logo.clearbit.com/${domain}`
}

export const getFoodName = (food, short) => {
  if (short) return food.food_name
  const makeUpper = x => x.charAt(0).toUpperCase() + x.slice(1)
  let name = makeUpper(food.food_name)
  let serving = food.serving_unit ? food.serving_unit.toLowerCase() : ''
  switch (serving) {
    case 'fluid ounce':
      {
        serving = 'oz.'
      }
      break
  }
  if (serving.length > 20) {
    serving = serving.split(' ')[0]
  }
  let qty = food.serving_qty
  const comps = ('' + qty).split('.')
  if (comps.length > 1) {
    qty = Number(qty).toFixed(1)
  }
  if (serving.length > 20) {
    console.warn("dropping serving size", qty + " " +serving)
    qty = ''
    serving = ''
  } else {
    qty = `${qty} ${serving}`
  }
  if (food.brand_name && food.brand_name != name) {
    name = food.brand_name + ' ' + name
  }
  if (qty) {
    name +=  ' ' +qty
  }
  return name
}


const whoop = new Whoop()

const TeTePrivacyNotice = "/static/PrivacyNotice.html";
const TeTeTOS = "/static/TermsOfService.html";
const TeTeBAA = "/static/TeTeBAA.html";

//import { EThree } from '@virgilsecurity/e3kit-browser';
const EThree = null
//      window.E3kit ? window.E3kit.EThree : null;

let firstTime = true;

const getEThree = () => {
  if (true) return null
  if (!EThree) {
    if (!firstTime) {
      return window.location.reload();
    }
    firstTime = false;
    throw {
      code: "network-error",
      message: "A network error occurred"
    };
  }
  return EThree;
}

const webAssemblySupported = (() => {
  try {
    if (typeof WebAssembly === "object"
        && typeof WebAssembly.instantiate === "function") {
      const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
      if (module instanceof WebAssembly.Module)
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
    }
  } catch (e) {
  }
  return false;
})();

let encryptionEnabled = EThree
let fileEncryptionEnabled = false

const toBase64 = function (u8) {
  return btoa(String.fromCharCode.apply(null, u8));
}

const fromBase64 = function (str) {
  return atob(str).split('').map(function (c) { return c.charCodeAt(0); });
}

const decode = toBase64;

class MealIndex {
  constructor(uid, observeMeals) {
    this.sub = observeMeals.subscribe(change => {
      const meal = change.meal
      if (change.type == 'removed') {
        delete this.meals[meal.id]
      } else {
        this.meals[meal.id]  = meal
      }
    })
    this.uid = uid
  }
  release = () => {
    this.sub.unsubscribe()
  }
  meals = {}
  search = (q, filter) => {
    q = q.trim().toLowerCase()
    const searchTerms = q ? q.split(/\s+/) : []
    const matches = {}
    const { type } = filter
    if (type) searchTerms.push(type)
    debugLog("searchTerms", searchTerms)
    const seen = {}
    const meals = Object.values(this.meals)
    meals.sort((x, y) => y.start - x.start)
    meals.forEach(meal => {
      for (const food of meal.foods) {
        const id = food.nix_item_id || food.tag_id
        if (seen[id]) {
          continue
        }
        seen[id] = true
        let matched = 0
        if (q) {
          const name = getFoodName(food).toLowerCase().replace("'s", 's')
          const terms = name.split(/\s+/)
          terms.forEach(term => {
            searchTerms.forEach(searchTerm => {
              if (term === searchTerm) {
                matched += 4
              }
              else if (term.startsWith(searchTerm)) {
                matched++
              }
              else if (searchTerm.startsWith(term)) {
                matched += 0.25
              }
            })
          })
          //debugLog('terms', terms.join(' '), "=>", matched)
        } else {
          matched = 1
        }
        if (matched > 0) {
          let match = matches[id]
          if (!match) {
            match = {
              matches: matched,
              food,
              start: meal.start
            }
            matches[id] = match
          } else {
            match.start = Math.max(meal.start, match.start)
            match.matches += matched
          }
        }
      }
    })
    //debugLog("MATCHES", matches)
    const results = Object.values(matches).map(match => match.food)
    results.sort((x, y) => {
      const match1 = matches[x.nix_item_id || x.tag_id]
      const match2 = matches[y.nix_item_id || y.tag_id]
      const cmp = -(match1.matches - match2.matches)
      if (cmp) return cmp
      return -(match1.start - match2.start)
    })
    //debugLog("RESULTS", results)
    return results
  }
}


export class Me {

  isNative = () => {
    return typeof window !== 'undefined' && window.ReactNativeWebView
  }

  sendNativeMessage = msg => {
    if (this.isNative()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }
  }

  nativeInit () {
    if (!this.nativeInitDone) {
      this.nativeInitDone = true
      if (typeof window !== 'undefined' && window.ReactNativeWebView) {
        this.nativeLog("native init done: "+window.postMessage);
        this.sendNativeMessage({
          type: 'config',
          config: this.config
        })
      }
    }
  }


  mobileCallActiveSubject = new Subject()
  mobileCallActive = false

  setMobileCallActive = value => {
    value = !!value
    if (this.mobileCallActive != value) {
      this.mobileCallActive = value
      this.mobileCallActiveSubject.next(value)
    }
  }

  observeMobileCallActive() {
    return concat(of(this.mobileCallActive), this.mobileCallActiveSubject)
  }

  req = {};
  reqId = 0;
  nativeCall = msg => {
    const reqId = ++this.reqId
    return new Promise((resolve, reject) => {
      msg.reqId = reqId
      this.req[reqId] = resolve
      this.sendNativeMessage(msg)
    })
  }

  nativeLog = msg => {
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'log',
        message: msg
      })
    } else {
      debugLog(msg)
    }
  }

  saveToken = async token => {
    if (this.isTodoList()) {
      return
    }
    try {
      const firebase = this.firebase
      const func = firebase.functions().httpsCallable('saveToken')
      const result = await func({
        token: token,
        utcOffset: this.utcOffset,
      })
      debugLog(result)
    } catch (err) {
      console.error(err)
    }
  }

  credsSubject = new Subject()

  observeCreds = () => {
    return this.creds ? concat(from([this.creds]), this.credsSubject) : this.credsSubject
  }

  notificationSubject = new Subject()

  observeNotifications = () => {
    if (!this.isNative()) {
      let test = false
      if (!test) return this.notificationSubject
      const db = this.firebase.firestore()
      const q = db.collection('Notifications').where('to', '==', this.self.uid).orderBy('ts', 'desc').limit(1)
      return collectionChanges(q).pipe(flatMap(changes => {
        return changes.map(change => {
          debugger
          if (change.type == 'removed') {
            return null
          }
          return change.doc.data().msg
        })
      }), filter(x => x))
    }
    if (!this.observingNotifications) {
      this.observingNotifications = true
      this.sendNativeMessage({
        type: 'observingNotifications',
        observingNotifications: true
      })
    }
    return this.notificationSubject
  }

  readQRCode = (title) => {
    return new Promise(resolve => {
      this.qrCodeResult = code => {
        this.qrCodeResult = null
        this.sendNativeMessage({
          type: 'qrCodeInput',
          enable: false
        })
        resolve(code)
      }      
      return this.nativeCall({
        type: 'qrCode',
        title: title || 'Scan Code'
      })
    })
  }

  hideQRCodeInput = () => {
    this.qrCodeInputSubject.complete()
    this.qrCodeInputSubject = null
    this.sendNativeMessage({
      type: 'qrCodeInput',
      enable: false
    })
  }

  observeQRCodeInput = (title) => {
    this.qrCodeInputSubject = new Subject()
    this.sendNativeMessage({
      type: 'qrCodeInput',
      title: title,
      enable: true
    })
    return this.qrCodeInputSubject
  }

  qrCodeReadError = error => {
    this.sendNativeMessage({
      type: 'qrCodeReadError',
      error: error
    })
  }

  urlSubject = new Subject()

  observeURL = () => {
    if (this.url) {
      return concat(of(this.url), this.initialUrlSubject)
    }
    return this.urlSubject()
  }

  onNativeMessage = json => {
    //this.nativeLog('onNativeMessage: ' + json)
    //if (json.source) return
    let msg
    try {
      msg = JSON.parse(json)
    } catch (err) {
      this.nativeLog('JSON.parse failed: ' + err.message)
      return
    }
    if (msg.type === 'token') {
      this.saveToken(msg.token)
    } else if (msg.type === 'notification') {
      //this.nativeLog("received not: " + msg.notification.data.type)
      this.notificationSubject.next(msg.notification)
    } else if (msg.type === 'safeArea') {
      window.safeAreaInsets = msg.safeArea
      //this.nativeLog("window.safeAreaInsets=>"+window.safeAreaInsets);
    } else if (msg.type === 'url') {
      //alert("initial url: " + msg.url)
      this.url = msg.url
      this.urlSubject.next(this.url)
    } else if (msg.type === 'creds') {
      this.creds = msg
      this.credsSubject.next(this.creds)
    } else if (msg.type === 'qrCode') {
      if (this.qrCodeInputSubject) {
        this.qrCodeInputSubject.next({
          type: msg.op,
          code: msg.qrCode
        })
      }
      if (this.qrCodeResult) {
        this.qrCodeResult(msg.qrCode)
      }
    } else if (msg.reqId) {
      const resolve = this.req[msg.reqId]
      if (resolve) {
        delete this.req[msg.reqId]
        resolve(msg)
      }
    }
  }

  setStatusBarColor = color => {
    debugLog('set status bar color:', color)
    this.sendNativeMessage({
      type: 'statusBarColor',
      color: color 
    })
  }

  constructor(firebase, teteFunctionEndpoint, config) {
    this.upcoming = {};
    this.products = [];
    this.selfSubject = new Subject();
    this.accountSubject = new Subject();
    this.productsSubject = new Subject();
    this.firebase = firebase;
    this.stripeAuthSubject = new Subject();
    const auth = this.firebase.auth();
    auth.onAuthStateChanged(this.onAuthStateChanged);
    this.linkPreviewer = new LinkPreviewer(firebase);
    this.contacts = {};
    this.contactsSubject = new Subject();
    this.eThreeSubject = new Subject();
    this.online = {};
    this.onlineSubject = new Subject();
    this.teteFunctionEndpoint = teteFunctionEndpoint;
    this.isDev = config == 'dev';
    this.onAuthStateChanged(auth.currentUser);
    this.config = config
    window.postMessage = this.onNativeMessage
    this.nativeInit()
  }

  getContactsAndObserveGroups = () => {
    return this.getContacts().then(() => {
      this.observeGroups().subscribe(change => {
        if (change.type === 'removed') {
          delete this.contacts[change.uid]
        } else if (change.deleted) {
          if (this.contacts[change.uid]) {
            delete this.contacts[change.uid]
            return this.contactsSubject.next({
              type: 'removed',
              contact: change.contact
            })
          } else {
            return
          }
        } else {
          this.contacts[change.uid] = change.contact
        }
        this.contactsSubject.next({type: change.type, contact: change.contact})
      })
    })
  }

  groupsRef = () => this.firebase.firestore().collection("Groups").where("members", "array-contains", this.self.uid);
  groupRef = (id) => this.firebase.firestore().collection("Groups").doc(id);


  convertGroup = (id, data) => {
    const members = data.members.map(uid => this.getContact(uid))
    const displayName = members.filter(x => x.uid !== this.self.uid).map(x => x.displayName).join(', ')
    const info = {
      uid: id,
      displayName: data.name || displayName,
      group: {
        organizer: data.organizer,
        members: data.members,
        name: data.name,
        displayName: data.name || displayName,
        uid: id,
        isGroup: true,
      },
      isGroup: true,
    }
    return new Contact(info);
  }

  observeGroup = (id) => {
    return docData(this.groupRef(id)).pipe(map(data => {
      const contact = this.convertGroup(id, data)
      return contact
    }));
  }

  observeGroups = () => {
    //debugger
    return collectionChanges(this.groupsRef()).pipe(flatMap(changes => {
      //odebugger
      return from(changes.map(change => {
        const getContact = uid => {
          const result = this.getContact(uid);
          if (!result) {
            const userData = {
              displayName: "n/a",
              uid: uid,
            }
            return new Contact(userData);
          }
          return result;
        }
        const data = change.doc.data();
        const result = {
          type: change.type,
          groupId: change.doc.id,
          organizer: getContact(data.organizer),
          members: data.members.map(uid => getContact(uid)),
          uid: change.doc.id,
          deleted: data.deleted
        }
        const json = {
          isGroup: true,
          name: data.name,
          displayName: data.name || result.members.filter(x => x.uid !== this.self.uid).map(x => x.displayName).join(', '),
          uid: change.doc.id,
          organizer: data.organizer,
          members: data.members,
        }
        result.contact = {
          isGroup: true,
          uid: result.groupId,
          displayName: json.displayName,
          licenses: "",
          degrees: "",
          creds: "",
          email: "",
          phoneNumber: "",
          profileImage: null,
          group: json,
          toJSON: () => result.contact,
        }
        debugLog("GROUP: ", result);
        //////debugger;
        return result;
      }));
    }));
  }

  createGroup = (name, contacts) => {
    const group = {
      name: name,
      members: [this.self.uid].concat(contacts.map(c => c.uid).filter(uid => uid !== this.self.uid)),
      organizer: this.self.uid,
    }
    return this.firebase.firestore().collection("Groups").add(group);
  }

  deleteGroup = async id => {
    const snap = await this.firebase.firestore().collection("Groups").doc(id).get()
    if (snap.exists) {
      snap.set({ deleted: true }, { merge: true })
    }
  }

  updateGroup = (id, name, contacts) => {
    const group = {
      name: name,
      members: [this.self.uid].concat(contacts.map(c => c.uid).filter(uid => uid !== this.self.uid)),
      organizer: this.self.uid,
    }
    return this.firebase.firestore().collection("Groups").doc(id).set(group, {merge: true});
  }

  leaveGroup = async id => {
    const f = this.firebase.functions().httpsCallable("leaveGroup")
    const result = await f({groupId: id})
    console.log("leave group: ", result)
  }

  deleteGroup = async id => {
    const f = this.firebase.functions().httpsCallable("deleteGroup")
    const result = await f({groupId: id})
    console.log("leave group: ", result)
  }

  getNextResponseTime = sub => moment(new Date(sub.latestQuestion)).businessAdd(sub.responseTime).toDate().getTime();

  setEncryptionEnabled = value =>  encryptionEnabled = fileEncryptionEnabled = value;

  isEncryptionEnabled = () => encryptionEnabled

  observeAccountImpl = () => docData(this.accountRef());

  observeAccount = () => this.accountData ? concat(from([this.accountData]), this.accountSubject) : this.accountSubject;

  markOnline = () => {
    const updates = {
      lastOnline: Date.now()
    }
    return this.firebase.firestore().collection("Online").doc(this.self.uid).set(updates, {merge: true});
  }

  getMyWhoopCycleData = async (start, end) => {
    return await whoop.getCycleData(this.self.uid, this.whoopAuth, start, end)
  }

  getWhoopWeekly = async (uid, when) => {
    const f = this.firebase.functions().httpsCallable("getWhoopWeekly")
    //debugger
    const result = await f({uid, when})
    //debugger
    if (result.error) {
      console.error(result.error)
      return {}
    }
    const { url } = result.data
    //const cors =  { url: `http://localhost:5002/letsbuildclouddev/us-central1/getFile?url=${encodeURIComponent(url)}` }
    if (!url) return {}
    const cors =  { url: `https://us-central1-letsbuildclouddev.cloudfunctions.net/getFile?url=${encodeURIComponent(url)}` }
    console.log(cors)
    return cors
    //return result.data
  }

  getWhoopMonthly = async (uid, when) => {
    const f = this.firebase.functions().httpsCallable("getWhoopMonthly")
    const result = await f({uid, when})
    if (result.error) {
      console.error(result.error)
      return {}
    }
    const { url } = result.data
    if (!url) return {}
    return { url: `https://us-central1-letsbuildclouddev.cloudfunctions.net/getFile?url=${encodeURIComponent(url)}` }
    //return result.data
  }

  getWhoopSleep = async (uid, cycleId, sleepId) => {
    const f = this.firebase.functions().httpsCallable("getWhoopSleep")
    const arg = {
      uid, id: sleepId, cycleId
    }
    console.log('getWhoopSleep', arg)
    const result = await f(arg)
    const { data } = result
    ////debugger
    if (data.error) {
      throw new Error(data.error)
    }
    return data
  }

  isTodoList = () => {
    const searchParams =  new URLSearchParams(window.location.search)
    const app = searchParams.get("app")
    if (app) {
      return app === 'todo'
    }
    const u = new URL(window.origin);
    if (u.hostname.endsWith('plantorelax.net') || u.hostname.startsWith('plan-to-relax')) {
      return true
    }
    return false
  }

  isLB = () => {
    const u = new URL(window.origin);
    if (u.hostname == 'letsbuild.fitness') {
      return true
    }
    const searchParams =  new URLSearchParams(window.location.search)
    const app = searchParams.get("app")
    return !app || app === 'lb'
  }

  getWhoopWorkout = async (uid, workoutId) => {
    const f = this.firebase.functions().httpsCallable("getWhoopWorkout")
    const result = await f({
      uid, id: workoutId
    })
    const { data } = result
    if (data.error) {
      throw new Error(data.error)
    }
    return data
  }

  observeWhoopCycleData = (uid, start, end) => {
    debugLog("observeWhoopCycleData:", start, end)
    let q = this.firebase.firestore().collection('WhoopCycleData').where('uid', '==', uid)
    q = q.where('start', '>=', start).where('start', '<', end)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const cycle = change.doc.data().cycle
        if (cycle.sleep) {
          cycle.sleep.sleeps.forEach(sleep => {
            if (!sleep.during) {
              const { bounds, lower, upper } = sleep
              sleep.during = {
                bounds, lower, upper
              }
            }
            if (!sleep.id) {
              sleep.id = cycle.id
            }
            if (!sleep.sleepEfficiency && sleep.sleepEfficienc) {
              sleep.sleepEfficiency = sleep.sleepEfficienc
            }
          })
          if (!cycle.sleep.naps) {
            const sleeps = cycle.sleep.sleeps
            cycle.sleep.naps = sleeps.filter(sleep => sleep.isNap)
            cycle.sleep.sleeps = sleeps.filter(sleep => !sleep.isNap)
          }
          if (!cycle.strain) {
            cycle.strain = {}
          }
          if (cycle.strain && !cycle.strain.workouts) {
            cycle.strain.workouts = []
          }
          cycle.strain.workouts.map(workout => {
            if (workout.sportId === undefined) {
              workout.sportId = -1
            }
          })
        }
        return {
          type: change.type,
          cycle,
        }
      })
    }))
  }
  
  getWhoopCycleData = async (uid, start, end) => {
    const f = this.firebase.functions().httpsCallable("getWhoopCycleData")
    const result = await f({
      start, end, uid
    })
    const { data } = result
    if (data.error) {
      throw new Error(data.error)
    }
    const { cycles } = data;
    let prevSleep
    let prevWorkout
    let prevCycle
    cycles.forEach(cycle => {
      if (prevCycle) {
        cycle.prevId = prevCycle.id
      }
      prevCycle = cycle
      if (cycle.sleep) {
        cycle.sleep.sleeps.forEach(sleep => {
          if (prevSleep) {
            sleep.prevId = prevSleep.id
          }
          prevSleep = sleep
          sleep.recovery = cycle.recovery
          sleep.needBreakdown = cycle.sleep.needBreakdown
        })
      }
      if (cycle.strain) {
        cycle.strain.workouts.forEach(workout => {
          if (prevWorkout) {
            workout.prevId = prevWorkout.id
          }
          prevWorkout = workout
        })
      }
    })
    return cycles
  }

  getGarminHeartRateData = async (uid, start, end) => {
    const f = this.firebase.functions().httpsCallable("getGarminHeartRateData")
    const arg = {
      start, end, uid
    }
    console.log(arg)
    //debugger
    const result = await f(arg)
    const { data } = result
    console.log("hr", data)
    if (data.error) {
      throw new Error(data.error)
    }
    return data
  }

  getWhoopHeartRateData = async (uid, start, end, step) => {
    const f = this.firebase.functions().httpsCallable("getWhoopHeartRateData")
    const result = await f({
      start, end, uid, step
    })
    const { data } = result
    if (data.error) {
      throw new Error(data.error)
    }
    return data.heartRate
  }

  getWhoop = () => whoop

  whoopLinkSubject = new Subject()

  observeWhoopLinked = () => {
    if (!this.whoopLinkSub) {
      this.whoopLinkSub = this.observeWhoopLinkedImpl().subscribe(linked => {
        linked = linked && linked.access_token
        this.whoopAuth = linked
        this.whoopLinkSubject.next(linked)
      })
    }
    if (this.whoopAuth) {
      return concat(of(this.whoopAuth), this.whoopLinkSubject)
    }
    return this.whoopLinkSubject
  }

  observeWhoopLinkedImpl = () => doc(this.firebase.firestore().collection("WhoopAuth").doc(this.self.uid)).pipe(map(doc => doc.exists ? doc.data() : null))

  ouraLinkSubject = new Subject()

  observeOuraLinked = () => {
    if (!this.ouraLinkSub) {
      this.ouraLinkSub = this.observeOuraLinkedImpl().subscribe(linked => {
        if (linked && (linked.access_token || linked.needsRelink)) {
          this.ouraAuth = linked
          this.ouraLinkSubject.next(linked)
        } else {
          if (this.ouraAuth) {
            this.ouraAuth = null
            this.ouraLinkSubject.next(null)
          }
        }
      })
    }
    if (this.ouraAuth) {
      return concat(of(this.ouraAuth), this.ouraLinkSubject)
    }
    return this.ouraLinkSubject
  }

  observeOuraLinkedImpl = () => doc(this.firebase.firestore().collection("OuraAuth").doc(this.self.uid)).pipe(map(doc => doc.exists ? doc.data() : null))


  withingsLinkSubject = new Subject()

  observeWithingsLinked = () => {
    if (!this.withingsLinkSub) {
      this.withingsLinkSub = this.observeWithingsLinkedImpl().subscribe(linked => {
        linked = linked && linked.access_token
        this.withingsAuth = linked
        this.withingsLinkSubject.next(linked)
      })
    }
    if (this.withingsAuth) {
      return concat(of(this.withingsAuth), this.withingsLinkSubject)
    }
    return this.withingsLinkSubject
  }

  observeWithingsLinkedImpl = () => doc(this.firebase.firestore().collection("WithingsAuth").doc(this.self.uid)).pipe(map(doc => doc.exists ? doc.data() : null))
  

  fitbitLinkSubject = new Subject()

  observeFitbitLinked = () => {
    if (!this.fitbitLinkSub) {
      this.fitbitLinkSub = this.observeFitbitLinkedImpl().subscribe(linked => {
        linked = linked && linked.access_token
        this.fitbitAuth = linked
        this.fitbitLinkSubject.next(linked)
      })
    }
    if (this.fitbitAuth) {
      return concat(of(this.fitbitAuth), this.fitbitLinkSubject)
    }
    return this.fitbitLinkSubject
  }

  maxHeartRate = {}

  getMaxHeartRate = uid => {
    return Math.max(this.maxHeartRate[uid] || 0, 160)
  }

  updateMaxHeartRate = async uid => {
    if (this.maxHeartRate[uid]) return
    const ref = this.firebase.firestore().collection('HR').doc(uid)
    const snap = await ref.get()
    const result = snap.exists ? snap.data().max : 0
    console.log("max heart rate", uid, result)
    this.maxHeartRate[uid] = result
    return result
  }

  observeFitbitLinkedImpl = () => doc(this.firebase.firestore().collection("FitbitAuth").doc(this.self.uid)).pipe(map(doc => doc.exists ? doc.data() : null))

  garminLinkSubject = new Subject()

  observeGarminLinked = () => {
    if (!this.garminLinkSub) {
      this.garminLinkSub = this.observeGarminLinkedImpl().subscribe(linked => {
        linked = linked && linked.user_token
        this.garminAuth = linked
        this.garminLinkSubject.next(linked)
      })
    }
    if (this.garminAuth) {
      return concat(of(this.garminAuth), this.garminLinkSubject)
    }
    return this.garminLinkSubject
  }

  observeGarminLinkedImpl = () => doc(this.firebase.firestore().collection("GarminAuth").doc(this.self.uid)).pipe(map(doc => doc.exists ? doc.data() : null))
  
  
  observeOuraCycleData = (uid, start, end) => {
    debugLog("observeOuraCycleData:", start, end)
    let q = this.firebase.firestore().collection('OuraCycleData').where('uid', '==', uid)
    q = q.where('start', '>=', start).where('start', '<', end)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const data = change.doc.data()
        const cycle = data.cycle
        cycle.id = change.doc.id
        cycle.start = data.start
        cycle.end = data.end
        return {
          type: change.type,
          cycle: cycle
        }
      })
    }))
  }


  observeFitbitCycleData = (uid, start, end) => {
    console.log("observeFitbitCycleData:", start, end)
    let q = this.firebase.firestore().collection('FitbitCycleData').where('uid', '==', uid)
    q = q.where('start', '>=', start).where('start', '<', end)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const data = change.doc.data()
        const cycle = data.cycle
        cycle.id = change.doc.id
        cycle.start = data.start
        cycle.end = data.end
        return {
          type: change.type,
          cycle: cycle
        }
      })
    }))
  }

  observeGarminCycleData = (uid, start, end) => {
    console.log("observeGarminCycleData:", uid, start, end)
    let q = this.firebase.firestore().collection('GarminCycleData').where('uid', '==', uid)
    q = q.where('start', '>=', start).where('start', '<', end).orderBy('start')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const data = change.doc.data()
        console.log("observe garmin cycle", data.type)
        const cycle = data
        cycle.id = change.doc.id
        cycle.start = data.start
        cycle.end = data.end
        return {
          type: change.type,
          cycle: cycle
        }
      })
    }))
  }
  

  observeWhoopSports = () => {
    return collectionChanges(this.firebase.firestore().collection("WhoopSports")).pipe(flatMap(changes => {
      return changes.map(change => {
        return {
          type: change.type,
          sport: change.doc.data()
        }
      })
    }))
  }

  observeContactOnline = contact => {
    const uid = contact.uid;
    const now = this.online[uid];
    let ob = this.onlineSubject.pipe(filter(x => x.uid == uid));
    if (now){
      return concat(of(now), ob);
    }
    return ob;
  }

  observeOnline = () => {
    return collectionChanges(this.firebase.firestore().collection("Online"));
  }

  observeStripeAccount = () => {
    return this.observeAccount().pipe(filter(x => x && x.account), map(x => x.account));
  }

  observeSelf = () => {
    const existing = this.self ? [this.self] : [];
    return concat(existing, this.selfSubject);
  }

  getAccount = () => {
    return this.observeAccount().pipe(take(1)).toPromise();
  }

  getPaymentMethod = () => {
    return this.getAccount().then(accountData => accountData.paymentMethod);
  }

  savePaymentMethod = paymentMethod => {
    return this.getToken().then(token => {
      const savePaymentMethod = this.firebase.functions().httpsCallable("savePaymentMethod?idToken="+token+"&paymentMethodId="+paymentMethod);
      return savePaymentMethod().then(response => {
        debugLog("savePaymentMethod: ", response);
      });
    });
  }

  getPaymentIntent = (appointmentId) => {
    return this.getToken().then(token => {
      const getPaymentIntent = this.firebase.functions().httpsCallable("getPaymentIntent?idToken="+token+"&appointmentId="+appointmentId);
      return getPaymentIntent().then(response => {
        //debugLog("getPaymentIntent: ", response);
        return response.data;
      });
    });
  }

  refundAppointment = (appointmentId) => {
    return this.getToken().then(token => {
      const refundPayment = this.firebase.functions().httpsCallable("refundAppointment?idToken="+token+"&appointmentId="+appointmentId);
      return refundPayment().then(response => {
        //debugLog(response);
        return response.data;
      });
    });
  }

  getContactLink = () => {
    const p = this.getToken().then(token => {
      const getContactLink = this.firebase.functions().httpsCallable("getContactLink?idToken="+token);
      return getContactLink().then(response => {
        debugLog("getContactLink: ", response);
        return Promise.resolve(this.contactLink = response.data);
      });
    });
    const link = this.contactLink;
    if (link) return Promise.resolve(link);
    return p;
  }

  markContactOpened = contact => {
    if (contact.isGroup) {
      return Promise.resolve();
    }
    ////////////debugger;
    const user = this.contacts[contact.uid];
    if (!user) {
      debugLog("bad contact: ", contact);
      //////debugger;
    }
    if (!user.open) {
      user.open = true;
      return this.updateContact(contact, {
        open: true
      });
    }
    return Promise.resolve();
  }

  updateContact = (contact, updates) => {
    return this.getToken().then(token => {
      const updateContact =
            this.firebase.functions().httpsCallable("updateContact?idToken="+token+"&contact="+contact.uid);
      return updateContact(updates).then(response => {
        ////////////debugger;
        //debugLog(response);
        return response.data;
      });
    });
  }

  applyContactLink = (type, link) => {
    const p = this.getToken().then(token => {
      let url = "applyContactLink?idToken="+token+"&link="+link+"&type="+type;
      const applyContactLink = this.firebase.functions().httpsCallable(url);
      return applyContactLink().then(response => {
        //debugLog("applyContactLink: ", response);
        return Promise.resolve(response.data);
      });
    });
    return p;
  }

  removeContact = contact => {
    const contactUid = contact.uid;
    const p = this.getToken().then(token => {
      const removeContact = this.firebase.functions().httpsCallable("removeContact?idToken="+token+"&contact="+contactUid);
      return removeContact().then(response => {
        //debugLog("removeContact: ", response);
        return Promise.resolve(response.data);
      });
    });
    return p;
  }

  hasContacts = () => {
    ////////debugger;
    for (var i in this.contacts) {
      if (this.contacts[i].uid != this.self.uid) {
        return Promise.resolve(true);
      }
    }
    return this.myContactsRef().limit(2).get().then(snap => {
      ////////debugger;
      return snap.docs.length > 1;
    });
  }

  updateSubscription = (contact, updates) => {
    const providerUpdates = {
      client: contact.uid,
    }
    for (var field in updates) {
      providerUpdates[field] = updates[field];
    }
    return this.doSubscriptionUpdates(providerUpdates);
  }

  doSubscriptionUpdates = updates => {
    debugLog("updates: ", updates);
    return this.getToken().then(token => {
      let url = "updateSubscription?idToken="+token;
      for (var i in updates) {
        url += "&"+i+"="+encodeURIComponent(updates[i]);
      }
      ////////debugger;
      const updateSubscription = this.firebase.functions().httpsCallable(url);
      return updateSubscription().then(response => {
        debugLog(response);
        return response;
      });
    });
  }

  doClientSubscriptionUpdates = updates => {
    return this.getToken().then(token => {
      let url = "updateClientSubscription?";
      let sep = "";
      for (var i in updates) {
        url += sep+i+"="+encodeURIComponent(updates[i]);
        sep = "&";
      }
      debugLog("updateClientSubscription: ", url);
      ////////debugger;
      url += "&idToken="+token;
      const updateSubscription = this.firebase.functions().httpsCallable(url);
      return updateSubscription().then(response => {
        debugLog(response);
        return response;
      });
    });
  }

  acceptSubscription = contact => {
    const updates = {
      uid: contact.uid,
      state: "accept"
    };
    return this.doClientSubscriptionUpdates(updates);
  }

  declineSubscription = contact => {
    const updates = {
      uid: contact.uid,
      state: "decline"
    };
    return this.doClientSubscriptionUpdates(updates);
  }

  cancelSubscription = contact => {
    //////debugger;
    const updates = {
      client: contact.uid,
      state: "cancel",
    };
    return this.doSubscriptionUpdates(updates);
  }

  cancelClientSubscription = contact => {
    //////debugger;
    const updates = {
      uid: contact.uid,
      state: "cancel"
    };
    return this.doClientSubscriptionUpdates(updates);
  }

  offerSubscription = (contact, subscription) => {
    const updates = {
      client: contact.uid,
      state: "offer",
      startDate: subscription.startDate,
      description: subscription.description || "",
      invoiceAmount: subscription.invoiceAmount,
      invoiceDescription: subscription.invoiceDescription || "",
      responseTime: subscription.responseTime,
    };
    return this.doSubscriptionUpdates(updates);
  }

  observeSubscriptions = () => {
    return collectionChanges(this.providerSubscriptionsRef()).pipe(flatMap(changes => changes.map(change => {
      const sub = change.doc.data();
      sub.latestQuestion = sub.latestQuestion || 0;
      sub.latestResponse = sub.latestResponse || 0;
      const result = {
        type: change.type,
        subscription: sub,
      }
      sub.contact = this.getContact(sub.client);
      return result;
    })));
  }

  resolvePaymentMethod = id => {
    return this.accountData.paymentMethod && this.accountData.paymentMethod.id == id && this.accountData.paymentMethod.card
  }

  observeMySubscriptions = () => {
    return collectionChanges(this.mySubscriptionsRef()).pipe(flatMap(changes => changes.map(change => {
      const sub = change.doc.data();
      sub.latestQuestion = sub.latestQuestion || 0;
      sub.latestResponse = sub.latestResponse || 0;
      sub.paymentMethod = this.resolvePaymentMethod(sub.paymentMethodId)
      const result = {
        type: change.type,
        subscription: sub,
      }
      sub.contact = this.getContact(sub.uid);
      return result;
    })));
  }

  observeSubscription = contact => {
    return collectionChanges(this.subscriptionRef(contact)).pipe(flatMap(changes => changes.map(change => {
      const sub = change.doc.data()
      const result = {
        type: change.type,
        subscription: sub,
      }
      result.subscription.contact = this.getContact(result.subscription.client);
      return result;
    })));
  }

  observeMySubscription = contact => {
    return collectionChanges(this.mySubscriptionRef(contact)).pipe(flatMap(changes => changes.map(change => {
      const sub = change.doc.data()
      const result = {
        type: change.type,
        subscription: sub
      }
      sub.paymentMethod = this.resolvePaymentMethod(sub.paymentMethodId)
      result.subscription.contact = this.getContact(result.subscription.uid);
      return result;
    })));
  }
  
  hasAppointments = () => {
    return this.appointmentsRef().limit(1).get().then(snap => snap.docs.length > 0);
  }

  observeAppointment = (appointment) => {
    const id = appointment.id;
    const editable = appointment.editable;
    const ref = this.firebase.firestore().collection("Appointments").doc(id);
    return docData(ref).pipe(map(data => {
      if (data) {
        data.id = id
        data.contact = this.getContact(data.uid == this.self.uid ? data.client : data.uid);
        data.organizer = this.getContact(appointment.uid);
        data.editable = editable;
      }
      return data;
    }));
  }

  getAppointments = contact => {
    return this.appointmentsRef().where("client", "==", contact.uid).get().then(snap => {
      const results = snap.docs.map(doc => {
        const appt = doc.data();
        appt.contact = contact;
        return appt;
      });
      results.sort((a, b) => b.start - a.start);
      return results;
    });
  }

  observeUpcomingAppointments = contact => {
    const now = Date.now();
    return this.observeAppointments(now).pipe(filter(change => change.appointment.contact.uid == contact.uid))
  }

  observeAppointments = (after) => {
    const byMe = collectionChanges(this.appointmentsRef(after));
    const converted = byMe.pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data();
        const appt = {
          client: this.getContact(data.client),
          id: change.doc.id,
          organizer: this.self,
          contact: this.getContact(data.client),
          start: data.start,
          end: data.end,
          editable: true,
          invoiceDescription: data.invoiceDescription,
          invoiceAmount: data.invoiceAmount,
          status: data.status,
          finalPaymentMethod: data.finalPaymentMethod,
          paymentStatus: data.paymentStatus,
          paymentIntentId: data.paymentIntentId,
          title: data.title,
        }
        return {type: change.type, appointment: appt};
      }).filter(change => change.appointment.contact))
    }
                                       ));
    const toMe = collectionChanges(this.myAppointmentsRef(after)).pipe(flatMap(changes => changes.map(change => {
      const data = change.doc.data();
      const contact = this.getContact(data.uid);
      const appt = {
        id: change.doc.id,
        organizer: contact,
        contact: contact,
        client: this.self,
        start: data.start,
        end: data.end,
        editable: false,
        invoiceDescription: data.invoiceDescription,
        invoiceAmount: data.invoiceAmount,
        paymentIntentId: data.paymentIntentId,
        status: data.status,
        finalPaymentMethod: data.finalPaymentMethod,
        paymentStatus: data.paymentStatus,
        paymentIntentId: data.paymentIntentId,
        title: data.title
      }
      return {type: change.type, appointment: appt};
    })));
    return converted.pipe(merge(toMe))
  }

  doAppointmentUpdates = updates => {
    return this.getToken().then(token => {
      let url = "updateAppointment?idToken="+token;
      const autofill = this.getAppointmentAutofill();
      for (var i in updates) {
        url += "&"+i+"="+encodeURIComponent(updates[i]);
        autofill[i] = updates[i];
      }
      this.setAppointmentAutofill(autofill);
      ////////debugger;
      const updateAppointment = this.firebase.functions().httpsCallable(url);
      return updateAppointment().then(response => {
        //debugLog(response);
        if (response.data.error) {
          return Promise.reject(new Error(response.data.error))
        }
        return response.data;
      });
      
    });
  }

  getAppointmentAutofill = () => {
    const string = localStorage.getItem("appointmentAutofill."+this.self.uid);
    if (string) return JSON.parse(string);
    return {};
  }

  setAppointmentAutofill = (autofill) => {
    return localStorage.setItem("appointmentAutofill."+this.self.uid, JSON.stringify(autofill));
  }
  
  acceptAppointment = (appointmentId) => {
    return this.doAppointmentUpdates({
      appointmentId: appointmentId,
      status: "accepted",
    });
  }

  declineAppointment = (appointmentId) => {
    return this.doAppointmentUpdates({
      appointmentId: appointmentId,
      status: "declined",
    });
  }

  deleteAppointment = (appointmentId) => {
    return this.getToken().then(token => {
      let url = "deleteAppointment?idToken="+token;
      url += "&appointmentId="+appointmentId;
      const deleteAppointment = this.firebase.functions().httpsCallable(url);
      return deleteAppointment().then(response => {
        //debugLog(response);
        return response;
      });
    });
  }

  updateAppointment = (appointment) => {
    const client = appointment.client;
    const appointmentId = appointment.id;
    const title = appointment.title || "";
    const start = appointment.start;
    const end = appointment.end;
    const invoiceDescription = appointment.invoiceDescription || "";
    const invoiceAmount = appointment.invoiceAmount || "";
    return this.getToken().then(token => {
      let url = "updateAppointment?idToken="+token;
      if (client) {
        url += "&client="+client;
      }
      if (appointmentId) {
        url += "&appointmentId="+appointmentId;
      }
      url += "&start="+start;
      url += "&end="+end;
      let autofill = this.getAppointmentAutofill();
      if (invoiceAmount) {
        url += "&invoiceAmount="+invoiceAmount;
        autofill.invoiceAmount = invoiceAmount;
      }
      if (invoiceDescription) {
        url += "&invoiceDescription="+encodeURIComponent(invoiceDescription);
        autofill.invoiceDescription = invoiceDescription;
      }
      if (title) {
        url += "&title="+encodeURIComponent(title);
        autofill.title = title;
      }
      const updateAppointment = this.firebase.functions().httpsCallable(url);
      return updateAppointment().then(response => {
        //debugLog(response);
        if (response.data.error) {
          return Promise.reject(new Error(response.data.error))
        }
        this.setAppointmentAutofill(autofill);
        return response.data;
      });
    });
  }
  
  createAppointment = (contact, appointment) => {
    appointment.client = contact.uid;
    return this.updateAppointment(appointment);
  }

  acceptBAA = () => {
    return this.getToken().then(token => {
      const func = this.firebase.functions().httpsCallable("acceptBAA?idToken="+token);
      return func().then(result => {
        debugLog("accept baa: ", result);
        if (result.data.error) {
          return Promise.reject("oof, sorry");
        }
        return result;
      });
    });
  }

  downloadBAA = () => {
    const url = "/static/BAA.pdf";
    return fetch(url).then(response => response.blob()).then(blob => {
      if (blob.type != "application/pdf") {
        return blob.text().then(result => {
          debugLog(result);
          return Promise.reject("oof, sorry");
        });
      }
      const when = this.accountData.acceptedBAA;
      const date = moment(new Date(when)).format("Do MMM YYYY");
      FileSaver.saveAs(new File([blob], filenamify(this.self.displayName) + " TeTe BAA Effective "+date+".pdf", {type: "application/pdf"}));
    });
  }
  
  updateAccount = updates0 => {
    const updates = JSON.parse(JSON.stringify(updates0));
    return this.getToken().then(token => {
      const updateAccount = this.firebase.functions().httpsCallable("updateAccount?idToken="+token);
      let p = Promise.resolve();
      if (updates.password) {
        p = this.updatePassword(updates.password).then(() => null).catch(err => err);
      }
      return p.then(err => {
        if (err) {
          return {data: {error: err}};
        }
        delete updates['password'];
        //////debugger;
        for (var i in updates) {
          return updateAccount(updates).then(result => {
            debugLog("updateAccount: ", result);
            if (result.data.error) {
              return result;
            }
            let changed = false;
            for (var field in this.self) {
              if (updates[field]) {
                if (this.self[field] != updates[field]) {
                  this.self[field] = updates[field];
                  changed = true;
                }
              }
            }                        
            if (changed) {
              this.selfSubject.next(this.self);
            }
            return result;
          }).catch(err => {
            console.error(err);
            return {data: {error: {code: "internal-error"}}};
          });
        }
        return Promise.resolve({data: {}});
      });
    });
  }

  observeCurrentWeight = (contact, before) => {
    return mergeN(this.observeCurrentWithingsWeight(contact, before), this.observeCurrentFitbitWeight(contact, before), this.observeCurrentUserWeight(contact, before))
  }

  observeCurrentWeightImpl = (c, contact, before) => {
    const db = this.firebase.firestore()
    let q = db.collection(c).where('uid', '==', contact.uid)
    if (before) {
      q = q.where('created', '<', before)
    }
    q = q.orderBy('created', 'desc').limit(1)    
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        if (change.type != 'removed') {
          const cycle = change.doc.data()
          cycle.id = change.doc.id
          return cycle
        }
        return null
      })
    }), catchError(err => {
      console.error(err)
      return of(null)
    }), filter(x => x))
  }

  observeCurrentWithingsWeight = (contact, before) => {
    return this.observeCurrentWeightImpl('WithingsCycleData', contact, before)
  }

  observeCurrentUserWeight = (contact, before) => {
    return this.observeCurrentWeightImpl('Weights', contact, before)
  }

  observeCurrentFitbitWeight = (contact, before) => {
    return this.observeCurrentWeightImpl('FitbitCycleData', contact, before)
  }
  
  observeCurrentWhoopCycle = contact => {
    const db = this.firebase.firestore()
    const ref = db.collection('WhoopCycleData').where('uid', '==', contact.uid).orderBy('start', 'desc').limit(1)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        if (change.type != 'removed') {
          const data = change.doc.data()
          debugLog("current cycle:", data)
          /*
          if (data.cycle.days[0] != moment(new Date()).local().format('YYYY-MM-DD')) {
            return null
          }
          if (!data.cycle.recovery || !data.cycle.recovery.score) {
            return null
          }
          */
          return data
        }
        return null
      })
    }), catchError(err => {
      console.error(err)
      return of(null)
    }), filter(x => x))
  }

  observeCurrentOuraCycle = contact => {
    const db = this.firebase.firestore()
    const ref = db.collection('OuraCycleData').where('uid', '==', contact.uid).orderBy('start', 'desc').limit(1)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        if (change.type != 'removed') {
          const data = change.doc.data()
          return data
        }
        return null
      })
    }), catchError(err => {
      console.error(err)
      return of(null)
    }), filter(x => x))
  }
  
  observeCurrentFitbitCycle = contact => {
    const db = this.firebase.firestore()
    const ref = db.collection('FitbitCycleData').where('uid', '==', contact.uid).orderBy('start', 'desc').limit(1)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        if (change.type != 'removed') {
          const data = change.doc.data()
          return data
        }
        return null
      })
    }), catchError(err => {
      console.error(err)
      return of(null)
    }), filter(x => x))
  }

  dateSubject = new Subject()

  getCalendarDate = () => {
    return moment(new Date()).format('yyyy-MM-DD')
  }

  calendarDate = this.getCalendarDate()

  tick = () => {
    const when = this.getCalendarDate()
    console.log("tick", when)
    if (this.calendarDate !== when) {
      this.dateSubject.next(when)
    }
  }

  initTimer = () => {
    this.tick()
    return setTimeout(this.tick, 60 * 60 * 5 * 1000)
  }

  timer = this.initTimer()
                     
  observeCalendarDate = () => {
    return concat(of(this.calendarDate), this.dateSubject)
  }
  
  observeCurrentGarminCycle = contact => {
    return this.observeCalendarDate().pipe(flatMap(calendarDate => {
      const result = mergeN(/* this.observeCurrentGarminDailyCycle(contact, calendarDate), */
        this.observeCurrentGarminSleepCycle(contact, calendarDate),
        this.observeCurrentGarminStressCycle(contact, calendarDate))
      return result
    }))
  }

  observeCurrentGarminDailyCycle = (contact) => {
    const db = this.firebase.firestore()
    const ref = db.collection('GarminCycleData').where('uid', '==', contact.uid).where('type', '==', 'dailies').orderBy('start', 'desc').limit(1)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        if (change.type != 'removed') {
          const data = change.doc.data()
          return data
        }
        return null
      })
    }), catchError(err => {
      console.error(err)
      return of(null)
    }), map(x => {
      return { type: 'dailies', cycle: x }
    }))
  }
  
  observeCurrentGarminSleepCycle = (contact, calendarDate) => {
    const db = this.firebase.firestore()
    const ref = db.collection('GarminCycleData').where('uid', '==', contact.uid).where('type', '==', 'sleeps').orderBy('start', 'desc').limit(1)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        if (change.type != 'removed') {
          const data = change.doc.data()
          const when = moment(new Date(data.end)).local().format('yyyy-MM-DD')
          if (when !== calendarDate) {
            return null
          }
          return data
        }
        return null
      })
    }), catchError(err => {
      console.error(err)
      return of(null)
    }), filter(x => x && !x.validation.startsWith('AUTO')), map(x => {
      return { type: 'sleeps', cycle: x}
    }))
  }

  observeCurrentGarminStressCycle = (contact, calendarDate) => {
    const db = this.firebase.firestore()
    const ref = db.collection('GarminCycleData').where('uid', '==', contact.uid).where('type', '==', 'stressDetails').orderBy('start', 'desc').limit(1)
    return collectionChanges(ref).pipe(flatMap(changes => {
      return changes.map(change => {
        if (change.type != 'removed') {
          const data = change.doc.data()
          if (data.calendarDate !== calendarDate) {
            console.log('calendarDate mismatch', calendarDate, data.calendarDate)
            return null
          }
          return data
        }
        return null
      })
    }), catchError(err => {
      console.error(err)
      return of(null)
    }), map(x => {
      return {
        type: 'stressDetails',
        cycle: x
      }
    }))
  }
  
  convertVideoFile = file => {
    const name = file.name.toLowerCase();
    if (name.endsWith(".mov")) {
      try {
        file = new File(file, name.replace(".mov", ".mp4"));
      } catch (err) {
        this.nativeLog(err)
      }
    }
    return file
  }

  uploadFileImpl = async (type, channel, file, progress) => {
    const filename = uuidv4() + file.name
    const targetRef = this.firebase.storage().ref(type).child(channel).child(filename)
    if (!file.type.startsWith('video/')) {
      // just upload directly
      const uploadTask = targetRef.put(file)
      if (progress) {
        uploadTask.on('state_changed', snap => {
          const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          debugLog("progress:", percent)
          if (progress) {
            progress(percent);
          }
        })
      }
      await uploadTask
      return targetRef
    }
    const videoDimensions = await this.getVideoDimensions(file)
    // invoke support for transcoding video
    const func = this.firebase.functions().httpsCallable('createUpload')
    const result = await func({
      contentType: file.type,
      type: type,
      channel: channel,
      filename: filename,
      videoDimensions
    })
    const { uploadId, path } = result.data
    const ref = this.firebase.storage().ref(path)
    const uploadTask = ref.put(file)
    if (progress) {
      uploadTask.on('state_changed', snap => {
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
        debugLog("progress:", percent)
        if (progress) {
          progress(percent);
        }
      })
    }
    await uploadTask
    const p = new Promise((resolve, reject) => {
      const unsubscribe = this.firebase.firestore().collection('Uploads').doc(uploadId).onSnapshot(snap => {
        const data = snap.data()
        if (data) {
          switch (data.status) {
            case 'completed':
              unsubscribe()
              if (progress) progress(100)
              resolve()
              break
            case 'failed':
              unsubscribe()
              if (progress) progress(100)
              reject(new Error(data.failureReason))
              break
          }
        }
      })
    })
    await p
    return targetRef
  }

  getVideoDimensions = async file => {
    const url = URL.createObjectURL(file)
    const video = document.createElement('video')
    video.src = url
    const videoDimensions = await new Promise(resolve => {
      video.onloadedmetadata = e => {
        resolve({
          height: video.height,
          width: video.width
        })
      }
      video.load()
    })
    return await videoDimensions
  }

  uploadFileToChannel = async (channel, file, progress) => {
    return await this.uploadFileImpl('Channels', channel, file, progress)
  }
  
  uploadFile = async (file, progress) => {
    return await this.uploadFileImpl('Files', this.self.uid, file, progress)
  }

  uploadProfileImage = (file, progress) => {
    const ref = this.firebase.storage().ref("ProfileImages").child(this.self.uid);
    return new Promise((resolve, reject) => {
      const uploadTask = ref.put(file);
      if (progress) {
        progress(0);
      }
      uploadTask.on("state_changed", snap => {
        //debugLog("state_changed", snap);
        const percent = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
        if (progress) {
          progress(percent);
        }
      }, reject, () => {
        return resolve(ref.getDownloadURL());
      });
    });
  }

  utcOffset = -(new Date().getTimezoneOffset()*60*1000)


  sendTodoMessage = async msg => {
    const { ts, text, channel, from } = msg
    debugger
    const timestamp = this.firebase.firestore.Timestamp.fromMillis(ts)    
    const data = {
      utcOffset: this.utcOffset,
      ts: timestamp,
      text,
      channel,
      from,
      pronouns: this.self.pronouns
    }
    return await this.firebase.firestore().collection('TodoChat').doc(String(ts)).set(data)
  }

  sendTodo = async updates => { // updates should be { ts?, text }
    const sendTodo = this.firebase.functions().httpsCallable('sendTodo')
    const response = await sendTodo(updates)
    return response.data
  }

  deleteTodo = async id => {
    const deleteTodo = this.firebase.functions().httpsCallable('deleteTodo')
    const response = await deleteTodo({ id })
    return response.data
  }

  deleteTodoChat = async msg => {
    const deleteTodoChat = this.firebase.functions().httpsCallable('deleteTodoChat')
    const response = await deleteTodoChat({ ts: msg.ts })
    return response.data
  }

  // manually create or edit todo by user
  saveTodo = async updates => { // updates = { id, task, category, location, term, summary }
    const saveTodo = this.firebase.functions().httpsCallable('saveTodo')
    const response = await saveTodo(updates)
    return response.data
  }

  deleteProgress = async (id, end) => {
    const f = this.firebase.functions().httpsCallable('updateTodoProgress')
    await f({
      id, end
    })
  }

  updateProgress = async (id, prog) => {
    const f = this.firebase.functions().httpsCallable('updateTodoProgress')
    await f({
      id,
      end: prog.end,
      outcome: prog.outcome,
      notes:prog.notes
    })
  }

  // updates todo status
  updateTodo = async updates => {
    const updateTodo = this.firebase.functions().httpsCallable('updateTodo')
    const response = await updateTodo(updates)
    debugger
    return response.data
  }

  startTodoListSubscription = async () => {
    const f = this.firebase.functions().httpsCallable("startTodoListSubscription")
    return await f()
  }

  cancelTodoListSubscription = async () => {
    const f = this.firebase.functions().httpsCallable("cancelTodoListSubscription")
    return await f()
  }

  saveTodoListPaymentMethod = async (paymentMethodId) => {
    const f = this.firebase.functions().httpsCallable("saveTodoListPaymentMethod")
    return await f({paymentMethodId})
  }

  observeTodoListSubscription = () => {
    const db = this.firebase.firestore()
    return this.observeSelf().pipe(flatMap(self => {
      if (!self) {
        return of(null)
      }
      return doc(db.collection('TodoListSubscription').doc(self.uid)).pipe(map(snap => snap.data()))
    }))
  }

  observeTodo = (start, end) => {
    return mergeN(this.observeTodoPending(start, end), this.observeTodoComplete(start, end)).pipe(catchError(err => {
      debugger
      console.error(err)
      return from([])
    }), map(change => {
      const todo = change.todo.todo
      if (!todo.emotion) todo.emotion = 'none'
      if (!todo.category) todo.category = 'General'
      return change
    }))
  }

  observeTodoPending = (start, end) => {
    const db = this.firebase.firestore()
    const convertTs = ts => {
      const result = ts.seconds * 1000 + Math.round(ts.nanoseconds/1000000);
      //debugLog("convertTs: ", new Date(result));
      return result;
    }
    let q = db.collection('Todo').where('uid', '==', this.self.uid)
    q = q.where('status', '==', 'pending').where('start', '<', end)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const todo = change.doc.data()
        todo.id = change.doc.id
        return { type: change.type, todo: todo }
      })
    }))
  }

  observeTodoComplete = (start, end) => {
    const db = this.firebase.firestore()
    const convertTs = ts => {
      const result = ts.seconds * 1000 + Math.round(ts.nanoseconds/1000000);
      //debugLog("convertTs: ", new Date(result));
      return result;
    }
    let q = db.collection('Todo').where('uid', '==', this.self.uid)
    q = q.where('status', 'in', ['done', 'canceled'])
    q = q.where('start', '<', end)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const todo = change.doc.data()
        todo.id = change.doc.id
        return { type: change.type, todo: todo }
      })
    }), filter(x => !(x.todo.end < start)))
  }

  getSystemContact = () => SystemContact

  observeTodoChat = (limit) => {
    const db = this.firebase.firestore()
    const convertTs = ts => {
      const result = ts.seconds * 1000 + Math.round(ts.nanoseconds/1000000);
      //debugLog("convertTs: ", new Date(result));
      return result;
    }
    let q = db.collection('TodoChat').where('channel', '==', this.self.uid).orderBy('ts', 'desc')
    if (limit) {
      q = q.limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const message = change.doc.data()
        message.ts = convertTs(message.ts)
        message.id = change.doc.id
        if (!message.from) {
          message.text = message.data.todo.task
          message.from = SystemContact
        } else {
          message.to = SystemContact
        }
        if (message.data) {
          console.log("message.data", message.data)
          message.data.todo.todo.start = message.data.todo.start || message.ts
          message.data.todo.todo.emotion = message.data.todo.todo.emotion || 'none'
        }
        return { type: change.type, message: message }
      })
    }), catchError(err => {
      debugger
      return from([])
    }))
  }
  
  sendMessage = message => {
    return this.getToken().then(token => {
      const updateMessage = this.firebase.functions().httpsCallable("updateMessage?idToken="+token);
      return updateMessage(message).then(result => {
        //debugLog("update message: ", result);
      });
    });
  }

  sendGroupMessage = message => {
    return this.getToken().then(token => {
      const updateMessage = this.firebase.functions().httpsCallable("updateGroupMessage?idToken="+token);
      return updateMessage(message).then(result => {
        debugLog("update group message: ", result);
      }).catch(err => {
        debugLog("update group message: ", err);
      })
    });
  }

  addReaction = (to, ts, emoji) => {
    console.log("addReaction", to, ts)
    return this.getToken().then(token => {
      let url = "addReaction?idToken="+token;
      url += "&uid="+to;
      url += "&emoji="+encodeURIComponent(emoji);
      url += "&ts="+ts;
      const addReaction = this.firebase.functions().httpsCallable(url);
      return addReaction().then(result => result.data).then(result => {
        //debugLog("add reaction: ", result);
      });
    });
  }


  /* Product related */

  addProduct = prod => {
    return this.getToken().then(token => {
      const createProduct= this.firebase.functions().httpsCallable("createProduct?idToken="+token);
      return createProduct(prod).then(result => {
        //debugLog("created product: ", prod, " => ", result);
        //////////debugger;
      });
    });
  }

  acceptOffer = (productId, paymentMethodId) => {
    return this.getToken().then(token => {
      const acceptOffer = this.firebase.functions().httpsCallable("acceptOffer?idToken="+token+"&productId="+productId+"&paymentMethodId="+paymentMethodId);
      return acceptOffer().then(result => {
        //debugLog("accept offer complete: ", result);
        return result;
      });
    });
  }

  
  deleteProduct = productId => {
    return this.getToken().then(token => {
      const deleteProduct= this.firebase.functions().httpsCallable("deleteProduct?idToken="+token+"&productId="+productId);
      return deleteProduct().then(result => {
        //debugLog("deleted product: ", productId, " => ", result);
      });
    });
  }

  applyProductLink = link => {
    return this.getToken().then(token => {
      const applyProductLink = this.firebase.functions().httpsCallable("applyProductLink?idToken="+token+"&link="+link);
      return applyProductLink().then(result => {
        //debugLog("applied product link: ", result);
      });
    });
  }

  observePurchased = fromContact => {
    return collectionChanges(this.purchasedRef(this.self.uid, fromContact.uid)).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data();
        const product = data.product;
        product.state = data.state;
        return {type: change.type, data: product};
      }));
    }));;
  }

  observeMyProducts = () => {
    return collectionChanges(this.myProductsRef()).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data();
        const product = data.product;
        product.productLink = data.productLink;
        return {type: change.type, data: product};
      }));
    }));
  }

  observeEThree = () => {
    if (!encryptionEnabled) return from([null])
    if (this.eThree) return from([this.eThree]);
    return this.eThreeSubject.pipe(take(1));
  }

  /* End Product related */

  getPublicKey = () => {
    if (this.publicKey) return Promise.resolve(this.publicKey);
    return this.observeEThree().toPromise().then(eThree => eThree.findUsers(this.self.uid).then(publicKey => {
      return this.publicKey = publicKey;
    }));
  }

  getToken = () => this.user.getIdToken(false);

  generateContacts = n => {
    return this.getToken().then(token => {
      const func = this.firebase.functions().httpsCallable("generateContacts?idToken="+token+"&count="+n);
      return func().then(result => {
        debugLog(result.data.contacts);
        return result.data.contacts;
      });
    });
  }

  completeGoogleSignUp = (form, getDetails, getPassword, done, fail) => {
    return getDetails(form).then(result => {
      const converted = phone("+"+result.countryCode+result.phoneNumber);
      const phoneNumber = converted[0];
      const email = result.email;
      if (encryptionEnabled) {
        return getEThree().derivePasswords(result.password).then(derived => {
        var {loginPassword, backupPassword } = derived;
          loginPassword = decode(loginPassword);
          const credential = this.firebase.auth.EmailAuthProvider.credential(email, loginPassword);
          const finish = () => {
            this.backupPassword = backupPassword;
            return this.initE3(backupPassword, true, getPassword, false, done, fail).then(() => {
              return this.updateAccount({
                displayName: result.name,
              email: result.email,
                phoneNumber: phoneNumber,
                photoURL: result.photoURL,
              });
            });
          }
          ////debugger;
          return this.firebase.auth().currentUser.linkWithCredential(credential).then(finish).catch(err => {
            console.error(err);
            //////debugger;
            return finish();
          });
        });
      } else {
        let wasInvalid
        return getPassword(wasInvalid).then(password => {
          const credential = this.firebase.auth.EmailAuthProvider.credential(email, password);
          const finish = () => {
            return this.updateAccount({
              displayName: result.name,
              email: result.email,
              phoneNumber: phoneNumber,
              photoURL: result.photoURL,
            })
          }
          return this.firebase.auth().currentUser.linkWithCredential(credential).then(finish).catch(err => {
            console.error(err);
            //////debugger;
            return finish();
          });
        })
      }
    })
  }

  completePhoneSignUp = (phoneNumber, email, password, displayName) => {
    if (encryptionEnabled) {
      return getEThree().derivePasswords(password).then(derived => {
        var {loginPassword, backupPassword } = derived;
        loginPassword = decode(loginPassword);
        const credential = this.firebase.auth.EmailAuthProvider.credential(email, loginPassword);
        const finish = () => {
          this.backupPassword = backupPassword;
          return this.initE3(backupPassword, true).then(() => {
            return this.updateAccount({
              displayName: displayName,
              email: email,
              phoneNumber: phoneNumber,
            });
          });
        }
        return this.firebase.auth().currentUser.linkWithCredential(credential).then(finish).catch(err => {
          console.error(err);
          //////debugger;
          return finish();
        });
      });
    } else {
      const finish = () => {
        return this.updateAccount({
          displayName: displayName,
          email: email,
          phoneNumber: phoneNumber,
        });
      }
      const credential = this.firebase.auth.EmailAuthProvider.credential(email, password);
      return this.firebase.auth().currentUser.linkWithCredential(credential).then(finish).catch(err => {
        console.error(err);
        //////debugger;
        return finish();
      })
    }
  }
  
  
  signUpWithGoogle = (getPassword) => {
    const provider = new this.firebase.auth.GoogleAuthProvider();
    return this.firebase.auth().signInWithPopup(provider).then(result => {
      this.signUpDisplayName = result.user.displayName;
      if (result.user.providerData.length > 1) { // already signed up
        if (encryptionEnabled) {
          return this.initE3(null, false, getPassword).then(() => result);
        }
      }
      return result;
    });
  }

  signInWithGoogle = (onNeedsSignUp, getPassword) => {
    const provider = new this.firebase.auth.GoogleAuthProvider();
    return this.firebase.auth().signInWithPopup(provider).then(result => {
      const user = result.user;
      //////debugger;
      if (user.providerData.length != 3) {
        return onNeedsSignUp(user);
      }
      if (encryptionEnabled) {
        return this.initE3(null, false, getPassword);
      }
    });
  }
  
  sendSignUpEmailVerification = email => {
    const func = this.firebase.functions().httpsCallable("sendEmailVerification?email="+encodeURIComponent(email));
    return func().then(result => {
      return result.data;
    });
  }

  verifySignUpEmail = (email, code) => {
    const func = this.firebase.functions().httpsCallable("verifyEmail?email="+encodeURIComponent(email)+"&code="+code);
    return func().then(result => {
      return result.data;
    });
  }
  
  signUp = (email, password, displayName, phoneNumber) => {
    ////debugger
    this.signupDisplayName = displayName;
    this.signUpPhoneNumber= phoneNumber;
    let p;
    this.self = new Contact({
      email: email,
      displayName: displayName,
      phoneNumber: phoneNumber,
    });
    this.accountData = {
      email: email,
      displayName: displayName,
      phoneNumber, phoneNumber,
    };
    if (encryptionEnabled) {
      p = getEThree().derivePasswords(password).then(result => {
        var { loginPassword, backupPassword } = result;
        loginPassword = decode(loginPassword);
        this.backupPassword = backupPassword;
        return this.firebase.auth().createUserWithEmailAndPassword(email, loginPassword).then(result => {
          return this.initE3(backupPassword, true).then(() => {
            window.analytics.logEvent("signUp");
            return result.user;
          });
        })
      });
    } else {
      p = this.firebase.auth().createUserWithEmailAndPassword(email, password).then(result => result.user).catch(err => {
        this.self = null
        throw err
      })
    }
    return p.then(user => {
      this.onAuthStateChanged(user)
      this.sendNativeMessage({
        type: 'login',
        email: email,
        password: password,
        phoneNumber: phoneNumber
      })
      
      return this.updateAccount({
        displayName: displayName,
        email: email,
        phoneNumber: phoneNumber
      });
    });
  }

  isTherapist = () => {
    return window.isBusiness == 't';
  }

  isBusiness = () => {
    return window.isBusiness;
  }

  phoneNumberExists = (phoneNumber, accountExists) => {
    let url = "phoneNumberExists?phoneNumber="+encodeURIComponent(phoneNumber);
    const func = this.firebase.functions().httpsCallable(url);
    return func().then(result => {
      return result.data.exists;
    });
  }

  emailExists = email => {
    email = email.trim().toLowerCase()
    const func = this.firebase.functions().httpsCallable("emailExists?email="+encodeURIComponent(email));
    return func().then(result => {
      return result.data.exists;
    }).catch(err => {
      console.error(err);
      return false;
    });
  }

  signIn = (email, password) => {
    email = email.trim()
    password = password.trim()
    if (encryptionEnabled) {
      return getEThree().derivePasswords(password).then(result => {
        var { loginPassword, backupPassword } = result;
        //////debugger;
        loginPassword = decode(loginPassword);
        this.backupPassword = backupPassword;
        //debugLog("signing in to firebase");
        return this.firebase.auth().signInWithEmailAndPassword(email, loginPassword).then(result => {
          const creds = {
            type: 'login',
            email: email,
            password: password,
            phoneNumber: result.user.phoneNumber
          }
          //alert('login ' + JSON.stringify(creds))
          this.sendNativeMessage(creds)
          //debugLog("signed in to firebase");
          this.initE3(backupPassword, false).then(() => {
            window.analytics.logEvent("login", {method: 'email'});
            return result;
          });
        }).catch(err => {
          //debugLog(err);
          return Promise.reject(err);
        });
      })
    }
    return this.firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
      this.onAuthStateChanged(result.user)
      const creds = {
        type: 'login',
        email: email,
        password: password,
        phoneNumber: result.user.phoneNumber
      }
      //alert('login ' + JSON.stringify(creds))
      this.sendNativeMessage(creds)
    })
  }

  signInWithPhoneNumber = (phoneNumber, recaptcha, getCode, getPassword, forgotPassword, done) => {
    return this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha).then(result => {
      //////////debugger;
      const doit = err => {
        getCode(err).then(code => {
          ////debugger;
          try {
            result.confirm(code).then(result => {
              ////debugger;
              window.analytics.logEvent("login", {method: 'phone'});
              if (encryptionEnabled) {
                return this.initE3(null, false, getPassword, forgotPassword, (err) => {
                  if (!err) done();
                });
              } else {
                ////debugger
                return done()
              }
            }).catch(err => {
              ////debugger;
              return doit(err);
            });
          } catch (err) {
            ////debugger;
            return doit(err);
          }
        });
      }
      doit();
    })
  }

  updatePassword = (newPassword) => {
    if (encryptionEnabled) {
      return getEThree().derivePasswords(newPassword).then(result => {
        var { loginPassword, backupPassword } = result;
        loginPassword = decode(loginPassword);
        return this.firebase.auth().currentUser.updatePassword(loginPassword).then(() => {                
          return this.eThree.changePassword(this.backupPassword, backupPassword).then(() => {
            this.backupPassword = backupPassword;
            window.analytics.logEvent("changePassword");
            return loginPassword;
          });
        })
      })
    } else {
      return this.firebase.auth().currentUser.updatePassword(newPassword).then(() => newPassword);
    }
  }

  resetPassword = email => {
    let p = Promise.resolve();
    if (encryptionEnabled) {
      const eThree = this.eThree;
      const ops = [];
      ops.push(eThree.cleanup());
      ops.push(eThree.resetPrivateKeyBackup());
      return Promise.all(ops).then(eThree.rotatePrivateKey());
    }
    return this.firebase.auth().sendPasswordResetEmail(email);
  }

  signOut = () => {
    this.signUpDisplayName = null;
    this.online = {};
    this.sendNativeMessage({
      type: 'signOut'
    })
    return this.firebase.auth().signOut().then(() => {
      window.analytics.logEvent("signOut");
      debugLog("signed out");
    });
  }

  autocompleteFood = async (searchTerm, recent, type) => {
    if (recent) {
      const results = this.mealIndex.search(searchTerm, { type })
      return { results: { branded: results, common: [] } }
    }
    const f = this.firebase.functions().httpsCallable('autocompleteFood')
    const result = await f({
      q: searchTerm
    })
    return result.data
  }

  resolveFoods = async searchTerm => {
    const f = this.firebase.functions().httpsCallable('resolveFoods')
    const result = await f({
      q: searchTerm
    })
    return result.data
  }

  getFoodNutrition = async item => {
    const f = this.firebase.functions().httpsCallable('getFoodNutrition')
    const result = await f({
      item: item
    })
    return result.data
  }

  resolveBarcode = async barcode => {
    const f = this.firebase.functions().httpsCallable('resolveBarcode')
    const result = await f({
      barcode
    })
    return result.data
  }

  deleteMediaFromWorkout = async (workoutId, url) => {
    ////debugger
    const f = this.firebase.functions().httpsCallable('deleteMediaFromWorkout')
    const result = await f({workoutId, url})
    return result.data
  }
  
  deleteWorkout = async workout => {
    const f = this.firebase.functions().httpsCallable('deleteWorkout')
    const result = await f({workoutId: workout.id})
    return result.data
  }

  saveWorkout = async (client, workout) => {
    ////debugger
    const f = this.firebase.functions().httpsCallable('saveWorkout')
    const result = await f({
      client: client.uid,
      workout: workout
    })
    debugLog('saveWorkout:', result)
    ////debugger
    return result.data
  }

  saveWorkoutSession = async (clientUid, workout) => {
    const f = this.firebase.functions().httpsCallable('saveWorkoutSession')
    const result = await f({
      client: clientUid,
      workout: workout
    })
    console.log('saveWorkoutSession:', result)
    ////debugger
    return result.data
  }

  deleteWorkoutSession = async id => {
    const f = this.firebase.functions().httpsCallable('deleteWorkoutSession')
    const result = await f({
      id
    })
    debugLog('deleteWorkout:', result)
    ////debugger
    return result.data
  }

  saveWeight = async weight => {
    debugLog("saveWeight", weight)
    const f = this.firebase.functions().httpsCallable('saveWeight')
    const result = await f({
      weight
    })
    debugLog("saveWeightResult", result)
    return result.data
  }

  deleteWeight = async id => {
    const f = this.firebase.functions().httpsCallable('deleteWeight')
    const result = await f({
      id
    })
    debugLog("deleteWeightResult", result)
    return result.data
  }

  saveMeal = async meal => {
    debugLog("saveMeal", meal)
    const f = this.firebase.functions().httpsCallable('saveMeal')
    const result = await f({
      meal
    })
    debugLog("saveMealResult", result)
    return result.data
  }

  deleteMeal = async mealId => {
    const f = this.firebase.functions().httpsCallable('deleteMeal')
    const result = await f({
      mealId
    })
    debugLog("saveMealResult", result)
    return result.data
  }

  observeBrands = () => {
    const ref = this.firebase.firestore().collection('BrandDomains')
    collectionChanges(ref).subscribe(changes => {
      changes.forEach(change => {
        const brand = change.doc.data()
        const name = brand.name.toLowerCase()
        const id = change.doc.id
        if (change.type == 'removed') {
          delete brandMap[name]
        } else {
          brandMap[name] = brand
        }
      })
    })
  }

  observeNutrients = () => {
    return docData(this.firebase.firestore.collection('Nutrients').doc('nutrients'))
  }

  deviceSettingsSubject = new Subject

  updateDeviceSetting = (name, value) => {
    localStorage.setItem(name, value)
    for (const s in this.deviceSettings) {
      if (s.toLowerCase() === name) {
        this.deviceSettings[s] = value
      }
    }
    this.deviceSettingsSubject.next(this.deviceSettings)
  }

  observeDeviceSettings = () => {
    if (!this.deviceSettings) {
      const videoInput = localStorage.getItem('videoinput')
      const audioInput = localStorage.getItem('audioinput')
      const audioOutput = localStorage.getItem('audiooutput')
      const videoRes = localStorage.getItem('videores')
      this.deviceSettings = {
        videoInput, audioInput, audioOutput, videoRes
      }
    }
    return concat(of(this.deviceSettings), this.deviceSettingsSubject)
  }

  saveDeviceSettings = settings => {
    const { videoInput, audioInput, videoRes, audioOutput } = settings
    if (!this.deviceSettings ||
        videoInput != this.deviceSettings.videoInput ||
        audioInput != this.deviceSettings.audioInput ||
        videoRes != this.deviceSettings.videoRes) {
      localStorage.setItem('videoinput', videoInput)
      localStorage.setItem('audioinput', audioInput)
      localStorage.setItem('videores', videoRes)
      localStorage.setItem('audiooutput', audioOutput)
      this.deviceSettings = {
        videoInput, audioInput, videoRes
      }
      this.deviceSettingsSubject.next(this.deviceSettings)
    }
  }

  observeDataSharedWithMe = contact => {
    //const uid = contact.group ? contact.group.organizer : uid
    const uid = contact.uid
    const ref = this.firebase.firestore().collection('Users').doc(uid).collection('DataShared').doc(this.self.uid)
    return doc(ref).pipe(map(doc => doc.exists))
  }

  observeISharedDataWith = contact => {
    //const uid = contact.group ? contact.group.organizer : contact.uid
    const uid = contact.uid
    const ref = this.firebase.firestore().collection('Users').doc(this.self.uid).collection('DataShared').doc(uid)
    const result = doc(ref).pipe(map(doc => doc.exists))
    ////debugger
    return result
  }

  workoutToId = workout => {
    return {workoutId: workout.sessionId, index: workout.index}
  }

  startWorkout = async workout => {
    const f = this.firebase.functions().httpsCallable('startActivity')
    ////debugger
    return await f(this.workoutToId(workout))
  }

  completeWorkout = async workout => {
    const f = this.firebase.functions().httpsCallable('completeActivity')
    return await f(this.workoutToId(workout))
  }

  declineWorkout = async workout => {
    const f = this.firebase.functions().httpsCallable('declineActivity')
    return await f(this.workoutToId(workout))
  }

  cancelWorkout = async workout => {
    const f = this.firebase.functions().httpsCallable('cancelActivity')
    return await f(this.workoutToId(workout))
  }

  markWorkoutDone = async workout => {
    const f = this.firebase.functions().httpsCallable('markActivityDone')
    return await f(this.workoutToId(workout))
  }

  observeScheduledWorkouts = (with_, status) => {
    return this.observeMyScheduledWorkouts(with_, status).pipe(merge(this.observeWorkoutsIScheduled(with_, status)))
  }

  observeMyCompletedWorkouts = () => {
    return this.observeWorkoutSessions()
  }

  observeMyScheduledWorkouts = (by, status) => {
    let q = this.firebase.firestore().collection('Workouts').where('client', '==', this.self.uid)
    if (by) q = q.where('trainer', '==', by)
    if (status) q = q.where('status', '==', status)
    //q = q.where('start', '>=', start).where('start', '<', end)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const rec = change.doc.data()
        const workout = rec.workout
        workout.id = change.doc.id
        workout.start = rec.start
        workout.end = rec.end
        workout.trainer = rec.trainer
        workout.client = rec.client
        workout.lastModified = rec.lastModified
        workout.scheduled = rec.scheduled
        workout.status = rec.status
        const sport = this.getWhoop().getSport(workout.activity)
        workout.activity = {
          uid: sport.id,
          displayName: sport.name,
          profileImage: sport.iconUrl,
        }
        debugLog("got workout", workout)
        return {
          type: change.type,
          workout: workout,
        }
      })
    }))
  }

  workoutSessions = {}

  mapWorkoutSessionChanges = changes => {
    const result = []
    changes.forEach(change => {
      const type = change.type
      const data = change.doc.data()
      const id = change.doc.id
      let prev
      if (type == 'modified') {
        prev = this.workoutSessions[id]
        if (prev) {
          prev.activities.forEach((w, i) => {
            result.push({
              type: 'removed',
              workout: w
            })
          })
        }
        this.workoutSessions[id] = data
      }
      data.activities.forEach((w, i) => {
        w.id = id + '-' + i
        w.sessionId = id
        w.index = i
        w.client = data.client
        w.trainer = data.uid
        w.scheduled = data.scheduled
        w.sessionTitle = data.description
        const sport = this.getWhoop().getSport(w.activity)
        w.activity = {
          uid: sport.id,
          displayName: sport.name,
          profileImage: sport.iconUrl,
        }
        result.push({
          type: change.type,
          workout: w
        })
      })
    })
    return result
  }

  observeRecentWorkoutSessions = (client) => {
    let q = this.firebase.firestore().collection('WorkoutSessions').where('uid', '==', this.self.uid)
    if (client) {
      q = q.where('client', '==', client)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const workout = change.doc.data()
        workout.id = change.doc.id
        workout.activities.map((a, i) => a.index = i)
        return {
          type: change.type,
          workout: workout
        }
      })
    }))
  }

  observeWorkoutSessions = (client, start, end) => {
    let q = this.firebase.firestore().collection('WorkoutSessions').where('uid', '==', this.self.uid)
    if (client) q = q.where('client', '==', client)
    return collectionChanges(q).pipe(flatMap(changes => {
      return this.mapWorkoutSessionChanges(changes)
    }))
  }
  
  observeMyWorkoutSessions = (start, end) => {
    let q = this.firebase.firestore().collection('WorkoutSessions').where('client', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return this.mapWorkoutSessionChanges(changes)
   }))
  }
  
  getWorkoutSession = async workoutId => {
    const ref = this.firebase.firestore().collection('WorkoutSessions').doc(workoutId)
    const snap = await ref.get()
    const data = snap.data()
    const id = snap.id
    data.id = id
    data.activities.forEach((w, i) => {
      w.id = id + '-' + i
      w.sessionId = id
      w.index = i
      w.client = data.client
      w.trainer = data.uid
      w.scheduled = data.scheduled
      const sport = this.getWhoop().getSport(w.activity)
      w.activity = {
        uid: sport.id,
        displayName: sport.name,
        profileImage: sport.iconUrl,
      }
    })
    return data
  }

  observeWorkout = workoutId => {
    return docData(this.firebase.firestore().collection('Workouts').doc(workoutId))
  }

  observeWorkoutsIScheduled = (uid, start, end, status) => {
    let q = this.firebase.firestore().collection('WorkoutSessions').where('uid', '==', this.self.uid)
    if (uid) {
      q = q.where('client', '==', uid)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return this.mapWorkoutSessionChanges(changes)
    }))
  }
  
  observeWorkoutsIScheduledOld = (uid, start, end, status) => {
    let q = this.firebase.firestore().collection('Workouts').where('trainer', '==', this.self.uid)
    if (uid) {
      q = q.where('client', '==', uid)
    }
    if (status) {
      q = q.where('status', 'in', status)
    }
    if (start) {
      q = q.where('start', '>=', start)
    }
    if (end) {
      q = q.where('end', '<',  end)
    }
    return collectionChanges(q).pipe(flatMap(changes => changes.map(this.convertWorkout)))
  }

  convertWorkout = change => {
    const rec = change.doc.data()
    const workout = rec.workout
    workout.id = change.doc.id
    workout.start = rec.start
    workout.end = rec.end
    workout.trainer = rec.trainer
    workout.client = rec.client
    workout.lastModified = rec.lastModified
    workout.scheduled = rec.scheduled
    workout.status = rec.status
    const sport = this.getWhoop().getSport(workout.activity)
    workout.activity = {
      uid: sport.id,
      displayName: sport.name,
      profileImage: sport.iconUrl,
    }
    return {
      type: change.type,
      workout: workout,
    }
  }

  getMealIndex = uid => {
    return new MealIndex(uid, this.observeMeals(uid))
  }

  observeWeight = (uid, start, end, limit) => {
    return mergeN(this.observeWithingsWeight(uid, start, end, limit), this.observeFitbitWeight(uid, start, end, limit), this.observeUserWeight(uid, start, end, limit))
  }

  observeFitbitWeight = (uid, start, end, limit) => {
    return this.observeWeightImpl('FitbitCycleData', uid, start, end, limit)
  }
  
  observeWithingsWeight = (uid, start, end, limit) => {
    return this.observeWeightImpl('WithingsCycleData', uid, start, end, limit)
  }

  observeUserWeight = (uid, start, end, limit) => {
    return this.observeWeightImpl('Weights', uid, start, end, limit).pipe(map(change => {
      change.cycle.editable = true
      return change
    }), catchError(e => from([])))
  }

  observeWeightImpl = (c, uid, start, end, limit) => {
    ////debugger
    let q = this.firebase.firestore().collection(c).where('uid', '==', uid)
    if (start) {
      q = q.where('created', '>=', start)
    }
    if (end) {
      q = q.where('created', '<', end)
    }
    if (limit) {
      q = q.orderBy('created', 'desc').limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const cycle = change.doc.data()
        cycle.id = change.doc.id
        return {
          type: change.type,
          cycle: cycle
        }
      })
    }), catchError(err => {
      ////debugger
    }))
  }

  observeMeals = (uid, start, end, limit) => {
    let q = this.firebase.firestore().collection('Meals').where('uid', '==', uid)
    if (start) {
      q = q.where('start', '>=', start)
    }
    if (end) {
      q = q.where('start', '<', end)
    }
    if (limit) {
      q = q.orderBy('start', 'desc').limit(limit)
    }
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const meal = change.doc.data()
        meal.id = change.doc.id
        meal.foods.map(food => {
          if (!food.nutrition && food.full_nutrients) {
            food.nutrition = JSON.parse(JSON.stringify(food))
          }
        })
        return {
          type: change.type,
          meal: meal
        }
      })
    }))
  }

  addMediaToWorkout = async (workoutId, media) => {
    const f = this.firebase.functions().httpsCallable('addMediaToWorkout')
    const response = await f({
      workoutId, media
    })
    ////debugger
    return response.data
  }

  shareData = async (contact, share) => {
    const f = this.firebase.functions().httpsCallable('shareData')
    await f({
      uid: contact.uid,
      share: share
    })
  }

  observeMyTrainees = () => {
    const q = this.firebase.firestore().collection('Users').doc(this.self.uid).collection('Training')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const uid = change.doc.id
        return from(this.resolveContact(uid)).pipe(map(contact => {
          return {
            type: change.type,
            contact
          }
        }))
      })
    }))
  }


  train = async (contact, isTraining) => {
    const f = this.firebase.functions().httpsCallable('train')
    await f({
      uid: contact.uid,
      isTraining: isTraining
    })
  }

  linkFitbit = () => {
    console.log("fitbit => ", this.fitbitUrl)
    return this.openWindow(this.fitbitUrl, '_blank')
  }

  unlinkFitbit = async () => {
    const f = this.firebase.functions().httpsCallable('unlinkFitbit')
    await f()
    await this.getFitbitUrl() // refresh link
  }


  linkGarmin = () => {
    console.log("garmin => ", this.garminUrl)
    return this.openWindow(this.garminUrl, '_blank')
  }

  unlinkGarmin = async () => {
    const f = this.firebase.functions().httpsCallable('unlinkGarmin')
    await f()
    await this.getGarminUrl() // refresh link
  }
  

  unlinkWhoop = async () => {
    const f = this.firebase.functions().httpsCallable('unlinkWhoop')
    await f()
  }

  linkWhoop = async data => {
    const f = this.firebase.functions().httpsCallable('linkWhoop')
    await f(data)
  }

  linkOura = async () => {
    return this.openWindow(this.ouraUrl, '_blank')
  }

  unlinkOura = async () => {
    const f = this.firebase.functions().httpsCallable('unlinkOura')
    await f()
    await this.getOuraUrl() // refresh link
  }

  linkWithings = () => {
    return this.openWindow(this.withingsUrl, '_blank')
  }

  unlinkWithings = async () => {
    const f = this.firebase.functions().httpsCallable('unlinkWithings')
    await f()
    await this.getWithingsUrl() // refresh link
  }

  initE3 = (backupPassword, isSignUp, getPassword, forgotPassword, done, fail) => {
    const tokenCallback = () => {
      const getJwt = this.firebase.functions().httpsCallable("getJwt");
      //debugLog("token callback started");
      return getJwt().then(result => {
        //debugLog("token callback complete");
        return result.data.token;
      });
    }
    return EThree.initialize(tokenCallback).then(eThree => {
      this.eThree = eThree;
      //////////debugger;
      if (isSignUp) {
        return eThree.register().then(() => eThree.backupPrivateKey(backupPassword));
      } else {
        return eThree.hasLocalPrivateKey().then(hasLocalPrivateKey => {
          //////debugger;
          let test = false;
          let count = 0;
          if (test || !hasLocalPrivateKey || forgotPassword) {
            const doGetPassword = (wasInvalid) => {
              ++count;
              let getPasswords;
              if (backupPassword) {
                getPasswords = () => Promise.resolve({backupPassword: backupPassword});
              } else {
                getPasswords = () => getPassword(wasInvalid).then(password => {
                  debugLog("got password: ", password);
                  return EThree.derivePasswords(password);
                });
              }
              return getPasswords().then(result => {
                if (hasLocalPrivateKey && !forgotPassword) {
                  if (count < 2) {
                    return doGetPassword(true);
                  }
                  return;
                }
                var { loginPassword, backupPassword } = result;
                if (!forgotPassword) {
                  return eThree.restorePrivateKey(backupPassword).then(()=> eThree).catch (err => {
                    console.error(err);
                    if (done) done({error: "Password not valid for this device"});
                    return doGetPassword(true);
                  });
                } else {
                  debugLog("got passwords: ", result);
                  return eThree.resetPrivateKeyBackup().then(() => {
                    return eThree.backupPrivateKey(backupPassword).then(() => {
                      loginPassword = decode(loginPassword);
                      return this.firebase.auth().currentUser.updatePassword(loginPassword).then(() => {                
                        if (done) done();
                        return eThree;
                      }).catch(err => {
                        if (done) done({
                          error: err.message
                        })
                      });
                    });
                  }).catch(err => {
                    console.error(err);
                    return doGetPassword(true);
                  });
                }
              }).then(result => {
                if (done) done();
                return result;
              });
            }
            return doGetPassword();
          }
          if (done) done();
          return eThree;
        })
      }
    }).then(() => {
      debugLog("firing e3 subject");
      this.eThreeSubject.next(this.eThree);
      return this.eThree;
    })
  }


  canResetPassword = () => {
  }

  onAuthStateChanged = user => {
    debugLog("auth state changed: ", user);
    if (user && user == this.user) return
    if (!user) {
      if (this.whoopLinkSub) {
        this.whoopLinkSub.unsubscribe()
        this.whoopLinkSub = null
        delete this.whoopAuth
      }
      if (this.ouraLinkSub) {
        this.ouraLinkSub.unsubscribe()
        this.ouraLinkSub = null
        delete this.ouraAuth
      }
      if (this.withingsLinkSub) {
        this.withingsLinkSub.unsubscribe()
        this.withingsLinkSub = null
        delete this.withingsAuth
      }
      if (this.fitbitLinkSub) {
        this.fitbitLinkSub.unsubscribe()
        this.fitbitLinkSub = null
        delete this.fitbitAuth
      }
      if (this.garminLinkSub) {
        this.garminLinkSub.unsubscribe()
        this.garminLinkSub = null
        delete this.garminAuth
      }
      if (this.contactsSub) {
        this.contactsSub.unsubscribe();
        this.contactsSub = null;
      } 
      if (this.stripeAuthUnsubscribe) {
        this.stripeAuthUnsubscribe();
        this.stripeAuthUnsubscribe = null;
      }
      if (this.onlineSub) {
        this.onlineSub.unsubscribe();
        this.onlineSub = null
      }
      if (this.accountSub) {
        this.accountSub.unsubscribe()
        this.accountsSub = null
      }
      if (this.stripeAuthSub) {
        this.stripeAuthSub.unsubscribe()
        this.stripeAuthSub = null
      }
      clearInterval(this.checkOnline)
      this.stripeAuth = null;
      this.stripeAuthSubject.next(null);
      this.accountData = null;
      this.accountSubject.next(null);
      this.user = null;
      this.self = null;
      debugLog("nullified self");
      this.eThree = null;
      this.backupPassword = null;
      this.selfSubject.next(null);
      this.contacts = {};
      clearInterval(this.checkOnline);
      clearInterval(this.onlineTimer);
      this.contacts = {}
      this.online = {}
      return;
    }
    this.user = user;
    //debugLog("firebase user: ", user);
    this.self = new Contact(user);
    this.contacts[this.self.uid] = {contact: this.self }
    this.updateMaxHeartRate(this.self.uid)
    //////////debugger;
    if (this.signupDisplayName) {
      this.self.displayName = this.signupDisplayName;
    }
    this.accountData = {
      uid: this.self.uid,
      email: this.self.email,
      displayName: this.self.displayName,
      phoneNumber: this.self.phoneNumber
    }
    ////debugger
    this.accountSub = this.observeAccountImpl().subscribe(accountData => {
      this.accountData = accountData;
      debugLog("got account: ", this.accountData);
      if (accountData.email) { 
        this.self.displayName = accountData.displayName;
        this.self.email = accountData.email;
        this.self.phoneNumber = accountData.phoneNumber;
      } else {
        accountData.email = this.self.email;
        accountData.phoneNumber = this.self.phoneNumber;
        accountData.displayName = this.self.displayName;
      }
      this.self.licenses = accountData.licenses || "";
      this.self.degrees = accountData.degrees || "";
      this.self.isTrainer = !!accountData.isTrainer
      this.self.pronouns = accountData.pronouns || 'they'
      this.self.updateCreds();
      this.selfSubject.next(this.self);
      this.accountSubject.next(accountData);
    });
    this.contactsSub = this.observeContactsImpl().subscribe(change => {
      const user = change.contact;
      //debugLog("contactsSub: ", user);
      user.contact = new Contact(user.contact);
      user.contact.sharedWhoop = user.sharedWhoop
      const contact = user.contact;
      if (change.type == "removed") {
        delete this.contacts[contact.uid];
      } else {
        this.contacts[contact.uid] = user;
      }
      this.updateMaxHeartRate(contact.uid)
      this.contactsSubject.next({type: change.type, contact: user});
    });
    this.stripeAuthSub = this.observeStripeAuthImpl().subscribe(stripeAuth => {
      debugger
      this.stripeAuth = stripeAuth
      this.stripeAuthSubject.next(stripeAuth)
    })
    this.selfSubject.next(this.self);
    this.getContactLink();
    this.onlineTimer = setInterval(this.markOnline, 1000 * 60 * 5);
    this.markOnline();
    this.online[this.self.uid] = {uid: this.self.uid, online: true};
    this.checkOnline = setInterval(() => {
      const now = Date.now();
      const sixMinutesAgo = now - 1000 * 60 * 6;
      for (var uid in this.online) {
        if (uid == this.self.uid) continue;
        const data = this.online[uid];
        const ts = data.lastOnline || 0;
        const online = sixMinutesAgo <= ts;
        if (online != data.online) {
          data.online = online;
          ////////////debugger;
          this.onlineSubject.next(data);
        }
      }
    }, 5000);
    this.onlineSub = this.observeOnline().subscribe(changes => {
      changes.map(change => {
        const data = change.doc.data();
        const uid = change.doc.id;
        const ts = data.lastOnline;
        const online = Date.now() - 1000 * 60 * 5 <= ts;
        if (ts) {
          //debugLog("last online: ", uid, ": ", moment(new Date(ts)).fromNow());
        }
        ////////debugger;
        if (!this.online[uid]) {
          this.online[uid] = {online: online, lastOnline: ts, uid: uid};
        } else {
          this.online[uid].lastOnline = ts;
          if (this.online[uid].online == online) {
            return;
          }
          this.online[uid].online = online;
        }
        //debugLog("is online ", uid, " => ", this.online[uid]);
        this.onlineSubject.next(this.online[uid]);
      });
    });
    this.getOuraUrl().then(() => {
    })
    this.getGarminUrl().then(() => {
    }).catch(err => {
      console.error(err)
      debugger
    })
    this.getWithingsUrl().then(() => {
    }).catch(err => {
      console.error(err)
    })
    this.getFitbitUrl().then(() => {
    }).catch(err => {
      console.error(err)
    })
    this.mealIndex = this.getMealIndex(this.self.uid)
    this.observeBrands()
  }

  ouraUrlSubject = new Subject()

  observeOuraLink = () => {
    if (this.ouraUrl) {
      return concat(of(this.ouraUrl), this.ouraUrlSubject)
    }
    return this.ouraUrlSubject
  }

  getOuraUrl = async () => {
    const f = this.firebase.functions().httpsCallable('getOuraLink')
    const response = await f()
    const { url } = response.data
    this.ouraUrl = url
    this.ouraUrlSubject.next(url)
  }

  withingsUrlSubject = new Subject()

  getWithingsUrl = async () => {
    const f = this.firebase.functions().httpsCallable('getWithingsLink')
    const response = await f()
    const { url } = response.data
    debugLog("withings link", url)
    ////debugger
    this.withingsUrl = url
    this.withingsUrlSubject.next(url)
  }

  getFitbitUrl = async data => {
    const f = this.firebase.functions().httpsCallable('getFitbitLink')
    const response = await f(data)
    const { url } = response.data
    console.log("fitbit url", url)
    this.fitbitUrl = url
    this.fitbitUrlSubject.next(url)
  }

  fitbitUrlSubject = new Subject()

  observeFitbitLink = () => {
    if (this.fitbitUrl) {
      return concat(of(this.fitbitUrl), this.fitbitUrlSubject)
    }
    return this.fitbitUrlSubject
  }

  getGarminUrl = async data => {
    const f = this.firebase.functions().httpsCallable('getGarminLink')
    const response = await f(data)
    console.log('getGarminLink', response.data)
    const { url } = response.data
    console.log("garmin url", url)
    this.garminUrl = url
    this.garminUrlSubject.next(url)
  }

  garminUrlSubject = new Subject()

  observeGarminLink = () => {
    if (this.garminUrl) {
      return concat(of(this.garminUrl), this.garminUrlSubject)
    }
    return this.garminUrlSubject
  }

  
  getContact = uid => {
    const c = this.contacts[uid];
    return c ? c.contact : null;
  }

  openWindow = (url, arg) => {
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'openURL',
        openURL: url
      })
      return {
        closed: true,
        close: () => {}
      }
    }
    console.log("window open", url)
    return window.open(url, arg)
  }

  showPrivacyPolicy = () => {
    return this.openWindow(TeTePrivacyNotice, "_blank");
  }

  showTOS = () => {
    return this.openWindow(TeTeTOS, "_blank");
  }

  showBAA = () => {
    return this.openWindow(TeTeBAA, "_blank");
  }

  showSupport = () => {
    return this.openWindow("mailto:support@letsbuild.fitness?subject=Let's Build Support Request");
  }

  showReceipt = appointmentId => {
    return this.getToken().then(token => {
      const getReceiptURL =
            this.firebase.functions().httpsCallable("getAppointmentReceiptURL?idToken="+token+"&appointmentId="+appointmentId);
      return getReceiptURL().then(response => {
        if (response.data.receiptURL ) {
          const popup = this.openWindow(response.data.receiptURL);
          return popup;
        }
        console.error(response.error);
        return null;
      })
    });
  }

  stripeConnect = () => {
    return this.firebase.firestore().collection("StripeAuth").doc(this.self.uid).get().then(snap => {
      const data = snap.data();
      const connected = snap.exists && data.stripe_user_id;
      return this.getAccount().then(autofill => {
        return this.getToken().then(token => {                    
          let url = this.teteFunctionEndpoint+"/stripeConnect?idToken="+token;
          const fields = [
            "email",
            "country",
            "phoneNumber",
            "firstName",
            "lastName",
            "streetAddress",
            "city",
            "state",
            "zip",
            "dobMonth",
            "dobDay",
            "dobYear"
          ];
          fields.forEach(field => {
            if (autofill[field]) {
              url += "&"+field+"="+encodeURIComponent(autofill[field]);
            }
          });
          if (autofill["bday"]) {
            const date = new Date(autofill["bday"]);
            const d = date.getDate()+1;
            const m = date.getMonth()+1;
            const y = date.getFullYear();
            url += "&dobDay="+d+"&dobMonth="+m+"&dobYear="+y;
          }
          window.analytics.logEvent("stripeConnect");
          const popup = this.openWindow(url);
          if (!connected) {
            this.getStripeAuth().toPromise().then(auth => {
              //debugLog("got response: ", auth);
              popup.close();
            });
          }
          return Promise.resolve(popup);
        });
      });
    });
  }

  stripeAuthRef = () => this.firebase.firestore().collection("StripeAuth").where("uid", "==", this.self.uid);

  hasStripeAuth = () => {
    if (this.stripeAuth) return Promise.resolve(true);
    return this.stripeAuthRef().get().then(snap => {            
      if (snap.docs.length > 0) {
        const data = snap.docs[0].data();
        if (data.stripe_user_id) {
          this.stripeAuth = data;
          return true;
        }
      }
      return false;
    });
  }
  
  getStripeAuth = () => {
    return this.observeStripeAuth().pipe(take(1));
  }

  observeStripeAuth = () => {
    return this.observeSelf().pipe(flatMap(self => {
      if (self) {
        if (this.stripeAuth) {
          return concat(of(this.stripeAuth), this.stripeAuthSubject)
        }
        return this.stripeAuthSubject
      }
      return of(null)
    }))
  }

  observeStripeAuthImpl = () => {
    return docData(this.firebase.firestore().collection("StripeAuth").doc(this.self.uid)).pipe(filter(doc => doc.stripe_user_id))
  }

  you = uid => {
    if (encryptionEnabled) {
      const ops = [this.getPublicKey(), this.eThree.findUsers(uid)];
      return Promise.all(ops).then(publicKeys => {
        debugLog("got public keys: ", publicKeys);
        return new You(this, uid, publicKeys[1]);
      }).catch(err => {
        console.error(err);
        return new Id();
      });
    }
    return Promise.resolve(new Id());
  }

  systemMessagesRef = () => this.firebase.firestore().collection("SystemMessages").where("to", "==", this.self.uid);

  markSystemMessagesRead = () => {
    //////debugger;
    debugLog("markSystemMessagesRead");
    return this.updateAccount({systemUnread: 0, lastSystemReadTime: Date.now()});
  }

  observeFeed = limit => {
    let q = this.firebase.firestore().collection('Users').doc(this.user.uid).collection('Feed')
    q = q.orderBy('end', 'desc')
    return collectionChanges(q).pipe(flatMap(changes => changes.map(this.convertWorkout)))
  }

  observeSystemMessages = (limit) => {
    const convertTs = ts => {
      const result = ts.seconds * 1000 + Math.round(ts.nanoseconds/1000000);
      //debugLog("convertTs: ", new Date(result));
      return result;
    }
    let q = this.systemMessagesRef().orderBy("ts", "desc");
    if (limit) q = q.limit(limit);
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const data = change.doc.data();
        const msg = data;
        debugLog("got system message: ", msg);
        msg.system = true;
        if (msg.data.newContact) {
          const newContact = msg.data.newContact;
          ////////////debugger;
          if (!newContact.contact) return;
          if (!newContact.contact.displayName) {
            const c = this.getContact(newContact.contact.uid);
            if (!c) {
              ////////////debugger;
            } else {
              newContact.contact = c;
            }
          }
          newContact.contact = new Contact(newContact.contact);
        }
        msg.ts = convertTs(msg.ts);
        return {type: change.type, message: msg};
      });
    }));
  }

  observeMyServices = ()=> {
    return this.observePurchasedChannels({by: this.self.uid});
  }

  observeMyClients = () => {
    return this.observePurchasedChannels({from: this.self.uid});
  }
  
  observePurchasedChannels = (purchased)=> {
    const by = purchased.by
    const from = purchased.from;
    return collectionChanges(this.purchasedRef(by, from).where("state", "==", "purchased")).pipe(flatMap(changes => {
      return changes.map(change => {
        const data = change.doc.data();
        data.getChannelId = () => data.by + "-" + data.from + "-" + data.product.productId;
        return {type: change.type, channel: data};
      });
    }));
  }

  getCurrentContacts = () => {
    return Object.values(this.contacts).filter(c => c.state != "removed" && !c.group).map(c => c.contact);       
  }

  getContacts = () => {
    return this.myContactsRef().get().then(snap2 => {
      snap2.docs.map(doc => {
        const user = doc.data()
        const contact = user.contact
        if (contact.uid == this.self.uid && contact.displayName == contact.email &&
            this.signupDisplayName) {
          contact.displayName = this.signUpDisplayName
        }
        user.contact = new Contact(contact)
        this.contacts[contact.uid] = user
      })
    }).then(() => {
      return this.groupsRef().get().then(snap1 => {
        snap1.docs.map(doc => {
          const group = doc.data()
          const contact = this.convertGroup(doc.id, group)
          this.contacts[doc.id] = contact
        })
      })
      return this.contacts
    })
  }

  observeContact = uid => {
    return this.observeContacts().pipe(filter(c => c.contact && c.contact.contact && c.contact.contact.uid === uid))
  }

  observeContacts = () => {
    const existing = Object.values(this.contacts).map(c => {
      return {type: 'added', contact: c}
    });
    debugLog("existing contacts: ", this.contacts);
    return concat(from(existing), this.contactsSubject);
  }

  observeContactsImpl = () => {
    return collectionChanges(this.myContactsRef()).pipe(flatMap(changes => {
      return changes.map(change => {
        debugLog("observeContacts change: ", change);
        const data = change.doc.data();
        debugLog("observeContacts data: ", data);
        const contact = data.contact = new Contact(data.contact);
        if (contact.uid == this.self.uid &&
            contact.displayName == contact.email) {
          contact.displayName = this.signupDisplayName;
        }
        return {type: change.type, contact: data};
      });
    }));
  }

  resolveGroup = async uid => {
    const contact = this.getContact(uid);
    if (contact) {
      return contact
    }
    const snap = await this.groupRef(uid).get()
    return this.convertGroup(uid, snap.data())
  }

  resolveContact = uid => {
    if (uid == this.self.uid) return Promise.resolve(this.self);
    const contact = this.getContact(uid);
    if (contact) {
      return Promise.resolve(contact);
    }
    return this.myContactsRef().where("contactId", "==", uid).get().then(snap => {
      if (snap.docs.length > 0) {
        const doc = snap.docs[0];
        const user = doc.data();
        user.contact = new Contact(user.contact);
        const contact = user.contact;
        this.contacts[contact.uid] = user;
        return Promise.resolve(contact);
      }
      return null;
    });
  }
  
  resolveAppointmentContact = (appointmentId) => {
    return this.firebase.firestore().collection("Appointments").doc(appointmentId).get().then(doc => {
      if (doc.exists) {
        const appt = doc.data();
        return this.getContact(appt.uid == this.self.uid ? appt.client : appt.uid);
      }
      return Promise.resolve()
    });
  }

  providerSubscriptionsRef = () => {
    return this.firebase.firestore().collection("Subscriptions").where('uid', '==', this.self.uid);
  }

  mySubscriptionsRef = () => {
    return this.firebase.firestore().collection("Subscriptions").where('client', '==', this.self.uid);
  }

  subscriptionRef = contact => {
    return this.firebase.firestore().collection("Subscriptions").where('uid', '==', this.self.uid).where('client', '==', contact.uid); 
  }

  mySubscriptionRef = contact => {
    return this.firebase.firestore().collection("Subscriptions").where('client', '==', this.self.uid).where('uid', '==', contact.uid);
  }

  appointmentsRef = (after) => {
    const q = this.firebase.firestore().collection("Appointments").where('uid', '==', this.self.uid);
    return after ? q.where("end", ">", after) : q;
  }

  myAppointmentsRef = (after) => {
    const q = this.firebase.firestore().collection("Appointments").where('client', '==', this.self.uid);
    return after ? q.where("end", ">", after) : q;
  }

  myContactsRef = () => this.firebase.firestore().collection("Contacts").where('uid', '==', this.self.uid);

  channelsRef = channelId => this.firebase.firestore().collection("Channels").doc(channelId);
  
  accountRef = () => this.firebase.firestore().collection("Users").doc(this.user.uid);

  purchasedRef = (by, from) => {
    let q = this.firebase.firestore().collection("Purchased");
    if (by) {
      q = q.where("by", "==", by);
    }
    if (from) {
      q = q.where("from", "==", from);
    }
    return q;
  }
  
  myProductsRef = () => this.firebase.firestore().collection("MyProducts").where("uid", "==", this.user.uid);

  unreadsRef = () => this.accountRef().collection("unreads");

  markRead = channelId => {
    return this.unreadsRef().doc(channelId).set({channel: channelId, unread: 0});
  }

  getUnreads = () => {
    const ops = [this.unreadsRef().get()];
    return Promise.all(ops).then(results => {
      const [snap] = results;
      return snap.docs.map(doc => doc.data())
    });
  }

  observeUnreads = () => {
    return collectionChanges(this.unreadsRef()).pipe(flatMap(changes => {
      return changes.map(change => {
        const data = change.doc.data();
        const comps = data.channel.split("-");
        const other = comps[0] == this.self.uid ? comps[1] : comps[0];
        const contact = this.contacts[other];
        console.log('UNREAD', other, '=>', contact)
        if (!contact || contact.state == 'removed') return null;
        if (change.type == 'removed') {
          return {channel: data.channel, contact: contact.contact, unread: 0};
        } 
        return {channel: data.channel, contact: contact.contact, unread: data.unread}
      })
    }), filter(x => !!x));
  }


  getSupport = () => {
    if (!this.support) {
      this.support = {
        name: "Let's Build Support",
        members: [this.self.uid],
        organizer: this.self.uid,
        uid: this.self.uid + '-support'
      }
    }
    return this.support
  }

  openSupportChat = () => {
    const source = this.getSupport()
    return this.observeEThree().toPromise().then(() => {
      return openGroup(this.eEThree, source.uid, this.self).then(group => {
        return new DM(this, source.uid, source, group);
      });
    })
  }
  
  openChat = source => {
    return this.observeEThree().toPromise().then(() => {
      let remoteContact = source;
      let channelId;
      //////debugger;
      if (source.isGroup) {
        channelId = source.uid;
        //////debugger;
        return openGroup(this.eEThree, channelId, source.organizer).then(group => {
          return new DM(this, channelId, source, group);
        });
      }  else {
        const uids = [this.self.uid, remoteContact.uid].sort();
        channelId = uids.join("-");
      }
      return this.you(remoteContact.uid).then(you => {
        return new DM(this, channelId, remoteContact, you);
      });
    });
  }

  getChannelFromContact = contact => {
    if (!this.self || !contact.uid) {
      return null;
    }
    if (contact.group) {
      return contact.uid
    }
    const uids = [this.self.uid, contact.uid];
    uids.sort();
    return uids.join("-");
  }
}

class Id {
  encryptFile = file => Promise.resolve(file);
  decryptFile = file => Promise.resolve(file);
  encrypt = text => Promise.resolve(text);
  decrypt = text => Promise.resolve(text);
  encryptMessage = msg => Promise.resolve(msg);
  decryptMessage = msg => Promise.resolve(msg);
  getPublicKey = ignored => null
}

class You {

  constructor(user, uid, publicKey) {
    this.localUser = user;
    this.eThree = user.eThree;
    this.uid = uid;
    this.publicKey = publicKey;
  }

  downloadFile = msg => {
    const publicKey = this.getPublicKey(msg.from);
    const file = msg.files[0];
    return this.doDownloadFile(file, publicKey);
  }
  
  doDownloadFile = (file, publicKey) => {
    const url = file.downloadURL;
    return new Promise((resolve, reject) => {
      return this.localUser.getToken().then(token => {
        const options = {
          headers: {
            'Authorization': token
          }
        }
        return fetch(url, options).then(response => response.blob()).then(blob => {
          this.decryptFile(blob, publicKey).then(result => {
            FileSaver.saveAs(new File([result], file.name, {type: file.type}));
            return resolve();
          });
        });
      });
    });
  }

  resolveFileSrc = (file, publicKey) => {
    if (file.contentType.startsWith("image/svg") ||
        (!file.contentType.startsWith("image/") && !file.contentType.startsWith("video/"))) {
      return Promise.resolve();
    }
    const url = file.downloadURL;
    if (encryptionEnabled && fileEncryptionEnabled) {
      return new Promise((resolve, reject) => {
        return this.localUser.getToken().then(token => {
          const options = {
            headers: {
              'Authorization': token
            }
          }
          return fetch(url, options).then(response => response.blob()).then(blob => {
            if (blob.type.startsWith("text") || blob.type.endsWith("json")) {
              return blob.text().then(text => {
                //////////debugger;
              });
            }
            debugLog("download complete");
            this.decryptFile(blob, publicKey).then(result => {
              file.resolve = null;
              file.src = URL.createObjectURL(new Blob([result], {type: file.contentType}));
              debugLog("decryption complete: ", file);
              if (true) return resolve(file.src);
              const reader = new FileReader();
              reader.addEventListener("load", function () {
                resolve(file.src = reader.result);
              }, false);
              reader.readAsDataURL(result);
            }).catch(err => {
              //debugLog("failed to decrypt file: ", err);
              return null;
            });
          }).catch(err => {
            console.error(err);
            //////////debugger;
            return null;
          });
        });
      });
    }
    return Promise.resolve(file.src = url);
  }

  encryptFile = file => {
    if (!fileEncryptionEnabled) {
      return Promise.resolve(file);
    }
    const publicKey = this.publicKey;
    return this.eThree.encryptFile(file, publicKey).then(blob => {
      const uid = this.localUser.self.uid;
      const timestamp = Date.now();
      const name = uid + "-"+ file.name+ "-"+timestamp;
      const result = new File([blob], name, {type: "application/octet-stream"});
      return Promise.resolve(result);
    });
  }
  
  decryptFile = (file, publicKey) => {
    return this.eThree.decryptFile(file, publicKey);
  }

  encrypt = (text, publicKey) => this.eThree.encrypt(text, publicKey);

  decrypt = (text, publicKey) => this.eThree.decrypt(text, publicKey)

  getPublicKey = from => from == this.uid ? this.publicKey : this.localUser.publicKey;

  encryptMessage = msg => {
    const publicKey = this.publicKey;
    return this.encrypt(msg.text, publicKey).then(encryptedText => {
      const dup = JSON.parse(JSON.stringify(msg));
      if (dup.files) {
        dup.files.map(file => {
          delete file.src;
          delete file.state;
        });
      }
      if (!this.isDev) {
        delete dup.text;
      }
      dup.encryptedText = encryptedText;
      return dup;
    });
  }

  decryptMessage = msg => {
    const publicKey = this.getPublicKey(msg.from);
    const ops = [];
    ops.push(this.decrypt(msg.encryptedText, publicKey).catch (err => {
      debugLog("public key: ", publicKey);
      debugLog("while decrypting: ", msg);
      console.error(err);
      return msg.text;
    }));
    if (true) {
      if (msg.files) msg.files.map(file => {
        if (file.contentType && (file.contentType.startsWith("image/") ||  file.contentType.startsWith("video/"))) {
          file.resolve = () =>this.resolveFileSrc(file, publicKey);
        }
      });
    } else {
      if (msg.files) ops.push(Promise.all(msg.files.map(file => {
        if (file.contentType && (file.contentType.startsWith("image/") ||  file.contentType.startsWith("video/"))) {
          return this.resolveFileSrc(file, publicKey);
        }
        return Promise.resolve();
      })));
    }
    return Promise.all(ops).then(results => {
      const [text, urls] = results;
      delete msg.encryptedText;
      msg.text = text;
      return msg;
    });
  }

  
}

const createGroup = (eThree, contacts, groupId) => {
  return eThree.findUsers(contacts.map(c => c.uid)).then(participants => {
    return eThree.createGroup(groupId, participants);
  });
}

const openGroup = (eThree, groupId, ownerContact) => {
  if (true) {
    return Promise.resolve(new Group(this, groupId, ownerContact));
  }
  return eThree.findUsers(ownerContact.uid).then(card => {
    return eThree.loadGroup(groupId, card);
  });
}

const joinGroup = (eThree, groupId, uid) => {
}

class Group {
  constructor(me, groupId, ownerContact) {
  }
  encryptFile = file => Promise.resolve(file);
  decryptFile = file => Promise.resolve(file);
  encrypt = text => Promise.resolve(text);
  decrypt = text => Promise.resolve(text);
  encryptMessage = msg => Promise.resolve(msg);
  decryptMessage = msg => Promise.resolve(msg);
  getPublicKey = () => null;
}
