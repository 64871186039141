/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { FDOKCancel } from '../OKCancel'
import Spinner from '../../assets/Icons/spinner.svg'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import './index.css'

export class FDPopup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false,
      fail: false
    }
  }

    done = () => {
      this.setState({
        busy: true
      })
      let p = this.props.done()
      if (!p || !p.then) {
        p = Promise.resolve()
      }
      return p.then(() => {
        this.setState({
          busy: false
        })
      }).catch(err => {
        console.error(err)
        this.setState({
          fail: true
        })
      })
    }

    render () {
      let className = 'fdPopupContainer'
      if (this.state.busy) {
        className += ' fdPopupContainerBusy'
      }
      let bodyClassName = 'fdPopupBody'
      const showHeader =
            this.props.title || this.props.cancel || this.props.done
      if (!showHeader) {
        bodyClassName += ' fdPopupBodyNoHeader'
      }
      if (this.props.className) {
        className += ' ' + this.props.className
      }
      return (
        <div className={className}>
          <div className='fdPopup'>
            <div className='fdPopupPopupBump' />
          <div className='fdPopupHeader' style={showHeader ? null : {display: 'none'}}>
              <mobiscroll.Button onClick={this.props.cancel}>{this.props.cancelLabel || 'Cancel'}</mobiscroll.Button>
              <div className='fdPopupHeaderTitleContainer'>
                <div className='fdPopupHeaderTitle'>{this.props.title}</div>
          <div className='fdPopupHeaderSpinner' style={this.state.busy ? null : { visibility: 'hidden' }}><ReactSVG src={Spinner} /></div>
              </div>
              <div className='fdPopupHeaderDone' style={this.props.done ? null : { visibility: 'hidden' }}>
                <mobiscroll.Button onClick={this.done}>{this.props.doneLabel || 'Done'}</mobiscroll.Button>
              </div>
            </div>
            <div className='fdPopupScroller'>
          <div className={bodyClassName}>
              {this.props.children}
            </div>
          </div>
          </div>
          {this.state.fail &&
            <FDOKCancel
              title='Operation Unsuccessful'
              message='Please try again later.'
              ok={() => this.setState({ fail: false })}
              okLabel='OK'
            />}
        </div>
      )
    }
}
