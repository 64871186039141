import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import Close from "../../assets/icons/Cross.svg";
import './index.css'


export class UIPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alpha: 0
        }
        console.log("page created: ", this);
    }

    componentDidMount() {
        console.log("page mounted: ", this);
        const subPage = this.props.subPage;
        if (subPage) {
            this.slide(1);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("should update:, ", this.props, " <-> ", nextProps);
        return true;
    }

    componentDidUpdate(prevProps) {
        console.log("page updated: ", this);
        const subPage = this.props.subPage;
        if ((subPage  && !prevProps.subPage) || (!subPage && prevProps.subPage)) {
            if (subPage) {
                this.slide(1);
            } else {
                this.slide(0);
            }
        } 
    }

    getAlpha = () => {
        return this.state.alpha;
    }

    slide = (target) => {
        console.log("slide: ", target, ": ", (this.props.subPage || this.state.subPage));
        let start = Date.now();
        const dur = 600;
        let end = start + dur;
        this.timer = setInterval(() => {
            requestAnimationFrame(() => {
                let elapsed = (Date.now() - start);
                let t = Math.min(elapsed/dur, 1.0);
                t = 1-Math.pow(1-t, 7);
                let updates = {
                    alpha: target ? t : 1.0-t
                };
                if (target) {
                    if (this.props.subPage) {
                        if (!this.state.subPage) {
                            updates.subPage = this.props.subPage;
                        }
                    }
                } else {
                    if (updates.alpha == 0) {
                        updates.subPage = null;
                    }
                }
                this.setState(updates,() => {
                    if (this.props.onAlphaChange) this.props.onAlphaChange(this.state.alpha);
                });
                if (t == 1) clearInterval(this.timer);
            });
        }, 16);
    }
    
    render() {
        const subPage = this.props.subPage || this.state.subPage;
        let pageStyle;
        console.log("render: ", this.state.alpha, ": subPage: ", subPage);
        if (subPage) {
            let x = -(this.state.alpha*100) + "%";
            pageStyle = {transform: "translate("+x+", 0)"};
        }
        let className = 'uiPage';
        if (this.props.hideHeader) {
            className += " uiPageNoHeader";
        }
	return  <div className={className}>
            {!this.props.hideHeader && <div className='uiPageHeader'>

            <div className='uiPageHeaderClose' onClick={this.props.close}>
            <ReactSVG src={Close}/>
            </div>

            <div className='uiPageHeaderInfo'>

        {this.props.icon && <div className='uiPageHeaderIcon'>
            <ReactSVG src={this.props.icon}/>
         </div>}

            <div className='uiPageHeaderTitle'>
            {this.props.title}
            </div>
            <div className='uiPageHeaderSubtitle'>
            {this.props.subtitle}
            </div>
            </div>
             </div>}
            <div className='uiPageBody' style={pageStyle}>
            {this.props.children}
            {subPage && <div className='uiPageSubpage'>
            {subPage}
             </div>}
            </div>
            {this.props.footer && <div className='uiPageFooter'>
            {this.props.footer}
             </div>}
            </div>
    }
}
