import React, { Component } from 'react';
import {Button} from './Button'
import "./IncomingCall.css"

export class IncomingCall extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return <div className='incomingCall'>
            <div className='incomingCallFields'>
            <div className='incomingCallTitle'>{"Incoming Call"}</div>
            <div className='incomingCallSubtitle'>{"Call from "+this.props.contact.displayName}</div>
            <div className='buttonContainer incomingCallButtonContainer'>
            <Button label={'Decline'} action={this.props.declineCall}/>
            <Button label={'Answer'} action={this.props.answerCall}/>
            </div>
            </div>
            </div>
    }
}
