/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { FDOKCancel } from '../OKCancel'
import Search from '../../assets/Icons/Search.svg'
import Clear from '../../assets/Icons/Clear.svg'
import Spinner from '../../assets/Icons/spinner.svg'
import { InputField } from '../InputField'
import './index.css'

export class FDScopeButtons extends Component {
  onClick = (e, button) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onClick(button)
  }

  render () {
    return (
      <div className='fdScopeButtons'>
        {this.props.scopeButtons.buttons.map((button, i) => {
          let className = 'fdScopeButton' + (button.value === this.props.scopeButtons.selection() ? ' fdScopeButtonSelected' : '')
          if (i === 0) className += ' fdScopeButtonLeft'
          return (
            <div key={button.value} className={className} onClick={(e) => this.onClick(e, button)}>
              {button.name}
            </div>
          )
        })}
      </div>
    )
  }
}

export class FDSearchField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: 0
    }
  }

  clear = () => {
    this.onChange('search', '')
  }

  onChange = (field, value) => {
    this.state.busy++
    this.forceUpdate()
    this.props.search(value).then(() => {
      this.state.busy--
      this.forceUpdate()
    }).catch(err => {
      console.error(err)
      this.state.busy--
      this.state.fail = true
      this.forceUpdate()
    })
  }

  clickScopeButton = (scopeButtons, button) => {
    scopeButtons.select(button)
  }

  onFocus = () => this.setState({ focused: true })
  onBlur = () => this.setState({ focused: false })

  render () {
    const busy = this.state.busy > 0
    return (
      <div className='fdSearchFieldContainer'>
        <div className={'fdSearchField' + (busy ? ' fdSearchFieldBusy' : '')}>
          <div className='fdSearchFieldIcon'><ReactSVG src={busy ? Spinner : Search} /></div>
        <InputField value={this.props.searchValue} onFocus={this.onFocus} onBlur={this.onBlur} name='search' type='text' placeholder={this.props.placeholder || 'Search'} onChange={this.onChange} />
          {this.props.searchValue && <div className='fdSearchFieldClear' onClick={this.clear}><ReactSVG src={Clear}/></div>}
          <div className='fdSearchFieldIconButton'>{this.props.searchAction}</div>
          {this.state.fail && <div className='fdSearchFieldPopup'>
            <FDOKCancel
              title='Operation Unsuccessful'
              message='Please try again later.'
              ok={() => this.setState({ fail: false })}
              okLabel='OK'
            />
                              </div>}
        </div>
        {this.props.scopeButtons && <div className='fdScopeButtonsList'>{
          this.props.scopeButtons.map((scopeButtons, i) => <FDScopeButtons key={i} scopeButtons={scopeButtons} onClick={button => this.clickScopeButton(scopeButtons, button)} />)
        }
        </div>}
      </div>
    )
  }
}
