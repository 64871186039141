import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import OpenAIApi from 'openai-api'
import axios from 'axios'
import './index.css'

const capitalize = x => x ? x[0].toUpperCase() + x.substring(1) : x

const url = 'https://api.openai.com/v1/engines/text-davinci-edit-001/edits'
const OPENAI_API_KEY='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'

const Auth = 'Bearer ' + OPENAI_API_KEY

const edit = async (instruction, input) => {
  console.log(url)
  const arg = {
    input, instruction, temperature: 0, top_p: 1
  }
  console.log(arg)
  const result = await axios.post(url, arg,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': Auth
    }
  })
  const data =  result.data
  console.log(data)
  return data.choices[0].text
}

export class UIVoiceEditor extends Component {

  constructor (props) {
    super(props)
    this.state = {
      output: '',
      prompt: '',
      prev: ''
    }
  }

  componentDidMount() {
    // this.evtSource = new EventSource(GPT3_URL)
    this.start()
  }

  componentWillUnmount() {
    this.stop()
  }

  onMessage = event => {
  }

  history = []
  historyPtr = -1

  advance = output => {
    this.history.push(output)
    this.historyPtr++
    this.history.length = this.historyPtr + 1
  }

  undo = () => {
    this.historyPtr--
  }

  redo = () => {
    this.historyPtr++
  }

  canRedo = () => {
    return this.historyPtr + 1 < this.history.length
  }

  canUndo = () => {
    return this.historyPtr >= 0
  }

  current = () => {
    return this.history[this.historyPtr] || ''
  }

  send = async prompt0 => {
    switch (prompt0.toLowerCase()) {
      case 'revert':
      case 'undo':
        if (this.canUndo()) {
          this.undo()
          this.setState({
            output: this.current()
          })
        }
        return
      case 'redo':
        if (this.canRedo()) {
          this.redo()
          this.setState({
            output: this.current()
          })
        }
        return
    }
    let output = this.current()
    const completion = await edit(prompt0, output)
    output = completion.trim()
    if (output == `"".`) {
      output = ""
    }
    this.advance(output)
    console.log("completion:", completion)
    if (true) {
      this.setState({
        err: '',
        output: output
      })
    } else {
      this.setState({
        err: completion
      })
    }
  }

  onResult = event => {
    let prompt = this.state.prompt
    console.log("onResult", event)
    for (var i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        prompt += event.results[i][0].transcript
        const msg = capitalize(prompt.trim())
        this.setState({
          voiceInput: msg
        })
        this.send(msg)
        prompt = ''
      }
    }
    this.setState({
      prompt: capitalize(prompt)
    })
  }

  stop = () => {
    if (this.recognition) {
      this.recognition.stop()
    }
  }

  start = () => {
    if (!this.recogition) {
      const recognition = new window.webkitSpeechRecognition()
      recognition.continuous = true
      recognition.interimResults = false
      recognition.onstart = this.onStart
      recognition.onresult =  this.onResult
      recognition.onerror = this.onError
      recognition.onend = this.onEnd
      this.recognition = recognition
    }
    this.recognition.start()
  }

  onError = event =>{
    console.log('error:', event)
  }

  onEnd = event =>{
    console.log('end:', event)
    this.start()
  }
  
  
  render() {
    return <div className='uiVoiceEditor'>
      <div className='uiVoiceEditorPrompt'>
      {this.state.voiceInput}
      </div>
      <div className='uiVoiceEditorErr'>
      {this.state.err}
      </div>
      <div className='uiVoiceEditorDocument'>
      {this.state.output}
    </div>
      </div>
  }
}


