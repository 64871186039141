import React, { Component } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Cleave from 'cleave.js/react'
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';
import moment from 'moment';
import "./InputField.css"
import googleLibPhoneNumber from 'google-libphonenumber'
import phone from "phone"
const PNF = googleLibPhoneNumber.PhoneNumberFormat;
const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();

const AmPmSelector = props => {
    return <div className='inputFieldAmPmSelector' onClick={()=>props.onChange(!props.value)} >{props.value ? "AM" : "PM"}</div>;
}


export class InputFieldSelect extends Component {
    constructor(props) {
        super(props);
    }
    onChange = e => {
        this.props.onChange(this.props.name, e.target.value);        
    }
    render() {
        if (!this.props.options.map) {
            debugger;
        }
        const wrap = x => {
            if (!this.props.tooltip) return x;
            return <Tooltip key={this.props.name} enterDelay={750} title={this.props.tooltip}>{x}</Tooltip>;
        }
            
        return <div key={this.props.name} className='inputFieldSelect'>
            <div className='inputFieldSelectLabel'>{this.props.label}</div>
            <div className='inputFieldSelectContainer'>
            {wrap(<select className='inputFieldSelectSelect' value={this.props.value} onChange={this.onChange}>
            {this.props.options.map(option => {
                    return <option value={option.value}>{option.name}</option>
             })}
             </select>)}
            </div>
            </div>
    }
}



export class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: "us",
        }
    }
    onChange = e => {
        this.props.onChange(this.props.name, e.target.value);        
    }
    focus = () => {
        this.input.focus();
    }
    setInputRef = x => {
        if (x && x != this.input) {
            this.input = x;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (false && this.props.type == 'tel') {
            if (prevProps.value != this.props.value) {
                if (!this.state.value || !prevProps.value) {
                    try {
                        const number = phoneUtil.parseAndKeepRawInput(this.props.value, 'US');
                        console.log(number.getCountryCode());
                        console.log(number.getNationalNumber());
                        this.setState({
                            value: "+" + number.getCountryCode() + " " + phoneUtil.format(number, PNF.NATIONAL)
                        });
                    } catch (ignored) {
                    }
                } else {
                    this.setState({
                        value: this.props.value
                    })
                }
                return;
            }
        }
    }

    getTimeValue = () => {
        const am = this.getAm();
        let hrs = this.props.value.getHours();
        const mins = this.props.value.getMinutes();
        debugger;
        if (!am) {
            hrs -= 12;
        }
        if (hrs == 0) hrs = 12;
        return hrs.toFixed(2) + ":" + mins.toFixed(2);
    }

    getAm = () => {
        return moment(this.props.value).format("a") == "am";
    }

    onChangeTime = (rawValue, value) => {
        let [hrs, mins] = rawValue.split(':');
        const am = this.getAm();
        const date = new Date(this.props.value);
        if (!am) {
            hrs += 12;
        }
        if (hrs == 24) hrs = 0;
        date.setHours(hrs)
        date.setMinutes(mins);
        return this.props.onChange(this.props.name, date);
    }

    onChangeAm = (am) => {
        const date = new Date(this.props.value);
        let hrs = this.props.value.getHours();
        if (!am) {
            hrs += 12;
        } else {
            hrs -= 12;
        }
        date.setHours(hrs);
        debugger;
        return this.props.onChange(this.props.name, date);
    }
    
    render() {
        const showLabel = this.props.type == 'number' || this.props.type == 'date' || this.props.type == 'time'
        const input = (className) => {
            if (this.props.type == 'time') {
                return <div className='inputFieldTimeInputContainer'><Cleave className={'inputFieldInput inputFieldTimeInput'} value={this.getTimeValue()}  options={{time: true, timeFormat: '12', timePattern:this.props.timePattern}} onChange={e => this.onChangeTime(e.target.rawValue, e.target.value)}/><AmPmSelector onChange={this.onChangeAm} value={this.getAm()}/></div>
            }
            if (this.type == 'date') {
                return <Cleave className={'inputFieldInput inputFieldDateInput'} placeholder={this.props.label} value={this.props.value} options={{date: true, datePattern:this.props.datePattern}} onChange={e => this.props.onChange(this.props.name, e.target.rawValue)}/>
            }
            if (this.props.type == "tel") {
                return <Cleave className={'inputFieldInput inputFieldPhoneInput'} placeholder={this.props.label} value={this.props.value} options={{phone: true, phoneRegionCode: "Us"}} onChange={e => this.props.onChange(this.props.name, e.target.rawValue)}/>
            }
            if (this.props.type == 'currency') {
              //return <CurrencyInput placeholder={this.props.label} prefix={'$'} allowDecimals={false} defaultValue={this.props.value} currencyDisplay="symbol" className={className} currency='USD' onChange={(value, name) => this.props.onChange(this.props.name, value)}/>
            }
            return <input name={this.props.name} readOnly={this.props.readOnly} min="0.00" step="1.00" max="1000" ref={x => this.setInputRef(x)} autoFocus={this.props.autoFocus} placeholder={!showLabel ? this.props.label: ""} className={className} type={this.props.type} autoComplete={this.props.autoComplete} value={this.props.value} onChange={this.onChange}/>;
        }
        let inputClassName = 'inputFieldInput';
        if (showLabel) {
            if (this.props.type == 'date') {
                inputClassName = 'inputFieldInput inputFieldDateInput';
            } else {
                inputClassName = 'inputFieldInput inputFieldNumberInput';
            }
        }
        const wrap = x => {
            let tooltip = this.props.tooltip || this.props.value && this.props.label || '';
            return <Tooltip enterDelay={750} placement={'left'} title={tooltip}>{x}</Tooltip>;
        }
        let style;
        if (this.props.readOnly && !this.props.value) style =  {display: "none"};
        else if (!this.props.onChange) style = {visibility: 'hidden'};
        return wrap(<div className='inputField' style={style}>
            <div className='inputFieldAndLabel'>
            {showLabel && <div className='inputFieldLabel'>{this.props.label}</div>}
            {input(inputClassName)}
            {this.props.type == "checkbox" && <label htmlFor={this.props.name} className='inputFieldCheckboxLabel'>{this.props.label}</label>}
        </div>
         </div>);
    }
}

export class AutoCompleteField extends Component {
    constructor(props) {
        super(props);
    }
    onChange = e => {
        this.props.onChange(this.props.name, e.target.value);        
    }
    focus = () => {
        this.input.focus();
    }
    setInputRef = x => {
        if (x && x != this.input) {
            this.input = x;
        }
    }
    onComplete = (completion) => {
        this.props.onChange(this.props.name, completion);
    }
    render() {
        return <div className='autocompleteField' style={this.props.readOnly && !this.props.value ? {display: "none"} : null} >
            <InputField readOnly={this.props.readOnly} min="0.00" step="1.00" max="1000" ref={x => this.setInputRef(x)} autoFocus={this.props.autoFocus} placeholder={this.props.label} className='inputFieldInput' type={this.props.type} autoComplete={this.props.autoComplete} value={this.props.value} onChange={this.onChange}/>
            <div className='autocompleteFieldAutocomplete'>
            {this.props.completions.map(completion => <div key={completion} onClick={this.onComplete(completion)}
                                        className='autoCompleteFieldCompletion'>{completion}</div>)}
        </div>
      </div>
    }
    
}
