import React, { Component } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {ElementsConsumer, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import "./ContactInfo.css"
import {Button} from './Button'
import {isDesktop} from "./Platform"
import {InputField} from './InputField'
import {ShowLink} from './ShowLink'
import {AddProductView, ProductView} from "./ProductView";
import {UserPaymentMethod} from "./PaymentMethod"
import {StripeButton} from "./StripeButton";
import Clipboard from "./Clipboard";


class ContactInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactInfoTitle: "Contact Information",
            products: [],
        };
        if (this.props.contact) {
            this.state.email = this.props.contact.email;
            this.state.name = this.props.contact.displayName;
            if (this.state.name == this.state.email) {
                this.state.name = "";
            }
            
        }
    }
    
    onReturn = e => {
        if (e.keyCode === 13) {
            this.props.contactInfo(this.state);
        }
    }

    componentDidMount() {
        if (isDesktop()) {
            window.addEventListener("keydown", this.onReturn);
        }
    }
    componentWillUnmount() {
        if (isDesktop()) {
            window.removeEventListener("keydown", this.onReturn);
        }
    }
    onChange=(field, value) => {
        const update = {};
        update[field] = value
        this.setState(update);
    }

    updateContact = () => {
    }

    renderSpecificFields() {
        return null;
    }
    
    renderSpecific() {
        return null;
    }
    renderPaymentMethod() {
        const paymentMethod = this.state.paymentMethod;
        if (paymentMethod) {
                return <div className='cardName'><p>{paymentMethod.card.brand} ending in {paymentMethod.card.last4}</p><Button label={"Remove"} action={this.removeCard}/></div>
        }
        return <ElementsConsumer>
            {({elements, stripe}) => {
                const CARD_OPTIONS = {
                    iconStyle: 'solid',
                    style: {
                        base: {
                            iconColor: 'gray',
                            color: 'gray',
                            fontWeight: 500,
                            fontSize: '14px',
                            fontSmoothing: 'antialiased',
                            ':-webkit-autofill': {
                                color: 'blue',
                            },
                            '::placeholder': {
                                color: 'gray'
                            },
                        },
                        invalid: {
                            iconColor: '#ffc7ee',
                            color: '#ffc7ee',
                        },
                    },
                };
                // stripe wtfs
                this.elements = elements;
                this.stripe = stripe;
                
                return <div className='stripeClientConnectForm'>
                    <div className='stripeConnectFormCard'><CardElement options={CARD_OPTIONS}/></div>
                    </div>
            }}
        </ElementsConsumer>;
    }


    update = async () => {
        if (!this.state.paymentMethod) {
            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = this.elements.getElement(CardElement);
            
            const {error, paymentMethod} = await this.stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (error) {
                console.log('[error]', error);
            } else {
                console.log('[PaymentMethod]', paymentMethod);
                this.state.paymentMethod = paymentMethod;
            }
        }
        if (true || this.state.paymentMethod) {
            return this.props.me.updateAccount(this.state);
        }
    }
    
    render() {
        return <div className='contactInfo'>
            <div className='contactInfoFields'>
            <div className='contactInfoTitle'>{this.state.contactInfoTitle}</div>
            <InputField readOnly={this.props.readOnly} label={'First Name'} value={this.state.firstName} name='firstName' type={'name'} autoComplete={'given-name'} onChange={this.onChange}/>
             <InputField readOnly={this.props.readOnly} label={'Last Name'} value={this.state.lastName} name='lastName' type={'name'} autoComplete={'family-name'} onChange={this.onChange}/>
            <InputField readOnly={this.props.readOnly} label={'Display Name'} value={this.state.name} name='name' type={'name'} autoComplete={'name'} onChange={this.onChange}/>
            <InputField readOnly={this.props.readOnly} label={'Title'} value={this.state.title} name='title' autoComplete={'organization-title'} type={'name'} onChange={this.onChange}/>
            <InputField readOnly={this.props.readOnly} label={'Organization'} value={this.state.organization} name='organization' type={'text'} autoComplete={"organization"} onChange={this.onChange}/>
            <InputField readOnly={this.props.readOnly} label={'Email Address'} value={this.state.email} name='email' type={'email'} autoComplete={'email'} onChange={this.onChange}/>
            <InputField readOnly={this.props.readOnly} label={'Phone Number'} value={this.state.phoneNumber} name='phoneNumber' type={'tel'} autoComplete={'tel'} onChange={this.onChange}/>
            {this.renderSpecificFields()}
            <div className='buttonContainer contactInfoButtonContainer'>
            {!this.props.readOnly && <Button label={"Update"} action={this.update}/>}
            <Button label={this.props.backLabel} action={this.props.backAction}/>
            </div>
            {this.renderSpecific()}
            <div className='buttonContainer contactInfoButtonContainer'>

            </div>
            <div className='contactInfoError'>{this.props.error}</div>            
            </div>
            {this.state.progressIndicator && <div key='progressFun' className='signIn' >
                <div className='signInProgress'>{this.state.progressIndicator}</div>
             </div>}
            </div>;
    }
}

export class MyContactInfo extends ContactInfo {
    constructor(props) {
        super(props);
        this.state.needsStripeConnect = true;
        this.state.address = "";
        this.state.city = "";
        this.state.state = "";
        this.state.zip = "";
        this.state.country = "";
        this.state.contactInfoTitle = "Account Information";
        this.state.products = [];
        if (this.props.me.accountData) {
            for (var i in this.props.me.accountData) {
                this.state[i] = this.props.me.accountData[i];
            }
        }
    }

    componentDidMount() {
        this.productSub = this.props.me.observeMyProducts().subscribe(change => {
            const product = change.data;
            if (change.type == "removed") {
                this.state.products = this.state.products.filter(x => x.productId != product.productId);
            } else {
                this.state.products.push(product);
            }
            this.forceUpdate();
        });
        super.componentDidMount();
    }

    componentWillUnmount() {
        this.productSub.unsubscribe();
        return super.componentWillUnmount();
    }

    toggleAddProduct = () => {
        this.setState({
            progressIndicator: null,
            addingProduct: !this.state.addingProduct
        });
    }

    productErr = err => {
        this.setState({
            addProductError: err
        });
    }


    handleAddProduct = product => {
        console.log("product: ", product);
        if (!product.name) return this.productErr("Description is required");
        debugger;
        if (!product.details.unitPrice) return this.productErr("Price is required");
        this.productErr(null);
        this.setState({
            progressIndicator: "Creating Product",
        });
        this.props.me.addProduct(product).then(result => {
            if (result) {
                const data = result.data;
                if (data.error) {
                    this.setState({
                        addProductError: data.error,
                        progressIndicator: null,
                    });
                }
            }
            this.toggleAddProduct();
        }).catch(err => {
            console.error(err);
            this.setState({
                progressIndicator: null,
                addProductError: "Internal error creating product"
            });
        });
    }

    stripeConnect = () => {
        if (true) {
            this.setState({
                needsStripeConnect: false
            });
            return;
        }
        this.props.stripeConnect(this.state)
    }
    

    renderConnectToStripe() {
        return [<div className='stripeConnectMessage'><p>To enable your paying clients on Tete, first connect your business to Stripe</p></div>,
                <div className='stripeButtonContainer'>
                <StripeButton autofill={this.state} observeStripeAuth={this.props.observeStripeAuth}
                action={this.stripeConnect}/>
                </div>];
    }

    setupPaymentMethod = paymentMethod => {
    }

    setSubmit = f => {
        this.submit = f;
    }

    removeCard = () => {
        this.setState({
            paymentMethod: null
        });
    }

    renderSpecificFields() {
        const result = [
        <InputField readOnly={this.props.readOnly} label={'Street Address'} value={this.state.streetAddress}
            name='streetAddress' type={'text'} autoComplete={'street-address'} onChange={this.onChange}/>,
        <InputField readOnly={this.props.readOnly} label={'City'} value={this.state.city}
            name='city' type={'text'} autoComplete={'locality'} onChange={this.onChange}/>,
        <InputField readOnly={this.props.readOnly} label={'State'} value={this.state.state}
            name='state' type={'text'} autoComplete={'region'} onChange={this.onChange}/>,
        <InputField readOnly={this.props.readOnly} label={'Zip Code'} value={this.state.zip}
            name='zip' type={'text'} autoComplete={'postal-code'} onChange={this.onChange}/>,
        <InputField readOnly={this.props.readOnly} label={'Country'} value={this.state.country}
            name='country' type={'text'} autoComplete={'country'} onChange={this.onChange}/>,
        <InputField readOnly={this.props.readOnly} label={'Date of Birth'} value={this.state.bday}
            name='bday' type={'date'} autoComplete={'bday'} onChange={this.onChange}/>,
            <Tooltip enterDelay={750} placement={'left'} title={"Payment method"}>
                {this.renderPaymentMethod()}
            </Tooltip>,
        <div className='insCovGroup'>
        <InputField readOnly={this.props.readOnly} label={'Enable Insurance Coverage Estimation for my Clients'} value={this.state.state}
            name='therapist' type={'checkbox'} onChange={this.onChange}/>
        <InputField readOnly={this.props.readOnly} label={'State License #'} value={this.state.stateLicenseNumber}
            name='stateLicenseNumber' type={'text'} onChange={this.onChange}/>
        <InputField readOnly={this.props.readOnly} label={'NPI #'} value={this.state.npiNumber}
            name='npiNumber' type={'text'} onChange={this.onChange}/>
        <InputField readOnly={this.props.readOnly} label={'Tax Id #'} value={this.state.taxId}
            name='taxId' type={'text'} onChange={this.onChange}/>
        </div>]
        return result;
    }

    showLink=(product) => {
        this.setState({
            link: product,
        });
    }

    formLinkURL = (product) => window.origin + '?sign-up='+product.productLink;

    copyLink = (link) => {
        Clipboard.copy(link);
        this.showLink(null);
    }

    removeProduct = product => {
        this.setState({
            progressIndicator: "Deleting Product"
        });
        return this.props.me.deleteProduct(product.productId).then(() => {
            this.setState({
                progressIndicator: null
            });
        });
    }

    renderSpecific() {
        //if (!this.props.stripeAuth) return this.renderConnectToStripe();
        return <div className='myContactInfo'>
            {this.state.link &&  <ShowLink link={this.formLinkURL(this.state.link)} title={this.state.link.name} subtitle={"Share this link with your clients to sign-up for this product"} copyLink={this.copyLink} done={()=>this.showLink(null)}/>}

        {this.state.addingProduct && <AddProductView error={this.state.addProductError} back={this.cancelAddProduct} add={this.handleAddProduct} back={this.toggleAddProduct}/>}
            <div className='stripeButtonConnectedContainer'>
            <StripeButton forceConnected={true}/>
            </div>
            <div className='addProductButtonContainer'>
            <div className='myTeteProductsTitle'>My TeTe Products</div>
            <Button label={"Create Product"} action={this.toggleAddProduct}/>
            </div>
            <div className="myContactInfoProducts">
            {this.state.products.map(product => {
                const onChange=(field, value) => {
                    product[field] = value;
                }
                return <div className='productListItem'>
                         <ProductView product={product} onChange={onChange}/>
                         <div className='productButtonContainer'>
                           <Button label={"Share"} action={ ()=>this.showLink(product) }/>
                           <Button label={"Remove"} action={()=>this.removeProduct(product)}/>
                         </div>
                    </div>
            })}
        </div>
            </div>

    }
}


export class YourContactInfo extends ContactInfo {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();
        debugger;
        this.productSub = this.props.me.observePurchased(this.props.contact).subscribe(change => {
            const product = change.data;
            debugger;
            if (change.type == "removed") {
                this.state.products = this.state.products.filter(x => x.productId != product.productId);
            } else {
                this.state.products.push(product);
            }
            this.forceUpdate();
        });
        this.accountSub = this.props.me.observeAccount().subscribe(accountData => {
            this.state.paymentMethod = accountData.paymentMethod;
        });
    }

    componentWillUnmount() {
        if (this.productSub) this.productSub.unsubscribe();
        if (this.accountSub) this.accountSub.unsubscribe();
        return super.componentWillUnmount();
    }

    renderSpecific() {
        if (this.state.buying) {
            //return <CheckoutForm buying={this.state.buying}/>
        }
        let availableProducts = this.state.products;
        const decline = (product) => {
        }
        const buy = async product => {
            let paymentMethodId;
            if (product.details.billing.value == "subscription") {
                if (!this.state.paymentMethod) {
                    // Get a reference to a mounted CardElement. Elements knows how
                    // to find your CardElement because there can only ever be one of
                    // each type of element.
                    const cardElement = this.elements.getElement(CardElement);
                    const {error, paymentMethod} = await this.stripe.createPaymentMethod({
                        type: 'card',
                        card: cardElement,
                    });
                    if (error) {
                        console.log('[error]', error);
                    } else {
                        console.log('[PaymentMethod]', paymentMethod);
                        this.state.paymentMethod = paymentMethod;
                    }
                }
                if (this.state.paymentMethod) {
                    this.setState({paymentErr: null});
                    paymentMethodId = this.state.paymentMethod.id;
                } else {
                    this.setState({paymentErr: product, paymentErrMsg: "Payment method is required"});
                    return;
                }
            }
            this.setState({
                progressIndicator: "Signing up",
                paymentErr: null
            });
            this.props.me.acceptOffer(product.productId, paymentMethodId).then(result => {
                console.log(result);
                this.setState({
                    progressIndicator: null,
                    paymentErr: result.error 
                });
                if (result.clientSecret) {
                    return this.stripe.confirmCardPayment(result.clientSecret).then(result => {
                        console.log("confirmed payment: ", result);
                    });
                }
            }).catch (err => {
                console.error(err);
                this.setState({
                    paymentErr: "Internal error"
                });
                
            });
            this.forceUpdate();
        }
        const checkInsurance = product => {
            product.purchased = !product.purchased;
            debugger;
            this.forceUpdate();
        }

        const renderPurchaseMethod = (product) => {
            console.log("product: ", product);
            if (product.details.billing.value == "subscription") {
                return <div className='purchaseMethodContainer'>
                    <div className='purchaseMethodDescription'>Your payment method</div>
                    {this.renderPaymentMethod()}
                </div>
            }
            return null;
        }

        
        const renderAvailableProducts = (buy) => {
            if (availableProducts) {
                return availableProducts.map(product => {
                    const onChange=(field, value) => {
                        product[field] = value;
                        this.forceUpdate();
                    }
                    return <div className='productListItem'>
                        <ProductView product={product} onChange={onChange}/>
                        {renderPurchaseMethod(product)}
                        <div className='purchaseButtonContainer'>
                        {product.state == "purchased" ? <div className='productAccepted'>Accepted</div> :
                         [<Button label={"Accept"} action={()=>buy(product)}/>,
                          <Button label={"Decline"} action={(() => decline(product))}/>]}
                    </div>
                    
                        {this.state.paymentErr == product && <div className='signUpError'>{this.state.paymentErrMsg}</div>}  

                        </div>
                });
            }
            return null;
        }
        if (availableProducts && availableProducts.length > 0) {
            return <div className='contactProducts'>
                <div className='paymentMethodTitle'>TeTe Products Offered to you by {this.props.contact.displayName}</div>
                {renderAvailableProducts(buy)}
            </div>;
        }
        return null;
    }
}

