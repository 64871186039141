/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ReactSVG } from 'react-svg'
import { isAndroid, isIPad, isDesktop } from '../../classes/Platform'
import BackArrow from '../../assets/Icons/BackArrow.svg'
import { FDUserProfileIcon } from '../UserProfileIcon'
import { FDSearchField } from '../SearchField'
import { getPortal } from '../Client'
import Arrow from '../../assets/Icons/MoreArrow.svg'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import './index.css'
import lerpColor from '@sunify/lerp-color'
import './index.css'
import ReactSwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'

export class Subpages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this.removedPages = []
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pages.length > this.props.pages.length) {
      // page removed
      this.removedPages = [prevProps.pages[prevProps.pages.length-1]]
      console.log("removedPages:", this.removedPages)
      this.forceUpdate()
    } 
  }
  
  onChangeIndex = index => {
  }

  onTransitionEnd = () => {
    console.log("transition end", this.removedPages.length)
    this.removedPages = []
    this.forceUpdate()
  }
  
  render() {
    let index = 0
    if (this.props.pages) {
      index = this.props.pages.length-1
    }
    let pages = this.props.pages.concat(this.removedPages)
    console.log("render pages:", pages)
    return <div className='uiSwipePage'>
      <ReactSwipeableViews
    onChangeIndex={this.onChangeIndex}
    onTransitionEnd={this.onTransitionEnd}
    index={index}
    disabled={true}
    enableMouseEvents={false} ignoreNativeScroll={false}>
      {pages}
    </ReactSwipeableViews>
      </div>
  }
}

export class FDInputField extends Component {
    onChange = e => {
      if (this.props.onChange) {
        this.props.onChange(this.props.name, e.target.value)
      }
      this.props.form[this.props.name] = e.target.value
      this.forceUpdate()
    }

    render () {
      const label = this.props.label
      const name = this.props.name
      const onChange = this.onChange
      const err = this.props.formErr[name]
      const value = this.props.form[name]
      return <mobiscroll.Input min={this.props.min} max={this.props.max} autoComplete={this.props.autoComplete} valid={!err} errorMessage={err} value={value} onChange={onChange} placeholder={label} disabled={!!this.props.readOnly} />
    }
}

export class FDArrowField extends Component {
  render () {
    return (
      <div className='fdArrowField' onClick={this.props.onClick}>
        <div className='fdArrowFieldLabel'>{this.props.label}</div>
        <div className='fdArrowFieldArrow'><ReactSVG src={Arrow} /></div>
      </div>
    )
  }
}


let pageScroller = {
  scrollTop: 0
}

export const setPageScroller = n => {
  pageScroller = n
}

export class FDPageHeaderButtons extends Component {
  render () {
    return (
        <div className={'fdPageHeaderButtons'} >
        <div className='fdPageBack' onClick={this.props.back} style={this.props.back ? null : { visibility: 'hidden' }}>
          <div className='fdPageBackIcon'><ReactSVG src={BackArrow} /></div>
          <div className='fdPageBackLabel'>{this.props.backLabel || 'Back'}</div>
        </div>
        <div className='fdPageHeaderTitle'>{this.props.title}</div>
        {!this.props.cancel && !this.props.next && <div className='fdPageHeaderSpacer' />}
        <div className='fdPageCancel' onClick={this.props.cancel} style={this.props.cancel ? null : { display: 'none' }}>
          <div className='fdPageCancelLabel'>{this.props.cancelLabel || 'Cancel'}</div>
        </div>
        <div className='fdPageNext' onClick={this.props.next} style={this.props.next ? null : { display: 'none' }}>
          <div className='fdPageNextLabel'>{this.props.nextLabel || 'Next'}</div>
        </div>
      </div>
    )
  }
}

export class FDPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      alpha: 0
    }
    if (!this.props.me) {
      debugger
    }
  }

  componentDidMount () {
    if (this.props.onCreate) this.props.onCreate(this)
    const popup = this.props.popup
    if (popup) this.slide(1)
  }

  componentDidUpdate (prevProps) {
    const subPage = this.props.subPage
    ////console.log('subpage:', subPage)
    ////console.log('state.subpage:', this.state.subPage)
    if ((subPage && !prevProps.subPage) || (!subPage && prevProps.subPage)) {
      if (subPage) {
        this.slide(1)
      } else {
        this.slide(0)
      }
    }
    const popup = this.props.popup
    if ((popup && !prevProps.popup) || (!popup && prevProps.popup)) {
      if (popup) {
        this.slide(1)
      } else {
        this.slide(0)
      }
    }
    if (subPage) {
      this.state.subPage = subPage
      this.state.popup = null
    }
    if (popup) {
      this.state.popup = popup
      this.state.subpage = null
    }
  }

  slide = (target) => {
    if (target === 1) {
      this.scrollPos = pageScroller.scrollTop
      pageScroller.scrollTop = 0
    } else {
      pageScroller.scrollTop = this.scrollPos
    }
    if (this.target === target) return
    this.target = target
    this.start = Date.now()
    this.dur = 600;
    if (this.timer) return
    this.timer = setInterval(() => {
      window.requestAnimationFrame(() => {
        const start = this.start
        const dur = this.dur
        const elapsed = (Date.now() - start)
        let t = Math.min(elapsed / dur, 1.0)
        t = 1 - Math.pow(1 - t, 7)
        const target = this.target
        const updates = {
          alpha: target ? t : 1.0 - t
        }
        if (target) {
          if (this.state.alpha < 0.5 && t >= 0.5) {
            if (this.state.popup) {
              this.props.me.setStatusBarColor('light-content')
            }
          }
          if (this.props.subPage) {
            if (!this.state.subPage) {
              updates.subPage = this.props.subPage
            }
          }
          else if (this.props.popup) {
            if (!this.state.popup) {
              updates.popup = this.props.popup
            }
          }
        } else {
          if (this.state.alpha > 0.5 && t <= 0.5) {
            if (this.state.popup) {
              //this.props.me.setStatusBarColor('dark-content')
            }
          } else {
            if (this.props.me.self) {
              if (this.props.me.isTodoList()) {
                this.props.me.setStatusBarColor('dark-content')
              } else {
                this.props.me.setStatusBarColor('light-content')
              }
            }
          }
          if (updates.alpha === 0) {
            updates.subPage = null
            updates.popup = 0
          }
        }
        this.setState(updates, () => {
          if (this.props.onAlphaChange) this.props.onAlphaChange(this.state.alpha)
        })
        if (t === 1) {
          this.endSlide()
        }
      })
    }, 16)
  }

  endSlide = () => {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (this.state.alpha == 0) {
      if (!this.props.me.self) {
        //this.props.me.setStatusBarColor('dark-content')
      } else {
        if (this.props.me.isTodoList()) {
          this.props.me.setStatusBarColor('dark-content')
        } else {
          this.props.me.setStatusBarColor('light-content')
        }
      }
    }
  }

  search = searchTerm => {
    if (this.props.search) return this.props.search(searchTerm)
    return Promise.resolve()
  }

  renderPopup (popupBgStyle, popupStyle) {
    let className = 'fdPagePopup'
    if (!this.props.me.isNative() && !isIPad()) {
      className += " teteMobileWebPopup"
    }
    const popup = this.props.popup || this.state.popup;
    if (popup) {
      if (true ||  popupBgStyle) {
        const n = <div className='fdPagePopupBg' style={popupBgStyle}><div className={className} style={popupStyle}>{popup}</div></div>
          const portalRef = getPortal()
        return ReactDOM.createPortal(n, portalRef)
      }
      return (
          <div className={className} style={popupStyle}>
          {popup}
        </div>
      )
    }
    return null
  }

  scrollPos = 0;

  setScrollRef = n => {
    if (n) {
      this.pageScroller = n
      const self = this
      this.pageScroller.onscroll = (e) => {
        console.log('scroll: ', this.props.title, n.scrollTop)
        if (this.state.popup || this.state.subpage) {
          e.preventDefault()
          n.scrollTo(0, this.scrollPos)
          return
        }
        const pos = n.scrollTop
        //console.log('pos=', pos)
        if ((this.scrollPos <= 40 && pos > 40) || (this.scrollPos > 40 && pos <= 40)) {
          this.scrollPos = pos
          this.setState({
            scrolled: pos > 40
          })
        } else {
          this.scrollPos = pos
        }
      }
      if (this.scrollPos !== n.scrollTop) {
        this.scrollPos = n.scrollTop
      }
    }
  }

  back = async () => {
    //console.log("alpha:", this.state.alpha, "leaving:", this.leaving)
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.back()
  }

  cancel = async () => {
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.cancel()
  }

  next = async (arg) => {
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.done(arg)
  }


  renderSafeArea = () => {
    const self = this
    if (this.props.safeArea) {
      let style
      if (isAndroid() && window.safeAreaInsets) {
        const { top, bottom } = window.safeAreaInsets
        style = {
          height: (top + bottom)+'px'
        }
      }
      return <div className='fdPageHeaderSafeArea' style={style}/>
    }
    return null
  }

  render () {
    const ipad = isIPad() || (isDesktop() && window.innerWidth > 600)
    const popup = this.props.popup || this.state.popup || null
    const subPage = this.props.subPage || this.state.subPage || null
    let pageStyle
    let popupStyle
    let bodyStyle
    let cameraStyle
    let popupBgStyle
    let scrollerStyle
    let scrollTitle
    let isMobileWeb = false
    if (!this.props.me.isNative() && !isIPad()) {
      isMobileWeb = true
    }
    //console.log("render.scroll", this.props.title, this.scrollPos)
    if (this.state.scrolled || this.props.embed) {
      scrollTitle = this.props.title
    }
    if (this.props.noHeader) {
      scrollerStyle = { maxHeight: '100%' }
    }
    if (this.props.header) {
      scrollerStyle = { maxHeight: '100%' }
    }

    if (subPage) {
      const x = -(this.state.alpha * 100) + '%'
      pageStyle = { transform: 'translate(' + x + ', 0)' }
    } else if (popup) {
      const y = (1.0 - this.state.alpha) * 100 + '%'
      let r = this.state.alpha * 10
      let z = -2.5
      const rot = this.state.alpha * 1
      let t1 = 35
      let t2 = 40
      if (isAndroid() || isMobileWeb) {
        //z = -1.5
        if (!window.ReactNativeWebView) {
          t1 = 0
        } else {
          t1 = 30
        }
        //r = 0
      }
      const t = this.state.alpha * z
      let dist = Math.max(t2 * window.innerHeight / 800, t2)
      if (window.ReactNativeWebView && !isIPad()) {
        t1 += 20
        dist = 22
      }
      if (ipad) {
        t1 = 20
        let w = window.innerWidth - 40
        if (!isIPad()) {
          w = Math.min(w, 600)
        }
        popupStyle = {
          position: 'fixed',
          width: w,
          maxHeight: 'calc(100% - 60px)',
          left: (window.innerWidth - w) / 2,
          top: 20,
          transform: `translate(0, calc(${y} + ${t1}px))`,
          //borderRadius: '10px 10px 10px 10px',
          zIndex: 150,
          overflow: 'auto'
        }
        popupBgStyle = {
          top: 0,
          left: 0,
          position: 'fixed',
          height: '100%',
          width: '100%',
          background: `rgb(0, 0, 0, ${this.state.alpha * 0.2})`,
          zIndex: 100
        }
      } else {
        popupStyle = {
          transform: `translate(0, calc(${y} + ${t1}px))`,
          //borderRadius: `${r}px ${r}px 0px 0px`,
        }
      }
      const start = 'rgb(239, 239, 244)'
      const end = '#bababa'
      if (!ipad) {
        pageStyle = {
          transform: `translateZ(${t}cm) rotateX(${rot}deg)`,
          //borderRadius: `${r}px ${r}px 0px 0px`,
          //background: lerpColor(start, end, this.state.alpha),
          overflow: 'hidden'
        }
        cameraStyle = {
          perspective: `${dist}cm`
        }
      }
    }
    if (this.props.customSearch) {
      bodyStyle = {
        height: 'calc(100% - 64px)'
      }
    }
    return (
      <div className='fdPageBackground'>
        <div className={'fdPage'  + (this.props.embed ? ' fdPageEmbed' : '')}>
          <div className='fdPageCamera' style={cameraStyle}>
        <div className='fdPageContent' style={pageStyle}>
        {this.renderSafeArea()}
        {!this.props.noHeader && !this.props.noHeaderButtons && (this.props.header ? this.props.header : 
                <FDPageHeaderButtons embed={this.props.embed}
                  back={this.props.back && this.back} backLabel={this.props.backLabel} nextLabel={this.props.nextLabel} next={this.props.next && this.next}
                  title={scrollTitle}
                  cancel={this.props.cancel && this.cancel}
                                  />)}
              <div className='fdPageScroller' ref={this.setScrollRef} style={scrollerStyle}>
                {this.props.titleImage && <div className='fdPageTitleImage'><img src={this.props.titleImage} /></div>}
      {!(this.props.noHeader || this.props.header) && <div className='fdPageHeader'>
                  <div className='fdPageTitleRow'>
                    <div className='fdPageTitleColumn'>
                      <div className='fdPageSubtitle'>{this.props.subtitle}</div>
                      <div className='fdPageTitle'>{this.props.title}</div>
                    </div>

                    {this.props.me && this.props.me.self && <div className='fdPageUserIcon' onClick={this.props.onClickUser}><FDUserProfileIcon user={this.props.me.self} /></div>}
       </div>
       {this.props.customSearch && <div className='fdPageCustomSearch' ref={this.props.customSearch}/>}
       {this.props.customSearchField && <div className='fdPageCustomSearch'>{this.props.customSearchField}</div>}
                  {this.props.search && <div className='fdPageSearch'><FDSearchField searchAction={this.props.searchAction} searchValue={this.props.searchValue} search={this.search} scopeButtons={this.props.scopeButtons} /></div>}
                </div>}
                <div className='fdPageBody' style={bodyStyle}>
                  {this.props.children}
                </div>
                {this.props.subPages ? <div className='fdPageSubpage'>
                 {
                   this.props.subPages.map(page => {
                       return <div className='fdSubpageContainer' style={subPage ? null : { display: 'none'}}>
                       {page}
                     </div>
                   })
                 }
                 </div> : subPage ? <div className='fdPageSubpage'>{subPage}</div> : null}
            </div>
              <div className='fdPageFooter'>{this.props.footer}</div>
        
            </div>
          </div>
          {this.renderPopup(popupBgStyle, popupStyle)}
        </div>
      </div>
    )
  }
}
