import React, { Component } from 'react';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {ReactSVG} from 'react-svg';
import {UIToggleButton} from "../Button";
import MicOn from "../../assets/icons/MicOn.svg";
import NoCamera from "../../assets/icons/NoCamera62x34.svg";
import Check from "../../assets/icons/CheckMark.svg";
import Speaker from '../../assets/icons/Speaker.svg'
import VideoOn from "../../assets/icons/VideoOn.svg";
import Gear from "../../assets/icons/Settings.svg";
import {isDesktop, isMobile, isIPad, isFirefoxDesktop} from "../../Platform";
import {enumerateDevices, getDeviceCaps} from '../../Signaling.js'
//import Check from "../../assets/icons/Check.svg";
import './index.css'

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

let currentHome;

export class UICantOpenMedia extends Component {
  render() {
    return <div className='uiCantOpenMediaContainer'>
      <div className='uiCantOpenMedia'>
      <div className='uiCantOpenMediaIcon'><ReactSVG src={NoCamera}/></div>
      <div className='uiCantOpenMediaText'>{"Oof, can't access your camera"}</div>
      </div>
      </div>
  }
}

export class UIDeviceSetupControls extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selection: "",
      selecting: false,
      options: {
        audioinput: [],
        videoinput: [],
        audiooutput: [],
        videores: [],
      },
      selected: {
        audioinput: '',
        videoinput: '',
        audiooutput: '',
        videores: '',
      }
    }
  }

  selectVideo = () => {
    const selected = this.state.selection == "videoinput";
    this.setState({
      selection: selected ? "" : 'videoinput',
      selecting: !selected || !this.state.selecting
    })
  }

  selectAudio = () => {
    const selected = this.state.selection == "audioinput";
    this.setState({
      selection: selected ? "" : "audioinput",
      selecting: !selected || !this.state.selecting
    })
  }

  selectSpeaker = () => {
    const selected = this.state.selection == "audiooutput";
    this.setState({
      selection: selected ? "" : "audiooutput",
      selecting: !selected || !this.state.selecting
    })
  }

  selectVideoRes = () => {
    const selected = this.state.selection == "videores";
    this.setState({
      selection: selected ? "" : "videores",
      selecting: !selected || !this.state.selecting
    })
  }

  select = value => {
    const selection = this.state.selection
    const prev = this.state.selected[selection]
    this.state.selected[selection] = value;
    this.props.me.updateDeviceSetting(selection, value)
    this.state.selecting = false;
    this.state.selection = "";
    if (selection === 'videoinput') {
      getDeviceCaps(value).then(caps => {
        this.setState({deviceCaps: caps})
      })
    }
    this.forceUpdate();
    debugLog("onChange", selection, value)
    if (this.mounted) {
      this.props.onChange(selection, value)
    }
  }

  hideMenu = () => {
    this.setState({
      selecting: false,
      selection: "",
    });
  }
  
  componentWillUnmount() {
    this.mounted = false
    this.caps = null
  }

  componentDidMount() {
    if (this.props.authorized) {
      this.loadDeviceCaps()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.authorized && !prevProps.authorized) {
      this.loadDeviceCaps()
    }
  }

  loadDeviceCaps = () => {
    if (this.caps) return
    const videoRes = localStorage.getItem("videores");
    const videoInput = localStorage.getItem("videoinput");
    const audioInput = localStorage.getItem("audioinput");
    const audioOutput = localStorage.getItem("audiooutput");
    getDeviceCaps().then(caps => {
      this.caps = caps
      ////alert(JSON.stringify(caps, null, ' '))
      const h = caps ? caps.height.max : 1080
      this.state.options.videores = VIDEO_RES.filter(opt => {
        let result
        result = opt.height <= h
        debugLog('caps.height.max', h, '=>', result, opt)
        return result
      })
      if (navigator.mediaDevices) {
        navigator.mediaDevices.enumerateDevices().then(devices => {
          //debugLog("devices: ", devices);
          devices.map((device, i) => {
            if (this.state.options[device.kind]) {
              this.state.options[device.kind].push(device);
            }
          });
          this.state.selected['videores'] = videoRes || (isDesktop() ? 'hd' : 'sd')
          this.state.selected['videoinput'] = videoInput || this.state.options.videoinput[0].deviceId;
          this.state.selected['audioinput'] = audioInput || this.state.options.audioinput[0].deviceId;
          this.state.selected['audiooutput'] = audioOutput || this.state.options.audiooutput[0] ? this.state.options.audiooutput[0].deviceId : ''
          debugLog("selected", this.state.selected)
          this.forceUpdate(() => {
            this.mounted = true
          });
        })
      }
    })
  }

  render() {
    let selectionOptions;
    let selectedOption;
    const caps = this.state.deviceCaps
    const makeLabel = (opt, i) => {
      let label = opt.label;
      if (!label) {
        if (opt.kind == 'audioinput') {
          label = "Microphone "+(i+1);
        } else if (opt.kind == 'audiooutput') {
          label = "Speaker "+(i+1);
        } else if (opt.kind == 'videoinput') {
          if (caps && caps.facingMode.includes('back')) {
            label = 'Back Camera'
          } else if (caps && caps.facingMode.includes('user')) {
            label = 'Front Camera'
          } else {
            label = "Camera "+(i+1);
          }
        }
      }
      return label;
    }
    if (this.state.selection) {
      selectionOptions = this.state.options[this.state.selection].map((opt, i) => {
        return {
          value: opt.deviceId,
          name: makeLabel(opt, i)
        }
      });
      selectedOption = this.state.selected[this.state.selection];
    }
    let selectionListClassName = 'uiDeviceSetupSelection';
    if (this.state.selection == 'audioinput') {
      selectionListClassName += " uiDeviceSetupSelectionOneMic";
    } else if (this.state.selection == 'audiooutput') {
      selectionListClassName += " uiDeviceSetupSelectionSpeaker";
    } else if (this.state.selection == 'videores') {
      selectionListClassName += " uiDeviceSetupSelectionVideoRes";
    }
    return <div className='uiDeviceSetupControlsContainer'>
      <ClickAwayListener onClickAway={this.hideMenu}><div className='uiDeviceSetupControls'>
      <UIToggleButton label={"Resolution"} icon={Gear} selected={this.state.selection == 'videores'} onChange={this.selectVideoRes}/>
      <UIToggleButton label={"Speakers"} icon={Speaker} selected={this.state.selection == 'audiooutput'} onChange={this.selectSpeaker}/>
      <UIToggleButton label={"Microphone"} icon={MicOn} selected={this.state.selection == 'audioinput'} onChange={this.selectAudio}/>
      <UIToggleButton label={"Camera"} icon={VideoOn} selected={this.state.selection == 'videoinput'} onChange={this.selectVideo}/>
      {selectionOptions && <div className={selectionListClassName}><UISelectionList select={this.select} options={selectionOptions} selected={selectedOption} visible={this.state.selecting} value={selectedOption}/></div>}
    </div></ClickAwayListener>
      </div>
  }
}


export class UISelectionList extends Component {
  constructor(props) {
    super(props);
  }
  
  selectOption = value => {
    let newValue = value;
    let opt
    if (this.props.multiSelect) {
      const existing = this.props.value.find(x => x == value);
      if (existing) {
        newValue = this.props.value.filter(x => x != value);
      } else {
        newValue = this.props.value.concat([newValue]);
      }
    } else {
      opt = this.props.options.find(opt => opt.value == newValue)
    }
    this.props.select(newValue, opt)
  }

  render() {
    return <div className='uiSelectionList' style={this.props.visible ? null: {display: "none"}}>
      {this.props.options.map((opt, i) => {
        let selected;
        if (this.props.multiSelect) {
          selected = this.props.value.find(x => x == opt.value);
        } else {
          selected = opt.value == this.props.value;
        }
        //debugLog("opt.value: ", opt.value, " = ", this.props.value, " => ", selected);
        const className = "uiSelectionListOption" + (selected ? " uiSelectionListOptionSelected" : "");
        return <div key={i} className={className} onClick={()=>this.selectOption(opt.value)}>
          <div className={'uiSelectionListOptionName'}>{opt.name}</div>
          <div key='check' className={'uiSelectionListOptionCheck'} style={selected ? null : { visibility: 'hidden'} }><ReactSVG src={Check}/></div>
          </div>
      })}
    </div>
  }
}

const VIDEO_RES = [

  {
    label: "UHD",
    kind: 'videores',
    deviceId: 'uhd',
    width: 3840,
    height: 2160,
  },

  {
    label: "1080p",
    kind: 'videores',
    deviceId: '1080p',
    width: 1920,
    height: 1080,
  },
  {
    label: "HD",
    kind: 'videores',
    deviceId: 'hd',
    width: 1280,
    height: 720,
  },
  {
    label: "SD",
    kind: 'videores',
    deviceId: 'sd',
    width: 640,
    height: 480,
  },
  {
    label: "LD",
    kind: 'videores',
    deviceId: 'ld',
    width: 480,
    height: 360,
  }]


export const openMediaDevice = (caps) => {
  const deviceId = localStorage.getItem('videoinput')
  const video = deviceId ? {deviceId: {exact: deviceId}} : {}
  const audioInput = localStorage.getItem('audioinput')
  const videoRes = localStorage.getItem('videores')
  const audio = audioInput ? { deviceId: {exact: audioInput} } : {};
//  video.frameRate = { min: 24, ideal: 30 }
  let h = caps ? caps.height.max : 8192
  const id = videoRes
  this.closeStream()
  if (id) {
    const res = VIDEO_RES.find(res => res.deviceId == id)
    h = res.height
  }
  video.height = { ideal: h }
  const constraints = {
    video: video,
    audio: audio
  }
  const videoRef = this.videoRef;
  //alert("open stream: " + JSON.stringify(constraints, null, ' '));
  //if (!this.props.me.isNative()) //alert(JSON.stringify(constraints, null, ' '))
  return this.openUserMedia(constraints).then(stream => {
    //debugLog("got stream");
    debugLog("openStream", stream)
    return stream
  })
}


export class UIDeviceSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: "",
      selecting: false,
      options: {
        audioinput: [],
        videoinput: [],
        audiooutput: [],
        videores: [],
      },
      selected: {
        audioinput: 0,
        videoinput: 0,
        audiooutput: 0,
        videores: ''
      }
    }
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    currentHome = this;
    getDeviceCaps().then(caps => {
      this.caps = caps
      if (this.props.visible && !this.stream) this.openStream()
    })
    if (isMobile()) {
      this.sub = this.props.me.observeMobileCallActive().subscribe(callActive => {
        this.setState({
          mobileCallActive: callActive
        })
      })      
    }
    this.videoRef.current.onresize = () => {
      this.forceUpdate()
    }
    this.updateAudioOutput()
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible) {
      if (!prevProps.visible) {
        this.openStream()
      }
    } else {
      this.closeStream()
    }
  }

  componentWillUnmount() {
    currentHome = null;
    if (this.videoRef.current) {
      this.videoRef.current.onloadeddata = null;
    }
    if (this.sub) this.sub.unsubscribe()
    this.closeStream();
  }

  closeStream = () => {
    debugLog('close stream', this.stream)
    if (this.stream) {
      this.stream.getTracks().map(track => track.stop());
    }
    this.stream = null
  }

  openUserMedia = (constraints) => {
    return new Promise((resolve, reject) => {
      const open = () => {
        if (!currentHome) {
          debugLog("device setup unmounted");
          return;
        }
        //alert(JSON.stringify(constraints, null, ' '))
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          debugLog("opened user media", constraints, stream.getVideoTracks()[0].getSettings())
          resolve(stream);
        }).catch(err => {
          //if (!this.props.me.isNative()) //alert(err.message + ': ' + JSON.stringify(constraints, null, ' '))
          this.setState({
            cantOpenMedia: true,
          });
          constraints.video = true
          console.warn(err);
          setTimeout(open, 1000);
        });
      }
      open();
    });
  }

  updateAudioOutput = (value) => {
    if (!value) value = localStorage.getItem('audiooutput')
    if (value && this.videoRef.current && this.videoRef.current.setSinkId) this.videoRef.current.setSinkId(value)
  }

  deviceSetupChanged = (field, value) => {
    debugLog("deviceSetupChanged", field, value)
    if (!this.props.me.isNative()) {
      ////alert(field + " = " + value)
    }
    switch (field) {
      case 'audioinput':
      case 'videoinput':
      case 'videores':
        this.openStream()
        break
      case 'audiooutput':
        this.updateAudioOutput(value)
    }
  }

  openStream = async () => {
    if (this.state.mobileCallActive) {
      return
    }
    if (!this.caps) {
      return
    }
    const deviceId = localStorage.getItem('videoinput')
    const video = deviceId ? {deviceId: {exact: deviceId}} : {}
    const audioInput = localStorage.getItem('audioinput')
    const videoRes = localStorage.getItem('videores')
    const audio = audioInput ? { deviceId: {exact: audioInput} } : {};
//    video.frameRate = { min: 24, ideal: 30 }
    let h = this.caps ? this.caps.height.max : 8192
    const id = videoRes
    this.closeStream()
    if (id) {
      const res = VIDEO_RES.find(res => res.deviceId == id)
      h = res.height
    }
    video.height = { ideal: h }
    const aspect = this.caps ? this.caps.width.max / this.caps.height.max : undefined
    if (aspect) {
      video.width = { ideal: Math.round(aspect * h / 10) * 10 }
    }
    const constraints = {
      video: video,
      audio: audio
    }
    const videoRef = this.videoRef;
    //alert("open stream: " + JSON.stringify(constraints, null, ' '));
    //if (!this.props.me.isNative()) //alert(JSON.stringify(constraints, null, ' '))
    console.log("openStream", constraints)
    return this.openUserMedia(constraints).then(stream => {
      this.setState({
        cantOpenMedia: false,
        videoWidth: 0,
        videoHeight: 0,
        authorized: true
      });
      console.log("got stream", stream.getVideoTracks().map(track => track.getSettings()))
      //debugLog("got stream");
      debugLog("openStream", stream)
      this.stream = stream;
      if (videoRef.current) videoRef.current.srcObject = stream;
    }).catch (err => {
      debugLog("failed to get stream: ", err);
      this.setState({
        cantOpenMedia: true
      });
    });
  }
  
  render() {
    let selectionOptions;
    let selectedOption;
    const caps = this.state.deviceCaps
    const makeLabel = (opt, i) => {
      let label = opt.label;
      if (!label) {
        if (opt.kind == 'audioinput') {
          label = "Microphone "+(i+1);
        } else if (opt.kind == 'audiooutput') {
          label = "Speaker "+(i+1);
        } else if (opt.kind == 'videoinput') {
          if (caps && caps.facingMode.includes('back')) {
            label = 'Back Camera'
          } else if (caps && caps.facingMode.includes('user')) {
            label = 'Front Camera'
          } else {
            label = "Camera "+(i+1);
          }
        }
      }
      return label;
    }
    if (this.state.selection) {
      selectionOptions = this.state.options[this.state.selection].map((opt, i) => {
        return {
          value: opt.deviceId,
          name: makeLabel(opt, i)
        }
      });
      selectedOption = this.state.selected[this.state.selection]
    }
    debugLog("selection:", this.state.selection, selectedOption)
    let selectionListClassName = 'uiDeviceSetupSelection';
    if (this.state.selection == 'audioinput') {
      selectionListClassName += " uiDeviceSetupSelectionOneMic";
    } else if (this.state.selection == 'audiooutput') {
      selectionListClassName += " uiDeviceSetupSelectionSpeaker";
    } else if (this.state.selection == 'videores') {
      selectionListClassName += " uiDeviceSetupSelectionVideoRes";
    }
    let dim = ''
    if (this.videoRef.current) {
      dim = this.videoRef.current.videoWidth + 'x' + this.videoRef.current.videoHeight
    }
    return <div className='uiDeviceSetup'>
      <div className='uiDeviceSetupVideo'>
      {this.state.cantOpenMedia ?
       <div className='uiDeviceSetupCantOpenMedia'>
       <UICantOpenMedia/>
       </div>
       : <video playsInline autoPlay ref={this.videoRef} muted/>}
      <div className='uiVideoDimensions'>{dim}</div>
      <UIDeviceSetupControls me={this.props.me} authorized={this.state.authorized} onChange={this.deviceSetupChanged}/>
      </div>
      </div>
  }
}
