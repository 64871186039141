import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UIProfileIcon} from "../ProfileIcon";
import {UIInputField} from "../Home";
import {UIOKCancel} from "../OKCancel";
import Cross from "../../assets/icons/Cross.svg";
import StripeS from "../../assets/icons/StripeS.svg";
import Save from "../../assets/icons/Save.svg";
import Clock from "../../assets/icons/Clock.svg";
import Close from "../../assets/icons/Cross.svg";
import CreditCard from "../../assets/icons/CreditCard.svg";
import Plus from "../../assets/icons/Plus.svg";
import Trash from "../../assets/icons/Trash.svg";
import CalSml from "../../assets/icons/CalendarSml.svg";
import Profile from "../../assets/icons/Profile.svg";
import ProfileSml from "../../assets/icons/ProfileSml.svg";
import Back from "../../assets/icons/Back.svg";
import Fwd from "../../assets/icons/Forward.svg";
import Update from "../../assets/icons/Update.svg";
import Refund from "../../assets/icons/Refund.svg";
import Check from "../../assets/icons/CheckMark.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import ChatSpace from "../../assets/icons/ChatSpace.svg";
import LB from '../../assets/icons/LB_Logo512.svg'
import moment from 'moment';
import Activity from '../../assets/icons/Active.svg'
import "./index.css";
import {Elements} from '@stripe/react-stripe-js';
import {ElementsConsumer, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';


export class UICreditCardInput extends Component {

    constructor(props) {
        super(props);
    }
    
    removePaymentMethod = () => {
        this.props.setPaymentMethod(null);
    }

    componentDidMount() {
    }
            
    render() {
        const paymentMethod = this.props.paymentMethod;
        if (paymentMethod) {
            return <div className='uiStripeClientConnectForm'>
                <div className='uiStripeConnectCardName'><p>{paymentMethod.card.brand} ending in {paymentMethod.card.last4}</p>
                {this.props.paymentStatus != 'succeeded' && <div className='uiSubscribeToChatRemoveCard' onClick={this.removePaymentMethod}>
                <ReactSVG src={Cross}/>
                 </div>}
                </div>
                </div>;
        }
        const CARD_OPTIONS = {
            iconStyle: 'solid',
            style: {
                base: {
                    iconColor: 'white',
                    color: 'white',
                    fontWeight: 500,
                    fontSize: '14px',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                        color: '#666666',
                    },
                    '::placeholder': {
                        color: 'white'
                    },
                },
                invalid: {
                    iconColor: '#ffc7ee',
                    color: '#ffc7ee',
                },
            },
        };
        return <div className='uiStripeClientConnectForm'>
            <div className='uiStripeConnectFormCard'><CardElement options={CARD_OPTIONS}/></div>
            </div>        
    }

}



export class UISubscribeToChat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentStatus: props.paymentStatus,
            paymentMethod: props.finalPaymentMethod,
            formError: {},
            form:  {
                date: props.startDate,
                on: props.startDate,
                with: props.with,
                startDate: props.startDate,
                description: props.description,
                invoiceDescription: props.invoiceDescription,
                invoiceAmount: props.invoiceAmount,
                responseTime: props.responseTime,
            }
        }
        console.log("Form: ", this.state.form);
        console.log("Props: ", this.props);
    }

    onResized = () => {
        this.forceUpdate();
    }

    componentDidMount() {
        this.windowListener = window.addEventListener("resize", this.onResized);
        window.removeEventListener("resize", this.onResized);
        if (this.props.client) {
            //debugger;
            if (!this.props.finalPaymentMethod) {
                this.props.me.getPaymentMethod().then(paymentMethod => {
                    console.log(paymentMethod);
                    if (paymentMethod) {
                        console.log("got payment method: ", paymentMethod.id);
                        this.setState({
                            paymentMethod: paymentMethod,
                        });
                    }
                })
            }
        }
    }

    update = async () => {
        if (!this.props.paymentMethod) {
            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            //debugger;
            const cardElement = this.elements.getElement(CardElement);
            
            const {error, paymentMethod} = await this.stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            if (error) {
                console.log('[error]', error);
                this.props.setPaymentMethodError(error.message);
            } else {
                console.log('[PaymentMethod]', paymentMethod);
                this.props.setPaymentMethod(paymentMethod);
            }
        }
    }

    createPaymentMethod = () =>{
        const cardElement = this.elements.getElement(CardElement);
        console.log("got card element: ", cardElement);
        //debugger;
        return this.stripe.createToken(cardElement, {currency: "usd"}).then(result => {
            //debugger;
            if (result.error) {
                this.setState({
                    error: result.error.message
                });
            } else {
                console.log("saving payment method: ", result);
                return this.props.me.savePaymentMethod(result.token.id).then(() => {
                    console.log("saved payment method: " , result);
                    this.setState({
                        paymentMethod: result.token
                    });
                    return result.token;
                });
                return result.token;
            }
        });
    }
    paymentAction = () => {
        if (this.props.client && this.state.form.invoiceAmount > 0) {
            if (this.state.paymentStatus != "succeeded") {
                window.showProgressIndicator("Saving Payment Method");
                let p = Promise.resolve(this.state.paymentMethod);
                if (!this.state.paymentMethod) {
                    p = this.createPaymentMethod().then(paymentMethod => {
                        //debugger;
                        this.setState({
                            paymentMethod: paymentMethod,
                        });
                        return paymentMethod;
                    });
                }
                return p.then(paymentMethod => {
                    if (!paymentMethod) {
                        window.hideProgressIndicator();
                        return;
                    }
                    window.showProgressIndicator("Making Payment");
                    return this.props.accept();
                });
            } else {
                return this.props.downloadInvoice();
            }
        }
    }

    onChange= (field, value) => {
        const e = this.state.formError;
        this.setState({error: "", formError: {}});
        this.props.onChange(field, value);
        this.state.form[field] = value;
        this.forceUpdate();
    }

    searchContacts = searchValue => {
        const searchTerms = searchValue.toLowerCase().split(/\s+/);
        const matches = {};
        const filter = x => {
            if (!searchValue) return true;
            let matched = 0;
            const name = x.displayName.toLowerCase();
            const terms = name.split(/\s+/);
            searchTerms.map(searchTerm => {
                terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
            });
            matches[x.uid] = matched;
            return matched > 0;
        }
        const sort = (x, y) => {
            const w1 = matches[x.uid] || 0;
            const w2 = matches[y.uid] || 0;
            const cmp1 = w2-w1;
            if (cmp1 !== 0) {
                return cmp1;
            }
            return x.displayName ? y.displayName ? x.displayName.localeCompare(y.displayName) : 1 : -1;
        }
        let result = this.props.me.getCurrentContacts().filter(filter);
        result.sort(sort);
        return result;
    }

    paymentRefund = () => {
        window.showProgressIndicator("Refunding Payment");
        this.props.me.refundAppointment(this.props.appointmentId).then(()=> {
            window.hideProgressIndicator()
            this.setState({
                paymentStatus: null
            });
        })
    }

    okAction = () => {
        //debugger;
        if (!this.state.form.with) {
            this.setState({
                error: "Who will you be meeting with?",
                formError: {field: 'with'}
            });
            return Promise.resolve();
        }
        if (!this.state.form.description) {
            this.setState({
                error: "Description is required",
                formError: {field: 'description'}
            });
            return Promise.resolve();
        }
        if (!this.state.form.invoiceAmount) {
            this.setState({
                error: "Invoice Amount is required",
                formError: {field: 'invoiceAmount'}
            });
            return Promise.resolve();
        }
        if (this.state.form.responseTime <= 0) {
            this.setState({
                error: "Response Time must be >= 1",
                formError: {field: 'invoiceAmount'}
            })
            return Promise.resolve();
        }
        this.setState({
            formError: {}
        });
        return this.props.update(this.state.form).then(result => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            }
        });
    }

    renderPaymentMethod = () => {
        return <UICreditCardInput
        me={this.props.me}
        paymentStatus={this.props.state == "active" ? "succeeded" : null}
        paymentMethod={this.state.paymentMethod}
        setPaymentMethod={paymentMethod => {
            this.setState({paymentMethod: paymentMethod});
        }}
        set={x=>this.creditCardPayment=x}/>
    }

    render() {
        return <Elements stripe={window.stripePromise}>
            <ElementsConsumer>
            {({elements, stripe}) => {
                //debugger;
                // stripe wtfs
                this.elements = elements;
                this.stripe = stripe;
                return this.renderSelf();
            }}
        </ElementsConsumer>
      </Elements>
    }
    
    renderSelf() {
        const paymentStatus = this.state.paymentStatus;
        let paymentLabel = "Complete Payment";
        let paymentStatusLabel = "Payment Requested";
        let okIcon = this.props.isNew ? Fwd: Update;
        let actionLabel = "";
        if (this.props.state == 'active') {
            paymentStatusLabel = "Active";
        } else if (this.props.state == 'client-cancel' || this.props.state == 'provider-cancel') {
            paymentStatusLabel = "Canceled";
        }
        let buttonsClass = 'uiSubscribeToChatBodyButtons'
        let okAction;
        let cancelAction;
        if (this.props.state == 'offer') {
            if (this.props.client) {
              okAction = this.props.decline
              okIcon = Trash
              actionLabel = "Decline Offer"
              cancelAction = null
              buttonsClass += ' ' + 'uiSubscribeToChatDeclineButton'
            } else {
                actionLabel = this.props.isNew ? "Offer" : "Update";
                okAction = this.okAction;
                cancelAction = this.props.cancel;
            }
        } else if (this.props.state == 'active') {
          //cancelAction = this.props.cancel;
          okAction = this.props.cancel
          okIcon = Trash
          actionLabel = "Cancel Subscription"
          buttonsClass += ' uiSubscribeToChatDeclineButton'
        } else {
            if (!this.props.client) {
                actionLabel = "Offer";
                okAction = this.okAction;
            }                
        }
        const paymentIntentId = paymentStatus ? this.props.paymentIntentId : null;
        const invoiceAmount = this.state.form.invoiceAmount || 0;
        let needsStripeConnect = !this.props.me.stripeAuth;
        let stripeLabel = "Setup Payments with Stripe";
        let invoiceAmountErr;
        if (!needsStripeConnect) {
            const account = this.props.me.accountData.account;
            if (account && account.requirements && (account.requirements.disabled_reason ||
                                                    (account.requirements.errors && account.requirements.errors.length > 0 ))) {
                stripeLabel = "Fix Payment Setup Errors";
                needsStripeConnect = true;
                invoiceAmountErr = {
                    field: "invoiceAmount",
                }
            }
        }
        const isIPad = () => {
            return document.documentElement.clientWidth < 1024;
        }
        let className = 'uiSubscribeToChat';
        if (isIPad()) {
            className += " uiSubscribeToChatIPad";
        }

      const stateIndicator = this.props.state == 'active' ? "Active" : "Offer";

      console.log('render form', this.state.form)
        
	return  <div className={className+ (this.props.editable ? "" : " uiSubscribeToChatClient")}>
            <div className='uiSubscribeToChatHeader'>

            <div className='uiSubscribeToChatHeaderClose' onClick={this.props.back}>
            <ReactSVG src={Close}/>
            </div>

            <div className='uiSubscribeToChatHeaderInfo'>

            <div className='uiSubscribeToChatHeaderIcon'>
            <ReactSVG src={Activity}/>
            </div>

            <div className='uiSubscribeToChatHeaderTitle'>
            <div>Let's Build Subscription</div><div>{stateIndicator}</div>
            </div>
            {this.props.isNew && <div className='uiSubscribeToChatHeaderDate'>
            Starting {moment(this.state.form.startDate).format("MMM Do YYYY")}
             </div>}
            
            </div>
         </div>
            <div className='uiSubscribeToChatBody'>
            {(this.props.client || this.props.editable) &&
             <div className='uiSubscribeToChatBodyWith'>

            <div className='uiSubscribeToChatBodyWithIcon'>
            <UIProfileIcon radius={22.5} contact={this.props.with}/>
            </div>

            <div className='uiSubscribeToChatBodyWithName'>
            {this.props.with ? this.props.with.displayName: ""}
             </div>
             <div className='uiSubscribeToChatBodyWithCreds'>{this.props.with.creds}</div>
             </div>
            }

            <div className='uiSubscribeToChatClientFields' style={this.props.client || !this.props.editable ? null : {display: "none"}}>
            <div className='uiSubscribeToChatClientFieldsInfo'>
            <div className='uiSubscribeToChatClientTitle'>
            {this.props.description}
            </div>
            <div className='uiSubscribeToChatClientTime'>
            {(this.props.client ? "Replies" : "You reply") +" within"} {this.props.responseTime} {this.props.responseTime == 1 ? "day" : "days"}
            </div>


        </div>
            </div>
            {(!this.props.editable || this.props.client) && invoiceAmount > 0 && <div className='uiSubscribeToChatPaymentInfo'>
             <div className='uiSubscribeToChatPaymentInfoLoading' style={
                 (this.props.paymentIntentId && !this.state.paymentStatus) ? {visibility: "hidden"} : null
             }>
            <div className='uiSubscribeToChatPaymentInvoice'>
            <div className='uiSubscribeToChatPaymentStatus'>{paymentStatusLabel}</div>
            <div className='uiSubscribeToChatPaymentInvoiceAmount'>${invoiceAmount.toFixed(2)} <span className='uiSubscribeToChatMonthly'>monthly</span></div>
            <div className='uiSubscribeToChatPaymentInvoiceDescription'>{this.props.invoiceDescription}</div>
             </div>
             {this.props.client &&
              <div className='uiSubcribeToChatPaymentContainer'>
              <div className='uiSubscribeToChatPaymentMethod'>
              <div className='uiSubscribeToChatPaymentMethodIcon'><ReactSVG src={CreditCard}/></div>
              {this.renderPaymentMethod()}
              </div>
              <div className='uiSubscribeToChatPaymentControl'>
              <UIOKCancel okIcon={Fwd} label={paymentLabel} ok={this.props.state == 'active' ? null : this.paymentAction}/>
              </div>
              </div>}
             </div>
            </div>}            
            <div className='uiSubscribeToChatBodyFields' style={this.props.editable && !this.props.client ? null : {display: "none"}}>


            <div className='uiSubscribeToChatBodyField'>
            <UIInputField label={'Description'} error={this.state.formError} value={this.state.form.description} icon={Edit} name='description' onChange={this.onChange} type='text'/>
            </div>
            {
            <div className='uiSubscribeToChatBodyField'>
            <UIInputField label={'Starts'} value={this.state.form.startDate} icon={CalSml} name='startDate' onChange={this.onChange} type='date'/>
                    </div>}
            {false && <div className='uiSubscribeToChatBodyField'>
            <UIInputField label={"Responds Within (Days)"} value={this.state.form.responseTime} icon={Clock} name='responseTime' onChange={this.onChange} type='number'/>
            </div>}
             {!paymentIntentId && 
            <div className='uiScheduleAppointBodyInvoiceFields'>
            <div className='uiSubscribeToChatBodyField'>
            <UIInputField label={'Invoice Description'} placeholder={this.state.form.description + ' with ' + this.props.me.self.displayName} value={this.state.form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={this.onChange} type='text'/>
            </div>
              <div className='uiSubscribeToChatBodyField'>
              {!needsStripeConnect ?
               <UIInputField label={'Invoice Amount (Monthly)'} value={this.state.form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={this.onChange} type='currency'/> :
               <UIInputField label={'Invoice Amount'} error={invoiceAmountErr} value={stripeLabel} readOnly={true} icon={StripeS} name='invoiceAmount' onChange={this.onChange} onClick={this.props.me.stripeConnect} type='button'/> }
              </div>
              </div>}
            {paymentIntentId &&
            <div className='uiSubscribeToChatPaymentInvoice'>
            <div className='uiSubscribeToChatPaymentStatusInfo'>
            <div className='uiSubscribeToChatPaymentStatus'>{paymentStatusLabel}</div>
            <div className='uiSubscribeToChatPaymentInvoiceAmount'>${invoiceAmount.toFixed(2)}</div>
            </div>
            <div className='uiSubscribeToChatPaymentControl'><UIOKCancel okIcon={Refund} label={"Refund Payment"} ok={this.paymentRefund}/></div>
            </div>}
            </div> 
            <div className='uiSubscribeToChatBodyFieldsError'>
            {this.state.error}
            </div>
            <div className={buttonsClass}>
            {(cancelAction || okAction) && <UIOKCancel okIcon={okIcon} cancelIcon={this.props.client ? Cross: Trash} label={actionLabel} ok={okAction} cancel={cancelAction}/>}
            </div>
          </div>
       </div>
    }
    
}
