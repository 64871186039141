import React, { Component } from 'react';
import './index.css';

export class LBFooter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='lbFooter'>
        <div className='lbFooterText'>letsbuild.fitness</div>
      </div>
    );
  }
}
