import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {UISelectionList} from "../DeviceSetup";
import Tooltip from "@material-ui/core/Tooltip";
import Screenshare from "../../assets/icons/Screenshare.svg";
import MicOn from "../../assets/icons/MicOn.svg";
import Settings from "../../assets/icons/Settings.svg";
import Connection from "../../assets/icons/Connection.svg";
import RecordOff from "../../assets/icons/RecordOff.svg";
import VideoOn from "../../assets/icons/VideoOn.svg";
import {UIOKCancel} from "../OKCancel";
import Fwd from "../../assets/icons/Forward.svg";
import Pause from "../../assets/icons/Hold.svg";
import Stop from "../../assets/icons/Stop.svg";
import YourVideo from "../../assets/icons/YourCamera.svg";
import EndCall from "../../assets/icons/EndCall.svg";
import FullScreen from "../../assets/icons/Fullscreen.svg";
import Upload from "../../assets/icons/File.svg";
import Save from "../../assets/icons/Save.svg";
import Board from "../../assets/icons/Blackboard/Board.svg";
import Clear from "../../assets/icons/Blackboard/Clear.svg";
import Color from "../../assets/icons/Blackboard/Color.svg";
import Pen from "../../assets/icons/Blackboard/Pen.svg";
import Pan from "../../assets/icons/Blackboard/Pan.svg";
import PenCursor from "../../assets/icons/Blackboard/PenCursor.svg";
import PenCursorImage from "../../assets/icons/Blackboard/PenCursor.png";
import Redo from "../../assets/icons/Blackboard/Redo.svg";
import Undo from "../../assets/icons/Blackboard/Undo.svg";
import Select from "../../assets/icons/Blackboard/Select.svg";
import Cross from "../../assets/icons/Cross.svg";
import ResizeObserver from 'resize-observer-polyfill';
import {isMobile, isIPad} from "../../Platform";
import {UIButton, UIToggleButton} from "../Button";
import {UIIcon} from "../Icon";
import {UIDeviceSetupControls, UICantOpenMedia} from "../DeviceSetup";
import {UIWhiteboard} from "../Whiteboard";
import './index.css'
import Enter from "../../assets/audio/Igor/callJoin.wav";
import 'fullscreen-polyfill';
import {Howl, Howler} from 'howler';

const isLandscape = () => window.innerWidth > window.innerHeight;

const canScreenshare = window.navigator.mediaDevices && window.navigator.mediaDevices.getDisplayMedia

let maxWidth;

const enterConference = new Howl({
  src: [Enter],
  loop: false,
});

export const getGridStyle = (dims, container)=> {
  const numCells = dims.length;
  if (numCells <= 1) {
    return {}
  }
  const isLandscape = () => true;
  let h = "100%";
  let w = "100%";
  let numCols;
  switch (numCells) {
    case 0: numCols = 0; break;
    case 1: numCols = 1; break;
    case 2: numCols = 2; break;
    case 3: numCols = 2; break;
    case 4: numCols = 2; break;
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      numCols = 3; break;
    default:
      numCols = 4; break;
  }
  if (numCols === 0) {
    return {};
  }
  const numRows = Math.ceil(numCells/numCols);
  w = (100/numCols) + "%";
  h = ((1/numRows)*100)+"%";
  const grid_auto = isLandscape() ? "grid-auto-rows": "grid-auto-columns";
  const grid_template = isLandscape() ? "grid-template-columns" : "grid-template-rows";
  const result = {};
  if (container) {
    console.log("numCols: ", numCols);
    console.log("numRows: ", numRows);
    let w1 = 0;
    let h1 = 0;
    const getCell = (row, col) => {
      const k = col * numRows+row;
      let result = null;
      if (k < dims.length) {
        result = dims[k];
      }
      console.log("cell: ", row, ", ", col, ": ", result);
      return result;
    }
    for (var i = 0; i < numCols; i++) {
      let h2 = 0;
      for (var j = 0; j < numRows; j++) {
        const dim = getCell(j, i);
        if (dim) {
          h2 += dim.height;
        }
      }
      console.log("col: ", i, ": h1: ", h1, ", h2: ", h2);
      h1 = Math.max(h1, h2);
    }
    for (var i = 0; i < numRows; i++) {
      let w2 = 0;
      for (var j = 0; j < numCols; j++) {
        const dim = getCell(i, j);
        if (dim) {
          w2 += dim.width;
        }
      }
      w1 = Math.max(w1, w2);
      console.log("row: ", i, ": w1: ", w1, ", w2: ", w2);
    }
    console.log("h1: ", h1, ",  w1: ", w1);
    //const w1 = (numCols * 480);
    //const h1 = (numRows * 320);
    const sx = container.offsetWidth / w1;
    const sy = container.offsetHeight / h1;

    console.log("container: sx: ", sx, " sy: ", sy);
    const s = Math.min(sx, sy);
    result["transform"] = "scale("+s+", "+s+")";
  }
  result[grid_auto] = "min-content";
  result[grid_template] = Array.from({length: isLandscape() ? numCols : numRows}).fill("max-content").join(" ");
  return result;
}


export class UIVideoStream extends Component {
  constructor(props) {
    super(props);
  }

  setRef = vid => {
    if (vid && vid != this.video) {
      this.video = vid;      
    }
  }

  componentDidMount() {
    this.video.srcObject = this.props.stream;
    this.video.onresize = () => {
      this.props.onStreamVisible(this.video.videoWidth, this.video.videoHeight, this.props.index);
    }
    this.props.stream.getVideoTracks().map(track => {
      track.addEventListener("ended", ()=> {
        console.log("TRACK ENDED");
        this.props.onStreamVisible(0, 0, this.props.index);
      })
    });
    this.sub = this.props.info.call.observeDeviceSettings().subscribe(settings => {
      //console.log("got new device settings: ", settings);
      this.props.onDeviceSettingsChanged(settings);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.video.srcObject != this.props.stream) {
      this.video.srcObject = this.props.stream;
      this.sub.unsubscribe();
      this.sub = this.props.info.call.observeDeviceSettings().subscribe(settings => {
        //console.log("got new device settings: ", settings);
        this.props.onDeviceSettingsChanged(settings);
      });
    }
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
  }
  
  render() {
    let contained = this.props.contained || this.props.remoteScreenShare
    if (!contained && this.video) {
      if (this.video.videoHeight > this.video.videoWidth) {
        contained = window.innerWidth > window.innerHeight
      } else {
        contained = window.innerWidth < window.innerHeight
      }
    }
    const style = contained ? {objectFit: 'contain'} : {};
    style.width = this.props.width;
    let className = 'uiRemoteVideoTrack';
    if (this.props.compositor && !this.props.remoteScreenShare) {
      className='uiRemoteVideoCompositorTrack';
    }
    if (this.props.audioOnly) {
      style.display = 'none';
    }
    return <video style={style} ref={this.setRef} playsInline autoPlay crossOrigin={'anonymous'} className={className}/>;
  }
}

export class CallButton extends Component {
  constructor(props) {
    super(props);
  }

  onClick = e => {
    e.preventDefault()
    this.props.action()
  }
  
  render() {
    return <div className={'uiCallButton ' + (this.props.callActive ? " uiCallButtonHangup" : "uiCallButtonCall")} onClick={this.onClick}>
      <Tooltip placement='left' title={!this.props.callActive ? "Call" : "Hangup"}><UIIcon icon={EndCall}/></Tooltip>
      {this.props.callActive && <div className='uiEndCallButtonLabel'>Goodbye {this.props.contact.displayName}</div>}
    {!this.props.callActive && <div className='uiBeginCallButtonLabel'>Hello {this.props.contact.displayName}</div>}
    </div>
  }
}

export class UIRemoteVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLocalVideo: true,
    };
    this.fullScreen = React.createRef();
    this.videoSelectionOptions = [
      {value: "uhd", name: "UHD", w: 3840, h: 2160}, 
      {value: "1080p", name: "1080p", w: 1920, h: 1080},
      {value: "hd", name: "HD", w: 1280, h: 720},
      {value: "sd", name: "SD", w: 640, h: 480},
      {value: "ld", name: "LD", w: 0, h: 0},
    ];
    this.state.videoSelectionOptions = this.videoSelectionOptions;
    this.videoWidth = {};
    this.videoHeight = {};
  }

  setYourVideo= n => {
    if (n && n != this.yourVideo) {
      //alert('set your video')
      this.yourVideo = n;
      this.yourVideo.onresize = () => {
        //alert("on  resize")
        this.forceUpdate()
      }
      this.yourVideo.onloadeddata = () => {
        //alert("on  loaded data")
        this.forceUpdate()
      }
      this.yourVideo.srcObject = this.props.yourMediaStream
    }
  }

  toggleFullScreen = () => {
    this.props.toggleFullScreen();
  }

  updateSrcObject () {
    if (this.yourVideo && this.yourVideo.srcObject != this.props.yourMediaStream) {
      this.yourVideo.srcObject = this.props.yourMediaStream
    }
  }

  componentDidUpdate(prevProps) {
    this.updateSrcObject()
    if (!this.props.callActive && prevProps.callActive) {
      this.setState({
        joined: false,
        blackboard: false,
        showLocalVideo: true,
      });
      this.props.onStreamVisible(0, 0, 0);
    }
    if (!this.props.callActive && this.props.fullScreen) {
      this.toggleFullScreen();
      return;
    }
    if (this.props.fullScreen != prevProps.fullScreen) {
      const full = this.props.fullScreen;
      if (!isMobile() && document.exitFullscreen) {
        if (!!document.fullscreenElement != full) {
          if (full) {
            this.fullScreen.current.requestFullscreen();
          } else {
            document.exitFullscreen();
          }
        }
      }
    }
    if (this.props.remoteStreams.length == 0 && !this.state.showLocalVideo) {
      this.setState({
        showLocalVideo: true
      });
    }
    //this.checkMaxWidth();
  }

  getMaxHeight = ()=> {
    let h = 720
    if (this.props.yourMediaStream) this.props.yourMediaStream.getVideoTracks().map(track => {
      if (track.getCapabilities) {
        const caps = track.getCapabilities()
        h = Math.max(caps.height.max, h)
      } 
    })
    return h;
  }


  getMaxWidth = ()=> {
    let w = 0;
    if (this.props.yourMediaStream) this.props.yourMediaStream.getVideoTracks().map(track => {
      if (track.getCapabilities) {
        const caps = track.getCapabilities()
        w = Math.max(caps.width.max, caps.height.max)
        if (false && isIPad()) {
          w = Math.min(w, 1280);
        }
      } else {
        w = maxWidth;
      }
    });
    return w;
  }

  checkMaxWidth() {
    if (this.props.yourMediaStream) this.props.yourMediaStream.getVideoTracks().map(track => {
      if (!track.getCapabilites) {
        if (!maxWidth) {
          const prev = track.getSettings();
          const reset = () => {
            track.applyConstraints({
              width: prev.width,
              height: prev.height
            });
          }
          let dim = {
            width: 3840,
            height: 2160
          }
          if (isMobile()) {
            dim = {
              height: { ideal: 3840 },
              aspectRatio: 16/9
            }
          }
          track.applyConstraints(dim).then(() => {
            console.log("Settings: ", track.getSettings());
            maxWidth = track.getSettings().width;
            reset();
          }).catch(err => {
            console.error(err);
            maxWidth = 1280;
            reset()
          });
        }
      }
    });
  }

  componentDidMount() {
    this.updateSrcObject(true);
    this.fullScreen.current.onfullscreenchange = e => {
      setTimeout(() => {
        const full = document.fullscreenElement === e.target;
        if (full != this.props.fullScreen) {
          this.toggleFullScreen();
        }
      }, 100);
    }
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
    const full = document.fullscreenElement;
    if (full) document.exitFullscreen();
  }

  join = () => {
    this.setState({
      joined: true,
    });
    return this.props.joinCall();
  }

  hangupCall = call => {
    if (this.props.remoteStreams.length > 1) {
      call.hangup();
    } else {
      this.hangup();
    }
  }

  hangup = () => {
    this.setState({
      joined: false,
      showLocalVideo: true,
      blackboard: false,
    });
    //debugger;
    this.props.toggleCallActive();
    return Promise.resolve();
  }

  streamsVisible = {};

  onStreamVisible = (width, height, index) => {
    const visible = width > 0;
    this.streamsVisible[index] = visible
    let showLocalVideo = true;
    this.props.remoteStreams.map((x, i) => {
      if (this.streamsVisible[i]) {
        showLocalVideo = false;
      }
    });
    console.log("on stream visible: ", width);
    if (this.state.showLocalVideo && !showLocalVideo) {
      this.setState({
        showLocalVideo: showLocalVideo
      });
    }
    this.props.onStreamVisible(width, height, index);
    const w = width
    const h = height
    if (this.videoWidth[index] != w || this.videoHeight[index] != h) {
      this.videoWidth[index] = w
      this.videoHeight[index] = h
    }
    this.forceUpdate();
  }

  selectVideo = () => {
    this.setState({
      selectingVideo: !this.state.selectingVideo
    });
  }

  selectVideo1 = () => {
    this.setState({
      selectingVideo1: !this.state.selectingVideo1
    });
  }

  dismissVideoSelection = () => {
    this.setState({
      selectingVideo: false
    });
  }

  setVideoSelection = value => {
    return this.props.setLocalVideoResolution(value).then(this.dismissVideoSelection);
  }

  setVideoSelection1 = async value => {
    await this.props.setLocalVideoResolution(value)
    this.selectVideo1()
  }

  getVideoResolution = (stream, i) => {
    const w = this.videoWidth[i];
    const h = this.videoHeight[i];
    const value = this.dimToResolution(w, h);
    return this.videoSelectionOptions.find(opt => opt.value == value).name;
  }

  dimToResolution = (w, h) => {
    if (h > w) {
      return this.heightToResolution(w)
    } else {
      return this.heightToResolution(h)
    }
  }

  widthToResolution = w => {
    console.log("track width: ", w);
    if (w >= 3840) {
      return "uhd";
    }
    if (w >= 1920) {
      return "1080p";
    }
    if (w >= 1280) {
      return "hd";
    }
    if (w >= 640) {
      return "sd";
    }
    return "ld";
  }

  heightToResolution = h => {
    console.log("track height: ", h);
    if (h >= 2160) {
      return "uhd";
    }
    if (h >= 1080) {
      return "1080p";
    }
    if (h >= 720) {
      return "hd";
    }
    if (h >= 480) {
      return "sd";
    }
    return "ld";
  }

  onDeviceSettingsChanged = stream => {
    this.forceUpdate();
  }

  renderCantOpenMedia() {
    return <div className='uiRemoteVideoCantOpenMedia'>
      <UICantOpenMedia/>
      </div>
  }

  renderControls() {
    let className='uiRemoteVideoControls';
    return <div key='remoteControls' style={!this.state.showLocalVideo && this.props.height >= 1.0 ? null : {display: 'none'}} className={className}>
      <div className='uiToggleYourVideo'><UIToggleButton label={"Your Video"} icon={YourVideo} selected={!this.props.yourVideoHidden} onChange={this.props.toggleYourVideoHidden}/></div>
      <UIToggleButton label={"Full Screen"} icon={FullScreen} selected={this.props.fullScreen} onChange={this.toggleFullScreen}/>
      {false && <UIToggleButton label={"Blackboard"} icon={Board} selected={this.props.whiteboard} onChange={this.toggleBlackboard}/>}
    {canScreenshare && <UIToggleButton label={"Screenshare"} icon={Screenshare} selected={this.props.screenShare} onChange={this.props.toggleScreenShare}/>}
    {false && <UIToggleButton className='recordButton' label={"Recording"} icon={RecordOff} selected={this.props.recording} onChange={this.props.toggleRecording}/>}
      <UIToggleButton label={"Audio Muted"} icon={MicOn} selected={this.props.audioMuted} onChange={this.props.toggleAudioMuted}/>
      <UIToggleButton label={"Camera Muted"} icon={VideoOn} selected={this.props.videoMuted} onChange={this.props.toggleVideoMuted}/>
      
    </div>
  }

  renderVideoStreams() {
    const remoteStreams = this.props.remoteStreams;
    if (remoteStreams.length === 0) return null
    let style;
    let className = !isMobile(true) ? 'uiRemoteVideoStreamsDesktop': 'uiRemoteVideoStreamsMobile';
    let className1 = 'uiRemoteVideoStreamContainer';
    let contained = this.props.remoteComposite;
    if (remoteStreams.length > 1) {
      className += " uiRemoteVideoGroupLayout";
    }
    let width;
    if (remoteStreams.length && this.state.blackboard) {
      width = 240/remoteStreams.length;
      style = {};
      className1 += " uiRemoteStreamsBlackboard";
    } else {
      const dims = remoteStreams.map((info, i) => {
        const stream = info.stream;
        const track = stream.getVideoTracks()[0]
        let setting = { width: 0, height: 0 }
        if (track) {
          setting = track.getSettings();
          if (!setting.width) {
            const call = info.call;
            const device = call.getRemoteDevice();
            if (device) {
              setting = device;
            }
          }
        }
        return setting;
      });
      style = getGridStyle(dims, this.containerRef);
    }
    let screenShare;
    if (this.props.contact.isGroup) {
      screenShare = remoteStreams.find(info => info.call.isSharingScreen());
    }
    return <div ref={this.setContainerRef} key='streamsContainer' className={className1}>
      <div key='streams' className={className} style={style}>
      {remoteStreams.map((info, i) => {
        const stream = info.stream;
        let audioOnly = screenShare && screenShare != info;
        return <UIVideoStream
        index={i}
        compositor={this.props.remoteStreams.length > 1}
        width={width}
        contact={info.contact}
        audioOnly={audioOnly}
        onDeviceSettingsChanged={() => this.onDeviceSettingsChanged(info)}
        onStreamVisible={this.onStreamVisible}
        contained={contained}
        composite={this.props.remoteComposite}
        info={info}
        remoteScreenShare={info.call.isSharingScreen() || this.props.remoteScreenShare} stream={stream}
          />
      })}
    </div>
      </div>
  }

  renderLobby() {
    let show = this.state.showLocalVideo
    let recording = false
    if (this.props.callActive && this.props.callActive.recording) recording = true
    let videoSelectionOptions = this.videoSelectionOptions;
    let h = this.getMaxHeight();
    console.log("renderLobby", this.props, this.state)
    console.log("maxHeight:",h)
    if (h) {
      videoSelectionOptions = this.videoSelectionOptions.filter(opt => opt.h <= h);
    } else {
      videoSelectionOptions = this.videoSelectionOptions
    }
    return (show) ? <div key='lobby' className='uiCallLobbyAccept'>
      <div className='uiCallLobbyControls'>
      <UIToggleButton label={"Audio Muted"} icon={MicOn} selected={this.props.audioMuted} onChange={this.props.toggleAudioMuted}/>
      <UIToggleButton label={"Camera Muted"} icon={VideoOn} selected={this.props.videoMuted} onChange={this.props.toggleVideoMuted}/>
      <UIToggleButton label={"Resolution"} icon={Settings} selected={this.state.selectingVideo1} onChange={this.selectVideo1}/>
      <div className='uiDeviceSetupSelection'><UISelectionList select={this.setVideoSelection1} options={videoSelectionOptions} selected={this.props.localVideoResolution} visible={this.state.selectingVideo1} value={this.props.localVideoResolution}/></div>
      </div>
      {((!recording && this.props.callActive) || (this.state.joined && !recording))  && <CallButton callActive={true} contact={this.props.contact} action={this.hangup}/>}
    {(!this.state.joined || recording) && <UIOKCancel okIcon={recording && this.state.joined ? Stop: Fwd} cancelIcon={Cross} label={recording ?
                                                                                                                                    this.state.joined ? "Stop Recording" : "Start Recording": "Start Call"} error={!this.props.yourMediaStream} onErrorClick={this.hangup} ok={this.join} cancel={ recording ? this.hangup:  null}/>}</div> : null;
  }

  renderBars() {
    return this.props.remoteStreams.map((stream, i) => {
      const mediaStream = stream.stream;
      const videoTracks = mediaStream.getVideoTracks();
      let q = 0.0;
      let qualityStyle = {
      };
      if (videoTracks.length > 0) {
        const track = videoTracks[0];
        let w1 = 0
        let h1 = 0
        const call = stream.call;
        const device = call.getRemoteDevice();
        if (device) {
          w1 = device.width
          h1 = device.height
        }
        const w2 = this.videoWidth[i] || 0
        const h2 = this.videoHeight[i] || 0
        if (h1 > 0) {
          q = Math.min(h2 / h1, 1.0);
        }
        if (q == 0 || q == 1) {
        } else if (q < 0.25) {
          q = 0.33;
        } else if (q <= 0.5) {
          q = 0.5;
        } else {
          q = 0.66;
        }
        console.log(w1 + 'x' + h1 + ' / ' + w2 + 'x' + h2+ ", q: ", q);
        const percent =  Math.round(q * 100)+ "%";
        //console.log("quality: ", percent);
        qualityStyle.background = "linear-gradient(to right, #ffffff 0%, #ffffff "+percent+", rgb(255, 255, 255, 0.4) "+percent+", rgb(255, 255, 255, 0.4) 100%)";
        qualityStyle['mask-image'] = "url("+Connection+")";
        qualityStyle['-webkit-mask-image'] = "url("+Connection+")";
        //console.log("quality gradient: ", qualityStyle.fill);
        return <div key='remoteVideoBars' className='uiRemoteVideoCallQuality'>
          <div className='uiRemoteVideoCallQualityOn' style={qualityStyle}>
          </div>
          </div>
      } else {
        return null;
      }
    });
  }

  renderYourVideo() {
    let yourVideoClassName='uiRemoteVideoYourVideo';
    let yourVideoContainerClassName='uiRemoteVideoLocalVideoContainer';
    if (!canScreenshare) {
      yourVideoContainerClassName += " uiRemoteVideoControlsNoScreenshare";
    }
    if (isIPad()) {
      yourVideoClassName += " uiRemoteVideoYourVideoIPad";
    }
    //console.log("showLocalVideo: ", this.state.show);
    const showLocalVideo = this.state.showLocalVideo
    if (this.state.showLocalVideo) {
      yourVideoClassName='uiCallLobbyYourVideo';
      yourVideoContainerClassName='uiCallLobbyYourVideoContainer';
    }
    if (this.props.screenShare) {
      yourVideoClassName += ' uiYourVideoScreenShare';
    }
    let videoSelectionOptions = this.videoSelectionOptions;
    let h = this.getMaxHeight();
    console.log("maxHeight:",h)
    if (h) {
      videoSelectionOptions = this.videoSelectionOptions.filter(opt => opt.h <= h);
    } else {
      videoSelectionOptions = this.videoSelectionOptions
    }
    return <div key='yourvideo' className={yourVideoContainerClassName} style={!this.state.showLocalVideo && this.props.yourVideoHidden ? {display: "none"} : null}>
      <div className={yourVideoClassName}>
      {!this.props.yourMediaStream ? this.renderCantOpenMedia() :
       <video autoPlay muted={true} playsInline ref={this.setYourVideo} onClick={this.onClickVideo}/>}
    </div>
      <div className='uiVideoDimensions'>{this.yourVideo ? (this.yourVideo.videoWidth + 'x'  + this.yourVideo.videoHeight) : ''}</div>
      <UIDeviceSetupControls me={this.props.me} authorized={true} style={this.props.contact.isGroup || videoSelectionOptions.length == 0 || this.state.showLocalVideo || this.props.screenShare ? {display: "none"} : null} onChange={this.deviceSetupChanged}/>
      </div>
  }

  onClickVideo = e => {
    ////alert("clicked video")
    this.forceUpdate()
  }

  deviceSetupChanged = (field, value) => {
    this.props.onDeviceSetupChanged(field, value)
  }

  hangupAll = () => {
    //this.props.remoteStreams.map((stream, i) => stream.call.hangup());
    //setTimeout(this.hangup, 200);
    this.hangup();
  }

  renderCallButton() {
    const contact = this.props.contact;
    if (this.props.remoteStreams.length > 0 && contact && contact.isGroup) {
      return <div key='remoteVideoCall' className='uiRemoteVideoCall'>
        <div className='uiRemoteVideoCallButtonContainer'>
        <CallButton callActive={true} contact={contact} action={this.hangupAll}/>
        </div>
        </div>;
    } 
    return <div key='remoteVideoCall' className='uiRemoteVideoCall'>
      {this.props.remoteStreams.map((stream, i) => {
        const call = stream.call;
        const contact = call.getRemoteContact();
        return <div className='uiRemoteVideoCallButtonContainer'>
          <CallButton callActive={true} contact={contact} action={()=>this.hangupCall(call)}/>
          <div className='uiRemoteVideoCallResolution'>{this.getVideoResolution(stream.stream, i)}</div>
          </div>
      })}
    </div>
  }

  createContainerListener() {
    this.resizeObserver = new ResizeObserver(entries => {
      entries.map(entry => {
        this.forceUpdate();
      });
    });
    this.resizeObserver.observe(this.containerRef);
  }

  releaseContainerListener() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  setContainerRef = n => {
    if (n && this.containerRef != n) {
      if (this.containerRef) {
        this.releaseContainerListener();
      }
      this.containerRef = n;
      if (this.containerRef) {
        this.createContainerListener();
      }
      console.log("set containerRef: ", n);
    }
  }


  
  render() {
    return <div ref={this.fullScreen} key='uiRemoteVideo'
    className='uiRemoteVideo' style={this.props.height > 0 ? null : { display: 'none'}}>
      <div className={'uiRemoteCallAnimationContainer'}>
       {this.renderVideoStreams()}
       {this.renderYourVideo()}
       {this.renderControls()}
       {this.renderCallButton()}
       {this.renderLobby()}
       {this.renderBars()}
       </div>
    </div>;
  }
}
