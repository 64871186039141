import axios from 'axios'
import Weightlifting from '../assets/icons/weightlifting.png'
import JiuJitsu from '../assets/icons/jiu-jitsu.png'
import Paddle_tennis from '../assets/icons/paddle_tennis.png'
import Barre from '../assets/icons/barre.png'
import Wheelchair_pushing from '../assets/icons/wheelchair_pushing.png'
import Pickleball from '../assets/icons/pickleball.png'
import Spikeball from '../assets/icons/spikeball.png'
import Crossfit from '../assets/icons/crossfit.png'

const BASE_URL = 'https://api-7.whoop.com/'
const AUTH_URL = BASE_URL + 'oauth/token'
const CYCLES_URL = (userId, start, end) => BASE_URL + `users/${userId}/cycles?start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`
const HEART_RATE_URL = userId => BASE_URL + `users/${userId}/metrics/heart_rate`
const SLEEP_URL = (userId, sleepId) => BASE_URL + `users/${userId}/sleeps/${sleepId}`
const WORKOUT_URL = (userId, workoutId) => BASE_URL + `users/${userId}/workouts/${workoutId}`

const sports = [
   {
      "id":84,
      "name":"Jumping Rope",
      "createdAt":"2019-08-16T22:50:09.178Z",
      "updatedAt":"2019-08-16T22:50:14.814Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/jumping_rope.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":85,
      "name":"Australian Football",
      "createdAt":"2019-08-16T22:51:26.059Z",
      "updatedAt":"2019-08-16T22:51:28.908Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/australian_football.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":86,
      "name":"Skateboarding",
      "createdAt":"2019-08-16T22:52:24.966Z",
      "updatedAt":"2019-08-16T22:52:26.697Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/skateboarding.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":91,
      "name":"Snowboarding",
      "createdAt":"2020-05-05T21:39:20.523Z",
      "updatedAt":"2020-05-05T21:39:20.523Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/snowboarding.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":87,
      "name":"Coaching",
      "createdAt":"2019-12-17T16:01:33.761Z",
      "updatedAt":"2019-12-17T16:01:33.761Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/coaching.png",
      "isCurrent":true,
      "hasSurvey":false,
      "category":"non-cardiovascular"
   },
   {
      "id":88,
      "name":"Ice Bath",
      "createdAt":"2019-12-17T16:01:33.761Z",
      "updatedAt":"2019-12-17T16:01:33.761Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/ice_bath.png",
      "isCurrent":true,
      "hasSurvey":false,
      "category":"non-cardiovascular"
   },
   {
      "id":89,
      "name":"Commuting",
      "createdAt":"2019-12-17T16:01:33.761Z",
      "updatedAt":"2019-12-17T16:01:33.761Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/commuting.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":90,
      "name":"Gaming",
      "createdAt":"2019-12-17T16:01:33.761Z",
      "updatedAt":"2019-12-17T16:01:33.761Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/gaming.png",
      "isCurrent":true,
      "hasSurvey":false,
      "category":"non-cardiovascular"
   },
   {
      "id":92,
      "name":"Motocross",
      "createdAt":"2020-05-05T21:39:20.523Z",
      "updatedAt":"2020-05-05T21:39:20.523Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/motocross.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":93,
      "name":"Caddying",
      "createdAt":"2020-05-05T21:39:20.523Z",
      "updatedAt":"2020-05-05T21:39:20.523Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/caddying.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":94,
      "name":"Obstacle Course Racing",
      "createdAt":"2020-05-05T21:39:20.523Z",
      "updatedAt":"2020-05-05T21:39:20.523Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/obstacle-course-racing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":95,
      "name":"Motor Racing",
      "createdAt":"2020-05-05T21:39:20.523Z",
      "updatedAt":"2020-05-05T21:39:20.523Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/motor-racing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":96,
      "name":"HIIT",
      "createdAt":"2020-10-01T20:12:02.307Z",
      "updatedAt":"2020-10-01T20:12:02.307Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/hiit.png",
      "isCurrent":true,
      "hasSurvey":false,
      "category":"cardiovascular"
   },
   {
      "id":98,
      "name":"Jiu Jitsu",
      "createdAt":"2020-10-01T20:12:02.307Z",
      "updatedAt":"2020-10-01T20:12:02.307Z",
      "hasGps":false,
      "iconUrl": JiuJitsu,
      "isCurrent":true,
      "hasSurvey":false,
      "category":"non-cardiovascular"
   },
   {
      "id":99,
      "name":"Manual Labor",
      "createdAt":"2020-10-01T20:12:02.307Z",
      "updatedAt":"2020-10-01T20:12:02.307Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/manual-labor.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":100,
      "name":"Cricket",
      "createdAt":"2020-10-01T20:12:02.307Z",
      "updatedAt":"2020-10-01T20:12:02.307Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/cricket.png",
      "isCurrent":true,
      "hasSurvey":false,
      "category":"non-cardiovascular"
   },
  /*
   {
      "id":97,
      "name":"Spin",
      "createdAt":"2020-10-01T20:12:02.307Z",
      "updatedAt":"2020-10-21T15:39:41.992Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/spin.png",
      "isCurrent":true,
      "hasSurvey":false,
      "category":"cardiovascular"
   },
*/
   {
      "id":101,
      "name":"Pickleball",
      "createdAt":"2021-02-09T20:03:47.195Z",
      "updatedAt":"2021-02-09T20:03:47.195Z",
      "hasGps":false,
     "iconUrl":Pickleball,
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":104,
      "name":"Spikeball®",
      "createdAt":"2021-03-18T17:22:06.061Z",
      "updatedAt":"2021-03-18T17:22:06.061Z",
      "hasGps":false,
     "iconUrl": Spikeball,
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },

   {
      "id":102,
      "name":"Inline Skating",
      "createdAt":"2021-02-09T20:03:47.195Z",
      "updatedAt":"2021-02-17T17:39:37.052Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/rollerblading.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":103,
      "name":"CrossFit® Training",
      "createdAt":"2021-03-04T13:21:19.803Z",
      "updatedAt":"2021-03-10T20:10:50.267Z",
      "hasGps":false,
     "iconUrl": Crossfit,
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":105,
      "name":"Wheelchair Pushing",
      "createdAt":"2021-03-18T17:22:06.061Z",
      "updatedAt":"2021-03-18T17:22:06.061Z",
      "hasGps":true,
     "iconUrl": Wheelchair_pushing,
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":106,
      "name":"Paddle Tennis",
      "createdAt":"2021-03-18T17:22:06.061Z",
      "updatedAt":"2021-03-18T17:22:06.061Z",
      "hasGps":false,
     "iconUrl": Paddle_tennis,
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },

   {
      "id":16,
      "name":"Baseball",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/baseball.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },

   {
      "id":107,
      "name":"Barre",
      "createdAt":"2021-04-07T20:24:46.347Z",
      "updatedAt":"2021-04-07T20:24:46.347Z",
      "hasGps":false,
     "iconUrl": Barre,
      "isCurrent":true,
      "hasSurvey":false,
      "category":"non-cardiovascular"
   },
   {
      "id":22,
      "name":"Golf",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-10-17T16:23:07.004Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/golf.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":53,
      "name":"Horseback Riding",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2021-04-01T15:57:24.262Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/horseback_riding.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":73,
      "name":"Diving",
      "createdAt":"2015-11-09T21:13:59.056Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/diving.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":51,
      "name":"Gymnastics",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/gymnastics.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":70,
      "name":"Meditation",
      "createdAt":"2015-04-02T15:30:33.814Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/meditation.png",
      "isCurrent":true,
      "hasSurvey":false,
      "category":"restorative"
   },
   {
      "id":43,
      "name":"Pilates",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/pilates.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":59,
      "name":"Powerlifting",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/powerlifting.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"muscular"
   },
   {
      "id":60,
      "name":"Rock Climbing",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/rock_climbing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":28,
      "name":"Sailing",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/sailing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":29,
      "name":"Skiing",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/skiing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":31,
      "name":"Softball",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/softball.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":63,
      "name":"Walking",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/walking.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":45,
      "name":"Weightlifting",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
     "iconUrl": Weightlifting,
      "isCurrent":true,
      "hasSurvey":true,
      "category":"muscular"
   },
   {
      "id":44,
      "name":"Yoga",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/yoga.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":17,
      "name":"Basketball",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/basketball.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":39,
      "name":"Boxing",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/boxing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":83,
      "name":"Climbing",
      "createdAt":"2018-07-16T18:02:38.560Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/climber.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":47,
      "name":"Cross Country Skiing",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/cross_country_skiing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":1,
      "name":"Cycling",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/cycling.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":42,
      "name":"Dance",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/dance.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":49,
      "name":"Duathlon",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/duathlon.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":65,
      "name":"Elliptical",
      "createdAt":"2014-06-20T18:41:51.246Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/elliptical.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":19,
      "name":"Fencing",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/fencing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":20,
      "name":"Field Hockey",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/field_hockey.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":21,
      "name":"Football",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/football.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":48,
      "name":"Functional Fitness",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/functional_fitness.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":52,
      "name":"Hiking/Rucking",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/hiking.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":24,
      "name":"Ice Hockey",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/ice_hockey.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":55,
      "name":"Kayaking",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/kayaking.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":25,
      "name":"Lacrosse",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/lacrosse.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":56,
      "name":"Martial Arts",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/martial_arts.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":57,
      "name":"Mountain Biking",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/mountain_biking.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":71,
      "name":"Other",
      "createdAt":"2015-10-13T18:58:51.368Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/other.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":61,
      "name":"Paddleboarding",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/SUP.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":18,
      "name":"Rowing",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/crew.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":27,
      "name":"Rugby",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/rugby.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":0,
      "name":"Running",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/running.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":30,
      "name":"Soccer",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/soccer.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":32,
      "name":"Squash",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/squash.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":66,
      "name":"Stairmaster",
      "createdAt":"2014-06-20T18:41:51.246Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/stairmaster.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":64,
      "name":"Surfing",
      "createdAt":"2014-06-20T18:41:51.246Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/surfing.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":33,
      "name":"Swimming",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/swimming_diving.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":34,
      "name":"Tennis",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/tennis.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":35,
      "name":"Track & Field",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/track_and_field.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":62,
      "name":"Triathlon",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/triathlon.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":82,
      "name":"Ultimate",
      "createdAt":"2018-01-09T19:36:14.598Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/ultimate_frisbee.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":36,
      "name":"Volleyball",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/volleyball.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":37,
      "name":"Water Polo",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/water_polo.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":38,
      "name":"Wrestling",
      "createdAt":"2014-02-11T10:07:16.000Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/wrestling.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":54,
      "name":"Jogging",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/jogging.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":58,
      "name":"Obstacle Racing",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/obstacle_racing.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":-1,
      "name":"Activity",
      "createdAt":"2015-03-06T19:38:27.281Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/unknown.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":-10,
      "name":"Rest",
      "createdAt":"2015-03-06T19:38:27.281Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/unknown.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":68,
      "name":"Spinning",
      "createdAt":"2014-09-08T14:05:33.743Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/spinning.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":67,
      "name":"Plyometrics",
      "createdAt":"2014-09-08T14:05:33.743Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/plyometrics.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":74,
      "name":"Operations - Tactical",
      "createdAt":"2017-03-20T17:54:01.394Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/tactical_ops.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":76,
      "name":"Operations - Flying",
      "createdAt":"2017-03-20T17:54:01.394Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/flying_ops.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":75,
      "name":"Operations - Medical",
      "createdAt":"2017-03-20T17:54:01.394Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/medical_ops.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":77,
      "name":"Operations - Water",
      "createdAt":"2017-03-20T17:54:01.394Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/water_ops.png",
      "isCurrent":true,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":72,
      "name":"Pit Practice",
      "createdAt":"2015-11-09T21:13:59.056Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/pitstop.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   },
   {
      "id":46,
      "name":"Canoeing",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":true,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/canoeing.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"cardiovascular"
   },
   {
      "id":50,
      "name":"Machine Workout",
      "createdAt":"2014-06-04T21:52:07.712Z",
      "updatedAt":"2019-05-14T18:08:12.552Z",
      "hasGps":false,
      "iconUrl":"https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/machine_workout.png",
      "isCurrent":false,
      "hasSurvey":true,
      "category":"non-cardiovascular"
   }
]

const Workouts = {
}

sports.map(sport => Workouts[sport.id] = sport)

export class Whoop {

  getWorkoutIcon = workout => {
    return Workouts[workout.sportId].iconUrl
  }

  getActivityIcon = () =>  Workouts[-1].iconUrl

  getSport = id => {
    return Workouts[id]
  }

  getSports = () => {
    return sports
  }

  getWorkout = workout => {
    let result = Workouts[workout.sportId]
    if (!result) {
      result = Workouts[-1]
    }
    return result
  }

  getSleepIcon = () => 'https://s3-us-west-2.amazonaws.com/icons.whoop.com/mobile/activities/sleep.png'

  signIn = async (username, password) => {
    const response = await axios.post(AUTH_URL, {      
      grant_type: 'password',
      issueRefresh: true,
      password: password,
      username: username
    })
    console.log(response.data)
    const { user, access_token } = response.data
    return response.data
  }

  get = async (uid, url, token) => {
    if (!token) {
      let response = await this.getToken(uid)
      if (response.error) {
        return response
      }
      token = response.token
    }
    const response = await axios.get(url, {
      headers: {
        'Authorization': "Bearer "+token
      }
    })
    return response.data
  }

  mapFromName = name => {
    if (!name) {
      return Workouts[-1]
    }
    name = name.toLowerCase().trim()
    let result = sports.find(sport => sport.name.toLowerCase().startsWith(name))
    return result ? result : Workouts[-1]
  }

  getCycleData = async (uid, auth, start, end) =>  {
    const cyclesArray = await this.get(uid, CYCLES_URL(auth.user.id, new Date(start).toISOString(), new Date(end).toISOString()), auth.access_token)
    return { cycles: cyclesArray }
  }
  
}
