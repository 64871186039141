import React, { Component } from 'react';
import "./SignUp.css"
import {Button} from './Button'
import {isDesktop} from "./Platform"
import {InputField} from './InputField'


export class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.email = this.props.email;
    }

    onChange=(field, value) => {
        const update = {};
        update[field] = value
        this.setState(update);
    }

    onReturn = e => {
        if (e.keyCode === 13) {
            this.props.resetPassword(this.state);
        }
    }

    componentDidMount() {
        if (isDesktop()) {
            window.addEventListener("keydown", this.onReturn);
        }
    }
    componentWillUnmount() {
        if (isDesktop()) {
            window.removeEventListener("keydown", this.onReturn);
        }
    }
    render() {
        return <div className='signUp'>
            <div className='signUpFields'>
            <div className='signUpTitle'>{this.props.title}</div>
            <InputField autoFocus={true} label={'Email Address'} value={this.state.email} name='email' type={'email'} autoComplete={'email'} onChange={this.onChange}/>
            <div className='buttonContainer signUpButtonContainer'>
            <Button label={this.props.backLabel} action={this.props.backAction}/>
            <Button label={this.props.label} action={()=>this.props.resetPassword(this.state)}/>
            </div>
            <div className='signUpError'>{this.props.error}</div>            
            </div>
            </div>
    }
}
