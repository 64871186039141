import React, {Component} from 'react';
import { ReactSVG } from "react-svg";
import Profile from "../../assets/icons/Profile.svg";
import Group from "../../assets/icons/Group.svg";
import './index.css'

let thumbCanvas;

const thumbnails = {};

export const generateThumbnail = (url, blob) => {
    if (thumbnails[url]) {
        return Promise.resolve(thumbnails[url]);
    }
    return new Promise((resolve, reject) => {
        var thumbSize = 256;
        if (!thumbCanvas) {
            thumbCanvas = document.createElement("canvas");
            thumbCanvas.width = thumbSize;
            thumbCanvas.height = thumbSize;
        }
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function(e) {
            var c = thumbCanvas.getContext("2d");
            var aspect = this.width / this.height;
            var h = thumbSize;
            var w = aspect * thumbSize;
            thumbCanvas.width = w;
            thumbCanvas.height = h;
            c.drawImage(this, 0, 0, w, h);
            if (blob) {
                return thumbCanvas.toBlob(resolve, "image/png");
            }
            resolve(thumbnails[url] = thumbCanvas.toDataURL("image/png"));
        };
        img.onerror = reject;
        img.src = url;
    });            
}


export class UIProfileIcon extends Component {

    constructor(props) {
        super(props);
        this.online = false;
    }


    checkOnline = () => {
        this.online = false;
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = null;
        }
        const contact = this.props.contact;
        if (contact) {
            this.sub = window.observeContactOnline(contact).subscribe(data => {
                const online = data.online;
                this.online = online;
                this.forceUpdate();
            });
        }
        this.forceUpdate();
    }
    

    componentDidMount() {
        this.checkOnline();
    }

    componentDidUpdate(prevProps) {
        if (this.props.contact != prevProps.contact) {
            this.checkOnline();
        }
    }

    componentWillUnmount() {
        this.online = false;
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    render() {
        const fillColor = this.props.fillColor;
        const borderColor = this.props.borderColor;
        const innerRadius = this.props.radius || 15;
        const borderWidth = 1;
        const outerRadius = innerRadius + 0.5*borderWidth;
        const outerSize = outerRadius * 2;
        const imageStyle = {
            position: 'absolute',
            width: outerSize,
            height: outerSize,
            left: borderWidth,
            top: borderWidth,
            clipPath: "circle("+innerRadius+"px at "+outerRadius+"px "+outerRadius+"px)",
            fill: fillColor
        };
        const ringStyle = {
            height: outerSize,
            width: outerSize,
            borderColor: borderColor,
            borderWidth: borderWidth*2,
        };
        const style = {
            width: outerSize + borderWidth*2,
            height: outerSize + borderWidth*2
        }
      const profileImage = this.props.contact ? this.props.contact.profileImage : null
      const profileIcon = this.props.contact ? this.props.contact.profileIcon : null
        const online = !!this.online;
        const key = this.props.contact ? this.props.contact.uid : "profile-icon";
        return <div key={key} className={"uiProfileIcon" + (online ? " uiProfileIconOnline" : " uiProfileIconOffline")} style={style}>
            <div key='icon' className="uiProfileIconImage">
        {profileIcon || (profileImage ? profileImage.endsWith('.svg') ? <ReactSVG src={profileImage}/> : <img key='profileImage' src={profileImage}/> :
             <div key='profileImage' className='uiProfileDefaultIcon'>
                         <ReactSVG src={this.props.contact && this.props.contact.isGroup ? Group : Profile}/>
                         </div>)}
            </div>
            <div key='ring' className="uiProfileIconRing" style={ringStyle}/>
            </div>
    }
}
