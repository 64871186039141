import React, { Component } from 'react';
import {InputField} from './InputField'
import {Button} from './Button'
import "./ShowLink.css"


export class ShowLink extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return <div className='showLink'>
            <div className='showLinkFields'>
            <div className='showLinkTitle'>{this.props.title}</div>
            <div className='showLinkSubtitle'>{this.props.subtitle}</div>
            <div className='showLinkContainer'>
            <div className="showLinkText">
            {this.props.link}
            </div>
            </div>
            <div className='buttonContainer'>
            <Button label={"Back"} action={this.props.done}/>
            <Button label={"Copy Link"} action={()=>this.props.copyLink(this.props.link)}/>
            </div>
            </div>
            </div>
    }
}
