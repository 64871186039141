import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import './index.css'
import { formatDate } from '../Appointment'
import WhoopLogo from '../../assets/icons/Whoop.svg'
import OuraLogo from '../../assets/icons/Oura.svg'
import FitbitIcon from '../../assets/icons/FitbitIcon.png'
import GarminLogo from '../../assets/icons/Garmin.svg'
import { UIProfileIcon } from '../ProfileIcon'
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel, VictoryArea, VictoryPie } from 'victory'
import { NutritionLabel } from '../Nutrition'
import { toFDA } from '../../classes/Nutritionix.js'
import { formatFoodCount } from '../Me'
import { FoodLogo, getFoodName } from '../ScheduleAppointment'
import moment from 'moment'
import {isDesktop, isMobile} from '../../Platform.js'
import Back from '../../assets/icons/Forward.svg'
import Spinner from '../../Mobile/src/assets/Icons/spinner.svg'
import ToDoListIcon from "../../assets/icons/ToDoList.svg";
console.log("VictoryArea: ", VictoryArea)
const RECOVERY_LOW = 'red'
const RECOVERY_NORMAL = 'rgb(249, 222, 73)'
const RECOVERY_HIGH = 'green'

const capitalize = x => x[0].toUpperCase() + x.substring(1).toLowerCase()

const formatWeekDay = when => when ? moment(when).format('ddd') : 'Today'
const formatDayOfMonth = when => {
  if (!when) return 'Today'
  const weekDay = moment(when).format('ddd')
  const dayDate = moment(when).format('M/DD')
  return [weekDay, dayDate]
}

export const formatWeight = w => {
  const x = Math.round(w)
  if (x === w) return w
  return w.toFixed(1)
}

const NegativeAwareTickLabel = props => {
  const {
    datum, // Bar's specific data object
    y, // Calculated y data value IN SVG SPACE (from top-right corner)
    dy, // Distance from data's y value to label's y value
    scale, // Function that converts from data-space to svg-space
    ...rest // Other props passed to label from Bar
  } = props;

  return (
    <VictoryLabel
      datum={datum} // Shove `datum` back into label. We destructured it from `props` so we'd have it available for a future step
      y={scale.y(0)} // Set y to the svg-space location of the axis
      dy={20 * Math.sign(datum.y)} // Change direction of offset based on the datum value
      {...rest} // Shove the rest of the props into the label
    />
  );
};

class DataChart extends Component {
  render() {
    const {
      w, h,
      points,
      yAxis,
      xAxis,
      tickValues,
      tickFormat,
      fill,
      canBeNegative
    } = this.props
    let barWidth = (w - 100)  / points.length

    const MAX = isDesktop() ? 14 : 7

    const formatX = when => {
      const result = when == 'Today' ? when: formatDayOfMonth(Date.parse(when))
      if (points.length > MAX) {
        if (result[0] !== 'Mon') {
          if (when !== points[0].x && when !== points[points.length-1].x) {
            return ''
          }
        }
        /*
        let day = result[1].split('/')[1]
        if (day.startsWith('0')) day = day.substring(1)
        day = parseInt(day)
        if (!((day - 1) % 7 === 0)) {
          return ''
        }
        */
      }
      return result
    }
    let minData = 0
    let maxData = 0
    points.forEach(data => {
      minData = Math.min(data.y, minData)
      maxData = Math.max(data.y, maxData)
    })
    minData = Math.min(minData, 0)
    let xTickFormat = formatX
    let formatNumber = x => x
    if (minData) {
      if (maxData > 0) {
        xTickFormat = when => {
          return [' '].concat(formatX(when))
        }
      } else {
        minData = 0
        xTickFormat = when => {
          return formatX(when).concat([' '])
        }
      }
      formatNumber = x => x > 0 ? '+' + x : x
    }
    let domainPadding = barWidth / 2
    //domainPadding += offset
    return <VictoryChart
    domainPadding={{ x: domainPadding } }    
    singleQuadrantDomainPadding={false}
    width={w}
    height={h}>
      <VictoryBar
    labelComponent={false && canBeNegative ? <NegativeAwareTickLabel/> : undefined}
    barWidth={barWidth}
    labels={points.length > MAX ? null : data => tickFormat ? tickFormat(data.datum.y) : formatNumber(data.datum.y)}
       style={{
         data: { fill: fill || 'rgb(66, 145, 244)', stroke: 'rgb(246, 4, 40, 0)' },
         labels: {
           fill: 'black',
           fontSize: isMobile() ? 9 : 14
         }
       }}
    x={'x'}
    y={'y'}
       data={points}/>
      <VictoryAxis dependentAxis
       tickValues={tickValues}
       style={
         {
           axis: { stroke: '#6d6d6d' },
           ticks: { stroke: '#6d6d6d', size: 8 },
           tickLabels: { color: '#6d6d6d', 'font-family': 'Arial', 'font-size': isMobile() ? 9 : 14}
         }
       }
       tickFormat={tickFormat}
      />
      <VictoryAxis
    axisValue={minData}
    tickFormat={xTickFormat}
    style={
      {
        axis: { stroke: minData ? 'transparent': '#6d6d6d' }
      }
    }
      />
      </VictoryChart>
  }
}

const sleepPerf = data => {
  const score = data.datum.y
  return score < 70 ? 'rgb(206, 0, 23)' : score >= 90 ? 'rgb(14, 211, 22)' : 'rgb(251, 176, 60)'
}

const percentFormat = percent => `${percent}%`
const percentValues = [0, 10, 20, 30, 40, 50, 60 , 70, 80 , 90, 100]
const sleepValues =  [0, 1, 2, 3, 4, 5, 6, 7, 8].map(x => x * 1000 * 60 * 60)
const sleepNeedValues =  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(x => x * 1000 * 60 * 60)
const gramsFormat = grams => `${grams} g`

export class DataCharts extends Component {
  render() {
    const { category, type, interval, cycleData, meals, weights }  =  this.props.options
    console.log('category', category)
    const workoutFilter = workout => {
      if (category.filter === -1) return true
      return workout.sportId === category.filter
    }
    const isWorkoutVisible = () => type == 'whoop' || type == 'garmin'
    let getX
    switch (type) {
      case 'whoop':
        cycleData.sort((x, y) => Date.parse(x.during.lower) - Date.parse(y.during.lower))
        getX = (cycle) => moment(cycle.days[0]).local().format()
        break
      case 'oura':
        cycleData.sort((x, y) => x.start = y.start)
        getX = (cycle) => moment(new Date(cycle.end)).format()
        break
      case 'garmin':
        cycleData.sort((x, y) => moment(x.calendarDate).toDate().getTime() - moment(y.calendarDate).toDate().getTime())
        getX = (cycle) => moment(cycle.calendarDate).format()
        break
    }
    let xAxis = 'Day'
    const w = Math.min(window.innerWidth, 700)
    const h = isMobile() ? w : w / 2
    const charts = [
      {
        category: ["overview"],
        yAxis: (type === 'whoop' || type === 'garmin') ? 'Recovery' : 'Readiness',
        tickFormat: percentFormat,
        tickValues: [0, 33, 66, 100],
        fill: data => {
          const score = data.datum.y
          return score <= 33 ? 'rgb(206, 0, 23)' : score > 66 ? 'rgb(14, 211, 22)' : 'rgb(251, 176, 60)'
        },
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              if (cycle.recovery) {
                const score = cycle.recovery.score
                y = score
              }
              break
            case 'oura':
              if (cycle.readiness) {
                y = cycle.readiness.score
              }
              break
            case 'garmin':
              {
                y = cycle.recovery || 0
              }
              break
          }
          return {
            x: getX(cycle, true),
            y: y,
          }
        })
      },
      {
        category: ["overview"],
        yAxis: type == 'whoop' ? 'Day Strain' : 'Activity',
        tickValues: type == 'whoop' ? [0, 10, 14, 16, 18, 20] : undefined,
        tickFormat: type == 'whoop' ? score => score.toFixed(1) : percentFormat,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.strain ? cycle.strain.score : 0
              break
            case 'oura':
              y = cycle.activity ? cycle.activity.score: 0
              break
            case 'garmin':
              {
                y = cycle.activity || 0
              }
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Sleep Performance',
        tickValues: [0, 70, 90, 100],
        tickFormat: percentFormat,
        fill: sleepPerf,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'garmin':
              y = cycle.sleepScore || 0
              break
            default:
              y = cycle.sleep ? (cycle.sleep.score || 0) : 0
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Slept',
        tickFormat: formatDur,
        tickValues: sleepValues,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.sleep ? cycle.sleep.qualityDuration : 0              
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.duration * 1000: 0
              break
            case 'garmin':
              y = cycle.slept || 0
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Sleep Need',
        tickFormat: formatDur,
        tickValues: sleepNeedValues,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.sleep && cycle.sleep.needBreakdown ? cycle.sleep.needBreakdown.total : 0
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.duration * (100/cycle.sleep.efficiency) * 1000 : 0
              break
            case 'garmin':
              y = Math.min(cycle.sleepNeed, 12*60*60*1000) || 0
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Time in Bed',
        tickFormat: formatDur,
        tickValues: sleepValues,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                let inBed = 0
                if (cycle.sleep) {
                  cycle.sleep.sleeps.map(sleep => {
                    inBed += sleep.inBedDuration
                  })
                }
                y = inBed
              }
              break
            case 'oura':
              y = cycle.sleep ? Date.parse(cycle.sleep.bedtime_end) - Date.parse(cycle.sleep.bedtime_start) : 0
              break
            case 'garmin':
              y = cycle.inBedDuration || 0
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Sleep Efficiency',
        tickValues: [0, 70, 90, 100],
        tickFormat: percentFormat,
        fill: sleepPerf,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                let sleepEfficiency = 0
                if (cycle.sleep) {
                  let totalDur = 0
                  cycle.sleep.sleeps.map(sleep => {
                    totalDur += cycle.sleep.qualityDuration
                  })
                  if (totalDur) {
                    cycle.sleep.sleeps.map(sleep => {
                      const dur = cycle.sleep.qualityDuration
                      sleepEfficiency += (dur / totalDur) * sleep.sleepEfficiency
                    })
                  }
                }
                y = Math.round(sleepEfficiency * 100)
              }
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.efficiency : 0
              break
            case 'garmin':
              y = Math.round((cycle.sleepEfficiency || 0)*100)
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'REM Sleep',
        tickFormat: formatDur,
        tickValues: sleepValues,
        fill: data => {
          /*
          const { y, inBed } = data.datum
          const percent = Math.round((y / inBed) * 100)
          return percent < 20 ? 'rgb(206, 0, 23)' : percent <= 25 ? 'rgb(251, 176, 60)' : 'rgb(14, 211, 22)'
          */
          return 'rgb(14, 211, 22)'
        },
        data: cycleData.map(cycle => {
          let y = 0
          let inBed = 0
          if (cycle.sleep) {
            inBed = cycle.sleep.inBedDuration
          }
          switch (type) {
            case 'whoop':
              if (cycle.sleep) {
                cycle.sleep.sleeps.map(sleep => {
                  y += sleep.remSleepDuration
                })
              }
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.rem * 1000 : 0
              break
            case 'garmin':
              y = cycle.remSleepDuration
              inBed = cycle.inBedDuration
              break
          }
          return {
            x: getX(cycle, true),
            y,
            inBed
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Slow-wave Sleep',
        tickValues: sleepValues,
        tickFormat: formatDur,
        fill: data => 'rgb(66, 145, 244)',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              if (cycle.sleep) {
                cycle.sleep.sleeps.map(sleep => {
                  y += sleep.slowWaveSleepDuration
                })
              }
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.deep * 1000: 0
              break
            case 'garmin':
              y = cycle.slowWaveSleepDuration
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Light Sleep',
        fill: data => 'rgb(251, 176, 60)',
        tickValues: sleepValues,
        tickFormat: formatDur,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              if (cycle.sleep) {
                cycle.sleep.sleeps.map(sleep => {
                  y += sleep.lightSleepDuration
                })
              }
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.light * 1000 : 0
              break
            case 'garmin':
              y = cycle.lightSleepDuration
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Awake',
        fill: 'rgb(206, 0, 23)',
        tickValues: sleepValues,
        tickFormat: formatDur,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              if (cycle.sleep) {
                cycle.sleep.sleeps.map(sleep => {
                  y += sleep.wakeDuration
                })
              }
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.awake * 1000: 0
              break
            case 'garmin':
              y = cycle.wakeDuration
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: <span>Sleep Pulse Ox (SPO<sub>2</sub>)</span>,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              if (cycle.sleep) {
                cycle.sleep.sleeps.map(sleep => {
                  // @TODO
                })
                if (cycle.sleep.sleeps.length > 0) {
                  y /= cycle.sleep.sleeps.length
                }
              }
              break
            case 'oura':
              y = 0
              break
            case 'garmin':
              y = cycle.spo2
              break
          }
          return {
            x: getX(cycle, true),
            y: Math.round(y)
          }
        })
      },
      {
        category: ["sleep"],
        yAxis: 'Respiratory Rate',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              if (cycle.sleep) {
                cycle.sleep.sleeps.map(sleep => {
                  y += sleep.respiratoryRate
                })
                if (cycle.sleep.sleeps.length > 0) {
                  y /= cycle.sleep.sleeps.length
                }
              }
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.breath_average : 0
              break
            case 'garmin':
              y = cycle.respiratoryRate
              break
          }
          return {
            x: getX(cycle, true),
            y: Math.round(y)
          }
        })
      },
      {
        category: ["sleep"],
        visible: () => type == 'whoop',
        yAxis: 'Sleep Consistency',
        tickValues: [0, 70, 90, 100],
        tickFormat: percentFormat,
        fill: sleepPerf,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              let sleeps = 0
              if (cycle.sleep) {
                cycle.sleep.sleeps.map(sleep => {
                  y += sleep.sleepConsistency
                  sleeps++
                })
              }
              if (sleeps) {
                y = Math.round(y / sleeps)
              }
              break
            case 'oura':
              // @TODO
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["overview"],
        yAxis: type === 'garmin' ? 'Stress Level' : 'Heart Rate Variability',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.recovery ? Math.round(cycle.recovery.heartRateVariabilityRmssd * 1000) : 0              
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.rmssd: 0
              break
            case 'garmin':
              y = cycle.stressLevel
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["overview"],
        yAxis: 'Resting Heart Rate',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.recovery ? cycle.recovery.restingHeartRate : 0
              break
            case 'oura':
              y = cycle.sleep ? cycle.sleep.hr_lowest: 0
              break
            case 'garmin':
              y = cycle.rhr
              break
          }
          return {
            x: getX(cycle, true),
            y: y
          }
        })
      },
      {
        category: ["workouts"],
        tickValues: [0, 1, 2, 3, 4, 5],
        visible: isWorkoutVisible,
        yAxis: 'Workouts',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                if (cycle.strain && cycle.strain.workouts) {
                  cycle.strain.workouts.forEach(workout => {
                    if (!workoutFilter(workout)) return
                    y++
                  })
                }
              }
              break
            case 'oura':
              // @TODO
              break
            case 'garmin':
              y = cycle.workouts
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["workouts"],
        tickValues: type == 'whoop' ? [0, 10, 14, 16, 18, 20] : undefined,
        tickFormat: type == 'whoop' ? score => score.toFixed(1) : percentFormat,
        visible: isWorkoutVisible,
        yAxis: type == 'whoop' ? 'Day Strain' : 'Activity',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                let strain = 0
                let count = 0
                if (cycle.strain.workouts) {
                  cycle.strain.workouts.forEach(workout => {
                    if(!workoutFilter(workout)) return
                    count++
                    strain += workout.score
                  })
                }
                if (count > 0) {
                  strain /= count
                }
                y = strain
              }
              break
            case 'oura':
              // @TODO
              break
            case 'garmin':
              y = cycle.workoutScore
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["workouts"],
        visible: isWorkoutVisible,
        yAxis: 'Workout Duration',
        tickFormat: formatDur,
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                let dur = 0
                if (cycle.strain.workouts) {
                  cycle.strain.workouts.forEach(workout => {
                    if(!workoutFilter(workout)) return
                    const workoutDur = Date.parse(workout.during.upper) - Date.parse(workout.during.lower)
                    dur += workoutDur
                  })
                }
                y = dur
              }
              break
            case 'oura':
              // @TODO
              break
            case 'garmin':
              y = cycle.workoutDur
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["workouts"],
        visible: isWorkoutVisible,
        yAxis: 'Max Heart Rate',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                let hr = 0
                if (cycle.strain.workouts) {
                  cycle.strain.workouts.forEach(workout => {
                    if(!workoutFilter(workout)) return
                    hr = Math.max(hr, workout.maxHeartRate)
                  })
                  y = hr
                }
              }
              break
            case 'oura':
              // @TODO
              break
            case 'garmin':
              y = cycle.maxHeartRate
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["workouts"],
        visible: isWorkoutVisible,
        yAxis: 'Average Heart Rate',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                let hr = 0
                let count = 0
                if (cycle.strain.workouts && cycle.strain.workouts.length > 0) {
                  cycle.strain.workouts.forEach(workout => {
                    if(!workoutFilter(workout)) return
                    console.log("averageHeartRate", workout.averageHeartRate)
                    hr += workout.averageHeartRate
                    count++
                  })
                  if (count) {
                    y = Math.round(hr / count)
                    console.log("hr", hr, 'y', y)
                  }
                }
              }
              break
            case 'oura':
              // @TODO
              break
            case 'garmin':
              y = cycle.avgHeartRate
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["workouts"],
        visible: isWorkoutVisible,
        yAxis: 'Calories',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              {
                let cal = 0
                if (cycle.strain.workouts && cycle.strain.workouts.length > 0) {
                  cycle.strain.workouts.forEach(workout => {
                    if(!workoutFilter(workout)) return
                    cal += Math.round(workout.kilojoules * 0.239006)
                  })
                  y = cal
                }
              }
              break
            case 'oura':
              // @TODO
              break
            case 'garmin':
              y = Math.round(cycle.workoutCalories)
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["overview"],
        visible: () => type == 'whoop',
        yAxis: 'Max Heart Rate',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.strain ? cycle.strain.maxHeartRate: 0
              break
            case 'oura':
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["overview"],
        visible: () => type == 'whoop',
        yAxis: 'Avg Heart Rate',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.strain ? cycle.strain.averageHeartRate: 0
              break
            case 'oura':
              // @TODO
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["meals", "overview"],
        yAxis: 'Calorie Deficit/Surplus',
        canBeNegative: true,
        data: meals.map((meal, i) => {
          let y0 = Math.round(meal.calories)
          const cycle = cycleData[i]
          let y = 0
          if (cycle) {
            switch (type) {
              case 'whoop':
                y = cycle.strain ? Math.round(cycle.strain.kilojoules * 0.239006) : 0
                break
              case 'oura':
                y = cycle.activity ? cycle.activity.cal_total : 0
                break
              case 'garmin':
                y = cycle.caloriesOut
                break
            }
          }
          if (y === 0 || y0 === 0) {
            y = 0
          } else {
            y = y0 - y
          }
          return {
            x: moment(meal.date).local().format(),
            y: y
          }
        })
      },
      {
        category: ["meals", "overview"],
        yAxis: category === 'overview' ? 'Calories In' : 'Calories',
        data: meals.map(cycle => {
          let y = Math.round(cycle.calories)
          return {
            x: moment(cycle.date).local().format(),
            y: y
          }
        })
      },
      {
        category: ["overview", "meals"],
        yAxis: category === 'overview' ? 'Calories Out' : 'Calories Burned',
        fill: 'rgb(14, 211, 22)',
        data: cycleData.map(cycle => {
          let y = 0
          switch (type) {
            case 'whoop':
              y = cycle.strain ? Math.round(cycle.strain.kilojoules * 0.239006) : 0
              break
            case 'oura':
              y = cycle.activity ? cycle.activity.cal_total : 0
              break
            case 'garmin':
              y = cycle.caloriesOut
              break
          }
          return {
            x: getX(cycle),
            y: y
          }
        })
      },
      {
        category: ["meals"],
        tickFormat: gramsFormat,
        yAxis: 'Carbohydrates',
        fill: '#9200A7',
        data: meals.map(cycle => {
          let y = Math.round(cycle.carbs)
          return {
            x: moment(cycle.date).local().format(),
            y: y
          }
        })
      },
      {
        category: ["meals"],
        tickFormat: gramsFormat,
        yAxis: 'Fat',
        fill: '#008178',
        data: meals.map(cycle => {
          let y = Math.round(cycle.fat)
          return {
            x: moment(cycle.date).local().format(),
            y: y
          }
        })
      },
      {
        category: ["meals"],
        tickFormat: gramsFormat,
        yAxis: 'Protein',
        fill: '#001A9A',
        data: meals.map(cycle => {
          let y = Math.round(cycle.protein)
          return {
            x: moment(cycle.date).local().format(),
            y: y
          }
        })
      },
      {
        category: ["weight"],
        yAxis: 'Weight',
        tickValues: !weights ? [0, 100, 200] : undefined,
        data: (weights || []).map(cycle => {
          let y = formatWeight(cycle.weight)
          return {
            x: moment(new Date(cycle.created)).local().format(),
            y: y
          }
        })
      },
    ]
    return <div className='uiDataCharts'>
      {charts.filter(chart => {
        if (!chart.category.find(x => x === category.scope)) {
          return false
        }
        if (!chart.data.find(d => d.y)) {
          return false
        }
        return chart.visible ? chart.visible() : true
      }).map(chart => {
        return <div className='uiSleepChart'>
          <div className='uiDataChartTitle'>{chart.yAxis}</div>
          <DataChart canBeNegative={chart.canBeNegative} points={chart.data} w={w} h={h} xAxis={xAxis} yAxis={chart.yAxis} tickValues={chart.tickValues} tickFormat={chart.tickFormat} fill={chart.fill}/>
          </div>
      })}
      </div>
  }
}

const ExertionStats = [
      {
        zone: 0,
        color: 'rgb(48, 143, 246)',
        name: "90-100%",
        range: [.9, 1.0],
        level: "MAX",
      },
      {
        zone: 1,
        color: 'rgb(99, 160, 214)',
        name: "80-90%",
        range: [.8, .9],
        level: "HARD",
      },
      {
        zone: 2,
        color: 'rgb(137, 229, 232)',
        name: "70-80%",
        range: [.7, .8],
        level: "MODERATE",
      },
      {
        zone: 3,
        color: 'rgb(172, 199, 232)',
        name: "60-70%",
        range: [.6, .7],
        level: "LIGHT",
      },
      {
        zone: 4,
        color: 'rgb(212, 224, 243)',
        name: "50-60%",
        range: [.5, .6],
        level: "VERY LIGHT",
      },
      {
        color: 'rgb(232, 238, 248)',
        zone: 5,
        name: "<50%",
        range: [0, 0.5],
        level: "REST",
      },
   ]

const formatDur = dur => moment.duration(dur, "milliseconds").format("h:mm", { trim: false })
const formatLap = dur => moment.duration(dur, "milliseconds").format("m:ss", { trim: false })

let gradientId = 0

export class WhoopWorkout extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
    const id = ++gradientId
    this.gradientId = 'gradientFill-'+id
    this.load().then(() => {
      console.log('loaded workout')
    }).catch(err => {
      const self = this
    })
    console.log("whoopworkout'", this.props.contact)
  }

  async load () {
    await Promise.all([this.loadWorkout(), this.loadHeartRateData()])
  }

  async loadWorkout () {
    if (this.props.workout.garmin) {
      return
    }
    const result = await this.props.me.getWhoopWorkout(this.props.contact.uid, this.props.workout.id)
    console.log("workout:", result)
    const { workout, voice } = result
    this.setState({
      workout: workout,
      voice: voice
    })
  }

  async loadHeartRateData () {
    const start = Date.parse(this.props.workout.during.lower)
    const end = Date.parse(this.props.workout.during.upper)
    if (this.props.workout.garmin) {
      debugger
      const garmin = this.props.workout.garmin
      const { max, avg, samples } = await this.props.me.getGarminHeartRateData(this.props.contact.uid, start, end)
      this.setState({
        maxHeartRate: this.props.workout.maxHeartRate || max,
        averageHeartRate: this.props.workout.averageHeartRate || avg,
        heartRate: { values: samples }
      })
      return
    }
    const heartRate = await this.props.me.getWhoopHeartRateData(this.props.contact.uid, start, end, 6)
    console.log("heartRate:", heartRate)
    this.setState({
      heartRate: heartRate
    })
  }

  render() {
    const workout = this.props.workout
    const w = Math.min(window.innerWidth, 700)
    const h = w / 2
    const workoutBegin = Date.parse(workout.during.lower)
    const workoutEnd = Date.parse(workout.during.upper)
    const workoutDur = workoutEnd - workoutBegin
    let laps
    const formatTime = (t, a) => {
      const lap = laps && laps[''+t]
      const when = moment(new Date(t))
      let s
      if (a) {
        s = when.format(`h:mm a`)
      } else {
        if (lap) return [lap, ' ']
        s = when.format(`h a`)
      }
      if (lap) {
        return [lap, s]
      }
      if (laps) {
        return [' ', s]
      }
      return s
    }
    const caloriesOut = Math.round(workout.kilojoules * 0.239006)
    const maxHeartRate = this.state.maxHeartRate || workout.maxHeartRate || 0
    const avgHeartRate = this.state.averageHeartRate || workout.averageHeartRate || 0
    const strain = workout.score
    const battery = workout.battery // hack for garmin
    const sportId = workout.sportId
    let zones = workout.zones
    const stats = ExertionStats
    const contact = this.props.contact
    console.log("whoopworkout", contact)
    const contactMax = this.props.me.getMaxHeartRate(contact.uid)     
    const invMax = 1 / (contactMax || maxHeartRate)
    const getZoneIndex = value => {
      const fraction = value.data * invMax;
      for (let i = 0; i < stats.length; i++) {
        const stat = stats[i]
        if (fraction >= stat.range[0]) {
          return i
        }
      }
      return 0
    }
    const events = []
    const hr = this.state.heartRate
    if (hr) {
      let z = 0
      let event = {
        start: workoutBegin,
        zoneIndex: stats.length - 1,
      }
      hr.values.map(value => {
        const index = getZoneIndex(value)
        if (event.zoneIndex != index) {
          event.end = value.time
          events.push(event)
          event = {
            start: value.time,
            zoneIndex: index,
          }
        }
      })
      event.end = workoutEnd
      events.push(event)
    }
    const newZones = [0, 0, 0, 0, 0, 0]
    events.forEach(event => {
      const dur = event.end - event.start
      newZones[event.zoneIndex] += dur
    })
    let dataArr
    if (hr) {
      dataArr = hr.values
    } else {
      dataArr = []
    }
    zones = newZones
    const tickValues = []
    let tick = moment(new Date(workoutBegin)).startOf('day')
    tickValues.push(workoutBegin)
    const hour = 1000 * 60 * 60
    while (tick < workoutEnd - 0.5 * hour) {
      if (tick > workoutBegin + 0.5 * hour) {
        tickValues.push(tick)
      }
      tick += hour
    }
    tickValues.push(workoutEnd)
    if (workout.garmin && workout.garmin.activityDetails) {
      console.log("garmin workout", workout.garmin)
      if (workout.garmin.activityDetails.laps.length > 1) {
        let prevLap
        workout.garmin.activityDetails.laps.forEach((lap, i) => {
          const when = lap.startTimeInSeconds * 1000
          if (when > workoutEnd) {
            return
          }
          if (prevLap) {
            let maxLaps
            if (isMobile()) {
              maxLaps = (i < 10 ? 30 : 20)
            } else {
              maxLaps = (i < 10 ? 50 : 45)
            } 
            const lapDur = when - prevLap
            console.log("lapDur", i+1, lapDur / (1000 * 60))
            if (lapDur < workoutDur / maxLaps) {
              prevLap = null
              return
            }
          }
          prevLap = when
          if (!laps) laps = {}
          laps[''+when] = i === 0 ? 'Lap 1' : i + 1
          tickValues.push(when)
        })
        tickValues.sort()
      }
    }
    console.log("laps", laps)
    const offsetToPercent = offset => {
      const percent = Math.round(((offset - workoutBegin) / workoutDur)*100)
      return percent + '%'
    }

    const f = d => ((d / workoutDur) * 100).toFixed(2)
    console.log(zones.map(f))
    console.log(newZones.map(f))

    const getStopColor = event => stats[event.zoneIndex].color
    
    const getStopOffset = event => {
      const startOffset = event.start
      const endOffset = event.end
      return { start: offsetToPercent(startOffset), end: offsetToPercent(endOffset) }
    }

    const hrPercent = Math.round((avgHeartRate / maxHeartRate) * 100)
    const exertionIndex = getZoneIndex({
      data: avgHeartRate
    })
    const exertionZone = stats[exertionIndex]
    //console.log("exertionIndex:", exertionIndex)
    //console.log("exertionZone:", exertionZone)
    const details = this.props.me.getWhoop().getWorkout(workout)

    const getValue = stat => newZones[stat.zone]


    let header
    let text
    if (this.state.voice) {
      const voice = this.state.voice
      const sentences =  voice.text.split('.')
      header = sentences[0] + '.'
      text = sentences.slice(1).join('.')
    }
    return <div className='uiWhoopEventDetails'>
      <div className={'uiWhoopSleep'  + (isMobile() ? ' uiWhoopSleepMobile': '')}>
      <div className='uiWhoopSleepDate'>{this.props.contact.displayName} | {formatDate(workout.during.upper)}</div>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title === undefined ?  details.name : this.props.title,
        low: 50,
        high: 80,
        topLeft: {
          value:  exertionZone.level,
          label: "EXERTION"
        },
        bottomLeft: {
          value: contactMax ? maxHeartRate + ' / ' + contactMax : maxHeartRate ,
          label: "MAX HR"
        },
        basementLeft: {
          value: caloriesOut || 'n/a',
          label: 'CALORIES',
        },
        topRight: battery === undefined ? {
          value: strain.toFixed(1),
          label: "STRAIN"
        } : {
          value: Math.abs(battery),
          label: "ACTIVITY"
        },
        bottomRight: {
          value: avgHeartRate,
          label: "AVG HR"
        },
        basementRight: {
          value: formatDur(workoutDur),
          label: "DURATION",
        },
        innerValue: hrPercent,
        innerColor: exertionZone.color,
        outerValue: battery === undefined ? Math.round(strain/21 * 100) : Math.abs(battery),
        logo: <div className='uiSleepLogoDur'>
          <img src={details.iconUrl}/>
        </div>
      }
    }
      />

    {this.state.voice && <div key='voice' className='uiWhoopSleepVoice'>
      <span className='uiWhoopSleepVoiceBodyHeader'>
      {header}
     </span>
     &nbsp;
      <span className='uiWhoopSleepVoiceBodyText'>
      {text}
      </span>
     </div>}
    
      <div className={'uiSleepChart tickLabelCount_' + tickValues.length}>
       <svg>
       <defs>
      <linearGradient id={this.gradientId}
              x1="0%"
              x2="100%"
              y1="0%"
              y2="0%"
       >
       {
         events.map(event => {
           const { start, end } = getStopOffset(event)
           if (start == end) return null
           const color = getStopColor(event)
           return [<stop offset={start} stopColor={color} stopOpacity="1" />,
                   <stop offset={end} stopColor={color} stopOpacity="1" />]
         })
       }
       </linearGradient>
       </defs>
       </svg>
      {<VictoryChart
       width={w}
       height={h}>
       
       <VictoryArea
       style={{
         data: { fill: `url(#${this.gradientId})`, stroke: 'rgb(246, 4, 40, 0)' }
       }}
       x={'time'}
       y={'data'}
       data={dataArr}/>
       <VictoryAxis dependentAxis tickValues={dataArr.length == 0 ? ['', ''] : undefined}/>
       <VictoryAxis
       tickValues={tickValues}
       style={
         {
           axis: { stroke: '#6d6d6d' },
           ticks: { stroke: laps ? 'transparent' : '#6d6d6d' },
           tickLabels: { color: '#6d6d6d', 'font-family': 'Arial', 'font-size': isMobile() ? 9 : 14}
         }
       }
       labelComponent={<VictoryLabel/>}
       tickFormat={t => formatTime(t, t == workoutBegin || t == workoutEnd)}/>
       </VictoryChart>}
    {(dataArr.length == 0) && <div className='uiSleepChartSpinner'><ReactSVG src={Spinner}/></div>}
       </div>
      <div key='stats' className='uiWhoopSleepStats'>
      {
        stats.map(stat => {
          return <div className={'uiWhoopSleepStat ' + stat.type}>
            <div className='uiWhoopSleepStatLegend' style={{background: stat.color}}/>
            <div className='uiWhoopSleepStatLabel uiHRZoneLevel'>{stat.level}</div>
            &nbsp;
            <div className='uiWhoopSleepStatLabel uiHRZonePercent'>{stat.name}</div>
            <div className='uiWhoopSleepStatValueAndPercent'>
            <div className='uiWhoopSleepStatValue'>{formatDur(getValue(stat))}</div>
            <div className='uiWhoopSleepStatPercent'>{Math.round((getValue(stat) / workoutDur) * 100)}%</div>
            </div>
            </div>
        })
      }
    </div>
      {this.renderLaps(workout)}
      </div>
      </div>
  }

  renderLaps = (workout) => {
    if (!(workout.garmin && workout.garmin.activityDetails)) return null
    const workoutEnd = Date.parse(workout.during.upper)
    let { fields, laps } = workout.garmin.activityDetails
    if (laps.length <= 1) return null
    let seen = {}
    fields = fields || []
    fields = fields.filter(f => {
      if (seen[f.field_name]) return false
      seen[f.field_name] = true
      return laps[0][f.field_name]
    })
    const header = <div className='uiWorkoutLapsRow'>
      <div className='uiWorkoutLapsColumn uiWorkoutLapsColumn1'>Lap</div>
      {fields.map(field => {
         return <div className='uiWorkoutLapsColumn'>{field.field_name}</div>
      })}
      <div className='uiWorkoutLapsColumn uiWorkoutLapsColumnLap'>Time</div>
    </div>
    return <div className='uiWorkoutLaps'>
      {header}
    {laps.map((lap, i) => {
      const end = i + 1 < laps.length ? laps[i+1].startTimeInSeconds * 1000 : workoutEnd
      const lapDur = end - laps[i].startTimeInSeconds * 1000
      if (lapDur <= 0) return null
        return <div className='uiWorkoutLapsRow'>
          <div className='uiWorkoutLapsColumn uiWorkoutLapsColumn1'>{i+1}</div>
          {fields.map(field => {
            let value = lap[field.field_name]
            let className = 'uiWorkoutLapsColumn'
            if (field.units === 'M') {
              value = value.toFixed(2)
              className += ' uiWorkoutLapsColumnNumber'
            }
            return <div className={className}>{value}</div>
          })}
          <div className='uiWorkoutLapsColumn uiWorkoutLapsColumnNumber uiWorkoutLapsColumnLap'>{formatLap(lapDur)}</div>
        </div>
      })
      }
    </div>
  }
}

export class WhoopSleepSummary extends Component {
  render() {
    const {
      needed,
      hrv,
      sleepEfficiency,
      sleepConsistency,
      inBed,
      respiratoryRate,
      score,
      slept,
      spo2
    } = this.props.profile
    const performance = Math.round(score) 
    return <div className={'uiWhoopSleep'  + (isMobile() ? ' uiWhoopSleepMobile': '')}>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title === undefined ? 'Sleep' : this.props.title,
        low: 70,
        high: 90,
        topLeft: {
          value: performance,
          label: "PERFORMANCE"
        },
        bottomLeft: {
          value: needed/1000 > 12 * 60 * 60 ? "> 12:00" : formatDur(needed),
          label: "SLEEP NEED"
        },
        basementLeft: sleepConsistency ? {
          value: Math.round(sleepConsistency) ,
          label: "CONSISTENCY"
        } : spo2 !== undefined ? {
          value: spo2 ? Math.round(spo2) : 'n/a',
          label: <span>SPO<sub>2</sub></span>//'SPO₂'
        } : null,
        topRight: {
          value: Math.round(sleepEfficiency * 100) ,
          label: "EFFICIENCY",
        },
        bottomRight: {
          value: formatDur(inBed),
          label: "TIME IN BED"
        },
        basementRight: {
          value: respiratoryRate ? Math.round(respiratoryRate) : 'n/a',
          label: "RESP. RATE"
        },
        outerValue: sleepEfficiency * 100,
        innerValue: score,
        logo: <div className='uiSleepLogoDur'>
          {formatDur(slept)}
        </div>
      }
    }
      />
      </div>
  }
}

export class WhoopWorkoutSummary extends Component {

  render() {
    const {
      avgHeartRate,
      caloriesOut,
      maxHeartRate,
      battery,
      strain,
      workoutDur,
      workouts
    } = this.props.profile
    const contactMax = this.props.me.getMaxHeartRate(this.props.contact.uid)
    const fraction = (contactMax || maxHeartRate) ? avgHeartRate / (contactMax || maxHeartRate) : 0
    let exertionZone
    const stats = ExertionStats
    for (let i = 0; i < stats.length; i++) {
      const stat = stats[i]
      if (fraction >= stat.range[0]) {
        exertionZone = stats[i]
        break
      }
    }
    const hrPercent = Math.round((avgHeartRate / contactMax * 100)) 
    return <div className={'uiWhoopSleep'  + (isMobile() ? ' uiWhoopSleepMobile': '')}>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title || "Workouts",
        low: 50,
        high: 80,
        topLeft: {
          value:  exertionZone.level,
          label: "EXERTION"
        },
        bottomLeft: {
          value: Math.round(maxHeartRate) + (contactMax ?  ' / ' + Math.round(contactMax) : ''),
          label: "MAX HR"
        },
        basementLeft: {
          value: Math.round(caloriesOut),
          label: 'CALORIES',
        },
        topRight: battery === undefined ?  {
          value: strain.toFixed(1),
          label: "STRAIN"
        } : {
          value: Math.abs(battery).toFixed(1),
          label: "ACTIVITY"
        },
        bottomRight: {
          value: Math.round(avgHeartRate),
          label: "AVG HR"
        },
        basementRight: {
          value: formatDur(workoutDur),
          label: "DURATION",
        },
        innerValue: hrPercent,
        innerColor: exertionZone.color,
        outerValue: strain ? Math.round(strain/21 * 100) : Math.abs(battery || 0),
        logo: <div className='uiSleepLogoDur'>{workouts}</div>
      }
    }
      />
      </div>
  }
}


export class WhoopSleep extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
    const id = ++gradientId
    this.gradientId = 'gradientFill-'+id
    this.load().then(() => {
      console.log('loaded sleep')
    }).catch(err => {
      const self = this
    })
    
  }

  async load () {
    await Promise.all([this.loadSleep(), this.loadHeartRateData()])
  }

  async loadSleep () {
    if (this.props.sleep.oura) {
      const sleep = this.props.sleep
      if (!sleep.events) {
        sleep.events = []
        const oura = this.props.sleep.oura
        const start = Date.parse(oura.sleep.bedtime_start)
        const end = Date.parse(oura.sleep.bedtime_end)
        const fiveMins = 1000 * 60 * 5
        const hypn = oura.sleep.hypnogram_5min
        let prev = new Date(start).toISOString()
        let prevType = 'wake'
        for (let i = 0; i < hypn.length; i++) {
          const x = hypn[i]
          const time = start + (i + 1) * fiveMins
          let type
          switch (x) {
            case '4':
              // awake
              type = 'wake'
              break
            case '3':
              // rem
              type = 'rem'
              break
            case '1':
              // sws
              type = 'sws'
              break
            case '2':
              // light
              type = 'light'
              break
            default:
              debugger
          }
          if (type != prevType) {
            const lower = prev
            const upper = new Date(time).toISOString()
            prev = upper
            sleep.events.push({
              during: {
                lower: lower,
                upper: upper
              },
              type: prevType
            })
            prevType = type
          }
        }
        sleep.events.push({
          during: {
            lower: prev,
            upper: new Date(end).toISOString()
          },
          type: prevType
        })
      }
      this.setState({
        sleep: sleep
      })
    } else if (this.props.sleep.garmin) {
      const sleep = this.props.sleep
      if (!sleep.events) {
        sleep.events = []
        const garmin = this.props.sleep.garmin
        const start =  garmin.start
        const end = garmin.end
        let prev = new Date(start).toISOString()
        let prevType = 'wake'
        let type
        for (const t in garmin.sleepLevelsMap) {
          switch (t) {
            case 'awake':
              type = 'wake'
              break
            case 'rem':
              // rem
              type = 'rem'
              break
            case 'deep':
              // sws
              type = 'sws'
              break
            case 'light':
              // light
              type = 'light'
              break
            default:
              debugger
          }
          for (const event of garmin.sleepLevelsMap[t]) {
            const lower = new Date(event.startTimeInSeconds * 1000).toISOString()
            const upper = new Date(event.endTimeInSeconds * 1000).toISOString()
            sleep.events.push({
              key: event.startTimeInSeconds,
              during: {
                lower: lower,
                upper: upper
              },
              type: type
            })
          }
        }
        sleep.events.sort((x, y) => x.key - y.key)
      }
      if (sleep.score) {
        sleep.needBreakdown.total = Math.round((100/sleep.score) * sleep.sleepEfficiency * sleep.inBedDuration)
      }
      this.setState({
        sleep: sleep
      })
    } else {
      await this.loadWhoopSleep()
    }
  }
    
  async loadWhoopSleep () {
    const result = await this.props.me.getWhoopSleep(this.props.contact.uid, this.props.sleep.cycleId, this.props.sleep.id)
    console.log("sleep:", result)
    const { sleep, voice } = result
    this.setState({
      sleep: sleep,
      voice: voice
    })
  }

  async loadHeartRateData () {
    if (this.props.sleep.oura) {
      const sleep = this.props.sleep.oura.sleep
      const fiveMins = 1000 * 5 * 60
      const start = Date.parse(sleep.bedtime_start)
      const values = []
      let prev = sleep.hr_5min.find(bpm => bpm)
      sleep.hr_5min.forEach((bpm, i) => {
        values.push({
          data: bpm || prev,
          time: start + i * fiveMins
        })
        if (bpm) {
          prev = bpm
        }
      })
      this.setState({
        heartRate: {
          values: values
        }
      })
      return
    }
    const start = Date.parse(this.props.sleep.during.lower)
    const end = Date.parse(this.props.sleep.during.upper)
    if (this.props.sleep.garmin) {
      const garmin = this.props.sleep.garmin
      const { max, avg, samples } = await this.props.me.getGarminHeartRateData(this.props.contact.uid, start, end)
      this.setState({
        maxHeartRate: max,
        averageHeartRate: avg,
        heartRate: { values: samples }
      })
      return
    }
    const heartRate = await this.props.me.getWhoopHeartRateData(this.props.contact.uid, start, end, 60)
    //console.log("heartRate:", heartRate)
    this.setState({
      heartRate: heartRate
    })
  }

  render() {
    const sleep = this.props.sleep
    const inBed = formatDur(sleep.inBedDuration)
    const slept = formatDur(sleep.qualityDuration)
    const performance = Math.round(sleep.score)
    const needed = sleep.needBreakdown ? formatDur(sleep.needBreakdown.total) : 'n/a'
    const sleepEfficiency = Math.round(sleep.sleepEfficiency * 100)
    let dataArr
    const hr = this.state.heartRate
    let s = this.state.sleep
    let respiratoryRate = 0
    let hrv = 0
    if (hr) {
      dataArr = hr.values
    } else {
      dataArr = []
    }
    const w = Math.min(window.innerWidth, 700)
    const h = w / 2
    const sleepBegin = Date.parse(sleep.during.lower)
    const sleepEnd = Date.parse(sleep.during.upper)
    if (sleep.recovery) {
      hrv = Math.round(sleep.recovery.heartRateVariabilityRmssd * 1000)
      if (sleep.oura) {
        debugger
      }
    }
    respiratoryRate = Math.round(sleep.respiratoryRate)
    const sleepDur = sleepEnd - sleepBegin
    const Colors = {
      wake: 'rgb(206, 0, 23)',
      latency: 'white',
      light: 'rgb(251, 176, 60)',
      disturbances: 'rgb(206, 0, 23)',
      sws: 'rgb(67, 144, 244)',
      rem: 'rgb(38, 182, 68)'
    }
    const hours = sleepDur / (1000 * 60 * 60)
    const tickValues = []
    let tick = moment(new Date(sleepBegin)).startOf('day').toDate().getTime()
    tickValues.push(sleepBegin)
    const hour = 1000 * 60 * 60
    let offset = 0.5 * hour
    while (tick < sleepEnd - offset) {
      if (tick > sleepBegin + offset) {
        tickValues.push(tick)
      }
      tick += hour
    }
    tickValues.push(sleepEnd)
    const formatTime = (t, endpoint) => {
      const when = moment(new Date(t))
      if (endpoint) {
        return when.format(`h:mm a`)
      } else if (tickValues.length < 12) {
        return when.format(`h a`)
      } else {
        return when.format('h')
      }
    }
    
    const offsetToPercent = offset => {
      const percent = Math.round(((offset - sleepBegin) / sleepDur)*100)
      return percent + '%'
    }

    const getStopColor = event => Colors[event.type]

    const getStopOffset = event => {
      const startOffset = Date.parse(event.during.lower)
      const endOffset = Date.parse(event.during.upper)
      return { start: offsetToPercent(startOffset), end: offsetToPercent(endOffset) }
    }
    let stats
    if (!s) {
      s = {
        remSleepDuration: 0,
        slowWaveSleepDuration: 0,
        lightSleepDuration: 0,
        wakeDuration: 0,
        events: [],
      }
    }
      
    if (s) {
       stats = [
      {
        name: "REM Sleep",
        value: s.remSleepDuration,
        type: 'rem'
      },
      {
        name: "Slow-wave Sleep",
        value: s.slowWaveSleepDuration,
        type: 'sws'
      },
      {
        name: "Light Sleep",
        value: s.lightSleepDuration,
        type: 'light'
      },
      {
        name: "Awake",
        value: s.wakeDuration,
        type: 'wake'
      }
       ]
    } else {
      stats = []
    }
    const isNap = this.props.sleep.isNap
    let voice = this.state.voice
    return <div className='uiWhoopEventDetails'>
      <div className={'uiWhoopSleep'  + (isMobile() ? ' uiWhoopSleepMobile': '')}>
      <div className='uiWhoopSleepDate'>{this.props.contact.displayName} | {formatDate(sleep.during.upper)}</div>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title === undefined ? 'Sleep' : this.props.title,
        low: 70,
        high: 90,
        topLeft: {
          value: performance,
          label: "PERFORMANCE"
        },
        bottomLeft: isNap ? {}: {
          value: needed,
          label: "SLEEP NEED"
        },
        basementLeft: sleep.garmin ? {
          value: sleep.garmin.averageSpo2 ? Math.round(sleep.garmin.averageSpo2) : 'n/a',
          label: 'SPO2'
         } : isNap ? {} : {
          value: hrv || 'n/a',
          label: "HRV"
        },
        topRight: {
          value: sleepEfficiency,
          label: "EFFICIENCY"
        },
        bottomRight: {
          value: inBed,
          label: "TIME IN BED"
        },
        basementRight: {
          value: respiratoryRate || 'n/a',
          label: "RESP. RATE"
        },
        outerValue: sleep.sleepEfficiency * 100,
        innerValue: sleep.score,
        logo: <div className='uiSleepLogoDur'>
          {slept}
        </div>
      }
    }
      />

    {voice && <div key='voice' className='uiWhoopSleepVoice'>
      <span className='uiWhoopSleepVoiceBodyHeader'>
      {voice.header}.
     </span>
     &nbsp;
      <span className='uiWhoopSleepVoiceBodyText'>
      {voice.text}
      </span>
     </div>}
    
      <div className={'uiSleepChart tickLabelCount_' + tickValues.length}>
       <svg>
       <defs>
      <linearGradient id={this.gradientId}
              x1="0%"
              x2="100%"
              y1="0%"
              y2="0%"
       >
       {
         s.events.map(event => {
           const color = getStopColor(event)
           const { start, end } = getStopOffset(event)
           return [<stop offset={start} stopColor={getStopColor(event)} stopOpacity="1" />,
                   <stop offset={end} stopColor={getStopColor(event)} stopOpacity="1" />]
         })
       }
       </linearGradient>
       </defs>
       </svg>
      {<VictoryChart
       width={w}
       height={h}>
       
       <VictoryArea
       style={{
         data: { fill: `url(#${this.gradientId})`, stroke: 'rgb(246, 4, 40, 0)' }
       }}
       x={'time'}
       y={'data'}
       data={dataArr}/>
       <VictoryAxis dependentAxis tickValues={dataArr.length === 0 ? ['', ''] : undefined}/>
       <VictoryAxis
       tickValues={tickValues}
       style={
         {
           axis: { stroke: '#6d6d6d' },
           ticks: { stroke: '#6d6d6d', size: 8 },
           tickLabels: { color: '#6d6d6d', 'font-family': 'Arial', 'font-size': isMobile() ? 9 : 14}
         }
       }
       labelComponent={<VictoryLabel/>}
       tickFormat={t => formatTime(t, t == sleepBegin || t == sleepEnd)}/>
       </VictoryChart>}
    {(dataArr.length == 0 || s.events.length == 0) && <div className='uiSleepChartSpinner'><ReactSVG src={Spinner}/></div>}
       </div>
      <div key='stats' className='uiWhoopSleepStats'>
      {
        stats.map(stat => {
          return <div className={'uiWhoopSleepStat ' + stat.type}>
            <div className='uiWhoopSleepStatLegend'/>
            <div className='uiWhoopSleepStatLabel'>{stat.name}</div>
            <div className='uiWhoopSleepStatValueAndPercent'>
            <div className='uiWhoopSleepStatValue'>{formatDur(stat.value)}</div>
            <div className='uiWhoopSleepStatPercent'>{Math.round((stat.value / sleepDur) * 100)}%</div>
            </div>
            </div>
        })
      }
    </div>
    </div>
   </div>
  }
}

export class WhoopGenericProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  renderNew() {
    const { title, back, logo, low, high, innerColor, innerValue, outerValue, extraClassName,
            topLeft, bottomLeft, topRight, bottomRight, 
            basementLeft, basementRight
          } = this.props.options
    let backClick
    if (back) {
      backClick = e => {
        e.preventDefault()
        e.stopPropagation()
        back()
      }
    }
    let className = 'uiWhoopProfile'
    if (extraClassName) {
      className += ' ' + this.props.extraClassName
    }
    if (innerValue < low) {
      className += ' uiWhoopProfileRecoveryLow'
    } else if (innerValue >= high) {
      className += ' uiWhoopProfileRecoveryHigh'
    }
    if (isMobile()) {
      className += ' uiWhoopProfileMobile'
    }
    let innerWidth = 0.7
    let stroke = 8
    return <div className='uiWhoopGenericProfile'>
      <div className='uiWhoopGenericProfileHeader'>
      {<div className={'uiCalendarScopeBack' + (back ? " uiCalendarScopeForward" : " uiCalendarScopeBack")}>
       <div className='uiCalendarScopeBackIcon'><ReactSVG src={Back}/></div>
       </div>}
      <div className='uiWhoopGenericProfileTitle'>
       {title}
    </div>
      </div>
      <div className={className}>
      <div className='uiWhoopProfileLeft'>
      <div className='uiWhoopProfileTopWithLegend'>
      <div className='uiWhoopProfileTop'>
      <div className='uiWhoopProfilePercent'>{topLeft.value}</div>
      <div className='uiWhoopProfileTopLabel'>{topLeft.label}</div>
      </div>
      <div className='uiWhoopProfileLeftLegend' style={innerColor ? { background: innerColor} : null}/>
      </div>
      <div className='uiWhoopProfileLeftMargin'/>
      <div className='uiWhoopProfileTopWithLegend'>
      <div className='uiWhoopProfileTop'>
      <div className='uiWhoopProfilePercent'>{topRight.value}</div>
      <div className='uiWhoopProfileTopLabel'>{topRight.label}</div>
      </div>
      <div className='uiWhoopProfileRightLegend'/>
      </div>
    </div>


      <div className='uiWhoopProfileCenter'>
      <div className='uiWhoopProfileProgressBarOuter'>
      <CircularProgressbar strokeWidth={8} value={outerValue}/>
      {innerValue !== null && <div className='uiWhoopProfileProgressBarInner'>
      <CircularProgressbar  styles={innerColor ? { path: { stroke: innerColor } } : undefined} strokeWidth={(1 / innerWidth) * stroke} value={innerValue}/>
       </div>}
      <div className='uiWhoopProfileLogo'>
      {logo || <ReactSVG src={WhoopLogo}/>}
    </div>
      </div>


    </div>

      <div className='uiWhoopProfileRight'>

      <div className='uiWhoopProfileBottom'>
      <div className='uiWhoopProfileBottomLeftLegend'/>
      <div className='uiWhoopProfileBottomWithLegend'>
      <div className='uiWhoopProfileBottomValue'>{bottomLeft.value}</div>
      <div className='uiWhoopProfileBottomLabel'>{bottomLeft.label}</div>
      </div>
      </div>

      <div className='uiWhoopProfileBottom'>
      <div className='uiWhoopProfileBottomRightLegend'/>
      <div className='uiWhoopProfileBottomWithLegend'>
      <div className='uiWhoopProfileBottomValue'>{bottomRight.value}</div>
      <div className='uiWhoopProfileBottomLabel'>{bottomRight.label}</div>
      </div>
      </div>
      
      {basementLeft && <div className='uiWhoopProfileBottom'>
      <div className='uiWhoopProfileBasementLeftLegend'/>
      <div className='uiWhoopProfileBottomWithLegend'>
      <div className='uiWhoopProfileBottomValue'>{basementLeft.value}</div>
       <div className='uiWhoopProfileBottomLabel'>{basementLeft.label}</div>
       </div>
       </div>}
    {basementRight && <div className='uiWhoopProfileBasement'>
      <div className='uiWhoopProfileBasementRightLegend'/>
      <div className='uiWhoopProfileBottomWithLegend'>
      <div className='uiWhoopProfileBasementValue'>{basementRight.value}</div>
     <div className='uiWhoopProfileBasementLabel'>{basementRight.label}</div>
     </div>
       </div>}

      
      </div>
      

      </div>
      </div>
  }


  render() {
    if (true) return this.renderNew()
    const { title, back, logo, low, high, innerColor, innerValue, outerValue, extraClassName,
            topLeft, bottomLeft, topRight, bottomRight, 
            basementLeft, basementRight
          } = this.props.options
    let backClick
    if (back) {
      backClick = e => {
        e.preventDefault()
        e.stopPropagation()
        back()
      }
    }
    let className = 'uiWhoopProfile'
    if (extraClassName) {
      className += ' ' + this.props.extraClassName
    }
    if (innerValue < low) {
      className += ' uiWhoopProfileRecoveryLow'
    } else if (innerValue >= high) {
      className += ' uiWhoopProfileRecoveryHigh'
    }
    if (isMobile()) {
      className += ' uiWhoopProfileMobile'
    }
    let innerWidth = 0.7
    let stroke = 8
    return <div className='uiWhoopGenericProfile'>
      <div className='uiWhoopGenericProfileHeader'>
      {<div className='uiCalendarScopeBack' onClick={backClick} style={back ? null: { display: 'none'}}>
       <div className='uiCalendarScopeBackIcon'><ReactSVG src={Back}/></div>
       </div>}
      <div className='uiWhoopGenericProfileTitle'>
       {title}
    </div>
      </div>
      <div className={className}>
      <div className='uiWhoopProfileLeft'>
      <div className='uiWhoopProfileTopWithLegend'>
      <div className='uiWhoopProfileLeftLegend' style={innerColor ? { background: innerColor} : null}/>
      <div className='uiWhoopProfileTop'>
      <div className='uiWhoopProfilePercent'>{topLeft.value}</div>
      <div className='uiWhoopProfileTopLabel'>{topLeft.label}</div>
      </div>
      </div>

      <div className='uiWhoopProfileBottom'>
      <div className='uiWhoopProfileBottomLeftLegend'/>
      <div className='uiWhoopProfileBottomLabel'>{bottomLeft.label}</div>
      <div className='uiWhoopProfileBottomValue'>{bottomLeft.value}</div>
      </div>
      {basementLeft && <div className='uiWhoopProfileBasement'>
      <div className='uiWhoopProfileBasementLeftLegend'/>
      <div className='uiWhoopProfileBasementLabel'>{basementLeft.label}</div>
      <div className='uiWhoopProfileBasementValue'>{basementLeft.value}</div>
       </div>}
    
    </div>


      <div className='uiWhoopProfileProgressBarOuter'>
      <CircularProgressbar strokeWidth={8} value={outerValue}/>
      {innerValue !== null && <div className='uiWhoopProfileProgressBarInner'>
      <CircularProgressbar  styles={innerColor ? { path: { stroke: innerColor } } : undefined} strokeWidth={(1 / innerWidth) * stroke} value={innerValue}/>
       </div>}
      <div className='uiWhoopProfileLogo'>
      {logo || <ReactSVG src={WhoopLogo}/>}
    </div>
      </div>




      <div className='uiWhoopProfileRight'>
      <div className='uiWhoopProfileTopWithLegend'>
      <div className='uiWhoopProfileTop'>
      <div className='uiWhoopProfilePercent'>{topRight.value}</div>
      <div className='uiWhoopProfileTopLabel'>{topRight.label}</div>
      </div>
      <div className='uiWhoopProfileRightLegend'/>
      </div>
      
      <div className='uiWhoopProfileBottom'>
      <div className='uiWhoopProfileBottomLabel'>{bottomRight.label}</div>
      <div className='uiWhoopProfileBottomValue'>{bottomRight.value}</div>
      <div className='uiWhoopProfileBottomRightLegend'/>
      </div>
      {basementRight && <div className='uiWhoopProfileBasement'>
      <div className='uiWhoopProfileBasementLabel'>{basementRight.label}</div>
      <div className='uiWhoopProfileBasementValue'>{basementRight.value}</div>
      <div className='uiWhoopProfileBasementRightLegend'/>
       </div>}
      
    </div>
      </div>
      </div>
  }
}


export class WhoopProfile extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    const profile = this.props.profile
    let recovery = profile.recovery
    let strain = profile.strain
    let caloriesOut = profile.caloriesOut
    let caloriesIn = profile.caloriesIn
    let bedtime = profile.bedtime
    let className = 'uiWhoopProfile'
    let rhr = profile.rhr
    let hrv = profile.hrv
    const strainPercent = strain / 21 * 100
    const recoveryLabel = recovery
    return <div className='uiWhoopProfileSummary uiWhoopSleep'>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title || 'Overview',
        low: 33.3,
        high: 66.7,
        topLeft: {
          value: recoveryLabel,
          label: 'RECOVERY'
        },
        bottomLeft: {
          value: hrv || 'n/a',
          label: "HRV"
        },
        basementLeft: {
          value: caloriesIn || 'n/a',
          label: "CALORIES IN"
        },
        topRight: {
          value: strain.toFixed(1),
          label: "DAY STRAIN"
        },
        bottomRight: {
          value: bedtime,
          label: "BEDTIME"
        },
        outerValue: strainPercent,
        innerValue: recovery,
        basementRight: {
          value: caloriesOut,
          label: "CALORIES OUT"
        },
      }
    }/>
      </div>
  }
}

export class OuraProfile extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    const {
      readiness,
      caloriesIn,
      rhr,
      hrv,
      caloriesOut,
      activity
    } = this.props.profile

    return <div className='uiWhoopProfileSummary uiWhoopSleep'>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title || 'Overview',
        low: 33.3,
        high: 66.7,
        topLeft: {
          value: readiness ,
          label: 'READINESS'
        },
        bottomLeft: {
          value: hrv || 'n/a',
          label: "HRV"
        },
        basementLeft: {
          value: caloriesIn || 'n/a',
          label: "CALORIES IN"
        },
        topRight: {
          value: activity ,
          label: 'ACTIVITY'
        },
        bottomRight: {
          value: rhr,
          label: "RHR"
        },
        outerValue: activity,
        innerValue: readiness,
        basementRight: {
          value: caloriesOut,
          label: "CALORIES OUT"
        },
        logo: <ReactSVG src={OuraLogo}/>
      }
    }/>
      </div>
  }
}

export class GarminProfile extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    const {
      readiness,
      battery,
      caloriesIn,
      rhr,
      hrv,
      stressLevel,
      caloriesOut,
      activity
    } = this.props.profile

    return <div className='uiWhoopProfileSummary uiWhoopSleep'>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title || 'Overview',
        low: 33.3,
        high: 66.7,
        topLeft: {
          value: readiness ,
          label: 'RECOVERY'
        },
        bottomLeft: {
          value: stressLevel <= 0 ? 'n/a' : stressLevel ,
          label: 'STRESS LEVEL'
        },
        basementLeft: {
          value: caloriesIn || 'n/a',
          label: "CALORIES IN"
        },
        topRight: {
          value: activity ,
          label: 'ACTIVITY'
        },
        bottomRight: {
          value: rhr,
          label: "RHR"
        },
        outerValue: Math.abs(activity),
        innerValue: readiness,
        basementRight: {
          value: caloriesOut,
          label: "CALORIES OUT"
        },
        logo: <ReactSVG src={GarminLogo}/>
      }
    }/>
      </div>
  }
}

export class TodoProfile extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    const {
      todo,
      done,
      better,
      worse,
      expected,
      canceled
    } = this.props.profile
    console.log("todo-profile render", this.props.profile)
    const total = todo + done
    let outer = 0
    let inner = 0
    if (total > 0) {
      inner = todo / total * 100
      outer = done / total * 100
    }
    return <div className='uiWhoopProfileSummary uiWhoopSleep uiTodoProfile'>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: (this.props.title && capitalize(this.props.title)) || 'Plans',
        topLeft: {
          value: todo ,
          label: 'TO-DO'
        },
        topRight: {
          value: done,
          label: 'DONE'
        },
        bottomLeft: {
          value: expected,
          label: "AS EXPECTED"
        },
        basementLeft: {
          value: worse,
          label: "WORSE"
        },
        basementRight: {
          value: canceled,
          label: 'CANCELED'
        },
        bottomRight: {
          value: better,
          label: "BETTER"
        },
        outerValue: outer,
        innerValue: inner,
        logo: <div className='TodoLogo'><ReactSVG src={ToDoListIcon}/></div>
      }
    }/>
      </div>
  }
}


export class FitbitProfile extends Component {
  constructor (props) {
    super(props)
  }
  render() {
    const {
      readiness,
      caloriesIn,
      rhr,
      hrv,
      caloriesOut,
      activity
    } = this.props.profile

    return <div className='uiWhoopProfileSummary uiWhoopSleep'>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title || 'Overview',
        low: 33.3,
        high: 66.7,
        topLeft: {
          value: readiness ,
          label: 'READINESS'
        },
        bottomLeft: {
          value: hrv,
          label: "HRV"
        },
        basementLeft: {
          value: caloriesIn || 'n/a',
          label: "CALORIES IN"
        },
        topRight: {
          value: activity ,
          label: 'ACTIVITY'
        },
        bottomRight: {
          value: rhr,
          label: "RHR"
        },
        outerValue: activity,
        innerValue: readiness,
        basementRight: {
          value: caloriesOut,
          label: "CALORIES OUT"
        },
        logo: <div className='FitbitLogo'><img src={FitbitIcon}/></div>
      }
    }/>
      </div>
  }
}


const isSameDay = (x, y) =>  moment(x).local().startOf('day').toDate().getTime() === moment(y).local().startOf('day').toDate().getTime()

export class WeightProfile extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { calendar, min, max, start, end, avg, weights, last } = this.props.profile
    let className = 'uiWeightProfile uiWhoopSleep'  + (isMobile() ? ' uiWhoopSleepMobile': '')
    const getWeight = cycle => cycle ? cycle.weight : 0
    let outerValue = 0
    if (weights.length === 0) {
      className += ' uiProfileBlank'
    } else {
      outerValue = Math.round(100 * getWeight(end) / (getWeight(start) + getWeight(end)))
    }
    let lastValue = 'NEVER'
    if (last) {
      if ((calendar.view === 'day' && isSameDay(last.created, calendar.end)) || isSameDay(last.created, new Date())) {
        lastValue = moment(last.created).format('h:mm A')
      } else {
        lastValue = formatDayOfMonth(last.created).join(' ')
      }
    }
    return <div className={className}>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title || 'Weight',
        low: 50,
        high: 80,
        topLeft: {
          value: formatWeight(getWeight(start)),
          label: "START"
        },
        bottomLeft: {
          value: formatWeight(getWeight(max)),
          label: "MAX"
        },
        basementLeft: {
          value: lastValue,
          label: 'LAST',
        },
        topRight: {
          value: formatWeight(getWeight(end)),
          label: 'END'
        },
        bottomRight: {
          value: formatWeight(getWeight(min)),
          label: 'MIN'
        },
        basementRight: {
          value: weights.length,
          label: 'WEIGHTS'
        },
        innerValue: null,
        outerValue: outerValue,
        logo: <div className='uiSleepLogoDur'>
          {formatWeight(avg)}
        </div>
      }}
      />
      </div>
  }
}


export class MealsProfile extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { sugars, days, caloriesIn, caloriesOut, meals, protein, fat, hydration, carbs, total } = this.props.profile
    let className = 'uiMealsProfile uiWhoopSleep'  + (isMobile() ? ' uiWhoopSleepMobile': '')
    let topRight
    let topRightLabel
    let outerValue
    let basementRight
    let basementRightLabel
    if (sugars === undefined) {
      topRightLabel = "BURNED"
      topRight = Math.round(caloriesOut)
      const totalCalories = caloriesIn + caloriesOut
      outerValue = totalCalories ? caloriesOut / totalCalories * 100 : 0
      if (!totalCalories) {
        className += ' uiProfileBlank'
      }
      let delta = Math.round(caloriesIn - caloriesOut)
      basementRightLabel = "DAILY"
      delta = (delta > 0 ? '+' : '') + delta + ' cal'
      basementRight = caloriesIn ? delta : 'n/a'
    } else {
      const percent = (sugars / (total || 1)) * 100
      topRightLabel = "SUGARS"
      topRight = Math.round(percent) + "%"
      outerValue = percent
      className += " uiMealsProfileSugars"
      basementRightLabel = "SUGARS"
      basementRight =  Math.round(sugars) + 'g'
    }
    let protein_
    let fat_
    let carbs_
    if (true) {
      const fmt = x => Math.round((x / 100) * (total/(days || 1))) + 'g'
      protein_ = fmt(protein)
      fat_ = fmt(fat)
      carbs_ = fmt(carbs)
    } else {
      protein_ = protein + '%'
      fat_ = fat + '%'
      carbs_ = carbs + '%'
    }
    return <div className='uiMealsProfile uiWhoopSleep'>
      <div className={className}>
      <WhoopGenericProfile
    options={
      {
        back: this.props.back,
        title: this.props.title === undefined ? 'Meals' : this.props.title,
        low: 50,
        high: 80,
        topLeft: {
          value: caloriesIn || 'n/a',
          label: "CALORIES"
        },
        bottomLeft: {
          value: carbs_,
          label: "CARBS"
        },
        basementLeft: {
          value: protein_,
          label: 'PROTEIN',
        },
        topRight: {
          value: topRight,
          label: topRightLabel
        },
        bottomRight: {
          value: fat_,
          label: "FAT"
        },
        basementRight: {
          value: basementRight,
          label: basementRightLabel
        },
        innerValue: null,
        outerValue: Math.round(outerValue),
        logo: <div className='uiMealProfilePie'>
          <VictoryChart>
          <VictoryAxis style={{ 
    axis: {stroke: "transparent"}, 
    ticks: {stroke: "transparent"},
    tickLabels: { fill:"transparent"} 
}} />
          <VictoryPie
        labelRadius={45}
        labels={({datum}) => datum.y ? datum.y + '%' : ''}
        style={{
          labels: { fill: 'white', fontSize: 20 },
          data: {
            fill: (data) => {
              const x = data.datum.x
              switch (x) {
                case 'Protein':
                  return '#001A9A'
                case 'Fat':
                  return '#008178'
                case 'Carbs':
                  return '#9200A7'
              }
            }
          }
        }}
        data={
          [
            {x: "Protein",  y: protein },
            {x: "Fat",  y: fat },
            {x: "Carbs",  y: carbs }
          ]
        }
          />
          </VictoryChart>
        </div>
      }
    }
      />
      </div>
      </div>
  }
}
  

const computeFoodProfile = foods => {
  const nutrients = toFDA(foods)
  let protein = 0
  let carbs = 0
  let fat = 0
  let meals = 0
  let calories = 0
  let meals1 = 0
  let sugars = 0
  foods.map(food => {
    let cal = Math.round(food.nutrition ? food.nutrition.nf_calories : food.nf_calories || 0)
    const count = food.count === undefined ? 1 : food.count
    cal *= count
    calories += cal
    if (food.nutrition) {
      let total = 0
      let p = food.nutrition.nf_protein
      total += p
      let f = food.nutrition.nf_total_fat
      total += f
      let c = food.nutrition.nf_total_carbohydrate
      total += c
      if (total) {
        meals1++
        carbs += c * count
        fat += f * count
        protein += p * count
      }
      sugars += food.nutrition.nf_sugars * count
    }
  })
  const total = protein + carbs + fat
  if (total > 0) {
    protein /= total
    carbs /= total
    fat /= total
  }
  const profile = {
    days: 1,
    meals: 1,
    protein: Math.round(protein * 100),
    carbs: Math.round(carbs * 100),
    fat: Math.round(fat * 100),
    caloriesIn: Math.round(calories),
    sugars,
    total,
  }
  return { nutrients, profile }
}

export const renderFoodProfile = (opts) => {
  const { me, title, subtitle, foods, onClick } = opts
  const { nutrients, profile } = computeFoodProfile(foods)
  return <div className='uiWhoopEventDetails'>
    <div className='uiMealDetails'>
    <div className='uiWhoopSleepDate'>{subtitle}</div>
    <MealsProfile title={title} profile={profile} me={me}/>
    <div className='uiMealDetailsFoods'>
    {
      foods.map(food => {
        const name = getFoodName(food)
        let calories = (food.nutrition ? food.nutrition.nf_calories : food.nf_calories || 0)
        calories *= food.count === undefined ? 1 : food.count
        calories = Math.round(calories)
        const onClickFood = foods.length > 1 ? () => onClick(food) : null
        return <div className={'uiBodyFood' + (onClickFood ? ' uiBodyFoodClickable' : '')} onClick={onClickFood}>
          <div className='uiBodyFoodNameAndImage uiFoodProfileNameAndImage'>
          <div className='uiBodyFoodCount'>{formatFoodCount(food)}</div>
          <FoodLogo food={food}/><div className='uiBodyFoodName'>{name}</div></div><div className='uiBodyFoodCal'>{calories ? `${calories} cal` : ''}</div>
          </div>
      })
    }
  </div>
    <div className='uiMealNutrition'>
    <NutritionLabel {...nutrients}/>
    </div>
    </div>
    </div>
}
