import {UserAgent} from 'express-useragent'


let userAgent
function getUserAgent() {
    if (!userAgent) userAgent = new UserAgent().parse(navigator.userAgent)
    return userAgent
}

console.log("userAgent: ", getUserAgent())


function isMobile(noReally) {
    const userAgent = getUserAgent()
  return userAgent.isMobile && !(isIPad() || userAgent.isAndroidTablet)
}

function isTabletOrMobile() {
    return isTablet() || isMobile(true)
}

function isTablet() {
    const userAgent = getUserAgent()
    const result = userAgent.isiPad || userAgent.isAndroidTablet || userAgent.isTablet
    if (!result) {
        if (userAgent.isSafari && window.innerWidth < window.innerHeight) { // simulator
            return true
        }
    }
    return result
}

function isApple() {
    const ua = getUserAgent()
    return ua.isMac || ua.isSafari
}

function isIOS() {
  return (isMobile() || isIPad()) && getUserAgent().isSafari
}

function isSafari() {
    return getUserAgent().isSafari
}

function isChrome() {
    return getUserAgent().isChrome
}

function isFirefoxDesktop() {
    return getUserAgent().isFirefox && isDesktop()
}

function isDesktop() {
  return getUserAgent().isDesktop && !isIPad()
}

function isEdge(){
    return getUserAgent().isEdge
}

function isIPad() {
  const userAgent = getUserAgent()
  return (navigator.platform.startsWith('Mac') && navigator.maxTouchPoints > 2) || userAgent.isiPad
}

function hasSoftKeyboard() {
  return userAgent.isMobile || isIPad() || userAgent.isAndroidTablet
}

function isAndroid() {
  return userAgent.isAndroid
}
  

export {hasSoftKeyboard, isAndroid, isTabletOrMobile, isTablet, isIPad, isApple, isMobile, isIOS, isSafari, isChrome, isDesktop, isFirefoxDesktop, isEdge}
