import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import {MediaDevice, Signaling} from './Signaling'
import {Contact} from './Contact'
import {Contacts} from './Contacts'
import {Button} from './Button'
import {SignUp} from './SignUp'
import {SignIn} from './SignIn'
import Connection from "./assets/icons/Connection.svg";
import {IncomingCall} from "./IncomingCall"
import {ForgotPassword} from "./ForgotPassword"
import {ContactsView} from './ContactsView'
import {isFirefoxDesktop, isWindows, isAndroid, isMobile, isDesktop, isSafari, isIOS, isIPad} from "./Platform";
import {generateThumbnail} from "./components/ProfileIcon";
import {UIWebsite} from "./components/Website";
import {UITodoListWebsite} from "./components/TodoListWebsite";
import {UIOpenContactView} from "./components/OpenContactView";
import {UIActiveContactView} from "./components/ActiveContactView";
import {UISidebar} from "./components/Sidebar";
import {UICalendar} from "./components/Calendar";
import {UIMe} from "./components/Me";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {StripeButton} from "./StripeButton";
import {Me} from "./E3"
import {SideListProductChannel} from "./components/Sidebar";
import {SideListImpromptuCallWaiting} from "./components/Sidebar";
import {InputField} from "./InputField";
import {PaymentMethod} from "./PaymentMethod";
import {UIHome} from './components/Home';
import {UISettings} from './components/Settings';
import {UIScheduleAppointment} from "./components/ScheduleAppointment";
import {MobileClient} from './Mobile/src/components/Client'
import Pop from "./assets/audio/Igor/callLeft.wav";
import NewMessage from "./assets/audio/Igor/notification.wav"
import Ring2 from "./assets/audio/Igor/ring_2.wav";
import RingShort from "./assets/audio/Igor/ring_short.wav";
import Spinner from "./assets/Assets/spinner.svg";
import Home from "./assets/icons/Home.svg";
import Arrow from "./assets/icons/PointerRight.svg";
import Tooltip from "@material-ui/core/Tooltip";
import {merge, of, concat, from, Subject} from "rxjs";
import {map, flatMap, take} from 'rxjs/operators';
import phone from 'phone';
import Div100vh from 'react-div-100vh'
//import ReactGA from 'react-ga';

import './Client.css'
import moment from 'moment'
import 'moment-duration-format'
import owasp from "owasp-password-strength-test"
import {Howl, Howler} from 'howler'
import { polyfill } from "seamless-scroll-polyfill"

polyfill()

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

const IDLE_TIMEOUT = 150000;

const incoming = new Set();

const ring2 = new Howl({
  src: [Ring2],
  loop: true,
});

const ringShort = new Howl({
  src: [RingShort],
  loop: true,
});

const notif = new Howl({
  src: [NewMessage],
  loop: false,
});


window.numCores = 4;
const getNumCores = async retries => {
  return 4
}

getNumCores(0);

const firebaseConfigLetsBuildDev = {
  apiKey: "AIzaSyDgLJJ8-KF3l7sO_Bzh8GVZdiudSkiCmfE",
  authDomain: "login.letsbuild.fitness",
  projectId: "letsbuildclouddev",
  storageBucket: "letsbuildclouddev.appspot.com",
  messagingSenderId: "18538761161",
  appId: "1:18538761161:web:6f616c8ff9b722a7f5b618",
  measurementId: "G-WV4WN0ESLM"
}

const firebaseConfigDev = {
  apiKey: "AIzaSyDqpnTuuPIn1cg5Lr3Um1yEgu1JjVQ1Akw",
  authDomain: "telemedicine-b2ea5.firebaseapp.com",
  databaseURL: "https://telemedicine-b2ea5.firebaseio.com",
  projectId: "telemedicine-b2ea5",
  storageBucket: "telemedicine-b2ea5.appspot.com",
  messagingSenderId: "307393941342",
  appId: "1:307393941342:web:5096fcbd5b8387a41309cf",
  measurementId: "G-9V2GHJ9P44"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyBPiyervweJJNPY583291GfAeC_zSCipxY",
  authDomain: "login.tete.chat",
  databaseURL: "https://tete-s.firebaseio.com",
  projectId: "tete-s",
  storageBucket: "tete-s.appspot.com",
  messagingSenderId: "734397495870",
  appId: "1:734397495870:web:ef33cd2dc47a75c5b6af6f",
  measurementId: "G-0Q60FTNPTX"
};

const firebaseConfigProd2 = {
  apiKey: "AIzaSyBPiyervweJJNPY583291GfAeC_zSCipxY",
  authDomain: "login.tete.video",
  databaseURL: "https://tete-s.firebaseio.com",
  projectId: "tete-s",
  storageBucket: "tete-s.appspot.com",
  messagingSenderId: "734397495870",
  appId: "1:734397495870:web:ef33cd2dc47a75c5b6af6f",
  measurementId: "G-0Q60FTNPTX"
};

const getConfig = () => {
  const u = new URL(window.origin);
  if (u.hostname == 'tete-production.appspot.com' || u.hostname.endsWith('tete.chat')) {
    return "prod";
  }
  if (u.hostname == 'tete-video.appspot.com' || u.hostname.endsWith("tete.video")) {
    return "prod2";
  }
  const searchParams =  new URLSearchParams(window.location.search);
  const env = searchParams.get("tete-env");
  return env ? env : "dev";
}

const getFirebaseConfig = () => {
  if (getConfig() == 'prod') {
    return firebaseConfigProd;
  }
  if (getConfig() == 'prod2') {
    return firebaseConfigProd2;
  }
  return firebaseConfigLetsBuildDev;
}

const firebaseConfig = getFirebaseConfig();
let fire = firebase
firebase.initializeApp(firebaseConfig);

//ReactGA.initialize(firebaseConfig.measurementId);

const getTeteFunctionEndpoint = () => {
  return "https://us-central1-"+firebaseConfig.projectId+".cloudfunctions.net";
}

if (getConfig() == 'prod') {
  window.stripe_key = "pk_live_KVU01o7JnDfnVRQrH75nIM0O00VdBoVzEu";
} else {
  window.stripe_key = "pk_test_dkomIQGNwr7Ziw4yvd6RJNxi006vne2jpJ";
}

window.stripePromise = loadStripe(window.stripe_key);

window.analytics = {
  logEvent: e => {}
}

const onMessageSubject = new Subject();
if (window.ReactNativeWebView) {
  window.onMessage = msg => {
    onMessageSubject.next(msg);
  }
}

const observeNativeMessage = () => onMessageSubject;

const sendNativeMessage = (msg) => {
  if (window.ReactNativeWebview) {
    window.ReactNativeWebView.postMessage(JSON.stringify(msg));
  }
}

const me = new Me(firebase, getTeteFunctionEndpoint(), getConfig());

window.ProductName = me.isTodoList() ? 'Plan to Relax' : "Let's Build";

const signaling = new Signaling(me, firebase);

class VideoStream extends Component {
  constructor(props) {
    super(props);
  }
  setRef = vid => {
    if (vid && vid != this.video) {
      this.video = vid;
      this.video.srcObjct = this.props.stream;
    }
  }
  updateTrackIndex() {
    if (this.video && this.video.videoTracks && this.video.videoTracks.length > this.props.trackIndex) {
      this.video.videoTracks[this.props.trackIndex].selected = true;
    }
  }
  componentDidMount() {
    this.video.srcObject = this.props.stream;
    this.updateTrackIndex();
  }
  componentDidUpdate() {
    this.updateTrackIndex();
  }
  render() {
    return <video ref={this.setRef} muted={false} playsInline autoPlay crossOrigin={'anonymous'} className={this.props.isMessagingActive() ? "remoteVideoSmall" : 'remoteVideo'}/>;
  }
}

if (me.isTodoList()) {
  document.body.style.backgroundColor = 'black'
}

export class Client extends Component {
  constructor(props) {
    super(props);
    const nav = "home";
    this.calSubject = new Subject();
    this.chatMessages = {};
    this.subs = {};
    this.state = {
      unreads: {},
      lastSystemReadTime: 0,
      systemUnreadCount: 0,
      pendingSubscriptions: 0,
      chatUpdates: 0,
      showWebsite: true,
      needsSignIn: !isMobile(),
      needsSignUp: false,
      needsCheckout: false,
      signInForm: {countryCode: 1},
      videoMuted: false,
      audioMuted: false,
      remoteVideo: [],
      callStats: {
        duration: 0,
        bytesReceived: 0,
        bytesSent: 0
      },
      calls: [],
      messages: [],
      incomingCalls: [],
      upNext: [],
      signInError: {},
      yourVideoHidden: true,
      nav: nav,
      activeContacts: [],
      incomingCallFrom: {},
      outgoingCallTo: {},
      heldCalls: {},
      messagesShown: {},
      homeStart: 'sign-in',
      settingsNav: 'profile',
      meNav: me.isTodoList() ? 'todo' : 'me'
    }
    const searchParams =  new URLSearchParams(window.location.search);
    if (true) {
      if (searchParams.has("contact") ||
          searchParams.has("client") ||
          searchParams.has("refer") ||
          searchParams.has("channel") ||
          searchParams.has("appointment") ||
          searchParams.has("manage-notifications")) {
        this.state.showWebsite = false;
        if (searchParams.has("manage-notifications")) {
          this.state.settingsNav = 'notification';
        }
      }
      if (searchParams.has("sign-up")) {
        this.state.homeStart = 'sign-up';
        this.state.showWebsite = false;
      } else if (searchParams.has("email-verify")) {
        this.state.homeStart = 'sign-up';
        this.state.showWebsite = false;
      } else {
        if (searchParams.has("business")) {
          window.isBusiness = searchParams.get("business")
          this.state.homeStart='sign-up';
        } else if (searchParams.has("refer")) {
          window.isBusiness = true;
          this.state.homeStart='sign-up';
        }
      }
    }
    if (searchParams.has("no-e3")) {
      me.setEncryptionEnabled(false);
    }
    this.streams = {};
    window.teteConfig = getConfig();
  }

  meNavigate = async nav => {
    this.setState({
      meNav: nav
    })
    if (nav == 'todo') {
      if (this.state.systemUnreadCount > 0) {
        this.markSystemMessagesRead();
      }
    }
  }

  settingsNavigate = nav => {
    this.setState({
      settingsNav: nav
    });
  }

  markSystemMessagesRead = (force) => {
    const subs = this.getSubUnread();
    if (force || subs > 0 || this.state.systemUnreadCount > 0) {
      me.markSystemMessagesRead();
    }
  }

  getSubUnread() {
    let total = 0;
    for (let i in this.subs) {
      const sub = this.subs[i].sub;
      if (sub.latestQuestion > this.state.lastSystemReadTime) {
        total++;
      }
    }
    debugLog("sub unread: ", total, " nav=", this.state.nav);
    return total;
  }

  getSystemUnread = () => {
    return this.state.systemUnreadCount + this.getSubUnread();
  }

  navigate = to => {
    let p = Promise.resolve();
    if (!isMobile() && this.state.openContact) {
      p = this.closeContact(true);
    }
    const updates = {
      nav: to
    }
    return new Promise((resolve, reject) => {
      p.then(() => {
        if (to == 'home') this.getSubUnread();
        this.setState(updates, () => {
          resolve();
        });
      });
    });
  }

  setLocalVideoRef=(ref) => {
    if (ref && ref != this.localVideo) {
      this.localVideo = ref;
      //debugLog("set local video");
    }
  }

  signInWithPhoneNumber1 = async (form) => {
    let signInError = {};
    let phoneNumber = "+"+form.countryCode +form.phoneNumber;
    if (!form.phoneNumber) {
      signInError = {field: 'phoneNumber', err: "Phone Number is required"};
    } else {
      const converted = phone(phoneNumber);
      if (!converted.length) {
        signInError = {field: 'phoneNumber', err: "Phone Number is invalid"};
      } else {
        phoneNumber = converted[0];
      }
    }
    this.setState({signInError: signInError});
    if (signInError.err) return 
    const exists = await me.phoneNumberExists(phoneNumber)
    if (!exists) {
      signInError = {field: 'phoneNumber', err: "No user with that phone number"};
      this.setState({signInError: signInError});
      return;
    }
    if (!form.onVerificationCodeInput) {
      if (!this.verifier) {
        this.verifier = new me.firebase.auth.RecaptchaVerifier(this.recaptchaFun, {
          size: "invisible",
          callback: response => {
          }
        });
        this.recaptchaFun.style.display = "";
      }
      const recaptcha = this.verifier;
      recaptcha.reset();
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
      const result = await firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
      this.verifier.reset();
      form.onVerificationCodeInput = async code => {
        try {
          const result1 = await result.confirm(code)
          form.user = result1.user
          form.onPasswordInput = null;
          form.onVerificationCodeInput = null;
          //debugger;
          this.setState({
            needsSignUp: false,
            needsSignIn: false,
          });
          this.applyContactLink(true);
          this.startCallListener();
        } catch (err) {
          signInError = {field: 'verificationCode', err: "Invalid verification code."};
          this.setState({signInError: signInError});
        }
      }
      this.forceUpdate()
    } else {
      if (!form.verificationCode) {
        signInError = {field: 'verificationCode', err: "Verification code is required."};
        this.setState({signInError: signInError});
        return
      }
      return form.onVerificationCodeInput(form.verificationCode)
    }
  }

  signInWithPhoneNumber = (form, getCode, getPassword, forgotPassword, done) => {
    let signInError = {};
    let phoneNumber = "+"+form.countryCode +form.phoneNumber;
    if (!form.phoneNumber) {
      signInError = {field: 'phoneNumber', err: "Phone Number is required"};
    } else {
      const converted = phone(phoneNumber);
      if (!converted.length) {
        signInError = {field: 'phoneNumber', err: "Phone Number is invalid"};
      } else {
        phoneNumber = converted[0];
      }
    }
    this.setState({signInError: signInError});
    if (signInError.err) return Promise.resolve();
    return me.phoneNumberExists(phoneNumber).then(exists => {
      //debugger;
      if (!exists) {
        signInError = {field: 'phoneNumber', err: "No user with that phone number"};
        this.setState({signInError: signInError});
        return;
      }
      if (!this.verifier) {
        this.verifier = new me.firebase.auth.RecaptchaVerifier(this.recaptchaFun, {
          size: "invisible",
          callback: response => {
          }
        });
      }
      this.recaptchaFun.style.display = "";
      debugLog("signInWithPhoneNumber: ", phoneNumber);
      const doneDone = (error) => {
        this.verifier.reset();
        if (error) {
          this.setState({
            signInError: {err: error.message}
          });
        } else {
          this.setState({
            needsSignIn: false,
            needsSignUp: false,
            authenticating: false,
          });
          this.startCallListener();
          this.recaptchaFun.style.display = "none";
        }
        done();
      }
      return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
        return me.signInWithPhoneNumber(phoneNumber, this.verifier, getCode, getPassword, forgotPassword, doneDone).catch(err => {
          //debugger;
          console.error(err);
          this.verifier.reset();
          this.setState({signInError: {err: err.message}});
          this.recaptchaFun.style.display = "none";
          return Promise.reject(err);
        });
      });
    });
  }

  signOut = async () => {
    try {
      await me.signOut()
    } catch (err) {
      console.error(err)
    }
    const self = this
    self.activeContactViews = {}
    let countryCode = this.state.signInForm.countryCode
    const empty = {
      nav: 'home',
      needsSignIn: true,
      activeContacts: [],
      heldCalls: {},
      calls: [],
      incomingCalls: [],
      systemUnreadCount: 0,
      openContact: null,
      openChannel: null,
      outgoingCallTo: {},
      signInForm: {countryCode: countryCode},
      signInError: {},
      meNav: me.isTodoList() ? 'todo' : 'me'
    }
    await new Promise(resolve => this.setState(empty, resolve))
    document.title = window.ProductName;
    if (this.verifier) {
      this.verifier.reset();
      this.verifier = null
    }
    if (this.home) {
      this.home.signOut();
      this.home = null
    }
  }

  signIn =(form)=> {
    const email = form.email;
    const password = form.password;
    //debugLog("signIn: ", form);
    let signInError = {};
    if (!email) {
      signInError = {field: 'email', err: "Email address is required"};
    } else if (!password) {
      signInError = {field: 'password', err: "Password is required"};
    } else {
    }
    this.setState({signInError: signInError});
    if (signInError.err) return Promise.resolve();
    const p = firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)    
    return p.then(() => {
      this.setState({
        authenticating: true
      });
      return me.signIn(email, password).then(() => {
        this.setState({
          needsSignIn: false,
          needsSignUp: false,
          authenticating: false,
        });
        return this.applyContactLink().then(() => {
          this.startCallListener();
        });
      });
    }).catch(err => {
      //debugLog("error: ", err);
      //debugger;
      let message = err.message;
      if (err.code == "auth/user-not-found") {
        message = {field: 'email', err: "No user with that email"};
      } else if (err.code == "auth/wrong-password") {
        message = {field: 'password', err: "Invalid password"};
      } else if (err.code) {
        message = {field: '', err: err.message};
      } else {
        return Promise.reject(err);
      }
      this.setState({signInError: message, authenticating: false});
      return Promise.resolve();
    });
  }

  signInWithGoogle = (form, selectSignUp, getPassword, fail, done, retry) => {
    this.setState({
      signInError: {}
    });
    const onNeedsSignup = user => {
      this.setState({
        needsSignUp: true,
        needsSignIn: false,
      });
      form.name = user.displayName;
      form.email = user.email;
      form.photoURL = user.photoURL;
      form.isGoogleSignUp = true;
      return selectSignUp().then(() => {
        return me.completeGoogleSignUp(form, form => {
          return new Promise((resolve, reject) => {
            form.googleSignUpResolve = v => {
              resolve(v);
              return Promise.resolve();
            }
            this.forceUpdate();
          });
        });
      });
    }
    return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
      return me.signInWithGoogle(onNeedsSignup, getPassword, fail, done).then(() => {
        this.setState({
          needsSignUp: false,
          needsSignIn: false,
        });
        this.applyContactLink(true);
        this.startCallListener();
      }).catch(err => {
        //debugger;
        if (err.code == "auth/popup-closed-by-user") {
          return;
        }
        if (err.code == "auth/popup-blocked") {
          let err = "Please allow pop-ups to sign in with your Google account";
          if (isSafari()) {
            err = "Pop-up was blocked. Please try again to sign in with your Google account.";
          }
          return this.setState({
            signInError: {err: err, field: ""}
          });
        }
        this.setState({
          signInError: {err: err.message, field: ""}
        });
      });
    });
  }

  signUpWithGoogle = (form, getPassword, done, fail) => {
    this.setState({
      signInError: {}
    });
    return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
      return me.signUpWithGoogle(getPassword).then(result => {
        let complete;
        const finish = () => {
          if (complete) complete();
          this.setState({
            needsSignUp: false,
            needsSignIn: false,
          });
          this.applyContactLink(true);
          this.startCallListener();
        }
        const user = result.user;
        if (!user || user.providerData.length > 1) {
          return finish();
        } else {
          form.name = user.displayName;
          form.email = user.email;
          form.photoURL = user.photoURL;
          form.isGoogleSignUp = true;
          return me.completeGoogleSignUp(form, form => {
            return new Promise((resolve, reject) => {
              window.unblockInput();
              form.googleSignUpResolve = arg => {
                window.blockInput();
                resolve(arg);
                return new Promise((resolve, reject) => {
                  complete = resolve;
                });
              }
              this.forceUpdate();
            });
          }, getPassword, done, fail).then(finish);
        }
      }).catch(err => {
        if (err.signInError) {
          this.setState({
            signInError: err.signInError
          });
        } else {
          console.error(err);
        }
        if (err.code == "auth/popup-closed-by-user") {
          return;
        }
        if (err.code == "auth/popup-blocked") {
          let err = "Please allow pop-ups to sign in with your Google account";
          if (isSafari()) {
            err = "Pop-up was blocked. Please try again to sign up with your Google account.";
          }
          this.setState({
            signInError: {err: err, field: ""}
          });
          return;
        }
        this.setState({
          signInError: err.message,
        });
      });
    })
  }

  validateForm = (form, passwordOnly) => {
    const displayName = form.name;
    const password = form.password;
    const email = form.email;
    let phoneNumber = "+"+form.countryCode +form.phoneNumber;
    let signInError = {};
    if (!passwordOnly && !displayName) {
      signInError = {field: 'name', err: "Display name is required"};
    } else if (!passwordOnly && !email) {
      signInError = {field: 'email', err: "Email address is required"}
    } else if (!password) {
      signInError = {field: 'password', err: "Password is required"};
    } else {
      const result = owasp.test(password);
      if (window.teteConfig != 'dev' && result.errors.length) {
        signInError = {field: 'password', err: result.errors[0]};
      } else if (!passwordOnly) {
        if (!phoneNumber) {
          signInError = {field: 'phoneNumber', err: "Phone Number is required"};
        } else {
          const converted = phone(phoneNumber);
          if (!converted.length) {
            signInError = {field: 'phoneNumber', err: "Phone Number is invalid"};
          } else {
          }
        }
      }
    }
    this.setState({signInError: signInError});
    return Promise.resolve(signInError.err);
  }

  signUp0 = form => {
    const email = form.email;
    let signInError = {};
    //debugger;
    if (form.needsSignUpEmail) {
      if (!email) {
        signInError = {field: 'email', err: "Email address is required"}
        this.setState({
          signInError: signInError,
        });
        return Promise.resolve();
      }
      return me.emailExists(email).then(exists => {
        //debugger;
        if (exists) {
          this.setState({
            signInError: {field: 'email', err: "An account with that email already exists"},
          });
          return;
        }
        return me.sendSignUpEmailVerification(email).then(result => {
          form.needsSignUpEmail = false;
          form.name = '';
          this.forceUpdate();
        });
      });
    }
    if (form.needsSignUpVerificationCode) {
      const code = form.verificationCode;
      if (!code) {
        signInError = {field: 'verificationCode', err: "Verification code is required"}
        this.setState({
          signInError: signInError,
        });
        return Promise.resolve();
      }
      return me.verifySignUpEmail(email, code).then(result => {
        if (result.error) {
          signInError = {field: 'verificationCode', err: "Invalid code"}
          this.setState({
            signInError: signInError,
          });
          return Promise.resolve();
        } else {
          form.needsSignUpVerificationCode = false;
          form.emailVerified = true;
          this.forceUpdate();
        }
      });
    }
    const displayName = form.name;
    const password = form.password;
    let phoneNumber = "+"+form.countryCode +form.phoneNumber;
    if (!displayName) {
      signInError = {field: 'name', err: "Display name is required"};
    } else if (!email) {
      signInError = {field: 'email', err: "Email address is required"}
    } else if (!password) {
      signInError = {field: 'password', err: "Password is required"};
    } else {
      const result = owasp.test(password);
      if (window.teteConfig != 'dev' && result.errors.length) {
        signInError = {field: 'password', err: result.errors[0]};
      } else {
        if (!phoneNumber) {
          signInError = {field: 'phoneNumber', err: "Phone Number is required"};
        } else {
          const converted = phone(phoneNumber);
          if (!converted.length) {
            signInError = {field: 'phoneNumber', err: "Phone Number is invalid"};
          } else {
          }
        }
      }
    }
    this.setState({signInError: signInError});
    if (signInError.err) return Promise.resolve();
    return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
      return me.signUp(email, password, displayName, phoneNumber).then(() => {
        this.setState({
          needsSignUp: false,
          needsSignIn: false,
        });
        this.applyContactLink(true);
        this.startCallListener();
      });
    }).catch(err => {
      if (err.code) {
        this.setState({signInError: {err: err.message}});
        return Promise.resolve();
      }
      return Promise.reject(err);
    });
  }

  signUp = form => {
    if (true) return this.desktopSignUp(form)
    let signInError = {};
    if (!form.name) {
      signInError = {field: 'name', err: "Display Name is required"}
    }
    else if (!form.email) {
      signInError = {field: 'email', err: "Email address is required"}
    } else if (!form.password) {
      signInError = {field: 'password', err: "Password is required"}
    }
    this.setState({signInError: signInError});
    if (signInError.err) return Promise.resolve();
    const { email, password, name } = form
    return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
      return me.signUp(email, password, name, '').then(() => {
        this.setState({
          needsSignUp: false,
          needsSignIn: false
        })
        this.applyContactLink(true);
        this.startCallListener();
      }).catch(err => {
        debugger
        const code = err.code
        let field = 'other'
        debugger
        if (code && code.indexOf) {
          if (code.indexOf("email") > 0) {
            field = 'email'
          } else if (code.indexOf("password") > 0) {
            field = 'password'
          }
        }
        this.setState({signInError: {field: field, err: err.message}});
        return Promise.resolve();
      });
    })
  }

  desktopSignUp = form => {
    let signInError = {};
    let phoneNumber;
    if (!form.phoneNumber) {
      signInError = {field: 'phoneNumber', err: "Phone number is required"}
    } else { 
      phoneNumber = "+"+form.countryCode +form.phoneNumber;
      const converted = phone(phoneNumber);
      if (!converted.length) {
        signInError = {field: 'phoneNumber', err: "Phone Number is invalid"};
      } else {
        phoneNumber = converted[0];
      }
    }
    this.setState({signInError: signInError});
    if (signInError.err) return Promise.resolve();
    //debugger;
    if (!form.user && !form.onVerificationCodeInput) {
      if (!this.verifier) {
        this.verifier = new me.firebase.auth.RecaptchaVerifier(this.recaptchaFun, {
          size: "invisible",
          callback: response => {
          }
        });
      }
      return me.phoneNumberExists(phoneNumber).then(exists => {
        debugger
        if (exists) {
          signInError = {field: 'phoneNumber', err: "An account with that phone number already exists"};
          this.setState({
            signInError
          })
          return
        }
        this.recaptchaFun.style.display = "";
        const recaptcha = this.verifier;
        recaptcha.reset();
        return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
          //debugger;
          return firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha).then(result => {
            //debugger;
            this.verifier.reset();
            form.onVerificationCodeInput = code => {
              //debugger;
              result.confirm(code).then(result => {
                const user = result.user;
                debugLog("provider data: ", user.providerData);
                form.user = user;
                //debugger;
                if (form.user.providerData.find(x => x.providerId == 'password')) {
                  // already signed up
                  let p = new Promise((resolve, reject) => {
                    form.onPasswordInput = resolve;
                    this.forceUpdate();
                    
                  })
                  let p1 = p
                  if (me.IsEncryptionEnabled) {
                    p1 = me.initE3(null, false, (wasInvalid) => {
                      //debugger;
                      if (wasInvalid) {
                        this.setState({signInError: {err: "Invalid password"}});
                      }
                      return p;
                    })
                  }
                  return p1.then(() => {
                    form.user = null;
                    form.onPasswordInput = null;
                    form.onVerificationCodeInput = null;
                    //debugger;
                    this.setState({
                      needsSignUp: false,
                      needsSignIn: false,
                    });
                    this.applyContactLink(true);
                    this.startCallListener();
                  });
                } else {
                  form.name = '';
                  form.onVerificationCodeInput = null;
                  this.forceUpdate();
                }
              }).catch(err => {
                form.signInError = {
                  field: 'verificationCode',
                  err: "Invalid code"
                }
                this.forceUpdate();
              });
            }
            this.forceUpdate();
          }).catch(err => {
            console.error(err);
            //debugger;
            if (err.code) {
              this.setState({signInError: {err: err.message}});
            }
          });
        });
      })
    }
    if (form.onVerificationCodeInput) {
      return form.onVerificationCodeInput(form.verificationCode);
    }
    debugger;
    const displayName = form.name;
    const password = form.password;
    const email = form.email;
    if (!displayName) {
      signInError = {field: 'name', err: "Display name is required"};
    } else if (!email) {
      signInError = {field: 'email', err: "Email address is required"}
    } else if (!password) {
      signInError = {field: 'password', err: "Password is required"};
    } else {
      const result = owasp.test(password);
      if (window.teteConfig != 'dev' && result.errors.length) {
        signInError = {field: 'password', err: result.errors[0]};
      } 
    }
    this.setState({signInError: signInError});
    if (signInError.err) return Promise.resolve();
    return me.completePhoneSignUp(phoneNumber, email, password, displayName).then(result => {
      form.user = null;
      form.onVerificationCodeInput = null;
      this.setState({
        needsSignUp: false,
        needsSignIn: false,
      });
      this.applyContactLink(true);
      this.startCallListener();
    }).catch(err => {
      if (err.code) {
        this.setState({signInError: {err: err.message}});
        return Promise.resolve();
      }
      return Promise.reject(err);
    });
  }

  hasContactLink = () => {
    const searchParams =  new URLSearchParams(window.location.search);
    return searchParams.has('client')
  }

  createURLObserver = (isSignUp) => {
    this.urlObserverSub = me.observeURL().subscribe(url => {
      const searchParams =  new URLSearchParams(url)
      if (searchParams.has('client')) {
        const link = searchParams.get('client')
        me.applyContactLink('client', link, isSignUp).then(result => {
          console.log('contact link applied')
        })
      }
    })
  }

  applyContactLink(isSignUp) {
    if (me.isNative()) {
      this.createUrlObserver(isSignUp)
    }
    const searchParams =  new URLSearchParams(window.location.search);
    let type;
    let link;
    if (searchParams.has("business")) {
      window.isBusiness = searchParams.get("business")
    }
    if (searchParams.has("contact")) {
      link = searchParams.get("contact");
      type = "contact";
    } else if (searchParams.has("client")) {
      link = searchParams.get("client");
      type = "client";
    } else if (searchParams.has("refer")) {
      link = searchParams.get("refer");
      window.isBusiness = true;
      type = "refer";
    }
    if (link) {
      me.applyContactLink(type, link, isSignUp).then(result => {
        //debugLog("apply contact link: ", result);
      });
    }
    if (false && searchParams.has("sign-up")) {
      const link = searchParams.get("sign-up");
      window.history.pushState({}, document.title, "/");
      return me.applyProductLink(link);
    }
    if (searchParams.has("ice")){
      window.ice = searchParams.get("ice").split(",");
    }
    if (searchParams.has("forceRelay")){
      window.forceRelay = true;
    }
    if (searchParams.has("forceNoRelay")){
      window.forceNoRelay = true;
    }
    if (searchParams.has("appointment")) {
      const appointmentId = searchParams.get("appointment");
      return me.resolveAppointmentContact(appointmentId).then(contact => {
        if (contact) this.openChat(contact);
        else {
          console.error("couldn't find appointment");
        }
      });
    }
    if (searchParams.has("channel")) {
      //debugger;
      const channel = searchParams.get("channel");
      const uids = channel.split("-");
      const contactUid = uids[0] == me.self.uid ? uids[1] : uids[0];
      return me.resolveContact(contactUid).then(contact => {
        if (contact) this.openChat(contact);
        else {
          console.error("couldn't find contact for channel: ", channel);
        }
      });
    }
    if (searchParams.has("manage-notifications")) {
      this.navigate("settings");
    }
    return Promise.resolve();
  }

  handleCall = call => {
    call.onMuted((type, muted) => {
      if (type == "audio") {
        this.setState({
          remoteAudioMuted: muted
        });
      } else if (type == "video") {
        this.setState({
          remoteVideoMuted: muted
        });
      }
    });
    this.screenshareSub = call.observeScreenShare().subscribe(screenShare => {
      //debugger;
      this.setState({
        remoteScreenShare: screenShare
      });
    });
    this.compositeSub = call.observeComposite().subscribe(composite => {
      //debugLog("composite: ", composite);
      this.setState({
        remoteComposite: composite
      });
    });
  }

  onStatsUpdate = stats => {
    this.setState({
      callStats: stats
    });
  }

  startCallListener = () => {
    signaling.listenForCalls((contact, call) => {
      debugLog("incoming call: ", contact, ": ", call);
      const group = contact.group;
      let uid = group ? group.uid: contact.uid;
      const existing = this.state.incomingCalls.find(x => x.uid == uid);
      if (existing) {
        if (existing.startTime < call.startTime) {
          existing.answer(true);
        } else {
          return Promise.resolve({
            answer: false
          });
        }
      }
      return new Promise((resolve, reject) => {
        const del = () => {
          if (incoming.delete(call)) {
            if (incoming.size > 0) {
              ringShort.stop();
            } else {
              ring2.stop();
            }
          }
          const updates ={
            incomingCalls: this.state.incomingCalls.filter(x => x != incomingCall),
          }
          const x = this.state.incomingCallFrom[uid];
          if (x && x.call == call) {
            delete this.state.incomingCallFrom[uid];
          }
          this.setState(updates);
        }
        call.observeHangup().subscribe(() => {
          debugLog("incoming call hangup");
          del()
        });
        const incomingCall = {
          uid: uid,
          call: call,
          from: contact,
          answer: declined => {
            incomingCall.answered = true;
            debugLog("incoming call answered: decline=", declined);
            if (declined || call.disconnected) {
              del();
              return resolve({answer: false});
            }
            this.state.incomingCallFrom[uid] = {call: call, resolve: result => {
              del();
              delete this.state.incomingCallFrom[uid];
              this.forceUpdate();
              if (!result) {
                return resolve({answer: false});
              }
              resolve(result);                           
            }};
            this.openChat(contact, 'video');
            this.forceUpdate();
          }
        }
        incoming.add(call);
        if (incoming.size == 1) {
          ring2.play();
        } else {
          ringShort.play()
        }
        this.setState({
          incomingCalls: this.state.incomingCalls.concat([incomingCall])                 
        });
      });
    });
  }


  getCal = () => {
    //debugger;
    if (this.cal) return Promise.resolve(this.cal);
    return this.calSubject.pipe(take(1)).toPromise();
  }

  rescheduleAppointment = (appointment) => {
    return this.navigate('cal').then(() => {
      return this.getCal().then(cal => {
        cal.openAppointment(appointment);
      });
    });
  }

  scheduleAppointmentWith = (contact) => {
    return this.navigate('cal').then(() => {
      return this.getCal().then(cal => {
        cal.scheduleNewAppointment(contact);
      });
    });
  }

  isSameChannel = (x, y) => {
    if (!x || !y) {
      return false;
    }
    debugLog("isSameChannel:  x: ", x, " y: ", y);
    if (x.group || y.group) {
      if (!x.group) return false;
      if (!y.group) return false;
      const result = x.group.uid == y.group.uid;
      debugLog("isSameChannel: ", result, " x: ", x, " y: ", y);
      return result;
    }
    return x.uid == y.uid;
  }

  openSubscription = to => {
    return this.openChat(to).then(() => {
      setTimeout(() => {
        this.activeContactViews[to.uid].openSubscription();
      }, 500);
    });
  }

  
  openChat = (to, selection, scope)  => {
    if (!to) {
      return this.closeContact()
    }
    //alert("openChat " + to.displayName + ", " + selection)
    if (to.group && to.group.uid != to.uid) {
      //debugger;
      const g = to.group;
      to = new Contact(to.group);
      to.group = g;
    }
    me.markContactOpened(to);
    return new Promise((resolve, reject) => {
      const existing = this.state.activeContacts.find(x => x.uid == to.uid);
      this.state.heldCalls[to.uid] = false;
      this.state.outgoingCallTo[to.uid] = selection == 'call' ? to.uid: null;
      const channel = me.getChannelFromContact(to)
      let activeContacts = this.state.activeContacts;
      if (!existing) {
        activeContacts = activeContacts.concat(to);
      }
      this.setState({
        activeContacts: activeContacts,
        openContact: to,
        openChannel: channel,
        nav: 'contacts'
      }, () => {
        debugLog("opened contact: ", to);
        debugLog("active: ", this.state.activeContacts.length);
        this.activeContactViews[to.uid].openChat(selection, scope).then(resolve)
      });
    })//.then(me.observeEThree);
  }

  canDrop = n => {
    if (n == document || !n) return false;
    return n.ondrop || this.canDrop(n.parentNode);
  }

  setupIdleTimeout() {
    const t = () => {
      if (!document.hidden) {
        clearTimeout(this.signoutTimeout);
        this.signoutTimeout = setTimeout(this.signOut, IDLE_TIMEOUT);
      }
    }
    const t1 = ()=> {
      clearTimeout(this.signoutTimeout);
      if (document.visibilityState === 'visible') {
        t();
      } else {
        this.signoutTimeout = setTimeout(this.signOut, IDLE_TIMEOUT);
      }
    };
    window.addEventListener("focus", t);
    document.addEventListener("visibilitychange", t1);
    if (isMobile()) {
      document.documentElement.addEventListener("touchstart", t);
      return;
    }
    document.documentElement.addEventListener("mousemove", t);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeContacts !== this.state.activeContacts) {
      if (this.state.activeContacts.indexOf(this.state.openContact) < 0) {
        this.setState({
          openContact: null,
          chat: null,
          remoteVideo: [],
        })
      }
    }
  }

  componentDidMount() {
    me.setStatusBarColor('light-content')
    document.title = window.ProductName;
    document.addEventListener("focusin", () => {
      debugLog("focus in: ", document.activeElement);
    }, true)
    document.addEventListener("focusout", (e) => {
      debugLog("focus out: ", document.activeElement, " target ", e.target);
      console.trace();
    }, true)

    window.observeContactOnline = me.observeContactOnline;
    window.showProgressIndicator = this.showProgressIndicator;
    window.hideProgressIndicator = this.hideProgressIndicator;
    window.blockInput = this.blockInput;
    window.unblockInput = this.unblockInput;
    if (false) window.ondrop = e => {
      //debugger;
      e.preventDefault();
    }
    if (false) window.ondragover = e => {
      if (!this.canDrop(e.target)) {
        //debugLog("not a drop target: ", e.target);
        e.preventDefault();
        e.stopPropagation();
      } else {
        //debugLog("found a drop target: ", e.target);
      }
    };
    me.observeStripeAuth().subscribe(auth => {
      this.setState({
        stripeAuth: auth,
      });
    });
    ////debugger;
    me.observeSelf().subscribe(user => {
      if (this.currentUser == user) {
        return;
      }
      this.currentUser = user;
      ////debugger;
      if (this.unreadsSubscription) {
        this.unreadsSubscription.unsubscribe();
        this.unreadsSubscription = null;
        this.state.unreads = {};
      }
      if (this.subSub) {
        this.subSub.unsubscribe();
        this.subSub = null;
        this.state.pendingSubscriptions = 0;
      }
      if (!user) {
        // logout;
        this.setState({
          needSignUp: true,
          needsSignIn: true,
        });
        return;
      }
      if (!me.isTodoList()) {
        //this.setupIdleTimeout();
        const selfChannel = me.self.uid + "-" + me.self.uid;
        me.getUnreads().then(results => {
          results.map(data => {
            this.state.unreads[data.channel] = data.unread;
          });
          this.unreadsSubscription = me.observeUnreads().subscribe(data => {
            debugLog("update unread: ", data);
            const prev = this.state.unreads[data.channel] || 0;
            if (data.unread) debugLog("update unread: ", data, " prev: ", prev);
            if (prev === data.unread) return;
            if (data.unread > prev) {
              notif.play();
            }
            this.state.unreads[data.channel] = data.unread;
          this.updateUnreadsLater();
          });
          this.updateUnreadsLater()
        });
        this.subSub = me.observeSubscriptions().subscribe(change => {
          const sub = change.subscription;
          const channel = sub.uid + "-"+ sub.client;
          if (change.type == 'removed') {
            delete this.subs[channel];
          } else {
            if (!this.subs[channel]) {
              this.subs[channel] = {}
            }
            this.subs[channel].sub = sub;
            if (sub.latestQuestion > this.state.lastSystemReadTime) {
              this.forceUpdate();
              this.updateDocumentTitle();
            }
          }
          
        });
      }
      this.forceUpdate();
    });
  }

  updateUnreadsLater = () => {
    clearTimeout(this.unreadsUpdateTimeout);
    this.unreadsUpdateTimeout = setTimeout(() => {
      let contactsUnread = 0
      for (let i in this.state.unreads) {
        contactsUnread += this.state.unreads[i];
      }
      this.state.contactsUnread = contactsUnread
      this.forceUpdate()
      this.updateDocumentTitle();
    }, 350);
  }
  

  updateDocumentTitle = () => {
    let total = 0
    total += this.state.contactsUnread
    total += this.getSystemUnread()
    const unreads = total ? " ("+total+")" : "";
    document.title = window.ProductName +unreads;
  }

  closeContact = () => {
    return new Promise((resolve, reject) => {
      if (this.state.openContact) {
        this.setState({openContact: null, chat: null, remoteVideo: []},
                      () => {
                        resolve();
                      });
      } else {
        resolve();
      }
    });
  }

  renderButtons() {
    return <div className='buttonContainer'>
      {this.state.callActive && <div className='buttonContainer'><Button
       label={!this.state.screenShare ? "Share Screen" : "Unshare Screen"}
       action={this.toggleScreenShare}/>
       <Button
       label={!this.state.audioMuted ? "Mute Audio" : "Unmute Audio"}
       action={this.toggleAudioMuted}/>
       <Button
       label={!this.state.videoMuted ? "Mute Video" : "Unmute Video"}
       action={this.toggleVideoMuted}/></div>}
      <Button
    label={this.state.callActive ? "Hangup": "Call"}
    action={this.callOrHangup}/>
      </div>
  }


  renderChat() {
  }

  resetPassword = form => {
    const emailAddress = form.email;
    let resetPasswordError = {};
    if (!emailAddress) {
      resetPasswordError = {field: 'email', err: "Email address is required"};
    }
    this.setState({
      resetPasswordError: resetPasswordError
    });
    if (resetPasswordError.err) {
      return Promise.resolve()
    }
    return me.resetPassword(emailAddress).then(() => {
      this.state.signInForm.password = null;
      this.setState({
        forgotPassword: false
      });
    }).catch(err => {
      //debugLog(err);
      let message = err.message;
      if (err.code == "auth/invalid-email") {
        message = {field: 'email', err:  "Invalid email"};
      } else if (err.code == "auth/user-not-found") {
        message = {field: 'email', err: "No account with that email"};
      }
      this.setState({
        resetPasswordError: message
      });
    });

  }

  toggleResetPassword = () => {
    this.setState({forgotPassword: false, resetPasswordError: false, signInError: false})
  }

  renderResetPassword = () => {
    return <ForgotPassword title={"Reset Password"}
    label={"Send Email"}
    backLabel={"Back"} backAction={this.toggleResetPassword}
    email={this.state.signInForm.email}
    error={this.state.resetPasswordError}
    resetPassword={this.resetPassword}
      />
  }

  clearForm = () => {
    this.setState({
      signInForm: {countryCode: 1},
      signInError: {}
    })
  }

  callIsHolding = call => {
    return this.state.heldCalls[call.getRemoteContact().uid];
  }

  holdCall = (call) => {
    this.state.heldCalls[call.getRemoteContact().uid] = !this.callIsHolding(call);
    this.forceUpdate();
  }

  answerIncomingCall = (c, decline) => {
    let p = Promise.resolve();
    if (this.state.calls.find(x => x == c)) {
      p = this.openChat(c.getRemoteContact());
    }
    return p.then(() => {
      if (c && c.answer) {
        c.answer(decline);
      }
    });
  }

  declineIncomingCall = () => this.answerIncomingCall(true)

  renderIncomingCalls() {
    const c = this.state.incomingCall;
    const setRef = x => {
      if (x) x.srcObject = this.state.localVideoStream;
    }
    return c && <div className='incomingCallContainer'>
      <IncomingCall magazines={me.magazines} contact={c.from} answerCall={()=>this.answerIncomingCall()} declineCall={this.declineIncomingCall}/>
      <video muted={true} playsInline autoPlay crossOrigin={'anonymous'} className={'localVideo'} ref={setRef}/>
      </div>
  }

  toggleSettings = () => {
    this.setState({
      settingsOpen: !this.state.settingsOpen
    });
  }

  renderConnectToStripe() {
    return <div className='stripeConnectPopup'>
      <div className='stripeConnectMessage'><p>To enable your paying clients on Tete, first connect your business with Stripe</p></div>
      <div className='stripeButtonContainer'>
      <StripeButton  observeStripeAuth={me.observeStripeAuth} action={me.stripeConnect}/>
      </div>
      </div>
  }

  toggleMyBusiness = () => {
    this.setState({
      connectMyBusiness: !this.state.connectMyBusiness
    });
    
  }

  addClientToCalendar = (channel, moment) => {
    const date = moment.toDate();
    const now = new Date();
    //debugger;
    date.setHours(now.getHours());
    date.setMinutes(0);
    const end = new Date(date);
    end.setHours(date.getHours() % 24 + 1)
    const data = {
      title: "Video Conference",
      start: date,
      end: end,
      with: channel,
      date: date,
      action: (start, end) => {
        const updates = {
          start: start.getTime(),
          end: end.getTime(),
          title: data.title,
          invoiceDescription: data.invoiceDescription,
          invoiceAmount: data.invoiceAmount,
          title: data.title,
        }
        return me.createAppointment(channel, updates);
      }
    }
    this.setState({
      needsCalendarEventTime: data,    
    });
  }

  renderAppointmentDateTimePrompt = () => {
    const data = this.state.needsCalendarEventTime;
    if (!data) return null;
    const date = data.date;
    const title = data.title;
    const startTime = data.start;
    const endTime = data.end;
    let dateTime = startTime;
    const onChange = (name, value) => {
      data[name] = value;
      this.forceUpdate();
    }
    const back = () => {
      this.setState({
        needsCalendarEventTime: null,
      });
    }
    const add = () => {
      //this.setState({progressIndicator: "Scheduling"});
      window.showProgressIndicator("Scheduling");
      data.action(startTime, endTime).then(() => {
        this.setState({
          progressIndicator: null,
          needsCalendarEventTime: null
        })
        window.hideProgressIndicator();
      });
    }
    //debugLog("with: ", data.with);
    return <div className='uiScheduleAppointmentPopup'><
      UIScheduleAppointment
    me={me}
    isNew={true}
    back={back}
    editable={true}
    date={date}
    start={startTime}
    end={endTime}
    headerTitle="Schedule Appointment"
    title={title}
    with={data.with}
    on={dateTime}
    trash={back}
    schedule={add}
    error={data.error}
    onChange={onChange}
    invoiceAmount={data.invoiceAmount || 0}
    invoiceDescription={data.invoiceDescription || title}
      />
      </div>
  }

  formErrors = ['signInError'];

  clearErrs = () => {
    let needsUpdate = false;
    this.formErrors.forEach(err => {
      if (this.state[err].err) {
        this.state[err] = {};
        needsUpdate = true;
      }
    });
    if (needsUpdate) {
      this.forceUpdate();
    }
  }

  setCal = cal => {
    if (cal && this.cal != cal) {
      this.cal = cal
      this.calSubject.next(cal);
      if (this.state.openEvent) {
        this.cal.navigate(this.state.openEvent.start);
      }
    }
  }

  clearProfilePictureToUpload = () => {
    this.setState({profilePictureToUpload: null, profilePictureToUploadURL: null})
  }

  onProfilePictureInput = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const url = reader.result;
        generateThumbnail(url, true).then(blob => {
          //debugger;
          this.setState({
            profilePictureToUploadURL: url,
            profilePictureToUpload: new File([blob], file)
          });
        });
      }
      reader.readAsDataURL(file);
    }
  }

  onCreateHome = home => {
    this.home = home;
  }

  openTodoList = async () => {
    await this.navigate('home')
    await this.meNavigate('todo')
  }
  openEvent = async (contact, page, scope, id) => {
    if (contact.uid === me.uid) {
      await this.navigate('home')
      await this.meNavigate('me')
      if (scope) {
        this.homeRef.cal.setScope(scope)
      }
      return
    }
    return this.openChat(contact, page, scope, id)
  }

  suggestRecordMeal = async () => {
    await this.navigate('home')
    //me.nativeLog("navigated home")
    await this.meNavigate('me')
    //me.nativeLog("navigated me")
    await this.homeRef.cal.setScope('meals')
    //me.nativeLog("set scope meals")
  }

  renderMobile() {
    return <MobileClient
    onSetOpenChat={this.onSetOpenChat}
    navigate={this.navigate}
    nav={this.state.nav}
    meNav={this.state.meNav}
    answerCall={
      contact => {
        const call = this.state.calls.find(c => c.from.uid == contact.uid)
        if (call) {
          return this.answerIncomingCall(call)
        }
      }
    }
    suggestRecordMeal={this.suggestRecordMeal}
    openTodoList={this.openTodoList}
    systemUnread={this.state.systemUnreadCount}
    contactsUnread={this.state.contactsUnread}
    openEvent={this.openEvent}
    openChat={this.openChat}
    openContact={this.state.openContact}
    form={this.state.signInForm}
    signInError={this.state.signInError}
    clearForm={this.clearForm}
    renderHome={this.renderHome}
    renderSettings={this.renderSettings}
    renderCalendar={this.renderCalendar}
    renderWebsite={this.renderWebsite}
    renderSidebar={this.renderSidebar}
    renderOpenContacts={this.renderOpenContacts}
    me={me}
    needsSignUp={this.state.needsSignUp}
    needsSignIn={this.state.needsSignIn}
    signIn={this.signIn}
    signUp={this.signUp}
    resetPassword={this.resetPassword}
    signInWithPhoneNumber={this.signInWithPhoneNumber1}
    cancelSignIn={() => {
      this.state.signInForm.onVerificationCodeInput = null
      //debugger
      this.setState({
        needsSignIn: false,
        needsSignUp: false
      })
    }}
    onChange={(field, value) => {
      this.state.signInForm[field] = value
      this.state.signInError.field = ''
      this.state.signInError.err = ''
      this.forceUpdate()
    }}
      />
  }

  renderCalendar = (opts) => {
    const setCal = ref => {
      if (opts.ref) opts.ref(ref)
      this.setCal(ref)
    }
    const { onEventChanged } = opts
    return <UICalendar
    opts={opts}
    openContact={this.openChat}
    onSet={setCal} me={me} visible={true}/>
  }

  setSystemUnreadCount = count => {
    debugLog('set system unread count', count)
    this.setState({
      systemUnreadCount: count
    })
    this.updateDocumentTitle()
  }

  setHomeRef = (f, x) => {
    f(x)
    this.homeRef = x
  }

  renderHome = (opts) => {
    const {
      viewBAA,
      searchPortal,
      openPopup,
      closePopup,
      update,
      ref
    } = opts
    return <UIMe
    ref={x => this.setHomeRef(ref, x)}
    openPopup={openPopup}
    closePopup={closePopup}
    setUnreadCount={this.setSystemUnreadCount}
    renderSettings={this.renderSettings}
    mainNav={this.navigate}
    update={update}
    searchPortal={searchPortal} viewBAA={viewBAA} systemUnread={this.getSystemUnread()} openChat={this.openChat} openSubscription={this.openSubscription} scheduleAppointmentWith={this.scheduleAppointmentWith} rescheduleAppointment={this.rescheduleAppointment} nav={this.state.meNav} navigate={this.meNavigate} visible={this.state.nav === 'home'} me={me}/>
  }

  renderSettings = (opts) => {
    const { onClickDegrees, onClickLicenses, licenses, degrees, onCancel } = opts
    return <UISettings
    visible={me.isTodoList() || this.state.nav === 'settings'}
    onClickDegrees={onClickDegrees}
    onClickLicenses={onClickLicenses}
    onCancel={onCancel}
    licenses={licenses}
    degrees={degrees}
    onProfilePictureInput={this.onProfilePictureInput}
    signOut={this.signOut}
    onSave={this.clearProfilePictureToUpload}
    onCancel={this.clearProfilePictureToUpload}
    profilePictureToUpload={this.state.profilePictureToUpload}
    profilePictureToUploadURL={this.state.profilePictureToUploadURL}
    me={me}
    nav={this.state.settingsNav}
    navigate={this.settingsNavigate}/>
  }

  renderAll() {
    if (isMobile()) return this.renderMobile()
    if (!me.self || this.state.needsSignUp || this.state.needsSignIn) {
      return <UIHome
      me={me}
      onCreate={this.onCreateHome}
      validateForm={this.validateForm}
      onFormChange={this.clearErrs}
      onViewChange={this.clearErrs}
      openWebsite={this.openWebsite}
      start={this.state.homeStart}
      signUpWithGoogle={this.signUpWithGoogle}
      signInWithGoogle={this.signInWithGoogle}
      signIn={this.signIn} signInError={this.state.signInError}
      signUp={this.signUp} signInError={this.state.signInError}
      resetPassword={this.resetPassword} resetPasswordError={this.state.signInError}
      signInWithPhoneNumber={this.signInWithPhoneNumber} phoneSignInError={this.state.signInError}
        />
    }
    if (false) {
      if (this.state.forgotPassword) {
        return this.renderResetPassword();
      }
      if (this.state.needsSignUp) {
        return this.renderSignUp();
      }
      if (this.state.needsSignIn) {
        return this.renderSignIn();
      }
    }
    if (this.state.connectMyBusiness) {
      return this.renderConnectToStripe();
    }
    //if (this.state.needsCheckout) {
    //return <PaymentMethod/>;
    //}
    //return [this.renderOpenContact(), this.renderContacts(), this.renderIncomingCalls()];
    if (this.state.settingsOpen) {
      return this.renderContacts();
    }
    const systemUnreadCount = this.getSystemUnread();
    //debugLog("Client systemUnreadCount: ", systemUnreadCount);
    return <div className='uiClientMain' >
      <UISidebar
    meNav={this.state.meNav}
    showPrivacyPolicy={me.showPrivacyPolicy}
    showPrivacyPolicy={me.showTermsOfService}
    showSupport={me.showSupport}
    openWebsite={this.openWebsite}
    onProfilePictureInput={this.onProfilePictureInput}
    profilePictureToUpload={this.state.profilePictureToUpload}
    profilePictureToUploadURL={this.state.profilePictureToUploadURL}
    markSystemMessagesRead={this.markSystemMessagesRead}
    systemUnreadCount={systemUnreadCount} me={me}
    toggleMe={this.toggleMe}
    nav={this.state.openContact ? "contact" : this.state.nav}
    navigate={this.navigate}
    messagesShown={this.state.messagesShown}
    visible={!isMobile() || (!this.state.openContact & !this.state.me)}
    navigate={this.navigate}
    goHome={this.goHome}
    getUnreadCount={this.getContactUnreadCount}
    isSameChannel={this.isSameChannel}
    message={this.openChat}
    removeContact={c=>{
      window.showProgressIndicator("Removing Contact");
      //debugLog("removing contact: ", c);
      return me.removeContact(c).then(result => {
        //debugLog(result);
        window.hideProgressIndicator();
      }).catch(err => {
        console.error(err);
        window.hideProgressIndicator();
      });
    }}
    decline={call=>this.answerIncomingCall(call, true)}
    answer={call=>this.answerIncomingCall(call, false)}
    hold={this.holdCall}
    isHolding={this.callIsHolding}
    selectedContact={this.state.openContact}
    upNext={this.state.upNext} calls={this.state.calls}
    incomingCalls={this.state.incomingCalls}
    openContact={this.makeCall}
    openChat={this.openChat}
    openSubscription={this.openSubscription}
    connectMyBusiness={this.toggleMyBusiness}
    onAddClientToCalendar={this.addClientToCalendar}
    stripeAuth={this.state.stripeAuth}
    openSettings={()=>{}}
      />
      <div className='uiClientMainContainer'>
      {!this.state.openContact && this.state.nav == 'cal' && <div key='calTitle' className='uiCalendarTitle'>Calendar</div>}
      <UICalendar openContact={this.openChat}
    onSet={this.setCal} me={me} visible={!this.state.openContact && this.state.nav == 'cal'}/>
      <div className='uiMeContainer' style={!this.state.openContact && this.state.nav == 'home' ? null: {display: "none"}}>
      <UIMe renderSettings={this.renderSettings} setUnreadCount={this.setSystemUnreadCount} systemUnread={this.getSystemUnread()} openChat={this.openChat} openSubscription={this.openSubscription} scheduleAppointmentWith={this.scheduleAppointmentWith} rescheduleAppointment={this.rescheduleAppointment} nav={this.state.meNav} navigate={this.meNavigate} visible={!this.state.openContact && this.state.nav == 'home'} me={me}/>
      </div>
      {this.renderOpenContacts({})}
      <div className='uiSettingsContainer' style={this.state.nav === 'settings' && !this.state.openContact ? null : { display: 'none'}}>
      <UISettings signOut={this.signOut} onSave={this.clearProfilePictureToUpload} onCancel={this.clearProfilePictureToUpload} profilePictureToUpload={this.state.profilePictureToUpload} me={me} nav={this.state.settingsNav} navigate={this.settingsNavigate} visible={!this.state.openContact && this.state.nav =='settings'}/>
      </div>
      </div>
      {this.renderAppointmentDateTimePrompt()}
    {this.state.progressIndicator && <div key='progressFun' className='uiClientProgressIndicatorContainer' >
     <div className='uiClientProgressIndicator'>
     <div className='uiClientProgressIndicatorMessage'>{this.state.progressIndicator}</div>
     </div>
     </div>}
    </div>
  }


  renderSidebar = (opts) => {
    const { nav, navigate, openChat, answer, contactsSearchTerm } = opts
    const systemUnreadCount = this.getSystemUnread();
    //debugLog("Client systemUnreadCount: ", systemUnreadCount);
    return <div className='uiClientMain' >
      <UISidebar
    contactsSearchTerm={contactsSearchTerm}
    meNav={this.state.meNav}
    showPrivacyPolicy={me.showPrivacyPolicy}
    showPrivacyPolicy={me.showTermsOfService}
    showSupport={me.showSupport}
    openWebsite={this.openWebsite}
    onProfilePictureInput={this.onProfilePictureInput}
    profilePictureToUpload={this.state.profilePictureToUpload}
    profilePictureToUploadURL={this.state.profilePictureToUploadURL}
    markSystemMessagesRead={this.markSystemMessagesRead}
    systemUnreadCount={systemUnreadCount} me={me}
    toggleMe={this.toggleMe}
    nav={nav}
    messagesShown={this.state.messagesShown}
    visible={true}
    navigate={navigate}
    goHome={this.goHome}
    getUnreadCount={this.getContactUnreadCount}
    isSameChannel={this.isSameChannel}
    message={openChat}
    removeContact={c=>{
      window.showProgressIndicator("Removing Contact");
      //debugLog("removing contact: ", c);
      return me.removeContact(c).then(result => {
        //debugLog(result);
        window.hideProgressIndicator();
      }).catch(err => {
        console.error(err);
        window.hideProgressIndicator();
      });
    }}
    decline={call=>this.answerIncomingCall(call, true)}
    answer={(call) => {
      if (call.isInProgress) {
        openChat(call.pc.remoteContact)
      } else {
        return this.answerIncomingCall(call)
      }
    }}
    hold={this.holdCall}
    isHolding={this.callIsHolding}
    selectedContact={this.state.openContact}
    upNext={this.state.upNext} calls={this.state.calls}
    incomingCalls={this.state.incomingCalls}
    openContact={this.makeCall}
    openChat={openChat}
    openSubscription={this.openSubscription}
    connectMyBusiness={this.toggleMyBusiness}
    onAddClientToCalendar={this.addClientToCalendar}
    stripeAuth={this.state.stripeAuth}
    openSettings={()=>{}}
      />
      </div>
  }

  goHome = () => {
    if (this.state.me) {
      this.toggleMe();
    } else {
      this.closeContact(true);
    }
  }

  toggleMe = ()=>  {
    this.setState({
      me: !this.state.me
    });
  }

  openWebsite = () => {
    this.signOut();
    this.setState({
      showWebsite: true
    });
  }

  websiteSignIn = () => {
    this.setState({
      showWebsite: false,
      needsSignIn: true,
      homeStart:'sign-in'
    })
  }

  websiteSignUp = () => {
    this.setState({
      showWebsite: false,
      needsSignUp: true,
      homeStart: 'sign-up'
    })
  }

  renderWebsite = () => {
    if (me.isTodoList()) {
      return <UITodoListWebsite me={me} signIn={this.websiteSignIn} signUp={this.websiteSignUp}/>
    }
    return <UIWebsite me={me} signIn={this.websiteSignIn} signUp={this.websiteSignUp}/>
  }

  setPortalRef = ref => {
    if (ref) {
      window.getPortalRef = () => ref
    }
  }

  render1() {
    let className = 'uiTeTeAppContainer'
    if (this.state.showWebsite && !isMobile()) {
      return this.renderWebsite()
    }
    return <div className={className}>
      <div className='uiTeTeApp'>
      {this.renderAll()}
    </div>
      
    </div>
  }

  render() {
    let className = 'topPortal'
    if (me.isTodoList()) {
      className += ' uiTeTeAppTodoList'
    }
    if (isAndroid()) {
      className += ' uiTeTeAppAndroid'
    }
    if (isMobile()) {
      className += ' uiTeTeAppMobile'
    } else {
      if (isIPad()) {
        className += ' uiTeTeAppIPad'
      } else {
        className += ' uiTeTeAppDesktop'
      }
    }
    if (isSafari()) {
      className += ' uiTeTeAppSafari'
    }
    if (isIOS()) {
      className += ' uiTeTeAppIOS'
    }

    if (!me.isNative()) {
      className += ' uiTeTeAppWeb'
    }
    else {
      className += ' uiTeTeAppNative'
    }

    if (isWindows()) {
      className += ' uiTeTeAppWindows'
    }

    if (isFirefoxDesktop()) {
      className += ' uiTeTeAppFirefoxDesktop'
    }
    
      

    return <Div100vh><div className={className} ref={this.setPortalRef}>
      <DndProvider backend={Backend}>
      <div id='recaptcha-fun' ref={ref=>{if (ref) this.recaptchaFun=ref}}/>
      {this.render1()}
    </DndProvider>
{(this.state.blockInput) && <div
       onClick={ e => {
        e.preventDefault()
        e.stopPropagation()
        return false
       }}
         
       onTouchStart={e => {
        e.preventDefault()
        e.stopPropagation()
        return false
      }}
      onMouseDown={
        e => {
          e.preventDefault()
          e.stopPropagation()
          return false
        }
      } className='uiClientBlockInput'/>}
    </div></Div100vh>
  }

  getContactUnreadCount = contact => {
    if (!contact) return 0;
    const channelId = me.getChannelFromContact(contact);
    const result = this.state.unreads[channelId] || 0;
    if (result) {
      console.log("unread", contact.displayName, result)
    }
    return result
  }

  renderContacts() {
    return <div key='openContactVisibility' >
      <ContactsView me={me} close={this.toggleSettings} observeStripeAuth={me.observeStripeAuth} stripeConnect={me.stripeConnect} setSearchField={x => this.contactsSearchField = x} contacts={this.contacts} takeFocus={!this.state.openContact}
    messageContact={this.openChat} callContact={this.makeCall}
    removeContact={this.removeContact}
    getUnread={this.getContactUnreadCount}
    signOut={this.signOut}/>
      </div>
  }


  showProgressIndicator = message => {
    //this.setState({progressIndicator: message});
  }

  hideProgressIndicator = () => {
    this.setState({progressIndicator: null})
  }

  blockInput = () => {
    debugLog("block input")
    this.setState({blockInput: true});
  }

  unblockInput = () => {
    debugLog("unblock input")
    this.setState({blockInput: false});
  }

  showSignUp = () => {
    this.setState({needsSignUp: true, signInError: null});
  }

  showSignIn = () => {
    this.setState({needsSignUp: false, signInError: null});
  }

  showForgotPassword = (form) => {
    this.state.signInForm.email = form.email;
    this.setState({forgotPassword: true});
  }

  renderSignIn() {
    return <SignIn
    authenticating={this.state.authenticating}
    title={'Sign in'}
    label={"Continue"}
    backAction={this.showSignUp}
    backLabel={'Sign up'}
    error={this.state.signInError}
    signIn={this.signIn}
    form={this.state.signInForm}
    forgotPassword={this.showForgotPassword}
      />
  }

  renderSignUp() {
    return <SignUp 
    title={'Sign up'}
    label={"Continue"}
    backLabel={"Back"}
    backAction={this.showSignIn}
    error={this.state.signInError}
    signUp={this.signUp}
      />
  }

  isMessagingActive = () => {
    return !this.state.callActive || this.state.messagingActive;
  }

  back = () => {
    if (this.state.callActive) {
      return this.setState({
        messagingActive: !this.state.messagingActive
      });
    }
    this.closeContact();
  }

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const dm = decimals < 0 || i < 2 ? 0 : decimals;
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  formatCallStats = () => {
    const s = this.state.callStats;
    const total = s.bytesSent + s.bytesReceived;
    if (total == 0) {
      return "";
    }
    const mbS = ((total) / (s.duration/1000));
    return (s.isRelay ? "Relay: yes " : "Relay: no ")+this.formatBytes(total) + " ("+(this.formatBytes(mbS).toLowerCase())+"/s)";
  }

  formatCallCost = () => {
    const s = this.state.callStats;
    const gb = (s.bytesSent + s.bytesReceived)/(1000*1000*1000);
    const price = 0.09;
    return "$"+(gb * price).toFixed(3);
  }
  
  formatCallDuration = () => {
    const s = this.state.callStats;
    if (s.duration == 0) return "0:00";
    const total = s.bytesSent + s.bytesReceived
    return moment.duration(s.duration/1000, "seconds").format();
  };

  onCallStarted = call => {
    delete this.state.outgoingCallTo[call.getRemoteContact().uid];
    this.state.calls.push(call);
    this.forceUpdate();
  }

  onCallEnded = call => {
    this.state.calls = this.state.calls.filter(x => x != call);
    this.forceUpdate();
  }

  onMessagesShown = to => {
    this.state.messagesShown[to.uid] = true;
    this.forceUpdate();
  }

  removeContact = contact => {
    return me.removeContact(contact).then(() => {
      this.setState({
        activeContacts: this.state.activeContacts.filter(x => x.uid != contact.uid)
      });
    });
  }

  leaveGroup = async group => {
    await me.leaveGroup(group.uid)    
    this.setState({
      activeContacts: this.state.activeContacts.filter(x => x.uid != group.uid)
    });
  }

  deleteGroup = async group => {
    await me.deleteGroup(group.uid)    
    this.setState({
      activeContacts: this.state.activeContacts.filter(x => x.uid != group.uid)
    });
  }

  activeContactViews = {}

  onContactViewCreated = (to, v) => {
    this.activeContactViews[to.uid] = v;
  }

  onContactViewDeleted = to => {
    delete this.activeContactViews[to.uid];
  }

  renderOpenContacts = (opts) => {
    //debugLog("heldCalls: ", this.state.heldCalls);
    let style = this.state.nav != 'home' && this.state.nav != 'cal' && this.state.nav != 'settings' ? null : { display: 'none'}
    if (window.safeAreaInsets) {
      if (!style) {
        style = {}
      }
      const { top, bottom } = window.safeAreaInsets
      style.top = 'calc(40px + ' + top + 'px)'
      style.height = 'calc(100% - 40px - ' + top + 'px)'
      me.nativeLog("activeContacts top:" + style.top + ', height:' + style.height)
    }
    
    debugLog("render open contacts: ", this.state.activeContacts.length);
    return <div className={'activeContacts' + (isMobile() ? '' : ' activeContactsNonMobile')} style={style}>
      {this.state.activeContacts.map(to => {
        //debugLog("to: ", to.uid);
        //debugLog("heldCalls: ", this.state.heldCalls[to.uid]);
        const incomingCall =
              this.state.incomingCalls.find(c => c.from.uid == to.uid);
        const channel = me.getChannelFromContact(to)
        let selected = this.state.openContact && this.state.openContact.uid == to.uid
        if (isMobile()) {
          selected = selected && this.state.nav === 'contacts'
        }
        return <UIActiveContactView key={to.uid}
        openPopup={opts.openPopup}
        calendarOpts={opts}
        leaveGroup={() => this.leaveGroup(to)}
        deleteGroup={() => this.deleteGroup(to)}
        onCreated={this.onContactViewCreated}
        onDeleted={this.onContactViewDeleted}
        removeContact={()=>this.removeContact(to)}
        onMessagesShown={ () => this.onMessagesShown(to) }
        scheduleAppointmentWith={this.scheduleAppointmentWith}
        rescheduleAppointment={this.rescheduleAppointment}                    
        callHolding={this.state.heldCalls[to.uid]}
        outgoingCall={this.state.outgoingCallTo[to.uid]}
        incomingCallRequest={incomingCall}
        answerIncomingCall={(decline)=>this.answerIncomingCall(incomingCall, decline)}
        incomingCall={this.state.incomingCallFrom[to.uid]}
        onCallStarted={this.onCallStarted}
        onCallEnded={this.onCallEnded}
        manageGroup={opts.manageGroup}
        selected={selected}       
        key={to.uid}
        to={to}
        me={me}
        unreadCount={this.state.unreads[channel]}
        signaling={signaling}
          />
      })}
    </div>;
  }
}

