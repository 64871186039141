import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import { LBSurface } from './lbSurface/';
import { isDesktop, isMobile } from '../../Platform.js';
import { Dots } from '../Dots'
import Forward from "../../assets/icons/Forward.svg";
import Back from "../../Mobile/src/assets/Icons/BackArrow.svg";
import ReactSwipeableViews from 'react-swipeable-views'

import './index.css';

const blurbs = [
'Get the digital support you need to achieve your fitness goals.'
]

class BlurbFade extends Component {
  render() {
    let className = 'lbBlurbFade'
    if (this.props.selected) {
      className += ' lbBlurbFadeSelected'
    } else {
      className += ' lbBlurbFadeUnselected'
    }
    return <div className={className}>
      {this.props.children}
      </div>
  }
}

export class UIWebsite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blurbSelection: 0
    }
  }

  onChangeIndex = index => {
    this.setState({
      blurbSelection: index
    })
  }

  componentDidMount() {
    this.props.me.setStatusBarColor('light-content');
  }

  render() {
    let selection = this.state.blurbSelection
    const back = () => {
      this.onChangeIndex(selection - 1)
    }
    const fwd = () => {
      this.onChangeIndex(selection + 1)
    }
    const blurb1 = <div className='lbMainTitleMobile'>Guidance<br/>×<br/>Motivation<br/>×<br/>Accountability<br/>+<br/>Data<br/><span className='lbDividedBy'></span><br/><span className='lbHeadline'>Fully Connected Personal Training</span></div>;
    const blurb2 = <div className='lbMainTitleMobile'>Texting<br/>×<br/>Whiteboarding<br/>×<br/>Video Conferencing<br/>+<br/>Wearables<br/><span className='lbDividedBy'></span><br/><span className='lbHeadline'>A digital platform for fully connected personal training</span></div>;
    const blurb3 = <div className='lbMainTitleMobile'>Whoop Strap<br/>×<br/>Oura Ring<br/>×<br/>Garmin Device<br/>+<br/>Smart Scales<br/><span className='lbDividedBy'></span><br/><span className='lbHeadline'>Real-time transparency through wearable technology</span></div>;
    const blurb4 = <div className='lbMainTitleMobile'>Recovery<br/>×<br/>Sleep<br/>×<br/>Workouts<br/>+<br/>Meals<br/><span className='lbDividedBy'></span><br/><span className='lbHeadline'>Intelligent personal training built on client data</span></div>;
    const blurbs = [blurb1, blurb3, blurb4, blurb2]

    let style
    const hh = window.innerHeight - 320 - 45
    const scale = hh / 270
    console.log('scale', scale)
    if (scale < 1) {
      style = {
        transform: `scale(${scale})`,
        transformOrigin: 'center top'
      }
    }
    return (
      <div className={'lbMain' + (isMobile() ? ' lbMobile' : '')}>
        <LBSurface signIn={this.props.signIn} signUp={this.props.signUp} />
      </div>
    );
  }
}


/*
        <div className='lbMainBlurbs'>
          <ReactSwipeableViews
            onChangeIndex={this.onChangeIndex} 
            index={selection}
            enableMouseEvents={true}
            ignoreNativeScroll={true}>
          {
            blurbs.map((blurb, i) => {
              return   <div className='lbMainBlurbsBlurb' style={style}>
                <BlurbFade selected={i === selection}>{blurb}</BlurbFade>
                <div className='lbMainBlurbsBlurbControl'>
                  { i === selection && i > 0 && <div className='lbMainBlurbsBack' onClick={back}><ReactSVG src={Back}/></div>}
                  { i === selection && i + 1 < blurbs.length && <div className='lbMainBlurbsFwd' onClick={fwd}><ReactSVG src={Back}/></div>}
                </div>
              </div>
            })
          }
        
        </ReactSwipeableViews>
          <div className='lbWorkoutCardMediaDots' style={blurbs.length > 1 ? null : { display: 'none' }}>
            <Dots size={isDesktop() ? 40 : 20 } margin={isDesktop() ? 12 : 3} position={selection} clickable={true} length={blurbs.length} positionChangeListener={ position => this.onChangeIndex(position)} />
          </div>
        </div>
        */