/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import './index.css'


export class InputField extends Component {
  onChange = e => {
    this.props.onChange(this.props.name, e.target.value)
  }
  
  onKeyDown = e => {
    console.log("onKeyDown", e.keyCode)
    if (e.keyCode == 27) {
      this.ref.blur()
    }
  }

  setRef = ref => {
    if (ref) {
      this.ref = ref
    }
  }
  
  render () {
    let className = 'fdInputField'
    if (this.props.error && this.props.error.field === this.props.name) {
      className += ' fdInputFieldError'
    }
    return (
        <div className={className}>
        <input
      ref={this.setRef}
      className='fdInputFieldInput'
      type={this.props.type}
      value={this.props.value}
      placeholder={this.props.placeholder}
      name={this.props.name}
      onBlur={this.props.onBlur}
      onFocus={this.props.onFocus}
      autoComplete={this.props.autoComplete || 'off'}
      onChange={this.onChange}
      onKeyDown={this.onKeyDown}
        />
        </div>
    )
  }
}
