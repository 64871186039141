import React, {Component} from 'react'
import {ReactSVG} from 'react-svg'
import {UIOKCancel} from '../OKCancel'
import Group from "../../assets/icons/Group.svg";
import Cross from "../../assets/icons/Cross.svg";
import {UIProfileIcon} from "../ProfileIcon";
import {UIPage} from '../Page'
import {UIInputField} from "../Home";
import ProfileSml from "../../assets/icons/ProfileSml.svg";
import Plus from "../../assets/icons/Plus.svg";
import Fwd from "../../assets/icons/Forward.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import Update from "../../assets/icons/Update.svg";
import Trash from "../../assets/icons/Trash.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import './index.css'

export class UICreateGroup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form : {groupMembers: [this.props.me.self], name: ''},
      formError: {}
    }
  }

  componentDidMount() {
    if (this.props.group) {
      const g = this.props.group
      this.state.form.name = g.name
      this.state.form.groupMembers = g.members.map(uid => this.props.me.getContact(uid))
    }
  }

  onChange = (field, value) => {
    if (field === 'name') {
      this.groupNameSet = true
    }
    if (this.state.formError.field === field) {
      this.state.formError = {}
      this.state.error = ''
    }
    if (this.state.form[field] != value) {
      this.state.form[field] = value;
    }
    this.forceUpdate()
  }

  searchContacts = (searchValue) => {
    const searchTerms = searchValue.toLowerCase().split(/\s+/);
    const matches = {};
    const filter = x => {
      if (this.state.form.groupMembers.find(y => y === x)) return false
      if (x.uid === this.props.me.self.uid) {
        return false
      }
      if (!searchValue) return true;
      let matched = 0;
      const name = x.displayName.toLowerCase();
      const terms = name.split(/\s+/);
      searchTerms.map(searchTerm => {
        terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
      });
      matches[x.uid] = matched;
      return matched > 0;
    }
    const sort = (x, y) => {
      const w1 = matches[x.uid] || 0;
      const w2 = matches[y.uid] || 0;
      const cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      return x.displayName ? y.displayName ? x.displayName.localeCompare(y.displayName) : 1 : -1;
    }
    let result
    result = this.props.me.getCurrentContacts().filter(filter);
    result.sort(sort);
    return result;
  }

  addContact = async () => {
    if (this.state.formError.field == 'members') {
      this.state.formError = {}
      this.state.error = ''
    }
    if (!this.state.form.contact) {
      this.state.formError = { field: 'contact', error: 'Please select a contact' }
      this.state.error = 'Please select a contact.'
      this.forceUpdate()
      return
    }
    this.state.form.groupMembers.push(this.state.form.contact)
    this.state.form.contact = ''
    this.forceUpdate()
  }

  deleteMember = contact => {
    if (this.state.formError.field == 'members') {
      this.state.formError = {}
      this.state.error = ''
    }
    this.state.form.groupMembers = this.state.form.groupMembers.filter(x => x.uid !== contact.uid)
    this.forceUpdate()
  }

  renderGroupMembers = () => {
    return <div className='uiGroupMembers'>
      <div className='uiGroupMembersTitle'>Members</div>
      <div className='uiGroupMembersList'>
      {this.state.form.groupMembers.map(contact => {
        return <div className='uiGroupMembersListMember'><UIProfileIcon contact={contact}/><div className='uiWorkoutCardMessageName'>{contact.displayName}</div>{contact.uid !== this.props.me.self.uid && <div className='uiGroupDeleteMember' onClick={()=>this.deleteMember(contact)}><ReactSVG src={Cross}/></div>}</div>
      })}
      </div>
      </div>
  }
  
  renderPage1 = () => {
    return <div className='uiScheduleAppointmentBodyFields uiCreateGroupFields'>
      <div key="client" className='uiScheduleAppointmentBodyField uiScheduleAppointmentWithFilterField'>
      <UIInputField label={'Contacts'} search={this.searchContacts} value={this.state.form.contact} error={this.state.formError} icon={ProfileSml} name='contact' onChange={this.onChange} type='contact'/>
      </div>
      <div className='uiMeContactButtons uiMeContactButtonsCreateGroup'>
      <UIOKCancel okIcon={Plus} label="Add Member" ok={this.addContact}/>
      </div>
      {this.renderGroupMembers()}
      <div key="gropuName" className='uiScheduleAppointmentBodyField'>
      <UIInputField label={'Group Name'} placeholder={this.state.form.groupMembers.filter(x => x.uid !== this.props.me.self.uid).map(x => x.displayName).join(', ')} value={this.state.form.name} error={this.state.formError} icon={Edit} name='name' onChange={this.onChange} type={'text'}/>
      </div>
      <div className='uiCreateGroupError'>{this.state.error}</div>
      </div>
  }

  deleteGroup = async () => {
    this.setState({
      confirmDeleteGroup: true
    })
  }

  createGroup = async () => {
    if (this.props.group) {
      await this.props.me.updateGroup(this.props.group.uid, this.state.form.name, this.state.form.groupMembers)
      return this.props.close()
    }
    if (this.state.form.groupMembers.length < 3) {
      return this.setState({
        formError: { field: 'members'  },
        error: "At least 3 members required."
      })
    }
    await this.props.me.createGroup(this.state.form.name, this.state.form.groupMembers)
    return this.props.close() 
 }

  performDeleteGroup = async () => {
    await this.props.me.deleteGroup(this.props.group.uid)
    return this.props.close() 
  }

  renderFooter = () => {
    let actionLabel = 'Create Group'
    let okAction = async () => {
      await this.createGroup()
    }
    let okIcon = Fwd
    let cancelIcon
    let cancelAction
    if (this.props.group) {
      okIcon = Update
      actionLabel = 'Update Group'
      cancelIcon = Trash
      cancelAction = this.deleteGroup
    }
    const onStopHover = () => {
      this.setState({
        confirmDeleteGroup: false
      })
    }
    return <div className='uiCreateGroupPageFooter'>
      <UIOKCancel okIcon={okIcon} cancelIcon={cancelIcon} label={actionLabel} ok={okAction} cancel={cancelAction}/>
      {this.state.confirmDeleteGroup && <ClickAwayListener onClickAway={onStopHover}><div key='confirm' className='uiRemoveContactConfirm' onMouseLeave={onStopHover}>
      <UIOKCancel ok={this.performDeleteGroup} okIcon={Trash} label={"Confirm deleting this group"}/>
       </div></ClickAwayListener>}
      </div>
  }

  render() {
    let subPage
    return <div className='uiGroup'><UIPage
    title={'Group'}
    icon={Group}
    subPage={subPage}
    footer={this.renderFooter()}
    close={this.props.close}>
      {this.renderPage1()}
    </UIPage>
      </div>
  }
}


