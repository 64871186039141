/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/jsx-handler-names */

import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { FDOKCancel } from '../OKCancel'
import Spinner from '../../assets/Icons/spinner.svg'
import Arrow from '../../assets/Icons/MoreArrow.svg'
import { showBusy } from '../Client'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import './index.css'

export class FDRadioButtons extends Component {

  select = (e, button) => {
    e.preventDefault()
    return this.props.select(button)
  }
  render () {
    return (
      <div className='fdRadioButtons'>
        <div className='fdRadioButtonsTrack'>
          {this.props.buttons.map(button => {
            const selected = button.selector === this.props.selection
            let className = 'fdRadioButton'
            if (selected) className += ' fdRadioButtonSelected'
            return (
               <div className={className} key={button.selector} onClick={(e) => this.select(e, button)}>
                {button.content ? button.content() : button.icon ? <div className={'fdButtonIcon' + (button.className ? ` ${button.className}` : '')}><ReactSVG src={button.icon()}/></div> : button.label}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export class FDButton1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false,
      fail: false
    }
  }

    onClick = () => {
      if (this.state.fail) return Promise.resolve()
      if (this.state.busy) return
      showBusy(true)
      this.setState({
        busy: true
      })
      let p = this.props.onClick()
      if (!p || !p.then) p = Promise.resolve()
      return p.then(() => {
        showBusy(false)
        this.setState({
          busy: false
        })
      }).catch(err => {
        console.error(err)
        showBusy(false)
        this.setState({
          fail: true,
          busy: false
        })
      })
    }

    render () {
      const icon = this.props.icon || Arrow
      return (
        <div className='fdButton1' onClick={this.onClick}>
          <div className='fdButton1Label'>{this.props.label}</div>
          {!this.state.busy && <div className='fdButton1Arrow'><ReactSVG src={icon} /></div>}
          {this.state.busy && <div className='fdButton1Spinner'><ReactSVG src={Spinner} /></div>}
          {this.state.fail &&
            <FDOKCancel
              title='Operation Unsuccessful'
              message='Internal Error.'
              ok={() => this.setState({ fail: false })}
              okLabel='OK'
            />}
        </div>
      )
    }
}

export class FDButton2 extends FDButton1 {
  render () {
    return (
      <div className='fdButton2' onClick={this.onClick}>
          {!this.state.busy && <div className='fdButton2Label'>{this.props.label}</div>}
          {this.state.busy && <div className='fdButton1Spinner'><ReactSVG src={Spinner} /></div>}
          {this.state.fail &&
            <FDOKCancel
              title='Operation Unsuccessful'
              message='Internal Error.'
              ok={() => this.setState({ fail: false })}
              okLabel='OK'
            />}
      </div>
    )
  }
}

export class FDButtonException extends FDButton1 {

  render () {
    return (
      <div className='fdButtonException' onClick={this.onClick}>
        {!this.state.busy &&  <div className='fdButtonExceptionLabel'>{this.props.label}</div>}
        {(this.props.busy || this.state.busy) && <div className='fdButton1Spinner'><ReactSVG src={Spinner} /></div>}
        {this.state.fail &&
          <FDOKCancel
            title='Operation Unsuccessful'
            message='Please try again later.'
            ok={() => this.setState({ fail: false })}
            okLabel='OK'
          />}
      </div>
    )
  }
}

export class Button extends Component {
  render () {
    return (
      <div className='fdButton' onClick={this.props.onClick}>
        <div className='fdButtonLabel'>{this.props.label}</div>
      </div>
    )
  }
}


export class FDInputField extends Component {
  onChange = e => {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, e.target.value)
    }
    this.props.form[this.props.name] = e.target.value
    this.forceUpdate()
  }

  render () {
    const label = this.props.label
    const name = this.props.name
    const onChange = this.onChange
    const err = this.props.formErr[name]
    const value = this.props.form[name]
    return <mobiscroll.Input min={this.props.min} max={this.props.max} autoComplete={this.props.autoComplete} valid={!err} errorMessage={err} value={value} onChange={onChange} placeholder={label} disabled={!!this.props.readOnly} />
  }
}

export class FDArrowField extends Component {
  render () {
    return (
      <div className='fdArrowField' onClick={this.props.onClick}>
        <div className='fdArrowFieldLabel'>{this.props.label}</div>
        <div className='fdArrowFieldArrow'><ReactSVG src={Arrow} /></div>
      </div>
    )
  }
}

