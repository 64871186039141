import React, {Component} from "react";
import {fabric} from 'fabric-with-gestures';
//import { MathfieldComponent } from "react-mathlive";
import domtoimage from 'dom-to-image-improved';
import ResizeObserver from 'resize-observer-polyfill';
import PenCursor from "../../assets/icons/Blackboard/PenCursor.png";

let canvasId = 0;

const Tools = {
  Pen : 'pen',
  Line : 'line',
  Arrow : 'arrow',
  Stroke : 'stroke',
  StrokeColor : 'stroke-color',
  FillColor : 'fill-color',

  Text : 'text',
  Math : 'math',
  Rectangle : 'rectangle',
  Image : 'image',
  Ellipse : 'ellipse',
  Polygon : 'polygon',

  Select : 'select',
  Pan : 'pan',
  Undo : 'undo',
  Redo : 'redo',
  Clear : 'clear',
  Rooms : 'rooms'
}

const ClipTools = {
    Cut : 'cut',
    Copy : 'copy',
    Paste : 'paste',
    Delete : 'delete'
}

export class UIWhiteboard extends Component {
    canvas= null;
    toolSelected= '';
    tools = Tools;
    selectorOpen= false;
    shapeStarted= false;
    shapeBeingAdded= null;
    lastPosX= null;
    lastPosY= null;
    shapeStartX= 0;
    shapeStartY= 0;
    snapshotHistory= [];
    currentSnapshotIndex= -1;
    canRedo= false;
    fillColor= '#f2f2f2';
    strokeColor= '#f2f2f2';
    strokeWidth= 3;
    isTypingMath= false;
    polygonPoints= [];
    clipboard= {};
    nMathFields = 0;
    currentMathfield= null;
    mathCoords= [];
    isMobile = false;
    shouldSelectTextBox = false;
    lastPosX = 0;
    lastPosY = 0;

    maxNewImageHeight = 500;
    maxNewImageWidth = 360;

    constructor(props) {
        super(props);
        canvasId++;
        this.canvasId = "canvas-"+canvasId;
    }

    positionStringsForTool() {
        switch (this.toolSelected) {
        case Tools.Ellipse:
            return { x: 'rx', y: 'ry' };
        case Tools.Line:
        case Tools.Arrow:
            return { x: 'x2', y: 'y2' };
        default:
            return { x: 'width', y: 'height' };
        }
    }

    componentDidMount() {
        // Set up Fabric.js canvas
        console.log("whiteboard mounted");
        this.canvas = new fabric.Canvas(this.canvasId);
        this.clickedTool(Tools.Pen);
        this.canvas.setWidth(this.props.width);
        this.canvas.setHeight(this.props.height);
        this.canvas.selectionFullyContained = false;
        fabric.Object.prototype.set({
            transparentCorners: false,
            borderColor: '#63CBAB',
            cornerColor: '#87E5CA',
        });
        this.listenToCanvasEvents();
        this.sub = this.props.observeCanvasData().subscribe(this.receiveCanvas);
        this.resizeObserver = new ResizeObserver(entries => {
            const w = this.canvasContainer.offsetWidth;
            const h = this.canvasContainer.offsetHeight;
            this.canvas.setWidth(w);
            this.canvas.setHeight(h);
            this.canvas.calcOffset();
            this.props.onResize(w, h);
        });
        this.resizeObserver.observe(this.canvasContainer);
        this.props.onInit(this);
    }

    componentWillUnmount() {
        if (this.sub) this.sub.unsubscribe();
        this.resizeObserver.disconnect();
        this.canvas.dispose();
    }

    // LISTENING TO CANVAS EVENTS

    listenToCanvasEvents() {
        this.listenToMouseDown();
        this.listenToMouseMove();
        this.listenToMouseUp();
        this.listenToDoubleClick();
        this.listenToTouchGesture();
        this.listenToObjectModified();
        this.listenToMouseWheel();        
    }

    listenToMouseWheel() {
        const canvas = this.canvas;
        function keepPositionInBounds() {
            var zoom = canvas.getZoom();
            var xMin = (2 - zoom) * canvas.getWidth() / 2;
            var xMax = zoom * canvas.getWidth() / 2;
            var yMin = (2 - zoom) * canvas.getHeight() / 2;
            var yMax = zoom * canvas.getHeight() / 2;
            
            var point = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2);
            var center = fabric.util.transformPoint(point, canvas.viewportTransform);
            
            var clampedCenterX = clamp(center.x, xMin, xMax);
            var clampedCenterY = clamp(center.y, yMin, yMax);
            
            var diffX = clampedCenterX - center.x;
            var diffY = clampedCenterY - center.y;
            
            if (diffX != 0 || diffY != 0) {
                canvas.relativePan(new fabric.Point(diffX, diffY));
            }
        }
        
        function clamp(value, min, max) {
            return Math.max(min, Math.min(value, max));
        }
        
        canvas.on('mouse:wheel', function(options) {
            var delta = options.e.deltaY;
            if (delta != 0) {
                var pointer = canvas.getPointer(options.e, true);
                var point = new fabric.Point(pointer.x, pointer.y);
                var zoom = canvas.getZoom();
                zoom *= 0.999 ** delta;
                if (zoom > 20) zoom = 20;
                if (zoom < 0.01) zoom = 0.01;
                canvas.zoomToPoint(point, zoom);
                //keepPositionInBounds(canvas);
            }
        })
    }

    listenToMouseDown() {
        this.canvas.on('mouse:down', event=> {
            const evt = event.e;
            const mouse = this.canvas.getPointer(evt);

            switch (this.toolSelected) {
            case Tools.Pan:
                if (evt.type.includes('touch')) {
                    return;
                }
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
                return;
            case Tools.Rectangle:
            case Tools.Ellipse:
            case Tools.Line:
            case Tools.Arrow:
                this.shapeStarted = true;
                this.shapeStartX = mouse.x;
                this.shapeStartY = mouse.y;
                const options = {
                    width: 0,
                    height: 0,
                    left: this.shapeStartX,
                    top: this.shapeStartY,
                    fill: this.fillColor,
                    stroke: this.strokeColor,
                    strokeWidth: this.strokeWidth,
                };
                if (this.toolSelected !== Tools.Arrow) {
                    const shape = this.createNewShape(options);
                    this.addShape(shape);
                    this.canvas.renderAll();
                    this.shapeBeingAdded = shape;
                } else {
                    const shape = [
                        new fabric.Line([
                            this.shapeStartX,
                            this.shapeStartY,
                            this.shapeStartX,
                            this.shapeStartY,
                        ], options),
                        new fabric.Triangle({
                            ...options,
                            fill: this.strokeColor,
                            width: this.strokeWidth * 4,
                            height: this.strokeWidth * 4,
                            originX: 'center',
                            originY: 'center',
                            id: 'arrow-pointer'
                        })
                    ];
                    this.addShape(shape[0]);
                    this.addShape(shape[1]);
                    this.canvas.renderAll();
                    this.shapeBeingAdded = shape;
                }
                return;
            default:
                return;
            }
        });
    }

    listenToMouseMove() {
        
        this.canvas.on('mouse:move', event=> {
            const evt = event.e;
            console.log("mouse move: ", event);
            const mouse = this.canvas.getPointer(evt);

            if (
                this.toolSelected === Tools.Pan &&
                    !!this.lastPosX &&
                    !!this.lastPosY &&
                    !(evt.type.includes('touch'))

            ) {
                this.canvas.viewportTransform[4] += evt.clientX - this.lastPosX;
                this.canvas.viewportTransform[5] += evt.clientY - this.lastPosY;
                this.canvas.requestRenderAll();
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            }

            if (this.shapeStarted) {
                if (this.toolSelected === Tools.Arrow) {
                    this.dragOutShape(this.shapeBeingAdded[0], mouse);
                    this.dragOutShape(this.shapeBeingAdded[1], mouse);
                } else {
                    this.dragOutShape(this.shapeBeingAdded, mouse);
                }
                this.canvas.renderAll();
            }
        });
    }

    listenToMouseUp() {
        this.canvas.on('mouse:up', event => {
            const mouse = this.canvas.getPointer(event.e);
            if (this.shouldSelectTextBox) {
                this.shouldSelectTextBox = false;
                this.clickedTool(Tools.Select);
                this.canvas.setActiveObject(this.shapeBeingAdded);
                this.shapeBeingAdded = {};
                this.canvas.renderAll();
                this.pushSnapshot();
                this.sendCanvas();
                return;
            }
            switch (this.toolSelected) {
            case Tools.Math:
                this.addMathField(mouse);
                return;
            case Tools.Pan:
                if (event.e.type.includes('touch')) {
                    return;
                }
                this.canvas.forEachObject(function(o) {
                    o.selectable = true;
                    o.setCoords();
                });
                this.lastPosX = null;
                this.lastPosY = null;
                return;
            case Tools.Rectangle:
            case Tools.Ellipse:
            case Tools.Line:
            case Tools.Arrow:
            case Tools.Pen:
                if (this.toolSelected === Tools.Arrow) {
                    const arrowShape = new fabric.Group(this.shapeBeingAdded);
                    this.addShape(arrowShape);
                    this.shapeBeingAdded.forEach(shape=> {
                        this.canvas.remove(shape);
                    });
                }
                this.canvas.renderAll();
                this.pushSnapshot();
                this.sendCanvas();
                this.shapeStarted = false;
                this.shapeBeingAdded = {};
                return;
            case Tools.Polygon:
                this.addPolygonPoint(mouse);
                return;
            case Tools.Text:
                this.addText(mouse);
                return;
            default:
                return;
            }
        });
    }

    listenToDoubleClick() {
        this.canvas.on('mouse:dblclick', event => {
            this.finishPolygon();
        });

        this.canvas.on('touch:longpress', async event => {
            const mouse = this.canvas.getPointer(event.e);
            this.addPolygonPoint(mouse);
            this.finishPolygon();
        });
    }

    listenToTouchGesture() {
        this.canvasContainer
            .addEventListener('touchstart', (e) => {
                if (this.toolSelected === Tools.Pan) {
                    this.lastPosX = e.touches[0].clientX;
                    this.lastPosY = e.touches[0].clientY;
                    this.canvas.selection = false;
                }
            }, false);
        this.canvasContainer
            .addEventListener('touchend', (e) => {
                if (this.toolSelected === Tools.Pan) {
                    this.lastPosX = null;
                    this.lastPosY = null;
                    this.canvas.forEachObject(function(o) {
                        o.selectable = true;
                        o.setCoords();
                    });
                }
            }, false);
        this.canvas.on('touch:drag', event => {
            console.log("touch drag: ", event);
            if (event.e.touches && event.e.touches[0]) {
                if (this.toolSelected === Tools.Pan && !!this.lastPosX && !!this.lastPosY) {
                    this.canvas.viewportTransform[4] += event.e.touches[0].clientX - this.lastPosX;
                    this.canvas.viewportTransform[5] += event.e.touches[0].clientY - this.lastPosY;
                    this.canvas.requestRenderAll();
                    this.lastPosX = event.e.touches[0].clientX;
                    this.lastPosY = event.e.touches[0].clientY;
                }
            }
        });
    }

    listenToObjectModified() {
        this.canvas.on('object:modified', event => {
            this.pushSnapshot();
            this.sendCanvas();
        });
    }
    
    // ADDING SHAPES

    createNewShape(options: any) {
        switch (this.toolSelected) {
        case Tools.Rectangle:
            return new fabric.Rect(options);
        case Tools.Ellipse:
            const ellipseOpts = {
                originX: 'left',
                originY: 'top',
                rx: 5,
                ry: 1,
                ...options,
            };
            return new fabric.Ellipse(ellipseOpts);
        case Tools.Text:
            return new fabric.IText('Write some text', options);
        case Tools.Line:
            return new fabric.Line([
                this.shapeStartX,
                this.shapeStartY,
                this.shapeStartX,
                this.shapeStartY,
            ], options);
        default:
            return {};
        }
    }

    dragOutShape(shape, mouse) {
        if (this.toolSelected === Tools.Line || this.toolSelected === Tools.Arrow) {
            if (this.toolSelected === Tools.Arrow && shape.id === 'arrow-pointer') {
                const angle = Math.atan2(
                    (mouse.y - this.shapeStartY),
                    (mouse.x - this.shapeStartX),
                );
                shape.rotate(fabric.util.radiansToDegrees(angle) + 90);
                shape.set('left', mouse.x + 1).set('top', mouse.y + 2);
            } else {
                shape
                    .set(this.positionStringsForTool().x, mouse.x)
                    .set(this.positionStringsForTool().y, mouse.y);
            }
        } else {
            shape.set('left', Math.min(mouse.x, this.shapeStartX));
            shape.set('top', Math.min(mouse.y, this.shapeStartY));
            const sizeMultiplier = this.toolSelected === Tools.Ellipse ? 0.5 : 1;
            shape
                .set(this.positionStringsForTool().x, Math.abs(mouse.x - this.shapeStartX) * sizeMultiplier)
                .set(this.positionStringsForTool().y, Math.abs(mouse.y - this.shapeStartY) * sizeMultiplier);
        }
        shape.setCoords();
    }

    addText(mouse) {
        const options = {
            left: mouse.x,
            top: mouse.y,
            fill: this.strokeColor,
        };
        const shape = this.createNewShape(options);
        shape.on('editing:exited', event => {
            if (!shape.text.trim()) {
                this.canvas.remove(shape);
            } else {
                this.shouldSelectTextBox = true;
                this.shapeBeingAdded = shape;
            }
        });
        this.addShape(shape);
        this.canvas.setActiveObject(shape);
        this.canvas.renderAll();
        shape.enterEditing();
        shape.selectAll();
    }

    addPolygonPoint(mouse) {
        const pt = { x: mouse.x, y: mouse.y };
        this.polygonPoints.push(pt);
        if (this.polygonPoints.length === 1) {
            this.polygonPoints.push({ x: mouse.x + 1, y: mouse.y + 1 });
            const shape = new fabric.Polygon(this.polygonPoints, {
                perPixelTargetFind: true,
                top: mouse.y,
                left: mouse.x,
                fill: this.fillColor,
                stroke: this.strokeColor,
                strokeWidth: this.strokeWidth
            });
            this.addShape(shape);
            this.canvas.renderAll();
            this.shapeBeingAdded = shape;
        } else {
            const newPts = this.polygonPoints;
            const activePolygons = this.canvas.getObjects().filter(obj=> {
                return obj.type === 'polygon';
            });
            const newObject = activePolygons[0].toObject();
            this.canvas.remove(...activePolygons);
            delete newObject.points;
            delete newObject.top;
            delete newObject.left;
            const shape = new fabric.Polygon(newPts, newObject);
            this.shapeBeingAdded = shape;
            this.addShape(shape);
            this.canvas.renderAll();
        }
    }


    finishPolygon() {
        if (this.toolSelected === Tools.Polygon && this.polygonPoints.length) {
            this.pushSnapshot();
            this.sendCanvas();
            this.shapeStarted = false;
            this.shapeBeingAdded = {};
            this.polygonPoints = [];
            this.clickedTool(Tools.Select);
        }
    }

    async addMathField(mouse) {
        if (this.isTypingMath) {
            const math = document.getElementById(`mathfield-${this.nMathFields}`);
            this.nMathFields += 1;
            const node = document.getElementsByClassName('ML__fieldcontainer__field')[0];
            if (node) {
                const dataUrl = await domtoimage.toSvg(node);
                fabric.Image.fromURL(dataUrl, async img=> {
                    img.set('left', this.mathCoords[0]).set('top', this.mathCoords[1]);
                    this.addShape(img);
                    img.setCoords();
                    const canvas = this.canvasContainer;
                    this.currentMathfield.$perform('hide-virtual-keyboard');
                    canvas.removeChild(math);
                    this.currentMathfield = null;
                    this.isTypingMath = false;
                    this.mathCoords = [];
                    this.clickedTool(Tools.Select);
                    this.canvas.setActiveObject(img);
                    this.canvas.renderAll();
                    this.pushSnapshot();
                    this.sendCanvas();
                });
            } else {
                const math = document.createElement('div');
                const canvas = this.canvasContainer;
                math.id = `mathfield-${this.nMathFields}`;
                math.style.position = 'absolute';
                math.style.top = `${mouse.y}px`;
                math.style.left = `${mouse.x}px`;
                this.mathCoords = [mouse.x, mouse.y];
                math.style.paddingLeft = '12px';
                math.style.paddingRight = '12px';
                math.style.border = '1px dotted black';
                canvas.appendChild(math);
                /*
                const field = MathLive.makeMathField(
                    `mathfield-${this.nMathFields}`, {
                        virtualKeyboardMode: this.isMobile ? 'manual' : 'off',
                        onBlur: f=> {
                            f.$perform('hide-virtual-keyboard');
                        }
                    }
                );
                this.currentMathfield = field;
                this.isTypingMath = true;
                field.$focus();
                */
            }
        }
    }

  

  imageUploaded(img) {
    let image
    if (img instanceof HTMLVideoElement) {
      image = new fabric.Image(img, {
        height: img.videoHeight,
        width: img.videoWidth,
        originX: 'center',
        originY: 'center',
        objectCaching: false
      });
      const self = this
      fabric.util.requestAnimFrame(function render() {
        self.canvas.renderAll();
        fabric.util.requestAnimFrame(render);
      });      
    } else {
      image = new fabric.Image(img)
    }
      
        // Scale down to smallest iphone size
        if (image.height > this.maxNewImageHeight) {
            image.scaleToHeight(this.maxNewImageHeight);
        }
        if (image.width * image.scaleX > this.maxNewImageWidth) {
            image.scaleToWidth(this.maxNewImageWidth);
        }

        this.canvas.centerObject(image);
        this.addShape(image);
        this.canvas.setActiveObject(image);
        this.canvas.renderAll();
        this.clickedTool(Tools.Select);
    }

    addShape(shape) {
        shape.evented = false;
        shape.selectable = false;
        this.canvas.add(shape);
    }

    penTool = () => {
        this.clickedTool(Tools.Pen);
    }

    selectTool = () => {
        this.clickedTool(Tools.Select);
    }

    undo = () => {
        const tool = this.tool;
        this.clickedTool(Tools.Undo);
        this.clickedTool(tool);
    }

    redo = () => {
        const tool = this.tool;
        this.clickedTool(Tools.Redo);
        this.clickedTool(tool);
    }

    clear = () => {
        this.clickedClipTool(ClipTools.Delete);
    }

    pan = () => {
        this.clickedTool(Tools.Pan);
    }

    getColor = () => {
        return this.strokeColor;
    }

    setColor = color => {
        this.strokeColor = color;
        console.log("setColor: ", this.strokeColor, " <= ", color);
        this.canvas.freeDrawingBrush.color = this.strokeColor;
    } 

    // TOOL SELECTION

    clickedTool(tool: string) {
        this.tool = tool;
        this.canvas.selection = (tool === Tools.Select);

        if (tool !== Tools.Stroke && 
            tool !== Tools.FillColor && 
            tool !== Tools.StrokeColor
           ) {
            this.toggleObjectsSelectable(tool === Tools.Select);
        }

        this.canvas.isDrawingMode = (tool === Tools.Pen);

        if (!(Object.values(ClipTools)).includes(tool)) {
            if (this.toolSelected !== tool) {
                this.toolSelected = tool;
                this.selectorOpen = true;
            } else {
                this.selectorOpen = !this.selectorOpen;
            }
        }

        switch (tool) {
        case Tools.Select:
            this.canvas.defaultCursor = 'crosshair';
            return;
        case Tools.Pan:
            this.canvas.defaultCursor = 'grab';
            return;
        case Tools.Pen:
            this.canvas.freeDrawingBrush.color = this.strokeColor;
            this.canvas.freeDrawingBrush.width = this.strokeWidth;
            const cursor = "url("+PenCursor+") 16 16, auto";
            this.canvas.freeDrawingCursor = cursor;
            console.log("cursor: ",  cursor);
            return;
        case Tools.Clear:
            this.canvas.remove(...this.canvas.getObjects());
            this.pushSnapshot();
            this.sendCanvas();
            return;
        case Tools.Undo:
            if (this.currentSnapshotIndex !== 0) {
                this.currentSnapshotIndex -= 1;
                this.canvas.loadFromJSON(
                    JSON.parse(this.snapshotHistory[this.currentSnapshotIndex][1]),
                    this.canvas.renderAll.bind(this.canvas),
                );
                this.sendCanvas();
                this.canRedo = true;
            }
            return;
        case Tools.Redo:
            if (this.currentSnapshotIndex !== this.snapshotHistory.length - 1 && this.canRedo) {
                this.currentSnapshotIndex += 1;
                this.canvas.loadFromJSON(
                    JSON.parse(this.snapshotHistory[this.currentSnapshotIndex][1]),
                    this.canvas.renderAll.bind(this.canvas),
                );
                this.sendCanvas();
            }
            return;
        default:
            return;
        }
    }

    clickedClipTool(tool) {
        switch (tool) {
        case ClipTools.Delete:
            if (this.canvas.getActiveObjects()) {
                this.canvas.remove(...this.canvas.getActiveObjects());
                this.canvas.discardActiveObject();
                this.pushSnapshot();
                this.sendCanvas();
            }
            return;
        case ClipTools.Cut:
            this.copyToClipboard(true);
            return;
        case ClipTools.Copy:
            this.copyToClipboard(false);
            return;
        case ClipTools.Paste:
            this.paste();
            return;
        default:
            return;
        }
    }

    toggleObjectsSelectable(selectable) {
        this.canvas.discardActiveObject();
        this.canvas.forEachObject(obj=> {
            obj.selectable = selectable;
            obj.evented = selectable;
        });
        this.canvas.renderAll();
    }
    
    updateColorValue(value) {
        if (this.toolSelected === Tools.StrokeColor) {
            this.strokeColor = value.hex;
            if (this.canvas.getActiveObject()) {
                this.canvas.getActiveObject().set('stroke', this.strokeColor);
                this.canvas.renderAll();
            }
        } else {
            this.fillColor = value.hex;
            if (this.canvas.getActiveObject()) {
                this.canvas.getActiveObject().set('fill', this.fillColor);
                this.canvas.renderAll();
            }
        }
        if (this.canvas.isDrawingMode) {
            this.canvas.freeDrawingBrush.color = this.strokeColor;
        }
    }

    updateStrokeWidth(value) {
        this.strokeWidth = value;
        if (this.canvas.getActiveObject()) {
            this.canvas.getActiveObject().set('strokeWidth', this.strokeWidth);
            this.canvas.renderAll();
        }
    }

    // CLIPBOARD
    // adapted from https://codepen.io/agenziabrand/details/QWLKMWz

    copyToClipboard(shouldCut) {
        if (!!this.canvas.getActiveObject()) {
            this.canvas.getActiveObject().clone(cloned=> {
                this.clipboard = cloned;
                if (shouldCut) {
                    this.canvas.remove(this.canvas.getActiveObject());
                    this.canvas.discardActiveObject();
                }
            });
            this.pushSnapshot();
            this.sendCanvas();
        }
    }

    paste() {
        if (!!Object.keys(this.clipboard).length) {
            this.clipboard.clone(clonedObj=> {
                this.canvas.discardActiveObject();
                clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                    evented: true,
                });
                if (clonedObj.type === 'activeSelection') {
                    clonedObj.canvas = this.canvas;
                    clonedObj.forEachObject(obj=> {
                        this.canvas.add(obj);
                    });
                    clonedObj.setCoords();
                } else {
                    this.canvas.add(clonedObj);
                }
                this.clipboard.top += 10;
                this.clipboard.left += 10;
                this.canvas.setActiveObject(clonedObj);
                this.canvas.renderAll();
            });
            this.pushSnapshot();
            this.sendCanvas();
        }
    }
    
    // BOARD STATE
    
    pushSnapshot(boardState) {
        this.currentSnapshotIndex += 1;
        if (this.canRedo) {
            this.snapshotHistory = this.snapshotHistory.slice(0, this.currentSnapshotIndex);
            this.canRedo = false;
        }
        if (!boardState) boardState = JSON.stringify(this.canvas.toJSON());
        this.snapshotHistory.push([Date.now(), boardState]);
    }

    lastSent = 0;
    
    sendCanvas() {
      const canvasAsJSON = this.canvas.toJSON();
      console.log('canvasJson', canvasAsJSON)
        this.lastSent = Date.now();
        this.props.sendCanvasData({
            ts: this.lastSent,
            canvasData: canvasAsJSON
        });
    }
    
    receiveCanvas = (data) => {
      if (data.fromMe && data.ts <= this.lastSent) {
        return;
      }
      if (!data.canvasData) return
      console.log("receive canvas: ", data);
      this.canvas.loadFromJSON(JSON.parse(data.canvasData), this.canvas.renderAll.bind(this.canvas));
      this.pushSnapshot(data.canvasData);
    }

    setRef = x => {
        if (x) {
            this.canvasContainer = x;
        }
    }

    
    
    render() {
        return <div ref={this.setRef} className='uiWhiteboard2'>
            <canvas id={this.canvasId}/>
            </div>
    }
}
