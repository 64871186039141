import React, { Component } from 'react';
import { LBHero } from '../lbHero/';
import './index.css';

export class LBSurface extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
        <div className='letsSurface'>
          <LBHero signIn={this.props.signIn} signUp={this.props.signUp} />
        </div>
      );
    }
}
