import { isAndroid } from './Platform.js'

const Clipboard = (function (window, document, navigator) {
  let textArea, copy

  function createTextArea (text) {
    textArea = document.createElement('textArea')
    textArea.value = text
    textArea.readOnly = true
    document.body.appendChild(textArea)
  }

  function selectText () {
    let range,selection
    range = document.createRange()
    range.selectNodeContents(textArea)
    selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    textArea.setSelectionRange(0, 999999)
  }

  function copyToClipboard () {
    document.execCommand('copy')
    document.body.removeChild(textArea)
  }

  copy = function (text) {
    if (navigator.clipboard && !isAndroid()) {
      return navigator.clipboard.writeText(text)
    }
    createTextArea(text)
    selectText()
    copyToClipboard()
    return Promise.resolve()
  }

  return {
    copy: copy
  }
})(window, document, navigator)

export default Clipboard
