import React from 'react'
import {format} from 'd3-format'
import moment from 'moment'
import {RadarChart} from 'react-vis'
import './index.css'

const getCalories = meal => {
  let total = 0
  meal.foods.forEach(food => {
    total += Math.round((food.nf_calories ||
                         food.nutrition.nf_calories) * (food.count || 1))
  })
  return total
}

const basicFormat = format('.2r');
const wideFormat = format('.3r');

export class SummaryChart extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
    this.days = {}
  }

  componentDidMount = () => {
  }

  componentDidUpdate = () => {
  }

  loadData = () => {
    this.maxCalIn = 0
    this.maxCalOut = 0
    this.days = {}
    const getDay = ts => {
      let when = moment(new Date(ts)).startOf('day').toDate().getTime()
      if (!this.days[when]) {
        this.days[when] = {
          date: new Date(when),
          caloriesIn: 0,
          caloriesOut: 0,
          strain: 0,
          recovery: 0,
          sleep: 0
        }
      }
      return this.days[when]
    }
    this.props.meals.map(meal => {
      const day = getDay(meal.start)
      day.caloriesIn += getCalories(meal)
      this.maxCalIn = Math.max(this.maxCalIn, day.caloriesIn)
    })
    this.props.cycleData.map(cycle => {
      const when = Date.parse(cycle.during.lower)
      const next = Date.parse(cycle.during.upper)
      const day = getDay(when)
      day.sleep = cycle.sleep ? cycle.sleep.score : 0
      day.strain = cycle.strain ? cycle.strain.score : 0
      day.recovery = cycle.recovery ? cycle.recovery.score : 0
      day.caloriesOut = cycle.strain ? cycle.strain.kilojoules * 0.239006 : 0
      this.maxCalOut = Math.max(day.caloriesOut, this.maxCalOut)
    })
  }
  
  render() {
    this.loadData()
    const data = Object.values(this.days)
    console.log(data)
    const dim = Math.min(window.innerWidth, window.innerHeight) - 30;
    return <div className='uiFitnessSummary'>
      <RadarChart
    data={data}
    margin={{ left: -50, right: 0, top: 50, left: 0 }}
      tickFormat={t => basicFormat(t)}
      startingAngle={0}
      domains={[
        {name: 'Strain', domain: [0, 21], getValue: d => d.strain},
        {name: 'Recovery', domain: [0, 100], tickFormat: t => `${basicFormat(t)}%`,  getValue: d => d.recovery},
        {name: 'Sleep', domain: [0, 100], tickFormat: t => `${basicFormat(t)}%`,  getValue: d => d.sleep},
      ]}
      width={dim}
      height={dim * 0.8}
      />
      </div>
  }
}
