import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import LogoSVGLogo from '../../../assets/Vector/Logo.svg';
import './index.css';

export class LBLogo extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='letsLogo'>
        <ReactSVG src={LogoSVGLogo}/>
      </div>
    );
  }
}
