import React, { Component } from 'react';
import { ReactSVG } from 'react-svg'
import {UISelectionList} from "../DeviceSetup";
import {UIPage} from "../Page";
import {UIProfileIcon} from "../ProfileIcon";
import {LBWorkoutCard} from "../WorkoutCard";
import {UIWorkoutSession} from "../WorkoutSession";
import { LBTodoCard } from '../TodoCard'
import ToDoListIcon from "../../assets/icons/ToDoList.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {UIInputField} from "../Home";
import {formatDate, formatStartEndTime, UIAppointment} from "../Appointment";
import {UIOKCancel} from "../OKCancel";
import Media from "../../assets/icons/Image.svg";
import Search from "../../assets/icons/Search.svg";
import Cross from "../../assets/icons/Cross.svg";
import StripeS from "../../assets/icons/StripeS.svg";
import Save from "../../assets/icons/Save.svg";
import Clock from "../../assets/icons/Clock.svg";
import Close from "../../assets/icons/Cross.svg";
import CreditCard from "../../assets/icons/CreditCard.svg";
import Plus from "../../assets/icons/Plus.svg";
import Trash from "../../assets/icons/Trash.svg";
import Cal from "../../assets/icons/Calender.svg";
import CalSml from "../../assets/icons/CalendarSml.svg";
import Profile from "../../assets/icons/Profile.svg";
import Edit from "../../assets/icons/ChatEdit.svg";
import ProfileSml from "../../assets/icons/ProfileSml.svg";
import Scale from "../../assets/icons/Scales.svg";
import Back from "../../assets/icons/Back.svg";
import Fwd from "../../assets/icons/Forward.svg";
import Update from "../../assets/icons/Update.svg";
import Refund from "../../assets/icons/Refund.svg";
import Camera from "../../assets/icons/VideoOn.svg";
import Check from "../../assets/icons/CheckMark.svg";
import UPC from "../../assets/icons/UPC_A.svg";
import Meal from '../../assets/icons/Food.svg'
import Schedule from "../../assets/icons/ScheduleClock.svg";
import {FDRadioButtons} from '../../Mobile/src/components/Button'
import {FDPage} from '../../Mobile/src/components/Page'
import {FDPopup} from '../../Mobile/src/components/Popup'
import {FDQRCodeInput} from '../../Mobile/src/components/QRCodeInput'
import {isIPad, isMobile, isSafari, isDesktop} from '../../Platform.js'
import moment from 'moment';
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import "./index.css";
import Fraction from 'fraction.js'
import {Elements} from '@stripe/react-stripe-js'
import {ElementsConsumer, CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import { parseBarcode } from '../../zebra.js'
import { resolveBrandLogo } from '../../E3.js'
import { getEmojiFromTodo } from '../TodoCard'

const debugLog = (...args) => {
  //console.log.apply(null, args)
}

const capitalize = x => x.length > 0 ? x[0].toUpperCase() + x.substring(1) : x

export const logoImage = food => {
  if (!food.brand_name) return food.photo && food.photo.thumb.indexOf('x-apple') < 0 ? food.photo.thumb : Meal
  return resolveBrandLogo(food.brand_name)
}

const _404s = {
}

export class FoodLogo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.updateSrc()
  }

  updateSrc = (isErr) => {
    let src
    if (isErr) {
    } else {
      const url = logoImage(this.props.food)
      if (!_404s[url]) {
        src = url
      }
    }
    debugLog("updateSrc:", this.state.src, "=>", src)
    this.setState({
      src: src,
      error: isErr
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.food !== this.props.food) {
      this.updateSrc()
    }
  }

  onError = () => {
    _404s[this.state.src] = true
    this.updateSrc(true)
  }

  render() {
    let src = this.state.src
    let className
    if (!src) {
      src = Meal
      className = 'uiMealDefaultLogo'
    }
    return <img className={className} src={src} onError={this.onError}/>
  }
}

export const getCalories = food => {
  let cal = food.nutrition ? food.nutrition.nf_calories: food.nf_calories
  return cal ?  Math.round(cal) + ' cal' : ''
}

const makeUpper = x => x.charAt(0).toUpperCase() + x.slice(1)
export const getFoodName = (food, short) => {
  if (short) return food.food_name
  let name = makeUpper(food.food_name)
  let serving = food.serving_unit ? food.serving_unit.toLowerCase() : ''
  switch (serving) {
    case 'fluid ounce':
      {
        serving = 'oz.'
      }
      break
  }
  if (serving.length > 20) {
    serving = serving.split(' ')[0]
  }
  let qty = food.serving_qty || ''
  const comps = ('' + qty).split('.')
  if (comps.length > 1) {
    qty = Number(qty).toFixed(1)
  }
  if (serving.length > 20) {
    console.warn("dropping serving size", qty + " " +serving)
    qty = ''
    serving = ''
  } else {
    qty = `${qty} ${serving}`
  }
  if (food.brand_name && food.brand_name != name) {
    name = food.brand_name + ' ' + name
  }
  return <span className='uiFoodWithBrandName'><span className='uiFoodBrandName'>{name}</span>&nbsp;{qty}</span>
}

export class UICreditCardInput extends Component {

  constructor(props) {
    super(props);
  }
  
  removePaymentMethod = () => {
    this.props.setPaymentMethod(null);
  }

  componentDidMount() {
  }
  
  render() {
    const paymentMethod = this.props.paymentMethod;
    if (paymentMethod) {
      return <div className='uiStripeClientConnectForm'>
        <div className='uiStripeConnectCardName'><p>{paymentMethod.card.brand} ending in {paymentMethod.card.last4}</p>
        {this.props.paymentStatus != 'succeeded' && <div className='uiScheduleAppointmentRemoveCard' onClick={this.removePaymentMethod}>
         <ReactSVG src={Cross}/>
         </div>}
      </div>
        </div>;
    }
    const CARD_OPTIONS = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: 'white',
          color: 'white',
          fontWeight: 500,
          fontSize: '14px',
          caretColor: 'white',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: 'white',
          },
          '::placeholder': {
            color: 'white'
          },
        },
        webkitAutoFill: {
          color: 'white',
          caretColor: 'white',
          backgroundColor: 'rgb(33, 161, 196)'
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#ffc7ee',
        },
      },
    };
    return <div className='uiStripeClientConnectForm'>
      <div className='uiStripeConnectFormCard'><CardElement options={CARD_OPTIONS}/></div>
      </div>        
  }

}


const guessMealFromStartTime = start => {
  return 'breakfast'
}


export class UIScheduleAppointment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      recentWorkouts: [],
      workoutTabSelection: 'workout',
      itemCount: 1,
      totalCalories: 0,
      homeMade: 'store',
      foodOptions: [],
      homeMadeOptions: [],
      paymentStatus: this.props.paymentStatus,
      paymentMethod: this.props.finalPaymentMethod,
      formError: {},
      searchForm: {
      },
      form:  {
        mealSelection: this.props.mealSelection || 'breakfast',
        foods: [],
        date: props.date,
        on: props.on,
        with: props.with,
        start: props.start,
        end: props.end,
        title: props.title,
        invoiceDescription: props.invoiceDescription,
        invoiceAmount: props.invoiceAmount,
        attendees: {},
        meal: guessMealFromStartTime(props.start),
        reps: 1,
        sets: 1,
        duration: 15,
        weight: props.scope == 'weight' ? 0 : 15,
        food: ''
      }
    }
    this.workouts = {}
    if (this.props.scope == 'workouts') {
      this.activityOptions = this.props.me.getWhoop().getSports().map(sport => {
        return {
          uid: sport.id,
          displayName: sport.name,
          profileImage: sport.iconUrl
        }
      })
    }
    if (this.props.appt) {
      if (this.props.appt.meal) {
        const meal = this.props.appt.meal
        this.state.form.foods = meal.foods
        this.state.form.mealSelection = meal.type
        this.computeCalories()
      }
      if (this.props.appt.workout) {
        this.state.form.sets = this.props.appt.workout.sets
        this.state.form.reps = this.props.appt.workout.reps
        this.state.form.duration = this.props.appt.workout.duration || 15
        if (this.props.appt.workout.weight) {
          this.state.form.weight = this.props.appt.workout.weight
        }
        this.state.form.activity = this.props.appt.workout.activity
        this.state.form.demo = this.props.appt.workout.demo
        this.state.demo = this.props.appt.workout.demo.length && this.props.appt.workout.demo[0]
        this.state.form.description = this.props.appt.workout.description
      }
    }
    debugLog("Form: ", this.state.form);
  }

  componentWillUnmount() {
    if (this.workoutsSub) {
      this.workoutsSub.unsubscribe()
    }
  }

  componentDidMount() {
    if (this.props.client) {
      ////debugger;
      if (!this.props.finalPaymentMethod) {
        this.props.me.getPaymentMethod().then(paymentMethod => {
          debugLog(paymentMethod);
          if (paymentMethod) {
            debugLog("got payment method: ", paymentMethod.id);
            this.setState({
              paymentMethod: paymentMethod,
            });
          }
        })
        if (this.props.paymentIntentId && !this.props.paymentStatus) {
          this.getPaymentIntent();
        }
      }
    } else {
      this.autofill();
    }
    if (this.props.appt && this.props.appt.weight) {
      const form = this.state.form
      form.weight = this.props.appt.weight.weight
      form.weight = Math.round(form.weight * 10) / 10
      this.forceUpdate()
    }
    if (this.props.scope == 'todo') {
      if (this.props.appt) {
        const item = this.props.appt.todo
        const todo = item.todo
        let task = todo.task
        if (!item.userEdit && item.todo.emojis) {
          task += ' '
          task += item.todo.emojis
        }
        let summary = capitalize(todo.summary)
        if (!item.userEdit && todo.emotion) {
          summary += ' ' +  getEmojiFromTodo(todo)
        }
        this.setState({
          form: {
            date: new Date(this.props.appt.start),
            task: task,
            benefits: todo.benefits,
            summary: summary,
            emotion: capitalize(todo.emotion),
            emojis: todo.emojis,
            location: todo.location == 'n/a' ? '' : capitalize(todo.location),
            term: todo.term,
            category: capitalize(todo.category),
            emojis: ''
          }
        })
      } else {
        this.setState({
          form: {
            date: new Date(),
            task: '',
            benefits: '',
            summary: '',
            emotion: '',
            emojis: '',
            location: '',
            term: 'short-term',
            category: ''
          }
        })
      }
    }
    if (this.props.scope == 'workouts') {
      const self = this
      //debugger
      this.workoutsSub = this.props.me.observeWorkoutsIScheduled().subscribe(change => {
        const workout = change.workout
        if (change.type == 'removed') {
          delete this.workouts[workout.id]
        } else {
          this.workouts[workout.id] = workout
        }
        this.updateScheduledWorkoutsLater()
      })
    }
  }

  updateScheduledWorkoutsLater = () => {
    clearTimeout(this.later)
    this.later = setTimeout(this.updateScheduledWorkoutsNow, 200)
  }

  updateScheduledWorkoutsNow = () => {
    let workouts = Object.values(this.workouts)
    const with_ = this.state.searchForm.with
    const searchTerm = this.state.recentSearchTerm
    const activity = this.state.searchForm.activity
    let searchTerms
    if (searchTerm) {
      searchTerms = searchTerm.toLowerCase().split(/\s+/);
    }
    const matches = {}
    workouts = workouts.filter(workout => {
      if (activity && workout.activity.uid !== activity.uid) {
        return false
      }
      if (with_ && workout.client !== with_.uid) {
        return false
      }
      if (searchTerm) {
        const appt = this.workoutToAppt(workout)
        let terms = appt.contact.displayName.toLowerCase().split(/\s+/);
        let timeTerms = formatStartEndTime(new Date(appt.start), new Date(appt.end)).split("-").map(x => x.trim()).join("").split(/\s+/);
        let dateTerms = formatDate(new Date(appt.start)).toLowerCase().split(/\s+/);
        let titleTerms = appt.title ? appt.title.split(/\s+/) : [];
        const allTerms = [terms, timeTerms, dateTerms, titleTerms];
        let matched = 0;
        allTerms.map(terms => {
          terms.map(term => {
            if (term) {
              term = term.toLowerCase();
              searchTerms.map(searchTerm => {
                if (searchTerm) {
                    if (term.startsWith(searchTerm) || searchTerm.startsWith(term)) {
                      matched++;
                    }
                }
              });
            }
          })
        });
        //debugLog("matched: ", matched, " in ", appt);
        if (matched == 0) return false;
        matches[workout.id] = matched;
        return true;
      }
      return true
    })
    workouts.sort((x, y) => {
      const w1 = matches[x.id] || 0
      const w2 = matches[y.id] || 0
      const cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      return y.start - x.start
    })
    this.setState({
      recentWorkouts: workouts
    })
  }

  onSearchTermChanged = e => {
    this.setState({
      recentSearchTerm: e.target.value
    }, this.updateScheduledWorkoutsNow)
  }

  onSearchIconClick = () => {
    this.setState({
      recentSearchTerm: ''
    })
  }

  renderSearchField = () => {
      return <div className='uiCalendarSearch'>
      <input placeholder={'Search'} value={this.state.recentSearchTerm}   onFocus={this.onSearchFocus} onBlur={this.onSearchBlur}
    onChange={this.onSearchTermChanged} ref={this.setSearchInput} className='uiCalendarSearchField'/>
      <div className='uiCalendarSearchIcon' onClick={this.onSearchIconClick}>
      <ReactSVG src={this.state.recentSearchTerm ? Cross: Search}/>
      </div>
      </div>
  }

  autoFillWorkout = workout => {
    debugLog(workout)
    this.state.form.description = workout.description
    this.state.form.activity = this.activityOptions.find(opt => opt.uid == workout.activity.uid)
    this.state.form.activityName = 
    this.state.form.reps = workout.reps
    this.state.form.set = workout.sets
    this.state.form.duration = workout.duration || 15
    this.state.form.weight = workout.weight
    const demo = workout.demo[0]
    this.state.form.demoFile = null
    this.state.demo = demo 
    this.state.form.demo = workout.demo
    debugger
    this.state.workoutTabSelection = 'workout'
    let mediaFragment = '#t=0.001'
    if (demo && demo.contentType.startsWith("video/")) {
      if (this.demoVideoRef) {
        this.demoVideoRef.src = this.state.demo.downloadURL + mediaFragment
      }
    }
    this.forceUpdate()
  }


  workoutToAppt = workout => {
    const contact = this.props.with
    return {
      organizer: contact,
      contact: workout.activity,
      title: workout.description + ((workout.activity.id == 35 || workout.activity.uid == 59) && workout.weight ? ' ' + workout.weight + ' lbs' : ''),
      client: this.props.me.self.uid,
      start: workout.start,
      end: workout.end,
      scheduled: workout.scheduled,
      id: workout.id,
      workout: workout,
      uid: workout.trainer,
      status: !workout.status || workout.status == 'started' ? 'todo' : workout.status
    }
  }


  renderScheduledWorkouts = () => {
    const workouts = this.state.recentWorkouts
    return workouts.map(workout => {
      const id = workout.id
      const channel = [workout.client, workout.trainer].sort().join('-')
      const appt = this.workoutToAppt(workout)
      const onClick = () => {
        this.autoFillWorkout(workout)
      }
      const start = new Date(appt.start)
      const end = new Date(appt.end)
      return <div className='uiScheduleAppointmentRecentWorkoutCard'>
        <LBWorkoutCard waitForSystemUpdate={this.props.waitForSystemUpdate} select={onClick} workout={appt.workout} me={this.props.me} inactive={true}/>
        </div>
    })
  }

  getPaymentIntent = () => {
    if (this.state.clientSecret) Promise.resolve(this.state.clientSecret);
    return this.props.me.getPaymentIntent(this.props.appointmentId).then(data => {
      debugLog("got payment intent: ", data);
      this.setState({
        clientSecret: data.id,
        paymentStatus: data.status,
        account: data.account,
      });
      return data;
    });
  }
  

  update = async () => {
    if (!this.props.paymentMethod) {
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      ////debugger;
      const cardElement = this.elements.getElement(CardElement);
      
      const {error, paymentMethod} = await this.stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        debugLog('[error]', error);
        this.props.setPaymentMethodError(error.message);
      } else {
        debugLog('[PaymentMethod]', paymentMethod);
        this.props.setPaymentMethod(paymentMethod);
      }
    }
  }

  createPaymentMethod = () =>{
    const cardElement = this.elements.getElement(CardElement);
    debugLog("got card element: ", cardElement);
    ////debugger;
    return this.stripe.createToken(cardElement, {currency: "usd"}).then(result => {
      ////debugger;
      if (result.error) {
        this.setState({
          error: result.error.message.replace("postal code", "zip code")
        });
      } else {
        debugLog("saving payment method: ", result);
        return this.props.me.savePaymentMethod(result.token.id).then(() => {
          debugLog("saved payment method: " , result);
          this.setState({
            paymentMethod: result.token
          });
          return result.token;
        });
        return result.token;
      }
    });
  }

  paymentAction = () => {
    this.setState({
      error: ""
    });
    if (this.props.client && this.state.form.invoiceAmount > 0) {
      if (this.state.paymentStatus != "succeeded") {
        window.showProgressIndicator("Saving Payment Method");
        let p = Promise.resolve(this.state.paymentMethod);
        if (!this.state.paymentMethod) {
          p = this.createPaymentMethod().then(paymentMethod => {
            ////debugger;
            this.setState({
              paymentMethod: paymentMethod,
            });
            return paymentMethod;
          });
        }
        return p.then(paymentMethod => {
          if (!paymentMethod) {
            window.hideProgressIndicator();
            return;
          }
          window.showProgressIndicator("Making Payment");
          return this.getPaymentIntent().then(data => {
            debugLog("got payment intent data: ", data);
            const clientSecret = data.id;
            let p = Promise.resolve();
            if (data.status == "requires_confirmation") {
              const stripe = window.Stripe(window.stripe_key, {stripeAccount: data.account});
              p = stripe.confirmCardPayment(clientSecret).then(result => {
                debugLog(result);
                if (result.error) {
                  this.setState({
                    error: result.error.message,
                  })
                } else {
                  this.setState({
                    paymentStatus: result.paymentIntent.status
                  });
                }
              }).catch(err => {
                ////debugger;
              });
            }
            return p.then(window.hideProgressIndicator);
          });
        });
      } else {
        return this.props.downloadInvoice();
      }
    }
  }

  onChangeSearch = (field, value) => {
    const e = this.state.formError;
    this.setState({error: "", formError: {}});
    if (this.state.searchForm[field] != value) {
      this.state.searchForm[field] = value;
      this.updateScheduledWorkoutsNow()
    }
  }

  onChange = (field, value) => {
    const e = this.state.formError;
    this.setState({error: "", formError: {}});
    if (this.state.form[field] != value) {
      debugLog("onChange: ", field, " => ", value);
      if (this.props.onChange) this.props.onChange(field, value);
      this.state.form[field] = value;
      this.forceUpdate();
      if (field == 'with' && value) {
        this.autofill();
      }
    }
  }

  getWith = () => {
    return this.props.with || this.state.form.with;
  }

  autofill = () => {
    if (!this.props.isNew) return;
    const value = this.getWith();
    if (value) {
      this.props.me.getAppointments(value).then(appts => {
        debugLog("got appointments: ", appts);
        let appt;
        if (appts.length > 0) {
          appt = appts[0];
        } else {
          appt = this.props.me.getAppointmentAutofill();
        }
        if (appt) {
          let update = false;
          if (appt.invoiceAmount) {
            this.onChange('invoiceAmount', appt.invoiceAmount);
          }
          if (appt.title && appt.title != "Video Conference" && this.state.form.title != appt.title) {
            this.onChange('title', appt.title);
          }
          if (appt.invoiceDescription) {
            this.onChange('invoiceDescription', appt.invoiceDescription);
          }
        }
      });
    }
  }

  searchContacts = searchTerm => this.searchImpl('contacts', searchTerm)
  searchActivities = searchTerm => this.searchImpl('workouts', searchTerm)

  searchImpl = (scope, searchValue) => {
    const searchTerms = searchValue.toLowerCase().split(/\s+/);
    const matches = {};
    const filter = x => {
      if (!searchValue) return true;
      let matched = 0;
      const name = x.displayName.toLowerCase();
      const terms = name.split(/\s+/);
      searchTerms.map(searchTerm => {
        terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
      });
      matches[x.uid] = matched;
      return matched > 0;
    }
    const sort = (x, y) => {
      const w1 = matches[x.uid] || 0;
      const w2 = matches[y.uid] || 0;
      const cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      return x.displayName ? y.displayName ? x.displayName.localeCompare(y.displayName) : 1 : -1;
    }
    let result
    if (scope == 'workouts') {
      result = this.activityOptions.filter(filter)
    } else {
      result = this.props.me.getCurrentContacts().filter(filter);
    }
    result.sort(sort);
    return result;
  }

  paymentRefund = () => {
    window.showProgressIndicator("Refunding Payment");
    this.props.me.refundAppointment(this.props.appointmentId).then(()=> {
      window.hideProgressIndicator()
      this.setState({
        paymentStatus: null
      });
    })
  }

  okAction = () => {

    if (!this.props.scope) {
      if (!this.state.form.with) {
        this.setState({
          error: "Who will you be meeting with?",
          formError: {field: 'with'}
        });
        return Promise.resolve();
      }
      if (!this.state.form.title) {
        this.setState({
          error: "Description is required",
          formError: {field: 'title'}
        });
        return Promise.resolve();
      }
    }
    if (this.props.scope !== 'weight' && this.state.form.end <= this.state.form.start) {
      this.setState({
        error: "End must be after Start",
        formError: {field: 'end'}
      });
      return Promise.resolve();
    }
    if (!this.props.scope) {
      if (this.state.form.invoiceAmount > 0) {
        if (this.state.form.invoiceAmount < 5) {
          this.setState({
            error: "Minimum invoice amount is $5",
            formError: {field: 'invoiceAmount'}
          });
          return Promise.resolve();
        }
        const with_ = this.getWith();
        if (this.props.me.self.uid == with_.uid) {
          this.setState({
            error: "Can't schedule a paid appointment with yourself",
            formError: {field: 'invoiceAmount'}
          });
          return Promise.resolve();
        }
      }
    }
    if (this.demoVideoRef && this.demoVideoRef.videoWidth) {
      if (this.demoVideoRef.audioTracks.length === 0) {
        this.setState({
          error: "Video must have an audio track",
          formError: {field: 'demo'}
        });
        return Promise.resolve();
      }
      if (false && this.demoVideoRef.duration < 10) {
        this.setState({
          error: "Video must be at least 10 seconds long",
          formError: {field: 'demo'}
        });
        return Promise.resolve();
      }
    }
    if (this.props.scope == 'meals' || (this.props.appt && this.props.appt.meal)) {
      if (this.state.form.foods.length == 0) {
        this.setState({
          error: "Add some foods!",
          formError: {field: 'food'}
        });
        return Promise.resolve()
      }
    }
    if (this.props.scope == 'workouts') {
      if (!this.state.form.activity) {
        this.setState({
          error: "Activity is required",
          formError: {field: 'activity'}
        });
        return Promise.resolve()
      }
      if (!this.state.form.description) {
        this.setState({
          error: "Description is required",
          formError: {field: 'description'}
        });
        return Promise.resolve()
      }
    }
    if (this.props.scope == 'weight') {
      if (!this.state.form.weight) {
        this.setState({
          error: "Weight is required",
          formError: {field: 'weight'}
        });
        return Promise.resolve()
      }
    }
    if (this.props.scope == 'todo') {
      if (!this.state.form.task) {
        this.setState({
          error: "Task is required",
          formError: {field: 'task'}
        });
        return Promise.resolve()
      }
      if (!this.state.form.category) {
        this.setState({
          error: "Category is required",
          formError: {field: 'category'}
        });
        return Promise.resolve()
      }
    }
    this.setState({
      error: "",
      formError: {}
    });
    let p = Promise.resolve();
    if (this.props.client) {
      if (this.state.form.invoiceAmount > 0) {
        if (this.state.paymentStatus != 'succeeded') {
        }
      }
    }
    return p.then(() => {
      return this.props.schedule(this.state.form)
    });
  }

  renderPaymentMethod = () => {
    return <UICreditCardInput
    me={this.props.me}
    paymentStatus={this.state.paymentStatus}
    paymentMethod={this.state.paymentMethod}
    setPaymentMethod={paymentMethod => {
      this.setState({paymentMethod: paymentMethod});
    }}
    set={x=>this.creditCardPayment=x}/>
  }

  render() {
    return <Elements stripe={window.stripePromise}>
      <ElementsConsumer>
      {({elements, stripe}) => {
        ////debugger;
        // stripe wtfs
        this.elements = elements;
        this.stripe = stripe;
        return this.renderSelf();
      }}
    </ElementsConsumer>
      </Elements>
  }

  showMembers = () => {
    this.setState({
      showMembers: true
    });
    return Promise.resolve();
  }

  hideMembers = () => {
    if (this.state.attendeeDetails) {
      this.setState({
        attendeeDetails: null
      });
    } else {
      this.setState({
        showMembers: false
      });
    }
    return Promise.resolve();
  }

  renderAttendeeFields(form) {
    let needsStripeConnect = !this.props.me.stripeAuth;
    let invoiceAmountErr;
    let stripeLabel = "Enable Payments with Stripe";
    const onChange= (field, value) => {
      const e = this.state.formError;
      this.setState({error: "", formError: {}});
      if (form[field] != value) {
        debugLog("onChange: ", field, " => ", value);
        //this.props.onChange(field, value);
        form[field] = value;
        this.forceUpdate();
      }
    }
    if (!needsStripeConnect) {
      const account = this.props.me.accountData.account;
      if (account && account.requirements && (account.requirements.disabled_reason ||
                                              (account.requirements.errors && account.requirements.errors.length > 0 ))) {
        stripeLabel = "Fix Payment Setup Errors";
        needsStripeConnect = true;
        invoiceAmountErr = {
          field: "invoiceAmount",
        }
      }
    }
    return <div className='uiScheduleAppointmentBodyInvoiceFields'>
      <div className='uiScheduleAppointmentBodyField'>
      <UIInputField label={'Invoice Description'} placeholder={form.title} value={form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={onChange} type='text'/>
      </div>
      <div className='uiScheduleAppointmentBodyField'>
      {!needsStripeConnect ?
       <UIInputField error={this.state.formError} label={'Invoice Amount'} value={form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={onChange} type='currency'/> :
       <UIInputField label={'Invoice Amount'} error={invoiceAmountErr} value={stripeLabel} readOnly={true} icon={StripeS} name='invoiceAmount' onChange={this.onChange} onClick={this.props.me.stripeConnect} type='button'/>} 
    </div>
      </div>
  }

  renderContact(attendee) {
    if (!attendee) return null;
    return <div className='uiScheduleAppointmentBodyWith'>
      <div className='uiScheduleAppointmentBodyWithIcon'>
      <UIProfileIcon radius={22.5} contact={attendee}/>
      </div>

      <div className='uiScheduleAppointmentBodyWithName'>
      {attendee.displayName}
    </div>
      <div className='uiScheduleAppointmentBodyWithCreds'>{attendee.creds}</div>
      </div>
  }

  renderAttendeeDetails() {
    let attendee = this.state.attendeeDetails;
    let form = this.state.form.attendees[attendee.uid];
    if (!form) {
      form = this.state.form.attendees[attendee.uid] = {};
      form.invoiceDescription = this.state.form.invoiceDescription;
      form.invoiceAmount = this.state.form.invoiceAmount;
      form.title = this.state.form.title;
    }
    return <div className='uiScheduleAppointmentAttendeeDetails'>
      <div className='uiScheduleAppointmentBody'>
      {this.renderContact(attendee)}
    {this.renderAttendeeFields(form)}
    </div>
      </div>
  }

  

  renderOrganizerPage2() {
    const group = this.props.with.group;
    const getSubpage = () => {
      let subPage;
      debugLog("attendeeDetails: ", this.state.attendeeDetails);
      if (this.state.attendeeDetails) {
        subPage = this.renderAttendeeDetails();
      }
      debugLog("subPage: ", subPage);
      return subPage;
    }
    return <div className='uiScheduleAppointmentPage2'>
      <UIPage hideHeader={true} subPage={getSubpage()}>
      <div className='uiScheduleAppointmentAttendeesListExpanded'>
      Attendees
    </div>
      <div className='uiScheduleAppointmentAttendeesExpanded'>
      {group.members.filter(x => x != group.organizer).map(member => {
        const c = this.props.me.getContact(member);
        const f = ()=> {
          this.setState({
            attendeeDetails: c
          });
        }
        return <div className='uiScheduleAppointmentAttendee' onClick={f}>
          <div className='uiScheduleAppointmentAttendeeIcon'><UIProfileIcon contact={c}/></div>
          <div className='uiScheduleAppointmentAttendeeName'>{c.displayName}</div>
          <div className='uiScheduleAppointmentAttendeeExpand'><ReactSVG src={Fwd}/></div>
          </div>
      })}
    </div>
      </UIPage>
      </div>
  }

  getDuration = async file => {
    const url = URL.createObjectURL(file)
    const vid = document.createElement('video')
    vid.crossOrigin = "anonymous"
    vid.src = url
    return await new Promise(resolve => {
      vid.onloadedmetadata = () => {
        resolve(vid.duration)
      }
    })
  }

  validateDuration = async (field, file) => {
    const dur = await this.getDuration(file)
    debugLog('video duration', dur)
    if (dur < 10) {
      this.state.error = "Duration must be at least 10 seconds"
      this.state.formError = {
        field: field,
        error: this.state.error
      }
      this.forceUpdate()
    }
  }

  onChangeDemo = (field, files) => {
    let file = files[0]
    if (file) {
      debugLog(file)
      const url = URL.createObjectURL(file)
      this.state.demo = {
        downloadURL: url,
        contentType: file.type
      }
      this.state.form.demoFile = file
      this.state.form.demo = null
      if (this.state.formError.field == 'demo') {
        this.state.error = {}
        this.state.formError = {}
      }
      if (this.demoVideoRef) {
        this.demoVideoRef.src = url
      }
      this.forceUpdate()
    }
  }

  onChangeField = (name, value) => {
    this.state.form[name] = value
    if (this.state.formError.field == name) {
      this.state.error = ''
      this.state.formError = {}
    }
    this.forceUpdate()
  }

  onChangeFood = (name, value) => {
    this.state.form.food = value
    if (this.state.formError.field == 'food') {
      this.state.error = ''
      this.state.formError = {}
    }
    this.state.showFoodAutocomplete = true
    this.autocompleteFoodsLater()
    this.forceUpdate()
  }

  call = 0

  autocompleteFoodsLater = () => {
    clearTimeout(this.autocompleteTimeout)
    this.autocompleteTimeout = setTimeout(this.autocompleteFoods, 500)
  }

  handleBarcode = async barcode => {
    if (barcode.toUPCA) barcode = barcode.toUPCA()
    const { code } = barcode
    try {
      const response = await this.props.me.resolveBarcode(code)
      const { foods } = response
      if (foods) {
        const [food] = foods
        this.selectFood(food)
        return food
      }
    } catch (err) {
      console.error(err)
    }
    if (this.state.form.code !== code) {
      this.state.form.food = code
      this.setState({
        error: "No food found with that barcode",
        formError: {
          field: 'food'
        }
      })
    }
  }

  autocompleteFoods = async () => {
    const thisCall = ++this.call
    if (Number.isInteger(this.state.form.food)) {
      try {
        debugLog(this.state.form.food)
        let barcode = parseBarcode(this.state.form.food)
        debugLog("barcode: ", barcode)
        if (barcode) {
          this.handleBarcode(barcode)
          return
        }
      } catch (err) {
        console.error(err)
      }
    }
    if (!this.state.form.food && this.state.homeMade != 'recent') {
      return this.setState({
        foodOptions: [],
        homeMadeOptions: []
      })
    }
    const response = await this.props.me.autocompleteFood(this.state.form.food, this.state.homeMade == 'recent', this.state.mealSelection)
    debugLog("autocomplete:", response)
    const { results } = response
    if (this.call !== thisCall) return
    const options1 = results.branded
    const options2 = results.common
    this.setState({
      foodOptions: options1,
      homeMadeOptions: options2
    })
  }

  deleteFood = food => {
    this.state.form.foods = this.state.form.foods.filter(x => x !== food)
    this.computeCalories()
    this.forceUpdate()
  }

  selectMeal = button => {
    this.state.form.mealSelection = button.selector
    this.forceUpdate()
  }

  hideFoodAutocomplete = () => {
    this.setState({showFoodAutocomplete: false})
  }

  selectHomeMade = button => {
    if (this.state.homeMade == button.selector) {
      this.setState({
        showFoodAutocomplete: !this.state.showFoodAutocomplete
      })
      return
    }
    if (button.selector == 'recent') {
      this.call++
      this.setState({
        homeMade: button.selector,
        showFoodAutocomplete: true
      }, this.autocompleteFoods)
    } else {
      this.setState({
        homeMade: button.selector,
        showFoodAutocomplete: true,
      }, this.state.homeMade == 'recent' ? this.autocompleteFoods: null)
    }
  }

  homeMadeButtons = [
    {
      selector: 'store',
      label: 'Brand Name'
    },
    {
      selector: 'home-made',
      label: "Common"
    },
    {
      selector: 'recent',
      label: 'Recent'
    },
  ]
  
  
  mealOptions = [
    {
      selector: 'breakfast',
      label: 'Breakfast',
    },
    {
      selector: 'lunch',
      label: 'Lunch',
    },
    {
      selector: 'dinner',
      label: 'Dinner',
    },
    {
      selector: 'snack',
      label: 'Snack',
    }]

  onChangeHomeMade = e => {
    this.setState({
      homeMade: e.target.checked
    })
  }

  computeCalories = () => {
    let total = 0
    this.state.form.foods.forEach(food => {
      let count = food.count === undefined ? 1 : food.count
      if (food.nutrition && food.nutrition.nf_calories) {
        total += Math.round(food.nutrition.nf_calories * count)
      } else if (food.nf_calories) {
        total += Math.round(food.nf_calories * count)
      }
    })
    this.state.totalCalories = total;
  }

  selectFood = async (food) => {
    debugLog(food)
    this.state.form.foods.push(food)
    this.state.showFoodAutocomplete = false
    this.computeCalories()
    this.forceUpdate()
    if (food.full_nutrients) {
      if (!food.nutrition) food.nutrition = JSON.parse(JSON.stringify(food))
    } else {
      let result
      if (food.nix_item_id) {
        result = await this.props.me.getFoodNutrition(food.nix_item_id)
      } else {
        result = await this.props.me.resolveFoods(food.food_name)
      }
      food.nutrition = result.foods[0]
    }
    this.computeCalories()
    this.forceUpdate()
  }

  startSelectingActivity = () => {
    this.setState({
      selectingActivity: true
    })
  }


  selectActivity = value => {
    this.state.form.activity = value
    this.state.activityName = this.activityOptions.find(opt => opt.value == value).name
    this.state.selectingActivity = false
    this.forceUpdate()
  }

  onChangeActivityName = (name, value) => {
    this.state.activityName = value
    this.forceUpdate()
  }

  getActivityIcon = () => {
    return this.props.me.getWhoop().getActivityIcon()
  }

  setDemoVideoRef = ref => {
    this.demoVideoRef = ref
  }

  renderTodoOutcome() {
    const todo = this.props.appt.todo
    return <div className='uiScheduleAppointmentTodoOutcome'>
      <div className='uiScheduleAppointmentBodyFields uiScheduleAppointmentBodyFieldsTodo'>
      <div className='uiScheduleAppointmentBodyField'>
      <LBTodoCard waitForSystemUpdate={this.props.waitForSystemUpdate} edit={null} todo={todo} me={this.props.me} inactive={true}/>
      </div>
      </div>
      </div>
  }
  
  renderTodo() {
    const onChange = (field, value) => {
      this.state.form[field] = value
      this.forceUpdate()
    }
    const onChangeTask = (e) => {
      onChange('task', e.target.value)
    }
    const selectTerm = b => {
      this.state.form.term = b.selector
      this.forceUpdate()
    }
    const terms =
          [{
            selector: 'short-term',
            label: 'Short-term'
          },
           {
            selector: 'long-term',
            label: 'Long-term'
           }]
    return <div className='uiScheduleAppointmentTodo'>
      <div className='uiScheduleAppointmentBodyFields uiScheduleAppointmentBodyFieldsTodo'>
      {false && <div className='uiScheduleAppointmentBodyField uiBodyFieldDateWhen'>
        <UIInputField label={'When'} value={this.state.form.date} icon={Cal} name='date' onChange={onChange} type='date'/>
       </div>}
      <div className='uiScheduleAppointmentBodyField'>
      <div className='uiInputFieldLabel uiScheduleAppointmentTodoTaskLabel'>Task</div>
      <div className='lbTodoCardProgressNotes uiScheduleAppointmentTodoTask'>
      <textarea value={this.state.form.task} placeholder={'Description'} onChange={onChangeTask}/>
      <div className='uiScheduleAppointmentTodoIcon'><ReactSVG src={ToDoListIcon}/></div>
        </div>
      </div>
      {<div className='uiScheduleAppointmentBodyField'>
       <UIInputField autoComplete={'off'} label={'Reason'} placeholder={''} type='text' error={this.state.formError} value={this.state.form.summary} name='summary' icon={Edit} onChange={onChange}/>
       </div>}
      <div className='uiScheduleAppointmentBodyField'>
      <UIInputField label={'Category'} placeholder={''} error={this.state.formError} value={this.state.form.category} icon={Edit} name='category' icon={Edit} onChange={onChange} />
      </div>
      <div className='uiScheduleAppointmentBodyField'>
      <UIInputField label={'Location'} placeholder={''} error={this.state.formError} value={this.state.form.location} icon={Edit} name='location' icon={Edit} onChange={onChange}/>
      </div>
      <div className='uiScheduleAppointmentBodyField'>
      <div className='uiInputFieldLabel uiScheduleAppointmentTodoTaskLabel'>Duration</div>
      <FDRadioButtons buttons={terms} selection={this.state.form.term} select={selectTerm}/>
      {false && <UIInputField label={'Duration'} placeholder={''} error={this.state.formError} value={this.state.form.term} icon={Edit} name='term' search={this.searchActivities} icon={Edit} onChange={onChange}/>}
       </div>
      <div className='uiScheduleAppointmentBodyField'>
      { false && <UIInputField label={'Motivation'} placeholder={''} error={this.state.formError} value={this.state.form.emotion} icon={Edit} name='emotion' search={this.searchActivities} icon={Edit} onChange={onChange}/>}
      </div>
      </div>
      </div>
}

  renderScheduleWorkout() {
    let activityOptions = this.activityOptions
    if (this.state.activityName) {
      const searchTerms = this.state.activityName.toLowerCase().split(/\s+/)
      const matches = {}
      activityOptions = this.activityOptions.filter(opt => {
        if (!opt.name) {
          return false
        }
        const terms = opt.name.toLowerCase().split(/\s+/)
        let matched = 0
        terms.forEach(term => {
          searchTerms.forEach(searchTerm => {
            if (searchTerm.startsWith(term) || term.startsWith(searchTerm)) {
              matched++
            }
          })
        })
        if (matched == 0) return false
        matches[opt.value] = matched
        return true
      })
      activityOptions.sort((a, b) => {
        const w1 = matches[a.value];
        const w2 = matches[b.value];
        const cmp1 = w2-w1;
        if (cmp1 !== 0) {
          return cmp1;
        }
        return a.name.localeCompare(b.name)
      })
    }
    let usesWeights = false
    debugLog("activity:", this.state.form.activity)
    if (this.state.form.activity) {
      switch (this.state.form.activity.uid) {
        case 45:
        case 59:
          usesWeights = true
          break
      }
    }
    const onChangeInstructions = e => {
      this.state.form.instructions = e.target.value
      this.forceUpdate()
    }
    const selectDemo = button => {
      this.setState({
        showDemo: button.selector === 'demonstration'
      })
    }
    const buttons = [
      { selector: 'description', label: 'Instructions' },
      { selector: 'demonstration', label: 'Demonstration' }
    ]

    let demo
    let contentType 
    if (this.state.form.demoFile) {
      contentType = this.state.form.demoFile.type
    } else if (this.state.demo) {
      contentType = this.state.demo.contentType
    }
    console.log("contentType", contentType)
    console.log("demo", this.state.demo)
    if (contentType) {
      if (contentType.startsWith("video/")) {
        let mediaFragment = '#t=0.001'
        let autoplay = false
        if (true || isSafari()) {
          if (this.state.demo.downloadURL.startsWith("blob")) {
            autoplay = true
            mediaFragment = ''
          }
        }
        demo = <video ref={this.setDemoVideoRef} controls muted autoPlay={autoplay} playsInline src={this.state.demo.downloadURL + mediaFragment}/>
      } else {
        demo = <img crossOrigin='anonymous' src={this.state.demo.downloadURL}/>
      }
    }

    if (!demo) {
      demo = <div className='uiScheduleAppointmentDemoPlaceholder'/>
    }

    let onKeyDown
    let onPaste

    onPaste = e => {
      if (e.clipboardData.files && e.clipboardData.files.length > 0) {
        this.onChangeDemo(e.clipboardData.files)
      }
    }

    onKeyDown = e => e.preventDefault()
    
    const fields = <div className='uiScheduleAppointmentBodyFields'>
      <div className='uiScheduleAppointmentBodyField uiScheduleAppointmentActivity'>
      <UIInputField defaultValue={{displayName: '', uid: 'empty', profileImage: this.getActivityIcon()}} label={'Activity'} placeholder={''} error={this.state.formError} value={this.state.form.activity} icon={Edit} name='activity' search={this.searchActivities} icon={Check} onChange={this.onChange} type='contact'/>
          </div>
      <div className='uiScheduleAppointmentBodyField'>
      <UIInputField label={'Description'} placeholder={''} error={this.state.formError} value={this.state.form.description} icon={Edit} name='description' onChange={this.onChange} type=''/>
     </div>
      <div className='uiScheduleAppointmentBodyFieldSelectDemoContainer'>
      <div className='uiScheduleAppointmentBodyFieldSelectDemo'>
      <FDRadioButtons buttons={buttons} selection={this.state.showDemo ? 'demonstration' : 'description'} select={selectDemo}/>
      </div>
      <div className='uiScheduleAppointmentBodyField uiScheduleAppointmentBodyFieldWorkoutDescription' style={!this.state.showDemo ? null : { display: 'none'}}>
      <textarea value={this.state.form.instructions} placeholder={'Instructions'} onChange={onChangeInstructions}/>
      </div>
      <div className='uiScheduleAppointmentBodyField uiScheduleAppointmentDemonstration' style={this.state.showDemo ? null : { display: 'none'} }>
      <UIInputField placeholder={''} error={this.state.formError} icon={Media} name='demo' input={'Choose Media'} onChange={this.onChangeDemo} type='file' onCreateRef={ref=>this.demoRef=ref} accept='video/*,image/*'/>
      <div className='uiScheduleAppointmentBodyFieldVideo' style={this.state.showDemo ? null : { display: 'none'}} onClick={(e)=> this.setState({rightClick: true})}>
      {demo}
       </div>
      </div>
      </div>
    {false && <div ref={this.setDemoMediaRef} className='uiScheduleAppointmentBodyFieldMedia' style={this.state.showDemo ? null:  { display: 'none'}} contentEditable onKeyDown={onKeyDown} onPaste={onPaste}/>}
      <div className='uiScheduleAppointmentBodyField uiScheduleAppointmentBodyFieldSetsReps'>
      <div className='uiScheduleAppointmentBodyFieldRow'>
      <UIInputField label={'Sets'} placeholder={''} error={this.state.formError} value={this.state.form.sets} icon={Edit} name='sets' onChange={this.onChangeField} type='number'/>
      <div className='uiScheduleAppointmentBodyRowSeparator'/>
      <UIInputField label={'Reps'} placeholder={''} error={this.state.formError} value={this.state.form.reps} icon={Edit} name='reps' onChange={this.onChangeField} type='number'/>
      <div className='uiScheduleAppointmentBodyRowSeparator'/>
      {!usesWeights && <UIInputField label={'Minutes'} placeholder={''} error={this.state.formError} value={this.state.form.duration} icon={Clock} name='duration' onChange={this.onChangeField} type='number'/>}
      {this.state.form.activity && usesWeights &&<div className='uiScheduleAppointmentBodyRowSeparator'/>}
    {this.state.form.activity && usesWeights && <UIInputField label={'Weight'} placeholder={''} error={this.state.formError} value={this.state.form.weight} icon={Edit} name='weight' onChange={this.onChangeField} type='number'/>}

    </div>
      {false && <div className='uiScheduleAppointmentBodyField'>
       <UIInputField label={'Notes'} placeholder={''} error={this.state.formError} value={this.state.form.notes} icon={Edit} name='notes' onChange={this.onChangeField} type='text'/>
       </div>}
    </div>
      </div>
      return <div key='recent' className='uiScheduleAppointmentWorkoutOrRecent'>
      <div className='uiScheduleAppointmentWorkoutOrRecentTabs'>
      <FDRadioButtons buttons={this.workoutTabs} selection={this.state.workoutTabSelection} select={this.selectWorkoutTab}/>
      </div>
      <div key='workoutOption' className='uiScheduleAppointmentWorkoutOption' style={this.state.workoutTabSelection == 'workout' ? null : { display: 'none' }}>
      {fields}
      </div>
      <div key="recentOption" className='uiScheduleAppointmentWorkoutOption' style={this.state.workoutTabSelection == 'recent' ? null : { display: 'none' }}>
      <div className='uiScheduleAppointmentWorkoutRecentList'>
      {this.renderSearchField()}
      <div key="activity" className='uiScheduleAppointmentBodyField uiScheduleAppointmentActivity uiScheduleAppointmentActivityFilterField'>
      <UIInputField defaultValue={{displayName: '', uid: 'empty', profileImage: this.getActivityIcon()}} label={'Activity'} placeholder={''} error={this.state.formError} value={this.state.searchForm.activity} icon={Edit} name='activity' search={this.searchActivities} icon={Edit} onChange={this.onChangeSearch} type='contact'/>
      </div>
      <div key="client" className='uiScheduleAppointmentBodyField uiScheduleAppointmentWithFilterField'>
       <UIInputField label={'Client'} search={this.searchContacts} value={this.state.searchForm.with} error={this.state.formError} icon={ProfileSml} name='with' onChange={this.onChangeSearch} type='contact'/>
      </div>
      <div className='uiScheduleAppointmentWorkoutRecentListTitle'>Activities</div>
      <div className='uiScheduleAppointmentWorkoutRecentListWorkouts'>
      {this.renderScheduledWorkouts()}
    </div>
      </div>
      </div>
      </div>
  }

  selectWorkoutTab = button => {
    this.setState({
      workoutTabSelection: button.selector,
      error: "",
      formError: {}      
    })
  }

  workoutTabs = [
    {
      selector: 'workout',
      label: 'Activity',
    },
    {
      selector: 'recent',
      label: 'Recent'
    }
  ]

  renderWorkoutSession = () => {
    let client = this.props.with
    if (this.props.appt) { 
      client = this.props.me.getContact(this.props.appt.client)
    }
    return <UIWorkoutSession workout={this.props.appt && this.props.appt.workout} me={this.props.me} openPopup={this.props.openPopup} client={client} close={this.props.back}/>
  }


  renderAddWeight = () => {
      return <div className='uiScheduleAppointmentBodyFields uiScheduleAppointmentBodyFieldsWeight'>


        <div key='when' className='uiScheduleAppointmentBodyField uiBodyFieldDateWhen'>
        <UIInputField label={'When'} value={this.state.form.date} icon={CalSml} name='date' onChange={this.onChange} type='date'/>
        </div>
        
      <div key='time' className='uiScheduleAppointmentBodyField'>
      <UIInputField key='startsEnds' label={'Time'} error={this.state.formError} value={this.state.form.start} icon={Clock} name='start' onChange={this.onChange} type={'time'}/>
      </div>
      <div key='weight' className='uiScheduleAppointmentBodyField uiScheduleAppointmentBodyFieldWeight'>
      <UIInputField key='weight' label={'Weight'} error={this.state.formError} value={this.state.form.weight} icon={Scale} name='weight' onChange={this.onChange} type={'number'}/>
      </div>
      </div>
  }

  renderOrganizerPage1() {
    if (this.props.scope == 'workouts') {
      return this.renderScheduleWorkout()
    }
    if (this.props.scope == 'weight') {
      return this.renderAddWeight()
    }
    if (this.props.todoOutcome) {
      return this.renderTodoOutcome()
    }
    if (this.props.scope == 'todo') {
      return this.renderTodo()
    }
    if (this.props.scope == 'meals' || (this.props.appt && this.props.appt.meal)) {
      let onKeyDown
      if (isDesktop()) {
        onKeyDown = e => {
          if (e.keyCode === 27) {
            this.setState({
              showFoodAutoComplete: false
            })
          }
        }
      }
      let maxFoodCount = 0
      this.state.form.foods.forEach((food, i) => {
        maxFoodCount = Math.max(maxFoodCount, Math.floor(food.count || 0))
      })
      return <div className='uiScheduleAppointmentBodyFields uiScheduleAppointmentBodyFieldsMeals'>


        <div className='uiScheduleAppointmentBodyField uiBodyFieldDateWhen'>
        <UIInputField label={'When'} value={this.state.form.date} icon={CalSml} name='date' onChange={this.onChange} type='date'/>
        </div>
        
        <div key='startsEnds' className='uiScheduleAppointmentBodyField'>
        <UIInputField key='startsEnds' label={{start: 'Start', end: 'End'}} error={this.state.formError} value={
          {start: this.state.form.start, end: this.state.form.end}} icon={Clock} name='start-end' onChange={(name, value) => {
            const {start, end} = value;
            if (start) {
              this.onChange('start', start);
            }
            if (end) {
              this.onChange('end', end);
            }
          }} type='time-duration'/>
        </div>

        <ClickAwayListener onClickAway={this.hideFoodAutocomplete}>
        <div className='uiScheduleAppointmentFoodSelection'>
        <div className='uiScheduleAppointmentBodyField uiScheduleAppointmentFoodSearch'>
        <UIInputField label={'Foods'} placeholder={'Search Foods'} onKeyDown={onKeyDown} error={this.state.formError} value={this.state.form.food} icon={Search} name='food' onChange={this.onChangeFood} type='text'/>
        {!isDesktop() && !this.state.form.food && <div className='uiScheduleAppointmentUPC' onClick={this.onScanUPC}>
        <ReactSVG src={UPC}/>
         </div>}
        </div>
        <div className='uiScheduleAppointmentBodyField'>
        <FDRadioButtons buttons={this.homeMadeButtons} select={this.selectHomeMade} selection={this.state.homeMade}/>
        </div>
        <div className='uiBodyFieldAutocomplete'>
        <div className='uiBodyFieldAutocompleteResults' style={this.state.showFoodAutocomplete ? null: { display: 'none' }}>
        {
          this.state.homeMade == 'home-made'
            ?
            this.state.homeMadeOptions.map(result => {
              const name = getFoodName(result)
              const cal = getCalories(result)
              return <div className='uiBodyFieldAutocompleteResult uiBodyFieldAutocompleteResultHomeMade' onClick={() => this.selectFood(result)}>
                <div className='uiBodyAutocompleteResultImage'><FoodLogo food={result}/></div>
                <div className='uiBodyAutocompleteResultName'>{name}</div>
                <div className='uiBodyAutocompleteResultCal'>{cal}</div>
                </div>
            })
            :
            this.state.foodOptions.map(result => {
              const name = getFoodName(result)
              const cal = getCalories(result)
              return <div className='uiBodyFieldAutocompleteResult' onClick={() => this.selectFood(result)}>
                <div className='uiBodyAutocompleteResultImage'><FoodLogo food={result}/></div>
                <div className='uiBodyAutocompleteResultName'>{name}</div>
                <div className='uiBodyAutocompleteResultCal'>{cal}</div>
                </div>
            })
        }
      </div>
        </div>
        </div>
        </ClickAwayListener>
        <div className='uiScheduleAppointmentMealFoods'>
        <div className='uiScheduleAppointmentMealFoodsList'>
        {
          this.state.form.foods.map((food, i) => {
            const name = getFoodName(food)
            const onChangeCount = e => {
              const n = e.target.value
              let foodCount = food.count === undefined ? 1 : food.count
              const whole = Math.floor(foodCount)
              let foodCountFraction = foodCount - whole
              foodCount = Number(n)
              food.count = foodCount + foodCountFraction
              this.computeCalories()
              this.forceUpdate()
            }
            const onChangeFraction = e => {
              const n = e.target.value
              let foodCount = food.count === undefined ? 1 : food.count
              const whole = Math.floor(foodCount)
              let fraction = eval(n)
              food.count = whole + fraction
              this.computeCalories()
              this.forceUpdate()
            }
            const editFood = e => {
              this.setState({
                editFood: food
              })
            }
            const foodCount = food.count === undefined ? 1 : food.count
            const whole = Math.floor(foodCount)
            let fraction = foodCount - whole
            let fractionValue = '0/0'
            if (fraction) {
              fractionValue = new Fraction(fraction).toFraction()              
              fraction = eval(fractionValue)
            }
            let calories = Math.round((food.nutrition ? food.nutrition.nf_calories : food.nf_calories) * foodCount)
            if (isNaN(calories)) {
              calories = '-'
            }
            debugLog("fraction", fraction)
            return <div className='uiBodyFood'>
              <div className={'uiBodyFoodCount' + (maxFoodCount >= 10 ? ' uiBodyFoodCountDoubleDigits' : '')}>
              <div className={'uiBodyFoodCountWhole'}>
              <label>{whole}
              <select onFocus={e=>e.target.click()}  value={whole} onChange={onChangeCount}>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(n => {
                return <option value={n}>{n}</option>
              })
              }
            </select>
              </label>
              </div>
              <div className='uiBodyFoodCountFraction'>
              <label>{fractionValue}
              <select value={fraction} onChange={onChangeFraction}>
              <option value={0}>0/0</option>
              {['1/16', '1/8', '1/6', '3/16', '1/4', '5/16', '1/3', '3/8', '7/16', '1/2', '9/16', '5/8', '2/3', '11/16', '3/4', '13/16', '5/6', '7/8', '15/16'].map(n => {
                return <option value={eval(n)}>{n}</option>
              })
              }
            }
          </select>
           </label>                                    
            </div>                                    
           </div>
         <div className='uiBodyFoodNameAndImage' onClick={editFood}>
              <FoodLogo food={food}/><div className='uiBodyFoodName'>{name}</div></div><div className='uiBodyFoodCal'>{calories !== undefined ? `${calories} cal` : ''}</div>
              <div className='uiBodyFoodDelete' onClick={()=>this.deleteFood(food)}>
              <ReactSVG src={Close}/>
              </div>
              </div>
          })
    }
        </div>
        <div className='uiScheduleAppointmentMealTotalCalories'>
        <div className='uiScheduleAppointmentMealTotalCaloriesLabel'>Total</div>
        <div className='uiScheduleAppointmentMealTotalCaloriesCalories'>{this.state.totalCalories} cal</div>
        </div>
        </div>
        <div className='uiScheduleAppointmentBodyField uiScheduleAppointmentBodyFieldMealSelection'>
        <FDRadioButtons buttons={this.mealOptions} select={this.selectMeal} selection={this.state.form.mealSelection}/>
        </div>
        </div>
    }
    let showInvoice;
    let isGroup = this.props.with && this.props.with.isGroup;
    let needsStripeConnect = !this.props.me.stripeAuth;
    let stripeLabel = "Enable Payments with Stripe";
    let invoiceAmountErr;
    if (!needsStripeConnect) {
      const account = this.props.me.accountData.account;
      if (account && account.requirements && (account.requirements.disabled_reason ||
                                              (account.requirements.errors && account.requirements.errors.length > 0 ))) {
        stripeLabel = "Fix Payment Setup Errors";
        needsStripeConnect = true;
        invoiceAmountErr = {
          field: "invoiceAmount",
        }
      }
    }
    let attendeeFields;
    if (isGroup) {
      attendeeFields = <div className='uiScheduleAppointmentAttendeesList'>
        <div className='uiScheduleAppointmentBodyField'>
        <UIInputField label={'Attendees'} error={invoiceAmountErr} value={"You and " +(this.props.with.group.members.length-1) + " others"} readOnly={true} icon={Fwd} name='attendees' onChange={this.onChange} onClick={this.showMembers} type='button'/>
        </div>
        </div>;
    } else {
      attendeeFields = this.renderAttendeeFields(this.state.form);
    }
    return <div className='uiScheduleAppointmentBodyFields'>
      {this.props.isNew && !this.props.withReadOnly ? <div className='uiScheduleAppointmentBodyField'>
       <UIInputField label={'Meeting'} search={this.searchContacts} value={this.state.form.with} error={this.state.formError} icon={ProfileSml} name='with' onChange={this.onChange} type='contact'/>
       </div> : this.renderContact(this.props.with)
      }
      <div className='uiScheduleAppointmentBodyField'>
      <UIInputField label={'Description'} error={this.state.formError} value={this.state.form.title} icon={Edit} name='title' onChange={this.onChange} type='text'/>
      </div>
      <div className='uiScheduleAppointmentBodyField uiBodyFieldDateWhen'>
      <UIInputField label={'When'} value={this.state.form.date} icon={CalSml} name='date' onChange={this.onChange} type='date'/>
      </div>

      <div key='startsEnds' className='uiScheduleAppointmentBodyField'>
      <UIInputField key='startsEnds' label={{start: 'Starts', end: 'Ends'}} error={this.state.formError} value={
        {start: this.state.form.start, end: this.state.form.end}} icon={Clock} name='start-end' onChange={(name, value) => {
          const {start, end} = value;
          if (start) {
            this.onChange('start', start);
          }
          if (end) {
            this.onChange('end', end);
          }
        }} type='time-duration'/>
      </div>
      {showInvoice && 
       <div key='invoiceFields' className='uiScheduleAppointmentBodyInvoiceFields'>
       <div className='uiScheduleAppointmentBodyField'>
       <UIInputField label={'Invoice Description'} placeholder={this.state.form.title} value={this.state.form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={this.onChange} type='text'/>
       </div>
       <div className='uiScheduleAppointmentBodyField'>
       {!needsStripeConnect ?
        <UIInputField error={this.state.formError} label={'Invoice Amount'} value={this.state.form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={this.onChange} type='currency'/> :
        <UIInputField label={'Invoice Amount'} error={invoiceAmountErr} value={stripeLabel} readOnly={true} icon={StripeS} name='invoiceAmount' onChange={this.onChange} onClick={this.props.me.stripeConnect} type='button'/> }
       </div>
       </div>}
    {attendeeFields}
    </div>
  }

   renderOrganizerViewFooter() {
    let style
    if (this.state.workoutTabSelection === 'recent') {
      style = { display: 'none' }
    }
    let okIcon = this.props.isNew ? Fwd: Update;
    let update = 'Update'
    let schedule = this.props.okActionTitle ? this.props.okActionTitle: 'Schedule'
    if (this.props.scope == 'weight') {
      schedule = 'Add Weight'
    }
    if (this.props.scope == 'meals' && this.state.form.mealSelection) {
      schedule = 'Add '+ makeUpper(this.state.form.mealSelection)
      //update = 'Update ' + makeUpper(this.state.form.mealSelection)
    }
    let actionLabel = this.props.isNew ? schedule : update;
    let okAction = this.okAction;
     if (this.props.scope == 'todo') {
       if (this.props.isNew) {
         actionLabel = "Add To-do"
       }
    }
    let cancelAction = this.props.isNew ? null: this.props.trash;
    let backAction;
    let cancelIcon = Trash;
    if (this.state.showMembers) {
      backAction = this.hideMembers;
    }
    console.log('render cancel action', cancelAction)
    return <div className='uiScheduleAppointmentOrganizerPageFooter' style={style}>
      <div className='uiScheduleAppointmentBodyFieldsError'>
      {this.state.error}
    </div>
      <div className='uiScheduleAppointmentBodyButtons'>
      {actionLabel && <UIOKCancel back={backAction} okIcon={okIcon} cancelIcon={cancelIcon} label={actionLabel} ok={okAction} cancel={cancelAction}/>}
    </div>
      </div>
  }

renderScoped() {
  if (this.props.scope === 'workouts' || (this.props.appt && this.props.appt.workout)) {
    if (!this.props.nonSession) {
      return this.renderWorkoutSession()
    }
  }
    let className='uiScheduleAppointment uiScheduleAppointmentMeals'
    if (isIPad()) {
      className += ' uiScheduleAppointmentIPad'
    }
    else if (isMobile()) {
      className += ' uiScheduleAppointmentMobile'
    }
    if (this.props.scope == 'workouts') {
      className += ' uiScheduleAppointmentWorkout'
    }
    return <div className={className}>
      {this.renderOrganizerView()}
        {this.state.scanFood &&
         <div className='uiScheduleAppointmentScanner' onClick={this.onScanUPC}>
         <FDQRCodeInput me={this.props.me} onScan={this.onScanBarcode} barcode={true} codeType="UPC"/>
         </div>
        }
    </div>
  }

  renderOrganizerView() {
    let title = 'Appointment'
    switch (this.props.scope) {
      case 'meals':
        title = 'Meal'
        break
      case 'workouts':
        title = 'Activity'
        break
      case 'todo':
        title = 'To-do'
        break
      case 'weight':
        title = 'Weight'
        break
    }
    const appt = this.props.appt
    if (appt && appt.meal) {
      title = "Meal"
    }
    if (appt && appt.workout) {
      title = "Activity"
    }
    let subPage;
    if (this.state.showMembers) {
      subPage = this.renderOrganizerPage2();
    }
    return <UIPage
    title={title}
    subtitle={moment(this.state.form.date).format("MMM Do YYYY")}
    icon={Schedule}
    subPage={subPage}
    footer={this.renderOrganizerViewFooter()}
    close={this.props.back}>
      {this.renderOrganizerPage1()}
    </UIPage>
  }
  
  renderSelf() {
    if (this.props.scope) {
      return this.renderScoped()
    }

    let isOrganizer = true;

    if (this.props.client) {
      isOrganizer = false;
    } else if (this.props.with && this.props.with.isGroup) {
      if (this.props.with.group.organizer != this.props.me.self.uid) {
        isOrganizer = false;
      }
    } else {
      isOrganizer = false;
    }


    const status = this.props.status;
    const paymentStatus = this.state.paymentStatus;
    let paymentLabel = "Complete Payment";
    let paymentStatusLabel = "Payment Requested";
    let okIcon = this.props.isNew ? Fwd: Update;
    let actionLabel = !this.props.client ?  this.props.isNew ? "Schedule" : "Update"  : "Accept";
    switch (paymentStatus) {
      case "requires_payment_method":
      case "requires_confirmation":
      case "requires_action":
        paymentStatusLabel = "Payment Requested";
        break;
      case "processing":
        paymentStatusLabel = "Payment Processing";
        break;
      case "canceled":
        paymentStatusLabel = "Payment Canceled";
        break;
      case "succeeded":
        paymentStatusLabel = "Payment Complete";
        paymentLabel = "View Receipt";
        if (this.props.client) {
          if (status == 'accepted') {
            actionLabel = null;
            okIcon = Save;
          }
        }
        break;
    }
    let okAction;
    let cancelAction;
    if (this.props.client) {
      if (status == 'canceled') {
        okAction = null;
        cancelAction = null;
      } else {
        if (status != "accepted") {
          okAction = this.props.accept;
        }
        if (paymentStatus != 'succeeded') {
          cancelAction = this.props.decline;
        }
      }
    } else {
      okAction = this.okAction;
      if (paymentStatus != 'succeeded') {
        cancelAction = this.props.isNew ? null: this.props.trash;
      }
      if (status == 'canceled') {
        okAction = null;
        cancelAction = null;
      }
    }
    debugLog("payment status: ", paymentStatus, ", payment Intent: ", this.props.paymentIntentId);
    const paymentIntentId = paymentStatus ? this.props.paymentIntentId : null;
    const invoiceAmount = this.state.form.invoiceAmount || 0;
    let needsStripeConnect = !this.props.me.stripeAuth;
    let stripeLabel = "Enable Payments with Stripe";
    let invoiceAmountErr;
    if (!needsStripeConnect) {
      const account = this.props.me.accountData.account;
      if (account && account.requirements && (account.requirements.disabled_reason ||
                                              (account.requirements.errors && account.requirements.errors.length > 0 ))) {
        stripeLabel = "Fix Payment Setup Errors";
        needsStripeConnect = true;
        invoiceAmountErr = {
          field: "invoiceAmount",
        }
      }
    }
    let groupMembers;
    let className = 'uiScheduleAppointment'+ (this.props.editable ? "" : " uiScheduleAppointmentClient");
    if (isIPad()) {
      className += ' uiScheduleAppointmentIPad'
    }
    else if (isMobile()) {
      className += ' uiScheduleAppointmentMobile'
    }
    if (false && this.props.with && this.props.with.isGroup) {
      className += " uiScheduleGroupAppointment";
      groupMembers = this.props.with.group.members.filter(uid => uid != this.props.me.self.uid).map(uid => this.props.me.getContact(uid));
    }
    if (this.props.appt && this.props.appt.weight) {
      cancelAction = this.props.trash
    }
    return  <div className={className}>
      <div className='uiScheduleAppointmentHeader'>

      <div className='uiScheduleAppointmentHeaderClose' onClick={this.props.back}>
      <ReactSVG src={Close}/>
      </div>

      <div className='uiScheduleAppointmentHeaderInfo'>

      <div className='uiScheduleAppointmentHeaderIcon'>
      <ReactSVG src={Schedule}/>
      </div>

      <div className='uiScheduleAppointmentHeaderTitle'>
      {this.props.scope == 'meals' ? 'Meal' : this.props.scope == 'weight' ? 'Weight' : 'Appointment'}
    </div>
      <div className='uiScheduleAppointmentHeaderDate'>
      {moment(this.props.on).format("MMM Do YYYY")}
    </div>
      
    </div>
      </div>
      <div className='uiScheduleAppointmentBody'>
      {!this.props.small && (this.props.client || !this.props.isNew || this.props.withReadOnly) &&
       <div className='uiScheduleAppointmentBodyWith'>

       <div className='uiScheduleAppointmentBodyWithIcon'>
       <UIProfileIcon radius={22.5} contact={this.props.with}/>
       </div>

       <div className='uiScheduleAppointmentBodyWithName'>
       {this.props.with ? this.props.with.displayName: ""}
       </div>
       <div className='uiScheduleAppointmentBodyWithCreds'>{this.props.with ? this.props.with.creds : ''}</div>
       </div>
      }

      <div className='uiScheduleAppointmentClientFields' style={this.props.client ? null : {display: "none"}}>
      <div className='uiScheduleAppointmentClientFieldsInfo'>
      <div className='uiScheduleAppointmentClientTitle'>
      {this.props.title}
    </div>
      <div className='uiScheduleAppointmentClientTime'>
      {moment(this.props.start).format('h:mm A') + " - "+ moment(this.props.end).format('h:mm A')}
    </div>


    </div>
      </div>
      {this.props.client && invoiceAmount > 0 && <div className='uiScheduleAppointmentPaymentInfo'>
       <div className='uiScheduleAppointmentPaymentInfoLoading' style={
         (this.props.paymentIntentId && !this.state.paymentStatus) ? {visibility: "hidden"} : null
       }>
       <div className='uiScheduleAppointmentPaymentInvoice'>
       <div className='uiScheduleAppointmentPaymentStatus'>{paymentStatusLabel}</div>
       <div className='uiScheduleAppointmentPaymentInvoiceAmount'>${invoiceAmount.toFixed(2)}</div>
       <div className='uiScheduleAppointmentPaymentInvoiceDescription'>{this.props.invoiceDescription}</div>
       </div>
       <div  className='uiScheduleAppointmentPaymentMethod'>{this.renderPaymentMethod()}<div className='uiScheduleAppointmentPaymentMethodIcon'><ReactSVG src={CreditCard}/></div></div>
       <div className='uiScheduleAppointmentPaymentControl'><UIOKCancel okIcon={Fwd} label={paymentLabel} ok={this.paymentAction}/></div>
       </div>
       </div>}            
      <div className='uiScheduleAppointmentBodyFields' style={!this.props.client ? null : {display: "none"}}>

    {this.props.isNew && !this.props.withReadOnly &&<div className='uiScheduleAppointmentBodyField'>
     <UIInputField label={'Meeting'} search={this.searchContacts} value={this.state.form.with} error={this.state.formError} icon={ProfileSml} name='with' onChange={this.onChange} type='contact'/>
     </div>}
      <div className='uiScheduleAppointmentBodyField'>
      <UIInputField label={'Description'} error={this.state.formError} value={this.state.form.title} icon={Edit} name='title' onChange={this.onChange} type='text'/>
      </div>
      <div className='uiScheduleAppointmentBodyField uiBodyFieldDateWhen'>
      <UIInputField label={'When'} value={this.state.form.date} icon={CalSml} name='date' onChange={this.onChange} type='date'/>
      </div>

      <div key='startsEnds' className='uiScheduleAppointmentBodyField'>
      <UIInputField key='startsEnds' label={{start: 'Starts', end: 'Ends'}} error={this.state.formError} value={
        {start: this.state.form.start, end: this.state.form.end}} icon={Clock} name='start-end' onChange={(name, value) => {
          const {start, end} = value;
          if (start) {
            this.onChange('start', start);
          }
          if (end) {
            this.onChange('end', end);
          }
        }} type='time-duration'/>
      </div>             
      {!paymentIntentId && (!this.props.with || !this.props.with.isGroup) && 
       <div className='uiScheduleAppointmentBodyInvoiceFields'>
       <div className='uiScheduleAppointmentBodyField'>
       <UIInputField label={'Invoice Description'} placeholder={this.state.form.title} value={this.state.form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={this.onChange} type='text'/>
       </div>
       <div className='uiScheduleAppointmentBodyField'>
       {!needsStripeConnect ?
        <UIInputField error={this.state.formError} label={'Invoice Amount'} value={this.state.form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={this.onChange} type='currency'/> :
        <UIInputField label={'Invoice Amount'} error={invoiceAmountErr} value={stripeLabel} readOnly={true} icon={StripeS} name='invoiceAmount' onChange={this.onChange} onClick={this.props.me.stripeConnect} type='button'/> }
       </div>
       </div>}
    {this.props.with && this.props.with.isGroup && <div className='uiScheduleAppointmentAttendeesList'>
     <UIInputField label={'Attendees'} error={invoiceAmountErr} value={this.props.with.group.members.length + " People"} readOnly={true} icon={Fwd} name='attendees' onChange={this.onChange} onClick={this.showMembers} type='button'/> 
     
     </div>}
    {groupMembers &&
     <div className='uiScheduleAppointmentMembersBody'>
     <div className='uiScheduleAppointmentBodyField'>
     <UIInputField label={'Invoice Description'} placeholder={this.state.form.title} value={this.state.form.invoiceDescription} icon={Edit} name='invoiceDescription' onChange={this.onChange} type='text'/>
     </div>
     <div className='uiScheduleAppointmentMembers'>
     {groupMembers.map(c => {
       return <div className='uiScheduleAppointmentMember'>
         <UIInputField error={this.state.formError} label={'Invoice Amount - '+c.displayName} value={this.state.form.invoiceAmount} icon={CreditCard} name='invoiceAmount' onChange={this.onChange} type='currency'/> 
         </div>})}
     </div>
     </div>
    }
    
    {paymentIntentId &&
     <div className='uiScheduleAppointmentPaymentInvoice'>
     <div className='uiScheduleAppointmentPaymentStatusInfo'>
     <div className='uiScheduleAppointmentPaymentStatus'>{paymentStatusLabel}</div>
     <div className='uiScheduleAppointmentPaymentInvoiceAmount'>${invoiceAmount.toFixed(2)}</div>
     </div>
     <div className='uiScheduleAppointmentPaymentControl'><UIOKCancel okIcon={Refund} label={"Refund Payment"} ok={this.paymentRefund}/></div>
     </div>}
    </div> 
      <div className='uiScheduleAppointmentBodyFieldsError'>
      {this.state.error}
    </div>
      <div className='uiScheduleAppointmentBodyButtons'>
      {actionLabel && <UIOKCancel okIcon={okIcon} cancelIcon={this.props.client ? Cross: Trash} label={actionLabel} ok={okAction} cancel={cancelAction}/>}
    </div>
      </div>
      </div>
  }

  onScanBarcode = code => {
    this.setState({
      scanFood: false
    })
  }
  
  onScanUPC = e => {
    if (this.props.me.isNative()) {
      return this.props.me.readQRCode().then(async code => {
        this.props.me.nativeLog("got code " + code)
        if (!code) {
        } else {
          let barcode = parseBarcode(code)
          if (barcode) {
            const result = await this.handleBarcode(barcode)
          } else {
            this.forceUpdate()
          }
        }
      })
    }
    if (e) e.preventDefault()
    this.setState({
      scanFood: !this.state.scanFood
    })
  }
}
