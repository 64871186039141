import React, { Component } from 'react';
import {Button} from "./Button"
import {ShowLink} from "./ShowLink"
import {SignUp} from "./SignUp"
import {YourContactInfo, MyContactInfo} from "./ContactInfo";
import {InputField} from "./InputField"
import Clipboard from "./Clipboard"
import {isDesktop} from "./Platform"
import MiddleEllipsis from 'react-middle-ellipsis'
import "./ContactsView.css";

class ContactView extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className='contactCard'>
            <div className='contactCard'>
            <div className='contactName' onClick={()=>this.props.info(this.props.contact)}>{this.props.contact.displayName}</div>
            {this.props.unread ? <div className='unreadCount'>{this.props.unread}</div> : null}
            </div>
            
            <div className='buttonContainer'>
            <Button small={true} label='Message' action={()=>this.props.message(this.props.contact)}/>
            <Button extraSmall={true} label='Call' action={()=>this.props.call(this.props.contact)}/>
            <Button small={true} label='Remove' action={this.props.remove ? ()=>this.props.remove(this.props.contact) : null}/>
            </div>
            </div>
    }
}

export class ContactsView extends Component {

    constructor(props) {
        super(props);
        this.state = {contacts: []};
        this.contacts = {};
    }

    componentDidMount() {
        this.props.contacts.onSelf(self => {
            this.setState({
                self: self
            })
        });
        this.props.contacts.onChange((type, contact) => {
            if (type == "removed") {
                delete this.contacts[contact.uid];
            } else {
                this.contacts[contact.uid] = contact;
            }
            this.updateContactsLater();
        });
        this.getContactLink();
    }

    updateContactsLater = () => {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            const contacts = [];
            for (var i in this.contacts) {
                contacts.push(this.contacts[i]);
            }
            this.setState({
                contacts: contacts
            });
        }, 200);
    }

    getContactLink = () => {
        if (this.state.link) {
            return Promise.resolve(this.state.link);
        }
        return new Promise((resolve, reject) => {
            this.props.contacts.getContactLink().then(link => {
                this.setState({link: link}, () => resolve(link));
            });
        });
    }

    showLink = () => {
        return this.getContactLink().then(link => {
            this.setState({
                showLink: true,
                link: link
            })
        });
    }

    checkFocus = ()=> {
        if (!this.state.showLink && !this.state.showAccount && !this.state.showContact) {
            this.searchField.focus();
        }
    }

    toggleShowLink = () => {
        this.setState({
            showLink: !this.state.showLink
        });
        this.checkFocus();
    }

    toggleShowAccount = () => {
        this.setState({
            showAccount: !this.state.showAccount
        });
        this.checkFocus();
    }

    toggleShowContact = () => {
        this.setState({
            showContact: !this.state.showContact
        });
        this.checkFocus();
    }

    formLinkURL = link => {
        return window.origin+"/?contact="+link;
    }

    copyLink = () => {
        Clipboard.copy(this.formLinkURL(this.state.link));
        this.toggleShowLink();
    }

    updateAccount = form => {
        this.props.contacts.updateSelf({
            email: form.email,
            displayName: form.name,
            password: form.password
        })
        this.toggleShowAccount();
    }

    showContact = contact => {
        this.setState({
            showContact: contact
        });
    }

    setSearchField = x => {
        this.searchField = x;
        this.props.setSearchField(x);
    }

    search = term => this.setState({searchTerm: term});

    render() {
        if (!this.state.self) {
            return <div/>
        }
        if (this.state.showLink) {
            return <ShowLink link={this.formLinkURL(this.state.link)} title={"Contact Link"} subtitle={"Share this link to connect your contacts to "+window.ProductName} copyLink={this.copyLink} done={this.toggleShowLink}/>
        }
        if (this.state.showAccount) {
            return <MyContactInfo me={this.props.me}
            observeStripeAuth={this.props.observeStripeAuth}
            stripeConnect={this.props.stripeConnect} contact={this.state.self} backLabel={"Back"} backAction={this.toggleShowAccount}/>;
        }
        if (this.state.showContact) {
            return <YourContactInfo me={this.props.me} readOnly={true} contact={this.state.showContact} backLabel={"Back"} backAction={this.toggleShowContact}/>
        }
        let contacts = this.state.contacts;
        if (this.state.searchTerm) {
            const searchTerms = this.state.searchTerm.toLowerCase().split(/\s+/);
            const matches = {};
            const filter = x => {
                let matched = 0;
                const name = x.displayName.toLowerCase();
                const terms = name.split(/\s+/);
                searchTerms.map(searchTerm => {
                    terms.map(term => { if (term.startsWith(searchTerm)) { matched++ } });
                });
                matches[x.uid] = matched;
                return matched > 0;
            }
            const sort = (x, y) => {
                const w1 = matches[x.uid];
                const w2 = matches[y.uid];
                const cmp1 = w2-w1;
                if (cmp1 !== 0) {
                    return cmp1;
                }
                return x.displayName.localeCompare(y.displayName);
            }
            contacts = this.state.contacts.filter(filter).sort(sort);
        } else {
            contacts.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
        return <div className='contacts'>
            <div className='buttonContainer'>
            <Button label="Back" action={this.props.close}/>
            <Button label="Contact Link" action={this.showLink}/>
            <Button label="Account" action={this.toggleShowAccount}/>
            <Button label="Sign out" action={this.props.signOut}/>
            </div>
            <div className='contactsContainer'>
            <div className='contactsHeader'>
            <div className='contactsTitle'>
            <div className='contactsSubtitleText'>{this.state.self.displayName}</div>
            <div className='contactsSearch'>
            <InputField ref={x => this.setSearchField(x)} autoFocus={isDesktop()} label={"Search "+window.ProductName+" Contacts"} name='search' type='text' onChange={(name, value) => this.search(value)}/>
            </div>
            </div>
            </div>
            <div className='contactsList'>
            {contacts.map(contact => <ContactView
                          key={contact.uid}
                          message={this.props.messageContact} call={this.props.callContact}
                          remove={contact.uid == this.state.self.uid ? null: this.props.removeContact}
                          info={this.showContact}
                          unread={this.props.getUnread(contact)}
                          contact={contact}/>)}
        </div>
        </div>
        </div>

    }
}


